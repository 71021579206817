import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { DossierState } from '../slices/dossier.slice';
import { mockDossier } from '../../common/services/dossier/dossier.service';
import {
  mapEducationAndQualificationDto,
  mapRolesAndResponsibilitiesDto,
  mapArhievementsOrProjectsDto,
  mapSkillsAndExpertiseDto,
  mapProfessionalAssociationsandMembershipsDto,
  mapPublicationsandContributionsDto,
  mapWorkExperienceDto,
} from '../../common/services/dossier/person/dossier.helper';

const selectDossierState: (state: AppState) => DossierState = (state) => state.dossier;
export const currentDossier = createSelector(selectDossierState, ({ currentDossier }) => currentDossier);
export const processDossierEnabled = createSelector(
  selectDossierState,
  ({ processDossierEnabled }) => processDossierEnabled,
);
export const DossierErrorCodes = createSelector(selectDossierState, ({ errorCode }) => errorCode);
export const defaultDossier = createSelector(selectDossierState, ({ defaultDossier }) => defaultDossier);
export const userDossiersList = createSelector(selectDossierState, ({ dossiersList }) => dossiersList);
export const isProcessingDossier = createSelector(selectDossierState, ({ isProcessingDossier }) => isProcessingDossier);
export const dossierProcessingInput = createSelector(selectDossierState, ({ userInput }) => userInput);
export const dossierInputType = createSelector(selectDossierState, ({ currentDossierType }) => currentDossierType);
export const isFetchingDossiers = createSelector(selectDossierState, ({ isFetchingDossiers }) => isFetchingDossiers);
export const favoriteDossierPagination = createSelector(
  selectDossierState,
  ({ dossierPagination }) => dossierPagination,
);
export const favoriteDossierSelector = createSelector(selectDossierState, ({ favoriteDossiers }) => favoriteDossiers);
export const currentDossierIdSelector = createSelector(selectDossierState, ({ currentDossierId }) => currentDossierId);
export const favoritesDossierCount = createSelector(
  selectDossierState,
  ({ favoriteDossiersCount }) => favoriteDossiersCount,
);
export const educationInformationSelector = createSelector(selectDossierState, ({ currentDossier }) =>
  mapEducationAndQualificationDto(currentDossier?.educationAndQualificationDto),
);
export const rolesAndResponsibilitiesSelector = createSelector(selectDossierState, ({ currentDossier }) =>
  mapRolesAndResponsibilitiesDto(currentDossier?.employmentHistoryDto?.rolesAndResponsibilities),
);
export const workExperienceSelector = createSelector(selectDossierState, ({ currentDossier }) =>
  mapWorkExperienceDto(currentDossier?.employmentHistoryDto?.WorkExperience),
);
export const achievementsOrProjectsUndertakenSelector = createSelector(selectDossierState, ({ currentDossier }) =>
  mapArhievementsOrProjectsDto(currentDossier?.employmentHistoryDto?.achievementsOrProjectsUndertaken),
);
export const skillsAndExpertiseSelector = createSelector(selectDossierState, ({ currentDossier }) =>
  mapSkillsAndExpertiseDto(currentDossier?.skillsAndExpertiseDto),
);
export const professionalAssociationsAndMembershipSelector = createSelector(selectDossierState, ({ currentDossier }) =>
  mapProfessionalAssociationsandMembershipsDto(currentDossier?.professionalAssociationsAndMembershipDto),
);
export const publicationsandContributionsSelector = createSelector(selectDossierState, ({ currentDossier }) =>
  mapPublicationsandContributionsDto(currentDossier?.publicationsAndContributionDto),
);
