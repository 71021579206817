import { PROMPT_OPTIMIZER } from '../../../common/constants/app-text';
import { PromptOptimizerPrimaryIcon } from '../../../common/icons/Icons';
import { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { federatedActions } from '../../../redux/actions/federated.actions';
import { ImageBox } from '../../ImageBox/ImageBox';
import ChatGptBlack from '../../../static/img/chat-gpt-black.png';
import { SecondaryButton } from '../../SecondaryButton';
import { PrimaryButton } from '../../PrimaryButton';
import Loading from '../../Loader/Loader';
import {
  isPromptOptimierRunning,
  optimisedPromptLLMResponse,
  processingOptimisedPromptLLM,
  processingPromptOptimizerLLM,
  promptOptimiserLLMResponse,
  promptOptimizerResult,
  promptProtectCheckText,
} from '../../../redux/selectors/federated.selectors';
import { InfoIcon } from '../../../common/icons/Icons';
import { TextInput } from 'flowbite-react';
import { GuardRailEngineType } from '../../../common/constants/enums/engine-types.enum';

interface PromptOptimizerCheckProps {
  engineType?: GuardRailEngineType;
}
export const PromptOptimizerCheck: FC<PromptOptimizerCheckProps> = ({ engineType }) => {
  const handleClose = () => {
    dispatch(federatedActions.togglePromptOptimiserModel(false));
  };
  const dispatch = useDispatch();
  const currentPromptText = useSelector(promptProtectCheckText);
  const loading = useSelector(isPromptOptimierRunning);
  const OptimizerResult = useSelector(promptOptimizerResult);
  const llmResponse = useSelector(promptOptimiserLLMResponse);
  const optimisedLLMResponse = useSelector(optimisedPromptLLMResponse);
  const isProcessingPromptOptimizerLLM = useSelector(processingPromptOptimizerLLM);
  const isProcessingOptimisedPromptLLM = useSelector(processingOptimisedPromptLLM);
  const [userFeedback, setUserFeedback] = useState<string>('');
  const [promptOptimizerText, setPromptOptimizerText] = useState(currentPromptText);
  const [showCompareResults, setShowCompareResults] = useState(false);

  const handleSubmit = (content: string) => {
    dispatch(federatedActions.togglePromptOptimiserModel(false));
    dispatch(federatedActions.setPromptText(content));
  };

  const handleOptimizeAgain = () => {
    if (OptimizerResult.optimized_prompt) {
      setPromptOptimizerText(OptimizerResult.optimized_prompt);
    }
    dispatch(
      federatedActions.processPromptOptimiser({
        session_id: Math.random().toString(36).substring(2),
        user_initial_prompt: OptimizerResult.optimized_prompt,
        user_feedback: userFeedback,
      }),
    );
  };

  const processPrompts = () => {
    dispatch(
      federatedActions.processPromptOptimizerLLM({
        initial_prompt: promptOptimizerText,
        optimized_prompt: OptimizerResult.optimized_prompt,
      }),
    );
    setShowCompareResults(true);
  };
  return (
    <div className=" flex items-center justify-center p-4 w-[80vw]">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full max-w-4xl mx-2 p-6">
        <div className="flex justify-between items-start flex-wrap">
          <div className="input-action-item ">
            <div className="icon-wrapper">
              <div className="text-wrapper-30">
                <PromptOptimizerPrimaryIcon />
              </div>
            </div>
            <div className="text-lg font-semibold text-gray-900">
              <span>{PROMPT_OPTIMIZER}</span>
            </div>
          </div>
        </div>
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="col-span-1 flex flex-col ">
            <div className="flex justify-between items-center w-full">
              <div className="text-sm font-medium text-gray-400 mb-2">ORIGINAL PROMPT</div>
            </div>
            <div className="bg-gray-50  rounded-md flex-grow min-h-150">
              <textarea
                onChange={(e) => setPromptOptimizerText(e.target.value)}
                className={`text-sm p-4 h-full w-full`}
                value={promptOptimizerText}
              />
            </div>
            <div className="flex justify-between items-center mt-3">
              <div className="text-sm text-gray-500  text-purple-lite">{promptOptimizerText?.length} characters</div>
              <SecondaryButton
                className="py-1 px-3"
                stateProp="rest"
                text="Use this"
                onClick={() => handleSubmit(promptOptimizerText)}
              />
            </div>
          </div>
          <div className="col-span-1 flex flex-col ">
            <div className="flex justify-between items-center w-full">
              <div className="text-sm font-medium text-gray-400 mb-2">OPTIMIZED PROMPT</div>
            </div>
            <div className="bg-gray-50  rounded-md flex-grow min-h-150">
              <textarea disabled className={`text-sm p-4 h-full w-full`} value={OptimizerResult?.optimized_prompt} />
            </div>
            {loading ? (
              <div className="flex items-center">
                <div className="bg-gray-100 flex w-full mt-2">
                  <div className="text-sm font-medium text-blue-600 p-2 flex items-center">
                    <ImageBox className="w-7 h-7 mr-2" url={ChatGptBlack} />
                    <Loading gap="m-0 p-0" message="Optimizing prompt, please wait" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-between items-center mt-3">
                <div className="text-sm text-gray-500  text-purple-lite">
                  {OptimizerResult?.optimized_prompt?.length} characters
                </div>
                <SecondaryButton
                  className="py-1 px-3"
                  stateProp="rest"
                  text="Use this"
                  onClick={() => handleSubmit(OptimizerResult?.optimized_prompt)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="col-span-1 flex flex-col ">
            {engineType === GuardRailEngineType.Federated || showCompareResults ? (
              <>
                <div className="flex items-center w-full mb-1">
                  <ImageBox className="w-4 h-4" url={ChatGptBlack} />
                  <div className="text-sm font-medium text-gray-400 ml-2">LLM OUTPUT</div>
                </div>
                <div className="flex-grow min-h-150 border border-gray-200 border-solid">
                  {isProcessingPromptOptimizerLLM ? (
                    <div className="h-40 flex items-center justify-center">
                      <div className="bg-gray-100 flex mt-2">
                        <div className="text-sm font-medium text-blue-600 p-2 flex items-center">
                          <Loading gap="m-0 p-0" message="Getting Response" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" h-40 overflow-auto scrollbar-visible">
                      <p className="text-xs text-gray-500 pr-4 p-1">{llmResponse}</p>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
          <div className="col-span-1 flex flex-col ">
            {engineType === GuardRailEngineType.Federated || showCompareResults ? (
              <>
                <div className="flex items-center w-full mb-1">
                  <ImageBox className="w-4 h-4" url={ChatGptBlack} />
                  <div className="text-sm font-medium text-gray-400 ml-2">LLM OUTPUT</div>
                </div>
                <div className="flex-grow min-h-150 border border-gray-200 border-solid">
                  {isProcessingOptimisedPromptLLM ? (
                    <div className="h-40 flex items-center justify-center">
                      <div className="bg-gray-100 flex mt-2">
                        <div className="text-sm font-medium text-blue-600 p-2 flex items-center">
                          <Loading gap="m-0 p-0" message="Geting Response" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" h-40 overflow-auto scrollbar-visible">
                      <p className="text-xs text-gray-500 pr-4 p-1">
                        {engineType === GuardRailEngineType.Federated
                          ? OptimizerResult?.expected_response
                          : optimisedLLMResponse}
                      </p>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>

        {engineType === GuardRailEngineType.Federated && (
          <div className="mt-6 w-full">
            <div className="flex items-center">
              <p className="m-0 p-0">Give instructions to optimize</p>
              <InfoIcon />
            </div>
            <TextInput
              className="mt-2 w-full rounded-none"
              style={{ paddingTop: '16px', paddingBottom: '16px' }}
              placeholder="Type instructions"
              value={userFeedback}
              onChange={(e) => setUserFeedback(e.target.value)}
            />
          </div>
        )}

        <div className="flex justify-end mt-3">
          <div className="flex flex-wrap justify-end space-x-2 w-1/2">
            <SecondaryButton className="px-4 py-2 mt-2" stateProp="rest" text="Cancel" onClick={handleClose} />
            {engineType === GuardRailEngineType.Gateway123 && (
              <PrimaryButton
                className=" px-4 py-2 text-sm mt-2"
                size="medium"
                stateProp="rest"
                style={`boxed`}
                text={showCompareResults ? 'Hide' : 'Advanced'}
                onClick={showCompareResults ? () => setShowCompareResults(false) : processPrompts}
              />
            )}
            {engineType === GuardRailEngineType.Federated && (
              <PrimaryButton
                className="px-4 py-2 text-sm mt-2"
                size="medium"
                stateProp="rest"
                style={`boxed`}
                text="Optimize Again"
                onClick={handleOptimizeAgain}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
