import { Library, LibraryCreateOrUpdateRequest as LibraryCreateOrUpdateRequest, LibraryMap, LibraryMapLite } from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const PATTERN_MANAGEMENT_STORE_NAME = 'pattern_management';

const createPayloadAction = getCreatePayloadActionFromStore(PATTERN_MANAGEMENT_STORE_NAME);
const createAction = getCreateActionFromStore(PATTERN_MANAGEMENT_STORE_NAME);

const setSystemPatterns = createAction('set system patterns');
const setUserPatterns = createPayloadAction<Library[]>('set user patterns');
const setIsFetching = createPayloadAction<boolean>('set is fetching');

const setSystemPatternsSuccess = createPayloadAction<LibraryMapLite[]>('set system patterns success');
const setUserPatternsSuccess = createPayloadAction<LibraryMapLite[]>('set user patterns success');
const setIsFetchingSuccess = createAction('set is fetching success');

const setSystemPatternsFail = createPayloadAction<any>('set system patterns fail');
const setUserPatternsFail = createPayloadAction<any>('set user patterns fail');
const setIsFetchingFail = createPayloadAction<any>('set is fetching fail');

const setPatternSelection = createPayloadAction<LibraryMap>('toggle pattern selection');
const setPatternSelectionSuccess = createPayloadAction<LibraryMap>('toggle pattern selection success');
const setPatternSelectionFail = createPayloadAction<any>('toggle pattern selection fail');

const setUserActivePatterns = createAction('get user active patterns');
const setUserActivePatternsSuccess = createPayloadAction<LibraryMap[]>('get user active patterns success');
const setUserActivePatternsFail = createPayloadAction<any>('get user active patterns fail');


const createLibrary = createPayloadAction<LibraryCreateOrUpdateRequest>('create library');
const createLibrarySuccess = createPayloadAction<Library>('create library success');
const createLibraryFail = createPayloadAction<any>('create library fail');

const removeLibrary = createPayloadAction<string>('remove library');
const removeLibrarySuccess = createAction('remove library success');
const removeLibraryFail = createPayloadAction<any>('remove library fail');

const updateLibrary = createPayloadAction<LibraryCreateOrUpdateRequest>('update library');
const updateLibrarySuccess = createAction('update library success');
const updateLibraryFail = createPayloadAction<any>('update library fail');

const getCustomPatternById = createPayloadAction<string>('get custom pattern by id');
const getCustomPatternByIdSuccess = createPayloadAction<Library>('get custom pattern by id success');
const getCustomPatternByIdFail = createPayloadAction<any>('get custom pattern by id fail');

const initilizeLibraries = createAction('initilize library');
const fetchLibrariesFailure = createPayloadAction<any>('initilize library fail');

const uploadCustomJson = createPayloadAction<File>('upload custom json');
const uploadCustomJsonSuccess = createAction('upload custom json success');
const uploadCustomJsonFail = createPayloadAction<any>('upload custom json fail');

export const patternManagementActions = {
  createLibrary,
  createLibrarySuccess,
  createLibraryFail,
  removeLibrary,
  removeLibrarySuccess,
  removeLibraryFail,
  updateLibrary,
  updateLibrarySuccess,
  updateLibraryFail,
  setSystemPatterns,
  setSystemPatternsSuccess,
  setSystemPatternsFail,
  setUserPatterns,
  setUserPatternsSuccess,
  setUserPatternsFail,
  setIsFetching,
  setIsFetchingSuccess,
  setIsFetchingFail,
  uploadCustomJson,
  uploadCustomJsonSuccess,
  uploadCustomJsonFail,
  setPatternSelection,
  setPatternSelectionSuccess,
  setPatternSelectionFail,
  setUserActivePatterns,
  setUserActivePatternsFail,
  setUserActivePatternsSuccess,
  initilizeLibraries,
  fetchLibrariesFailure,
  getCustomPatternById,
  getCustomPatternByIdSuccess,
  getCustomPatternByIdFail,
};