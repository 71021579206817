export enum GuardRailEngineType {
  FactChecker,
  Dossier,
  ContractionFinder,
  PromptProtect,
  Federated,
  LLMS,
  CodeAnalyzer,
  Summarization,
  Projects,
  ProjectAssertion,
  Gateway123,
}

export enum SourceType {
  GPT3 = 'gpt3',
  GPT4 = 'gpt4',
  Gemini = 'gemini',
  Guardrail = 'guardrail',
  Copilot = 'copilot',
}

export enum ContradictionNegationType {
  Wildcard = 'wildcard',
  Contextual = 'contextual',
  RulesBased = 'rule_based',
  None = 'none',
}

export enum SocialMediaProfilesEnum {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
}

export enum PatternTypes {
  System = 'System',
  Custom = 'Custom',
}

export enum ExpressionType {
  inlineRegex = 'inline-regex',
  stringArrary = 'string-array',
  fuzzyMatch = 'fuzzy-match',
}
