export const LANDING_PAGE_MAIN_TEXT =
  'AI generated text is impressive but surprises and mistakes are possible. We help you check the facts, citations and sources of a text block to state the truthfulness of any text block.';

export const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tortor metus, laoreet at orci eget,tincidunt rhoncus nisi. Donec risus felis, porttitor sit amet diam id, elementum faucibus lorem. Aliquam iaculis tellus odio, at commodo nisi commodo ut. Quisque sit.';
export const LOREM_IPSUM_SHORT =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ullamcorper leo sit amet ex venenatis, ut vulputate ipsum porta.';
export const HOME_IT_WORKS = 'How it works?';

export const SOURCES_FOUND = 'Sources found';
export const NEGATIONS_FOUND = 'Negations found';
export const ASSERTION = 'Assertion';
export const NEGATION = 'Negation';
export const CONTRADICTIONS = 'Contradictions';

export const MORE_RESOURCES = 'More resources';
export const UNLOCK_FEATURES = 'Unlock all sources and expand features.';

export const PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER =
  'Copy-paste the text from a AI Model (Chat GPT, Microsoft Bing, Gemini...)';

export const PLACEHOLDER_FOR_USERINPUT_DOSSIER_GENERATOR =
  'Search for an Identity Like a Place or a Famous Personality or a Website';

export const PLACEHOLDER_FOR_USERINPUT_FEDERATED = 'Enter your prompt here';

export const PLACEHOLDER_FOR_USERINPUT_SUMMARIZATION = 'Write or paste text';
export const PLACEHOLDER_FOR_USERINPUT_EXISTING_SUMMARY = 'Write or paste summary';

export const PLACEHOLDER_FOR_PROMPT_PROTECTOR = 'Assess your prompt for potentially inappropriate content';

export const PLACEHOLDER_FOR_ARTICLE_SHARE = 'Enter email(s), comma separated';

export const PROCESSING_WAITING_MESSAGE_GUEST = 'Create an account to get notified when assertions search is complete.';

export const PROCESSING_WAITING_MESSAGE_USER = 'You will be notified via email when complete.';
export const SIGN_AS_GUEST = 'Sign in as guest';
export const CREATE_ACCOUNT = 'Create account';
export const VALIDATE_EMAIL = 'Validate email';
export const CODE_ANALYZER = 'Code Analyzer';
export const FACT_CHECKER = 'Fact Checker';
export const DASHBOARD = 'Dashboard';
export const CONTRADICTION_FINDER = 'Counterpoint';

export const DOSSIER_GENERATOR = 'Dossier Generator';
export const CLIENT_API_ONBOARDING = 'Client API Onboarding';
export const EXTERNAL_SEARCH_SOURCE = 'External Search Source';
export const SUMMARIZATION = 'Summarization';
export const AIPROJECTS = 'Projects';
export const GATEWAY123 = 'Guardrail Gateway';

export const PROMPT_PROTECT = 'Prompt Protect';
export const SETTINGS = 'Settings';
export const ACCESS_MANAGEMENT = 'Access Management';
export const PATTERNS_MANAGEMENT = 'Pattern Management';
export const SUBSCRIPTION_PLANS = 'Subscription Plans';
export const FEDERATED = 'Gateway (Legacy)';
export const ALREADY_HAVE_ACCOUNT = 'Already have an account?';
export const DONT_HAVE_ACCOUNT = "Don't have an account?";
export const DONT_KNOW_EMAIL = "Don't know your email?";
export const REQUEST_SUPPORT = 'Request Support';
export const GET_STARTED_NOW = 'Get Started Now';
export const SIGN_IN = 'Sign in';
export const SUBMIT = 'Submit';
export const PASSWORD_RECOVERY = 'Password Recovery';
export const PASSWORD_RECOVERY_MESSAGE = 'Please provide us with the email you used to create account';
export const SIGN_UP = 'Sign up';
export const TERMS_OF_USE = 'Terms of Use';
export const PRIVACY_POLICY = 'Privacy Policy';
export const CONTINUE = 'Continue';
export const CONTINUE_AS_GUEST = 'Continue as guest';

export const DID_NOT_RECEIVE_EMAIL = "Didn't receive the OTP?";
export const RESEND = 'Resend';
export const BACK_TO_EMAIL = 'Back to email';
export const BACK_TO_SIGNIN = 'Back to sign in';

export const LOADING_TEXT = 'Processing...';

export const EDIT_TEXT = 'Edit Text';

export const SHARE_TEXT = 'Share';

export const Generate_PDF = 'Generate PDF';

export const SHELL_CHECK = 'Spell Check';

export const FAVORITE_TEXT = 'Favorite';

export const GRAMMER_CHECK = 'Grammer Check';

export const PROMPT_OPTIMIZER = 'Prompt Optimizer';

export const USER_PREFERENCE_FONTSIZE = 'fontSize';
export const USER_PREFERENCE_ACCESSIBILITY_SETTINGS = 'accessibilitySettings';
export const USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION = 'twofactorAuthentication';
export const USER_PREFERENCE_EMAIL_NOTIFICATIONS = 'emailNotifications';
export const USER_PREFERENCE_PUSH_NOTIFICATIONS = 'pushNotifications';
export const USER_PREFERENCE_IN_APP_NOTIFICATIONS = 'inAppNotifications';
export const USER_PREFERENCE_NEWS_LETTER = 'newsletter';
export const USER_PREFERENCE_NO_OF_RECORDS = 'No of Records in Results page';
export const USER_PREFERENCE_CACHED_RESULTS = 'Fetch Cached Results If avilable(is faster) live Results';
export const USER_PREFERENCE_ARCHIEVE_HISTORY = 'Archieve All History more than one month old';
export const USER_PREFERENCE_DEFAULT_LLM = 'Use this as a Default LLM for all searches';
export const DEFAULT_LLM_NAME = 'ChatGPT';
export const DEFAULT_FONT_SIZE = 'small';
export const DEFAULT_DOMAIN_ID = 'DefaultDomain';
