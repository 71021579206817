import React, { useState, ChangeEvent } from 'react';

interface DropdownProps {
  options: string[];
  defaultOption?: string;
  onSelect: (selectedValue: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ options, defaultOption, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(defaultOption);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    onSelect(newValue);
  };

  return (
    <div className="relative inline-block text-left w-full">
      <select
        value={selectedOption || ''}
        onChange={handleSelectChange}
        className="form-select w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
      >
        {defaultOption === undefined && (
          <option value="" disabled>
            Select an option
          </option>
        )}
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
