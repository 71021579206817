import React, { useState, useEffect } from 'react';

function ProgressBar({
  children,
  navigate,
  stop,
  message = 'Searching for assertions...',
  subText = '',
  showTime = true,
}: any) {
  const [progress, setProgress] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState('00:00');

  useEffect(() => {
    const intervalTime = stop ? 100 : 1000; // If stop is true, update every 200ms, else every second
    let apiComplete = stop; // Flag to track completion of API call
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100 && apiComplete) {
          // Check if API call is complete
          clearInterval(interval);
          setTimeout(() => {
            navigate();
          }, 500);
          return 100;
        }
        const newProgress = Math.min(oldProgress + (stop ? 5 : 1), 100); // If stop is true, increase progress by 5, else by 1

        // Check if elapsed time has exceeded 10 minutes
        const elapsedMillis = Date.now() - startTime;
        if (elapsedMillis > 10 * 60 * 1000) {
          clearInterval(interval);
          return oldProgress;
        }

        return newProgress;
      });

      // Calculate elapsed time
      const elapsedMillis = Date.now() - startTime;
      const elapsedSeconds = Math.floor(elapsedMillis / 1000);
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds % 60;
      setElapsedTime(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

      // Check if API call is complete
      if (elapsedMillis > 5 * 60 * 1000) {
        apiComplete = true;
      }
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [navigate, stop, startTime]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="font-semibold">
          {message} {subText && <span className="text-gray-800 text-sm font-light"> ({subText}) </span>}
        </div>
        {showTime ? (
          <div className={`text-sm text-grey font-normal font-['Open_Sans']`}>Elapsed time: {elapsedTime}</div>
        ) : null}
      </div>
      {children ? children : null}
      <div className="w-full h-4 bg-gray-200 rounded-full mt-3">
        <div
          className="h-full text-center text-xs text-white bg-gradient-to-r from-blue-500 to-progress-bar-start transition-all duration-100 rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;
