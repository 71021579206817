import ContentLayout from "../../components/ContentLayout/ContentLayout";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import MainPage from "../../components/MainPage/MainPage";
import { MainMenu } from "../../layouts/Sidenav/MainMenu";
import { MainBackground } from "../Home/MainBackground";
import SubscriptionPlans from "./components/SubscriptionPlans";
import { Outlet } from 'react-router-dom';

export const SubscriptionPlansView = () => {
  return (
    <>
      <MainPage>
        <div className="mt-16 -mb-16">
          <Header />
          <div className="flex min-h-[100vh] w-full bg-center bg-no-repeat bg-cover overflow-auto"  >
            <MainBackground showBackground={false}>
            <MainMenu isPushMenu={true} />
            </MainBackground>
          </div>
          <Footer />
        </div>
      </MainPage>
    </>
  );
};