import React from 'react';
import { Result } from '../../types/models';
import { ASSERTION, SOURCES_FOUND } from '../../common/constants/app-text';
import { LinkIcon } from '@heroicons/react/outline';
import { InfoIcon } from '../../common/icons/Icons';
import { extractBaseUrl, extractMainDomain } from '../../common/helpers/Helper';
import { PrimaryButton } from '../../components/PrimaryButton';
import ExpandableParagraph from '../../components/ExpandableParagraph/ExpandableParagraph';

interface Props {
  assertion?: any;
  assertionCount: number;
  selectedSources: string[];
  onSelectSources: (sources: string[]) => void;
  onAddAssertion: () => void;
}

interface AssertionResultItemProps {
  result: Result;
  onCheckboxChange: (source: string, isChecked: boolean) => void;
  isChecked: boolean;
}

const Gateway123AssertionResultItem: React.FC<AssertionResultItemProps> = React.memo(
  ({ result, onCheckboxChange, isChecked }: AssertionResultItemProps) => {
    const mainDomain = extractMainDomain(result.source);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onCheckboxChange(result.source, event.target.checked);
    };

    return (
      <tr className="border-t border-gray-200 border-solid">
        {/* Checkbox */}
        <td className="align-top">
          <div className="flex items-end flex-col p-2">
            <input type="checkbox" className="rounded" onChange={handleCheckboxChange} checked={isChecked} />
          </div>
        </td>
        {/* Source */}
        <td className="align-top sticky left-0 bg-white flex-1">
          <div className="p-2">
            <span className="flex">
              <InfoIcon />
              <div className="ml-1">
                <p className="text-lg -mt-[5px] pt-0">{mainDomain}</p>
                <p className="text-xs text-gray-400">{extractBaseUrl(result.source)}</p>
              </div>
            </span>
            <div className="flex items-center text-sm mt-1">
              <div className="min-w-4 mr-2">
                <LinkIcon />
              </div>
              <a className="text-primary-blue text-xs max-w-32 !line-clamp-1" target="blank" href={result.source}>
                {result.source}
              </a>
            </div>
          </div>
        </td>
        {/* Excerpt */}
        <td className="align-top flex-1">
          <ExpandableParagraph text={result.excerpt} className="text-gray-400 text-xs p-2 pb-0" />
        </td>
      </tr>
    );
  },
);

export const Gateway123AssertionResultsList = ({
  assertion,
  assertionCount,
  selectedSources,
  onSelectSources,
  onAddAssertion,
}: Props): JSX.Element => {
  const handleCheckboxChange = (source: string, isChecked: boolean) => {
    const updatedSources = isChecked ? [...selectedSources, source] : selectedSources.filter((item) => item !== source);

    onSelectSources(updatedSources);
  };

  return (
    <div className="assertion-container-main">
      <div className="assertion-container">
        <div className="flex items-center justify-between mt-3">
          <div className="flex items-center space-x-4">
            <div className="text-black font-bold text-lg">
              {ASSERTION} {assertionCount}
            </div>
            <div className="text-purple-600 text-sm">
              {assertion?.topRanks.length} {SOURCES_FOUND}
            </div>
          </div>

          <div className="self-end">
            <PrimaryButton
              className=""
              size="small"
              stateProp="rest"
              style={`boxed`}
              iconPosition="right"
              onClick={onAddAssertion}
              text="Add assertion"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between space-x-4 mt-3 overflow-auto">
        <div className="p-wrapper overflow-auto" style={{ backgroundColor: assertion?.colorCode }}>
          <p className="p whitespace-pre-wrap break-words overflow-auto">{assertion?.articleName}</p>
        </div>
      </div>
      <div className="relative mb-10 mt-4 overflow-x-scroll">
        <table>
          <thead>
            <tr>
              <th className="assertion-warpper text-end pt-2 pb-2 flex items-center"></th>
              <th className="assertion-warpper text-start p-2">SOURCE</th>
              <th className="assertion-warpper text-start p-2">EXCERPT</th>
            </tr>
          </thead>
          <tbody>
            {assertion?.topRanks?.map((result: Result, index: number) => (
              <Gateway123AssertionResultItem
                result={result}
                key={index}
                onCheckboxChange={handleCheckboxChange}
                isChecked={selectedSources.includes(result.source)} // Check if the source is selected
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
