import { PayloadAction } from '@reduxjs/toolkit';
import { GuestLoginForm, LoginForm, UpdateSubscriptionPlan } from '../../types/models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { profileActions } from '../actions/profile.actions';
import { ProfileErrorCode } from '../../common/constants/profile/profile-error-code';
import { AuthResponse } from '../../common/services/auth/models/auth-response';
import { AuthService } from '../../common/services/auth/auth.service';
import { TokenService } from '../../common/services/token/token.service';
import { Profile } from '../../common/models/user/profile';
import { ProfileService } from '../../common/services/profile/profile.service';
import { layoutActions } from '../actions/layout.actions';
import { DefaultUserRole, PostUserRequest } from '../../common/services/users/models/post-user-request';
import { UserService } from '../../common/services/users/user.service';
import { UserSignInForm } from '../../components/Modal/SignUpModal/SignUpEmailStep';
import { passwordRecoveryActions } from '../actions/password-recovery.actions';
import { setNotification } from '../actions/notification.actions';
import { UserRole } from '../../common/models/user/user-role';
import { User } from '../../common/models/user/user';
import { articleActions } from '../actions/article.actions';
import { DomainService } from '../../common/services/domain/domain.service';
import { Domain } from '../../common/services/domain/models/domain.model';
import { dossierActions } from '../actions/dossier.actions';
import { DossierOrgActions } from '../actions/dossier-org.actions';
import { PromptActions } from '../actions/prompt.actions';
import { contradictionActions } from '../actions/contradiction.actions';
import { userPreferencesActions } from '../actions/user-preference.actions';
import { UpdateProfileForm } from '../../types/models';
import { RoleDetails } from '../../common/services/roles/models/roles';
import { RoleService } from '../../common/services/roles/roles.service';
import { ImageService } from '../../common/services/image/image.service';
import { federatedActions } from '../actions/federated.actions';
import { CodeAnalysisActions } from '../actions/codeAnalysis.actions';
import { SummarizationActions } from '../actions/summarization.actions';
import { ProjectsActions } from '../actions/projects.actions';
import { ActionTypes } from '../../config/authorization/AuthContext';

function* initialSaga() {
  if (!TokenService.accessToken) return;
  yield call(getCurrentUserProfile);
}

function isMockUser(): boolean {
  return process.env.REACT_APP_ENABLE_MOCK_USER === 'true';
}

function* setMockProfile() {
  yield put(profileActions.setMockUserSuccess(getDefaultProfile()));
}
const getDefaultProfile = () => {
  return {
    id: '',
    firstName: 'Michael',
    lastName: 'Byrd',
    email: 'michael.byrd@guardrail.tech',
    imageUrl: '',
    isSubscribed: false,
    planId: "",
    planName: 'Free',
    activeOrganization: '',
    isPlatformAdmin: false,
    claims: [],
    domaidId: undefined,
    subscriptionPlan: 'Free',
    permissions: [],
    organizations: [],
  };
};

function* getRoleDetails(profile: Profile) {
  try {
    let org = profile.organizations.find((e) => e.id === profile.activeOrganization);
    if (org && org.role) {
      const roleDetails: RoleDetails = yield call(RoleService.getByName, org.role);
      yield put(profileActions.setPermissionsForUser(roleDetails));
    }
  } catch (error) {
    yield put(setNotification({ title: 'Unable to fetch user permissions', type: 'error' }));
  }
}
function* login({ payload }: PayloadAction<LoginForm>) {
  let errorCode = ProfileErrorCode.None;
  try {
    const result: AuthResponse = yield call(AuthService.authorizeByCredentials, payload.email, payload.password);
    if (!result.accessToken) {
      errorCode = ProfileErrorCode.UserNotFoundOrIncorrectPassword;
    } else {
      TokenService.accessToken = result.accessToken;
      TokenService.userId = result.userId;
      yield put(setNotification({ title: 'Login Sucessfull', type: 'success' }));
      yield call(getCurrentUserProfile);
    }
  } catch (error: any) {
    console.log(error);
    errorCode = ProfileErrorCode.UserNotFoundOrIncorrectPassword;
    yield put(profileActions.loginFail({ errorCode }));
  }
}

function* refreshAccessToken() {
  try {
    let token = TokenService.accessToken;
    if (!token) return;
    const result: AuthResponse = yield call(AuthService.refreshToken, token);
    if (result.accessToken) {
      TokenService.accessToken = result.accessToken;
    }
    return result;
  } catch (error) {
    console.log('unable to refresh token');
    return null;
  }
}


function* logout() {
  TokenService.clear();
  yield put(setNotification({ title: 'Sucessfully logged out', type: 'success' }));
}

function sessionExpired() {
  TokenService.clear();
}

function* postLoginActions(profile: Profile) {
  yield call(getRoleDetails, profile);
  yield put(profileActions.getProfileSuccess(profile));
  yield put(profileActions.loginSuccess());
  yield put(layoutActions.setShowSignUpModal(false));
  if (profile.permissions.includes(ActionTypes.ACCESS_FACT_CHECK_SERVICE)) {
    yield put(articleActions.getUserArticlesList(1));
  }

  if (profile.permissions.includes(ActionTypes.ACCESS_CONTRADICTION_FINDER)) {
    yield put(contradictionActions.getUserContradictionsList(1));
  }

  if (profile.permissions.includes(ActionTypes.ACCESS_PROMOT_PROTECT_SERVICE)) {
    yield put(PromptActions.getUserPromptsList(1));
  }

  if (profile.permissions.includes(ActionTypes.ACCESS_DOSSIER_GENERATOR)) {
    yield put(dossierActions.getUserDossiersList(1));
    yield put(DossierOrgActions.getUserDossierOrgsList(1));
  }

  if (profile.permissions.includes(ActionTypes.ACCESS_CODE_ANALYZER)) {
    yield put(CodeAnalysisActions.getUserCodeAnalysisList(1));
  }

  if (profile.permissions.includes(ActionTypes.ACCESS_SUMMARIZATION)) {
    yield put(SummarizationActions.getSummarizationsList(1));
  }
  if (profile.permissions.includes(ActionTypes.ACCESS_PROJECT)) {
    yield put(ProjectsActions.getProjectsList({ page: 1 }));
    yield put(ProjectsActions.getPinnedProjects({ page: 1, pinToTop: true }));
  }
  yield put(userPreferencesActions.getUserPreferences());
  yield call(getUserLimit)

}
function* getCurrentUserProfile() {
  let errorCode = ProfileErrorCode.None;
  try {
    const profile: Profile = yield call(ProfileService.getProfile);
    let domainId = profile.claims?.find((e) => e['domainId'])?.domainId;

    if (domainId) {
      let domain: Domain = yield call(DomainService.getDomainById, domainId);
      profile.domain = domain;
    } else {
      const domain: Domain = yield call(DomainService.getDefaultDomain);
      profile.domain = domain;
    }
    console.log(profile.domain);
    if (errorCode === ProfileErrorCode.None) {
      yield call(postLoginActions, profile);
    } else {
      yield put(profileActions.getProfileFail({ errorCode }));
    }
  } catch (error) {
    errorCode = ProfileErrorCode.UnAuthorized;
    yield put(profileActions.getProfileFail({ errorCode }));
  }
}

function* guestLogin({ payload }: PayloadAction<GuestLoginForm>) {
  let errorCode = ProfileErrorCode.None;
  let defaultPassword = 'Welcome@123';
  try {
    const result: AuthResponse = yield call(AuthService.authorizeByEmail, payload.email, defaultPassword);
    if (!result.accessToken) {
      errorCode = ProfileErrorCode.UserNotFoundOrIncorrectPassword;
    }

    TokenService.accessToken = result.accessToken;
    TokenService.userId = result.userId;

    const profile: Profile = yield call(ProfileService.getProfile);
    const isPlatformAdmin: boolean = yield call(ProfileService.getIsPlatformAdmin, TokenService.accessToken);
    profile.isPlatformAdmin = isPlatformAdmin;
    if (profile.organizations.length === 0 || !profile.activeOrganization) {
      errorCode = ProfileErrorCode.UserDoesNotBelongAnyOrganization;
    }

    if (errorCode === ProfileErrorCode.None) {
      yield put(profileActions.getProfileSuccess(profile));
      yield put(profileActions.guestLoginSuccess());
      yield put(layoutActions.setShowGuestSignUpModal(false));
      yield put(setNotification({ title: 'Logged in as guest', type: 'success' }));
    } else {
      yield put(profileActions.guestLoginFail({ errorCode }));
    }
  } catch (error: any) {
    errorCode = ProfileErrorCode.UserNotFoundOrIncorrectPassword;
    yield put(profileActions.guestLoginFail({ errorCode }));
  }
}

function* createUserProfile(payload: UserSignInForm, roleName: UserRole) {
  let errorCode = ProfileErrorCode.None;
  try {
    let cliam = { domainId: payload.domainId };
    let cliams = [];
    if (payload.domainId && payload.domainId.length) {
      cliams.push(cliam);
    }
    console.log('organization id', process.env.REACT_APP_DEFAULT_ORGANIZATION);

    const user: PostUserRequest = {
      email: payload.email,
      firstName: roleName === UserRole.Guest ? 'Guest' : payload.firstName,
      lastName: roleName === UserRole.Guest ? 'Profile' : payload.lastName,
      organizationId: process.env.REACT_APP_DEFAULT_ORGANIZATION ?? '658455937a8f1d135255a4f7',
      role: roleName,
      claims: cliams,
      roleId: DefaultUserRole,
    };
    const result: User = yield call(UserService.post, user);

    if (errorCode === ProfileErrorCode.None) {
      if (roleName === UserRole.User) {
        yield put(passwordRecoveryActions.setPasswordRecoveryRequest(payload.email));
        yield put(profileActions.createAccountSuccess(result));
        yield put(setNotification({ title: 'Account created for ' + result.email, type: 'success' }));
      } else {
        yield put(setNotification({ title: 'OTP Sent to  ' + result.email, type: 'success' }));
        yield put(profileActions.createGuestAccountSuccess(result));
      }
    } else {
      if (roleName === UserRole.User) {
        yield put(profileActions.createAccountFailure({ errorCode }));
      } else {
        yield put(profileActions.createGuestAccountFailure({ errorCode }));
      }
    }
  } catch (error: any) {
    errorCode = ProfileErrorCode.UserExists;
    if (roleName === UserRole.User) {
      yield put(profileActions.createAccountFailure({ errorCode }));
    } else {
      yield put(profileActions.createGuestAccountFailure({ errorCode }));
    }
  }
}

function* createProfile({ payload }: PayloadAction<UserSignInForm>) {
  yield call(createUserProfile, payload, UserRole.User);
}

function* createGuestProfile({ payload }: PayloadAction<UserSignInForm>) {
  yield call(createUserProfile, payload, UserRole.Guest);
}

function* sendEmailVerificationCode({ payload }: PayloadAction<{ email: string }>) {
  try {
    yield put(passwordRecoveryActions.setPasswordRecoveryRequest(payload.email));
  } catch (error) {
    console.log(error);
  }
}

function* getUserLimit() {
  try {
    const result: number = yield call(ProfileService.getRemainingCount);
    yield put(profileActions.getUserLimitSuccess(result));
  } catch (error) {
    yield put(profileActions.getUserLimitSuccess(0));
  }
}

function* updateSubscriptionDetails({ payload }: PayloadAction<UpdateSubscriptionPlan>) {
  try {
    const result: User = yield call(UserService.updateSubscriptionStatus, payload);
      yield call(refreshAccessToken);
    if (result) {
      yield call(getCurrentUserProfile);
    }
  } catch (error) {
    return null
  }
}

function* updateProfile({ payload }: PayloadAction<UpdateProfileForm & { profilePicture?: File }>) {
  let errorCode = ProfileErrorCode.None;
  try {
    let imageId = '';
    let data = { ...payload } as any;

    if (payload.profilePicture) {
      const { id, location } = yield call(ImageService.post, payload.profilePicture);
      imageId = id;
      data.imageId = imageId;
    }

    const profile: Profile = yield call(ProfileService.updateProfile, data);
    yield put(profileActions.updateProfileSuccess(profile));
    yield put(setNotification({ title: 'Profile updated successfully', type: 'success' }));
  } catch (err: any) {
    yield put(profileActions.updateProfileFail({ errorCode }));
    yield put(setNotification({ title: 'Unable to update profile', type: 'error' }));
  }
}


export default function* watcher() {
  yield takeLatest(profileActions.initialize.type, initialSaga);
  yield takeLatest(profileActions.setMockUser.type, setMockProfile);
  yield takeLatest(profileActions.login.type, login);
  yield takeLatest(profileActions.logout.type, logout);
  yield takeLatest(profileActions.sendEmailVerificationCode.type, sendEmailVerificationCode);
  yield takeLatest(profileActions.createAccount.type, createProfile);
  yield takeLatest(profileActions.createGuestAccount.type, createGuestProfile);
  yield takeLatest(profileActions.guestLogin.type, guestLogin);
  yield takeLatest(profileActions.getProfile.type, getCurrentUserProfile);
  yield takeLatest(profileActions.updateProfile.type, updateProfile);
  yield takeLatest(profileActions.sessionExpired.type, sessionExpired);
  yield takeLatest(profileActions.setSubscripedPlan.type, updateSubscriptionDetails);
  yield takeLatest(profileActions.getUserLimit.type, getUserLimit);
}


