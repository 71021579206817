import {
  DossierLite,
  DossierOrganizaiton,
  DossierOrganizaitonLite,
  DossierUpdateRequest,
  HttpErrorResponse,
  ProcessDossierRequest,
  ResultListLite,
} from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const DOSSIER_ORG_STORE_NAME = 'DossierOrg Store';

const createPayloadAction = getCreatePayloadActionFromStore(DOSSIER_ORG_STORE_NAME);
const createAction = getCreateActionFromStore(DOSSIER_ORG_STORE_NAME);

const getDefaultDossierOrg = createAction('Get Default DossierOrg');
const getDefaultDossierOrgSuccess = createPayloadAction<DossierOrganizaiton>('Get Default Success');
const getDefaultDossierOrgFailure = createPayloadAction<HttpErrorResponse>('Get Default Failure');

const processDossierOrg = createPayloadAction<ProcessDossierRequest>('Process DossierOrgs');

const processDossierOrgEnabled = createPayloadAction<boolean>('Process DossierOrgs Enabled');
const processDossierOrgSuccess = createPayloadAction<DossierOrganizaiton>('Process Success');
const processDossierOrgFailure = createPayloadAction<HttpErrorResponse>('Process Failure');

const getDossierOrgById = createPayloadAction<string>('Get DossierOrg By Id');
const getDossierOrgByIdSuccess = createPayloadAction<DossierOrganizaiton>('get DossierOrg By Id Success');
const getDossierOrgByIdFailure = createPayloadAction<HttpErrorResponse>('get DossierOrg By Id Failure');

const getUserDossierOrgsList = createPayloadAction<number>('Get User DossierOrgs List');
const getUserDossierOrgsListSuccess = createPayloadAction<ResultListLite<DossierLite>>(
  'Get User DossierOrgs List Success',
);
const getUserDossierOrgsListFailure = createPayloadAction<HttpErrorResponse>('Get User DossierOrgs List Failure');

const setDossierOrgFavorite = createPayloadAction<DossierUpdateRequest>('Set DossierOrg Favorite');
const setDossierOrgFavoriteSuccess = createPayloadAction<DossierOrganizaiton>('Set DossierOrg Favorite Success');
const setDossierOrgFavoriteFailure = createPayloadAction<HttpErrorResponse>('Set DossierOrg Favorite Failure');
const toggleFavoriteDossierOrgs = createPayloadAction<boolean>('Toggle Favorite DossierOrgs');

const clearDossierHistory = createAction('Clear dossier organization history');

const clearDossierOrgHistorySuccess = createAction('Clear dossier organization history success');

const clearDossierOrgPersonHistoryFailure = createAction('Clear dossier organization history failure');

const deleteDossierOrg = createPayloadAction<string>('Delete DossierOrg');
const deleteDossierOrgSuccess = createPayloadAction<string>('Delete DossierOrg Success');
const deleteDossierOrgFailure = createPayloadAction<HttpErrorResponse>('Delete DossierOrg Failure');

export const DossierOrgActions = {
  processDossierOrg,
  processDossierOrgSuccess,
  processDossierOrgEnabled,
  processDossierOrgFailure,
  getUserDossierOrgsList,
  getUserDossierOrgsListSuccess,
  getUserDossierOrgsListFailure,
  getDefaultDossierOrg,
  getDefaultDossierOrgSuccess,
  getDefaultDossierOrgFailure,
  getDossierOrgById,
  getDossierOrgByIdSuccess,
  getDossierOrgByIdFailure,
  setDossierOrgFavorite,
  setDossierOrgFavoriteSuccess,
  setDossierOrgFavoriteFailure,
  toggleFavoriteDossierOrgs,
  clearDossierOrgHistorySuccess,
  clearDossierHistory,
  clearDossierOrgPersonHistoryFailure,
  deleteDossierOrg,
  deleteDossierOrgSuccess,
  deleteDossierOrgFailure,
};
