interface Props {
  message: string;
  action: string;
  onAction?: () => void;
}

export const MessageWithAction = ({ message, action, onAction }: Props) => {
  return (
    <div className="flex items-center justify-between w-full mt-4 text-gray-400 font-sansInter text-lg">
      <span>{message}</span>
      <a className="ml-1  text-primary-blue cursor-pointer font-bold text-lg" onClick={onAction}>
        {action}
      </a>
    </div>
  );
};
