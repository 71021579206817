import React from 'react';
import Modal from './Modal';
import { Divider } from '../Divider/Divider';
import { MainButton } from '../MainButton';

interface ConfirmationPopupProps {
  isOpen: boolean;
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  isOpen,
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) => {

  return (
   
    <Modal isShown={isOpen} animation="fade" centered withBackdrop>
     <div className="border rounded-md bg-white border-gray-100 border-solid shadow-md  w-400">
      <div className="w-full ">
      <div className="flex items-center justify-between px-4 pt-2 border-b rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {title}
                </h3>
                <button
                  onClick={onCancel}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="crud-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                
       </div>
       <Divider/>
      
        <div className="p-3">
          <h2 className="text-2xl font-bold mb-4"></h2>
          <p className="text-gray-700 mb-6">{message}</p>
          <div className="flex justify-end">
            <button
              className="px-4  mr-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={onCancel}
            >
              {cancelText}
            </button>
            <MainButton
                    onClick={onConfirm}
                    className=" w-100 px-4  "
                    stateProp="rest"
                    text= {confirmText}
                  />
          </div>
        </div>
      </div>
     </div>
    </Modal>
  );
};

export default ConfirmationPopup;