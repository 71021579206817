import { useState, useEffect } from 'react';
import { ModulesListEnum } from '../../common/constants/default-values';
import { EditIcon, EyeIcon } from '../../common/icons/Icons';
import { User } from '../../common/models/user/user';
import { ActionItem } from '../ActionItem/ActionItem';
import { UserEditModal } from '../Modal/UserEditModal/UserEditModal';
import { useSelector } from 'react-redux';
import { recentActivity } from '../../redux/selectors/dashboard.selectors';
import { useNavigate } from 'react-router-dom';
import { getDetailsRouterFromModule } from '../../common/helpers/Helper';

interface RecentActivityProps {}
export const RecentActivity: React.FC<any> = ({}) => {
  const recentActivityData = useSelector(recentActivity);
  const [searchText, setSearchText] = useState('');
  const [activityList, setActivityList] = useState(recentActivityData);
  const [filteredActivity, setFilteredActivity] = useState(activityList);
  const navigate = useNavigate();
  useEffect(() => {
    setActivityList(recentActivityData);
    setFilteredActivity(recentActivityData);
  }, [recentActivityData]);
  const handleSearch = (text: string) => {
    if (text.length >= 3) {
      setFilteredActivity(
        activityList.filter(
          (user) =>
            user.moduleName.toLowerCase().includes(text.toLowerCase()) ||
            user.name.toLowerCase().includes(text.toLowerCase()),
        ),
      );
    } else {
      setFilteredActivity(activityList);
    }
  };

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);
  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 bg-white dark:bg-gray-900 pt-2">
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div>
            <p className="text-sm font-extrabold text-bg-light-dark mx-5">
              Recent Activity <span className="text-xs font-normal text-gray-500"> ( Last 10 Requests.. )</span>
            </p>
          </div>
          <div className="relative m-5">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for activity"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Module
              </th>
              <th scope="col" className="px-6 py-3">
                Search Text
              </th>
              <th scope="col" className="px-6 py-3">
                Requested On
              </th>
              <th scope="col" className="px-6 py-3">
                Progress
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredActivity?.length > 0 ? (
              filteredActivity?.map((data, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th
                    scope="row"
                    className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div className="ps-3">
                      <div className="font-semibold text-gray-500">{data.moduleName}</div>
                    </div>
                  </th>
                  <td className="px-6 py-4 font-normal">{data.name.substring(0, 15)}</td>
                  <td className="px-6 py-4">
                    <div className="flex items-center font-normal">
                      <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>
                      {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(
                        new Date(data.date.replace(/-/g, '/')),
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="w-full h-4 bg-gray-200 rounded-full">
                      <div
                        className="h-full text-center text-xs text-white bg-gradient-to-r from-progress-bar-start to-progress-bar-end rounded-full"
                        style={{ width: `${data.progress}%` }}
                      >
                        {data.progress}%
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <ActionItem
                      key={index}
                      icon={<></>}
                      text={'View'}
                      onClick={() => {
                        navigate(`${getDetailsRouterFromModule(data.moduleName)}/${data.id}`);
                      }}
                    />

                    {/* <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit user</a> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="text-center py-4">
                  No users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
