import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  externalSearchLightList,
  externalSearchPagination,
  isCloningExternalSearch,
  isValidated,
  refreshExternalSearchSourceList,
  showExternalSearchSourceList,
} from '../../redux/selectors/external-search.selectors';
import InfiniteScroll from 'react-infinite-scroll-component';
import { externalSearchActions } from '../../redux/actions/external-search.action';
import { ArticleIcon, IconsSideNavClose } from '../../common/icons/Icons';
import { RoutePath } from '../../common/constants/route-path';
import { useNavigate } from 'react-router-dom';

const ExternalSearchSourceList = () => {
  const navigate = useNavigate();
  const [showList, setShowList] = useState(true);
  const [hasMounted, setHasMounted] = useState(false);
  const toggleExternalSearch = useSelector(showExternalSearchSourceList);
  const paginationConfig = useSelector(externalSearchPagination);
  const dispatch = useDispatch();
  const externalSearchLightSources = useSelector(externalSearchLightList);
  const isValidatedExternalApi: boolean = useSelector(isValidated);
  const refreshExternalSearchSource: boolean = useSelector(refreshExternalSearchSourceList);
  const handleIconClick = () => {
    dispatch(externalSearchActions.toggleExternalSearchSourceList(false));
  };

  useEffect(() => {
    if (hasMounted && !toggleExternalSearch) {
      const timeoutId = setTimeout(() => {
        setShowList(false);
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      setHasMounted(true);
    }
  }, [toggleExternalSearch, hasMounted]);

  useEffect(() => {
    if (toggleExternalSearch) {
      setShowList(true);
    }
  }, [toggleExternalSearch]);

  useEffect(() => {
    if (refreshExternalSearchSource) {
      externalSearchActions.getExternalSearchSourceLightList(paginationConfig.page);
      // setShowList(false);
    }
  }, [refreshExternalSearchSource]);

  return (
    <>
      {showList && (
        <div
          className={`flex-1 max-w-350 p-5 pt-8 user-favoritie-bg relative duration-100 mt-20 border-r-2 rounded-r-lg border-dark-purple border-opacity-20  z-10 transform animate__animated  ${
            toggleExternalSearch ? 'animate__slideInLeft' : 'animate__slideOutLeft'
          } `}
        >
          <div className="flex justify-between ">
            <div className="flex  items-center">
              <span onClick={handleIconClick}>
                <ArticleIcon />
              </span>
              <span className="mx-2 text-white font-sans">ExternalSourceList</span>
            </div>
            <span onClick={handleIconClick}>
              <IconsSideNavClose />
            </span>
          </div>
          <div className="result-max-height overflow-scroll app-scroll-bar mt-6">
            <InfiniteScroll
              dataLength={paginationConfig.itemCount}
              next={() => {
                dispatch(externalSearchActions.getExternalSearchSourceLightList(paginationConfig.page + 1));
              }}
              hasMore={paginationConfig.hasNextPage} // Replace with a condition based on your data source
              loader={
                externalSearchLightSources.length < paginationConfig.itemCount ? (
                  <div className="text-center text-white w-full mt-2 text-sm">Loading more...</div>
                ) : (
                  <div className="text-center text-white w-full mt-2 text-sm">No more records to load.</div>
                )
              }
              endMessage={
                <div className="text-center text-white w-full mt-2 text-sm">
                  No more external search sources to load.
                </div>
              }
            >
              {externalSearchLightSources?.map((collection) => (
                <li
                  key={`external-search${collection.id}`}
                  className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-dark text-gray-300 text-sm items-center gap-x-4 bg-dark  mt-5 mb-5`}
                  onClick={() => {
                    navigate(`${RoutePath.externalSearchSource}/` + collection.id);
                  }}
                >
                  <div className="flex flex-col">
                    <span className="text-sky-400 text-lg">{collection.name}</span>
                    <span className="text-xs">{collection.createdBy}</span>
                    <span className="text-xs">{collection.createdAt}</span>
                  </div>
                </li>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </>
  );
};

export default ExternalSearchSourceList;
