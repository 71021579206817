import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';
import { UserPreferenceRequest } from '../../common/services/user-preferences/models/user-preference-request';
import {
  DashboardMatericsRequestByDateRange,
  ModuleMetrics,
} from '../../common/services/dashboard/models/dashboard.model';
import {
  HttpErrorResponse,
  LLMRequest,
  LLMResultLite,
  PromptProtect,
  AssertionResult,
  ArticleUpdateRequest,
  ResultListLite,
  UserHistoryList,
  LLMFactCheckerRequest,
  PromptOptimzerRequest,
  PromptOptimizerLiteRequest,
  PromptOptimizerLLMsRequest,
} from '../../types/models';
import {
  LLMResponse,
  LLMResponseWithoutHistory,
  PromptOptimizerLiteResponse,
  PromptOptimzerResponse,
} from '../../common/services/llm/model/llm-response.model';

export const FEDERATED_STORE_NAME = 'GuardRail Federated';

const createPayloadAction = getCreatePayloadActionFromStore(FEDERATED_STORE_NAME);
const createAction = getCreateActionFromStore(FEDERATED_STORE_NAME);
const processPrompt = createPayloadAction<LLMRequest>('Process User Prompt');
const processPromptSuccess = createPayloadAction<LLMResponse>('Process User Prompt Success');
const processPromptFailure = createPayloadAction('Process User Prompt Failure');
const getPromptsList = createPayloadAction('Get Prompts List');

const getPromptById = createPayloadAction<string>('Get Prompt By Id');
const getPromptByIdSuccess = createPayloadAction<LLMResponse>('get Prompt By Id Success');
const getPromptByIdFailure = createPayloadAction<HttpErrorResponse>('get Prompt By Id Failure');

const getUserPromptsList = createPayloadAction<number>('Get User Prompts List');
const getUserPromptsListSuccess = createPayloadAction<ResultListLite<UserHistoryList>>('Get User Prompts List Success');
const getUserPromptsListFailure = createPayloadAction<HttpErrorResponse>('Get User Prompts List Failure');

const setPromptFavorite = createPayloadAction<ArticleUpdateRequest>('Set Prompt Favorite');
const setPromptFavoriteSuccess = createPayloadAction<LLMResponse>('Set Prompt Favorite Success');
const setPromptFavoriteFailure = createPayloadAction<HttpErrorResponse>('Set Prompt Favorite Failure');

const deletePrompt = createPayloadAction<string>('Delete Prompt');
const deletePromptSuccess = createPayloadAction<string>('Delete Prompt Success');
const deletePromptFailure = createPayloadAction<HttpErrorResponse>('Delete Prompt Failure');

const setPromptText = createPayloadAction<string>('Set Prompt Text');
const processPromptProtect = createPayloadAction<string>('Process Prompts');
const processPromptProtectEnabled = createPayloadAction<boolean>('Process Prompts Enabled');
const processPromptProtectSuccess = createPayloadAction<PromptProtect>('Process prompt Success');
const processPromptProtectFailure = createPayloadAction<HttpErrorResponse>('Prompt Protect Failure');
const processArticle = createPayloadAction<LLMFactCheckerRequest>('Process Articles');
const processArticleReset = createAction('rest process article');

const toggleRequestSubmitted = createPayloadAction<boolean>('Togggle Request Submitted');

const processArticleEnabled = createPayloadAction<boolean>('Process Articles Enabled');
const processArticleSuccess = createPayloadAction<AssertionResult>('Process Success');
const processArticleFailure = createPayloadAction<HttpErrorResponse>('Process Failure');

const getArticleById = createPayloadAction<string>('Get Article By Id');
const getArticleByIdSuccess = createPayloadAction<AssertionResult>('get Article By Id Success');
const getArticleByIdFailure = createPayloadAction<HttpErrorResponse>('get Article By Id Failure');
const togglePromptProtectModal = createPayloadAction<boolean>('Toggle Prompt Protect Modal');
const toggleFavoritePrompt = createPayloadAction<boolean>('Toggle Favorites ');
const setFactCheckerText = createPayloadAction<LLMFactCheckerRequest>('Set fact checker text ');
const setPromptContent = createPayloadAction<string>('Edit Prompts');
const clearFederatedHistory = createAction('Clear federated history');

const processPromptOptimiser = createPayloadAction<PromptOptimzerRequest>('Process Prompt Optimizer');
const processPromptOptimiserSuccess = createPayloadAction<PromptOptimzerResponse>('Process Prompt Optimizer Success');
const processPromptOptimiserFailure = createPayloadAction<HttpErrorResponse>('Process Prompt Optimizer Failure');
const togglePromptOptimiserModel = createPayloadAction<boolean>('Toggle Prompt Optimiser Modal');
const promptOptimizerLLMSuccess = createPayloadAction<LLMResponseWithoutHistory>('Prompt Optimizer LLM Success');

const processPromptOptimizerLite = createPayloadAction<PromptOptimizerLiteRequest>('Process Prompt Optimizer Lite');
const processPromptOptimizerLiteSuccess = createPayloadAction<PromptOptimizerLiteResponse>(
  'Process Prompt Optimizer Lite Success',
);
const processPromptOptimizerLiteFailure = createPayloadAction<HttpErrorResponse>(
  'Process Prompt Optimizer Lite Failure',
);

const processPromptOptimizerLLM = createPayloadAction<PromptOptimizerLLMsRequest>('Process Prompt Optimizer LLM');
const processPromptIptimizerLLMInitialPromptSuccess = createPayloadAction<LLMResponseWithoutHistory>(
  'Process Prompt Optimizer LLM Initial Prompt Success',
);
const processPromptOptimizerLLMInitialPromptFailure = createPayloadAction<HttpErrorResponse>(
  'Process Prompt Optimizer LLM Initial Prompt Failure',
);
const processPromptIptimizerLLMOptimizedPromptSuccess = createPayloadAction<LLMResponseWithoutHistory>(
  'Process Prompt Optimizer LLM Optimized Prompt Success',
);
const processPromptOptimizerLLMOptimizedPromptFailure = createPayloadAction<HttpErrorResponse>(
  'Process Prompt Optimizer LLM Optimized Prompt Failure',
);

const clearFederatedHistorySuccess = createAction('Clear dossier Federated history success');

const clearFederatedPersonHistoryFailure = createAction('Clear dossier Federated history failure');

export const federatedActions = {
  processPrompt,
  toggleRequestSubmitted,
  setFactCheckerText,
  processPromptSuccess,
  processPromptFailure,
  getPromptsList,
  getUserPromptsList,
  getUserPromptsListSuccess,
  getUserPromptsListFailure,
  getPromptById,
  getPromptByIdSuccess,
  getPromptByIdFailure,
  setPromptFavorite,
  setPromptFavoriteSuccess,
  setPromptFavoriteFailure,
  deletePrompt,
  deletePromptSuccess,
  deletePromptFailure,
  setPromptText,
  processPromptProtect,
  processPromptProtectEnabled,
  processPromptProtectSuccess,
  processPromptProtectFailure,
  processArticleEnabled,
  processArticleSuccess,
  processArticleFailure,
  getArticleById,
  processArticle,
  getArticleByIdSuccess,
  getArticleByIdFailure,
  togglePromptProtectModal,
  toggleFavoritePrompt,
  setPromptContent,
  clearFederatedHistory,
  clearFederatedHistorySuccess,
  clearFederatedPersonHistoryFailure,
  processArticleReset,
  processPromptOptimiser,
  processPromptOptimiserSuccess,
  processPromptOptimiserFailure,
  togglePromptOptimiserModel,
  promptOptimizerLLMSuccess,
  processPromptOptimizerLite,
  processPromptOptimizerLiteSuccess,
  processPromptOptimizerLiteFailure,
  processPromptOptimizerLLM,
  processPromptIptimizerLLMInitialPromptSuccess,
  processPromptIptimizerLLMOptimizedPromptSuccess,
  processPromptOptimizerLLMInitialPromptFailure,
  processPromptOptimizerLLMOptimizedPromptFailure,
};
