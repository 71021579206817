import React from 'react';

interface IconProps {
    width?: number;
    height?: number;
    icon:any;
}

const Icon: React.FC<IconProps> = ({ width = 24, height = 24 , icon}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24">
            {icon}
        </svg>
    );
};

export default Icon;