import React, { useState, ChangeEvent } from 'react';

export interface ExternalAppOption {
  key: string;
  value: string;
}

interface DropdownProps {
  options: ExternalAppOption[];
  defaultOption?: ExternalAppOption;
  onSelect: (selectedValue?: ExternalAppOption) => void;
}

const DropdownV2: React.FC<DropdownProps> = ({ options, defaultOption, onSelect }) => {
  const [externalAppOption, setExternalAppOption] = useState<string | undefined>(defaultOption?.value);
  //   const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
  //     const newValue = event.target.value;
  //     setExternalAppOption(newValue);
  //
  //     onSelect(options.find((a) => a.value == newValue));
  //   };

  const handleSelectChange = (externalAppOption: unknown) => {
    let option = externalAppOption as ChangeEvent<HTMLSelectElement>;
    onSelect(options.find((a) => a.value == option.target.value));
    setExternalAppOption(option.target.value);
  };

  return (
    <div className="relative inline-block text-left w-full">
      <select
        value={defaultOption?.value || externalAppOption}
        onChange={handleSelectChange}
        className="form-select w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
      >
        {defaultOption === undefined && (
          <option value="" disabled>
            Select an option
          </option>
        )}
        {options.map((option) => {
          return (
            <option key={option.key} value={option.value} selected={option.value === externalAppOption}>
              {option.value}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default DropdownV2;
