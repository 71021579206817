import { createSelector } from '@reduxjs/toolkit';
import { ArticleState, articleSlice } from '../slices/article.slice';
import { AppState } from '../types/App.type';
import { AppRenderState } from '../slices/appSlice';
import { ProfileState } from '../slices/profile.slice';

const selectActiveUsersState: (state: AppState) => AppRenderState = (state) => state.appRenderState;
const profileState: (state: AppState) => ProfileState = (state) => state.profile;
export const isLoggedInUser = createSelector(profileState, ({ isUserLoggedIn }) => isUserLoggedIn);
export const loggedInUser = createSelector(profileState, ({ user }) => user);
export const isGuestUser = createSelector(profileState, ({ isGuestAccount }) => isGuestAccount);
export const isMockUser = createSelector(profileState, ({ enableUserMock }) => enableUserMock);
export const showLoginPage = createSelector(profileState, ({ showLoginScreen }) => showLoginScreen);
export const maxResultsToShow = createSelector(selectActiveUsersState, ({ maxResultsToShow }) => maxResultsToShow);
export const maxSourceToShow = createSelector(selectActiveUsersState, ({ maxSourceToShow }) => maxSourceToShow);
