import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import './style.css';
import { MainBackground } from './MainBackground';
import { MainContent } from './MainContent';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { isUserLoggedIn } from '../../redux/selectors/profile.selectors';

export const Home = (): JSX.Element => {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      navigate(RoutePath.Landing);
    }
  }, [isLoggedIn]);
  return (
    <div className="landing">
      <div className="div">
        <Header className="header-instance" state="homescreen" />
        <div className="main-content flex min-h-[90vh] overflow-auto">
          <MainBackground>
            <MainContent />
          </MainBackground>
        </div>
        <></>
        <Footer
          className="footer-instance"
          group="https://generation-sessions.s3.amazonaws.com/992bca50d1a1be566686effdf99311ae/img/group-41-1@2x.png"
        />
      </div>
    </div>
  );
};
