import { FC } from 'react';
import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { isUserLoggedIn, showGuestOTPScreen } from '../../../redux/selectors/profile.selectors';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../common/constants/route-path';
import { PromptProtectCheck } from './PromptProtectCheck';
interface PropTypes {
  orgId?: string;
  isShown: boolean;
  onClose?: () => void;
}

export const PromptProtectModal: FC<PropTypes> = ({ isShown, onClose }) => {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div>
      <Modal isShown={isShown} animation="fade" centered onAfterClose={onClose} withBackdrop onBackdropClick={onClose}>
        <PromptProtectCheck />
      </Modal>
    </div>
  );
};
