import { User } from '../../common/models/user/user';
import { PermissionMapping, RoleDetails, UpdateUserPermissionsRequest } from '../../common/services/roles/models/roles';
import { PutUserRequest } from '../../common/services/users/models/put-user-request';
import { UserQueryParams } from '../../common/services/users/models/user-query-params';
import { UserResponse } from '../../common/services/users/models/user-response';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const ACCESS_MANAGEMENT_STORE_NAME = 'Access Management';

const createPayloadAction = getCreatePayloadActionFromStore(ACCESS_MANAGEMENT_STORE_NAME);
const createAction = getCreateActionFromStore(ACCESS_MANAGEMENT_STORE_NAME);

const getApplicationPermissions = createAction('Get Application Permissions');
const getApplicationPermissionsSuccess = createPayloadAction<string[]>('Get Application Permissions Success');
const getApplicationPermissionsFailure = createAction('Get Application Permissions Failure');

const getApplicationRoles = createAction('Get Application Roles');
const getApplicationRolesSuccess = createPayloadAction<RoleDetails[]>('Get Application Roles Success');
const getApplicationRolesFailure = createAction('Get Application Roles Failure');

const getApplicationPermissionsMappings = createAction('Get Application Permission Mappings');

const getApplicationPermissionsMappingsSuccess = createPayloadAction<PermissionMapping[]>(
  'Get Application Permission Mappings Success',
);
const getApplicationPermissionsMappingsFailure = createAction('Get Application Permission Mappings Failure');

const getUsers = createPayloadAction<UserQueryParams | undefined>('Get Users List');
const getUsersSuccess = createPayloadAction<UserResponse>('Get Users List Success');
const getUsersFailure = createPayloadAction('Get Users List Failure');

const updateUser = createPayloadAction<PutUserRequest>('Update User');

const updateUserSuccess = createPayloadAction<User>('Update User Success');

const updateUserFailure = createAction('Update User Failure');

const updateRolePermissions = createPayloadAction<UpdateUserPermissionsRequest>('Update User Permissions');

const updateRolePermissionsSuccess = createAction('Update User Permissions Success');

const updateRolePermissionsFailure = createAction('Update User Permissions Failure');

export const accessManagementActions = {
  getApplicationPermissions,
  getApplicationPermissionsSuccess,
  getApplicationPermissionsFailure,
  getApplicationRoles,
  getApplicationRolesSuccess,
  getApplicationRolesFailure,
  getApplicationPermissionsMappings,
  getApplicationPermissionsMappingsSuccess,
  getApplicationPermissionsMappingsFailure,
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  updateRolePermissions,
  updateRolePermissionsSuccess,
  updateRolePermissionsFailure,
};
