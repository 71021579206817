import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseButton from '../../CloseButton/CloseButton';
import { MainButton } from '../../MainButton';
import Modal from '../Modal';
import { PrimaryButton } from '../../PrimaryButton';
import { UserInfo } from 'os';
import { UserResponse } from '../../../common/services/users/models/user-response';
import { User } from '../../../common/models/user/user';
import { applicationRoles, isUserUpdating } from '../../../redux/selectors/access-management.selectors';
import { Validators } from '../../../common/helpers/form-controls/validators';
import { useForm } from '../../../common/helpers/form-controls/use-form';
import Input from '../../Input';
import { accessManagementActions } from '../../../redux/actions/access-management.actions';
import { PutUserRequest } from '../../../common/services/users/models/put-user-request';
import { isEmpty } from '../../../common/helpers/objects/is-empty';

interface PropTypes {
  isShown: boolean;
  userInfo?: User;
  isCreate?: boolean;
  onClose: () => void;
  onSaveClick: () => void;
}

export const UserEditModal: FC<PropTypes> = ({ isShown, onClose, onSaveClick, userInfo, isCreate = false }) => {
  const userRoles = useSelector(applicationRoles);
  const isUpdatingUser = useSelector(isUserUpdating);
  const [processingReqeust, setProcessingRequest] = useState(false);
  const dispatch = useDispatch();
  const { handleSubmit, handleChange, data, errors, initialize } = useForm<User>({
    initialValues: userInfo,
    validations: {
      firstName: [
        {
          validator: Validators.required,
          message: 'First name is required',
        },
      ],
      lastName: [
        {
          validator: Validators.required,
          message: 'Last name is required',
        },
      ],
      email: [
        {
          validator: Validators.email,
          message: 'Invalid email format',
        },
      ],
      role: [
        {
          validator: Validators.required,
          message: 'Select user role',
        },
      ],
    },
    onSubmit: () => {
      let updateuserRequest = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        roleId: userRoles.find((x) => x.id === data.role)?.id ?? '',
        id: userInfo?.id ?? '',
      } as PutUserRequest;
      console.log('user Update request', updateuserRequest);
      dispatch(accessManagementActions.updateUser(updateuserRequest));
      setProcessingRequest(true);
    },
  });
  useEffect(() => {
    if (userInfo) {
      initialize(userInfo);
    }
  }, [userInfo]);

  useEffect(() => {
    if (processingReqeust && !isUpdatingUser) {
      onClose();
    }
  }, [isUpdatingUser]);

  return (
    <div>
      <Modal isShown={isShown} animation="fade" centered withBackdrop>
        <div id="crud-modal" className=" items-center w-full md:inset-0 h-full">
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {isCreate ? 'Create User' : 'Edit User'}
                </h3>
                <button
                  onClick={onClose}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="crud-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-2">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="user-first-name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      First Name
                    </label>
                    <Input
                      id="first-name"
                      className="w-55  py-2 "
                      type="text"
                      size="sm"
                      disabled={false}
                      placeholder="First name"
                      value={data.firstName}
                      error={!!errors.firstName}
                      onChange={handleChange('firstName')}
                    />
                    {errors.firstName && (
                      <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="user-last-name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Last Name
                    </label>
                    <Input
                      id="last-name"
                      className="w-55  py-2 "
                      type="text"
                      size="sm"
                      disabled={false}
                      placeholder="Last name"
                      value={data.lastName}
                      error={!!errors.lastName}
                      onChange={handleChange('lastName')}
                    />
                    {errors.lastName && (
                      <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">
                        {errors.lastName}
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 ">
                    <label
                      htmlFor="user-email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <Input
                      id="email"
                      className="w-full  py-2 "
                      type="text"
                      size="sm"
                      disabled={false}
                      placeholder="Email address"
                      value={data.email}
                      error={!!errors.email}
                      onChange={handleChange('email')}
                    />
                    {errors.email && (
                      <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.email}</div>
                    )}
                  </div>
                  <div className="col-span-2 ">
                    <label htmlFor="roles" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Role
                    </label>
                    <select
                      onChange={handleChange('role')}
                      id="roles"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    >
                      {userRoles.map((role, index) => (
                        <option selected={data.role === role.name} key={index} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <MainButton
                    isLoading={isUpdatingUser}
                    disabled={!isEmpty(errors) || !data.firstName || !data.lastName || !data.email}
                    onClick={() => handleSubmit()}
                    className="w-full w-100 px-4  mt-2"
                    stateProp="rest"
                    text="Update User"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
