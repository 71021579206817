import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../PrimaryButton';
import './style.css';
import { userDetails } from '../../redux/selectors/profile.selectors';
import { userPreferencesActions } from '../../redux/actions/user-preference.actions';
import {
  USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION,
  USER_PREFERENCE_FONTSIZE,
  USER_PREFERENCE_ACCESSIBILITY_SETTINGS,
  USER_PREFERENCE_EMAIL_NOTIFICATIONS,
  USER_PREFERENCE_IN_APP_NOTIFICATIONS,
  USER_PREFERENCE_NEWS_LETTER,
  USER_PREFERENCE_PUSH_NOTIFICATIONS,
  USER_PREFERENCE_NO_OF_RECORDS,
  USER_PREFERENCE_CACHED_RESULTS,
  USER_PREFERENCE_ARCHIEVE_HISTORY,
  USER_PREFERENCE_DEFAULT_LLM,
} from '../../common/constants/app-text';
import { preferenceDetails, userPreferences } from '../../redux/selectors/user-preference.selectors';
import { domainDetails } from '../../redux/selectors/profile.selectors';
import { EditIcon } from '../../common/icons/Icons';
import { profileActions } from '../../redux/actions/profile.actions';

function ProfileSettings() {
  const dispatch = useDispatch();
  const data = useSelector(userDetails);
  const [editedFirstName, setEditedFirstName] = useState(data.firstName);
  const [editedLastName, setEditedLastName] = useState(data.lastName);
  const [lightColorActive, setLightColorActive] = useState<boolean>(true);
  const preference = useSelector(preferenceDetails);
  const domain = useSelector(domainDetails);
  const userpreference = useSelector(userPreferences);
  const [editstate, setEditState] = useState(true);
  const [profilePicture, setProfilePicture] = useState<string | null>(data.imageUrl);
  const [editimage, seteditimage] = useState<File | undefined>(undefined);

  const [editedPreferences, setEditedPreferences] = useState({
    twoFactorAuthentication: userpreference.twoFactorAuthentication,
    accessibilitySettings: userpreference.accessibilitySettings,
    fontSize: userpreference.fontSize,
  });

  useEffect(() => {
    setEditedFirstName(data.firstName);
    setEditedLastName(data.lastName);
    setEditedPreferences({
      twoFactorAuthentication: userpreference.twoFactorAuthentication,
      accessibilitySettings: userpreference.accessibilitySettings,
      fontSize: userpreference.fontSize,
    });
  }, [userpreference]);

  useEffect(() => {
    setProfilePicture(data.imageUrl);
  }, [data.imageUrl]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      seteditimage(file);
    }
  };

  const onSaveChange = () => {
    dispatch(
      profileActions.updateProfile({
        firstName: editedFirstName,
        lastName: editedLastName,
        profilePicture: editimage,
      }),
    );
    const updatedPreferences = {
      id: preference?.id ?? '',
      domainId: domain?.id || '',
      language: preference?.language ?? 'en',
      timeZone: preference?.timeZone ?? 'pst',
      preferenceFlagsDto: [
        { name: USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION, value: editedPreferences.twoFactorAuthentication },
        { name: USER_PREFERENCE_FONTSIZE, value: editedPreferences.fontSize },
        { name: USER_PREFERENCE_ACCESSIBILITY_SETTINGS, value: editedPreferences.accessibilitySettings },
        { name: USER_PREFERENCE_PUSH_NOTIFICATIONS, value: userpreference.pushNotifications },
        { name: USER_PREFERENCE_EMAIL_NOTIFICATIONS, value: userpreference.emailNotifications },
        { name: USER_PREFERENCE_IN_APP_NOTIFICATIONS, value: userpreference.inAppNotifications },
        { name: USER_PREFERENCE_NEWS_LETTER, value: userpreference.newsletter },
        { name: USER_PREFERENCE_NO_OF_RECORDS, value: userpreference.numberofRecords },
        { name: USER_PREFERENCE_CACHED_RESULTS, value: userpreference.cacheResults },
        { name: USER_PREFERENCE_ARCHIEVE_HISTORY, value: userpreference.archieveHistory },
        { name: USER_PREFERENCE_DEFAULT_LLM, value: userpreference.defaultLLM },
      ],
    };
    dispatch(userPreferencesActions.updateUserPreferences(updatedPreferences));
  };

  const handleDiscardChanges = () => {
    setEditedFirstName(data.firstName);
    setEditedLastName(data.lastName);
    setEditedPreferences({
      twoFactorAuthentication: userpreference.twoFactorAuthentication,
      accessibilitySettings: userpreference.accessibilitySettings,
      fontSize: userpreference.fontSize,
    });
  };

  return (
    <div className="flex-1 bg-white ">
      <div className="flex items-center  justify-center p-2 text-gray-900 rounded-lg dark:text-white">
        <h3 className="text-2xl leading-6 font-medium mb-4 mt-10">Profile Details</h3>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className="flex items-center flex-col relative">
          <div className="flex-shrink-0 mb-2 relative">
            <img
              className="h-24 w-24 rounded-full"
              src={
                editimage
                  ? URL.createObjectURL(editimage)
                  : profilePicture
                  ? profilePicture
                  : `https://ui-avatars.com/api/?name=${editedFirstName}+${editedLastName}&size=200`
              }
              alt="Profile"
            />
            <label htmlFor="fileInput" className="absolute right-0 top-0 mt-3 text-gray-500 cursor-pointer">
              <EditIcon />
            </label>
            <input type="file" id="fileInput" className="hidden" onChange={handleImageChange} />
          </div>
          <div className="ml-8">
            <div className="text-lg font-medium text-gray-700 mb-8">Avatar(png, jpg, jpeg)</div>
            <div className="text-base text-gray-500 mb-4"></div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-y-6 gap-x-4">
          <div className="col-span-1">
            <label htmlFor="full-name" className="block text-lg font-medium text-gray-700 mb-4">
              Full Name:
            </label>
          </div>
          <div className="col-span-2 relative">
            <div className="flex">
              <div className="relative flex-1">
                <input
                  type="text"
                  id="first-name"
                  value={editedFirstName}
                  onChange={(e) => setEditedFirstName(e.target.value)}
                  className="py-3 px-4 w-3/4 border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-blue-300 dark:text-gray-400 dark:focus:ring-gray-600"
                  placeholder="Enter first name"
                  disabled={editstate}
                />
              </div>
              <div className="relative flex-1 mr-4">
                <input
                  type="text"
                  id="last-name"
                  value={editedLastName}
                  onChange={(e) => setEditedLastName(e.target.value)}
                  className="py-3 px-4 w-3/4 border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-blue-300 dark:text-gray-400 dark:focus:ring-gray-600"
                  placeholder="Enter Last name"
                  disabled={editstate}
                />
              </div>
              <span
                className="absolute right-5 top-0 mt-3 mr-4 text-gray-500 cursor-pointer"
                onClick={() => setEditState(!editstate)}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="col-span-1">
            <label htmlFor="email" className="block text-lg font-medium text-gray-700 mb-2">
              Email:
            </label>
          </div>
          <div className="col-span-2">
            <div className="text-base text-gray-500 mb-8">{data.email}</div>
          </div>

          {/* <div className="col-span-1 flex items-center">
            <label htmlFor="two-factor-auth" className="block text-lg font-medium text-gray-700 mb-8">
              Two-Factor Auth:
            </label>
          </div> 
           <div className="col-span-2 flex items-center">
            <div className="switch-container">
              <input
                type="checkbox"
                id="two-factor-auth"
                className="switch-checkbox"
                checked={editedPreferences.twoFactorAuthentication}
                onChange={(e) =>
                  setEditedPreferences((prev) => ({ ...prev, twoFactorAuthentication: e.target.checked }))
                }
              />
              <label htmlFor="two-factor-auth" className="switch-label">
                <div className="slider"></div>
              </label>
            </div>
            <div className="ml-2"></div>
          </div>
          <div className="col-span-1">
            <label htmlFor="font-size" className="block text-lg font-medium text-gray-700 mb-8">
              Font Size:
            </label>
          </div>
          <div className="col-span-2">
            <div className="font-size-container">
              <div
                className={`font-segment ${editedPreferences.fontSize === 'small' ? 'active' : ''}`}
                onClick={() => setEditedPreferences((prev) => ({ ...prev, fontSize: 'small' }))}
              >
                Small
              </div>
              <div
                className={`font-segment ${editedPreferences.fontSize === 'medium' ? 'active' : ''}`}
                onClick={() => setEditedPreferences((prev) => ({ ...prev, fontSize: 'medium' }))}
              >
                Medium
              </div>
              <div
                className={`font-segment ${editedPreferences.fontSize === 'large' ? 'active' : ''}`}
                onClick={() => setEditedPreferences((prev) => ({ ...prev, fontSize: 'large' }))}
              >
                Large
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <label htmlFor="color-schema" className="block text-lg font-medium text-gray-700 mb-8">
              Color Schema:
            </label>
          </div>
          <div className="col-span-2">
            <div className="color-schema-container">
              <div
                className={`color-schema-segment light ${lightColorActive ? 'active' : ''}`}
                onClick={() => setLightColorActive(true)}
              >
                Light
              </div>
              <div
                className={`color-schema-segment dark ${!lightColorActive ? 'active' : ''}`}
                onClick={() => setLightColorActive(false)}
              >
                Dark
              </div>
            </div>
          </div>
          <div className="col-span-1 flex items-center">
            <label htmlFor="accessibility-features" className="block text-lg font-medium text-gray-700 mb-4">
              Accessibility Features:
            </label>
          </div>
          <div className="col-span-2 flex items-center">
            <div className="switch-container">
              <input
                type="checkbox"
                id="accessibility-features"
                className="switch-checkbox"
                checked={editedPreferences.accessibilitySettings}
                onChange={(e) => setEditedPreferences((prev) => ({ ...prev, accessibilitySettings: e.target.checked }))}
              />
              <label htmlFor="accessibility-features" className="switch-label">
                <div className="slider"></div>
              </label>
            </div>
            <div className="ml-2"></div>
          </div> */}
        </div>
        <div className="flex justify-end mt-8">
          <PrimaryButton
            className="mx-2 bg-gradient-to-tl from-blue-500 to-violet-500"
            size="small"
            stateProp="rest"
            style="boxed"
            text="Save Changes"
            onClick={onSaveChange}
          />
          <PrimaryButton
            onClick={handleDiscardChanges}
            className=""
            size="small"
            stateProp="rest"
            style="label-bold"
            text="Discard"
          />
        </div>
      </div>
    </div>
  );
}

export default ProfileSettings;
