import { GuardRailLogoSVG } from "../../common/icons/Icons";
import Icon from "../SvgIcon/svgIcon";


export const LoadingIndicator: React.FC<{message?:any}> = ({ message="" }) => {
    return (<div className="z-[999] fixed inset-0 flex justify-center items-center bg-white bg-opacity-70 z-50">
    <div className=" p-4 rounded-md ">
      <div className="flex items-center  animate-bounce ">
        <span className="text-2xl mr-2"> <Icon width={120} height={120} icon={<GuardRailLogoSVG/>} />  </span>
       
      </div>
      { message && <div className="w-full h-full border-4 border-gray-300 rounded-full border-t-4 border-t-indigo-500">{message}...</div>}
    </div>
  </div>);
  }