import { useEffect, useMemo, useState } from 'react';
import { CodeMirrorInput } from '../../components/CodeAnalysis/CodeMirrorInput';
import { Divider } from '../../components/Divider/Divider';
import { EDIT_TEXT, FAVORITE_TEXT, Generate_PDF } from '../../common/constants/app-text';
import { EditIcon, FavoriteIcon, FavoriteIconPink, ArrowDown, GeneratePDFIcon } from '../../common/icons/Icons';
import { PlugInResultActions } from '../../components/PlugInResultActions/PlugInResultActions';
import { PrimaryButton } from '../../components/PrimaryButton';
import { Dropdown, Tabs } from 'flowbite-react';
import Badge from '../../components/Base/Badge';
import { ResultIndicatorColors } from '../../common/constants/default-values';
import { RiskAssessmentResult } from '../../components/CodeAnalysis/RiskAssessmentResult';
import { RiskAssessmentResultView } from '../../types/models';
import { BillOfLadingResultView } from '../../components/CodeAnalysis/BillofLadingResultView';
import { CodeReferenceResultView } from '../../components/CodeAnalysis/CodeRefereanceResultView';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentCodeAnalysisSelector,
  riskAssessmentOutputResponseSelector,
} from '../../redux/selectors/codeAnalysis.selectors';
import { CodeAnalysisActions } from '../../redux/actions/codeAnalysis.actions';
import { useParams } from 'react-router';
import { RoutePath } from '../../common/constants/route-path';
import { useNavigate } from 'react-router-dom';
import AppLoader from '../../components/AppLoader/AppLoader';
import { mapRiskAssessmentToResultView } from '../../common/helpers/Helper';

interface CodeAnalysisResultViewProps {
  disableHideInput?: boolean;
}
export const CodeAnalysisResultView = ({ disableHideInput = false }: CodeAnalysisResultViewProps) => {
  const userInput = useSelector(currentCodeAnalysisSelector);
  const currentCodeAnalysis = useSelector(currentCodeAnalysisSelector);
  const riskAssessmentOutputResponse = useSelector(riskAssessmentOutputResponseSelector);
  const [isOpen, setIsOpen] = useState(true);
  const [code, setCode] = useState('');
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [assessmentDropdownText, setAssessmentDropdownText] = useState(
    `View all ${riskAssessmentOutputResponse?.length ?? 0} risks`,
  );
  const [filteredRiskAssessments, setFilteredRiskAssessments] = useState<RiskAssessmentResultView[]>([]);

  const mappedResults = useMemo(() => {
    if (riskAssessmentOutputResponse) {
      return mapRiskAssessmentToResultView(riskAssessmentOutputResponse);
    }
    return [];
  }, [riskAssessmentOutputResponse]);
  const failedRiskAssessments = useMemo(() => {
    return mappedResults.filter((riskAssessment) => riskAssessment?.risk_detected).length;
  }, [mappedResults]);

  const passedRiskAssessments = useMemo(() => {
    return mappedResults.filter((riskAssessment) => !riskAssessment?.risk_detected).length;
  }, [mappedResults]);

  useEffect(() => {
    setAssessmentDropdownText(`View all ${mappedResults.length} risks`);
    setFilteredRiskAssessments(mappedResults);
  }, [mappedResults]);

  useEffect(() => {
    if (userInput) {
      setCode(userInput.code);
    }
  }, [userInput]);

  useEffect(() => {
    if (id) {
      dispatch(CodeAnalysisActions.getCodeAnalysisById(id));
    } else {
      navigate(RoutePath.CodeAnalyzer);
    }
  }, [id]);
  useEffect(() => {
    if (riskAssessmentOutputResponse) {
      let response = mapRiskAssessmentToResultView(riskAssessmentOutputResponse);
      setFilteredRiskAssessments(response);
    }
  }, [riskAssessmentOutputResponse]);

  return currentCodeAnalysis ? (
    <>
      <div className=" p-4 ml-20">
        <div className="bg-white p-6">
          <div className={` collapsible-section ${isOpen ? 'open' : ''}`}>
            <CodeMirrorInput code={code} disabled={true} />
          </div>
          <>
            <div className="flex items-center justify-between mt-10 animate__animated animate__fadeInUp  ">
              <div className="flex space-x-4">
                <PlugInResultActions
                  actionItems={[
                    {
                      icon: <EditIcon />,
                      text: EDIT_TEXT,
                      isVisible: true,
                      onClick: () => {
                        dispatch(CodeAnalysisActions.setCodeAnalysisContent(currentCodeAnalysis?.code ?? ''));
                        navigate(RoutePath.CodeAnalyzer);
                      },
                    },
                    // {
                    //   icon: <GeneratePDFIcon />,
                    //   text: Generate_PDF,
                    //   onClick: () => {},
                    // },
                    {
                      icon: currentCodeAnalysis?.isFavorite ? <FavoriteIcon /> : <FavoriteIconPink />,
                      text: FAVORITE_TEXT,
                      isVisible: true,
                      textClass: currentCodeAnalysis?.isFavorite ? `text-primary` : 'text-wrapper-27 ',
                      onClick: () => {
                        dispatch(
                          CodeAnalysisActions.setCodeAnalysisFavorite({
                            id: id ?? '',
                            progressPercentage: 100,
                            isFavorite: !currentCodeAnalysis?.isFavorite,
                          }),
                        );
                      },
                    },
                  ]}
                />
              </div>
              <div className="flex space-x-4">
                {isOpen ? (
                  <div
                    className="input-action-item"
                    onClick={() => {
                      if (!disableHideInput) {
                        setIsOpen(false);
                      }
                    }}
                  >
                    <div className="text-wrapper-27">Hide Input</div>
                    <div className="text-wrapper-26"></div>
                  </div>
                ) : (
                  <PrimaryButton
                    onClick={() => setIsOpen(true)}
                    className=""
                    size="small"
                    stateProp="rest"
                    style="boxed"
                    icon={<ArrowDown />}
                    iconPosition="right"
                    text="Show Input"
                  />
                )}
              </div>
            </div>
          </>
          <Divider />

          <div className="overflow-x-auto p-2 animate__animated animate__fadeInUp ">
            <Tabs
              theme={{
                tablist: {
                  tabitem: {
                    base: 'flex items-center mx-1 border border-black justify-center p-2 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-0  focus:outline-none rounded-t-lg border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 gap-4 border-gray-200 border-2',
                  },
                },
              }}
              style="default"
              color="white"
              className="border-b border-gray-300"
            >
              {mappedResults && mappedResults?.length && (
                <Tabs.Item active title="Risk Assessment" className="p-2 m-2">
                  <div>
                    <div className="flex  justify-between">
                      <div>
                        <Dropdown
                          label={assessmentDropdownText}
                          className="border shadow border-grey"
                          style={{
                            color: '#181D2C',
                            borderColor: '#E1E4EC',
                            width: '100%',
                            minWidth: '400px',
                            textAlign: 'left',
                            justifyContent: 'left',
                            alignItems: 'left',
                          }}
                        >
                          <Dropdown.Header
                            style={{
                              width: '100%',
                            }}
                            onClick={() => {
                              setAssessmentDropdownText(`View all ${mappedResults.length} risks`);
                              setFilteredRiskAssessments(mappedResults);
                            }}
                          >
                            <span className="block text-sm">View all {mappedResults.length} risks</span>
                          </Dropdown.Header>
                          <Dropdown.Header
                            style={{
                              color: '#181D2C',
                              borderColor: '#E1E4EC',
                              width: '100%',
                              minWidth: '400px',
                              textAlign: 'left',
                              alignItems: 'left',
                            }}
                            onClick={() => {
                              setAssessmentDropdownText('Passed');
                              if (riskAssessmentOutputResponse) {
                                setFilteredRiskAssessments(
                                  mappedResults.filter((riskAssessment) => !riskAssessment.risk_detected),
                                );
                              }
                            }}
                          >
                            {passedRiskAssessments} Passed{' '}
                          </Dropdown.Header>
                          <Dropdown.Header
                            onClick={() => {
                              setAssessmentDropdownText('Failed');
                              if (riskAssessmentOutputResponse) {
                                setFilteredRiskAssessments(
                                  mappedResults.filter((riskAssessment) => riskAssessment.risk_detected),
                                );
                              }
                            }}
                          >
                            {failedRiskAssessments} Failed
                          </Dropdown.Header>
                        </Dropdown>
                      </div>
                      <div className="flex flex-row gap-2">
                        <Badge backgroundColor={ResultIndicatorColors.GREEN} text={`${passedRiskAssessments} Passed`} />
                        <Badge backgroundColor={ResultIndicatorColors.RED} text={`${failedRiskAssessments} Failed`} />
                      </div>
                    </div>

                    <div className="max-h-[600px] overflow-auto">
                      {filteredRiskAssessments.map((riskAssessment) => (
                        <RiskAssessmentResult riskAssessment={riskAssessment} />
                      ))}
                    </div>
                  </div>
                </Tabs.Item>
              )}
              {currentCodeAnalysis && currentCodeAnalysis?.billOfLadding && (
                <Tabs.Item title="Bill of Lading" className="p-2 m-2">
                  <BillOfLadingResultView />
                </Tabs.Item>
              )}
              {currentCodeAnalysis?.codeReference && (
                <Tabs.Item title="Reference Finder" className="p-2 m-2">
                  <CodeReferenceResultView />
                </Tabs.Item>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      {' '}
      <AppLoader />{' '}
    </>
  );
};
