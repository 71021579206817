import { createSlice } from '@reduxjs/toolkit';
import { ModuleMetrics, RecentActivity } from '../../common/services/dashboard/models/dashboard.model';
import { DASHBOARD_STORE_NAME, dashboardActions } from '../actions/dashboard.actions';
import { ModulesListEnum } from '../../common/constants/default-values';
import { generateReportForAreaChart } from '../../common/helpers/Helper';
import { profileActions } from '../actions/profile.actions';

interface SeriesMetric {
  name: string;
  data: number[];
}

export enum SeriesMetricEnum {
  Completed = 'Completed',
  Favorite = 'Favorite',
}

export interface DashboardState {
  modulesMetrics: ModuleMetrics[];
  recentActivity: RecentActivity[];
  allRecentActivity: RecentActivity[];
  currentActivityCount: number;
  currentActiveModules: ModulesListEnum[];
  seriesMetricsOptions: SeriesMetric[];
  matricsForAreaChart: any[];
  profileCompletion: number;
  errorCode: '';
  fetching: boolean;
}

export const initialDashboardState: DashboardState = {
  modulesMetrics: [],
  recentActivity: [],
  allRecentActivity: [],
  profileCompletion: 0,
  currentActiveModules: [],
  seriesMetricsOptions: [
    { name: SeriesMetricEnum.Completed, data: [] },
    { name: SeriesMetricEnum.Favorite, data: [] },
  ],
  currentActivityCount: 10,
  errorCode: '',
  matricsForAreaChart: [],
  fetching: false,
};

export const dashboardSlice = createSlice({
  name: DASHBOARD_STORE_NAME,
  initialState: initialDashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dashboardActions.getDashboardMetrics, (state) => {
        state.fetching = true;
        state.errorCode = '';
      })
      .addCase(profileActions.getProfileSuccess, (state, { payload }) => {
        let profileCompletion = 0;
        if (payload.firstName) {
          profileCompletion += 20;
        }
        if (payload.lastName) {
          profileCompletion += 20;
        }
        if (payload.email) {
          profileCompletion += 20;
        }
        if (payload.claims.length > 0) {
          profileCompletion += 20;
        }
        if (payload.imageUrl) {
          profileCompletion += 20;
        }
        state.profileCompletion = profileCompletion;
      })
      .addCase(dashboardActions.getDashboardMetricsSuccess, (state, { payload }) => {
        state.modulesMetrics = payload;
        state.allRecentActivity = [];
        state.modulesMetrics.forEach((module) => {
          module.records.forEach((record) => {
            state.allRecentActivity.push({
              date: new Date(record.createdAt),
              id: record.id,
              moduleName: module.moduleName,
              name: record.name,
              isFavorite: record.isFavorite,
              progress: record.progressPercentage,
            } as RecentActivity);
          });
        });
        state.allRecentActivity = state.allRecentActivity.sort((a, b) => b.date.getTime() - a.date.getTime());
        state.recentActivity = state.allRecentActivity.slice(0, state.currentActivityCount);
        state.currentActiveModules = state.modulesMetrics.map((e) => e.moduleName);
        const completedIndex = state.seriesMetricsOptions.findIndex((e) => e.name === SeriesMetricEnum.Completed);
        const favoriteIndex = state.seriesMetricsOptions.findIndex((e) => e.name === SeriesMetricEnum.Favorite);
        state.seriesMetricsOptions[completedIndex].data = [];
        state.seriesMetricsOptions[favoriteIndex].data = [];
        state.currentActiveModules.forEach((category) => {
          state.seriesMetricsOptions[completedIndex].data.push(
            state.allRecentActivity.filter((activity) => activity.moduleName === category && activity.progress === 100)
              .length,
          );
          state.seriesMetricsOptions[favoriteIndex].data.push(
            state.allRecentActivity.filter((activity) => activity.moduleName === category && activity.isFavorite)
              .length,
          );
        });
        state.matricsForAreaChart = [];
        state.matricsForAreaChart = generateReportForAreaChart(JSON.parse(JSON.stringify(state.allRecentActivity)));
        state.allRecentActivity.forEach((record) => {
          const date = new Date(record.date);
          record.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}` as any;
        });
        state.fetching = false;
      }).addCase(profileActions.logout, (state) => {
        Object.assign(state, initialDashboardState);
      });
  },
});

export const dashboardReducer = dashboardSlice.reducer;
