import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ArticleService } from '../../common/services/article/article.service';
import {
  ArticleUpdateRequest,
  AssertionResult,
  AssertionResultLite,
  ContradictionLite,
  ContradictionResult,
} from '../../types/models';
import { mapContradictionApiResponseToResult, wait } from '../../common/helpers/Helper';
import { ArticleProcessRequest } from '../../common/services/article/models/ArticleProcessRequest';
import { setNotification } from '../actions/notification.actions';
import { RoutePath } from '../../common/constants/route-path';
import { useNavigate } from 'react-router-dom';
import { browserHistory } from '../helpers/history/history';
import { contradictionActions } from '../actions/contradiction.actions';
import { ContradictionService } from '../../common/services/article/contradiction.service';
import { numberofRecords } from '../selectors/user-preference.selectors';
export function* getContradictions({ payload }: PayloadAction<number>) {
  try {
    var userArticles: ContradictionLite = yield call(ContradictionService.getContradictionsList, payload, 10);
    yield put(contradictionActions.getUserContradictionsListSuccess(userArticles));
  } catch (err: any) {
    yield put(
      contradictionActions.getUserContradictionsListFailure({ message: err.message, statusCode: err.statusCode }),
    );
  }
}

export function* getDefaultContradiction() {
  try {
    const defaultArticle: AssertionResult = yield call(ContradictionService.getDefaulContradiction);
    // yield put(contradictionActions.getDefaultArticleSuccess(defaultArticle));
  } catch (err: any) {
    yield put(
      contradictionActions.getDefaultContradictionFailure({ message: err.message, statusCode: err.statusCode }),
    );
  }
}

export function* processContradiction({ payload }: PayloadAction<string>) {
  try {
    const selectedNumberOfRecords: number = yield select(numberofRecords);
    const result: ArticleProcessRequest = new ArticleProcessRequest(payload, selectedNumberOfRecords, true);
    const processedArticle: ContradictionResult = yield call(ContradictionService.processContradiction, result);
    yield put(contradictionActions.processContradictionSuccess(mapContradictionApiResponseToResult(processedArticle)));
  } catch (err: any) {
    yield put(contradictionActions.processContradictionFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
    yield put(contradictionActions.processContradictionEnabled(false));
  }
}

export function* setContradictionFavorite({ payload }: PayloadAction<ArticleUpdateRequest>) {
  try {
    const updateArticleResult: ContradictionResult = yield call(ContradictionService.updateContradiction, payload);
    yield put(
      contradictionActions.setContradictionFavoriteSuccess(mapContradictionApiResponseToResult(updateArticleResult)),
    );
    let message = payload.isFavorite ? 'Contradiction marked as favorite' : 'Contradiction removed from favorite';

    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(
      contradictionActions.setContradictionFavoriteFailure({ message: err.message, statusCode: err.statusCode }),
    );
  } finally {
  }
}

export function* getContradictionsById({ payload }: PayloadAction<string>) {
  try {
    const articleDetails: ContradictionResult = yield call(ContradictionService.getUserContradictionsById, payload);
    if (articleDetails) {
      yield put(contradictionActions.getContradictionByIdSuccess(articleDetails));
    } else {
      yield put(
        contradictionActions.getContradictionByIdFailure({ message: 'Contradiction Not Found', statusCode: 404 }),
      );
    }
  } catch (err: any) {
    yield put(contradictionActions.getContradictionByIdFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to fetch contradiction details', type: 'error' }));
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export function* deleteContradiction({ payload }: PayloadAction<string>) {
  try {
    yield call(ContradictionService.deleteContradiction, payload);
    yield put(contradictionActions.deleteContradictionSuccess(payload));
    yield put(setNotification({ title: 'Article deleted successfully', type: 'success' }));
  } catch (err: any) {
    yield put(contradictionActions.deleteContradictionFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to delete article', type: 'error' }));
  }
}

export function* clearContradictionHistory() {
  try {
    const result: boolean = yield call(ContradictionService.clearHistory);
    if (result) {
      yield put(contradictionActions.clearContradictionHistorySuccess());
      yield put(setNotification({ title: 'Code Analysis History Cleared', type: 'success' }));
    } else {
      yield put(contradictionActions.clearContradictionHistoryFailure());
      yield put(setNotification({ title: 'Unable to clear code analysis history', type: 'error' }));
    }
  } catch (err: any) {
    yield put(contradictionActions.clearContradictionHistoryFailure());
  }
}

export const PROCESS_CONTRADICTION_SAGA = 'Process Contradiction Saga';

export const processContradictionSaga = createAction<string>(PROCESS_CONTRADICTION_SAGA);

export default function* watcher() {
  yield takeLatest(contradictionActions.processContradiction.type, processContradiction);
  yield takeEvery(contradictionActions.getUserContradictionsList.type, getContradictions);
  yield takeEvery(contradictionActions.getDefaultContradiction.type, getDefaultContradiction);
  yield takeEvery(contradictionActions.getContradictionById.type, getContradictionsById);
  yield takeLatest(contradictionActions.setContradictionFavorite.type, setContradictionFavorite);
  yield takeLatest(contradictionActions.clearContradictionHistory.type, clearContradictionHistory);
  yield takeLatest(contradictionActions.deleteContradiction.type, deleteContradiction);
}
