import { ClientAppState } from '../slices/client-app.slice';
import { AppState } from '../types/App.type';
import { createSelector } from '@reduxjs/toolkit';

const selectClientAppState: (state: AppState) => ClientAppState = (state) => state.clientApp;
export const currentClientApp = createSelector(selectClientAppState, ({ currentClientApp }) => currentClientApp);
export const ClientAppErrorCodes = createSelector(selectClientAppState, ({ errorCode }) => errorCode);
export const isFetchingClientApp = createSelector(
  selectClientAppState,
  ({ isFetchingClientApp }) => isFetchingClientApp,
);
export const isPostingClientApp = createSelector(selectClientAppState, ({ isPostingClientApp }) => isPostingClientApp);
export const isEditingClientApp = createSelector(selectClientAppState, ({ isEditingClientApp }) => isEditingClientApp);
export const isDeleteClientApp = createSelector(selectClientAppState, ({ isDeleteClientApp }) => isDeleteClientApp);
export const isLoading = createSelector(selectClientAppState, ({ isLoading }) => isLoading);
