import {
  HttpErrorResponse,
  ProcessSummaryRequest,
  ResultListLite,
  SummarizationLite,
  SummarizationUpdateRequest,
  SummaryComparisonInput,
  SummaryComparisonResponse,
  UserHistoryList,
} from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const Summarization_STORE_NAME = 'Summarization Store';

const createPayloadAction = getCreatePayloadActionFromStore(Summarization_STORE_NAME);
const createAction = getCreateActionFromStore(Summarization_STORE_NAME);

const saveSummarizationInput = createPayloadAction<ProcessSummaryRequest>('Save Summarization Input');
const processSummarization = createPayloadAction<ProcessSummaryRequest>('Process Summarizations');
const processSummarizationSuccess = createPayloadAction<UserHistoryList>('Process Success');
const processSummarizationFailure = createPayloadAction<HttpErrorResponse>('Process Failure');

const toggleFavoriteSummarizations = createPayloadAction<boolean>('Toggle Favorite Summarizations');
const setSummarizationFavorite = createPayloadAction<SummarizationUpdateRequest>('Set Summarization Favorite');
const setSummarizationFavoriteSuccess = createPayloadAction<SummarizationLite>('Set Summarization Favorite Success');
const setSummarizationFavoriteFailure = createPayloadAction<HttpErrorResponse>('Set Summarization Favorite Failure');

const getSummarizationsList = createPayloadAction<number>('Get User Summarizations List');
const getSummarizationsListSuccess = createPayloadAction<ResultListLite<UserHistoryList>>(
  'Get User Summarizations List Success',
);
const getSummarizationsListFailure = createPayloadAction<HttpErrorResponse>('Get User Summarizations List Failure');

// List of summaries
const getSummarizationById = createPayloadAction<string>('Get Summarization By Id');
const getSummarizationByIdSuccess = createPayloadAction<SummarizationLite>('get Summarization By Id Success');
const getSummarizationByIdFailure = createPayloadAction<HttpErrorResponse>('get Summarization By Id Failure');

// Delete Summary
const deleteSummarization = createPayloadAction<string>('Delete Summarization');
const deleteSummarizationSuccess = createPayloadAction<string>('Delete Summarization Success');
const deleteSummarizationFailure = createPayloadAction<HttpErrorResponse>('Delete Summarization Failure');

const compareSummaries = createPayloadAction<SummaryComparisonInput>('Compare Summaries');
const compareSummariesSuccess = createPayloadAction<SummaryComparisonResponse>('Compare Summaries Success');
const compareSummariesFailure = createPayloadAction<HttpErrorResponse>('Compare Summaries Failure');

const clearSummaryHistory = createAction('Clear Summary history');

const clearSummaryHistorySuccess = createAction('Clear Summary history success');

const clearSummaryHistoryFailure = createAction('Clear Summary history failure');

export const SummarizationActions = {
  saveSummarizationInput,
  processSummarization,
  processSummarizationSuccess,
  processSummarizationFailure,
  toggleFavoriteSummarizations,
  setSummarizationFavorite,
  setSummarizationFavoriteSuccess,
  setSummarizationFavoriteFailure,
  getSummarizationsList,
  getSummarizationsListSuccess,
  getSummarizationsListFailure,
  getSummarizationById,
  getSummarizationByIdSuccess,
  getSummarizationByIdFailure,
  deleteSummarization,
  deleteSummarizationSuccess,
  deleteSummarizationFailure,
  compareSummaries,
  compareSummariesSuccess,
  compareSummariesFailure,
  clearSummaryHistory,
  clearSummaryHistorySuccess,
  clearSummaryHistoryFailure,
};
