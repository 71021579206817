import { showPasswordRecovery } from '../../../redux/selectors/profile.selectors';
import { SignInModalContent } from './SignInModalContent';
import PasswordRecoveryModal from '../PasswordRecoveryModal/PasswordRecoveryModal';
import { useSelector } from 'react-redux';

const SignInPage = () => {
  const showPasswordRecoveryScreen = useSelector(showPasswordRecovery);

  return (
    <div className="flex w-100 justify-center text-left items-center animate__animated animate__slideInUp  my-10">
      <div className=" bg-white p-5 w-500 h-550 z-10" id="SignInModal">
        {showPasswordRecoveryScreen && <PasswordRecoveryModal />}
        {!showPasswordRecoveryScreen && <SignInModalContent />}
      </div>
    </div>
  );
};

export default SignInPage;
