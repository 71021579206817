import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { ContradictionState } from '../slices/contradiction.slice';

const selectContradictionState: (state: AppState) => ContradictionState = (state) => state.contradiction;
export const currentContradiction = createSelector(
  selectContradictionState,
  ({ currentContradiction }) => currentContradiction,
);

export const processContradictionEnabled = createSelector(
  selectContradictionState,
  ({ processContradictionEnabled }) => processContradictionEnabled,
);

export const contradictionErrorCodes = createSelector(selectContradictionState, ({ errorCode }) => errorCode);
export const defaultContradiction = createSelector(
  selectContradictionState,
  ({ defaultContradiction }) => defaultContradiction,
);
export const userContradictionsList = createSelector(
  selectContradictionState,
  ({ contradictionList }) => contradictionList,
);
export const isProcessingContradiction = createSelector(
  selectContradictionState,
  ({ isProcessingContradiction }) => isProcessingContradiction,
);
export const processingContradictionInput = createSelector(selectContradictionState, ({ userInput }) => userInput);
export const favoriteContradictionSelector = createSelector(
  selectContradictionState,
  ({ favoriteContradictions }) => favoriteContradictions,
);
export const isFetchingContradictions = createSelector(
  selectContradictionState,
  ({ isFetchingContradictions }) => isFetchingContradictions,
);
export const favoriteContradictionPagination = createSelector(
  selectContradictionState,
  ({ articlePagination }) => articlePagination,
);
export const favoritesContradictionCount = createSelector(
  selectContradictionState,
  ({ favoriteContradictionsCount }) => favoriteContradictionsCount,
);
