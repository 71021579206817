import {
  ArticleUpdateRequest,
  AssertionResult,
  AssertionResultLite,
  HttpErrorResponse,
  PromptProtect,
} from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';
import { ArticleProcessRequest } from '../../common/services/article/models/ArticleProcessRequest';

export const ARTICLE_STORE_NAME = 'Article Store';

const createPayloadAction = getCreatePayloadActionFromStore(ARTICLE_STORE_NAME);
const createAction = getCreateActionFromStore(ARTICLE_STORE_NAME);

const getDefaultArticle = createAction('Get Default Article');
const getDefaultArticleSuccess = createPayloadAction<AssertionResult>('Get Default Success');
const getDefaultArticleFailure = createPayloadAction<HttpErrorResponse>('Get Default Failure');
const processArticle = createPayloadAction<string>('Process Articles');
const processArticleEnabled = createPayloadAction<boolean>('Process Articles Enabled');
const processArticleSuccess = createPayloadAction<AssertionResult>('Process Success');
const processArticleFailure = createPayloadAction<HttpErrorResponse>('Process Failure');
const setPromptContent=createPayloadAction<string>("Edit Articles");

const getArticleById = createPayloadAction<string>('Get Article By Id');
const getArticleByIdSuccess = createPayloadAction<AssertionResult>('get Article By Id Success');
const getArticleByIdFailure = createPayloadAction<HttpErrorResponse>('get Article By Id Failure');

const getUserArticlesList = createPayloadAction<number>('Get User Articles List');
const getUserArticlesListSuccess = createPayloadAction<AssertionResultLite>('Get User Articles List Success');
const getUserArticlesListFailure = createPayloadAction<HttpErrorResponse>('Get User Articles List Failure');

const setArticleFavorite = createPayloadAction<ArticleUpdateRequest>('Set Article Favorite');
const setArticleFavoriteSuccess = createPayloadAction<AssertionResult>('Set Article Favorite Success');
const setArticleFavoriteFailure = createPayloadAction<HttpErrorResponse>('Set Article Favorite Failure');

const toggleFavoriteArticles = createPayloadAction<boolean>('Toggle Favorite Articles');

const deleteArticle = createPayloadAction<string>('Delete Article');
const deleteArticleSuccess = createPayloadAction<string>('Delete Article Success');
const deleteArticleFailure = createPayloadAction<HttpErrorResponse>('Delete Article Failure');

const clearArticleHistory = createAction('Clear article history');

const clearArticleHistorySuccess = createAction('Clear article history success');

const clearArticleHistoryFailure = createAction('Clear article history failure');

export const articleActions = {
  processArticle,
  processArticleSuccess,
  processArticleEnabled,
  processArticleFailure,
  getUserArticlesList,
  getUserArticlesListSuccess,
  getUserArticlesListFailure,
  getDefaultArticle,
  getDefaultArticleSuccess,
  getDefaultArticleFailure,
  getArticleById,
  getArticleByIdSuccess,
  getArticleByIdFailure,
  setArticleFavorite,
  setArticleFavoriteSuccess,
  setArticleFavoriteFailure,
  toggleFavoriteArticles,
  deleteArticle,
  deleteArticleSuccess,
  deleteArticleFailure,
  setPromptContent,
  clearArticleHistory,
  clearArticleHistorySuccess,
  clearArticleHistoryFailure,
};
