import React, { ReactNode } from 'react';

interface ElementToRender {
  title: string;
  description: string;
}

interface DynamicTitleDescriptionLayoutProps {
  items: ElementToRender[];
  icon: ReactNode;
}

export const DynamicTitleDescriptionLayout: React.FC<DynamicTitleDescriptionLayoutProps> = ({ items, icon }) => {
  return (
    <div className="mt-4 grid grid-cols-1 gap-8 lg:grid-cols-3">
      {items.map((item, index) => (
        <div key={index} className="flex items-start gap-4">
          <span className="shrink-0 rounded-lg bg-gray-800 p-4">{icon}</span>
          <div>
            <h2 className="text-lg font-bold">{item.title}</h2>
            <p className="mt-1 text-sm text-gray-300">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
