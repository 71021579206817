import { useSelector } from 'react-redux';
import { AssessmentOptions } from '../../common/constants/default-values';
import WaitingMessage from '../Base/WaitingMessage';
import ProgressBar from '../Progressbar/Progressbar';
import {
  currentCodeAnalysisId,
  isCodeAnalysisRunning,
  processingCodeInputSelector,
} from '../../redux/selectors/codeAnalysis.selectors';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';

export const CodeProcessingIndicator: React.FC = () => {
  const navigate = useNavigate();
  const assessmentOptions = useSelector(processingCodeInputSelector);
  const showProgressBar = useSelector(isCodeAnalysisRunning);
  const [processingInputs, setProcessingInputs] = useState<string[]>([]);
  const currentId = useSelector(currentCodeAnalysisId);
  useEffect(() => {
    if (assessmentOptions) {
      let riskAssessment = [...assessmentOptions.riskAssessment];
      if (assessmentOptions.checkForReferenceFinder) {
        riskAssessment.push('Reference Finder');
      }
      if (assessmentOptions.checkForBillOfLadding) {
        riskAssessment.push('Bill of Ladding');
      }
      setProcessingInputs(riskAssessment);
    }
  }, [assessmentOptions]);
  const handleProgressNavigation = () => {
    navigate(`${RoutePath.CodeAnalyzer}/${currentId}`);
  };
  return (
    <>
      <div className="mt-6 w-full">
        <div className="flex text-center  w-full mt-10 mb-5 animate__animated animate__zoomIn">
          <div className="w-full">
            <ProgressBar
              stop={!showProgressBar}
              children={
                <>
                  <div className="flex flex-col text-left text-grey-200  text-sm">
                    {processingInputs?.map((option, index) => {
                      return (
                        <div key={index} className="w-100 my-1 font-['Open_Sans'] text-gray-400">
                          <span> {option}</span>
                        </div>
                      );
                    })}
                  </div>
                </>
              }
              subText=""
              message="Evaluating risks..."
              navigate={handleProgressNavigation}
            />
          </div>
        </div>
        <div>
          <WaitingMessage />
        </div>
      </div>
    </>
  );
};
