import React, { useEffect, useState } from 'react';
import { DeSelectIcon, ArrowDown } from '../../common/icons/Icons';
import CheckboxWithLabel from '../Base/CheckBoxWithLabel';
import { PrimaryButton } from '../PrimaryButton';
import { AssessmentOptions } from '../../common/constants/default-values';

interface RiskAssessmentInputProps {
  onChange?: (newCode: string[]) => void;
}

export const RiskAssessmentInput: React.FC<RiskAssessmentInputProps> = ({ onChange }) => {
  const assessmentOptions = Object.values(AssessmentOptions);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState(new Array(assessmentOptions.length).fill(false));
  const [selectedAssessments, setSelectedAssessments] = useState<string[]>([]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedAssessments);
    }
  }, [selectedAssessments]);

  const handleCheckboxChange = (position: number) => {
    const updatedCheckedStatus = [...checkedStatus];
    updatedCheckedStatus[position] = !updatedCheckedStatus[position];
    setCheckedStatus(updatedCheckedStatus);

    const updatedSelectedAssessments = updatedCheckedStatus
      .map((isChecked, index) => (isChecked ? assessmentOptions[index] : null))
      .filter(Boolean) as string[];
    setSelectedAssessments(updatedSelectedAssessments);
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    setCheckedStatus(new Array(assessmentOptions.length).fill(true));
    setSelectedAssessments(assessmentOptions);
  };

  const handleDeselectAll = () => {
    setSelectAll(false);
    setCheckedStatus(new Array(assessmentOptions.length).fill(false));
    setSelectedAssessments([]);
  };

  return (
    <div className="border-b border-gray-200 mb-6  font-sans animate__animated animate__fadeInUp ">
      <h2 className="text-lg font-semibold">Risk Assessment</h2>
      <p className="text-sm mt-2 mb-4 text-gray-700 ">
      Run a series of static risk assessments  on your code.  Check for potential known vulnerabilities and get suggestions for remediation.
      </p>
      <div className="flex justify-between items-center">
        <p className="font-sm font-['Open_Sans'] gray-900">Select the risks you want to include on the assessment:</p>
        <div className="flex space-x-4">
          {selectAll ? (
            <div
              className="input-action-item"
              onClick={() => {
                handleDeselectAll();
              }}
            >
              <div className="text-sm text-secondary-pink">Deselect All</div>
              <div className="text-wrapper-26">
                <DeSelectIcon />
              </div>
            </div>
          ) : (
            <PrimaryButton
              onClick={() => handleSelectAll()}
              className=""
              size="small"
              stateProp="rest"
              style="boxed"
              icon={<ArrowDown />}
              iconPosition="right"
              text="Select All"
            />
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 my-4"></div>

      <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1">
        {assessmentOptions.map((option, index) => (
          <CheckboxWithLabel
            label={option}
            showInfoIcon={true}
            onCheckboxChange={() => handleCheckboxChange(index)}
            isChecked={checkedStatus[index]}
          />
        ))}
      </div>
    </div>
  );
};
