import React, { useState, useEffect } from 'react';
import { ComparisonIcon, InfoIcon, RightChevron, ScoreDownIcon, ScoreUpIcon } from '../../common/icons/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { Checkbox } from 'flowbite-react';
import { PrimaryButton } from '../../components/PrimaryButton';
import { SecondaryButton } from '../../components/SecondaryButton';
import { currentSummarizationSelector } from '../../redux/selectors/summarization.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { SummarizationActions } from '../../redux/actions/summarization.actions';
import { setNotification } from '../../redux/actions/notification.actions';
import { FavoriteIcon, FavoriteIconPink } from '../../common/icons/Icons';
import { FAVORITE_TEXT } from '../../common/constants/app-text';
import { PlugInResultActions } from '../../components/PlugInResultActions/PlugInResultActions';
import { SummaryDto } from '../../types/models';
import AppLoader from '../../components/AppLoader/AppLoader';
import { languageModels, stepperSteps, languageModelsOptions } from '../../common/constants/summarization';
import HorizontalStepper from '../../components/Stepper/Stepper';
import InfoIconWithDescription from '../../components/InfoDescription/InfoDescription';
import { Divider } from '../../components/Divider/Divider';
import './style.css';

interface SummaryResultItemProps {
  model: string;
  summary: SummaryDto;
  selectionEnabled: boolean;
  isChecked: boolean;
  handleCheckboxChange: (model: string, isChecked: boolean) => void;
  currentSummarizationId: string;
}

const SummaryResultItem: React.FC<SummaryResultItemProps> = React.memo(
  ({ model, summary, selectionEnabled, isChecked, handleCheckboxChange, currentSummarizationId }) => {
    const navigate = useNavigate();
    const currentSummarization = useSelector(currentSummarizationSelector);
    const [minMaxScores, setMinMaxScores] = useState({
      maxScores: {
        readability: -Infinity,
        compression: -Infinity,
        coherence: -Infinity,
        similarity: -Infinity,
      },
      minScores: {
        readability: Infinity,
        compression: Infinity,
        coherence: Infinity,
        similarity: Infinity,
      },
    });

    useEffect(() => {
      const Summaries = currentSummarization?.summaries ?? {};
      setMinMaxScores(calculateMaxScores(Summaries));
    }, [currentSummarization]);

    const calculateMaxScores = (summaries: any) => {
      const maxScores = {
        readability: -Infinity,
        compression: -Infinity,
        coherence: -Infinity,
        similarity: -Infinity,
      };
      const minScores = {
        readability: Infinity,
        compression: Infinity,
        coherence: Infinity,
        similarity: Infinity,
      };

      Object.values(summaries).forEach((summary: any) => {
        maxScores.readability = Math.max(maxScores.readability, summary?.evaluation_metrics?.readability_score || 0);
        minScores.readability = Math.min(
          minScores.readability,
          summary?.evaluation_metrics?.readability_score || Infinity,
        );

        maxScores.compression = Math.max(maxScores.compression, summary?.evaluation_metrics?.compression_ratio || 0);
        minScores.compression = Math.min(
          minScores.compression,
          summary?.evaluation_metrics?.compression_ratio || Infinity,
        );

        maxScores.coherence = Math.max(maxScores.coherence, summary?.evaluation_metrics?.topic_coherence || 0);
        minScores.coherence = Math.min(minScores.coherence, summary?.evaluation_metrics?.topic_coherence || Infinity);

        maxScores.similarity = Math.max(maxScores.similarity, summary?.evaluation_metrics?.sentence_similarity || 0);
        minScores.similarity = Math.min(
          minScores.similarity,
          summary?.evaluation_metrics?.sentence_similarity || Infinity,
        );
      });

      return { maxScores, minScores };
    };

    const getArrow = (score: number, maxScore: number, minScore: number) => {
      if (score === maxScore) {
        return <ScoreUpIcon />;
      } else if (score === minScore) {
        return <ScoreDownIcon />;
      } else {
        return null; // Return null for scores that are not min or max
      }
    };

    const toggleCheckbox = () => {
      handleCheckboxChange(model, !isChecked);
    };

    const option = languageModelsOptions.find((option) => option.items?.some((item) => item.value === model));
    const description = option?.items?.find((item) => item.value === model)?.description || '';

    return (
      <tr className="mt-2">
        <td className="align-top">
          <div className="p-2 flex">
            {selectionEnabled ? (
              <span className="-mt-1 ml-1 pr-2 ">
                <Checkbox checked={isChecked} disabled={!selectionEnabled && !isChecked} onClick={toggleCheckbox} />
              </span>
            ) : (
              <span className="pr-2 mt-[2px] cursor-pointer">
                <InfoIconWithDescription color="#BEC5D7" description={description} />
              </span>
            )}
            <div className="w-full">
              <div className="text-md flex justify-between align-top items-center">
                <span className="flex items-center">
                  <span className="text-nowrap leading-5">{languageModels[model]}</span>
                  <span className="text-xs text-gray-400 ml-2">{summary?.summarized_text.length} Characters</span>
                </span>
                <span
                  className="primary-Button rounded text-nowrap text-purple-primary text-sm flex py-1 px-3"
                  onClick={() => {
                    const URL = `${RoutePath.Summarization}/${currentSummarizationId}/details/${model}`;
                    navigate(URL);
                  }}
                >
                  <span>Full Summary</span>
                  <RightChevron />
                </span>
              </div>
              <span className="text-sm text-neutral-500 line-clamp-3">{summary?.summarized_text}</span>
              <Divider />
            </div>
          </div>
        </td>
        <td className="align-top">
          <div className="flex items-center p-2  justify-end border-r border-neutral-300 border-solid">
            {getArrow(
              summary?.evaluation_metrics?.readability_score || 0,
              minMaxScores.maxScores.readability,
              minMaxScores.minScores.readability,
            )}
            <div className="w-10 text-right">
              <p className="text-neutral-600 text-sm mr-2 text-right">
                {summary?.evaluation_metrics?.readability_score?.toFixed(2)}
              </p>
            </div>
          </div>
        </td>
        <td className="align-top">
          <div className="flex items-center p-2  justify-end border-r border-neutral-300 border-solid">
            {getArrow(
              summary?.evaluation_metrics?.compression_ratio || 0,
              minMaxScores.maxScores.compression,
              minMaxScores.minScores.compression,
            )}
            <div className="w-18 text-right">
              <p className="text-neutral-600 text-sm mr-2 text-right">
                {summary?.evaluation_metrics?.compression_ratio?.toFixed(5)}
              </p>
            </div>
          </div>
        </td>
        <td className="align-top">
          <div className="flex items-center p-2  justify-end border-r border-neutral-300 border-solid">
            {getArrow(
              summary?.evaluation_metrics?.topic_coherence || 0,
              minMaxScores.maxScores.coherence,
              minMaxScores.minScores.coherence,
            )}
            <div className="w-18 text-right">
              <p className="text-neutral-600 text-sm mr-2 text-right">
                {summary?.evaluation_metrics?.topic_coherence?.toFixed(5)}
              </p>
            </div>
          </div>
        </td>
        <td className="align-top">
          <div className="flex items-center p-2  justify-end border-r border-neutral-300 border-solid">
            {getArrow(
              summary?.evaluation_metrics?.sentence_similarity || 0,
              minMaxScores.maxScores.similarity,
              minMaxScores.minScores.similarity,
            )}
            <div className="w-18 text-right">
              <p className="text-neutral-600 text-sm mr-2 text-right">
                {summary?.evaluation_metrics?.sentence_similarity?.toFixed(5)}
              </p>
            </div>
          </div>
        </td>
      </tr>
    );
  },
);

export const SummarizationResultView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const currentSummarization = useSelector(currentSummarizationSelector);
  const Summaries = currentSummarization?.summaries ?? {};

  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [selectionEnabled, setSelectionEnabled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (id) {
      dispatch(SummarizationActions.getSummarizationById(id));
    } else {
      dispatch(setNotification({ title: 'Prompt Id Missing ', type: 'error' }));
      navigate(RoutePath.Landing);
    }
  }, [id, dispatch, navigate]);

  const handleCheckboxChange = (model: string, isChecked: boolean) => {
    if (isChecked) {
      if (selectedModels.length < 2) {
        setSelectedModels((prev) => [...prev, model]);
      } else {
        dispatch(setNotification({ title: 'Maximum two summaries allowed for comparison', type: 'error' }));
      }
    } else {
      setSelectedModels((prev) => prev.filter((m) => m !== model));
    }
  };

  const toggleSelection = () => {
    setSelectionEnabled((prev) => !prev);
    setSelectedModels([]);
  };

  const continueSelection = () => {
    if (selectedModels.length === 2) {
      const URL = `${RoutePath.Summarization}/${currentSummarization.id}/compare/${selectedModels.join('-')}`;
      setSelectionEnabled(false);
      navigate(URL);
    } else {
      dispatch(setNotification({ title: 'Exactly two summaries need to be selected', type: 'error' }));
    }
  };

  return currentSummarization ? (
    <div className="flex justify-center my-10 scroll-container">
      <div className="relative w-5/4">
        <HorizontalStepper steps={stepperSteps} completedStep={3} />
        <div className="flex justify-between items-center min-h-10">
          <p className=" font-semibold">{selectionEnabled ? 'Select 2 summaries to compare' : 'Summary Metrics'}</p>
          <div className="flex">
            {!selectionEnabled && (
              <PlugInResultActions
                actionItems={[
                  {
                    icon: currentSummarization?.isFavorite ? <FavoriteIcon /> : <FavoriteIconPink />,
                    text: FAVORITE_TEXT,
                    textClass: currentSummarization?.isFavorite ? `text-primary` : 'text-wrapper-27 ',
                    onClick: () => {
                      let updatedSummarization = {
                        isFavorite: !currentSummarization?.isFavorite,
                        id: currentSummarization?.id,
                        progressPercentage: String(currentSummarization?.progressPercentage),
                      };
                      dispatch(SummarizationActions.setSummarizationFavorite(updatedSummarization));
                    },
                  },
                ]}
              />
            )}
            {selectionEnabled ? (
              <div className="flex">
                <SecondaryButton className="px-3 py-2 mr-2" stateProp="rest" onClick={toggleSelection} text="Cancel" />
                <PrimaryButton
                  onClick={continueSelection}
                  className=""
                  size="medium"
                  stateProp="rest"
                  style={`boxed`}
                  iconPosition="right"
                  text="Continue"
                />
              </div>
            ) : (
              <div className="input-action-item pt-4 pl-4 pr-4 pb-4" onClick={toggleSelection}>
                <div className="text-wrapper-27 flex items-center">
                  <ComparisonIcon /> <span className="ml-2"> Binary Comparison</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="rectangle-7 mt-6" />
        <div className="table-content">
          <table className=" w-full">
            <thead>
              <th className="assertion-warpper text-start p-2 pb-4">SUMMARY</th>

              <th className="assertion-warpper text-end py-2 pl-8">
                <span className="flex items-center justify-end">
                  READABILITY
                  <InfoIconWithDescription
                    color="#BEC5D7"
                    description="Readability scores such as the Flesch-Kincaid Grade Level (FKGL) or the
                  Automated Readability Index (ARI) serve as crucial metrics for assessing the
                  comprehensibility of text summaries."
                  />
                </span>
              </th>
              <th className="assertion-warpper text-end py-2 pl-8">
                <span className="flex items-center justify-end">
                  COMPRESSION
                  <InfoIconWithDescription
                    color="#BEC5D7"
                    description="Compression Ratio is a straightforward yet insightful metric used in evaluating text
                  summarization systems."
                  />
                </span>
              </th>
              <th className="assertion-warpper text-end py-2 pl-8">
                <span className="flex items-center justify-end">
                  COHERENCE
                  <InfoIconWithDescription
                    color="#BEC5D7"
                    description="Coherence Metric assesses is how well a topic is 'supported' by a text set
                      (called reference corpus) . It uses statistics and probabilities drawn from the reference corpus, especially focused on the word's context, to give a coherence score to a topic."
                  />
                </span>
              </th>
              <th className="assertion-warpper text-end py-2 pl-8">
                <span className="flex items-center justify-end">
                  SIMILARITY
                  <InfoIconWithDescription
                    color="#BEC5D7"
                    description="Sentence Similarity is an essential metric for evaluating the quality of text
                  summarization by assessing how well the sentences in a summary capture the
                  essence of the original text."
                  />
                </span>
              </th>
            </thead>
            <tbody>
              {Object.keys(Summaries).map((model: string) => (
                <SummaryResultItem
                  key={model}
                  model={model}
                  summary={Summaries[model]}
                  selectionEnabled={selectionEnabled}
                  isChecked={selectedModels.includes(model)}
                  handleCheckboxChange={handleCheckboxChange}
                  currentSummarizationId={currentSummarization.id}
                />
              ))}
            </tbody>
          </table>
        </div>
        {selectionEnabled && (
          <div className="flex justify-end mt-10">
            <SecondaryButton
              // iconPosition="right"
              className="px-3 py-2 mr-2"
              stateProp="rest"
              onClick={toggleSelection}
              text="Cancel"
            />
            <PrimaryButton
              onClick={continueSelection}
              className=""
              size="medium"
              stateProp="rest"
              style={`boxed`}
              iconPosition="right"
              text="Continue"
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <AppLoader />
  );
};
