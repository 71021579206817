import React from 'react';
import MainPage from '../../components/MainPage/MainPage';
import { Header } from '../../components/Header';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import { MainMenu } from '../../layouts/Sidenav/MainMenu';
import { SideBarIcon } from '../../common/icons/Icons';
import { Footer } from '../../components/Footer';
import ExternalSearchSourceList from '../../components/ExternalSearchSource/ExternalSearchSourceList';
import { useDispatch, useSelector } from 'react-redux';
import { layoutExternalAppMainMenu } from '../../redux/selectors/external-search.selectors';
import { externalSearchActions } from '../../redux/actions/external-search.action';

export const ExternalSearchApp = () => {
  const dispatch = useDispatch();
  let showMenu = useSelector(layoutExternalAppMainMenu);
  // var showMenu = useSelector(layoutMainMenu);
  const toggleExternalSourceList = useSelector(layoutExternalAppMainMenu);
  return (
    <MainPage>
      <Header />
      <div className="flex items-stretch result-scroll">
        <ExternalSearchSourceList />
        <ContentLayout>
          {showMenu ? null : (
            <div
              className={` z-20 absolute left-10 top-15 w-15 cursor-pointer border-dark-purple
           border-2 rounded-full animate__animated  ${
             toggleExternalSourceList ? 'animate__zoomOut' : 'animate__zoomIn animate__delay-0.3s'
           }`}
              onClick={() => {
                dispatch(externalSearchActions.toggleExternalSearchSourceList(true));
                dispatch(externalSearchActions.getExternalSearchSourceLightList(1));
                dispatch(externalSearchActions.toggleExternalAppMenu(true));
              }}
            >
              <SideBarIcon />
            </div>
          )}
          <MainMenu isPushMenu={false} />
        </ContentLayout>
      </div>
      <Footer />
    </MainPage>
  );
};
