import React, { FC, useEffect, useState } from 'react';
import { VALIDATE_EMAIL, DID_NOT_RECEIVE_EMAIL, RESEND } from '../../common/constants/app-text';
import { MainButton } from '../MainButton';
import { MessageWithAction } from '../MessageWithAction/MessageWithAction';
import OtpInput from '../CodeInput/CodeInput';
import { useDispatch, useSelector } from 'react-redux';
import { passwordRecoverSelectors } from '../../redux/selectors/password-recover.selectors';
import ErrorBox from '../ErrorBox';
import { passwordRecoveryActions } from '../../redux/actions/password-recovery.actions';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
interface PropTypes {
  otpAction?: any;
  isGuestAccount?: boolean;
}

const OTPStepContent: FC<PropTypes> = ({ otpAction = 'Create Account', isGuestAccount = false }) => {
  const dispatch = useDispatch();
  const [verificationCode, setVerificationCode] = useState('');
  const email = useSelector(passwordRecoverSelectors.selectEmail);
  const error = useSelector(passwordRecoverSelectors.selectError);
  const token = useSelector(passwordRecoverSelectors.selectToken);
  const isProcessing = useSelector(passwordRecoverSelectors.isLoading);
  const navigate = useNavigate();
  const handleVerificationCodeComplete = (code: string) => {
    setVerificationCode(code);
  };

  useEffect(() => {
    if (token && token.length) {
      navigate(RoutePath.PasswordRecovery);
    }
  }, [token]);

  const validateOTP = () => {
    let emailValidateRequest = {
      email: email,
      code: verificationCode,
      isGuestAccount: isGuestAccount,
    };
    dispatch(passwordRecoveryActions.setEmailValidate(emailValidateRequest));
  };
  const resendOTP = () => {
    dispatch(passwordRecoveryActions.resendPasswordRecoveryRequest(email));
  };

  return (
    <div className="flex flex-col items-center justify-center animate__animated animate__flipInX">
      <h2 className="mt-4  font-sans text-center text-midnight-blue font-bold">{VALIDATE_EMAIL}</h2>
      <div className="flex items-center justify-center w-full mt-4 text-gray-400 font-sansInter text-lg text-center">
        <p>
          We’ve sent a email to <span className="text-primray-pink">{email}</span> to confirm it’s yours. Please enter
          the code below.
        </p>
      </div>
      <div>
        <OtpInput length={5} onComplete={handleVerificationCodeComplete} />
      </div>
      <div className="pt-8 w-full">{error && <ErrorBox errorMessage={error} />}</div>
      <MainButton
        isLoading={isProcessing}
        onClick={validateOTP}
        disabled={!verificationCode}
        className="w-full w-100 px-4  mt-2"
        stateProp="rest"
        text={otpAction}
      />
      <MessageWithAction onAction={resendOTP} message={DID_NOT_RECEIVE_EMAIL} action={RESEND} />
    </div>
  );
};

export default OTPStepContent;
