import { createSlice } from '@reduxjs/toolkit';
import {
  DossierLite,
  DossierOrganizaiton,
  DossierOrganizaitonLite,
  FavoriteArticles,
  PaginationConfig,
} from '../../types/models';
import { DOSSIER_ORG_STORE_NAME, DossierOrgActions } from '../actions/dossier-org.actions';
import { mockDossierOrganization } from '../../common/services/dossier/organization/dossier-org.service';
import {
  mapDossierOrganizationToLite,
  mapDossierOrganzationToFavorite,
  mapDossierToFavorite,
} from '../../common/helpers/Helper';
import { profileActions } from '../actions/profile.actions';
import { dossierActions } from '../actions/dossier.actions';

export interface DossierOrgState {
  currentDossierOrg?: DossierOrganizaiton;
  defaultDossierOrg?: DossierOrganizaiton;
  userInput: string;
  isProcessingDossierOrg: boolean;
  enableDossierOrgResults: boolean;
  processDossierOrgEnabled: boolean;
  errorCode: string;
  isFetchingDossierOrgs: boolean;
  isFavoriteEnabled: boolean;
  DossierOrgsList: DossierLite[];
  favoriteDossierOrgsCount: number;
  favoriteDossierOrgs: FavoriteArticles[];
  DossierOrgPagination: PaginationConfig;
  isDeleting: boolean;
}

const initialState: DossierOrgState = {
  userInput: '',
  currentDossierOrg: undefined,
  isProcessingDossierOrg: false,
  isFetchingDossierOrgs: false,
  enableDossierOrgResults: false,
  processDossierOrgEnabled: false,
  isFavoriteEnabled: false,
  errorCode: '',
  defaultDossierOrg: mockDossierOrganization,
  DossierOrgsList: [],
  DossierOrgPagination: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  favoriteDossierOrgs: [],
  favoriteDossierOrgsCount: 0,
  isDeleting: false,
};
export const DossierOrgSlice = createSlice({
  name: DOSSIER_ORG_STORE_NAME,
  initialState,
  reducers: {
    processDossierOrg: (state, action) => {
      state.isProcessingDossierOrg = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(DossierOrgActions.processDossierOrg, (state, { payload }) => {
        state.isProcessingDossierOrg = true;
        state.userInput = payload.subject_name;
      })
      .addCase(DossierOrgActions.getDefaultDossierOrgSuccess, (state) => {
        state.defaultDossierOrg = mockDossierOrganization;
      })
      .addCase(DossierOrgActions.processDossierOrgSuccess, (state, { payload }) => {
        state.currentDossierOrg = payload;
        state.DossierOrgsList.unshift(mapDossierOrganizationToLite(state.currentDossierOrg));
        state.favoriteDossierOrgs = mapDossierToFavorite(
          state.DossierOrgsList.filter((article, index, self) => article.isFavorite === state.isFavoriteEnabled),
        );
        state.isProcessingDossierOrg = false;
        state.errorCode = '';
      })
      .addCase(DossierOrgActions.getUserDossierOrgsListSuccess, (state, { payload }) => {
        state.DossierOrgsList = state.DossierOrgsList.concat(payload.data ?? []);
        var list = state.DossierOrgsList;
        if (state.isFavoriteEnabled) {
          list = state.DossierOrgsList.filter((article) => article.isFavorite === true);
        }
        state.favoriteDossierOrgs = mapDossierOrganzationToFavorite(list);
        state.favoriteDossierOrgsCount = state.isFavoriteEnabled
          ? state.favoriteDossierOrgs.length
          : state.DossierOrgsList.filter((article) => article.isFavorite === true).length;
        state.DossierOrgPagination = payload.meta;
        console.log(state.DossierOrgPagination);
        state.isFetchingDossierOrgs = false;
        state.DossierOrgsList = payload.data;
      })
      .addCase(DossierOrgActions.getUserDossierOrgsList, (state, { payload }) => {
        state.isFetchingDossierOrgs = true;
      })
      .addCase(DossierOrgActions.setDossierOrgFavoriteSuccess, (state, { payload }) => {
        state.currentDossierOrg = payload;
        let index = state.DossierOrgsList.findIndex((e) => e.id === payload.dossierOrgId);
        state.DossierOrgsList[index].isFavorite = payload.isFavorite;
        var list = state.DossierOrgsList;
        if (state.isFavoriteEnabled) {
          list = state.DossierOrgsList.filter((dossier, index, self) => dossier.isFavorite === true);
        }
        state.favoriteDossierOrgs = mapDossierOrganzationToFavorite(list);
        state.favoriteDossierOrgsCount = state.isFavoriteEnabled
          ? state.favoriteDossierOrgs.length
          : state.DossierOrgsList.filter((dossier, index, self) => dossier.isFavorite === true).length;
      })
      .addCase(DossierOrgActions.getUserDossierOrgsListFailure, (state, { payload }) => {
        state.isFetchingDossierOrgs = false;
      })
      .addCase(DossierOrgActions.toggleFavoriteDossierOrgs, (state, { payload }) => {
        state.isFavoriteEnabled = payload;
        var list = state.DossierOrgsList;
        if (state.isFavoriteEnabled) {
          list = state.DossierOrgsList.filter((article) => article.isFavorite === true);
        }
        state.favoriteDossierOrgs = mapDossierOrganzationToFavorite(list);
        state.favoriteDossierOrgsCount = state.DossierOrgsList.filter((article) => article.isFavorite).length;
      })
      .addCase(DossierOrgActions.processDossierOrgFailure, (state, { payload }) => {
        state.isProcessingDossierOrg = false;
        state.errorCode = payload.message;
      })
      .addCase(DossierOrgActions.getDossierOrgById, (state, { payload }) => {
        state.currentDossierOrg = undefined;
        state.isFetchingDossierOrgs = true;
      })
      .addCase(DossierOrgActions.getDossierOrgByIdSuccess, (state, { payload }) => {
        state.currentDossierOrg = payload;
        if (
          state.userInput.trim().toLocaleLowerCase() !==
          state?.currentDossierOrg?.organizationInformationDto?.Name.trim().toLocaleLowerCase()
        ) {
          state.userInput = '';
        }
        state.errorCode = '';
        state.isFetchingDossierOrgs = false;
      })
      .addCase(dossierActions.deleteDossier, (state, { payload }) => {
        state.isDeleting = true;
        state.userInput = payload;
      })
      .addCase(dossierActions.deleteDossierSuccess, (state, { payload }) => {
        state.isDeleting = false;
        if (state.currentDossierOrg?.dossierOrgId == payload) {
          state.currentDossierOrg = undefined;
        }
        state.DossierOrgsList = state.DossierOrgsList.filter((e) => e.id != payload);
        state.favoriteDossierOrgs = mapDossierOrganzationToFavorite(
          state.DossierOrgsList.filter((article, index, self) => article.isFavorite === state.isFavoriteEnabled),
        );
        state.favoriteDossierOrgsCount = state.DossierOrgsList.filter(
          (article, index, self) => article.isFavorite === true,
        ).length;
      })
      .addCase(dossierActions.deleteDossierFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(DossierOrgActions.clearDossierOrgHistorySuccess, (state) => {
        state.DossierOrgsList = [];
        state.favoriteDossierOrgs = [];
        state.favoriteDossierOrgsCount = 0;
        state.DossierOrgPagination = {
          page: 1,
          take: 10,
          itemCount: 0,
          hasPreviousPage: false,
          hasNextPage: false,
        };
      })
      .addCase(DossierOrgActions.setDossierOrgFavoriteFailure, (state, { payload }) => {
        state.isFetchingDossierOrgs = false;
        state.errorCode = payload.message;
      })
      .addCase(DossierOrgActions.processDossierOrgEnabled, (state, { payload }) => {
        state.processDossierOrgEnabled = payload;
      })
      .addCase(profileActions.logout, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { processDossierOrg } = DossierOrgSlice.actions;
export const DossierOrgReducer = DossierOrgSlice.reducer;
