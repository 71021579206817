import React from 'react';
import Input from '../Input';
import StringArrayInput from './StringArrayInput';
import { ResponseItem } from '../../common/services/external-search/models/external-search';

interface Props {
  responseItem: ResponseItem;
  onChange: (field: string, value: string | string[] | { url: string; title: string; snippet: string }) => void;
  errors?: { [key: string]: string };
}

const ResponseItemFields: React.FC<Props> = ({ responseItem, onChange, errors }) => {
  return (
    <div className="bg-slate-50 p-2 mt-1 rounded-lg border border-highlight-lightgreen w-2/3">
      <label>Response Item:</label>
      <div className="pt-4 my-1">
        <label htmlFor="Url" className="mb-1 block text-xs text-gray-600 font-sansInte">
          Url:
        </label>
        <Input
          id="url"
          type="text"
          size="sm"
          placeholder="url"
          value={responseItem.url}
          error={!!errors?.url}
          onChange={(e) => onChange('url', e.target.value)}
        />
        <input id="id" type="hidden" />
        {errors && errors.url && <div className="error">{errors.url}</div>}
      </div>

      <div className="pt-4 my-1">
        <label htmlFor="snippet" className="mb-1 block text-xs text-gray-600 font-sansInte">
          Snippet:
        </label>
        <Input
          id="snippet"
          type="text"
          size="sm"
          placeholder="snippet"
          value={responseItem.snippet}
          error={!!errors?.snippet}
          onChange={(e) => onChange('snippet', e.target.value)}
        />
        <input id="id" type="hidden" />
        {errors && errors.snippet && <div className="error">{errors.snippet}</div>}
      </div>

      <div className="pt-4 my-1">
        <label htmlFor="title" className="mb-1 block text-xs text-gray-600 font-sansInte">
          Title:
        </label>
        <Input
          id="title"
          type="text"
          size="sm"
          placeholder="title"
          value={responseItem.title}
          error={!!errors?.title}
          onChange={(e) => onChange('title', e.target.value)}
        />
        <input id="id" type="hidden" />
        {errors && errors.title && <div className="error">{errors.title}</div>}
      </div>
      <StringArrayInput rootData={responseItem.rootData} onChange={onChange} />
      {/* ... (add more fields for rootData, title, snippet) */}
    </div>
  );
};

export default ResponseItemFields;
