import { HttpClient } from '../http';
import { PostUserRequest } from './models/post-user-request';
import { UserResponse } from './models/user-response';
import { PutUserRequest } from './models/put-user-request';
import { UserQueryParams } from './models/user-query-params';
import { User } from '../../models/user/user';
import { UpdateSubscriptionPlan } from '../../../types/models';

class UserServiceImpl {
  get(orgId: string, query: UserQueryParams = { pageSize: 50 }) {
    return HttpClient.get<UserResponse>('users', { portNumber: 4747, params: { orgId, ...query } }).then(
      (response) => response.data,
    );
  }

  getById(id: string) {
    return HttpClient.get<UserResponse>(`user/${id}`).then((response) => response.data);
  }

  post(request: PostUserRequest) {
    return HttpClient.post<User>('user', request, { portNumber: 3002 , showLoader:false, }).then((response) => response.data);
  }
  put(id: string, request: PutUserRequest) {
    return HttpClient.put<UserResponse>(`user/${id}`, request, { portNumber: 4747 }).then((response) => response.data);
  }

  getBillingSessionURL() {
    return HttpClient.post<string>(`user/billing-session-url`, { portNumber: 4747 }).then((response) => response.data);
  }


  updateSubscriptionStatus(request:UpdateSubscriptionPlan) {
    return HttpClient.post<User>(`user/update-subscription`, request, { portNumber: 4747 }).then((response) => response.data);
  }

  delete(userId: string, organizationId: string) {
    return HttpClient.delete<null>(`/user/${userId}/organization/${organizationId}`).then((response) => response.data);
  }
}

const UserService = new UserServiceImpl();

export { UserService };
