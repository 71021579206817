import React from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import {
    ACCESS_PERMISSIONS_MANAGE,
    CLIENT_API_CONFIGURE_EXTERNAL_SEARCH,
    CONFIGURE_EXTERNAL_SEARCH_SOURCE,
  } from '../../config/authorization/PermissionsConstants';
  import {
    ACCESS_MANAGEMENT,
    CLIENT_API_ONBOARDING, 
    EXTERNAL_SEARCH_SOURCE,
    PATTERNS_MANAGEMENT,
  } from '../../common/constants/app-text';
  import {
    APIConfigureIcon,
    AccessControlIcon,
    FileSearchIcon,
    PatternConfigureIcon,
  } from '../../common/icons/Icons';

  const SettingsSubMenu = ({ }) => {
  const settingsSubMenu = [
        {
          to: RoutePath.accessManagementPermissions,
          icon: <AccessControlIcon />,
          label: ACCESS_MANAGEMENT,
          requiredPermissions: [ACCESS_PERMISSIONS_MANAGE],
        },
        {
          to: RoutePath.clientApiOnboarding,
          icon: <APIConfigureIcon />,
          label: CLIENT_API_ONBOARDING,
          requiredPermissions: [CLIENT_API_CONFIGURE_EXTERNAL_SEARCH],
        },
        {
          to: RoutePath.externalSearchSource,
          icon: <FileSearchIcon />,
          label: EXTERNAL_SEARCH_SOURCE,
          requiredPermissions: [CONFIGURE_EXTERNAL_SEARCH_SOURCE],
        }
  ];

  return (
    <ul className="space-y-2 font-medium">
      {settingsSubMenu.map((item, index) => (
        <li key={index}>
          <Link
            to={item.to}
            className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <span className="ml-3">{item.label}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SettingsSubMenu;
