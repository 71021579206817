import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { PatternManagementState } from '../slices/pattern-management.slice';
const selectState: (state: AppState) => PatternManagementState = (state) => state.patternManagement;

export const selectSystemPatterns = createSelector(selectState, (x) => x.systemPatterns);
export const selectUserPatterns = createSelector(selectState, (x) => x.userPatterns);
export const selectIsFetching = createSelector(selectState, (x) => x.isFetching);
export const selectIsUploading = createSelector(selectState, (x) => x.isUploading);
export const selectIsCreating = createSelector(selectState, (x) => x.isCreating);
export const selectError = createSelector(selectState, (x) => x.error);
export const selectUserLibraries = createSelector(selectState, (x) => x.userLibraries);
export const userActiveLibraries = createSelector(selectState, (x) => x.userActiveLibraries);
export const libraryDetails = createSelector(selectState, (x) => x.currentLibrary);

export const patternManagementSelectors = {
  selectSystemPatterns,
  selectUserPatterns,
  selectIsFetching,
  selectIsUploading,
  selectIsCreating,
  selectError,
  selectUserLibraries,
  userActiveLibraries,
  libraryDetails
};