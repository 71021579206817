import { HttpClient } from '../http';

import { User } from '../../models/user/user';
import { RoleDetails } from './models/roles';
import { config } from 'process';

class RoleServiceImpl {
  getById(id: string) {
    return HttpClient.get<RoleDetails>(`/role/${id}`, { portNumber: 4747 }).then((response) => response.data);
  }
  getByName(roleName: string) {
    return HttpClient.get<RoleDetails>(`role/roleByName/${roleName}`, { portNumber: 4747 }).then(
      (response) => response.data,
    );
  }
  getAllRoles() {
    return HttpClient.get<RoleDetails[]>(`roles`, { portNumber: 4747 }).then((response) => response.data);
  }

  updateRolePermissions(roleId: string, permissions: string[]) {
    return HttpClient.put<RoleDetails>(`role/role-permissions/${roleId}`, permissions).then(
      (response) => response.data,
    );
  }
}

const RoleService = new RoleServiceImpl();

export { RoleService };
