import { FC } from 'react';
import Modal from '../Modal';
import { Validators } from '../../../common/helpers/form-controls/validators';
import { useForm } from '../../../common/helpers/form-controls/use-form';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from '../../../redux/actions/layout.actions';
import { SignUpOTPStep } from './SignUpOTPStep';
import { SignUpEmailStep } from './SignUpEmailStep';
import { showOTPScreen } from '../../../redux/selectors/profile.selectors';
import { passwordRecoverSelectors } from '../../../redux/selectors/password-recover.selectors';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../common/constants/route-path';
import { setNotification } from '../../../redux/actions/notification.actions';

interface PropTypes {
  orgId?: string;
  isShown: boolean;
  onClose?: () => void;
}

const SignUpPage = () => {
  var navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(passwordRecoverSelectors.selectToken);
  const enableOTPScreen = useSelector(showOTPScreen);

  const onSignUpClose = () => {
    if (token) {
      dispatch(setNotification({ title: 'Setup password for your account', type: 'success' }));
      navigate(RoutePath.PasswordRecovery);
    }
  };
  return (
    <div className="flex w-100 justify-center text-left items-center animate__animated animate__slideInUp my-10">
      <div className=" bg-white p-5 w-500 h-600 z-10" id="SignUpModal">
        {enableOTPScreen && (
          <div className="">
            <SignUpOTPStep onBackNavigation={onSignUpClose} />
          </div>
        )}
        {!enableOTPScreen && (
          <div className="">
            <SignUpEmailStep onSignInClose={onSignUpClose} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpPage;
