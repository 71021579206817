import React from 'react';
import MainPage from '../../components/MainPage/MainPage';
import { Header } from '../../components/Header';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import { MainMenu } from '../../layouts/Sidenav/MainMenu';
import { Footer } from '../../components/Footer';

export const OnboardClientApp = () => {
  return (
    <MainPage>
      <Header />
      <ContentLayout>
        <MainMenu isPushMenu={true} />
      </ContentLayout>
      <Footer />
    </MainPage>
  );
};
