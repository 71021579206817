// SubscriptionPlans.js
import React, { useState, useEffect } from 'react';
import { paymentService, planDetails } from '../../../common/services/payment/payment.service';
import PlanCard from './PlanCard';
import { useSelector } from 'react-redux';
import { userDetails } from '../../../redux/selectors/profile.selectors';
import { StripePricingTable } from './StripePricingTable';
import Typography from '../../../components/Typography/Ty[pgraphy';
import { useNavigate } from 'react-router-dom';

const SubscriptionPlans = () => {
  const [plans, setPlans] = useState(planDetails);
  const currentLoggedInUser = useSelector(userDetails);
  const router = useNavigate();
//   useEffect(() => {
//     const fetchData = async () => {
//       const planData:any = await paymentService.fetchPlanDetails();
//       setPlans(planData);
//     };

//     fetchData();
//   }, []);

  useEffect(() => {
    if (currentLoggedInUser.isSubscribed) {
      router('/');
    }
  }, [currentLoggedInUser]);



  return (
    <div
  >
    <div className="dark:bg-gray-900 w-full flex justify-center h-full mt-2 overflow-auto" >
      <div className="py-4 px-4 w-full max-w-screen-xl">
        <br/><br/>
        {/* <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12 my-5">
          <Typography className="mb-5 font-light text-secondary-pink">
          Explore our plans. Get access to fact-checkers, prompt protectors, and AI validation tools.
        </Typography>
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold dark:text-white text-primary-blue">
                Choose a subscription plan to get started
          </h2>
              </div> */}
              <StripePricingTable
  pricingTableId="prctbl_1PT7OeP4P8zFznW05DCPxl0j"
  publishableKey="pk_test_51PPNSNP4P8zFznW04PccVncw6qMPXzDyX7pFxTPYAy9uPlLnnrArIEq5uOKzi086gA6tsGihrAsWjhnpVZQmu82800ojiCWz6g"
/>
      </div>
    </div>
    </div>
  );
};

export default SubscriptionPlans;