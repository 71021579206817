import {
  DossierOrganizaiton,
  DossierOrganizaitonLite,
  DossierUpdateRequest,
  HttpErrorResponse,
  ProcessDossierRequest,
  ResultListLite,
} from '../../../../types/models';
import { DashboardMatericsRequestByDateRange, ModuleMetrics } from '../../dashboard/models/dashboard.model';
import { HttpClient } from '../../http';

export const mockDossierOrganization: DossierOrganizaiton = {
  dossierOrgId: '657c5dc5e94bb1eafb523b3f',
  organizationInformationDto: {
    LegalStatus: 'Corporation',
    Name: 'Google Inc.',
    RegistrationDetails: 'Incorporated on September 4, 1998',
  },
  missionAndVisionDto: {
    MissionStatement: ["To organize the world's information and make it universally accessible and useful."],
    VisionStatement: ["To provide access to the world's information in one click."],
    CoreValues: [
      'Focus on the user',
      'Fast is better than slow',
      'Democracy on the web',
      'You can make money without doing evil',
      'Information should be universally accessible',
      "Don't be evil",
    ],
  },
  organizationalStructureDto: {
    Departments: [],
    TeamsAndRoles: [],
    ReportingStructure: [
      'The reporting structure follows a hierarchical model with department heads reporting to the executive team and the executive team reporting to the Board of Directors.',
    ],
  },
  keyPersonnelDto: {
    Founders: ['Larry Page', 'Sergey Brin'],
    ExecutiveTeam: [
      'Sundar Pichai (CEO)',
      'Ruth Porat (CFO)',
      'Kent Walker (SVP of Global Affairs)',
      'Philipp Schindler (SVP and Chief Business Officer)',
    ],
    BoardOfDirectors: [
      'John L. Hennessy (Chairman)',
      'Sundar Pichai',
      'L. John Doerr',
      'Roger W. Ferguson Jr.',
      'Diane B. Greene',
      'Ann Mather',
      'Alan R. Mulally',
      'Kavitark Ram Shriram',
      'Shirley M. Tilghman',
    ],
  },
  historyAndMilestonesDto: {
    FoundingDate: 'September 4, 1998',
    History:
      'Google was founded by Larry Page and Sergey Brin while they were Ph.D. students at Stanford University. It started as a research project to develop a new search engine algorithm. The company quickly gained popularity and expanded its services to include email, online advertising, cloud computing, and various other products and services.',
    Achievements: [
      'Successful IPO in August 2004',
      'Market capitalization of over $23 billion at IPO',
      'Stock price surge to $350 per share in October 2007',
      'Acquisition of various companies including YouTube, Android, and DoubleClick',
      'Transition to Alphabet Inc. as the holding company in 2015',
    ],
  },
  financialInformationDto: {
    RevenueSources: [
      'Online advertising',
      'Cloud computing services',
      'Hardware sales',
      'App and digital content sales',
    ],
    FinancialStatements:
      'Financial statements are publicly available and can be accessed through regulatory filings and investor relations.',
    FundingPartners: [
      'Google has not disclosed specific funding partners as it primarily generates revenue through its operations.',
    ],
  },
  programsAndServicesDto: {
    Overview:
      'Google offers a wide range of products and services including search engine, online advertising platforms, cloud computing, productivity tools, mobile operating system, video sharing platform, and various other web-based applications.',
    Objectives:
      "The objectives of Google's programs and services are to provide users with access to information, facilitate online advertising, enhance productivity, and enable seamless digital experiences.",
    TargetAudience:
      "Google's target audience includes individuals, businesses, advertisers, developers, and content creators.",
    Impact:
      "Google's programs and services have had a significant impact on the way people access and utilize information, conduct online advertising campaigns, collaborate and communicate, and leverage technology for various purposes.",
  },
  partnershipsAndCollaborationsDto: {
    Partnerships: [
      'Google has formed partnerships with various organizations and companies across industries including technology, media, telecommunications, and education.',
    ],
    Collaborations: [
      'Google collaborates with academic institutions, research organizations, and industry leaders to advance technology, promote innovation, and address global challenges.',
    ],
  },
  legalAndComplianceDto: {
    LegalStructure: [
      'Google operates as a corporation under the laws and regulations of the jurisdictions in which it operates.',
    ],
    Compliance: [
      'Google complies with applicable laws and regulations related to data privacy, intellectual property, competition, and other areas of its operations.',
    ],
  },
  mediaCoverageDto: {
    MediaArticles: [
      'Google has been extensively covered in media outlets worldwide, including news articles, features, and analysis on its products, services, business strategies, and impact on society.',
    ],
    PressReleases: [
      'Google regularly issues press releases to announce new products, partnerships, initiatives, and important updates related to its operations.',
    ],
  },
  clienteleAndBeneficiariesDto: {
    Clientele: [
      'Google serves a diverse range of clients including individuals, small and medium-sized businesses, large enterprises, educational institutions, government organizations, and non-profit entities.',
    ],
    Beneficiaries: [
      "The beneficiaries of Google's products and services are users who rely on its search engine, advertising platforms, productivity tools, and other offerings for various purposes.",
    ],
  },
  partnershipsAndStakeholdersDto: {
    GovernmentEntities: [
      'Google collaborates with government entities at local, national, and international levels on various initiatives related to technology, policy, and public welfare.',
    ],
    CommunityGroups: [
      'Google engages with community groups and organizations to support local initiatives, promote digital literacy, and contribute to community development.',
    ],
    DonorsAndSupporters: [],
  },
  updatedAt: '2023-12-15T14:08:05.158Z',
  createdBy: 'Cesar Admin',
  createdAt: '2023-12-15T14:08:05.158Z',
  deletedAt: '',
  progressPercentage: 100,
  isFavorite: false,
  isReqFromApi: false,
};

class DossierOrganizationServiceImpl {
  processDossier(request: ProcessDossierRequest): Promise<DossierOrganizaiton> {
    return HttpClient.post<DossierOrganizaiton>('/dossierOrg', request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  getForDashboard(request: DashboardMatericsRequestByDateRange): Promise<ModuleMetrics> {
    const baseURL = '/dossierOrg/getMetricsByDaterange';
    return HttpClient.post<ModuleMetrics>(baseURL, request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
  getDossierById(id: string): Promise<DossierOrganizaiton> {
    return HttpClient.get<DossierOrganizaiton>(`/dossierOrg/${id}`, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  getDefaultDossier() {
    return mockDossierOrganization;
  }

  getDossierList(page: number = 1, take: number = 10): Promise<ResultListLite<DossierOrganizaitonLite>> {
    const baseURL = '/dossierOrg';
    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}`;
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<ResultListLite<DossierOrganizaitonLite>>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  updateDossier(changes: DossierUpdateRequest): Promise<DossierOrganizaiton> {
    const baseURL = '/dossierOrg';
    let request = {
      isFavorite: changes.isFavorite.toString(),
      progressPercentage: '100',
    };

    return HttpClient.put<DossierOrganizaiton>(`${baseURL}/${changes.id}`, request, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  deleteDossier(id: string): Promise<void> {
    return HttpClient.delete<void>(`/dossierOrg/${id}`, {
      portNumber: 5001,
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
  
  clearHistory(): Promise<boolean> {
    return HttpClient.post<boolean>('/dossierOrg/clear-history', {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

}

const DossierOrganizationService = new DossierOrganizationServiceImpl();

export { DossierOrganizationService };
