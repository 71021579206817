import {
  CreateProjectRequest,
  GetProjectsRequest,
  HttpErrorResponse,
  ProjectUpdateRequest,
  ProjectsLite,
  ResultListLite,
} from '../../../types/models';
import { HttpClient } from '../http';

class ProjectsServiceImpl {
  createProject(request: CreateProjectRequest): Promise<ProjectsLite> {
    return HttpClient.post<ProjectsLite>('/projects', request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  getProjectById(id: string): Promise<ProjectsLite> {
    return HttpClient.get<ProjectsLite>(`/projects/${id}`, { portNumber: 5001 }).then((response) => response.data);
  }

  getProjectsList({
    page = 1,
    take = 50,
    search = '',
    pinToTop,
  }: GetProjectsRequest): Promise<ResultListLite<ProjectsLite>> {
    const baseURL = '/projects';
    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}&search=${search}`;
    if (pinToTop) {
      paginationEndpoint = paginationEndpoint + `&pinToTop=${pinToTop}`;
    }
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<ResultListLite<ProjectsLite>>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  updateProject(request: ProjectUpdateRequest): Promise<ProjectsLite> {
    const baseURL = '/projects';
    return HttpClient.put<ProjectsLite>(`${baseURL}/${request.id}`, request, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  deleteProject(id: string): Promise<void> {
    const baseURL = '/projects';
    return HttpClient.delete<void>(`${baseURL}/${id}`, { portNumber: 5001 })
      .then(() => {})
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
}

const ProjectsService = new ProjectsServiceImpl();

export { ProjectsService };
