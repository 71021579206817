import { createSlice } from '@reduxjs/toolkit';
import { AssertionResult, PaginationConfig, ProjectsLite } from '../../types/models';
import { PROJECTS_STORE_NAME, ProjectsActions } from '../actions/projects.actions';

export interface ProjectsState {
  projects: ProjectsLite[];
  currentProject?: ProjectsLite | any;
  projectsPaginationConfig: PaginationConfig;
  errorCode: string;
  currentArticle: AssertionResult | any;
  loadingProjects: boolean;
  pinnedProjects: ProjectsLite[];
}

const initialState: ProjectsState = {
  projects: [],
  currentProject: {},
  projectsPaginationConfig: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  errorCode: '',
  currentArticle: {},
  loadingProjects: false,
  pinnedProjects: [],
};
export const projectsSlice = createSlice({
  name: PROJECTS_STORE_NAME,
  initialState,
  reducers: {
    createProject: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(ProjectsActions.createProjectSuccess, (state, { payload }) => {
        state.errorCode = '';
        let index = state.projects.findIndex((e) => e.id === payload.id);
        if (index < 0) {
          state.projects.unshift(payload);
        }
      })
      .addCase(ProjectsActions.createProjectFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(ProjectsActions.updateProject, (state, { payload }) => {})
      .addCase(ProjectsActions.updateProjectSuccess, (state, { payload }) => {
        state.errorCode = '';
        let index = state.projects.findIndex((e) => e.id === payload.id);
        if (index >= 0) {
          state.projects[index] = payload;
        }
      })
      .addCase(ProjectsActions.updateProjectFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(ProjectsActions.getProjectsList, (state, { payload }) => {
        state.loadingProjects = true;
      })
      .addCase(ProjectsActions.getProjectsListSuccess, (state, { payload }) => {
        state.errorCode = '';
        state.projects = payload.data;
        state.projectsPaginationConfig = payload.meta;
        state.loadingProjects = false;
      })
      .addCase(ProjectsActions.getProjectsListFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.loadingProjects = false;
      })
      .addCase(ProjectsActions.getPinnedProjects, (state, { payload }) => {
        state.loadingProjects = true;
      })
      .addCase(ProjectsActions.getPinnedProjectsSuccess, (state, { payload }) => {
        state.errorCode = '';
        state.pinnedProjects = payload.data;
        state.loadingProjects = false;
      })
      .addCase(ProjectsActions.getPinnedProjectsFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.loadingProjects = false;
      })
      .addCase(ProjectsActions.getProjectById, (state, { payload }) => {
        state.currentProject = null;
      })
      .addCase(ProjectsActions.getProjectByIdSuccess, (state, { payload }) => {
        state.errorCode = '';
        state.currentProject = payload;
      })
      .addCase(ProjectsActions.getProjectByIdFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(ProjectsActions.getArticleById, (state, { payload }) => {
        state.currentArticle = null;
      })
      .addCase(ProjectsActions.getArticleByIdSuccess, (state, { payload }) => {
        state.errorCode = '';
        state.currentArticle = payload;
      })
      .addCase(ProjectsActions.getArticleByIdFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(ProjectsActions.deleteProject, (state, { payload }) => {
        state.loadingProjects = true;
      })
      .addCase(ProjectsActions.deleteProjectSuccess, (state, { payload }) => {
        state.errorCode = '';
        state.projects = state.projects.filter((e) => e.id !== payload);
        state.loadingProjects = false;
      })
      .addCase(ProjectsActions.deleteProjectFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.loadingProjects = false;
      });
  },
});

export const { createProject } = projectsSlice.actions;
export const projectsReducer = projectsSlice.reducer;
