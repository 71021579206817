import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pinnedProjectsSelector } from '../../redux/selectors/projects.selectors';
import { ProjectsActions } from '../../redux/actions/projects.actions';
import { ProjectsListView } from './ProjectsListView';
import { ProjectDetailsView } from './ProjectDetailsView';
import { CrossIcon } from '../../common/icons/CrossIcon';
import { ProjectsLite } from '../../types/models';

interface CustomTabsProps {
  tabs: ProjectsLite[];
  activeTab: number;
  setActiveTab: (index: number) => void;
  onTabChange: (index: number) => void;
  firstTabTitle: string;
}

const CustomTabs: React.FC<CustomTabsProps> = ({ tabs, activeTab, setActiveTab, onTabChange, firstTabTitle }) => {
  const dispatch = useDispatch();
  return (
    <div className="px-6 flex bg-purple-700">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`mr-1 mt-4 flex justify-center items-center p-2.5 text-sm rounded-t-lg hover:bg-white hover:text-gray-900 ${
            activeTab === index ? 'bg-white text-gray-900' : ' bg-purple-600 text-white'
          }`}
        >
          {tab?.title !== 'Projects' && (
            <div
              className="hover:bg-purple-100 flex justify-center items-center rounded-sm mr-1"
              onClick={() => {
                dispatch(
                  ProjectsActions.updateProject({
                    id: tab.id,
                    pinToTop: false,
                  }),
                );
                if (activeTab === index) {
                  setActiveTab(0);
                } else if (activeTab > index) {
                  setActiveTab(activeTab - 1);
                }
              }}
            >
              <CrossIcon />
            </div>
          )}
          <span onClick={() => onTabChange(index)}>{tab?.title === 'Projects' ? firstTabTitle : tab?.title}</span>
        </div>
      ))}
    </div>
  );
};

const ProjectsTabsView: any = ({ children }: { children: React.ReactNode }) => {
  const Projects = useSelector(pinnedProjectsSelector);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs = [{ title: 'Projects', id: '' }, ...Projects] as ProjectsLite[];

  return (
    <div>
      {Projects.length === 0 ? (
        children ? (
          children
        ) : (
          <ProjectsListView />
        )
      ) : (
        <>
          <CustomTabs
            firstTabTitle={children ? 'Guardrail LLM' : 'Projects'}
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onTabChange={(index) => {
              const selectedTab = tabs[index];
              if (selectedTab?.title !== 'Projects') {
                dispatch(ProjectsActions.getProjectByIdSuccess(selectedTab));
              }
              setActiveTab(index);
            }}
          />
          <div className="">
            {activeTab === 0 ? (
              children ? (
                children
              ) : (
                <ProjectsListView />
              )
            ) : (
              <ProjectDetailsView projectId={tabs[activeTab]?.id} />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default ProjectsTabsView;
