import { HttpClient } from '../http';
import { PostImageResponse } from './model/post-image-response';

export class ImageServiceImpl {
  post(image: File) {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('name', image.name);

    return HttpClient.post<PostImageResponse>('/image/storage', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((response) => response.data);
  }
}

const ImageService = new ImageServiceImpl();

export { ImageService };
