import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useSelector } from 'react-redux';
import CloseButton from '../../components/CloseButton/CloseButton';
import { MainButton } from '../../components/MainButton';
import { Divider } from '../../components/Divider/Divider';
import { ImageUploadIcon, JsonInputIcon } from '../../common/icons/Icons';
import Typography from '../../components/Typography/Ty[pgraphy';
import { CommonLibraryService } from '../../common/services/library/common-library.service';
import { hideLoader, showLoader } from '../../common/services/loader-provider/loaderObservable';
import { PatternTypes } from '../../common/constants/enums/engine-types.enum';
import { Library } from '../../types/models';

interface ImportPatternsProps {
  onImport: (file: File) => void;
  onCancel: () => void;
  isOpen: boolean;
  onPatternLoad?: (library:Library[]) => void;
  patternType?: PatternTypes;
  isBulkUpdate: boolean;
}

const ImportPatterns: React.FC<ImportPatternsProps> = ({
  onImport,
  onPatternLoad,
  onCancel,
  isOpen,
  isBulkUpdate = true,
  patternType = PatternTypes.Custom,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileInput, setFileInput] = useState<File | null>();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setErrorMessage([]);
      setFileInput(event.target.files[0]);
    }
  };

  useEffect(() => {
      setFileInput(null);
  }, [isOpen]);


  const handleClick = () => {
    fileInputRef.current?.click();
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setErrorMessage([]);
      setFileInput(file);
    }
  };

  const onLoadClick = () => {
    if (fileInput) {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const data = JSON.parse(reader.result as string) as  {library:Library[]};
          const errors = [];
          if (!Array.isArray(data.library)) {
            errors.push('The library property must be an array');
          } else {
            data.library.forEach((item: Library, index: number) => {
              let library = index+1;
              if (!item.libraryGroup) {
                errors.push(`Library ${library}: libraryGroup cannot be null`);
              }
             item.patterns.forEach((pattern, patternIndex) => {
              if (!['inline-regex', 'string-array', 'fuzzy-match'].includes(pattern.expressionType)) {
                errors.push(`Library ${library}: expressionType must be one of inline-regex, string-array, fuzzy-match`);
              }
              if (!pattern.label) {
                errors.push(`Library ${library}: label cannot be null`);
              }
              if (!pattern.pattern) {
                errors.push(`Library ${library}: pattern cannot be null`);
              }
             });
            });
          }
          if (errors.length > 0) {
            setErrorMessage(errors);
          } else {
            proceedWithLoad(data.library);
          }
        } catch (error) {
          setErrorMessage(['Failed to parse the JSON file']);
        }
      };
      reader.readAsText(fileInput);
    }
  };
  const proceedWithLoad = (library:Library[]) => {
    if (isBulkUpdate) {
      showLoader();
      let uploadPromose = null;
      if (patternType == PatternTypes.Custom) {
        uploadPromose = CommonLibraryService.uploadCustomPattern(fileInput as File);
      } else if (patternType == PatternTypes.System) {
        uploadPromose = CommonLibraryService.uploadSystemPattern(fileInput as File);
      }
      uploadPromose &&
        uploadPromose
          .then(res => {
            onImport(fileInput as File);
          })
          .finally(() => {
            hideLoader();
          });
    } else {
      onPatternLoad && onPatternLoad(library)
    }
  };

  return (
    <div>
      <Modal isShown={isOpen} animation="fade" centered withBackdrop>
        <div
          className="border rounded-md border-gray-100 border-solid bg-white shadow-md  w-500  "
          id="UploadPatternModel"
        >
          <div className="px-5 pt-6">
            <div className="flex flex-col items-center justify-center">
              <div className="flex justify-between w-full items-center">
                <Typography variant="h2" className="font-semibold text-gray-700">
                  Import Patterns with JSON File
                </Typography>
                <CloseButton onClose={() => onCancel()} />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center my-5">
              <div className="bg-white  rounded-lg  w-full max-w-md">
                <Typography variant="p" className="text-lg font-medium mb-4">
                  Import File
                </Typography>
                {fileInput && (
                  <Typography variant="p" className="text-gray-500 mb-4">
                    {fileInput.name}
                  </Typography>
                )}
                <div
                  className="border-4 border-dashed border-mid-gray rounded-lg p-5 text-center transition-colors duration-300 hover:bg-gray-100 cursor-pointer"
                  onClick={handleClick}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <div className="flex mx-auto mb-4 w-full items-center justify-center ">
                    <ImageUploadIcon />
                  </div>
                  <Typography variant="p" className="text-blue-600 font-medium">
                    Upload a file <span className="text-gray-400">or drag and drop</span>{' '}
                  </Typography>
                  <Typography variant="subtext" className="text-gray-500">
                    JSON file up to 10MB
                  </Typography>
                  <input type="file" className="hidden" ref={fileInputRef} onChange={handleFileChange} accept=".json" />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center mt-2 mb-2 animate__animated animate__fadeInDown">
              <MainButton
                disabled={errorMessage!?.length > 0}
                onClick={onLoadClick}
                className="px-4  mt-2"
                stateProp="rest"
                text="Load File"
              />
            </div>
            <div className="flex justify-start items-center mt-2 mb-2">
              {errorMessage && (
                <ul className="list-disc text-red-500">
                  {errorMessage.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <Divider />
          <div>
            <div className="input-action-item mb-2" onClick={() => {}}>
              <div className="text-wrapper-26">
                <JsonInputIcon />
              </div>
              <div
                className="text-purple-primary cursor-pointer mr-2"
                onClick={() => {
                    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify({
                      "supportedExpressionTypes": {
                        "note": "you can ignore this section",
                        "inline-regex": {
                          "comment": "Regular expression pattern",
                          "supported": true,
                          "example": ".*"
                        },
                        "string-array": {
                          "comment": "strings with array pattern",
                          "supported": true,
                          "example": [
                            "string1",
                            "string2"
                          ]
                        },
                        "fuzzy-match": {
                          "comment": "Fuzzy match pattern",
                          "supported": true,
                          "example": [
                            "string1",
                            "string2"
                          ]
                        }
                      },
                      "library": [
                        {
                        "libraryType": "System",
                        "libraryGroup": "APAC PII",
                        "libraryComment": "Asia-Pacific Personal Identity",
                        "patterns": [
                          {
                            "label": "Indian Aadhaar Card Number (space delimited)",
                            "type": "RegEx",
                            "description": "Aadhaar is a 12 digit unique-identity number issued to all Indian residents based on their biometric and demographic data. The data is collected by the Unique Identification Authority of India (UIDAI).",
                            "pattern": "\\d{4}\\s\\d{4}\\s\\d{4}",
                            "expressionType": "inline-regex"
                          },
                          {
                            "label": "Indian Aadhaar Card Number (dash delimited)-22",
                            "type": "RegEx",
                            "description": "Aadhaar is a 12 digit unique-identity number issued to all Indian residents based on their biometric and demographic data. The data is collected by the Unique Identification Authority of India (UIDAI).",
                            "pattern": "\\d{4}-\\d{4}-\\d{4}-\\d{4}",
                            "expressionType": "inline-regex",
                            "action":{
                              "action":"test",
                              "RGB":"#FF0000"
                            }
                          },
                          {
                            "label": "Indian Aadhaar Card Number - 2",
                            "type": "RegEx",
                            "description": "Aadhaar is a 12 digit unique-identity number issued to all Indian residents based on their biometric and demographic data. The data is collected by the Unique Identification Authority of India (UIDAI).",
                            "pattern": "\\d{4}-\\d{4}-\\d{4}-\\d{4}",
                            "expressionType": "inline-regex"
                          }
                        ]
                        }
                      ]
                    }));
                    var downloadAnchorNode = document.createElement('a');
                    downloadAnchorNode.setAttribute("href",     dataStr);
                    downloadAnchorNode.setAttribute("download", 'pattern-template' + ".json");
                    document.body.appendChild(downloadAnchorNode); 
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();
                }}
              >
                <Typography>Download JSON template</Typography>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImportPatterns;
