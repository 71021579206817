import {
  ClientAppDto,
  ClientAppResponseDto,
  ClientAppUpdateDto,
} from '../../common/services/client-app/models/client-app';
import { HttpErrorResponse } from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const CLIENT_APP_STORE_NAME = 'ClientApp Store';

const createPayloadAction = getCreatePayloadActionFromStore(CLIENT_APP_STORE_NAME);
const createAction = getCreateActionFromStore(CLIENT_APP_STORE_NAME);

const initialize = createAction('initialize');

const getClientAppById = createPayloadAction<string>('Get Client App By Id');
const getClientAppByIdSuccess = createPayloadAction<ClientAppResponseDto>('Get Default Success');
const getClientAppByIdFailure = createPayloadAction<HttpErrorResponse>('Get Default Failure');

const getClientAppByUserId = createAction('Get Client App By User Id');
const getClientAppByUserIdSuccess = createPayloadAction<ClientAppResponseDto>('Get by user Success');
const getClientAppByUserIdFailure = createPayloadAction<HttpErrorResponse>('Get by user Failure');

const postClientApp = createPayloadAction<ClientAppDto>('On-boarded ClientApp');

// const postClientAppEnabled = createPayloadAction<boolean>(
//   'On-boarded ClientApp'
// );
const postClientAppSuccess = createPayloadAction<ClientAppResponseDto>('On-boarding Success');
const postClientAppFailure = createPayloadAction<HttpErrorResponse>('On-boarding Failure');

const updateClientApp = createPayloadAction<ClientAppUpdateDto>('update ClientApp');
const updateClientAppSuccess = createPayloadAction<ClientAppResponseDto>('update ClientApp Success');
const updateClientAppFailure = createPayloadAction<HttpErrorResponse>('update ClientApp Failure');

const deleteClientApp = createPayloadAction<string>('delete ClientApp');
const deleteClientAppSuccess = createPayloadAction<void>('delete ClientApp Success');
const deleteClientAppFailure = createPayloadAction<HttpErrorResponse>('delete ClientApp Failure');

export const clientAppActions = {
  initialize,
  postClientApp,
  postClientAppSuccess,
  postClientAppFailure,
  getClientAppById,
  getClientAppByIdSuccess,
  getClientAppByIdFailure,
  getClientAppByUserId,
  getClientAppByUserIdSuccess,
  getClientAppByUserIdFailure,
  updateClientApp,
  updateClientAppSuccess,
  updateClientAppFailure,
  deleteClientApp,
  deleteClientAppSuccess,
  deleteClientAppFailure,
};
