import { createSlice } from '@reduxjs/toolkit';
import { LAYOUT_STORE_NAME, layoutActions } from '../actions/layout.actions';
import { ACCESS_MANAGEMENT_STORE_NAME, accessManagementActions } from '../actions/access-management.actions';
import { PermissionMapping, RoleDetails } from '../../common/services/roles/models/roles';
import { createPermissionMapping } from '../../common/helpers/Helper';
import { UserResponse } from '../../common/services/users/models/user-response';
import { profileActions } from '../actions/profile.actions';

export interface AccessManagementState {
  permissions: string[];
  isFetching: boolean;
  applicationRoles: RoleDetails[];
  permissionMappings: PermissionMapping[];
  usersResponse: UserResponse;
  isUpdatingUser: boolean;
}

export const initialAccessManagementState: AccessManagementState = {
  permissions: [],
  applicationRoles: [],
  usersResponse: {} as UserResponse,
  isFetching: false,
  isUpdatingUser: false,
  permissionMappings: [],
};
const accessManagementSlice = createSlice({
  name: ACCESS_MANAGEMENT_STORE_NAME,
  initialState: initialAccessManagementState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(accessManagementActions.getApplicationPermissionsSuccess, (state, { payload }) => {
        state.permissions = payload;
      })
      .addCase(accessManagementActions.getApplicationRolesSuccess, (state, { payload }) => {
        state.applicationRoles = payload;
        console.log('permissions Mapping');
      })
      .addCase(accessManagementActions.getApplicationPermissionsMappings, (state) => {
        state.isFetching = true;
      })
      .addCase(accessManagementActions.getApplicationPermissionsMappingsSuccess, (state, { payload }) => {
        state.permissionMappings = payload;
        state.isFetching = false;
        console.log('permissions Mapping');
      })
      .addCase(accessManagementActions.getApplicationPermissionsMappingsFailure, (state) => {
        state.isFetching = true;
      })
      .addCase(accessManagementActions.getUsersSuccess, (state, { payload }) => {
        state.isFetching = false;
        state.usersResponse = payload;
      })
      .addCase(accessManagementActions.getUsers, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(accessManagementActions.getUsersFailure, (state, { payload }) => {
        state.isFetching = false;
      })
      .addCase(accessManagementActions.updateUser, (state, { payload }) => {
        state.isUpdatingUser = true;
      })
      .addCase(accessManagementActions.updateUserSuccess, (state, { payload }) => {
        state.usersResponse = {
          ...state.usersResponse,
          users: state.usersResponse.users.map((user) => (user.id === payload.id ? payload : user)),
        };
        state.isUpdatingUser = false;
      })
      .addCase(accessManagementActions.updateUserFailure, (state, { payload }) => {
        state.isUpdatingUser = false;
      }).addCase(profileActions.logout, (state) => {
        Object.assign(state, initialAccessManagementState);
      });
  },
});

export const accessManagementReducer = accessManagementSlice.reducer;
