import { HttpClient } from '../http';
import { ClientAppResponseDto, ClientAppUpdateDto, ClientAppDto } from './models/client-app';
import { HttpErrorResponse } from '../../../types/models';

class ClientAppServiceImpl {
  postClientApp(request: ClientAppDto): Promise<ClientAppResponseDto> {
    return HttpClient.post<ClientAppResponseDto>('/clientApp', request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  async getClientAppById(id: string) {
    const baseURL = '/clientApp/';
    return HttpClient.get<ClientAppResponseDto>(`/${baseURL}/${id}`, {
      portNumber: 5001,
    }).then((response) => response.data);
  }

  async getClientAppByUserId() {
    const baseURL = '/clientApp/created/user/';
    return HttpClient.get<ClientAppResponseDto>(`/${baseURL}`, {
      portNumber: 5001,
    }).then((response) => response.data);
  }

  async updateClientApp(request: ClientAppUpdateDto, id: string): Promise<ClientAppResponseDto> {
    const baseURL = 'clientApp';
    return HttpClient.put<ClientAppResponseDto>(`/${baseURL}/${id}`, request).then((response) => response.data);
  }

  async deleteClientApp(id: string): Promise<void> {
    HttpClient.delete<ClientAppResponseDto>(`/clientApp/${id}`, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
}

const ClientAppService = new ClientAppServiceImpl();

export { ClientAppService };
