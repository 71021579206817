import { useRef, useEffect, useState } from 'react';
import { MORE_RESOURCES, UNLOCK_FEATURES, LOREM_IPSUM_SHORT } from '../../common/constants/app-text';
import { FolderMappings, HierarchyTree, HundredScore } from '../../common/icons/Icons';
import { FeatureCard } from '../FeatureCard/FeatureCard';
import { PrimaryButton } from '../PrimaryButton';
import { SecondaryButton } from '../SecondaryButton';
import { UnlockFeaturesBanner } from '../UnlockFeaturesBanner/UnlockFeaturesBanner';

interface Props {
  className?: string;
  isRowLayout?: boolean;
}

export const FeaturesList = ({ className = '', isRowLayout = true }: Props): JSX.Element => {
  const animatedDivRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
            const animationClasses = ['animate__zoomIn'];
            const animationClass =
              (entry.target as HTMLElement).dataset.animation ||
              animationClasses[Math.floor(Math.random() * animationClasses.length)];
            entry.target.classList.add('animate__animated', animationClass);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 },
    );

    animatedDivRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      animatedDivRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div className={`features-container ${className}`}>
      <div className={`flex flex-col md:flex-row justify-between py-4`}>
        <div
          className={`grid ${isRowLayout ? 'grid-cols-1' : 'grid-cols-3'} gap-6 py-4 animate__animated`}
          data-animation="animate__zoomIn"
          ref={(el) => (animatedDivRefs.current[0] = el)}
        >
          <FeatureCard
            title="Save your search history"
            backgroundColor="#d6f6f9"
            description={LOREM_IPSUM_SHORT}
            icon={<FolderMappings />}
          />
          <FeatureCard
            title="Hierarchical results"
            backgroundColor="#ffe5e3"
            description={LOREM_IPSUM_SHORT}
            icon={<HierarchyTree />}
          />
          <FeatureCard
            title="Visualize similarity score"
            backgroundColor="#ecf1f8"
            description={LOREM_IPSUM_SHORT}
            icon={<HundredScore />}
          />
        </div>
      </div>
    </div>
  );
};
