import React from 'react';

interface HighlightSubstringsProps {
  paragraph: string;
  substrings: string[];
}

const colors = ['#d6f5f8', '#ffe5e3', '#f6eaff', '#ecf0f7', '#f6f9ec', '#ecf1f8', '#f3f6e6', '#f0ebeb', '#f8e8e8'];

const HighlightSubstrings: React.FC<HighlightSubstringsProps> = ({ paragraph, substrings }) => {
  const sortedSubstrings = substrings
    .map((sub) => ({ index: paragraph.indexOf(sub), text: sub }))
    .filter((sub) => sub.index !== -1)
    .sort((a, b) => a.index - b.index);

  let highlightedParagraph = [];
  let start = 0;

  sortedSubstrings.forEach((sub, index) => {
    // Randomly pick a color from the array.
    const color = colors[Math.floor(Math.random() * colors.length)];

    const highlightStyle = {
      backgroundColor: color,
      padding: '5px',
      marginRight: '2px',
    };

    // Append the text before the substring.
    if (sub.index > start) {
      highlightedParagraph.push(paragraph.substring(start, sub.index));
    }

    // Append the substring with highlight.
    const substringWithHighlight = (
      <span key={index} style={highlightStyle}>
        {sub.text}
      </span>
    );
    highlightedParagraph.push(substringWithHighlight);

    // Update the start index.
    start = sub.index + sub.text.length;
  });

  // Append the remaining text.
  if (start < paragraph.length) {
    highlightedParagraph.push(paragraph.substring(start));
  }

  return <p className=" text-sm ">{highlightedParagraph}</p>;
};

export default HighlightSubstrings;
