import { useState, useContext, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { layoutMainMenu } from '../../redux/selectors/layout.selectors';
import { layoutActions } from '../../redux/actions/layout.actions';
import {
  CodeGrayIcon,
  ContradictionFinderIcon,
  DashboardIcon,
  FactCheckerIcon,
  FederatedIcon,
  PatternConfigureIcon,
  ProjectsIcon,
  PromptProtectIcon,
  SettingsIcon,
  SummarizationIcon,
} from '../../common/icons/Icons';
import { RoutePath } from '../../common/constants/route-path';
import {
  AIPROJECTS,
  CODE_ANALYZER,
  CONTRADICTION_FINDER,
  DASHBOARD,
  DOSSIER_GENERATOR,
  FACT_CHECKER,
  FEDERATED,
  GATEWAY123,
  PATTERNS_MANAGEMENT,
  PROMPT_PROTECT,
  SETTINGS,
  SUBSCRIPTION_PLANS,
  SUMMARIZATION,
} from '../../common/constants/app-text';
import {
  ACCESS_GUARDRAIL123,
  ACCESS_PATTERN_MANAGEMENT,
  ACCESS_PERMISSIONS_MANAGE,
  API_GATEWAY_ACCESS,
  CODE_ANALYZER_ACCESS,
  CONTRADICTION_FINDER_CREATE,
  DOSSIER_GENERATOR_CREATE,
  FACT_CHECKER_CREATE,
  PROJECTS_ACCESS,
  PROMPT_PROTECT_CREATE,
  SETTINGS_ACCESS,
  SUMMARIZATION_ACCESS,
} from '../../config/authorization/PermissionsConstants';
import { AuthContext } from '../../config/authorization/AuthContext';
import { Divider } from '../../components/Divider/Divider';
import SettingsSubMenu from './Settings';
import { userDetails } from '../../redux/selectors/profile.selectors';

export const MainMenu = ({
  isPushMenu = true,
  topMargin = true,
}: {
  isPushMenu?: boolean;
  topMargin?: boolean;
}): JSX.Element => {
  const menuRef = useRef(null);
  const authContext = useContext(AuthContext);
  const [showSettingsSubMenu, setShowSettingsSubMenu] = useState(false);
  const currentLoggedInUser = useSelector(userDetails);
  const dispatch = useDispatch();
  const showMenu = useSelector(layoutMainMenu);
  const menuItems = [
    {
      to: RoutePath.Landing,
      icon: <DashboardIcon />,
      label: DASHBOARD,
      requiredPermissions: [],
    },
    {
      to: RoutePath.Gateway123,
      icon: <ProjectsIcon />,
      label: GATEWAY123,
      requiredPermissions: [ACCESS_GUARDRAIL123],
    },
    {
      to: RoutePath.LLMResult,
      icon: <FederatedIcon />,
      label: FEDERATED,
      requiredPermissions: [API_GATEWAY_ACCESS],
    },
    {
      to: RoutePath.FactChecker,
      icon: <FactCheckerIcon />,
      label: FACT_CHECKER,
      requiredPermissions: [FACT_CHECKER_CREATE],
    },
    {
      to: RoutePath.Dossier,
      icon: <FactCheckerIcon />,
      label: DOSSIER_GENERATOR,
      requiredPermissions: [DOSSIER_GENERATOR_CREATE],
    },
    {
      to: RoutePath.ContradictionFinder,
      icon: <ContradictionFinderIcon />,
      label: CONTRADICTION_FINDER,
      requiredPermissions: [CONTRADICTION_FINDER_CREATE],
    },
    {
      to: RoutePath.PromptProtect,
      icon: <PromptProtectIcon />,
      label: PROMPT_PROTECT,
      requiredPermissions: [PROMPT_PROTECT_CREATE],
    },
    {
      to: RoutePath.CodeAnalyzer,
      icon: <CodeGrayIcon />,
      label: CODE_ANALYZER,
      requiredPermissions: [CODE_ANALYZER_ACCESS],
    },
    {
      to: RoutePath.Summarization,
      icon: <SummarizationIcon />,
      label: SUMMARIZATION,
      requiredPermissions: [SUMMARIZATION_ACCESS],
    },
    {
      to: RoutePath.Projects,
      icon: <ProjectsIcon />,
      label: AIPROJECTS,
      requiredPermissions: [PROJECTS_ACCESS],
    },
    {
      to: RoutePath.patternConfiguration,
      icon: <PatternConfigureIcon />,
      label: PATTERNS_MANAGEMENT,
      requiredPermissions: [ACCESS_PATTERN_MANAGEMENT],
    },
  ];

  const secondMenu = [
    {
      to: '',
      icon: <SettingsIcon />,
      label: SETTINGS,
      requiredPermissions: [SETTINGS_ACCESS],
    },
  ];

  useEffect(() => {
    if (!currentLoggedInUser.isSubscribed) {
      const exists = menuItems.some((item) => item.to === RoutePath.Plans);
      if (!exists) {
        menuItems.push({
          to: RoutePath.Plans,
          icon: <PatternConfigureIcon />,
          label: SUBSCRIPTION_PLANS,
          requiredPermissions: [],
        });
      }
    }
  }, [currentLoggedInUser, menuItems]);

  function closeMenu() {
    if (showMenu) {
      dispatch(layoutActions.toggleLayoutMenu());
    }
  }
  const menuContainerStyles = isPushMenu
    ? 'w-64 bg-white dark:bg-blue-800 text-white p-5 z-10 min-w-[250px] border-r border-gray-300 shadow-md'
    : `absolute left-0 h-full w-64 ${
        topMargin ? '-mt-1' : '-mt-16'
      } bg-white dark:bg-blue-800 text-white p-5 z-10 min-w-[250px] border-r border-gray-300 shadow-md`;

  return (
    <div ref={menuRef} className={`flex  h-full w-full  ${isPushMenu ? 'h-screen' : 'h-full'}`}>
      {showMenu && (
        <div className={menuContainerStyles}>
          {/* Your menu items here */}
          <ul className="space-y-2 font-medium justify-start ">
            {menuItems.map(
              (item, index) =>
                (!item.requiredPermissions.length ||
                  (authContext && authContext.checkPermission(item.requiredPermissions))) && (
                  <li key={index}>
                    <Link
                      to={item.to}
                      onClick={closeMenu}
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white text-start hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      {item.icon}
                      <span className="ml-3">{item.label}</span>
                    </Link>
                  </li>
                ),
            )}
          </ul>
          <Divider />
          <ul className="space-y-2 font-medium">
            {secondMenu.map(
              (item, index) =>
                (!item.requiredPermissions.length ||
                  (authContext && authContext.checkPermission(item.requiredPermissions))) && (
                  <li key={index}>
                    <Link
                      to={item.to}
                      onClick={() => setShowSettingsSubMenu(!showSettingsSubMenu)}
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      {item.icon}
                      <span className="ml-3">{item.label}</span>
                    </Link>
                  </li>
                ),
            )}
          </ul>
          {showSettingsSubMenu && <SettingsSubMenu />}
        </div>
      )}
      <div className="flex-grow w-full">
        <div onClick={closeMenu}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
