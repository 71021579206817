import { createSlice } from '@reduxjs/toolkit';
import { LAYOUT_STORE_NAME, layoutActions } from '../actions/layout.actions';

export interface LayoutState {
  showSignInModal: boolean;
  showSignUpModal: boolean;
  showGuestSignUpModal: boolean;
  showUsersArticles: boolean;
  layoutMainMenu: boolean;
  showDeleteModal: boolean;
}

export const initialLayoutState: LayoutState = {
  showSignInModal: false,
  showSignUpModal: false,
  layoutMainMenu: false,
  showGuestSignUpModal: false,
  showUsersArticles: true,
  showDeleteModal: false,
};
const layoutSlice = createSlice({
  name: LAYOUT_STORE_NAME,
  initialState: initialLayoutState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(layoutActions.setShowSignInModal, (state, { payload }) => {
        state.showSignInModal = payload;
        state.showSignUpModal = false;
      })
      .addCase(layoutActions.setShowSignUpModal, (state, { payload }) => {
        state.showSignInModal = false;
        state.showSignUpModal = payload;
      })
      .addCase(layoutActions.setShowGuestSignUpModal, (state, { payload }) => {
        state.showGuestSignUpModal = payload;
      })
      .addCase(layoutActions.toggleLayoutMenu, (state) => {
        state.layoutMainMenu = !state.layoutMainMenu;
        // if(state.showUsersArticles && state.layoutMainMenu){
        //   state.showUsersArticles = false;
        // }
      })
      .addCase(layoutActions.toggleUsersArticles, (state, { payload }) => {
        state.showUsersArticles = payload;
        state.layoutMainMenu = false;
      })
      .addCase(layoutActions.setShowDeleteModal, (state, { payload }) => {
        state.showDeleteModal = payload;
        state.layoutMainMenu = false;
      });
  },
});

export const layoutReducer = layoutSlice.reducer;
