import {
  CodeAnalysisResult,
  HttpErrorResponse,
  ResultListLite,
  DossierUpdateRequest,
  CodeAnalysisInput,
  UserHistoryList,
  BillOfLaddingResult,
  CodeRiskAssessmentResult,
  ReferenceFinderResult,
  CodeAnalysisEnteryRequest,
  CodeAnalysisLightResponse,
} from '../../../types/models';
import { AssessmentOptions } from '../../constants/default-values';
import { DashboardMatericsRequestByDateRange, ModuleMetrics } from '../dashboard/models/dashboard.model';
import { HttpClient } from '../http';

class CodeAnalysisProtectServiceImpl {
  createCodeAnalysisEntry(request: CodeAnalysisEnteryRequest): Promise<CodeAnalysisLightResponse> {
    return HttpClient.post<CodeAnalysisLightResponse>('/code-analysis', request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  processCodeLadding(inputCode: string): Promise<BillOfLaddingResult> {
    const request = {
      code: inputCode,
    };
    return HttpClient.post<BillOfLaddingResult>('/ladding', request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  processRiskAssessment(
    inputCode: string,
    riskAssessment: string[],
    language: string,
  ): Promise<CodeRiskAssessmentResult> {
    const request = {
      code_block: inputCode,
      language_selection: language,
      check_filter: riskAssessment,
      chunk_size: 25,
    };

    return HttpClient.post<CodeRiskAssessmentResult>('/risk-assessment', request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  processReferenceFinder(inputCode: string): Promise<ReferenceFinderResult> {
    const request = {
      code: inputCode,
    };

    return HttpClient.post<ReferenceFinderResult>('/code-reference', request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  clearHistory(): Promise<boolean> {
    return HttpClient.post<boolean>('/code-analysis/clear-history', {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  getCodeAnalysisReportById(id: string): Promise<CodeAnalysisResult> {
    return HttpClient.get<CodeAnalysisResult>(`/code-analysis/${id}`, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  getDefaultCodeAnalysis() {
    return null;
  }

  getForDashboard(request: DashboardMatericsRequestByDateRange): Promise<ModuleMetrics> {
    const baseURL = '/code-analysis/getMetricsByDaterange';
    return HttpClient.post<ModuleMetrics>(baseURL, request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  getCodeAnalysisList(page: number = 1, take: number = 10): Promise<ResultListLite<UserHistoryList>> {
    const baseURL = '/code-analysis';
    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}`;
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<ResultListLite<UserHistoryList>>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  updateCodeAnalysis(changes: DossierUpdateRequest): Promise<CodeAnalysisResult> {
    const baseURL = '/code-analysis';
    let request = {
      isFavorite: changes.isFavorite.toString(),
      progressPercentage: '100',
    };

    return HttpClient.put<CodeAnalysisResult>(`${baseURL}/${changes.id}`, request, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }
  deleteCodeAnalysis(id: string): Promise<void> {
    const baseURL = '/code-analysis';
    return HttpClient.delete<void>(`${baseURL}/${id}`, { portNumber: 5001 })
      .then(() => {})
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
}

const CodeAnalysisProtectService = new CodeAnalysisProtectServiceImpl();

export { CodeAnalysisProtectService };
