import { HttpClient } from '../http';

import { HttpErrorResponse } from '../../../types/models';
import { ExternalSearchSource, ExternalSearchSourceLightList } from './models/external-search';

class ExternalSearchServiceImpl {
  async postExternalSearch(request: ExternalSearchSource): Promise<ExternalSearchSource> {
    return HttpClient.post<ExternalSearchSource>('/externalSearchApi', request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  async getExternalSearchById(id: string) {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = '/externalSearchApi/';
    return HttpClient.get<ExternalSearchSource>(`/${baseURL}/${id}`, {
      portNumber: 5001,
    }).then((response) => response.data);
  }

  async getExternalSearchSourceLightList(page: number = 1, take: number = 20): Promise<ExternalSearchSourceLightList> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = '/externalSearchApi/';
    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}`;
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<ExternalSearchSourceLightList>(paginationEndpoint, {
      portNumber: 5001,
    }).then((response) => response.data);
  }

  async postExternalSearchApiValidation(id: string): Promise<boolean> {
    return HttpClient.post<boolean>(`/externalSearchApi/validate/${id}`, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  async postExternalSearchApiCloning(id: string): Promise<ExternalSearchSource> {
    return HttpClient.post<ExternalSearchSource>(`/externalSearchApi/clone/${id}`, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  async deleteExternalSearchApi(id: string): Promise<void> {
    HttpClient.delete<ExternalSearchSource>(`/externalSearchApi/${id}`, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  async updateExternalSearch(request: ExternalSearchSource, id: string): Promise<ExternalSearchSource> {
    const baseURL = 'externalSearchApi';
    return HttpClient.put<ExternalSearchSource>(`/${baseURL}/${id}`, request).then((response) => response.data);
  }
}

const ExternalSearchService = new ExternalSearchServiceImpl();

export { ExternalSearchService };
