import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';
import { UserPreferenceRequest } from '../../common/services/user-preferences/models/user-preference-request';
import {
  DashboardMatericsRequestByDateRange,
  ModuleMetrics,
} from '../../common/services/dashboard/models/dashboard.model';

export const DASHBOARD_STORE_NAME = 'dashboard';

const createPayloadAction = getCreatePayloadActionFromStore(DASHBOARD_STORE_NAME);
const createAction = getCreateActionFromStore(DASHBOARD_STORE_NAME);
const getDashboardMetrics = createPayloadAction<DashboardMatericsRequestByDateRange>(
  'Get dashboard metrics with date range',
);
const getDashboardMetricsSuccess = createPayloadAction<ModuleMetrics[]>('Get dashboard metrics success');
const getDashboardMetricsFailure = createPayloadAction('Get dashboard metrics failure');

const setProfileCompletionValue = createPayloadAction<number>('Set Profile Completion');

export const dashboardActions = {
  getDashboardMetrics,
  getDashboardMetricsSuccess,
  getDashboardMetricsFailure,
  setProfileCompletionValue,
};
