import { useEffect, useState } from 'react';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import MainPage from '../../components/MainPage/MainPage';
import ProfilePage from '../../components/ViewProfile/ProfileOverriew';
import { MainMenu } from '../../layouts/Sidenav/MainMenu';
import PermissionsView from './permissions/permissionsView';
import { useDispatch } from 'react-redux';
import { accessManagementActions } from '../../redux/actions/access-management.actions';
import { tab } from '@testing-library/user-event/dist/tab';
import { RolesView } from './roles/rolesView';
import { CustomerSupportIcon, DashboardNewIcon, SettingsNewIcon, UserProfileIcon } from '../../common/icons/Icons';
import { Outlet, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';

export const AccessManagementView = (): JSX.Element => {
  return (
    <MainPage>
      <Header />
      <ContentLayout>
        <MainMenu isPushMenu={true} />
      </ContentLayout>
      <Footer />
    </MainPage>
  );
};

export const AccessManagementMainView = (): JSX.Element => {
  const tabsList = {
    permissions: 'Permissions',
    roles: 'Roles',
    users: 'Users',
    requests: 'Requests',
  };

  const [selectedTab, setSelectedTab] = useState(tabsList.permissions);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  useEffect(() => {
    dispatch(accessManagementActions.getApplicationPermissionsMappings());
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md mb-2 p-4">
      <div className="md:flex">
        <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
          {Object.entries(tabsList).map(([tabKey, tabLabel]) => (
            <li key={tabKey}>
              <button
                onClick={() => {
                  let defaultLink = RoutePath.accessManagementPermissions;
                  switch (tabLabel) {
                    case tabsList.requests:
                      defaultLink = RoutePath.accessManagementSupport;
                      break;
                    case tabsList.roles:
                      defaultLink = RoutePath.accessManagementRole;
                      break;
                    case tabsList.users:
                      defaultLink = RoutePath.accessManagementUsers;
                      break;
                  }
                  navigate(defaultLink);
                  setSelectedTab(tabLabel);
                }}
                className={`inline-flex items-center px-4 py-3 rounded-lg ${
                  selectedTab === tabLabel
                    ? 'bg-blue-700 text-white dark:bg-blue-600'
                    : 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 hover:text-gray-900  dark:hover:bg-gray-700 dark:hover:text-white'
                } w-full`}
              >
                {getTabIcon(tabKey, selectedTab)}
                {tabLabel}
              </button>
            </li>
          ))}
        </ul>
        <div className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

// Helper function to get corresponding icon for each tab
const getTabIcon = (tabKey: string, selectedTab: string) => {
  let isActive = tabKey === selectedTab;
  switch (tabKey) {
    case 'users':
      return <SettingsNewIcon />;
    case 'roles':
      return <DashboardNewIcon active={isActive} />;
    case 'requests':
      return <CustomerSupportIcon />;
    default:
      return <UserProfileIcon />;
  }
};

// Replace with the appropriate icon component or library for roles
const PlaceholderIcon = () => <span>Role Icon</span>;
