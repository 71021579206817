import { ProfileErrorCode } from './profile-error-code';

export const UserNotFoundOrIncorrectPasswordErrorMessage = 'Wrong Email or Password, please try again.';
export const UserDoesNotBelongAnyOrganizationErrorMessage =
  'Error logging in. Your account is not part of an organization. If you think this is a mistake please contact us.';
export const UnknownErrorMessage = 'Unknown error. Please try again later.';

export const getProfileErrorMessage = (errorCode: ProfileErrorCode) => {
  return {
    [ProfileErrorCode.UserNotFoundOrIncorrectPassword]: UserNotFoundOrIncorrectPasswordErrorMessage,
    [ProfileErrorCode.UserDoesNotBelongAnyOrganization]: UserDoesNotBelongAnyOrganizationErrorMessage,

    [ProfileErrorCode.UnknownError]: UnknownErrorMessage,
    [ProfileErrorCode.None]: '',
    [ProfileErrorCode.UserExists]: 'User already exists',
    [ProfileErrorCode.UnAuthorized]: 'Unauthorized',
  }[errorCode];
};
