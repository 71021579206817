import { ImageBox } from '../../components/ImageBox/ImageBox';
import { GuardRailIcon, BardIcon } from '../icons/Icons';
import { SourceType } from './enums/engine-types.enum';

import ChatGpt from '../../static/img/chat-gpt.png';
import ChatGptBlack from '../../static/img/chat-gpt-black.png';
import CoPilot from '../../static/img/copilot-new.png';

const LLMModels = [
  {
    type: 'ChatGPT',
    displayName: 'Chat GPT 3',
    icon: <ImageBox className="w-7 h-7" url={ChatGpt} />,
    color: 'text-green-500',
    hoverColor: 'hover:text-green-600',
    disabled: false,
    sourceType: SourceType.GPT3,
  },
  {
    type: 'Chat GPT 4',
    displayName: 'Chat GPT 4',
    icon: <ImageBox className="w-7 h-7" url={ChatGptBlack} />,
    color: 'text-green-500',
    hoverColor: 'hover:font-bold',
    disabled: false,
    sourceType: SourceType.GPT4,
  },
  {
    type: 'Gemini',
    displayName: 'Gemini',
    icon: (
      <div className="w-7 h-7">
        <BardIcon />
      </div>
    ),
    color: 'text-pink-500',
    hoverColor: 'hover:text-pink-600',
    disabled: false,
    sourceType: SourceType.Gemini,
  },
  {
    type: 'Guardrail LLM',
    displayName: 'Guardrail LLM',
    icon: (
      <div className="w-7 h-7">
        <GuardRailIcon />
      </div>
    ),
    color: 'text-blue-500',
    hoverColor: 'hover:text-blue-600',
    disabled: true,
    sourceType: SourceType.Guardrail,
  },
  {
    type: 'Microsoft Copilot',
    displayName: 'Microsoft Copilot',
    icon: <ImageBox className="h-7 w-7" url={CoPilot} />,
    color: 'text-blue-500',
    hoverColor: 'hover:text-blue-600',
    disabled: true,
    sourceType: SourceType.Copilot,
  },
];

export default LLMModels;
