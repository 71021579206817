import { Module, Action } from './AuthContext';
import React, { useState, useEffect, useContext, Suspense } from 'react';
import UnauthorizedPage, { UnauthorizedView } from './UnauthorizedPage';
import { AuthContext } from './AuthContext';
import { hideLoader, showLoader } from '../../common/services/loader-provider/loaderObservable';
import AppLoader from '../../components/AppLoader/AppLoader';
import { LoadingIndicator } from '../../components/AppLoader/LoadingIndicator';

interface WithAuthorizationCheckProps {
  requiredPermissions: { module: Module; action: Action | any }[];
  children: React.ReactNode;
}

const WithAuthorizationCheck: React.FC<WithAuthorizationCheckProps> = ({ requiredPermissions, children }) => {
  const authContext = useContext(AuthContext);

  const permissions = authContext && authContext.permissionList;

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    showLoader('Authorizing...');
  }, []);

  useEffect(() => {
    hideLoader();
    if (permissions) {
      setIsLoading(false);
      setIsAuthorized(authContext && authContext.checkPermission(requiredPermissions));
    }
  }, [permissions, requiredPermissions]);

  if (isLoading) {
    return <LoadingIndicator message={'Authorizing...'} />;
  }
  return isAuthorized ? (
    <>
      <Suspense
        fallback={
          <>
            <LoadingIndicator message={'Authorizing...'} />
          </>
        }
      >
        {children}
      </Suspense>
    </>
  ) : (
    <UnauthorizedView></UnauthorizedView>
  );
};

export default WithAuthorizationCheck;
