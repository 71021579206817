import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Userpreferencesservice } from '../../common/services/user-preferences/user-preference-service';
import { UserPreferenceRequest } from '../../common/services/user-preferences/models/user-preference-request';
import { userPreferencesActions } from '../actions/user-preference.actions';
import { setNotification } from '../actions/notification.actions';
import { accessManagementActions } from '../actions/access-management.actions';
import { RoleDetails, UpdateUserPermissionsRequest } from '../../common/services/roles/models/roles';
import { RoleService } from '../../common/services/roles/roles.service';
import { createPermissionMapping } from '../../common/helpers/Helper';
import { PermissionService } from '../../common/services/permissions/permissions.service';
import { PermissionDetails } from '../../common/services/permissions/models/permissions';
import { UserService } from '../../common/services/users/user.service';
import { activeOrganizationId, userDomainName } from '../selectors/profile.selectors';
import { UserResponse } from '../../common/services/users/models/user-response';
import { UserQueryParams } from '../../common/services/users/models/user-query-params';
import { PutUserRequest } from '../../common/services/users/models/put-user-request';
import { User } from '../../common/models/user/user';

function* updateUserRolePermissions({ payload }: PayloadAction<UpdateUserPermissionsRequest>) {
  try {
    yield call(RoleService.updateRolePermissions, payload.roleId, payload.permissions);
    yield put(accessManagementActions.getApplicationPermissionsMappings());
    yield put(setNotification({ title: 'Permissions Updated', type: 'success' }));
    yield put(accessManagementActions.updateRolePermissionsSuccess());
  } catch (error) {
    yield put(setNotification({ title: 'Unable to update permissions', type: 'error' }));
    yield put(accessManagementActions.updateRolePermissionsSuccess());
  }
}

function* getUsers({ payload }: PayloadAction<UserQueryParams>) {
  try {
    const domainName: string = yield select(userDomainName);
    const orgId: string = yield select(activeOrganizationId);
    payload.domainName = domainName;
    const response: UserResponse = yield call(UserService.get, orgId, payload);

    yield put(accessManagementActions.getUsersSuccess(response));
  } catch (error) {
    yield put(accessManagementActions.getUsersFailure(error));
  }
}

function* updateUser({ payload }: PayloadAction<PutUserRequest>) {
  try {
    const orgId: string = yield select(activeOrganizationId);
    payload.orgId = orgId;
    const updatedUser: User = yield call(UserService.put, payload.id, payload);
    yield put(accessManagementActions.updateUserSuccess(updatedUser));
    yield put(setNotification({ title: 'User Updated', type: 'success' }));
  } catch (error) {
    yield put(setNotification({ title: 'Unable to update user', type: 'error' }));
    yield put(accessManagementActions.updateUserFailure());
  }
}

function* getApplicationPermissionsMappings() {
  try {
    //get User Roles
    const roleDetails: RoleDetails[] = yield call(RoleService.getAllRoles);
    let query = { page: 1, pageSize: 25 } as UserQueryParams;

    yield put(accessManagementActions.getUsers(query));
    yield put(accessManagementActions.getApplicationRolesSuccess(roleDetails));

    //get Application Permissions
    const permissionsDTO: PermissionDetails[] = yield call(PermissionService.getAllPermissions);
    const permissions = permissionsDTO.map((permission) => permission.name);
    yield put(accessManagementActions.getApplicationPermissionsSuccess(permissions));
    const permissionMapping = createPermissionMapping(roleDetails, permissions);
    yield put(accessManagementActions.getApplicationPermissionsMappingsSuccess(permissionMapping));
  } catch (error) {
    console.error('Error getting user permission mappins:', error);
    yield put(accessManagementActions.getApplicationPermissionsMappingsFailure);
  }
}

export default function* watcher() {
  yield takeLatest(accessManagementActions.getApplicationPermissionsMappings.type, getApplicationPermissionsMappings);
  yield takeLatest(accessManagementActions.getUsers.type, getUsers);
  yield takeLatest(accessManagementActions.updateUser.type, updateUser);
  yield takeLatest(accessManagementActions.updateRolePermissions.type, updateUserRolePermissions);
}
