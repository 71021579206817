import { HttpClient } from '../http';

import { User } from '../../models/user/user';
import { PermissionDetails } from './models/permissions';
import { config } from 'process';

class PermissionServiceImpl {
  getAllPermissions() {
    return HttpClient.get<PermissionDetails[]>(`permissions`, { portNumber: 4747 }).then((response) => response.data);
  }
}

const PermissionService = new PermissionServiceImpl();

export { PermissionService };
