import { AppState } from '../../../redux/types/App.type';
import { HttpClient } from '../http';
import { Domain } from './models/domain.model';

class DomainServiceImpl {
  getDomainById(id: string): Promise<Domain> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = '/domain/domainName/';
    return HttpClient.get<Domain>(`/${baseURL}/${id}`, { portNumber: 5001 }).then((response) => response.data);
  }

  getDomainList(): Promise<Domain[]> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = '/domain';
    return HttpClient.get<Domain[]>(baseURL, { portNumber: 5001 }).then((response) => response.data);
  }

  getDefaultDomain(): Domain {
    return {
      id: '653535765bc92f426a1d7966',
      name: 'Legal',
      createdBy: 'Lokesh kumar',
      createdAt: '2023-10-22T14:45:10.532Z',
      themes: [
        {
          title: 'Assess the origin of Legal queries',
          subTitle: 'Using Legal API calls',
          paragraph: 'Legal queries are asserted using the ballot box API calls',
          isActive: true,
        },
      ],
    };
  }

  getDefaultDomainFromAPI(): Promise<Domain> {
    return HttpClient.get<Domain>('/defaultarticle', { portNumber: 5001 }).then((response) => response.data);
  }
}
const DomainService = new DomainServiceImpl();

export { DomainService };
