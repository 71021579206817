export const MAX_RESULTS_TO_SHOW = 5;
export const MAX_SOURCES_TO_SHOW = 4;

export const DossierTypes = {
  Person: 'person',
  Organization: 'organization',
};

export const ColorCodesForDashboard = {
  BLUE: '#008ffb',
  GREEN: '#00e396',
  ORANGE: '#feb019',
  RED: '#ff4560',
  VAILET: '#775dd0',
  LIGHT_BLUE: '#36a2eb',
  LIGHT_GREEN: '#4bc0c0',
  LIGHT_ORANGE: '#ffcd56',
  LIGHT_RED: '#ff6384',
  PRIMARY_BLUE: '#0065ff',
  PRIMARY_PINK: '#BB00FF',
};

export enum ModulesListEnum {
  FactChecker = 'Fact Checker',
  Dossier = 'Dossier Person',
  DossierOrg = 'Dossier Organization',
  PromptProtect = 'Prompt Protect',
  ContradictionFinder = 'Contradiction Finder',
  Unknown = 'Unknown',
}

export enum ResultIndicatorColors {
  GREEN = '#B7ECF1',
  RED = '#F7CECC',
}
export enum AssessmentOptions {
  CommonApiSecurityPitfalls = 'Other common API security pitfalls',
  InsufficientLoggingAndMonitoring = 'Insufficient logging and monitoring',
  SecurityMisconfiguration = 'Security misconfiguration',
  BadCoding = 'Bad coding',
  FlawedEndpointSecurity = 'Flawed endpoint security',
  InadequateValidation = 'Inadequate validation',
  LackOfProperUserManagement = 'Lack of proper user management',
  SensitiveDataExfiltration = 'Sensitive data exfiltration',
}

export const colors = ['#d6f5f8', '#ffe5e3', '#f6eaff', '#ecf0f7', '#f6f9ec', '#ecf1f8', '#f3f6e6', '#f3f6e6'];
