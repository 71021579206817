import React from 'react';

interface Props {
  index: number;
  currentIndex: number;
  colorCode: string;
  setCurrentIndex: (index: number) => void;
}

const CountIndicator: React.FC<Props> = ({ index, currentIndex, colorCode, setCurrentIndex }) => {
  return (
    <div key={index} className={`mr-1 p-1 border-2 border-gray-200 dark:border-gray-700 rounded-t-lg`}>
      <button
        aria-current="page"
        className={`inline-block p-4 text-blue-600 bg-gray-100 border-b border-gray-100 dark:bg-gray-800 dark:text-blue-500 custom-nav-tab ${currentIndex === index ? 'nav-border-active' : 'nav-border'}`}
        onClick={() => setCurrentIndex(index)}
      >
        <div className="ellipse-6" style={{ backgroundColor: colorCode }} />
        <div className="text-wrapper-5">{index + 1}</div>
      </button>
    </div>
  );
};

export default CountIndicator;
