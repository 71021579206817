import PropTypes from 'prop-types';
import React from 'react';
import { useReducer } from 'react';
import './style.css';
import { LoadingIcon } from '../../common/icons/Icons';
import { LOADING_TEXT } from '../../common/constants/app-text';

interface Props {
  stateProp: 'disabled' | 'rest' | 'hover';
  className: any;
  text: string;
  onClick?: any;
  disabled?: boolean;
  isLoading?: boolean;
}

export const MainButton = ({
  stateProp,
  className,
  disabled = false,
  isLoading = false,
  text = 'Get Assertions',
  onClick,
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || 'rest',
  });

  return (
    <button
      disabled={disabled || isLoading}
      className={`flex items-start justify-center  px-4 relative z-50 rounded-md p-3 cursor-pointer bg-gradient-to-b from-purple-500 to-blue-500 ${state.state} ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      onMouseLeave={() => {
        dispatch('mouse_leave');
      }}
      onMouseEnter={() => {
        dispatch('mouse_enter');
      }}
      onClick={onClick}
    >
      <React.Fragment>
        {isLoading && <LoadingIcon />}
        <span className="text-center text-white font-sans">{isLoading ? LOADING_TEXT : text}</span>
      </React.Fragment>
    </button>
  );
};

function reducer(state: any, action: any) {
  switch (action) {
    case 'mouse_enter':
      return {
        ...state,
        state: 'hover',
      };

    case 'mouse_leave':
      return {
        ...state,
        state: 'rest',
      };
  }

  return state;
}

MainButton.propTypes = {
  stateProp: PropTypes.oneOf(['disabled', 'rest', 'hover']),
  text: PropTypes.string,
};
