import React from 'react';
import { TickMark } from '../../common/icons/Icons';
import { useNavigate } from 'react-router-dom';

interface Props {
  steps: string[];
  completedStep: number;
}

const HorizontalStepper: React.FC<Props> = ({ steps, completedStep }) => {
  const navigate = useNavigate();
  const completedIndex = completedStep - 1;
  return (
    <div className="w-full flex items-center mb-6">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`flex items-center ${index < completedIndex ? 'cursor-pointer ' : ''}`}
          onClick={() => {
            if (index < completedIndex) {
              navigate(-(completedIndex - index));
            }
          }}
        >
          <div
            className={`h-4 w-4 p-[2px] rounded-full flex items-center justify-center mr-2 ${
              completedIndex === index
                ? 'bg-purple'
                : completedStep > index
                ? 'bg-purple-lite'
                : 'bg-white border border-purple-lite border-solid'
            }`}
          >
            {completedIndex > index ? (
              <TickMark color="white" />
            ) : (
              <span className={` text-xs ${completedIndex === index ? 'text-white' : 'text-purple-lite'}`}>
                {index + 1}
              </span>
            )}
          </div>
          <p className={`text-sm ${completedIndex === index ? 'text-purple font-bold' : 'text-purple-lite'} `}>
            {step}
          </p>
          {index !== steps.length - 1 && <div className={`h-0.5 w-6 mx-2 bg-purple-lite`} />}
        </div>
      ))}
    </div>
  );
};

export default HorizontalStepper;
