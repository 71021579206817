import React, { useState } from 'react';
import Input from '../Input';

interface Props {
  rootData: string[];
  onChange: (field: string, value: string | string[] | { url: string; title: string; snippet: string }) => void;
}

const StringArrayInput: React.FC<Props> = ({ rootData, onChange }) => {
  const [newItem, setNewItem] = useState('');

  const handleAddItem = () => {
    if (newItem && newItem.trim() !== '') {
      onChange('rootData', [...rootData, newItem]);
      setNewItem('');
    }
  };

  const handleRemoveItem = (index: number) => {
    const updatedArray = [...rootData];
    updatedArray.splice(index, 1);
    onChange('rootData', [...updatedArray]);
  };

  return (
    <div className="p-3">
      <label className="">Root data:</label>
      <div className="flex flex-wrap">
        {rootData.map((item, index) => (
          <div className="flex mr-1 mb-1" key={index}>
            <div className="mr-1 ">{item}</div>
            <button type="button" onClick={() => handleRemoveItem(index)}>
              <span className="text-lg">🗑</span>
            </button>
          </div>
        ))}
      </div>
      <div className="flex flex-row  ">
        <Input type="text" size="sm" value={newItem} onChange={(e) => setNewItem(e.target.value)} className="w-3/4" />
        <button type="button" onClick={handleAddItem}>
          <span className="text-lg px-2 ">➕</span>
        </button>
      </div>
    </div>
  );
};

export default StringArrayInput;
