import  React, {  useEffect, useState } from 'react';
import { loaderObservable } from '../../common/services/loader-provider/loaderObservable';
import { LoadingIndicator } from './LoadingIndicator';


const AppLoader = ({message=""}) => {
    const [loaderState, setLoaderState] = useState({ isLoading: false, message: message });

    useEffect(() => {
      const subscription = loaderObservable.subscribe((state:any) => {
        setLoaderState(state);
      });
  
      return () => subscription.unsubscribe();
    }, []);
  return loaderState.isLoading ? (<LoadingIndicator  message={loaderState.message} />) : null;
};

export default AppLoader;