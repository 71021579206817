import { DEFAULT_DOMAIN_ID } from '../../constants/app-text';
import { HttpClient } from '../http';
import { UserPreferenceRequest } from './models/user-preference-request';

class UserpreferencesserviceImpl {
  getUserPreferenceList(): Promise<UserPreferenceRequest> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = '/userPreference';
    return HttpClient.get<UserPreferenceRequest>(`/${baseURL}/`, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  CreateorUpdateUserPreference(updatePreferenceRequest: UserPreferenceRequest): Promise<UserPreferenceRequest> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = '/userPreference';
    return HttpClient.post<UserPreferenceRequest>(baseURL, updatePreferenceRequest, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  getDefaultUserPreference(): UserPreferenceRequest {
    return {
      id: '',
      domainId: DEFAULT_DOMAIN_ID,
      language: 'en',
      timeZone: 'PST',
      preferenceFlagsDto: [
        { name: 'isActive', value: 'true' },
        { name: 'isTwoFactorAuthentication', value: 'true' },
      ],
    };
  }
}
const Userpreferencesservice = new UserpreferencesserviceImpl();

export { Userpreferencesservice };
