import { ValidationArguments } from './index';

export function equalToField<ValidatedObject>(field: keyof ValidatedObject) {
  return (value: string, validationArguments: ValidationArguments<ValidatedObject>): boolean => {
    if (!value || !validationArguments.object[field]) return false;

    return value === String(validationArguments.object[field]);
  };
}

export function notEqualToField<ValidatedObject>(field: keyof ValidatedObject) {
  return (value: string, validationArguments: ValidationArguments<ValidatedObject>): boolean => {
    if (!value || !validationArguments.object[field]) return true;

    return value !== String(validationArguments.object[field]);
  };
}
