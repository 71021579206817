import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../common/constants/route-path';
import SearchInput from '../../../components/Search/SearchInput';
import CheckboxWithLabel from '../../../components/Base/CheckBoxWithLabel';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { EditIcon, InfoIcon, JsonInputIcon, PlusIcon } from '../../../common/icons/Icons';
import { LibraryMap, LibraryMapLite } from '../../../types/models';
import { useDispatch } from 'react-redux';
import { patternManagementActions } from '../../../redux/actions/pattern-management.actions';
import { PatternTypes } from '../../../common/constants/enums/engine-types.enum';
import IconButton from '../../../components/IconButton/IconButton';
import ImportPatterns from '../ImportPatterns';
import { setNotification } from '../../../redux/actions/notification.actions';

interface CustomPatternLibrariesProps {
  customPatterns:LibraryMapLite[];
  onCreateNew: () => void;
}

const CustomPatternLibraries: React.FC<CustomPatternLibrariesProps> = ({ customPatterns, onCreateNew }) => {
  const [showEditIcon, setShowEditIcon] = React.useState<number | null>(null);
  const [showImportModel, setShowImportModel] = React.useState<boolean>(false);
  let router = useNavigate();
  let dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(customPatterns);
  useEffect(() => {
    let newFilteredItems = customPatterns;
    if (searchTerm) {
      newFilteredItems = newFilteredItems.filter(item =>
        item.libraryGroup?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
    }
    setFilteredItems(newFilteredItems);
  }, [searchTerm, customPatterns]);
  return (
    <div className="bg-white p-4 mt-2">
      <h2 className="text-lg  mb-4 font-semibold">Custom Pattern Libraries</h2>
      <p className="text-sm text-gray-600 mb-4 font-openSans">
        Create your own pattern libraries.{' '}
        <a href="#" className="text-blue-500 hover:underline">
          Learn more
        </a>
      </p>
      <div className="flex space-x-4 justify-between ">
        <SearchInput className="my-2" placeholder="Search By Name"  onChange={value => setSearchTerm(value)} value={searchTerm} />
        <div className='flex items-center '>
          <div>
          <IconButton
                icon={<JsonInputIcon />}
                text="Import Libraries With JSON"
                onClick={()=>{
                  setShowImportModel(true);
                }}
              />
          </div>
          <div className='mx-2'>
          <PrimaryButton
            className=""
            size="small"
            stateProp="rest"
            iconPosition="left"
            icon={<PlusIcon />}
            enableFullWidth={false}
            style="boxed"
            text="Create New"
            onClick={() => {
              router(RoutePath.patternConfigurationAdd);
            }}
          />
          </div>
        </div>
      </div>

      <div className="flex  space-y-2 flex-wrap">
        {filteredItems.length === 0 ? (
          <p className="w-full flex justify-center text-gray-500">No patterns to show</p>
        ) : (
          filteredItems.map((pattern, index) => (
            <div
              key={index}
              className="px-4 py-2 flex w-[300px] justify-between items-center"
              onMouseEnter={() => setShowEditIcon(index)}
              onMouseLeave={() => setShowEditIcon(null)}
            >
              <CheckboxWithLabel
                isChecked={pattern.isSelected}
                label={pattern.libraryGroup}
                showInfoIcon={false}
                onCheckboxChange={() => {
                  let payload = {
                    isEnabled: !(pattern.isSelected ?? false),
                    libraryId: pattern.id,
                    patternType: PatternTypes.Custom,
                    libraryName: pattern.libraryGroup,
                  } as LibraryMap;
                  dispatch(patternManagementActions.setPatternSelection(payload));
                }}
              />
              {showEditIcon === index && (
                <div className="pr-4">
                  <NavLink to={`edit-pattern/${pattern.id}`}>
                    <EditIcon />
                  </NavLink>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      <ImportPatterns
        isOpen={showImportModel}
        isBulkUpdate={true}
        onCancel={() => {
          setShowImportModel(false);
        }} 
        onImport={function (file: File): void {
          setShowImportModel(false);
          dispatch(patternManagementActions.initilizeLibraries());
          dispatch(setNotification({ text: 'Pattern Library Imported Successfully', type: 'success' }));
        } }      />
    </div>
  );
};

export default CustomPatternLibraries;
