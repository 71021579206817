import { ArticleUpdateRequest, ContradictionLite, ContradictionResult, HttpErrorResponse } from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const CONTRADICTION_FINDER_STORE_NAME = 'Contradiction Store';

const createPayloadAction = getCreatePayloadActionFromStore(CONTRADICTION_FINDER_STORE_NAME);
const createAction = getCreateActionFromStore(CONTRADICTION_FINDER_STORE_NAME);

const getDefaultContradiction = createAction('Get Default Contradiction');
const getDefaultContradictionSuccess = createPayloadAction<ContradictionResult>('Get Default Success');
const getDefaultContradictionFailure = createPayloadAction<HttpErrorResponse>('Get Default Failure');

const processContradiction = createPayloadAction<string>('Process Contradictions');
const setPromptContent=createPayloadAction<string>("Edit Contradictions");

const processContradictionEnabled = createPayloadAction<boolean>('Process Contradictions Enabled');
const processContradictionSuccess = createPayloadAction<ContradictionResult>('Process Success');
const processContradictionFailure = createPayloadAction<HttpErrorResponse>('Process Failure');

const getContradictionById = createPayloadAction<string>('Get Contradiction By Id');
const getContradictionByIdSuccess = createPayloadAction<ContradictionResult>('get Contradiction By Id Success');
const getContradictionByIdFailure = createPayloadAction<HttpErrorResponse>('get Contradiction By Id Failure');

const getUserContradictionsList = createPayloadAction<number>('Get User Contradictions List');
const getUserContradictionsListSuccess = createPayloadAction<ContradictionLite>('Get User Contradictions List Success');
const getUserContradictionsListFailure = createPayloadAction<HttpErrorResponse>('Get User Contradictions List Failure');

const setContradictionFavorite = createPayloadAction<ArticleUpdateRequest>('Set Contradiction Favorite');
const setContradictionFavoriteSuccess = createPayloadAction<ContradictionResult>('Set Contradiction Favorite Success');
const setContradictionFavoriteFailure = createPayloadAction<HttpErrorResponse>('Set Contradiction Favorite Failure');

const toggleFavoriteContradictions = createPayloadAction<boolean>('Toggle Favorite Contradictions');
const clearContradictionHistory = createAction('Clear contradictioin history');
const clearContradictionHistorySuccess = createAction('Clear contradiction history success');

const deleteContradiction = createPayloadAction<string>('Delete Contradiction');
const deleteContradictionSuccess = createPayloadAction<string>('Delete Contradiction Success');
const deleteContradictionFailure = createPayloadAction<HttpErrorResponse>('Delete Contradiction Failure');

const clearContradictionHistoryFailure = createAction('Clear contradiction history failure');

export const contradictionActions = {
  processContradiction,
  processContradictionSuccess,
  processContradictionEnabled,
  processContradictionFailure,
  getUserContradictionsList,
  getUserContradictionsListSuccess,
  getUserContradictionsListFailure,
  getDefaultContradiction,
  getDefaultContradictionSuccess,
  getDefaultContradictionFailure,
  getContradictionById,
  getContradictionByIdSuccess,
  getContradictionByIdFailure,
  setContradictionFavorite,
  setContradictionFavoriteSuccess,
  setContradictionFavoriteFailure,
  toggleFavoriteContradictions,
  setPromptContent,
  clearContradictionHistory,
  clearContradictionHistorySuccess,
  clearContradictionHistoryFailure,
  deleteContradiction,
  deleteContradictionSuccess,
  deleteContradictionFailure,
};
