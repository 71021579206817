import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { DossierOrgState } from '../slices/dossier-org.slice';
import {
  mapDossierFinancialInformation,
  mapDossierLegalAndCompliance,
  mapDossierMediaCoverage,
  mapDossierOrgHeader,
  mapDossierOrganizationProgramAndServices,
  mapDossierSkillsAndExpertise,
  mapDossierclienteleAndBeneficiaries,
  mapKeyPersonsSelector,
  mapOrganizationMissionAndVision,
} from '../../common/services/dossier/person/dossier.helper';

const selectDossierOrgState: (state: AppState) => DossierOrgState = (state) => state.dossierOrg;
export const currentDossierOrg = createSelector(selectDossierOrgState, ({ currentDossierOrg }) => currentDossierOrg);
export const processDossierOrgEnabled = createSelector(
  selectDossierOrgState,
  ({ processDossierOrgEnabled }) => processDossierOrgEnabled,
);
export const dossierOrgErrorCodes = createSelector(selectDossierOrgState, ({ errorCode }) => errorCode);
export const defaultDossierOrg = createSelector(selectDossierOrgState, ({ defaultDossierOrg }) => defaultDossierOrg);
export const userDossierOrgsList = createSelector(selectDossierOrgState, ({ DossierOrgsList }) => DossierOrgsList);
export const isProcessingDossierOrg = createSelector(
  selectDossierOrgState,
  ({ isProcessingDossierOrg }) => isProcessingDossierOrg,
);
export const dossierOrgProcessingInput = createSelector(selectDossierOrgState, ({ userInput }) => userInput);
export const isFetchingDossierOrgs = createSelector(
  selectDossierOrgState,
  ({ isFetchingDossierOrgs }) => isFetchingDossierOrgs,
);
export const favoriteDossierOrgPagination = createSelector(
  selectDossierOrgState,
  ({ DossierOrgPagination }) => DossierOrgPagination,
);
export const favoriteDossierOrgSelector = createSelector(
  selectDossierOrgState,
  ({ favoriteDossierOrgs }) => favoriteDossierOrgs,
);
export const favoritesDossierOrgCount = createSelector(
  selectDossierOrgState,
  ({ favoriteDossierOrgsCount }) => favoriteDossierOrgsCount,
);
export const dossierOrganizatonHeaderSelector = createSelector(selectDossierOrgState, ({ currentDossierOrg }) =>
  mapDossierOrgHeader(currentDossierOrg),
);
export const missionAndVisionSelector = createSelector(selectDossierOrgState, ({ currentDossierOrg }) =>
  mapOrganizationMissionAndVision(currentDossierOrg),
);
export const keyPersonsSelector = createSelector(selectDossierOrgState, ({ currentDossierOrg }) =>
  mapKeyPersonsSelector(currentDossierOrg),
);
export const dossierOrganizationFinancialInformationSelector = createSelector(
  selectDossierOrgState,
  ({ currentDossierOrg }) => mapDossierFinancialInformation(currentDossierOrg),
);
export const skillsAndExpertiseSelector = createSelector(selectDossierOrgState, ({ currentDossierOrg }) =>
  mapDossierOrganizationProgramAndServices(currentDossierOrg),
);
export const dossierPartnershipsAndCollaborationsSelector = createSelector(
  selectDossierOrgState,
  ({ currentDossierOrg }) => mapDossierSkillsAndExpertise(currentDossierOrg),
);
export const dossierLegalAndComplianceSelector = createSelector(selectDossierOrgState, ({ currentDossierOrg }) =>
  mapDossierLegalAndCompliance(currentDossierOrg),
);
export const dossierMediaCoverageSelector = createSelector(selectDossierOrgState, ({ currentDossierOrg }) =>
  mapDossierMediaCoverage(currentDossierOrg),
);
export const dossierClienteleAndBeneficiariesSelector = createSelector(selectDossierOrgState, ({ currentDossierOrg }) =>
  mapDossierclienteleAndBeneficiaries(currentDossierOrg),
);
export const dossierPartnershipsAndStakeholdersSelector = createSelector(
  selectDossierOrgState,
  ({ currentDossierOrg }) => mapDossierclienteleAndBeneficiaries(currentDossierOrg),
);
