import React from 'react';
import './UnauthorizedPage.css';
import MainPage from '../../components/MainPage/MainPage';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { MainMenu } from '../../layouts/Sidenav/MainMenu';
import UnauthorizaedImage from '../../static/img/unauthorized.jpeg';
const UnauthorizedPage = () => {
  return (
    <div className="flex flex-col justify-center items-center  h-full">
      <img src={UnauthorizaedImage} className=" max-h-500 max-w-500" alt=" UnAuthorized 403" />
      <div className="content">
        <p>You do not have permission to view this page.</p>
        <p>
          Please contact your administrator for assistance
          {/* <Link to="/">home page</Link>. */}
        </p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;

export const UnauthorizedView = () => {
  return (
    <MainPage>
      <Header />
      <ContentLayout>
        <UnauthorizedPage />
      </ContentLayout>
      <Footer />
    </MainPage>
  );
};
