import React, { useState, useMemo } from 'react';
import { extractMainDomain, extractBaseUrl, getFormatedDate } from '../../common/helpers/Helper';
import { Result } from '../../types/models';
import { CONTRADICTIONS, NEGATIONS_FOUND } from '../../common/constants/app-text';
import { FactCheckerIcon, InfoIcon } from '../../common/icons/Icons';
import { ContradictionNegationType } from '../../common/constants/enums/engine-types.enum';
import { Dropdown } from 'flowbite-react';

interface Props {
  assertion?: any;
}

interface ContradictionResultItemProps {
  result: Result;
}

const FilterComponent = ({ filter, setFilter }: any) => (
  <Dropdown
    label={filter}
    className="border shadow border-grey"
    style={{
      color: '#181D2C',
      borderColor: '#E1E4EC',
      minWidth: '350px',
      textAlign: 'left',
      justifyContent: 'left',
      alignItems: 'left',
    }}
  >
    <Dropdown.Header onClick={() => setFilter('all')}>View all negation types</Dropdown.Header>
    <Dropdown.Header onClick={() => setFilter(ContradictionNegationType.Wildcard)}>Wildcard negations</Dropdown.Header>
    <Dropdown.Header onClick={() => setFilter(ContradictionNegationType.Contextual)}>
      Contextual negations
    </Dropdown.Header>
    <Dropdown.Header onClick={() => setFilter(ContradictionNegationType.RulesBased)}>
      Rules based negations
    </Dropdown.Header>
  </Dropdown>
);

const ContradictionResultItem: React.FC<ContradictionResultItemProps> = React.memo(
  ({ result }: ContradictionResultItemProps) => {
    const mainDomain = extractMainDomain(result.source);
    return (
      <tr className="tablerowborder">
        <td className=" align-top">
          <div className="flex items-center p-2">
            <div className="text-wrapper-12 mr-2">
              <a
                href={`/engine/dossier-generator?SearchText=${mainDomain}&&Type=Organization`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FactCheckerIcon />
              </a>
            </div>
            <div className="div-2">
              <span className="text-wrapper-13">{mainDomain}</span>
              <div className="text-wrapper-14">
                {/* this is not collapsing because there are no spaces in the text */}
                {extractBaseUrl(result.source)}
              </div>
            </div>
          </div>
        </td>

        <td className="align-top">
          <div className="frame-8 flex items-center p-2">
            <div className="min-w-5 mr-1">
              <InfoIcon />
            </div>
            <p className="text-wrapper-16">
              <a target="blank" href={result.source}>
                {result.source}
              </a>
            </p>
          </div>
        </td>
        <td className=" align-top pb-12">
          <div className="text-wrapper-17 p-2 pb-0 ">{getFormatedDate(new Date())}</div>
        </td>
        <td className="align-top">
          <p className="text-wrapper-18 p-2 pb-0">“{result.excerpt}”</p>
        </td>
        <td className="align-top">
          <div className="flex items-end flex-col pt-3">
            <div className="frame-10">
              <div className="text-wrapper-19">{result.score.toFixed(2)}</div>
            </div>
          </div>
        </td>
      </tr>
    );
  },
);

export const ContradictionResultsList = ({ assertion }: Props): JSX.Element => {
  const [filter, setFilter] = useState<string>('all');
  const filteredAssertionsList = useMemo(() => {
    if (filter === 'all') {
      return assertion;
    }
    return assertion?.filter((a: any) => a.negation_type === filter);
  }, [assertion, filter]);

  return (
    <div className="assertion-container-main">
      <div className="assertion-container">
        <div className="flex items-center justify-between space-x-4 mt-3">
          <div className="flex gap-2">
            <div className="text-black font-bold text-lg">{CONTRADICTIONS}</div>
            <div className="text-purple-600 text-sm mt-1">
              {filteredAssertionsList?.length} {NEGATIONS_FOUND}
            </div>
          </div>
          <div className="w-300">
            <FilterComponent filter={filter} setFilter={setFilter} />
          </div>
        </div>
      </div>
      {filteredAssertionsList.map((assert: any, index: any) => {
        return (
          <>
            <div className="flex items-center space-x-4 mt-3">
              <div className="p-wrapper" style={{ backgroundColor: '#FFE5E3' }}>
                <p className="p whitespace-pre-wrap break-words ">
                  <span className="text-black font-bold">
                    {assert.negation_type !== ContradictionNegationType.None ? (
                      <>
                        <span>{assert.negation_type} </span>
                      </>
                    ) : (
                      ''
                    )}
                    negation {index + 1} :
                  </span>
                  " {assert?.negation} "
                </p>
              </div>
            </div>
            <div className="relative mb-10">
              <div className="rectangle-7 mt-6" />
              <table className=" w-full overflow-x-scroll">
                <thead>
                  <th className="assertion-warpper text-start pl-8 p-2 pb-4">SOURCE</th>
                  <th className="assertion-warpper text-start pl-8 p-2">ARTICLE</th>
                  <th className="assertion-warpper text-start p-2">DATE</th>
                  <th className="assertion-warpper text-start p-2">EXCERPT</th>
                  <th className="assertion-warpper text-end pt-2 pb-2 flex items-center">
                    SCORE
                    <InfoIcon />
                  </th>
                </thead>
                <tbody>
                  {assert.topRanks?.map((result: Result, index: number) => (
                    <ContradictionResultItem result={result} key={index} />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
      })}
    </div>
  );
};
