import { PRIVACY_POLICY, TERMS_OF_USE } from '../../common/constants/app-text';

export const TermsAndConditions = () => {
  return (
    <div className="mt-4 flex items-center justify-center text-gray-400 font-sansInter gap-10 text-lg">
      <a className="ml-1">{TERMS_OF_USE}</a>
      <a className="ml-1">{PRIVACY_POLICY}</a>
    </div>
  );
};
