import React, { ReactNode } from 'react';
import { DynamicTitle } from './DynamicTitle';
import { DynamicTitleDescriptionLayout } from './DynamicTitleDescriptionLayout';
import { CurvedTimeline } from './CurvedTimeLine';

interface CurvedTimeLineLayoutProps {
  title: string;
  items: any[];
}
export const CurvedTimeLineLayout: React.FC<CurvedTimeLineLayoutProps> = ({ title, items }) => {
  return (
    <div
      className="bg-white p-4 my-4  rounded-2xl
    border border-gray-200
    shadow-sm animate__animated animate__fadeInUp"
    >
      <DynamicTitle title={title} />
      {items.length > 0 ? <CurvedTimeline items={items} /> : <div className="text-center">No data to display</div>}
    </div>
  );
};
