import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../PrimaryButton';
import './style.css';
import { userPreferencesActions } from '../../redux/actions/user-preference.actions';
import {
  USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION,
  USER_PREFERENCE_FONTSIZE,
  USER_PREFERENCE_ACCESSIBILITY_SETTINGS,
  USER_PREFERENCE_EMAIL_NOTIFICATIONS,
  USER_PREFERENCE_IN_APP_NOTIFICATIONS,
  USER_PREFERENCE_NEWS_LETTER,
  USER_PREFERENCE_PUSH_NOTIFICATIONS,
  USER_PREFERENCE_NO_OF_RECORDS,
  USER_PREFERENCE_CACHED_RESULTS,
  USER_PREFERENCE_ARCHIEVE_HISTORY,
  USER_PREFERENCE_DEFAULT_LLM,
} from '../../common/constants/app-text';
import { preferenceDetails, userPreferences } from '../../redux/selectors/user-preference.selectors';
import { domainDetails } from '../../redux/selectors/profile.selectors';
import Dropdown from '../DropDown/DropDown';
import { ArticleProcessRequest } from '../../common/services/article/models/ArticleProcessRequest';

function ConfigurationManagement() {
  const dispatch = useDispatch();
  const preference = useSelector(preferenceDetails);
  const domain = useSelector(domainDetails);
  const userpreference = useSelector(userPreferences);

  const [editedPreferences, setEditedPreferences] = useState({
    numberofRecords: userpreference.numberofRecords,
    cacheResults: userpreference.cacheResults,
    archieveHistory: userpreference.archieveHistory,
    defaultLLM: userpreference.defaultLLM,
  });

  useEffect(() => {
    setEditedPreferences({
      numberofRecords: userpreference.numberofRecords,
      cacheResults: userpreference.cacheResults,
      archieveHistory: userpreference.archieveHistory,
      defaultLLM: userpreference.defaultLLM,
    });
  }, [userpreference]);

  const handleNumberOfRecordsChange = (selectedOption: string) => {
    // Assuming the dropdown options are strings representing numbers
    const numberOfRecords = parseInt(selectedOption);

    const updatedArticleProcessRequest = new ArticleProcessRequest('', numberOfRecords, true);

    setEditedPreferences((prev) => ({
      ...prev,
      numberofRecords: numberOfRecords,
    }));
  };

  const handleDefaultLLMChange = (selectedOption: string) => {
    setEditedPreferences((prev) => ({
      ...prev,
      defaultLLM: selectedOption,
    }));
  };

  const onSaveChange = () => {
    const updatedPreferences = {
      id: preference?.id ?? '',
      domainId: domain?.id || '',
      language: preference?.language ?? 'en',
      timeZone: preference?.timeZone ?? 'pst',
      preferenceFlagsDto: [
        { name: USER_PREFERENCE_PUSH_NOTIFICATIONS, value: userpreference.pushNotifications },
        { name: USER_PREFERENCE_EMAIL_NOTIFICATIONS, value: userpreference.emailNotifications },
        { name: USER_PREFERENCE_IN_APP_NOTIFICATIONS, value: userpreference.inAppNotifications },
        { name: USER_PREFERENCE_NEWS_LETTER, value: userpreference.newsletter },
        { name: USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION, value: userpreference.twoFactorAuthentication },
        { name: USER_PREFERENCE_FONTSIZE, value: userpreference.fontSize },
        { name: USER_PREFERENCE_ACCESSIBILITY_SETTINGS, value: userpreference.accessibilitySettings },
        { name: USER_PREFERENCE_NO_OF_RECORDS, value: editedPreferences.numberofRecords },
        { name: USER_PREFERENCE_CACHED_RESULTS, value: editedPreferences.cacheResults },
        { name: USER_PREFERENCE_ARCHIEVE_HISTORY, value: editedPreferences.archieveHistory },
        { name: USER_PREFERENCE_DEFAULT_LLM, value: editedPreferences.defaultLLM },
      ],
    };
    dispatch(userPreferencesActions.updateUserPreferences(updatedPreferences));
  };

  const handleDiscardChanges = () => {};

  return (
    <div className="flex-1 bg-white">
      <div className="flex-1 sm:rounded-lg mx-2">
        {/* <div className="flex items-center justify-center p-2 text-gray-900 rounded-lg dark:text-white mb-4">
      <h3 className="text-2xl leading-6 font-medium mb-4 mt-10">Configuration Management</h3>
    </div> */}
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-between mb-8">
            <div
              style={{
                border: '1px solid #e5e7eb',
                borderRadius: '0.375rem',
                marginBottom: '1rem',
                flex: '1',
                marginRight: '4rem',
              }}
            >
              <div className="flex items-center justify-between px-4 py-3 border-b border-black">
                <label htmlFor="archieve-history" className="text-lg font-medium text-gray-700 mr-4">
                  Archived All History (more than one month old)
                </label>
                <div className="switch-container">
                  <input
                    type="checkbox"
                    id="archieve-history"
                    className="switch-checkbox"
                    checked={editedPreferences.archieveHistory}
                    onChange={(e) => setEditedPreferences((prev) => ({ ...prev, archieveHistory: e.target.checked }))}
                  />
                  <label htmlFor="archieve-history" className="switch-label">
                    <div className="slider"></div>
                  </label>
                </div>
              </div>
            </div>
            <div
              style={{
                border: '1px solid #e5e7eb',
                borderRadius: '0.375rem',
                marginBottom: '1rem',
                flex: '1',
                marginLeft: '4rem',
              }}
            >
              <div className="flex items-center justify-between px-4 py-3 border-b border-black">
                <label htmlFor="accessibility-features" className="text-lg font-medium text-gray-700 mr-4">
                  Fetch Cached Results If Available (is faster) Live results
                </label>
                <div className="switch-container">
                  <input
                    type="checkbox"
                    id="accessibility-features"
                    className="switch-checkbox"
                    checked={editedPreferences.cacheResults}
                    onChange={(e) => setEditedPreferences((prev) => ({ ...prev, cacheResults: e.target.checked }))}
                  />
                  <label htmlFor="accessibility-features" className="switch-label">
                    <div className="slider"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 grid grid-cols-2 gap-10">
            <div className="flex flex-col items-start mb-8 ml-4">
              <label htmlFor="record_number" className="text-lg font-medium text-gray-700 mr-4 mb-4">
                Number of Records in search page
              </label>
              <div className="relative" style={{ width: '250px' }}>
                <Dropdown
                  options={['5', '4', '3', '2', '1']}
                  defaultOption={editedPreferences.numberofRecords.toString()}
                  onSelect={handleNumberOfRecordsChange}
                />
              </div>
            </div>
            <div className="flex flex-col items-start mb-8 ml-16">
              <label htmlFor="record_number" className="text-lg font-medium text-gray-700 mr-4 mb-4">
                Use this as a default LLM for all Searches
              </label>
              <div className="relative" style={{ width: '300px' }}>
                <Dropdown
                  options={['Chat GPT', 'Guardrail LLM', 'Microsoft Copilot', 'Gemini']}
                  defaultOption={editedPreferences.defaultLLM}
                  onSelect={handleDefaultLLMChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-6 mb-8">
          <PrimaryButton
            className="mx-2 bg-gradient-to-tl from-blue-500 to-violet-500"
            size="small"
            stateProp="rest"
            style="boxed"
            text="Save Changes"
            onClick={onSaveChange}
          />
          <PrimaryButton
            onClick={handleDiscardChanges}
            className=""
            size="small"
            stateProp="rest"
            style="label-bold"
            text="Discard"
          />
        </div>
      </div>
    </div>
  );
}

export default ConfigurationManagement;
