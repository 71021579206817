import React from 'react';
import { BackIcon } from '../../common/icons/Icons';
import { useNavigate } from 'react-router-dom';

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex items-center justify-center cursor-pointer rounded-full bg-primray-pink w-12 h-12 "
      onClick={() => {
        navigate(-1);
      }}
    >
      <BackIcon />
    </div>
  );
};

export default BackButton;
