import { DynamicListLayout } from './DynamicListLayout';
import { DynamicTitle } from './DynamicTitle';

interface DynamicListLayoutWithTitleProps {
  title: string;
  items: any[];
}

export const DynamicListLayoutWithTitle: React.FC<DynamicListLayoutWithTitleProps> = ({ title, items }) => {
  return (
    <>
      <div className="animate__animated animate__fadeInUp">
        <DynamicTitle title={title} />
        <DynamicListLayout items={items} />
      </div>
    </>
  );
};
