import React from 'react';
import { Assertion, AssertionResult, ContradictionInfoResult, ContradictionResult } from '../../types/models';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';

const colors = ['#d6f5f8', '#ffe5e3', '#f6eaff', '#ecf0f7', '#f6f9ec', '#ecf1f8', '#f3f6e6', '#f0ebeb', '#f8e8e8'];

interface HighlightedParagraphState {
  currentAssertion?: AssertionResult | ContradictionResult;
  engineType?: GuardRailEngineType;
}

const HighlightedParagraph = ({
  currentAssertion,
  engineType = GuardRailEngineType.FactChecker,
}: HighlightedParagraphState) => {
  const paragraph = currentAssertion?.article ?? '';

  let highlightedSentences = [];
  if (engineType === GuardRailEngineType.FactChecker || engineType === GuardRailEngineType.Gateway123) {
    highlightedSentences = [...((currentAssertion as AssertionResult)?.assertions ?? [])] ?? [];
  } else {
    highlightedSentences = [...((currentAssertion as ContradictionResult)?.assertionsInfo ?? [])] ?? [];
    highlightedSentences = highlightedSentences.filter(
      (result, index: number, self) => index === self.findIndex((s) => s.assertion === result.assertion),
    );
  }

  // Find all the matching sentences from the paragraph and store their starting indexes.
  const sentenceIndexes = highlightedSentences
    .map((sentence, index) => {
      let articleName = '';
      if (engineType === GuardRailEngineType.FactChecker || engineType === GuardRailEngineType.Gateway123) {
        articleName = (sentence as Assertion).articleName;
      } else {
        articleName = (sentence as ContradictionInfoResult).assertion;
      }

      const subWords = articleName.split(' ');

      // Check for complete sentence match
      let sentenceIndex = paragraph.indexOf(articleName);

      // Ignore first, second, and third words of the sentence
      if (sentenceIndex === -1 && subWords.length > 3) {
        const subSentence = subWords.slice(3).join(' ');
        sentenceIndex = paragraph.indexOf(subSentence);
      }

      return {
        index: sentenceIndex,
        sentence: articleName,
        colorCode: sentence.colorCode,
        articleIndex: index + 1,
      };
    })
    .filter((item) => item.index !== -1)
    .sort((a, b) => a.index - b.index);

  let highlightedParagraph = [];
  let start = 0;
  sentenceIndexes.forEach((item, index) => {
    const highlightStyle = {
      backgroundColor: item.colorCode,
      position: 'relative',
      display: 'inline',
      padding: '5px',
      marginRight: '2px',
    } as any;
    const numberStyle = {
      position: 'relative',
      top: '-23px',
      padding: '3px 6px',
      right: '-5px',
      backgroundColor: item.colorCode,
      height: '20px',
      width: '20px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000000',
      fontFamily: '"Inter", Helvetica',
      fontSize: '10px',
      fontWeight: '700',
    } as any;

    // Append the text before the sentence.
    if (item.index > start) {
      highlightedParagraph.push(paragraph.substring(start, item.index));
    }

    // Append the sentence with highlight.
    const sentenceWithHighlight = (
      <span key={index} style={highlightStyle}>
        {item.sentence}
        <span style={numberStyle}>{item.articleIndex}</span>
      </span>
    );
    highlightedParagraph.push(sentenceWithHighlight);

    // Update the start index.
    start = item.index + item.sentence.length;
  });

  // Append the remaining text.
  if (start < paragraph.length) {
    highlightedParagraph.push(paragraph.substring(start));
  }

  return <p className="text-wrapper-23 mt-6">{highlightedParagraph}</p>;
};

export default HighlightedParagraph;
