import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { binaryMetricsSelector, currentSummarizationSelector } from '../../redux/selectors/summarization.selectors';
import { Divider } from '../../components/Divider/Divider';
import { ArrowDown, ArrowUpIcon, InfoIcon, ScoreUpIcon, ScoreDownIcon } from '../../common/icons/Icons';
import { ActionItem } from '../../components/ActionItem/ActionItem';
import BackButton from '../../components/BackButton/BackButton';
import { languageModels } from '../../common/constants/summarization';
import { Spinner } from 'flowbite-react';
import { useNavigate, useParams } from 'react-router-dom';
import { SummarizationActions } from '../../redux/actions/summarization.actions';
import { setNotification } from '../../redux/actions/notification.actions';
import { RoutePath } from '../../common/constants/route-path';
import AppLoader from '../../components/AppLoader/AppLoader';
import InfoIconWithDescription from '../../components/InfoDescription/InfoDescription';

export const SummaryComparisonView: React.FC = () => {
  const { id, comparisonId } = useParams();
  const models = comparisonId?.split('-');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);
  const callApi = useRef(false);
  const callCompareApi = useRef(false);
  const currentSummarization = useSelector(currentSummarizationSelector);
  const binaryMetrics = useSelector(binaryMetricsSelector);
  const summary_1 =
    currentSummarization && Object.keys(currentSummarization).length > 0 && models
      ? currentSummarization?.summaries[models[0]]
      : {};
  const summary_2 =
    currentSummarization && Object.keys(currentSummarization).length > 0 && models
      ? currentSummarization?.summaries[models[1]]
      : {};

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      if (!callApi.current) {
        callApi.current = true;
        dispatch(SummarizationActions.getSummarizationById(id));
      }
    } else {
      dispatch(setNotification({ title: 'Prompt Id Missing ', type: 'error' }));
      navigate(RoutePath.Landing);
    }
  }, []);

  useEffect(() => {
    if (
      models?.length === 2 &&
      currentSummarization &&
      Object.keys(currentSummarization).length > 0 &&
      models &&
      !callCompareApi.current
    ) {
      callCompareApi.current = true;
      dispatch(
        SummarizationActions.compareSummaries({
          summaries: {
            summary_1: currentSummarization?.summaries[models[0]]?.summarized_text,
            summary_2: currentSummarization?.summaries[models[1]]?.summarized_text,
          },
        }),
      );
    }
  }, [currentSummarization]);

  // Function to determine which arrow to display based on score comparison
  const getArrow = (score1: number, score2: number) => {
    if (score1 > score2) {
      return <ScoreUpIcon />;
    } else if (score1 < score2) {
      return <ScoreDownIcon />;
    } else {
      return null;
    }
  };

  return (
    <>
      {currentSummarization && Object.keys(currentSummarization).length > 0 && models ? (
        <div className="relative">
          <div className=" bg-gray-50 p-10">
            <BackButton />
            <p className="text-xs pb-2 pt-4 text-gray-500">Summarization</p>
            <div className="flex">
              <div className="mr-6 w-1/2">
                <span>
                  <span className=" font-bold">{languageModels[models[0]]}</span>
                  <span className="text-xs text-gray-400 ml-2">{summary_1?.summarized_text?.length} Characters</span>
                </span>
                <p className={`text-sm text-gray-500 collapsible-section my-5 ${isOpen ? 'open' : ''}`}>
                  {summary_1?.summarized_text}
                </p>
              </div>
              <div className="ml-6 w-1/2">
                <span>
                  <span className=" font-bold">{languageModels[models[1]]}</span>
                  <span className="text-xs text-gray-400 ml-2">{summary_2?.summarized_text?.length} Characters</span>
                </span>
                <p className={`text-sm text-gray-500 collapsible-section my-5 ${isOpen ? 'open' : ''}`}>
                  {summary_2?.summarized_text}
                </p>
              </div>
            </div>
            <div className="flex justify-end">
              <ActionItem
                icon={isOpen ? <ArrowUpIcon /> : <ArrowDown color="#B987E1" />}
                text={isOpen ? 'Hide Summary' : 'Show Summary'}
                isVisible={true}
                textClass={`text-wrapper-27`}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            </div>
          </div>
          {/* Unitary Metrics */}
          <div className="flex flex-col items-center">
            <div className="p-10 w-3/5">
              <p className=" font-bold mb-12 text-center ">Unitary Metrics</p>
              <div className="flex justify-between text-gray-500 text-sm px-5">
                <div className="flex items-center justify-end">
                  {getArrow(
                    summary_1?.evaluation_metrics?.readability_score,
                    summary_2?.evaluation_metrics?.readability_score,
                  )}
                  <div className="w-10 text-right">
                    <p className="text-neutral-600 text-sm mr-2 text-right">
                      {summary_1?.evaluation_metrics?.readability_score?.toFixed(5)}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <span className="pr-2">
                    <InfoIconWithDescription
                      color="#BEC5D7"
                      description="Readability scores such as the Flesch-Kincaid Grade Level (FKGL) or the
                  Automated Readability Index (ARI) serve as crucial metrics for assessing the
                  comprehensibility of text summaries."
                    />
                  </span>
                  <p className="leading-5">Readability Score</p>
                </div>
                <div className="flex items-center justify-end">
                  {getArrow(
                    summary_2?.evaluation_metrics?.readability_score,
                    summary_1?.evaluation_metrics?.readability_score,
                  )}
                  <div className="w-10 text-right">
                    <p className="text-neutral-600 text-sm mr-2 text-right">
                      {summary_2?.evaluation_metrics?.readability_score?.toFixed(5)}
                    </p>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="flex justify-between text-gray-500 text-sm px-5">
                <div className="flex items-center justify-end">
                  {getArrow(
                    summary_1?.evaluation_metrics?.compression_ratio,
                    summary_2?.evaluation_metrics?.compression_ratio,
                  )}
                  <div className="w-10 text-right">
                    <p className="text-neutral-600 text-sm mr-2 text-right">
                      {summary_1?.evaluation_metrics?.compression_ratio?.toFixed(5)}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <span className="pr-2">
                    <InfoIconWithDescription
                      color="#BEC5D7"
                      description="Compression Ratio is a straightforward yet insightful metric used in evaluating text
                  summarization systems."
                    />
                  </span>
                  <p className="leading-5">Compression Ratio</p>
                </div>
                <div className="flex items-center justify-end">
                  {getArrow(
                    summary_2?.evaluation_metrics?.compression_ratio,
                    summary_1?.evaluation_metrics?.compression_ratio,
                  )}
                  <div className="w-10 text-right">
                    <p className="text-neutral-600 text-sm mr-2 text-right">
                      {summary_2?.evaluation_metrics?.compression_ratio?.toFixed(5)}
                    </p>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="flex justify-between text-gray-500 text-sm px-5">
                <div className="flex items-center justify-end">
                  {getArrow(
                    summary_1?.evaluation_metrics?.topic_coherence,
                    summary_2?.evaluation_metrics?.topic_coherence,
                  )}
                  <div className="w-10 text-right">
                    <p className="text-neutral-600 text-sm mr-2 text-right">
                      {summary_1?.evaluation_metrics?.topic_coherence?.toFixed(5)}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <span className="pr-2">
                    <InfoIconWithDescription
                      color="#BEC5D7"
                      description="Coherence Metric assesses is how well a topic is 'supported' by a text set
                      (called reference corpus) . It uses statistics and probabilities drawn from the reference corpus, especially focused on the word's context, to give a coherence score to a topic."
                    />
                  </span>
                  <p className="leading-5">Topic Coherence</p>
                </div>
                <div className="flex items-center justify-end">
                  {getArrow(
                    summary_2?.evaluation_metrics?.topic_coherence,
                    summary_1?.evaluation_metrics?.topic_coherence,
                  )}
                  <div className="w-10 text-right">
                    <p className="text-neutral-600 text-sm mr-2 text-right">
                      {summary_2?.evaluation_metrics?.topic_coherence?.toFixed(5)}
                    </p>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="flex justify-between text-gray-500 text-sm px-5">
                <div className="flex items-center justify-end">
                  {getArrow(
                    summary_1?.evaluation_metrics?.sentence_similarity,
                    summary_2?.evaluation_metrics?.sentence_similarity,
                  )}
                  <div className="w-10 text-right">
                    <p className="text-neutral-600 text-sm mr-2 text-right">
                      {summary_1?.evaluation_metrics?.sentence_similarity?.toFixed(5)}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <span className="pr-2">
                    <InfoIconWithDescription
                      color="#BEC5D7"
                      description="Sentence Similarity is an essential metric for evaluating the quality of text
                  summarization by assessing how well the sentences in a summary capture the
                  essence of the original text."
                    />
                  </span>
                  <p className="leading-5">Sentence Similarity</p>
                </div>
                <div className="flex items-center justify-end">
                  {getArrow(
                    summary_2?.evaluation_metrics?.sentence_similarity,
                    summary_1?.evaluation_metrics?.sentence_similarity,
                  )}
                  <div className="w-10 text-right">
                    <p className="text-neutral-600 text-sm mr-2 text-right">
                      {summary_2?.evaluation_metrics?.sentence_similarity?.toFixed(5)}
                    </p>
                  </div>
                </div>
              </div>
              <Divider />
            </div>
            {Object.keys(binaryMetrics?.evaluation_metrics?.rogue ?? {}).length > 0 ? (
              <div className="p-10 pt-0 w-3/5">
                <p className="font-bold mb-12 text-center">Binary Metrics</p>
                <div className="grid gap-x-10">
                  <div className="">
                    <div className="flex justify-between text-gray-500 text-sm">
                      <div className="flex">
                        <span className="pr-2">
                          <InfoIconWithDescription
                            color="#BEC5D7"
                            description="ROUGE Score is
                            calculated by comparing the machine-generated text to one or more reference texts created by humans
                            ROUGE-1,-looks at individual words or unigrams"
                          />{' '}
                        </span>
                        <p className="leading-5">Rogue 1</p>
                      </div>
                      <p className="leading-5">{binaryMetrics?.evaluation_metrics?.rogue?.rouge1?.toFixed(5)}</p>
                    </div>
                    <Divider />
                  </div>
                  <div className="">
                    <div className="flex justify-between text-gray-500 text-sm">
                      <div className="flex">
                        <span className="pr-2">
                          <InfoIconWithDescription
                            color="#BEC5D7"
                            description="ROUGE Score is
                            calculated by comparing the machine-generated text to one or more reference texts created by humans
                            ROUGE-2,-looks at individual words or unigrams"
                          />{' '}
                        </span>
                        <p className="leading-5">Rogue 2</p>
                      </div>
                      <p className="leading-5">{binaryMetrics?.evaluation_metrics?.rogue?.rouge2?.toFixed(5)}</p>
                    </div>
                    <Divider />
                  </div>
                  <div className="">
                    <div className="flex justify-between text-gray-500 text-sm">
                      <div className="flex">
                        <span className="pr-2">
                          <InfoIconWithDescription
                            color="#BEC5D7"
                            description="Focuses on the longest common subsequence between the 
                            generated and reference texts, emphasizing the longest streak of words that 
                            appear in both texts in the same order."
                          />{' '}
                        </span>
                        <p className="leading-5">Rouge L</p>
                      </div>
                      <p className="leading-5">{binaryMetrics?.evaluation_metrics?.rogue?.rougeL?.toFixed(5)}</p>
                    </div>
                    <Divider />
                  </div>
                  <div className="">
                    <div className="flex justify-between text-gray-500 text-sm">
                      <div className="flex">
                        <span className="pr-2">
                          <InfoIconWithDescription
                            color="#BEC5D7"
                            description="ROUGE-Lsum splits the text into sentences based on newlines and computes the Score for each pair of sentences. It then takes the union of all scores"
                          />{' '}
                        </span>
                        <p className="leading-5">Rogue L Sum</p>
                      </div>
                      <p className="leading-5">{binaryMetrics?.evaluation_metrics?.rogue?.rougeLsum?.toFixed(5)}</p>
                    </div>
                    <Divider />
                  </div>
                  <div className="">
                    <div className="flex justify-between text-gray-500 text-sm">
                      <div className="flex">
                        <span className="pr-2">
                          <InfoIconWithDescription
                            color="#BEC5D7"
                            description="Metrics focusing on exact word matches often resulted in a lack of correlation 
                            with human judgment, especially in cases 
                            where synonyms or paraphrasing occurred. "
                          />
                        </span>
                        <p className="leading-5">Meteor</p>
                      </div>
                      <p className="leading-5">{binaryMetrics?.evaluation_metrics?.meteor?.toFixed(5)}</p>
                    </div>
                    <Divider />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                Loading Binary Metrics... <Spinner color="purple" aria-label="Purple spinner example" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <AppLoader />
      )}
    </>
  );
};
