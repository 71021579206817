import { createSlice } from '@reduxjs/toolkit';
import { PASSWORD_RECOVERY_STORE_NAME, passwordRecoveryActions } from '../actions/password-recovery.actions';
import { getNextPasswordRecoverStage } from '../helpers/password-recover-stage';
import { profileActions } from '../actions/profile.actions';

export interface PasswordRecoveryState {
  token: string;
  email: string;
  error: any;
  stage: PasswordRecoveryStage;
  loading: boolean;
  newPassword: string;
}

export enum PasswordRecoveryStage {
  Request,
  Validate,
  NewPassword,
  Confirm,
  Error,
  PasswordUpdatedSuccess,
  ChangePassword,
}

export const initialPasswordRecoveryState: PasswordRecoveryState = {
  token: '',
  email: '',
  error: null,
  stage: PasswordRecoveryStage.Request,
  loading: false,
  newPassword: '',
};

export const passwordRecoverySlice = createSlice({
  name: PASSWORD_RECOVERY_STORE_NAME,
  initialState: initialPasswordRecoveryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(passwordRecoveryActions.setPasswordRecoveryRequest, (state, { payload }) => {
        state.loading = true;
        state.email = payload;
        state.error = null;
        state.token = '';
      })
      .addCase(passwordRecoveryActions.setPasswordRecoveryRequestSuccess, (state) => {
        state.loading = false;
      })
      .addCase(passwordRecoveryActions.setPasswordRecoveryRequestFail, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(passwordRecoveryActions.resendPasswordRecoveryRequest, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(profileActions.logout, (state) => {
        state.error = null;
        state.email = '';
        state.token = '';
      })
      .addCase(passwordRecoveryActions.resendPasswordRecoveryRequestSuccess, (state) => {
        state.loading = false;
      })
      .addCase(passwordRecoveryActions.resendPasswordRecoveryRequestFail, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(passwordRecoveryActions.setEmailValidate, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(passwordRecoveryActions.setEmailValidateSuccess, (state, { payload }) => {
        state.token = payload.token;
        state.loading = false;
      })
      .addCase(passwordRecoveryActions.setEmailValidateFail, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(passwordRecoveryActions.resetPassword, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(passwordRecoveryActions.resetPasswordSuccess, (state, { payload }) => {
        state.loading = false;
        state.stage = PasswordRecoveryStage.PasswordUpdatedSuccess;
        state.token = '';
      })
      .addCase(passwordRecoveryActions.resetPasswordFail, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(profileActions.createGuestAccountSuccess, (state, { payload }) => {
        state.email = payload.email;
      })
      .addCase(passwordRecoveryActions.showStage, (state, { payload }) => {
        state.stage = payload;
      })
      .addCase(passwordRecoveryActions.resetValidationProcess, () => {
        return initialPasswordRecoveryState;
      })
      .addCase(passwordRecoveryActions.showNextStage, (state) => {
        state.stage = getNextPasswordRecoverStage(state.stage);
      })
      .addCase(passwordRecoveryActions.showErrorStage, (state) => {
        state.stage = PasswordRecoveryStage.Error;
      })
      .addCase(passwordRecoveryActions.changePassword, (state, { payload }) => {
        state.loading = true;
        state.email = payload.email;
        state.error = null;
        state.token = '';
        state.stage = PasswordRecoveryStage.ChangePassword;
      })
      .addCase(passwordRecoveryActions.changePasswordSuccess, (state) => {
        state.loading = false;
        state.stage = PasswordRecoveryStage.PasswordUpdatedSuccess;
        state.token = '';
      })
      .addCase(passwordRecoveryActions.changePasswordFail, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const passwordRecoveryReducer = passwordRecoverySlice.reducer;
