import Typewriter from 'typewriter-effect';
import { ContentInput } from '../../components/ContentInput';
import { PrimaryButton } from '../../components/PrimaryButton';
import './style.css';

type Props = {
  children: React.ReactNode;
  showBackground?: boolean;
};

export const MainBackground = ({ children, showBackground = true }: Props): JSX.Element => {
  return (
    <>
      <div
        className="min-[750px] w-full bg-center bg-no-repeat bg-cover"style={ showBackground ? {
          backgroundImage:
            "url('https://generation-sessions.s3.amazonaws.com/992bca50d1a1be566686effdf99311ae/img/group-37.png')",
        }:{}}
      >
        <div className="base-lines">
          <div className="relative  min-h-[185px] w-full">
            <div
              className="absolute top-0 left-0 w-full h-full min-h-[185px] bg-center bg-no-repeat bg-cover"
              style={{
                backgroundImage:
                  "url('https://generation-sessions.s3.amazonaws.com/992bca50d1a1be566686effdf99311ae/img/group-47.png')",
              }}
            ></div>
            <div className="relative w-full min-h-[276px] mt-4">
              <div
                className="absolute top-0 left-0 w-full h-full min-h-[142px] bg-center bg-no-repeat bg-cover"
                style={{
                  backgroundImage:
                    "url('https://generation-sessions.s3.amazonaws.com/992bca50d1a1be566686effdf99311ae/img/union.svg')",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="main-content-container flex text-center justify-center  h-auto">{children}</div>
      </div>
    </>
  );
};
