import { LinkIcon } from '@heroicons/react/outline';
import { GitHubIcon } from '../../../common/icons/Icons';
import { CodeMirrorInput } from '../CodeMirrorInput';
import Badge from '../../Base/Badge';
import { useEffect, useState } from 'react';
import { GithubResponse } from '../../../types/models';

interface GitHubReferenceProps {
  index?: number;
  item: GithubResponse;
  code?:string;
}

export const GitHubReference = ({ index = 1 , item, code="" }:GitHubReferenceProps) => {
  let [primaryTags , setPrimaryTags] = useState<string[]>([]);
  let [secondaryTags , setSecondaryTags] = useState<string[]>([]);
  useEffect(() => {
    let primary = [] as string[];
      let secondary =  [] as string[];
    if(item){
        primary.push(`${item.user[0]?.contributions ?? ""}`)
        primary.push(`${item.user[0]?.followers ?? 0} followers`)
        primary.push(`following ${item.user[0]?.following ?? 0}`)
        primary.push(`Tier ${item.tier}`)
        secondary.push(`${item.repo[0]?.contributors ?? 0} contributors`)
        secondary.push(`${item.repo[0]?.forks} forks`)
        secondary.push(`Tier ${item.repo[0]?.tier}`)
        secondary.push(`${item.repo[0]?.watchers ?? 0} watchers`)
    }
    setPrimaryTags(primary);
    setSecondaryTags(secondary);
  },[])
  return (
    <>
      <div className="mt-5 animate__animated animate__fadeInUp ">
        <p className="font-bold font-black">Reference {index+1}</p>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col ">
            <div className="flex  flex-row gap-3 mt-4">
            <span className="text-gray-500 font-semibold font-[Open_sans]">Username</span>
              <span onClick={() => window.open(item.user[0].url, '_blank') }  className="text-primary-blue font-[Open_sans]">{item.user[0]?.name ?? ""}</span>
            </div>
            <div className="flex flex-row gap-3 mt-2">
              <span className="text-gray-500  font-semibold font-[Open_sans]">Organization</span>
              <span className="text-primary-blue font-[Open_sans]">{item.user[0]?.org ?? ""}</span>
            </div>
            <div className="flex  flex-row gap-3 mt-2">
              <span className="text-gray-500  min-w-100 font-semibold font-[Open_sans]">Bio</span>
              <span className="text-gray-500 font-[Open_sans]">
              {item.user[0]?.bio ?? ""}
              </span>
            </div>
            <div>
              <div className="flex flex-row flex-wrap ">
                {primaryTags.map((e, index) => (
                  <Badge key={index} backgroundColor="#EBEEF6" text={e} />
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="flex  flex-row gap-3 mt-4">
              <div
              onClick={() => window.open(item.url, '_blank') }
                className="flex justify-between gap-4  border rounded  mx-2 p-3 align-middle"
                style={{
                  background: '#D6F6F9',
                }}
              >
                <div className="flex col"  >
                  <span className="mt-1">
                    <GitHubIcon color="#10828B" />
                  </span>
                  <span className="font-sm font-['Open_Sans'] mx-2">Repository URL</span>
                </div>
                <span className="mt-1">
                  <LinkIcon color="#56607C" />
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-3 mt-2"></div>
            <div className="flex  mt-2">
              <span className="text-gray-500 min-w-100 font-semibold font-[Open_sans]">About</span>
              <span className="text-gray-400 font-normal font-[Open_sans]">
                {item.repo[0]?.about_description ?? ""}
              </span>
            </div>
            <div>
              <div className="flex flex-row flex-wrap ">
                {secondaryTags.map((e, index) => (
                  <Badge key={index} backgroundColor="#EBEEF6" text={e} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <>
        { code && code.length && <CodeMirrorInput
            hideTitle={true}
            disabled={true}
            code={code}
          />}
        </>
      </div>
    </>
  );
};
