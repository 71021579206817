import Modal from '../Modal/Modal';
import { ActionItem } from '../ActionItem/ActionItem';
import { CrossIcon } from '../../common/icons/CrossIcon';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsSelector, loadingProjectsSelector } from '../../redux/selectors/projects.selectors';
import CheckboxWithLabel from '../Base/CheckBoxWithLabel';
import { useCallback, useEffect, useState } from 'react';
import { Divider } from '../Divider/Divider';
import { setNotification } from '../../redux/actions/notification.actions';
import { ProjectsActions } from '../../redux/actions/projects.actions';
import { useNavigate, useParams } from 'react-router-dom';
import { SourceType } from '../../common/constants/enums/engine-types.enum';
import { Assertion, ProjectAssertionDto } from '../../types/models';
import { PrimaryButton } from '../PrimaryButton';
import { RoutePath } from '../../common/constants/route-path';

interface Props {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  assertion?: Assertion;
  createdAt?: string;
  sourceType?: SourceType;
}

const ClipToProjectModal = ({ modalOpen, setModalOpen, assertion, createdAt, sourceType }: Props) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Projects = useSelector(ProjectsSelector);
  const loadingProjects = useSelector(loadingProjectsSelector);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const debounce = (func: Function, delay: number) => {
    let debounceTimer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  const handleSearch = useCallback(
    debounce((text: string) => {
      dispatch(ProjectsActions.getProjectsList({ page: 1, search: text }));
    }, 500),
    [],
  );

  useEffect(() => {
    if (searchText !== undefined) handleSearch(searchText);
  }, [searchText, handleSearch]);

  const handleCheckboxChange = (project: string) => {
    const selectedIndex = selectedItems.indexOf(project);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, project]);
    } else {
      const updatedItems = [...selectedItems];
      updatedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedItems);
    }
  };
  const handleAddAssertionToProject = (projectId: string | undefined) => {
    const projectDetails = Projects.find((project) => project.id === projectId);
    const existingAssertion = projectDetails?.assertions?.find(
      (a: ProjectAssertionDto) => a.assertionId === assertion?.id,
    );
    if (existingAssertion) {
      dispatch(setNotification({ title: 'Assertion already added to project', type: 'error' }));
    } else {
      dispatch(
        ProjectsActions.updateProject({
          id: String(projectId),
          assertions: [
            ...projectDetails?.assertions,
            {
              articleId: String(id),
              assertionId: assertion?.id,
              assertionText: assertion?.articleName,
              model: sourceType,
              createdAt,
            },
          ],
        }),
      );
    }
  };

  const handleSubmit = () => {
    selectedItems.forEach((project) => {
      const projectDetails = Projects.find((p) => p.title === project);
      handleAddAssertionToProject(projectDetails?.id);
    });
  };
  return (
    <div>
      <Modal
        isShown={modalOpen}
        animation="fade"
        centered
        withBackdrop
        onBackdropClick={() => {
          setSearchText('');
          setModalOpen(false);
        }}
      >
        <div className="flex items-center justify-center p-4 w-[50vw] ">
          <div className="bg-white shadow-lg rounded-lg w-full max-w-4xl p-8">
            <div className="flex items-center justify-between">
              <span className="text-lg">{'Select project(s)'}</span>
              <ActionItem
                icon={<CrossIcon />}
                text={'Close'}
                isVisible={true}
                isPrefix={true}
                textClass={`text-wrapper-27`}
                onClick={() => {
                  setSearchText('');
                  setModalOpen(false);
                }}
              />
            </div>
            <input
              type="text"
              id="table-search-users"
              className="mt-6 block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search by name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <p className="text-sm mt-5 mb-2">Other projects</p>
            {loadingProjects ? (
              <div className="flex justify-center items-center text-sm">Loading Projects...</div>
            ) : (
              <>
                {Projects.length > 0 ? (
                  <div className="max-h-[40vh] overflow-x-scroll overflow-y-scroll">
                    {Projects?.map((project) => {
                      return (
                        <>
                          <div className="flex items-center justify-between mr-4">
                            <CheckboxWithLabel
                              isChecked={selectedItems.includes(project.title)}
                              label={project.title}
                              showInfoIcon={false}
                              onCheckboxChange={() => handleCheckboxChange(project.title)}
                            />
                            <p className="text-xs text-gray-400">
                              {new Date(project?.createdAt).toLocaleDateString('en-US', {
                                year: 'numeric',
                                day: 'numeric',
                                month: 'numeric',
                              })}
                            </p>
                          </div>
                          <Divider margin={''} />
                        </>
                      );
                    })}
                  </div>
                ) : searchText ? (
                  <div className="flex justify-center items-center text-sm">No projects found</div>
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <div className="my-4">Start by creating a new project.</div>
                    <PrimaryButton
                      onClick={() => {
                        navigate(RoutePath.Projects);
                      }}
                      className="mb-4"
                      size="medium"
                      stateProp="rest"
                      style={`boxed`}
                      text="Go to Projects"
                    />
                  </div>
                )}
              </>
            )}
            <div className="flex justify-end">
              <button
                className="disabled:from-gray-300 disabled:to-gray-300 text-white rounded-md px-4 py-[6px] text-sm mt-2 bg-gradient-to-b from-purple-500 to-blue-500 "
                onClick={() => {
                  handleSubmit();
                  setSearchText('');
                  setSelectedItems([]);
                  setModalOpen(false);
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ClipToProjectModal;
