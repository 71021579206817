import { createSlice } from '@reduxjs/toolkit';
import {
  CodeAnalysisInput,
  CodeAnalysisLightResponse,
  CodeAnalysisResult,
  FavoriteArticles,
  PaginationConfig,
  UserHistoryList,
} from '../../types/models';
import { PROMPT_STORE_NAME } from '../actions/prompt.actions';
import { CODE_ANALYSIS_STORE_NAME, CodeAnalysisActions } from '../actions/codeAnalysis.actions';
import { mapCodeAnalysisToFavorite } from '../../common/helpers/Helper';
import { profileActions } from '../actions/profile.actions';

export interface CodeAnalysisState {
  inputCodeRequest?: CodeAnalysisInput | null;
  currentCodeAnalysis?: CodeAnalysisResult | null;
  requestedPaylod: string;
  codeRequestsList: CodeAnalysisLightResponse[];
  favoriteCodeRequests: FavoriteArticles[];
  favoriteCodesCount: number;
  errorCode: string;
  isFavoriteEnabled: boolean;
  isProcessingCode: boolean;
  historyPaginationConfig: PaginationConfig;
  isDeleting: boolean;
}

const initialState: CodeAnalysisState = {
  inputCodeRequest: {
    code: '',
    riskAssessment: [],
    checkForBillOfLadding: false,
    checkForReferenceFinder: false,
  },
  requestedPaylod: '',
  codeRequestsList: [],
  currentCodeAnalysis: undefined,
  favoriteCodesCount: 0,
  isFavoriteEnabled: false,
  isProcessingCode: false,
  favoriteCodeRequests: [],
  historyPaginationConfig: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  errorCode: '',
  isDeleting: false,
};
export const codeAnalysisSlice = createSlice({
  name: CODE_ANALYSIS_STORE_NAME,
  initialState,
  reducers: {
    processCodeAnalysis: (state, action) => {
      state.isProcessingCode = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CodeAnalysisActions.processCodeAnalysis, (state, { payload }) => {
        state.isProcessingCode = true;
        state.inputCodeRequest = payload;
      })
      .addCase(CodeAnalysisActions.processCodeAnalysisSuccess, (state, { payload }) => {
        state.currentCodeAnalysis = payload;
        state.isProcessingCode = false;
        state.errorCode = '';
      })
      .addCase(CodeAnalysisActions.toggleFavoriteCodeAnalysis, (state, { payload }) => {
        state.isFavoriteEnabled = payload;
        var list = state.codeRequestsList;
        if (state.isFavoriteEnabled) {
          list = state.codeRequestsList.filter((CodeAnalysis, index, self) => CodeAnalysis.isFavorite === true);
        }
        state.favoriteCodeRequests = mapCodeAnalysisToFavorite(list);
        state.favoriteCodesCount = state.codeRequestsList.filter(
          (CodeAnalysis, index, self) => CodeAnalysis.isFavorite,
        ).length;
      })
      .addCase(CodeAnalysisActions.getUserCodeAnalysisListSuccess, (state, { payload }) => {
        state.codeRequestsList = state.codeRequestsList.concat(payload.data ?? []);
        var list = state.codeRequestsList;
        if (state.isFavoriteEnabled) {
          list = state.codeRequestsList.filter((CodeAnalysis, index, self) => CodeAnalysis.isFavorite === true);
        }
        state.favoriteCodeRequests = mapCodeAnalysisToFavorite(list);
        state.favoriteCodesCount = state.isFavoriteEnabled
          ? state.favoriteCodeRequests.length
          : state.codeRequestsList.filter((CodeAnalysis, index, self) => CodeAnalysis.isFavorite === true).length;
        state.historyPaginationConfig = payload.meta;
        state.isProcessingCode = false;
      })
      .addCase(CodeAnalysisActions.getUserCodeAnalysisList, (state, { payload }) => {
        state.isProcessingCode = true;
      })
      .addCase(CodeAnalysisActions.setCodeAnalysisContent, (state, { payload }) => {
        state.currentCodeAnalysis = null;
        state.inputCodeRequest = {
          code: payload,
          riskAssessment: [],
          checkForBillOfLadding: false,
          checkForReferenceFinder: false,
        };
      })
      .addCase(CodeAnalysisActions.setCodeAnalysisFavoriteSuccess, (state, { payload }) => {
        let currentCode = state.currentCodeAnalysis;
        if (currentCode && currentCode.id === payload.id) {
          currentCode.isFavorite = payload.isFavorite ?? false;
          state.currentCodeAnalysis = currentCode;
        }
        let index = state.codeRequestsList.findIndex((e) => e.id === payload.id);
        state.codeRequestsList[index].isFavorite = payload.isFavorite ?? false;
        var list = state.codeRequestsList;
        if (state.isFavoriteEnabled) {
          list = state.codeRequestsList.filter((CodeAnalysis, index, self) => CodeAnalysis.isFavorite === true);
        }
        state.favoriteCodeRequests = mapCodeAnalysisToFavorite(list);
        state.favoriteCodesCount = state.isFavoriteEnabled
          ? state.favoriteCodeRequests.length
          : state.codeRequestsList.filter((CodeAnalysis, index, self) => CodeAnalysis.isFavorite === true).length;
      })
      .addCase(CodeAnalysisActions.getUserCodeAnalysisListFailure, (state, { payload }) => {
        state.isProcessingCode = false;
      })
      .addCase(CodeAnalysisActions.processCodeAnalysisFailure, (state, { payload }) => {
        state.isProcessingCode = false;
        state.errorCode = payload.message;
      })
      .addCase(CodeAnalysisActions.getCodeAnalysisByIdSuccess, (state, { payload }) => {
        state.currentCodeAnalysis = payload;
        state.errorCode = '';
      })
      .addCase(CodeAnalysisActions.deleteCodeAnalysis, (state, { payload }) => {
        state.isDeleting = true;
        state.inputCodeRequest = {
          code: payload,
          riskAssessment: [],
          checkForBillOfLadding: false,
          checkForReferenceFinder: false,
        };
      })
      .addCase(CodeAnalysisActions.deleteCodeAnalysisSuccess, (state, { payload }) => {
        state.isDeleting = false;
        if (state.currentCodeAnalysis?.id == payload) {
          state.currentCodeAnalysis = null;
        }
        state.codeRequestsList = state.codeRequestsList.filter((e) => e.id != payload);
        let records = state.codeRequestsList;
        if (state.isFavoriteEnabled) {
          records = state.codeRequestsList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteCodeRequests = mapCodeAnalysisToFavorite(records);
        state.favoriteCodesCount = state.codeRequestsList.filter(
          (article, index, self) => article.isFavorite === true,
        ).length;
      })
      .addCase(CodeAnalysisActions.deleteCodeAnalysisFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(CodeAnalysisActions.clearCodeAnalysisHistorySuccess, (state, { payload }) => {
        state.codeRequestsList = [];
        state.favoriteCodeRequests = [];
        state.favoriteCodesCount = 0;
        state.historyPaginationConfig = {
          page: 1,
          take: 10,
          itemCount: 0,
          hasPreviousPage: false,
          hasNextPage: false,
        };
      })

      .addCase(CodeAnalysisActions.getCodeAnalysisById, (state, { payload }) => {
        state.currentCodeAnalysis = null;
        state.errorCode = '';
      })
      .addCase(CodeAnalysisActions.getCodeAnalysisByIdFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(profileActions.logout, (state, { payload }) => {
        Object.assign(state, initialState);
      });
  },
});

export const { processCodeAnalysis } = codeAnalysisSlice.actions;
export const CodeAnalysisReducer = codeAnalysisSlice.reducer;
