import { Dossier } from '../../common/services/dossier/person/dossierPerson';
import {
  DossierLite,
  DossierOrganizaitonLite,
  DossierPersonLite,
  DossierUpdateRequest,
  HttpErrorResponse,
  ProcessDossierRequest,
  ResultListLite,
} from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const DOSSIER_STORE_NAME = 'Dossier Store';

const createPayloadAction = getCreatePayloadActionFromStore(DOSSIER_STORE_NAME);
const createAction = getCreateActionFromStore(DOSSIER_STORE_NAME);

const getDefaultDossier = createAction('Get Default Dossier');
const getDefaultDossierSuccess = createPayloadAction<Dossier>('Get Default Success');
const getDefaultDossierFailure = createPayloadAction<HttpErrorResponse>('Get Default Failure');

const processDossier = createPayloadAction<ProcessDossierRequest>('Process Dossiers');

const processDossierEnabled = createPayloadAction<boolean>('Process Dossiers Enabled');
const processDossierSuccess = createPayloadAction<Dossier>('Process Success');
const processDossierFailure = createPayloadAction<HttpErrorResponse>('Process Failure');

const getDossierById = createPayloadAction<string>('Get Dossier By Id');
const getDossierByIdSuccess = createPayloadAction<Dossier>('get Dossier By Id Success');
const getDossierByIdFailure = createPayloadAction<HttpErrorResponse>('get Dossier By Id Failure');

const getUserDossiersList = createPayloadAction<number>('Get User Dossiers List');
const getUserDossiersListSuccess = createPayloadAction<ResultListLite<DossierLite>>('Get User Dossiers List Success');
const getUserDossiersListFailure = createPayloadAction<HttpErrorResponse>('Get User Dossiers List Failure');

const setDossierFavorite = createPayloadAction<DossierUpdateRequest>('Set Dossier Favorite');
const setDossierFavoriteSuccess = createPayloadAction<Dossier>('Set Dossier Favorite Success');
const setDossierFavoriteFailure = createPayloadAction<HttpErrorResponse>('Set Dossier Favorite Failure');
const toggleFavoriteDossiers = createPayloadAction<boolean>('Toggle Favorite Dossiers');
const clearDossierHistory = createAction('Clear dossier history');

const deleteDossier = createPayloadAction<string>('Delete Dossier');
const deleteDossierSuccess = createPayloadAction<string>('Delete Dossier Success');
const deleteDossierFailure = createPayloadAction<HttpErrorResponse>('Delete Dossier Failure');

const clearDossierPersonHistorySuccess = createAction('Clear dossier person history success');
const setDossierContent = createPayloadAction<string>('');

const clearDossierPersonHistoryFailure = createAction('Clear dossier person history failure');

export const dossierActions = {
  processDossier,
  processDossierSuccess,
  processDossierEnabled,
  processDossierFailure,
  getUserDossiersList,
  getUserDossiersListSuccess,
  getUserDossiersListFailure,
  getDefaultDossier,
  getDefaultDossierSuccess,
  getDefaultDossierFailure,
  getDossierById,
  getDossierByIdSuccess,
  getDossierByIdFailure,
  setDossierFavorite,
  setDossierFavoriteSuccess,
  setDossierFavoriteFailure,
  toggleFavoriteDossiers,
  clearDossierHistory,
  clearDossierPersonHistorySuccess,
  clearDossierPersonHistoryFailure,
  setDossierContent,
  deleteDossier,
  deleteDossierSuccess,
  deleteDossierFailure,
};