import { createAction } from '@reduxjs/toolkit';
import { getCreatePayloadActionFromStore } from '../helpers/create-action';

export const LAYOUT_STORE_NAME = 'layout';

const createPayloadAction = getCreatePayloadActionFromStore(LAYOUT_STORE_NAME);

const setShowSignInModal = createPayloadAction<boolean>('Show Sign In Modal ');
const setShowSignUpModal = createPayloadAction<boolean>('Show Sign Up Modal');
const toggleUsersArticles = createPayloadAction<boolean>('Toggle Users Articles');
const setShowGuestSignUpModal = createPayloadAction<boolean>('Show Guest Sign Up Modal');
const setShowDeleteModal = createPayloadAction<boolean>('show Delete Modal');

const toggleLayoutMenu = createAction('Toggle layout main menu');
export const layoutActions = {
  setShowSignInModal,
  setShowSignUpModal,
  toggleUsersArticles,
  setShowGuestSignUpModal,
  toggleLayoutMenu,
  setShowDeleteModal,
};
