import MainPage from '../../components/MainPage/MainPage';
import { Header } from '../../components/Header';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import { Footer } from '../../components/Footer';
import ProfilePage from '../../components/ViewProfile/ProfileOverriew';
import { MainMenu } from '../../layouts/Sidenav/MainMenu';

export const ViewProfile = (): JSX.Element => {
  return (
    <MainPage>
      <Header />
      <ContentLayout>
        <MainMenu isPushMenu={false} />
      </ContentLayout>
      <Footer />
    </MainPage>
  );
};
