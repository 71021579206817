import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { RoutePath } from '../../common/constants/route-path';
import { PromptActions } from '../actions/prompt.actions';
import { browserHistory } from '../helpers/history/history';
import {
  CodeAnalysisProtectService,
} from '../../common/services/code-analysis/code-analysis.service';
import { CodeAnalysisActions } from '../actions/codeAnalysis.actions';
import {
  ResultListLite,
  UserHistoryList,
  CodeAnalysisResult,
  CodeAnalysisInput,
  DossierUpdateRequest,
  BillOfLaddingResult,
  CodeRiskAssessmentResult,
  RiskAssessment,
  ReferenceFinderResult,
  CodeAnalysisLightResponse,
  CodeAnalysisEnteryRequest,
} from '../../types/models';
import { setNotification } from '../actions/notification.actions';
import { autoMapper, wait } from '../../common/helpers/Helper';
import { profileActions } from '../actions/profile.actions';

export function* getCodeAnalysisRequests({ payload }: PayloadAction<number>) {
  try {
    var userCodeRequests: ResultListLite<CodeAnalysisLightResponse> = yield call(
      CodeAnalysisProtectService.getCodeAnalysisList,
      payload,
      10,
    );
    yield put(CodeAnalysisActions.getUserCodeAnalysisListSuccess(userCodeRequests));
  } catch (err: any) {
    console.log('failed request', err.message, err.statusCode);

    yield put(PromptActions.getUserPromptsListFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* getDefaultCodeReport() {
  try {
    const defaultCodeReport: CodeAnalysisResult = yield call(CodeAnalysisProtectService.getDefaultCodeAnalysis);
    yield put(CodeAnalysisActions.getDefaultCodeAnalysisSuccess(defaultCodeReport));
  } catch (err: any) {
    yield put(CodeAnalysisActions.getDefaultCodeAnalysisFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* processCodeForChecks({ payload }: PayloadAction<CodeAnalysisInput>) {
  try {
    let codeLanguage ='javascript';
    const laddingReport:BillOfLaddingResult = yield call(processBillOfLadding, payload.code);
    if(laddingReport && laddingReport.bill_of_ladding.languages.length > 0){
      codeLanguage = laddingReport.bill_of_ladding.languages[0];
    }
    let riskAssessmentResult:CodeRiskAssessmentResult | undefined = undefined;
    if(payload.riskAssessment.length){
     riskAssessmentResult = yield call(processRiskAssessment, payload.code, payload.riskAssessment, codeLanguage);
    }
    let codeReferencesResult:ReferenceFinderResult | undefined = undefined;
    if(payload.checkForBillOfLadding){
      codeReferencesResult = yield call(processReferenceFinder, payload.code);
    }
    let codeEntry = {
      code: payload.code,
      riskAssessmentId: riskAssessmentResult?.id ?? "",
      codeReferenceId: codeReferencesResult?.id ?? "",
      billOfLadding: laddingReport?.id ?? "",
    } as CodeAnalysisEnteryRequest;

    let codeAnalysisResultEntry:CodeAnalysisLightResponse = yield call(CodeAnalysisProtectService.createCodeAnalysisEntry, codeEntry);

    let result:CodeAnalysisResult = {
      id: codeAnalysisResultEntry?.id,
      isFavorite: false,
      billOfLadding: {
        bill_of_ladding: laddingReport?.bill_of_ladding ?? null,
        version_command: laddingReport?.version_command ?? null,
      },
      riskAssessment: riskAssessmentResult?.riskAssessmentOutputResponse ?? [],
      codeReference:codeReferencesResult?.result ?? undefined,
      code: payload.code,
    };

    yield put(CodeAnalysisActions.processCodeAnalysisSuccess(result));
  } catch (err: any) {
    yield put(CodeAnalysisActions.processCodeAnalysisFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
    yield put(profileActions.getUserLimit());
  }
}



export function* processBillOfLadding(codeInpout:string) {
  try {
    const laddingReport: BillOfLaddingResult = yield call(CodeAnalysisProtectService.processCodeLadding, codeInpout);
    return laddingReport;
  } catch (err: any) {
    console.log('failed request', err);
    return null;
  } finally {
  }
}

export function* processRiskAssessment(codeInpout:string, assessmentTypes: string[], language:string){
  try {
    const riskAssessmentResult: CodeRiskAssessmentResult = yield call(CodeAnalysisProtectService.processRiskAssessment, codeInpout, assessmentTypes, language);
    riskAssessmentResult.riskAssessmentOutputResponse =  riskAssessmentResult.riskAssessmentOutputResponse.map((riskAssessment) => {
      if(riskAssessment.result){
        riskAssessment.result.risk_detected = (riskAssessment.result?.risk_detected as any) === "true" ? true : false;
      }
      return riskAssessment;
    });
    return riskAssessmentResult;
  } catch (err: any) {
    console.log('failed request', err);
    return null;
  } finally {
  }
}

export function* processReferenceFinder(codeInpout:string){
  try {
    const riskAssessmentResult: ReferenceFinderResult = yield call(CodeAnalysisProtectService.processReferenceFinder, codeInpout);
    return riskAssessmentResult;
  } catch (err: any) {
    console.log('failed request', err);
    return null;
  } finally {
  }
}


export function* setCodeToFavorite({ payload }: PayloadAction<DossierUpdateRequest>) {
  try {
    const updateCodeReportResult: CodeAnalysisResult = yield call(
      CodeAnalysisProtectService.updateCodeAnalysis,
      payload,
    );
    yield put(CodeAnalysisActions.setCodeAnalysisFavoriteSuccess(updateCodeReportResult));
    let message = payload.isFavorite ? 'Code marked as favorite' : 'Code removed from favorite';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(setNotification({ title: 'Unable to perform action', type: 'error' }));
    yield put(CodeAnalysisActions.setCodeAnalysisFavoriteFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* deleteCodeAnalysis({ payload }: PayloadAction<string>) {
  try {
    yield call(CodeAnalysisProtectService.deleteCodeAnalysis, payload);
    yield put(CodeAnalysisActions.deleteCodeAnalysisSuccess(payload));
    yield put(setNotification({ title: 'CodeAnalysis deleted successfully', type: 'success' }));
  } catch (err: any) {
    yield put(CodeAnalysisActions.deleteCodeAnalysisFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to delete CodeAnalysis', type: 'error' }));
  }
}

export function* clearCodeAnalysisHistory(){
  try {
    const result: boolean = yield call(CodeAnalysisProtectService.clearHistory);
    if(result){
      yield put(CodeAnalysisActions.clearCodeAnalysisHistorySuccess());
      yield put(setNotification({ title: 'Code Analysis History Cleared', type: 'success' }));
    }else{
      yield put(CodeAnalysisActions.clearCodeAnalysisHistoryFailure());
      yield put(setNotification({ title: 'Unable to clear code analysis history', type: 'error' }));
    }
  } catch (err: any) {
    yield put(CodeAnalysisActions.clearCodeAnalysisHistoryFailure());
  }
}


export function* getCodeReportById({ payload }: PayloadAction<string>) {
  try {
    const DossierOrgDetails: CodeAnalysisResult = yield call(
      CodeAnalysisProtectService.getCodeAnalysisReportById,
      payload,
    );
    if (DossierOrgDetails) {
      yield put(CodeAnalysisActions.getCodeAnalysisByIdSuccess(DossierOrgDetails));
    } else {
      yield put(CodeAnalysisActions.getCodeAnalysisByIdFailure({ message: 'Code Report Not Found', statusCode: 404 }));
    }
  } catch (err: any) {
    yield put(CodeAnalysisActions.getCodeAnalysisByIdFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to fetch code report', type: 'error' }));
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export default function* watcher() {
  yield takeLatest(CodeAnalysisActions.processCodeAnalysis.type, processCodeForChecks);
  yield takeEvery(CodeAnalysisActions.getCodeAnalysisById.type, getCodeReportById);
  yield takeEvery(CodeAnalysisActions.getDefaultCodeAnalysis.type, getDefaultCodeReport);
  yield takeEvery(CodeAnalysisActions.getUserCodeAnalysisList.type, getCodeAnalysisRequests);
  yield takeLatest(CodeAnalysisActions.setCodeAnalysisFavorite.type, setCodeToFavorite);
  yield takeEvery(CodeAnalysisActions.clearCodeAnalysisHistory.type, clearCodeAnalysisHistory);
  yield takeLatest(CodeAnalysisActions.deleteCodeAnalysis.type, deleteCodeAnalysis);
}

