import React from 'react';
import { WorkIcon } from '../../../common/icons/Icons';

interface CurvedTimelineProps {
  items: string[];
}

export const CurvedTimeline: React.FC<CurvedTimelineProps> = ({ items }) => {
  return (
    <ol className="items-center sm:flex justify-center">
      {items.map((item, index) => (
        <li key={index} className="relative mb-6 sm:mb-0">
          <div className="flex items-center">
            <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
              <WorkIcon />
            </div>
            <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
          </div>
          <div className="mt-3 sm:pe-8">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{item[0] ?? ''}</h3>
            <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
              {item[1] ?? ''}
            </time>
            <p className="text-base font-normal text-gray-500 dark:text-gray-400">{item[2] ?? ''}</p>
          </div>
        </li>
      ))}
    </ol>
  );
};
