import { createSelector } from '@reduxjs/toolkit';
import { ArticleState, articleSlice } from '../slices/article.slice';
import { AppState } from '../types/App.type';
import { PromptState } from '../slices/prompt.slice';

const selectArticleState: (state: AppState) => PromptState = (state) => state.prompt;
export const currentPromptSelector = createSelector(selectArticleState, ({ currentPrompt }) => currentPrompt);
export const processPromptEnabledSelector = createSelector(
  selectArticleState,
  ({ processPromptEnabled }) => processPromptEnabled,
);
export const promptErrorCodesSelector = createSelector(selectArticleState, ({ errorCode }) => errorCode);
export const userPromptsListSelector = createSelector(selectArticleState, ({ favoritePrompts }) => favoritePrompts);
export const isProcessingPromptsSelector = createSelector(
  selectArticleState,
  ({ isProcessingPrompt }) => isProcessingPrompt,
);
export const processingInputSelector = createSelector(selectArticleState, ({ userInput }) => userInput);
export const favoritePromptsSelector = createSelector(selectArticleState, ({ favoritePrompts }) => favoritePrompts);
export const isFetchingPromptsSelector = createSelector(
  selectArticleState,
  ({ isFetchingPrompts }) => isFetchingPrompts,
);
export const favoritePromptPaginationSelector = createSelector(
  selectArticleState,
  ({ promptPagination }) => promptPagination,
);
export const favoritesCountSelector = createSelector(
  selectArticleState,
  ({ favoritePromptsCount }) => favoritePromptsCount,
);
