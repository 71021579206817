import React, { useEffect } from 'react';
import { DynamicListLayoutWithTitle } from './configComponents/DynamicListLayoutWithTitle';
import { Timeline } from './configComponents/DynamicTimeLineLayout';
import { DynamicTitle } from './configComponents/DynamicTitle';
import { EducationCapIcon } from '../../common/icons/Icons';
import { DynamicTitleDescriptionLayoutWithTitle } from './configComponents/DynamicTitleDescriptionLayoutWithTitle';
import { CurvedTimeLineLayout } from './configComponents/CurvedTimeLineLayout';
import { PersonalInformationContainer } from './configComponents/PersonalInformationContainer';
import { Dossier } from '../../common/services/dossier/person/dossierPerson';
import { ContactInformationContainer } from './configComponents/ContactInformationContainer';
import { DividerWithText } from '../Divider/DividerWithText';
import { useDispatch, useSelector } from 'react-redux';
import {
  achievementsOrProjectsUndertakenSelector,
  currentDossier,
  dossierProcessingInput,
  educationInformationSelector,
  isFetchingDossiers,
  professionalAssociationsAndMembershipSelector,
  publicationsandContributionsSelector,
  rolesAndResponsibilitiesSelector,
  skillsAndExpertiseSelector,
  workExperienceSelector,
} from '../../redux/selectors/dossier.selectors';
import { RoutePath } from '../../common/constants/route-path';
import { setNotification } from '../../redux/actions/notification.actions';
import { dossierActions } from '../../redux/actions/dossier.actions';
import { useNavigate, useParams } from 'react-router-dom';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';
import { ContentInput } from '../ContentInput';
import ProgressBar from '../Progressbar/Progressbar';

export const DynamicLayoutContainer: React.FC = () => {
  // Replace jsonData with your actual JSON data
  const dossierInformation: Dossier = useSelector(currentDossier);
  const isFetchingDossier: boolean = useSelector(isFetchingDossiers);
  const educationInformation = useSelector(educationInformationSelector);
  const rolesAndResponsibilities = useSelector(rolesAndResponsibilitiesSelector);
  const workExperience = useSelector(workExperienceSelector);
  const achievementsOrProjectsUndertaken = useSelector(achievementsOrProjectsUndertakenSelector);
  const skillsAndExpertiseDto = useSelector(skillsAndExpertiseSelector);
  const professionalAssociationsAndMembershipDto = useSelector(professionalAssociationsAndMembershipSelector);
  const publicationsandContributions = useSelector(publicationsandContributionsSelector);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  let userProcessingInput = useSelector(dossierProcessingInput);
  useEffect(() => {
    if (id) {
      dispatch(dossierActions.getDossierById(id));
    } else {
      dispatch(setNotification({ title: 'Dossier Id is missing ', type: 'error' }));
      navigate(RoutePath.Dossier);
    }
  }, [id]);
  return (
    <>
      {userProcessingInput ? (
        <div className="md:w-7/10 w-full relative">
          <ContentInput
            showTextInput={true}
            engineType={GuardRailEngineType.Dossier}
            userProcessingInput={userProcessingInput}
          ></ContentInput>
        </div>
      ) : null}
      {dossierInformation ? (
        <div className="container mx-auto p-4 ">
          <div className="bg-gray-100 shadow rounded-lg p-6">
            {dossierInformation.personalInformationDto ? (
              <PersonalInformationContainer
                isFavorite={dossierInformation.isFavorite}
                id={dossierInformation.dossierId}
                personalInformation={dossierInformation.personalInformationDto}
              />
            ) : null}
            <section className="py-2 sm:py-2 lg:py-2 animate__animated animate__fadeInUp">
              {dossierInformation.contactInformationDto ? (
                <ContactInformationContainer contactInformation={dossierInformation.contactInformationDto} />
              ) : null}
            </section>
            <section
              className="bg-gray-900 rounded-2xl
 border border-gray-200
 shadow-sm text-white mt-5 animate__animated animate__fadeInUp"
            >
              <DynamicTitleDescriptionLayoutWithTitle
                icon={<EducationCapIcon />}
                items={educationInformation.items}
                title={educationInformation.title}
              />
            </section>
            <DividerWithText />
            <DynamicTitle title="Employment History" />
            <div>
              <CurvedTimeLineLayout title={workExperience.title} items={workExperience.items} />
              <section
                className="bg-gray-900 rounded-2xl
 border border-gray-200
 shadow-sm text-white my-5 animate__animated animate__fadeInUp"
              >
                <DynamicTitleDescriptionLayoutWithTitle
                  icon={<EducationCapIcon />}
                  items={rolesAndResponsibilities.items}
                  title={rolesAndResponsibilities.title}
                />
              </section>
              <Timeline title={achievementsOrProjectsUndertaken.title} items={achievementsOrProjectsUndertaken.items} />
            </div>
            <DividerWithText />
            <DynamicListLayoutWithTitle items={skillsAndExpertiseDto.items} title={skillsAndExpertiseDto.title} />
            <DynamicListLayoutWithTitle
              items={professionalAssociationsAndMembershipDto.items}
              title={professionalAssociationsAndMembershipDto.title}
            />
            <DynamicListLayoutWithTitle
              items={publicationsandContributions.items}
              title={publicationsandContributions.title}
            />
          </div>
        </div>
      ) : (
        <div className="flex  h-screen text-gray-400 mt-10">
          {isFetchingDossier ? (
            <div className="w-4/5 md:w-4/5 mx-auto">
              <ProgressBar stop={true} message="Fetching Dossier Information..." showTime={false} />
            </div>
          ) : (
            <>No data to display</>
          )}
        </div>
      )}
    </>
  );
};
