import { createSelector } from '@reduxjs/toolkit';
import { ArticleState, articleSlice } from '../slices/article.slice';
import { AppState } from '../types/App.type';

const selectArticleState: (state: AppState) => ArticleState = (state) => state.article;
export const currentArticle = createSelector(selectArticleState, ({ currentArticle }) => currentArticle);

export const processArticleEnabled = createSelector(
  selectArticleState,
  ({ processArticleEnabled }) => processArticleEnabled,
);

export const articleErrorCodes = createSelector(selectArticleState, ({ errorCode }) => errorCode);
export const defaultArticle = createSelector(selectArticleState, ({ defaultArticle }) => defaultArticle);
export const userArticlesList = createSelector(selectArticleState, ({ articlesList }) => articlesList);
export const isProcessingArticle = createSelector(selectArticleState, ({ isProcessingArticle }) => isProcessingArticle);
export const processingInput = createSelector(selectArticleState, ({ userInput }) => userInput);
export const favoriteArticleSelector = createSelector(selectArticleState, ({ favoriteArticles }) => favoriteArticles);
export const isFetchingArticles = createSelector(selectArticleState, ({ isFetchingArticles }) => isFetchingArticles);
export const favoriteArticlePagination = createSelector(
  selectArticleState,
  ({ articlePagination }) => articlePagination,
);
export const favoritesCount = createSelector(selectArticleState, ({ favoriteArticlesCount }) => favoriteArticlesCount);
export const isDeleting = createSelector(selectArticleState, ({ isDeleting }) => isDeleting);
