import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { ClientAppService } from '../../common/services/client-app/client-app.service';
import { ClientAppDto, ClientAppResponseDto } from '../../common/services/client-app/models/client-app';
import { clientAppActions } from '../actions/client-app.actions';
import { setNotification } from '../actions/notification.actions';
import { browserHistory } from '../helpers/history/history';
import { RoutePath } from '../../common/constants/route-path';

export function* PostClientApp({ payload }: PayloadAction<ClientAppDto>) {
  try {
    const clientAppResult: ClientAppResponseDto = yield call(ClientAppService.postClientApp, payload);
    yield put(
      setNotification({
        title: 'Client app added successfully',
        type: 'success',
      }),
    );
    yield put(clientAppActions.postClientAppSuccess(clientAppResult));
    let message = clientAppResult ? 'Client app created successfully' : 'unable to create client app';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(
      clientAppActions.postClientAppFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
    yield put(
      setNotification({
        title: 'Unable to create client api secret key',
        type: 'error',
      }),
    );
  }
}

export function* updateClientApp({ payload }: PayloadAction<any>) {
  try {
    // yield call(ClientAppService.updateClientApp, payload);
    const updateClientAppResult: ClientAppResponseDto = yield call(
      ClientAppService.updateClientApp,
      payload,
      payload.id,
    );
    yield put(
      setNotification({
        title: 'Client app updated successfully',
        type: 'success',
      }),
    );
    yield put(clientAppActions.updateClientAppSuccess(updateClientAppResult));
    let message = updateClientAppResult.clientToHostHeaderKey
      ? 'Client app updated successfully'
      : 'unable to update client app details';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(
      clientAppActions.updateClientAppFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
    yield put(
      setNotification({
        title: 'unable to update client app details',
        type: 'error',
      }),
    );
  }
}

export function* getClientAppById({ payload }: PayloadAction<string>) {
  try {
    const clientAppInfo: ClientAppResponseDto = yield call(ClientAppService.getClientAppById, payload);
    if (clientAppInfo) {
      yield put(clientAppActions.getClientAppByIdSuccess(clientAppInfo));
    } else {
      yield put(
        clientAppActions.getClientAppByIdFailure({
          message: 'ClientApp Not Found',
          statusCode: 404,
        }),
      );
    }
  } catch (err: any) {
    yield put(
      clientAppActions.getClientAppByIdFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
    yield put(
      setNotification({
        title: 'Unable to fetch ClientApp details',
        type: 'error',
      }),
    );
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export function* getClientAppByUserId({}: PayloadAction<string>) {
  try {
    const clientAppInfo: ClientAppResponseDto = yield call(ClientAppService.getClientAppByUserId);
    if (clientAppInfo) {
      yield put(clientAppActions.getClientAppByUserIdSuccess(clientAppInfo));
    } else {
      yield put(
        clientAppActions.getClientAppByUserIdFailure({
          message: 'No ClientApp associated with the user',
          statusCode: 404,
        }),
      );
    }
  } catch (err: any) {
    yield put(
      clientAppActions.getClientAppByUserIdFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
    yield put(
      setNotification({
        title: 'No ClientApp associated',
        type: 'error',
      }),
    );
    browserHistory.push(RoutePath.clientApiOnboarding);
  } finally {
  }
}

export function* deleteClientApp({ payload }: PayloadAction<string>) {
  try {
    yield call(ClientAppService.deleteClientApp, payload);
    yield put(clientAppActions.deleteClientAppSuccess());
    let message = payload ? `Client App deleted successfully ${payload}` : 'Client App deleted successfully';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(
      clientAppActions.deleteClientAppFailure({
        message: 'delete failed',
        statusCode: 404,
      }),
    );
    yield put(
      setNotification({
        title: 'delete failed',
        type: 'error',
      }),
    );
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export const PROCESS_CLIENT_APP_SAGA = 'Process Client App Saga';
export const processClientAppSaga = createAction<string>(PROCESS_CLIENT_APP_SAGA);

export default function* watcher() {
  // yield takeLatest(clientAppActions.initialize.type, initialSaga);
  yield takeLatest(clientAppActions.postClientApp.type, PostClientApp);
  yield takeEvery(clientAppActions.getClientAppById.type, getClientAppById);
  yield takeEvery(clientAppActions.getClientAppByUserId.type, getClientAppByUserId);
  yield takeLatest(clientAppActions.updateClientApp.type, updateClientApp);
  yield takeEvery(clientAppActions.deleteClientApp.type, deleteClientApp);
}
