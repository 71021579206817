import { LinkIcon } from '@heroicons/react/outline';
import { GitHubIcon } from '../../../common/icons/Icons';
import { CodeMirrorInput } from '../CodeMirrorInput';
import Badge from '../../Base/Badge';
import { useEffect, useState } from 'react';
import { GithubResponse, Website } from '../../../types/models';

interface WebsiteReferenceProps {
  index?: number;
  item: Website;
  code?:string;
}

export const WebsiteReference = ({ index = 1 , item, code="" }:WebsiteReferenceProps) => {
  let [primaryTags , setPrimaryTags] = useState<string[]>([]);
  useEffect(() => {
    let primary = [] as string[];
    
    if(item){
        primary.push(`Tier ${item.tier}`)
    }
    setPrimaryTags(primary);
  },[])
  return (
    <>
      <div className="mt-5 animate__animated animate__fadeInUp ">
        <p className="font-bold font-black">Reference {index+1}</p>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col ">
            <div className="flex  flex-row gap-3 mt-4">
            <span className="text-gray-500 font-semibold font-[Open_sans]">Source</span>
              <span onClick={() => window.open(item.url, '_blank') }  className="text-primary-blue font-[Open_sans]">{item.source ?? ""} - {item.title}</span>
            </div>
            <div>
              <div className="flex flex-row flex-wrap ">
                {primaryTags.map((e, index) => (
                  <Badge key={index} backgroundColor="#EBEEF6" text={e} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <>
        { code && code.length && <CodeMirrorInput
            hideTitle={true}
            disabled={true}
            code={code}
          />}
        </>
      </div>
    </>
  );
};
