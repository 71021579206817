import { createSlice } from '@reduxjs/toolkit';
import { AssertionResult, ContradictionResult, FavoriteArticles, PaginationConfig } from '../../types/models';
import { ARTICLE_STORE_NAME, articleActions } from '../actions/article.actions';
import { CONTRADICTION_FINDER_STORE_NAME, contradictionActions } from '../actions/contradiction.actions';
import {
  mapApiResponseToResult,
  mapArticlesToFavorite,
  mapContradictionApiResponseToResult,
  mapContradictionToFavorite,
} from '../../common/helpers/Helper';
import { DefaultAssertionsData } from '../../common/constants/defaultArticle';
import { profileActions } from '../actions/profile.actions';

export interface ContradictionState {
  currentContradiction?: ContradictionResult | any;
  defaultContradiction?: ContradictionResult;
  userInput: string;
  isProcessingContradiction: boolean;
  enableContradictionResults: boolean;
  processContradictionEnabled: boolean;
  errorCode: string;
  isFetchingContradictions: boolean;
  isFavoriteEnabled: boolean;
  contradictionList: ContradictionResult[];
  favoriteContradictionsCount: number;
  articlePagination: PaginationConfig;
  favoriteContradictions: FavoriteArticles[];
  isDeleting: boolean;
}

const initialState: ContradictionState = {
  userInput: '',
  currentContradiction: null,
  isProcessingContradiction: false,
  isFetchingContradictions: false,
  enableContradictionResults: false,
  processContradictionEnabled: false,
  isFavoriteEnabled: false,
  errorCode: '',
  defaultContradiction: undefined,
  contradictionList: [],
  articlePagination: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  favoriteContradictions: [],
  favoriteContradictionsCount: 0,
  isDeleting: false,
};
export const contradictionSlice = createSlice({
  name: CONTRADICTION_FINDER_STORE_NAME,
  initialState,
  reducers: {
    processContradiction: (state, action) => {
      state.isProcessingContradiction = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(contradictionActions.processContradiction, (state, { payload }) => {
        state.isProcessingContradiction = true;
        state.userInput = payload;
      })
      .addCase(contradictionActions.setPromptContent, (state, { payload }) => {
        state.isProcessingContradiction = true;
        state.userInput = payload;
      })
      .addCase(contradictionActions.getDefaultContradictionSuccess, (state) => {
        // state.defaultContradiction = mapContradictionApiResponseToResult(DefaultAssertionsData);
      })
      .addCase(contradictionActions.processContradictionSuccess, (state, { payload }) => {
        state.currentContradiction = mapContradictionApiResponseToResult(payload);
        state.isProcessingContradiction = false;
        let newContradiction = mapContradictionToFavorite([payload]);
        state.favoriteContradictions.unshift(newContradiction[0]);
        state.errorCode = '';
      })
      .addCase(contradictionActions.toggleFavoriteContradictions, (state, { payload }) => {
        state.isFavoriteEnabled = payload;
        let records = state.contradictionList;
        if (state.isFavoriteEnabled) {
          records = state.contradictionList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteContradictions = mapContradictionToFavorite(records);
        state.favoriteContradictionsCount = state.contradictionList.filter((article) => article.isFavorite).length;
      })
      .addCase(contradictionActions.getUserContradictionsListSuccess, (state, { payload }) => {
        state.contradictionList = state.contradictionList.concat(payload.data ?? []);

        let records = state.contradictionList;
        if (state.isFavoriteEnabled) {
          records = state.contradictionList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteContradictions = mapContradictionToFavorite(records);
        state.favoriteContradictionsCount = state.contradictionList.filter(
          (article, index, self) => article.isFavorite === true,
        ).length;
        state.articlePagination = payload.meta;
        console.log(state.articlePagination);
        state.isFetchingContradictions = false;
      })
      .addCase(contradictionActions.getUserContradictionsList, (state, { payload }) => {
        state.isFetchingContradictions = true;
      })
      .addCase(contradictionActions.setContradictionFavoriteSuccess, (state, { payload }) => {
        state.currentContradiction = payload;
        state.contradictionList = state.contradictionList.map((dossier) => {
          if (dossier.id === payload.id) {
            dossier.isFavorite = payload.isFavorite;
          }
          return dossier;
        });
        if (state.currentContradiction.id === payload.id) {
          state.currentContradiction.isFavorite = payload.isFavorite;
        }
        let records = state.contradictionList;
        if (state.isFavoriteEnabled) {
          records = state.contradictionList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteContradictions = mapContradictionToFavorite(records);
        state.favoriteContradictionsCount = state.contradictionList.filter(
          (article, index, self) => article.isFavorite === true,
        ).length;
      })
      .addCase(contradictionActions.getUserContradictionsListFailure, (state, { payload }) => {
        state.isFetchingContradictions = false;
      })
      .addCase(contradictionActions.processContradictionFailure, (state, { payload }) => {
        state.isProcessingContradiction = false;
        state.errorCode = payload.message;
      })
      .addCase(contradictionActions.getContradictionByIdSuccess, (state, { payload }) => {
        state.currentContradiction = mapContradictionApiResponseToResult(payload);
        state.errorCode = '';
      })
      .addCase(contradictionActions.deleteContradiction, (state, { payload }) => {
        state.isDeleting = true;
        state.userInput = payload;
      })
      .addCase(contradictionActions.deleteContradictionSuccess, (state, { payload }) => {
        state.isDeleting = false;
        if (state.currentContradiction?.id == payload) {
          state.currentContradiction = null;
        }
        state.contradictionList = state.contradictionList.filter((e) => e.id != payload);
        let records = state.contradictionList;
        if (state.isFavoriteEnabled) {
          records = state.contradictionList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteContradictions = mapContradictionToFavorite(records);
        state.favoriteContradictionsCount = state.contradictionList.filter(
          (article, index, self) => article.isFavorite === true,
        ).length;
      })
      .addCase(contradictionActions.deleteContradictionFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(contradictionActions.clearContradictionHistorySuccess, (state) => {
        state.contradictionList = [];
        state.favoriteContradictions = [];
        state.favoriteContradictionsCount = 0;
        state.articlePagination = {
          page: 1,
          take: 10,
          itemCount: 0,
          hasPreviousPage: false,
          hasNextPage: false,
        };
      })
      .addCase(contradictionActions.getContradictionByIdFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(contradictionActions.processContradictionEnabled, (state, { payload }) => {
        state.processContradictionEnabled = payload;
      })
      .addCase(profileActions.logout, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { processContradiction } = contradictionSlice.actions;
export const contradictionReducer = contradictionSlice.reducer;
