import { createSlice } from '@reduxjs/toolkit';
import { DossierLite, FavoriteArticles, PaginationConfig } from '../../types/models';
import {
  mapDossierOrganizationToLite,
  mapDossierToFavorite,
  mapDossierToPersonLite,
} from '../../common/helpers/Helper';
import { Dossier } from '../../common/services/dossier/person/dossierPerson';
import { mockDossier } from '../../common/services/dossier/dossier.service';
import { DOSSIER_STORE_NAME, dossierActions } from '../actions/dossier.actions';
import { DossierOrgActions } from '../actions/dossier-org.actions';
import { DossierTypes } from '../../common/constants/default-values';
import { profileActions } from '../actions/profile.actions';

export interface DossierState {
  currentDossier?: Dossier | any;
  defaultDossier: Dossier;
  userInput: string;
  currentDossierId: string;
  isProcessingDossier: boolean;
  enableDossierResults: boolean;
  processDossierEnabled: boolean;
  errorCode: string;
  currentDossierType: string;
  isFetchingDossiers: boolean;
  isFavoriteEnabled: boolean;
  dossiersList: DossierLite[];
  favoriteDossiersCount: number;
  favoriteDossiers: FavoriteArticles[];
  dossierPagination: PaginationConfig;
  isDeleting: boolean;
}

const initialState: DossierState = {
  userInput: '',
  currentDossier: mockDossier,
  isProcessingDossier: false,
  isFetchingDossiers: false,
  currentDossierType: DossierTypes.Person,
  currentDossierId: '',
  enableDossierResults: false,
  processDossierEnabled: false,
  isFavoriteEnabled: false,
  errorCode: '',
  defaultDossier: mockDossier,
  dossiersList: [],
  dossierPagination: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  favoriteDossiers: [],
  favoriteDossiersCount: 0,
  isDeleting: false,
};
export const DossierSlice = createSlice({
  name: DOSSIER_STORE_NAME,
  initialState,
  reducers: {
    processDossier: (state, action) => {
      state.isProcessingDossier = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dossierActions.processDossier, (state, { payload }) => {
        state.isProcessingDossier = true;
        state.userInput = payload.subject_name;
        if (payload.subject_class === DossierTypes.Organization) {
          state.currentDossierType = DossierTypes.Organization;
        } else {
          state.currentDossierType = DossierTypes.Person;
        }
      })
      .addCase(DossierOrgActions.processDossierOrg, (state, { payload }) => {
        state.userInput = payload.subject_name;
        state.isProcessingDossier = true;
        if (payload.subject_class === DossierTypes.Organization) {
          state.currentDossierType = DossierTypes.Organization;
        } else {
          state.currentDossierType = DossierTypes.Person;
        }
      })
      .addCase(dossierActions.getDefaultDossierSuccess, (state) => {
        state.defaultDossier = mockDossier;
      })
      .addCase(dossierActions.processDossierSuccess, (state, { payload }) => {
        state.currentDossier = payload;
        state.currentDossierId = payload.dossierId;
        state.dossiersList.unshift(mapDossierToPersonLite(state.currentDossier));
        state.favoriteDossiers = mapDossierToFavorite(
          state.dossiersList.filter((article, index, self) => article.isFavorite === state.isFavoriteEnabled),
        );
        state.isProcessingDossier = false;
        state.errorCode = '';
      })
      .addCase(DossierOrgActions.processDossierOrgSuccess, (state, { payload }) => {
        state.isProcessingDossier = false;
        state.currentDossierId = payload.dossierOrgId;
        state.errorCode = '';
        state.dossiersList.unshift(mapDossierOrganizationToLite(payload));
        state.favoriteDossiers = mapDossierToFavorite(
          state.dossiersList.filter((article, index, self) => article.isFavorite === state.isFavoriteEnabled),
        );
      })
      .addCase(dossierActions.getUserDossiersListSuccess, (state, { payload }) => {
        state.dossiersList = state.dossiersList.concat(payload.data ?? []);
        state.dossiersList = state.dossiersList.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        let records = state.dossiersList;
        if (state.isFavoriteEnabled) {
          records = state.dossiersList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteDossiers = mapDossierToFavorite(records);
        state.favoriteDossiersCount = state.isFavoriteEnabled
          ? state.favoriteDossiers.length
          : state.dossiersList.filter((article, index, self) => article.isFavorite === true).length;
        state.dossierPagination = payload.meta;
        console.log(state.dossierPagination);
        state.isFetchingDossiers = false;
      })
      .addCase(DossierOrgActions.getUserDossierOrgsListSuccess, (state, { payload }) => {
        let organizationList = payload.data ?? [];
        state.dossiersList = state.dossiersList.concat(organizationList);
        state.dossiersList = state.dossiersList.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        let records = state.dossiersList;
        if (state.isFavoriteEnabled) {
          records = state.dossiersList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteDossiers = mapDossierToFavorite(records);
        state.favoriteDossiersCount = state.isFavoriteEnabled
          ? state.favoriteDossiers.length
          : state.dossiersList.filter((article, index, self) => article.isFavorite === true).length;
      })
      .addCase(dossierActions.getUserDossiersList, (state, { payload }) => {
        state.isFetchingDossiers = true;
      })
      .addCase(dossierActions.setDossierFavoriteSuccess, (state, { payload }) => {
        state.currentDossier = payload;
        let index = state.dossiersList.findIndex((e) => e.id === payload.dossierId);
        state.dossiersList[index].isFavorite = payload.isFavorite;
        var list = state.dossiersList;
        if (state.isFavoriteEnabled) {
          list = state.dossiersList.filter((dossier, index, self) => dossier.isFavorite === true);
        }
        state.favoriteDossiers = mapDossierToFavorite(list);
        state.favoriteDossiersCount = state.isFavoriteEnabled
          ? state.favoriteDossiers.length
          : state.dossiersList.filter((dossier, index, self) => dossier.isFavorite === true).length;
      })
      .addCase(DossierOrgActions.setDossierOrgFavoriteSuccess, (state, { payload }) => {
        state.currentDossier = payload;
        let index = state.dossiersList.findIndex((e) => e.id === payload.dossierOrgId);
        state.dossiersList[index].isFavorite = payload.isFavorite;
        var list = state.dossiersList;
        if (state.isFavoriteEnabled) {
          list = state.dossiersList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteDossiers = mapDossierToFavorite(list);
        state.favoriteDossiersCount = state.isFavoriteEnabled
          ? state.favoriteDossiers.length
          : state.dossiersList.filter((article, index, self) => article.isFavorite === true).length;
      })
      .addCase(dossierActions.getUserDossiersListFailure, (state, { payload }) => {
        state.isFetchingDossiers = false;
      })
      .addCase(dossierActions.toggleFavoriteDossiers, (state, { payload }) => {
        state.isFavoriteEnabled = payload;
        let records = state.dossiersList;
        if (state.isFavoriteEnabled) {
          records = state.dossiersList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteDossiers = mapDossierToFavorite(records);

        state.favoriteDossiersCount = state.dossiersList.filter((article) => article.isFavorite).length;
      })
      .addCase(dossierActions.processDossierFailure, (state, { payload }) => {
        state.isProcessingDossier = false;
        state.errorCode = payload.message;
      })
      .addCase(DossierOrgActions.processDossierOrgFailure, (state, { payload }) => {
        state.isProcessingDossier = false;
        state.errorCode = payload.message;
      })
      .addCase(dossierActions.getDossierById, (state, { payload }) => {
        state.currentDossier = null;
        state.isFetchingDossiers = true;
      })
      .addCase(dossierActions.setDossierContent, (state, { payload }) => {
        state.userInput = payload;
      })
      .addCase(dossierActions.getDossierByIdSuccess, (state, { payload }) => {
        state.currentDossier = payload;
        if (
          state.userInput.trim().toLocaleLowerCase() !==
          state.currentDossier?.personalInformationDto?.FullName.trim().toLocaleLowerCase()
        ) {
          state.userInput = '';
        }
        state.errorCode = '';
        state.isFetchingDossiers = false;
      })
      .addCase(dossierActions.setDossierFavoriteFailure, (state, { payload }) => {
        state.isFetchingDossiers = false;
        state.errorCode = payload.message;
      })
      .addCase(dossierActions.deleteDossier, (state, { payload }) => {
        state.isDeleting = true;
        state.userInput = payload;
      })
      .addCase(dossierActions.deleteDossierSuccess, (state, { payload }) => {
        state.isDeleting = false;
        if (state.currentDossier?.id == payload) {
          state.currentDossier = null;
        }
        state.dossiersList = state.dossiersList.filter((e) => e.id != payload);
        let records = state.dossiersList;
        if (state.isFavoriteEnabled) {
          records = state.dossiersList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteDossiers = mapDossierToFavorite(records);
        state.favoriteDossiersCount = state.dossiersList.filter(
          (article, index, self) => article.isFavorite === true,
        ).length;
      })
      .addCase(dossierActions.deleteDossierFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(dossierActions.clearDossierPersonHistorySuccess, (state) => {
        state.dossiersList = [];
        state.favoriteDossiers = [];
        state.favoriteDossiersCount = 0;
        state.dossierPagination = {
          page: 1,
          take: 10,
          itemCount: 0,
          hasPreviousPage: false,
          hasNextPage: false,
        };
      })
      .addCase(dossierActions.processDossierEnabled, (state, { payload }) => {
        state.processDossierEnabled = payload;
      })
      .addCase(profileActions.logout, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { processDossier } = DossierSlice.actions;
export const DossierReducer = DossierSlice.reducer;
