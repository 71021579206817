import React, { useEffect, useState } from 'react';
import { planDetails } from '../../../common/services/payment/payment.service';
import PlanCard from '../components/PlanCard';
import { useDispatch, useSelector } from 'react-redux';
import { userDetails } from '../../../redux/selectors/profile.selectors';

import { useLocation } from 'react-router-dom';
import { MainButton } from '../../../components/MainButton';
import Typography from '../../../components/Typography/Ty[pgraphy';
import { profileActions } from '../../../redux/actions/profile.actions';
interface SubscriptionConfirmationProps {
  customerName: string;
  planName: string;
  amount: number;
  currency: string;
  nextPaymentDate: string;
}


const PlanConfirmationContent = ({ planId , planName }: { planId?: string , planName:string }) => {
    const [plan, setPlan] = useState<any>(null);
    const currentLoggedInUser = useSelector(userDetails);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(profileActions.setSubscripedPlan({isSubscribed: true, planId: planId ?? '', planName: planName ?? "Free"}))
    }, [planId]);
  
    const nextBillDate = new Date();
    nextBillDate.setDate(nextBillDate.getDate() + 30);
  
    return (
      <div className=" h-full text-center flex flex-col justify-center w-full rounded-lg p-6 items-center">
        <div className="flex flex-col items-center justify-center h-[250px]   p-4 animate__animated animate__zoomIn">
          {/* Replace icon-checkmark-circle with a Tailwind CSS compatible icon solution, e.g., Heroicons */}
          <svg width="133px" height="133px" viewBox="0 0 133 133" version="1.1" xmlns="http://www.w3.org/2000/svg" >
  <g id="check-group" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <circle id="filled-circle" fill="#B0F" cx="66.5" cy="66.5" r="54.5"></circle>
    <circle id="white-circle" fill="#FFFFFF" cx="66.5" cy="66.5" r="55.5"></circle>
    <circle id="outline" stroke="#B0F" stroke-width="4" cx="66.5" cy="66.5" r="54.5"></circle>
    <polyline id="check" stroke="#FFFFFF" stroke-width="4" points="41 70 56 85 92 49"></polyline>
  </g>
</svg>

          <h2 className="text-xl font-semibold text-progress-bar-start mt-4">Thank you, {currentLoggedInUser.firstName}, for subscribing to our {planName} plan!</h2>
          <p className="text-gray-600 mt-2">{planName}</p>
         
        
        </div>
        <div>
        <div className='mb-8 w-[550px] bg-white shadow-lg rounded-lg p-5 animate__animated animate__fadeIn'>
        <div role="list" className="flex flex-wrap gap-5 text-left ">
        {/* {plan.features.map((feature:any, index:number) => (
          <div key={index} className="flex items-center space-x-3 animate__animated animate__fadeIn"
          style={{ animationDelay: `${index * 0.4}s` }} >
            <svg
              className="flex-shrink-0 w-5 h-5 text-primray-pink dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className='text-gray-600'>{feature}</span>
          </div>
        ))} */}
      </div>
      <MainButton
          className="w-full w-100 px-4  mt-2"
          stateProp="rest"
          text={'Subscribed Successfully!'}
          onClick={() => {
            window.location.href = '/';
          }}/>
      </div>
      <div className="mt-4 flex flex-row gap-4  justify-center w-full">
  <Typography className="text-lg  text-gray-600 ">Next Bill Date: <span className="text-lg font-semibold text-progress-bar-start">{nextBillDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}</span></Typography>
</div>
        </div>
        <div className="flex flex-col items-center justify-center h-[250px]   p-4">
        <MainButton
          className="w-full w-100 px-4  mt-2"
          stateProp="rest"
          text={'Back to Home'}
          onClick={() => {
            window.location.href = '/';
          }}/>
        </div>
      </div>
    ) ;
  };
export const SubscriptionConfirmation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('planId') ?? "";
  const planName = queryParams.get('planName') ?? "";

  return (
    <PlanConfirmationContent planId={planId} planName={planName} />
  );
};