interface IconTabSwitchProps {
  activeIndex: number;
  icons: React.ReactNode[];
  count?: number[];
  showCount?: boolean;
  onChange: (index: number) => void;
}

export const IconTabSwitch: React.FC<IconTabSwitchProps> = ({
  showCount = false,
  count = [],
  activeIndex,
  icons,
  onChange,
}) => {
  return (
    <div className="flex flex-row border">
      {icons.map((icon, index) => (
        <span
          className={`flex w-25 h-25 p-1 border border-solid border-primary-blue ${
            index === activeIndex ? 'bg-primary-blue' : ''
          }`}
          onClick={() => onChange(index)}
        >
          <span className="mt-1 mx-2"> {icon} </span>
          {showCount && (
            <span className={` mx-2 ${index === activeIndex ? 'text-white' : 'text-primary-blue'}`}>
              {count[index]}
            </span>
          )}
        </span>
      ))}
    </div>
  );
};
