import { StorageService } from '../local-storage/storage.service';

class TokenServiceImpl {
  private accessTokenPath: string = 'accessToken';
  private refreshTokenPath: string = 'refreshToken';
  private userIdPath: string = 'userId';
  private _rememberMe: boolean = false;

  constructor(private storage: StorageService) {}

  get rememberMe(): boolean {
    return this._rememberMe;
  }

  set rememberMe(value: boolean) {
    this._rememberMe = value;
  }

  get accessToken(): string | undefined {
    return this.storage.getItem(this.accessTokenPath);
  }

  set accessToken(token: string | undefined) {
    token ? this.storage.setItem(this.accessTokenPath, token) : this.storage.removeItem(this.accessTokenPath);
  }

  get refreshToken(): string | undefined {
    return this.storage.getItem(this.refreshTokenPath);
  }

  set refreshToken(token: string | undefined) {
    token ? this.storage.setItem(this.refreshTokenPath, token) : this.storage.removeItem(this.refreshTokenPath);
  }

  set userId(userId: string | undefined) {
    userId ? this.storage.setItem(this.userIdPath, userId) : this.storage.removeItem(this.userIdPath);
  }

  get userId() {
    return this.storage.getItem(this.userIdPath);
  }

  clear() {
    this.storage.clear();
  }

  setStorage(storage: Storage) {
    this.storage.setStorage(storage);
  }
}

const storage = new StorageService('auth');

const TokenService = new TokenServiceImpl(storage);

export { TokenService };
