import { useState, useEffect, useRef } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

const ExpandableParagraph = ({ text, className }: { text: string; className: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const paragraphRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    const element = paragraphRef.current;
    if (element) {
      const isTextOverflowing = element.scrollHeight > element.clientHeight;
      setIsOverflowing(isTextOverflowing);
    }
  }, [text]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <p ref={paragraphRef} className={`${className} ${isExpanded ? '' : '!line-clamp-3'}`}>
        {text}
      </p>
      {isOverflowing && (
        <span
          onClick={toggleExpand}
          className={`text-primary-blue cursor-pointer ${className} flex items-center justify-end mb-1 mt-0 pt-0`}
        >
          {isExpanded ? 'See Less' : 'Read More'}
          {isExpanded ? <ChevronUpIcon width={12} height={12} /> : <ChevronDownIcon width={12} height={12} />}
        </span>
      )}
    </div>
  );
};

export default ExpandableParagraph;
