import { BehaviorSubject, Observable } from 'rxjs';

interface LoaderState {
  isLoading: boolean;
  message?: string;
}

const initialState: LoaderState = {
  isLoading: false,
  message: undefined,
};

const loaderSubject = new BehaviorSubject<LoaderState>(initialState);

export const loaderObservable: Observable<LoaderState> = loaderSubject.asObservable();

export const showLoader = (message?: string) => {
  loaderSubject.next({ isLoading: true, message });
};

export const hideLoader = () => {
  loaderSubject.next({ isLoading: false, message: undefined });
};

export const toggleLoader = (isLoading: boolean, message?: string) => {
    loaderSubject.next({ isLoading, message });
  };