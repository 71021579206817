import { Navigate, Router, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../components/PrimaryButton';
import { RoutePath } from '../../common/constants/route-path';
import { useDispatch } from 'react-redux';
import { profileActions } from '../../redux/actions/profile.actions';

const content = {
  heading: 'Take control of AI Generated Content',
  paragraphs: [
    'What is the original source ?',
    'Is it a source you trust ?',
    'Is There harmful or risky content ?',
    'Can you assume responsibility for the content ?',
  ],
};
export const MainContent = (): JSX.Element => {
  var navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <div className="flex justify-between w-full items-center">
        <div
          id="landing-left-content"
          className="px-5 text-left items-center flex-grow flex-shrink justify-center w-100"
        >
          <div className="ml-15">
            <h1 className="font-bold mb-2 text-3xl animate__animated animate__slideInLeft ">{content.heading}</h1>
            <div>
              {content.paragraphs.map((paragraph, index) => (
                <p key={index} className={`animate__animated animate__slideInLeft animate__delay-${index + 1}s`}>
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div
          id="landing-right-content"
          className="flex-grow flex-shrink text-center justify-center  animate__animated animate__slideInRight"
        >
          <h1 className="font-bold mb-2 text-3xl">Get Started</h1>
          <div className="flex justify-center mt-5">
            <PrimaryButton
              className="mx-2"
              size="small"
              stateProp="rest"
              style="boxed"
              text="Sign In"
              onClick={() => {
                dispatch(profileActions.resetError())
                navigate(RoutePath.SignIn);
              }}
            />
            <PrimaryButton
              className="mx-2"
              size="small"
              stateProp="rest"
              onClick={() => {
                dispatch(profileActions.resetError())
                navigate(RoutePath.SignUp);
              }}
              style="boxed"
              text="Create Account"
            />
          </div>
        </div>
      </div>
    </>
  );
};
