import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { articleActions } from '../../redux/actions/article.actions';
import { articleErrorCodes, processingInput } from '../../redux/selectors/article.selectors';
import {
  PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER,
  PROCESSING_WAITING_MESSAGE_GUEST,
  PROCESSING_WAITING_MESSAGE_USER,
} from '../../common/constants/app-text';
import { MainButton } from '../MainButton';
import ProgressBar from '../Progressbar/Progressbar';
import { RoutePath } from '../../common/constants/route-path';
import { isGuestUser, isLoggedInUser } from '../../redux/selectors/activeUser.selectors';
import { SignInGroupPrimary } from '../SingInGroupPrimary/SignInGroupPrimary';
import { setNotification } from '../../redux/actions/notification.actions';
import Dropdown from '../DropDown/DropDown';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';
import { dossierActions } from '../../redux/actions/dossier.actions';
import { dossierInputType } from '../../redux/selectors/dossier.selectors';
import { AppState } from '../../redux/types/App.type';
import { DossierOrgActions } from '../../redux/actions/dossier-org.actions';
import { DossierTypes } from '../../common/constants/default-values';
import { PromptActions } from '../../redux/actions/prompt.actions';
import { getShowProgressBarSelectorFromForEngine } from '../../common/helpers/Helper';
import { contradictionActions } from '../../redux/actions/contradiction.actions';
import { federatedActions } from '../../redux/actions/federated.actions';
import { processedFactChecker } from '../../redux/selectors/federated.selectors';
import { processingContradictionInput } from '../../redux/selectors/contradiction.selectors';
import { processingInputSelector } from '../../redux/selectors/prompt.selectors';
import { cacheResults } from '../../redux/selectors/user-preference.selectors';
import { RequestTrackers } from '../RequestTracker/RequestTrackers';
import { limitedReached } from '../../redux/selectors/profile.selectors';
import AppLoader from '../AppLoader/AppLoader';
import { LoadingIndicator } from '../AppLoader/LoadingIndicator';

interface ContentInputProps {
  userProcessingInput?: string;
  viewType?: 'Processing' | 'Results' | 'Input';
  placeHolderText?: string;
  showTextInput?: boolean;
  engineType: GuardRailEngineType;
}

export function ContentInput({
  userProcessingInput,
  viewType,
  placeHolderText = PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER,
  showTextInput = false,
  engineType = GuardRailEngineType.FactChecker,
}: ContentInputProps) {
  const [content, setContent] = useState<string>(userProcessingInput ?? '');
  const isLoggedIn = useSelector(isLoggedInUser);
  const loggedInAsGuest = useSelector(isGuestUser);
  const prossedFactChecker = useSelector(processedFactChecker);
  const currentInputForDossier = useSelector(dossierInputType);
  const limitReached = useSelector(limitedReached);
  const errorCode = useSelector(articleErrorCodes);
  const location = useLocation();
  const routeName = location.pathname;
  const [charCount, setCharCount] = useState<number>(0);
  const dispatch = useDispatch();
  const cacheResultsEnabled = useSelector(cacheResults);
  const [liveData, setLiveData] = useState<boolean>(cacheResultsEnabled);
  const showProgressBar = useSelector(getShowProgressBarSelectorFromForEngine(engineType));
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const currentActiveAssetionId = useSelector((state: AppState) => state.article.currentArticle?.id);

  const currentContradictionInput = useSelector(processingContradictionInput);
  const currentPromptProtectInput = useSelector(processingInputSelector);
  const currentFactCheckerInput = useSelector(processingInput);
  const currentActivePromptId = useSelector((state: AppState) => state.prompt.currentPrompt?.id);
  const currentActiveDossierId = useSelector((state: AppState) => state.dossier.currentDossierId);
  const currentActiveContradictionId = useSelector((state: AppState) => state.contradiction.currentContradiction?.id);
  const currentFactCheckerText = useSelector((state: AppState) => state.federated.currentFactCheckPrompt);
  const currentFactCheckerOriginalModel = useSelector(
    (state: AppState) => state.federated.currentFactCheckerOriginalModel,
  );
  const [filterOption, setFilterOption] = useState<string>(DossierTypes.Person);

  useEffect(() => {
    var searchText = searchParams.get('SearchText');
    var filterType = searchParams.get('Type');
    if (searchText && filterType && engineType === GuardRailEngineType.Dossier) {
      setContent(searchText);
      setCharCount(searchText.length);
      setFilterOption(filterType);
      handleClick();
    }
  }, [searchParams, engineType, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn || loggedInAsGuest) {
      if (routeName === RoutePath.Landing) {
        if (engineType === GuardRailEngineType.FactChecker && showProgressBar) {
          dispatch(articleActions.processArticle(content));
          navigate(RoutePath.DossierProcess);
        } else if (engineType === GuardRailEngineType.Dossier && showProgressBar) {
          let dossierContent = {
            subject_name: content,
            subject_class: filterOption,
            live_data: liveData,
          };
          if (filterOption === DossierTypes.Person) {
            dispatch(dossierActions.processDossier(dossierContent));
          } else {
            dispatch(DossierOrgActions.processDossierOrg(dossierContent));
          }
          navigate(RoutePath.DossierProcess);
        } else if (engineType === GuardRailEngineType.ContractionFinder && showProgressBar) {
          dispatch(contradictionActions.processContradiction(content));
          navigate(RoutePath.ContradictionFinderProcess);
        }
      }
    }
  }, [isLoggedIn, loggedInAsGuest]);

  useEffect(() => {
    if (engineType === GuardRailEngineType.Federated) {
      setContent(currentFactCheckerText);
      setCharCount(currentFactCheckerText.length);
      handleClick();
    } else if (engineType === GuardRailEngineType.FactChecker) {
      setContent(currentFactCheckerInput);
      setCharCount(currentFactCheckerInput.length);
    } else if (engineType === GuardRailEngineType.ContractionFinder) {
      setContent(currentContradictionInput);
      setCharCount(currentContradictionInput.length);
    } else if (engineType === GuardRailEngineType.PromptProtect) {
      setContent(currentPromptProtectInput);
      setCharCount(currentPromptProtectInput.length);
    }
  }, []);

  useEffect(() => {
    if (engineType === GuardRailEngineType.Federated && prossedFactChecker) {
      navigate(prossedFactChecker?.id);
      dispatch(federatedActions.processArticleReset());
    }
  }, [prossedFactChecker]);

  const handleClick = () => {
    try {
      if (isLoggedIn || loggedInAsGuest) {
        if (engineType === GuardRailEngineType.FactChecker) {
          dispatch(articleActions.processArticle(content));
          navigate(RoutePath.FactCheckerProcess);
        } else if (engineType === GuardRailEngineType.Dossier) {
          let dossierContent = {
            subject_name: content,
            subject_class: filterOption,
            live_data: liveData,
          };
          if (filterOption === DossierTypes.Person) {
            dispatch(dossierActions.processDossier(dossierContent));
          } else {
            dispatch(DossierOrgActions.processDossierOrg(dossierContent));
          }
          navigate(RoutePath.DossierProcess);
        } else if (engineType === GuardRailEngineType.PromptProtect) {
          dispatch(PromptActions.processPrompt(content));
          navigate(RoutePath.PromptProcess);
        }
        if (engineType === GuardRailEngineType.ContractionFinder) {
          dispatch(contradictionActions.processContradiction(content));
          navigate(RoutePath.ContradictionFinderProcess);
        } else if (engineType === GuardRailEngineType.Federated) {
          dispatch(
            federatedActions.processArticle({
              prompt: currentFactCheckerText,
              sourceType: currentFactCheckerOriginalModel,
            }),
          );
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleProgressNavigation = () => {
    let id = currentActiveAssetionId;
    if (errorCode && errorCode.length) {
      dispatch(setNotification({ title: 'Unable to request.. ', type: 'error' }));
      navigate(RoutePath.Landing);
    } else {
      let route = RoutePath.FactCheckerResults as string;
      if (engineType === GuardRailEngineType.Dossier) {
        route = RoutePath.Dossier;
        if (currentInputForDossier === DossierTypes.Organization) {
          route = route + '/organization';
        } else {
          route = route + '/person';
        }
        id = currentActiveDossierId;
      } else if (engineType === GuardRailEngineType.PromptProtect) {
        route = RoutePath.PromptProtect;
        id = currentActivePromptId;
      } else if (engineType === GuardRailEngineType.ContractionFinder) {
        route = RoutePath.ContradictionFinder;
        id = currentActiveContradictionId;
      }

      navigate(`${route}/` + id);
    }
  };

  return (
    <div className="mt-5">
      <div className="flex justify-center items-center mt-2 mb-2 animate__animated animate__fadeInDown">
        {showTextInput ? (
          <>
            <div className="flex w-3/4">
              <div className="flex flex-col w-full items-start px-2">
                <div className="text-wrapper-25 text-left">Prompt</div>
                <input
                  style={{ backgroundColor: charCount > 0 ? 'white' : '#f0f1f9' }}
                  className={`relative w-full p-4 rounded-md my-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-lg text-base ${
                    viewType === 'Processing' ? 'text-gray-500' : ''
                  }`}
                  value={content}
                  disabled={viewType === 'Processing'}
                  placeholder={placeHolderText}
                  onChange={(e) => {
                    setContent(e.target.value);
                    setCharCount(e.target.value.length);
                  }}
                />
                <div></div>
              </div>

              {engineType === GuardRailEngineType.Dossier ? (
                <div className="flex flex-col w-1/4 items-start mx-5">
                  <div className="text-wrapper-25 text-left">Filter</div>
                  <div className="mt-4 w-full">
                    <Dropdown
                      options={[DossierTypes.Person, DossierTypes.Organization]}
                      defaultOption={currentInputForDossier}
                      onSelect={setFilterOption}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <textarea
            style={{ backgroundColor: charCount > 0 ? 'white' : '#f0f1f9' }}
            className={`content-input relative p-4 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-lg text-base ${
              viewType === 'Processing' ? 'text-gray-500' : ''
            } ${viewType === 'Input' ? 'w-full h-[400px]' : 'w-4/5'}`}
            value={content}
            disabled={viewType === 'Processing'}
            placeholder={placeHolderText}
            onChange={(e) => {
              setContent(e.target.value);
              setCharCount(e.target.value.length);
            }}
          />
        )}
      </div>

      {viewType === 'Processing' && (
        <div className="flex text-center mt-10 mb-5 animate__animated animate__zoomIn">
          <div className="w-4/5 md:w-4/5 mx-auto">
            <ProgressBar navigate={handleProgressNavigation} stop={!showProgressBar} />
          </div>
        </div>
      )}

      {viewType === 'Processing' && (
        <>
          <div className="flex justify-center items-center mt-2 mb-2 animate__animated animate__fadeInUp">
            <div
              className={`flex flex-col md:flex-row justify-between py-4 ${
                isLoggedIn && !loggedInAsGuest ? '' : 'w-4/5 md:w-4/5'
              }`}
            >
              <div className="flex space-x-6 items-center mb-4 md:mb-0 md:mr-4">
                <p className="don-t-want-to-wait">
                  <span className="text-blue-600 font-bold">Don’t want to wait? </span>
                  <span className="text-gray-500">
                    {isLoggedIn && !loggedInAsGuest
                      ? PROCESSING_WAITING_MESSAGE_USER
                      : PROCESSING_WAITING_MESSAGE_GUEST}
                  </span>
                </p>
              </div>
              {!(isLoggedIn && !loggedInAsGuest) && <SignInGroupPrimary />}
            </div>
          </div>
        </>
      )}

      {!viewType && (
        <div className="flex justify-center items-center mx-2 mt-4 gap-10 animate__animated animate__fadeInUp">
          <div className="w-4/5 md:w-4/5 flex items-center justify-between w-full">
            {engineType === GuardRailEngineType.Dossier ? (
              <div className="flex items-center min-w-[200px]">
                <input
                  type="checkbox"
                  checked={liveData}
                  onChange={(e) => setLiveData(e.target.checked)}
                  id="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <label htmlFor="checkbox" className="ml-2 text-wrapper-34">
                  Get Latest Information
                </label>
              </div>
            ) : null}
            <div className="flex justify-between w-full">
              <div className="w-[80%]">
                <RequestTrackers />
              </div>
              <div className="flex justify-end items-center space-x-4">
                <div className="text-wrapper-34">{charCount} characters</div>
                <MainButton
                  disabled={content.length < 3 || limitReached}
                  onClick={handleClick}
                  className=""
                  stateProp="rest"
                  text="Submit"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {viewType === 'Input' && (
        <div className="flex justify-between w-full">
          <div>
            <RequestTrackers />
          </div>
          <div className="text-wrapper-34">{charCount} characters</div>
        </div>
      )}
    </div>
  );
}
export default ContentInput;
