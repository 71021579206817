import MainPage from '../../components/MainPage/MainPage';
import { Header } from '../../components/Header';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import { MainMenu } from '../../layouts/Sidenav/MainMenu';
import { SideBarIcon } from '../../common/icons/Icons';
import { Footer } from '../../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { layoutExternalAppMainMenu } from '../../redux/selectors/external-search.selectors';
import { externalSearchActions } from '../../redux/actions/external-search.action';

export const PatternManagement = () => {
  const dispatch = useDispatch();
  let showMenu = useSelector(layoutExternalAppMainMenu);
  // var showMenu = useSelector(layoutMainMenu);
  const toggleExternalSourceList = useSelector(layoutExternalAppMainMenu);
  return (
    <MainPage>
      <Header />
      <div className="flex items-stretch result-scroll">
        <ContentLayout>
          <MainMenu isPushMenu={true} />
        </ContentLayout>
      </div>
      <Footer />
    </MainPage>
  );
};
