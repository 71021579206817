import React from 'react';
import './style.css';

interface FeatureCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  backgroundColor?: string;
}

export const FeatureCard = ({ title, description, icon, backgroundColor }: FeatureCardProps): JSX.Element => {
  return (
    <div className="w-full p-4 border border-highlight-lightgreen rounded-lg">
      <div className="bg-white">
        <div className="icon-container flex items-center justify-left mb-4">
          <div className="icon-wrapper p-4 rounded-md" style={{ backgroundColor }}>
            <div className="icon">{icon}</div>
          </div>
        </div>
        <div className="title font-bold mb-2">{title}</div>
        <p className="over-flow-wrap text-gray-600">{description}</p>
      </div>
    </div>
  );
};
