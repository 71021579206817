import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import '../Landing/style.css';
import { useDispatch, useSelector } from 'react-redux';
import MainPage from '../../components/MainPage/MainPage';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import UserAssertionsList from '../../layouts/Sidenav/UserAssertionsList';
import { layoutMainMenu, showUserArticles } from '../../redux/selectors/layout.selectors';
import { SideBarIcon } from '../../common/icons/Icons';
import { layoutActions } from '../../redux/actions/layout.actions';
import { MainMenu } from '../../layouts/Sidenav/MainMenu';
import { DOSSIER_GENERATOR } from '../../common/constants/app-text';
import { RoutePath } from '../../common/constants/route-path';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';

export const DossierGeneratorEngineView = (): JSX.Element => {
  const toggleUserAticles = useSelector(showUserArticles);
  const dispatch = useDispatch();
  var showMenu = useSelector(layoutMainMenu);

  return (
    <MainPage>
      <Header />
      <div className="flex items-stretch result-scroll">
        <UserAssertionsList
          engineType={GuardRailEngineType.Dossier}
          label={DOSSIER_GENERATOR}
          routerLink={RoutePath.Dossier}
        />
        <ContentLayout>
          {showMenu ? null : (
            <div
              className={` z-20 absolute left-10 top-15 w-15 cursor-pointer border-dark-purple
           border-2 rounded-full animate__animated  ${toggleUserAticles ? 'animate__zoomOut' : 'animate__zoomIn animate__delay-0.3s'}`}
              onClick={() => dispatch(layoutActions.toggleUsersArticles(true))}
            >
              <SideBarIcon />
            </div>
          )}
          <MainMenu isPushMenu={false} />
        </ContentLayout>
      </div>
      <Footer />
    </MainPage>
  );
};
