import { useState } from 'react';
import {
  GitHubIcon,
  StackOverFlowIcon,
  StatusIcon,
  WebIcon,
} from '../../common/icons/Icons';
import { IconTabSwitch } from '../IconTabSwitch/IconTabSwitch';
import { StackOverFlowReference } from './ReferenceFilder/StackOverflowReference';
import { GitHubReference } from './ReferenceFilder/GitHubReference';
import { useSelector } from 'react-redux';
import { referenceFinderResultSelector } from '../../redux/selectors/codeAnalysis.selectors';
import { MediumReference } from './ReferenceFilder/MediumReference';
import { WebsiteReference } from './ReferenceFilder/WebsiteReference';

export const CodeReferenceResultView = () => {
  let [activeVersionIndex, setActiveVersionIndex] = useState(0);
  let referenceFinderResult = useSelector(referenceFinderResultSelector);
  return (
    <>
    {referenceFinderResult &&  <div>
        <div className="flex items-center  justify-between mt-5 animate__animated animate__fadeInUp ">
          <div>
            <IconTabSwitch
              showCount={true}
              count={[referenceFinderResult.github.length, referenceFinderResult.stackoverflow.length, referenceFinderResult.medium.length, referenceFinderResult.website.length]}
              onChange={(index) => setActiveVersionIndex(index)}
              activeIndex={activeVersionIndex}
              icons={[
                <GitHubIcon color={activeVersionIndex === 0 ? '#fff' : '#0065FF'} />,
                <StackOverFlowIcon color={activeVersionIndex === 1 ? '#fff' : '#0065FF'} />,
                <StatusIcon color={activeVersionIndex === 2 ? '#fff' : '#0065FF'} />,
                <WebIcon color={activeVersionIndex === 3 ? '#fff' : '#0065FF'} />,
              ]}
            />
          </div>
        </div>
        <div className='max-h-[600px] overflow-auto'>
          {activeVersionIndex === 0 ? (
            <> 
            { referenceFinderResult.github.length ?  referenceFinderResult.github.map((item, index) =>
             <GitHubReference item={item} index={index} /> ):<div className="text-center text-sm font-[Open_sans] text-gray-600 mt-5">No Reference Found From Git Hub</div>}
            </>
          ):<></>}
          {activeVersionIndex === 1 ? (
            <>
              {referenceFinderResult.stackoverflow.length ? referenceFinderResult.stackoverflow.map((item, index) => <StackOverFlowReference code={item.code} item={item} index={index} />):
              <div className="text-center text-sm font-[Open_sans] text-gray-600 mt-5">No Reference Found From Stack Overflow</div>}
            </>
          ):<></>}
           {activeVersionIndex === 2 ? (
            <>
              {referenceFinderResult.medium.length ? referenceFinderResult.medium.map((item, index) => <MediumReference code={item.code} item={item} index={index} />):
              <div className="text-center text-sm font-[Open_sans] text-gray-600 mt-5">No Reference Found From Medium</div>}
            </>
          ):<></>}
           {activeVersionIndex === 3 ? (
            <>
              {referenceFinderResult.website.length ? 
              
              <>
              <div>
              {
                referenceFinderResult.website.map((item, index) => {
                  return <div>
                    <WebsiteReference code={item.code} item={item} index={index} />
                     </div>;
                })
              }</div></>:<div className="text-center text-sm font-[Open_sans] text-gray-600 mt-5">No Reference Found From Website</div>}
            </>
          ):<></>}
      
        </div>
      </div> }
     
    </>
  );
};
