import { useDispatch, useSelector } from 'react-redux';
import { PromptProtectIcon, PromptProtectPrimaryIcon, ThumbtackIcon } from '../../../common/icons/Icons';
import { ActionItem } from '../../ActionItem/ActionItem';
import Loading from '../../Loader/Loader';
import { federatedActions } from '../../../redux/actions/federated.actions';
import {
  isCheckingForProfanity,
  promptProtectCheckText,
  promptProtectResult,
} from '../../../redux/selectors/federated.selectors';
import { useState } from 'react';
import { Divider } from '../../Divider/Divider';
import { PromptsInfoResponseDto } from '../../../types/models';

export const PromptProtectCheck = ({ onClose }: any) => {
  const dispatch = useDispatch();
  const promptProtectInput = useSelector(promptProtectCheckText);
  const processedPrompt = useSelector(promptProtectResult);
  const isValidatingPrompt = useSelector(isCheckingForProfanity);
  const [promptProtectText, setPromptProtectText] = useState(promptProtectInput);


  const handleRunPromptProtect = () => {
    dispatch(federatedActions.processPromptProtect(promptProtectText));
  };

  const handleClose = () => {
    dispatch(federatedActions.togglePromptProtectModal(false));
    if (onClose) onClose();
  };
  

  
  const handleSubmit = () => {
    dispatch(federatedActions.togglePromptProtectModal(false));
    dispatch(federatedActions.setPromptText(promptProtectText));
  };

  const getUniqueProfanities = (items: PromptsInfoResponseDto[]): string[] => {
    const profanitySet = new Set<string>();
    items.forEach(item => {
      item.profanityCheckResponseDto.forEach(e => {
        profanitySet.add(e.profanity);
      });
    });
    return Array.from(profanitySet);
  };
  
  const uniqueProfanitiesArray: string[] = !isValidatingPrompt && processedPrompt
    ? getUniqueProfanities(processedPrompt.promptsInfoResponseDto)
    : [];
  
  const uniqueProfanities: string = uniqueProfanitiesArray.join(', ');
  
  const warningCount = uniqueProfanitiesArray.length;
  const hasProfanities = warningCount > 0;
  

  return (
    <div className=" flex items-center justify-center p-4 w-[50vw]">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full max-w-4xl mx-2">
        <div className="p-6">
          <div className="flex justify-between items-start flex-wrap">
            <div className="flex items-center mb-4 md:mb-0">
              <div className="input-action-item ">
                <div className="icon-wrapper">
                  <div className="text-wrapper-30">
                    <PromptProtectPrimaryIcon />
                  </div>
                </div>
                <div className="text-lg font-semibold text-gray-900">
                  <span> Prompt Protect</span>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center space-x-4">
              <div className="bg-gray-50 flex">
                <div className="text-sm font-medium text-blue-600 px-5 py-2">
                  {isValidatingPrompt && <Loading gap="mt-0" message="Scanning prompt, please wait" />}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6 px-2">
            <div className="col-span-1 flex flex-col ">
              <div className="flex justify-between items-center w-full">
                <div className="text-sm font-medium text-gray-600 mb-2">INPUT</div>
                {/* <div className="text-sm text-gray-500  text-purple-lite">54 characters</div> */}
              </div>
              <div className="bg-gray-50  rounded-md flex-grow min-h-150">
                <textarea
                  disabled={isValidatingPrompt}
                  onChange={(e) => setPromptProtectText(e.target.value)}
                  className={`text-sm p-4 h-full w-full ${isValidatingPrompt ? 'bg-gray-50' : ''}`}
                >
                  {promptProtectText}
                </textarea>
              </div>
            </div>


            <div className="col-span-1 flex flex-col">
              <div className="flex justify-between items-center w-full">
                <div className="text-sm font-medium text-gray-600 mb-2">FINDINGS</div>
                {!isValidatingPrompt && (
                  <div className="text-sm text-gray-500 text-purple-lite">
                  {hasProfanities
                    ? `${warningCount} Warning${warningCount > 0 ? 's' : ''} Found`
                    : 'No Warnings Found'}
                </div>
                  // <div className="text-sm  text-gray-500  text-purple-lite">
                  //    {hasProfanities
                  //     ? `${uniqueProfanities.length} Warning${uniqueProfanities.length > 1 ? 's' : ''} Found`
                  //     : 'No Warnings Found'}
                  // </div>
                )}
              </div>
              <div className="bg-gray-50 p-4 rounded-md flex-grow min-h-150 overflow-scroll">
                
                  {/* {!isValidatingPrompt &&
                    processedPrompt &&
                    processedPrompt.promptsInfoResponseDto.length > 0 &&
                    getUniqueProfanities(processedPrompt.promptsInfoResponseDto).map((profanity, index) => (
                      <div key={index}>
                      
                              <div className="text font-medium text-gray-700 mr-2">
                              Sensitive Text Found:<span className="bg-red-100 px-2">{profanity}</span>
                              </div>
                              <Divider/>
                              </div>
                              ))} */}
                               

  {!isValidatingPrompt && hasProfanities && (
    <div>
      <div className="text font-medium text-gray-700">
        Sensitive Text Found: <span className="bg-red-100 px-2">{uniqueProfanities}</span>
      </div>
      <Divider />
    </div>
  )}
  {!isValidatingPrompt && !hasProfanities && (
    <div className="w-full h-full flex items-center justify-center text-center flex-col">
      <ThumbtackIcon />
      <span className="text-gray-500 mt-2 font-['Open_Sans']">Prompt is good to go</span>
    </div>
  )}
</div>

            </div>
          </div>
          
          <div>
            <div className="flex justify-end mt-6">
              <div className="flex justify-between items-center mt-4 w-1/2">
                <ActionItem
                  onClick={handleRunPromptProtect}
                  icon={<PromptProtectPrimaryIcon />}
                  text={'Run Prompt Protect'}
                />
                <div className="text-sm text-gray-500  text-purple-lite">{promptProtectText.length} characters</div>
              </div>
              <div className="flex flex-wrap justify-end space-x-2 mt-6 w-1/2">
                <button className="bg-gray-200 text-gray-800 rounded-md px-4 py-2 text-sm mt-2" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="bg-blue-600 text-white rounded-md px-4 py-2 text-sm mt-2 bg-gradient-to-b from-purple-500 to-blue-500"
                  onClick={handleSubmit}
                >
                  Use Edited
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    
  );
};
