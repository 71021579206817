import React, { FC, ReactNode, useRef, MouseEvent } from 'react';
import { getAnimationClassName } from './helpers/get-modal-animation-classname';
import classNames from 'classnames';

interface PropTypes {
  children: ReactNode;
  isShown: boolean;
  onBackdropClick?: () => void;
  onAnimationEnd: () => void;
  className?: string;
  animation: 'none' | 'fade';
  withBackdrop?: boolean;
  centered?: boolean;
}

const Component: FC<PropTypes> = ({
  children,
  isShown,
  className,
  onBackdropClick,
  onAnimationEnd,
  animation,
  withBackdrop,
  centered,
}) => {
  const refWrapper = useRef<HTMLDivElement>(null);

  const modalClassNames = classNames({
    'fixed inset-0 bg-blueGray-800 bg-opacity-15 backdrop-filter backdrop-blur-sm': withBackdrop,
    'flex justify-center items-center': centered,
  });

  const handleWrapperClick = (event: MouseEvent) => {
    if (!refWrapper.current || !refWrapper.current.isEqualNode(event.target as Node)) return;
    onBackdropClick && onBackdropClick();
  };

  return (
    <div className="">
      <div
        ref={refWrapper}
        onClick={handleWrapperClick}
        className={`z-50 ${modalClassNames} ${className || ''}  w-full h-full bg-white animate__animated  ${isShown ? 'animate__fadeIn' : 'animate__fadeOut'} `}
      >
        <div className={getAnimationClassName(animation)(isShown)} onAnimationEnd={onAnimationEnd}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Component;
