import { createSlice } from '@reduxjs/toolkit';
import { Library,  LibraryMap, LibraryMapLite } from '../../types/models';
import { PATTERN_MANAGEMENT_STORE_NAME, patternManagementActions } from '../actions/pattern-management.actions';
import { PatternTypes } from '../../common/constants/enums/engine-types.enum';

export interface PatternManagementState {
  systemPatterns:LibraryMapLite[];
  userPatterns:Library[];
  userLibraries:LibraryMapLite[];
  userActiveLibraries:LibraryMap[];
  isFetching:boolean;
  currentLibrary?:Library;
  isUploading:boolean;
  isCreating?:boolean;
  error?:any;
}


export const patternManagementState: PatternManagementState = {
  systemPatterns:[],
  userPatterns:[],
  userLibraries:[],
  userActiveLibraries:[],
  error:undefined,
  currentLibrary:undefined,
  isUploading:false,
  isFetching:false
};

export const patternManagementSlice = createSlice({
  name: PATTERN_MANAGEMENT_STORE_NAME,
  initialState: patternManagementState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(patternManagementActions.setSystemPatterns, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(patternManagementActions.setSystemPatternsSuccess, (state, { payload }) => {
        state.systemPatterns = payload ?? [];
        state.isFetching = false;
      })
      .addCase(patternManagementActions.setSystemPatternsFail, (state, { payload }) => {
        state.isFetching = false;
        state.error = payload;
      })
      .addCase(patternManagementActions.setUserActivePatternsSuccess, (state, { payload }) => {
        state.userActiveLibraries = payload ?? [];
       

        state.isFetching = false;
        state.error = null;
      })
      .addCase(patternManagementActions.setUserPatterns, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(patternManagementActions.setUserPatternsSuccess, (state, { payload }) => {
        state.userLibraries = payload ?? [];
        state.isFetching = false;
      })
      .addCase(patternManagementActions.setUserPatternsFail, (state, { payload }) => {
        state.isFetching = false;
        state.error = payload;
      })
      .addCase(patternManagementActions.getCustomPatternById, (state, { payload }) => {
        state.currentLibrary = undefined;
        state.error = null;
      })
      .addCase(patternManagementActions.getCustomPatternByIdSuccess, (state, { payload }) => {
        state.currentLibrary = payload;
        state.error = null;
      })
      .addCase(patternManagementActions.getCustomPatternByIdFail, (state, { payload }) => {
        state.currentLibrary = undefined;
        state.error = null;
      })
      .addCase(patternManagementActions.setIsFetching, (state, { payload }) => {
        state.isFetching = payload;
      })
      .addCase(patternManagementActions.setIsFetchingSuccess, (state) => {
        state.isFetching = false;
      })
      .addCase(patternManagementActions.setIsFetchingFail, (state, { payload }) => {
        state.isFetching = false;
        state.error = payload;
      }).addCase(patternManagementActions.createLibrary, (state) => {
        state.isCreating = true;
        state.error = undefined;
      })
      .addCase(patternManagementActions.createLibrarySuccess, (state, { payload }) => {
        state.userPatterns = [...state.userPatterns, payload];
        state.isCreating = false;
      })
      .addCase(patternManagementActions.createLibraryFail, (state, { payload }) => {
        state.isCreating = false;
        state.error = payload;
      })
      .addCase(patternManagementActions.removeLibrary, (state, { payload }) => {
        state.userPatterns = state.userPatterns.filter((pattern) => pattern.id !== payload);
      })
      .addCase(patternManagementActions.updateLibrary, (state, { payload }) => {
       
      })
      .addCase(patternManagementActions.uploadCustomJson, (state) => {
        state.isUploading = true;
        state.error = undefined;
      })
      .addCase(patternManagementActions.setPatternSelectionSuccess, (state, {payload}) => {
        debugger;
        if (payload.patternType === PatternTypes.Custom) {
          let index = state.userLibraries.findIndex((pattern) => pattern.id === payload.libraryId);
          if (index !== -1) {
            state.userLibraries[index].isSelected = payload.isEnabled;
          }
        } else if (payload.patternType === PatternTypes.System) {
          let index = state.systemPatterns.findIndex((pattern) => pattern.id === payload.libraryId);
          if (index !== -1) {
            state.systemPatterns[index].isSelected = payload.isEnabled;
          }
        }
        
        state.error = undefined;
      })
      .addCase(patternManagementActions.uploadCustomJsonSuccess, (state) => {
        state.isUploading = false;
      })
      .addCase(patternManagementActions.uploadCustomJsonFail, (state, { payload }) => {
        state.isUploading = false;
        state.error = payload;
      });;
  },
});

export const patternManagementReducer = patternManagementSlice.reducer;
