import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClientApiOnboardingForm from '../../components/ClientAppOnboardingForm/ClientApiOnboardingForm';
import { clientAppActions } from '../../redux/actions/client-app.actions';
import { ClientAppDto, ClientAppUpdateDto } from '../../common/services/client-app/models/client-app';
import { isFetchingClientApp } from '../../redux/selectors/client-app.selectors';
import SampleCode from '../../components/SampleConsumerCode/SampleCode';
import { externalSearchActions } from '../../redux/actions/external-search.action';

const OnboardClientApi = () => {
  const dispatch = useDispatch();
  const isFetchingClient: boolean = useSelector(isFetchingClientApp);

  useEffect(() => {
    dispatch(clientAppActions.getClientAppByUserId());
  }, []);

  useEffect(() => {
    dispatch(externalSearchActions.getExternalSearchSourceLightList(1));
  }, []);

  const handleSubmit = (clientAppResponseDto: any) => {
    if (clientAppResponseDto && clientAppResponseDto.id && isFetchingClient) {
      let clientAppUpdate = {
        id: clientAppResponseDto.id,
        name: clientAppResponseDto.name,
        alias: clientAppResponseDto.alias,
        clientDomain: clientAppResponseDto.clientDomain,
        requestPerDay: clientAppResponseDto.requestPerDay,
        expiresAt: clientAppResponseDto.expiresAt,
        // callBackEndpoint: clientAppResponseDto.callbackEndpoint,
        externalSourceApiId: clientAppResponseDto.externalSourceApiId,
        clientToHostUpdateApiKey: clientAppResponseDto.clientToHostUpdateApiKey,
      } as ClientAppUpdateDto;
      dispatch(clientAppActions.updateClientApp(clientAppUpdate));
    } else if (clientAppResponseDto) {
      let clientAppCreate = {
        name: clientAppResponseDto.name,
        alias: clientAppResponseDto.alias,
        clientDomain: clientAppResponseDto.clientDomain,
        requestPerDay: clientAppResponseDto.requestPerDay,
        expiresAt: clientAppResponseDto.expiresAt,
        callbackEndpoint: clientAppResponseDto.callbackEndpoint,
        externalSourceApiId: clientAppResponseDto.externalSourceApiId,
      } as ClientAppDto;
      dispatch(clientAppActions.postClientApp(clientAppCreate));
      dispatch(clientAppActions.getClientAppByUserId());
    }
    dispatch(clientAppActions.getClientAppByUserId());
    //navigate(RoutePath.clientApiOnboarding);

    // navigate(RoutePath.clientApiOnboarding + '/' + id?.toString());
  };
  return (
    <div className="flex flex-wrap justify-center p-5 m-5">
      <div className="w-full md:w-2/5 px-4 border-r card">
        <ClientApiOnboardingForm onSubmit={(clientApp) => handleSubmit(clientApp)} />
      </div>
      <div className="w-full md:w-3/5 px-4">
        <div className="hidden md:block">
          <SampleCode />
        </div>
      </div>
    </div>
  );
};

export default OnboardClientApi;
