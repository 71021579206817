import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { Userpreferencesservice } from '../../common/services/user-preferences/user-preference-service';
import { UserPreferenceRequest } from '../../common/services/user-preferences/models/user-preference-request';
import { userPreferencesActions } from '../actions/user-preference.actions';
import { setNotification } from '../actions/notification.actions';
import {
  DashboardMatericsRequestByDateRange,
  ModuleMetrics,
} from '../../common/services/dashboard/models/dashboard.model';
import { DashboardService } from '../../common/services/dashboard/dashboard.service';
import { dashboardActions } from '../actions/dashboard.actions';
import { federatedActions } from '../actions/federated.actions';
import {
  AssertionResult,
  LLMFactCheckerRequest,
  LLMRequest,
  LLMResultLite,
  ProcessPromoteRequest,
  PromptProtect,
  PromptProtectLite,
  ResultListLite,
  UserHistoryList,
  PromptOptimzerRequest,
  PromptOptimizerLiteRequest,
  PromptOptimizerLLMsRequest,
} from '../../types/models';
import { PromptProtectService } from '../../common/services/prompt/prompt.service';
import { PromptActions } from '../actions/prompt.actions';
import { LLMService } from '../../common/services/llm/llm.service';
import {
  LLMResponse,
  LLMResponseWithoutHistory,
  PromptOptimzerResponse,
} from '../../common/services/llm/model/llm-response.model';
import { mapApiResponseToResult, mapLLMPromptProtectLiteToUserHistoryList } from '../../common/helpers/Helper';
import { ArticleProcessRequest } from '../../common/services/article/models/ArticleProcessRequest';
import { ArticleService } from '../../common/services/article/article.service';
import { numberofRecords } from '../selectors/user-preference.selectors';
import { profileActions } from '../actions/profile.actions';
import { SourceType } from '../../common/constants/enums/engine-types.enum';

export function* processProtectPrompt({ payload }: PayloadAction<string>) {
  try {
    let data = {
      check_for_profanity: true,
      prompt: payload,
    } as ProcessPromoteRequest;
    const processPromptProtect: PromptProtect = yield call(PromptProtectService.processPrompt, data);
    yield put(federatedActions.processPromptProtectSuccess(processPromptProtect));
  } catch (err: any) {
    yield put(federatedActions.processPromptProtectFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* getPrompts({ payload }: PayloadAction<number>) {
  try {
    var userPrompts: LLMResultLite = yield call(LLMService.getPromptsList, payload, 10);
    const mappedData: ResultListLite<UserHistoryList> = {
      ...userPrompts,
      data: userPrompts.data.map(mapLLMPromptProtectLiteToUserHistoryList),
    };
    yield put(federatedActions.getUserPromptsListSuccess(mappedData));
  } catch (err: any) {
    yield put(federatedActions.getUserPromptsListFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* processArticle({ payload }: PayloadAction<LLMFactCheckerRequest>) {
  try {
    const selectedNumberOfRecords: number = yield select(numberofRecords);
    const result: ArticleProcessRequest = new ArticleProcessRequest(
      payload.prompt,
      selectedNumberOfRecords,
      false,
      payload.sourceType,
    );
    const processedArticle: AssertionResult = yield call(ArticleService.processArticle, result);
    yield put(federatedActions.processArticleSuccess(mapApiResponseToResult(processedArticle)));
  } catch (err: any) {
    yield put(federatedActions.processArticleFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
    yield put(profileActions.getUserLimit());
  }
}

export function* processLLMPrompt({ payload }: PayloadAction<LLMRequest>) {
  try {
    const llmResponse: LLMResponse = yield call(LLMService.processPrompt, payload);
    yield put(federatedActions.processPromptSuccess(llmResponse));
  } catch (err: any) {
    yield put(federatedActions.processPromptFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* deletePrompt({ payload }: PayloadAction<string>) {
  try {
    yield call(LLMService.deletePrompt, payload);
    yield put(federatedActions.deletePromptSuccess(payload));
    yield put(setNotification({ title: 'prompt deleted successfully', type: 'success' }));
  } catch (err: any) {
    yield put(federatedActions.deletePromptFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to delete prompt', type: 'error' }));
  }
}

export function* clearLLMHistory() {
  try {
    const result: boolean = yield call(LLMService.clearHistory);
    if (result) {
      yield put(federatedActions.clearFederatedHistorySuccess());
      yield put(setNotification({ title: 'AI Gateway History Cleared', type: 'success' }));
    } else {
      yield put(federatedActions.clearFederatedPersonHistoryFailure());
      yield put(setNotification({ title: 'Unable to clear AI Gateway history', type: 'error' }));
    }
  } catch (err: any) {
    yield put(federatedActions.clearFederatedPersonHistoryFailure());
  }
}

export function* getPromptById({ payload }: PayloadAction<string>) {
  try {
    const llmResponse: LLMResponse = yield call(LLMService.getPromptById, payload);
    if (llmResponse) {
      yield put(federatedActions.getPromptByIdSuccess(llmResponse));
    } else {
      yield put(federatedActions.getPromptByIdFailure({ message: 'Prompt Not Found', statusCode: 404 }));
    }
  } catch (err: any) {
    yield put(federatedActions.getPromptByIdFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* processPromptOptimizer({ payload }: PayloadAction<PromptOptimzerRequest>) {
  try {
    let data = {
      prompt: payload.user_initial_prompt,
      sourceType: SourceType.GPT4,
    };
    let llmResponse: LLMResponseWithoutHistory;
    try {
      llmResponse = yield call(LLMService.processPromptWithoutHistory, data);
      yield put(federatedActions.promptOptimizerLLMSuccess(llmResponse));
    } catch (err: any) {
      yield put(federatedActions.processPromptOptimiserFailure({ message: err.message, statusCode: err.statusCode }));
      return;
    }
    let PromptOptimizerData = {
      ...payload,
      user_initial_prompt_response: llmResponse.output,
    };
    const PromptOptimizerResponse: PromptOptimzerResponse = yield call(LLMService.promptOptimizer, PromptOptimizerData);
    yield put(federatedActions.processPromptOptimiserSuccess(PromptOptimizerResponse));
  } catch (err: any) {
    yield put(federatedActions.processPromptOptimiserFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* processPromptOptimizerLite({ payload }: PayloadAction<PromptOptimizerLiteRequest>) {
  try {
    const PromptOptimizerResponse: PromptOptimzerResponse = yield call(LLMService.promptOptimizerLite, payload);
    yield put(federatedActions.processPromptOptimizerLiteSuccess(PromptOptimizerResponse));
  } catch (err: any) {
    yield put(federatedActions.processPromptOptimizerLiteFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* PromptOptimizerLLMs({ payload }: PayloadAction<PromptOptimizerLLMsRequest>) {
  let data1 = {
    prompt: payload.initial_prompt,
    sourceType: SourceType.GPT4,
  };
  let data2 = {
    prompt: payload.optimized_prompt,
    sourceType: SourceType.GPT4,
  };
  try {
    const [optimisedPromptLLMResponse, llmResponse]: [LLMResponseWithoutHistory, LLMResponseWithoutHistory] = yield all(
      [call(LLMService.processPromptWithoutHistory, data1), call(LLMService.processPromptWithoutHistory, data2)],
    );
    // Handle responses
    yield put(federatedActions.processPromptIptimizerLLMInitialPromptSuccess(optimisedPromptLLMResponse));
    yield put(federatedActions.processPromptIptimizerLLMOptimizedPromptSuccess(llmResponse));
  } catch (err: any) {
    yield put(
      federatedActions.processPromptOptimizerLLMInitialPromptFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
    yield put(
      federatedActions.processPromptOptimizerLLMOptimizedPromptFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
  }
}

export function* setPromptFavorite({ payload }: PayloadAction<any>) {
  try {
    const updatePromptResult: LLMResponse = yield call(LLMService.updatePrompt, payload);
    yield put(federatedActions.setPromptFavoriteSuccess(updatePromptResult));
    let message = payload.isFavorite ? 'Prompt marked as favorite' : 'Prompt removed from favorite';

    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(federatedActions.setPromptFavoriteFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

function* federatedMetricsWatcher() {
  yield takeLatest(federatedActions.processPromptProtect.type, processProtectPrompt);
  yield takeLatest(federatedActions.processPrompt.type, processLLMPrompt);
  yield takeLatest(federatedActions.getUserPromptsList.type, getPrompts);
  yield takeLatest(federatedActions.processArticle.type, processArticle);
  yield takeEvery(federatedActions.getPromptById.type, getPromptById);
  yield takeLatest(federatedActions.setPromptFavorite.type, setPromptFavorite);
  yield takeLatest(federatedActions.clearFederatedHistory.type, clearLLMHistory);
  yield takeLatest(federatedActions.deletePrompt.type, deletePrompt);
  yield takeLatest(federatedActions.processPromptOptimiser.type, processPromptOptimizer);
  yield takeLatest(federatedActions.processPromptOptimizerLite.type, processPromptOptimizerLite);
  yield takeLatest(federatedActions.processPromptOptimizerLLM.type, PromptOptimizerLLMs);
}

export default function* rootSaga() {
  yield all([federatedMetricsWatcher()]);
}
