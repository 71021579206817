import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';
import { UserPreferenceRequest } from '../../common/services/user-preferences/models/user-preference-request';

export const USER_PREFERENCES_STORE_NAME = 'userPreferences';

const createPayloadAction = getCreatePayloadActionFromStore(USER_PREFERENCES_STORE_NAME);
const createAction = getCreateActionFromStore(USER_PREFERENCES_STORE_NAME);
const getUserPreferences = createAction('get user preferences');
const getUserPreferencesSuccess = createPayloadAction<UserPreferenceRequest>('get user preferences success');
const getUserPreferencesFailure = createPayloadAction('get user preferences failure');
const updateUserPreferences = createPayloadAction<UserPreferenceRequest>('update user preferences');
const updateUserPreferencesSuccess = createPayloadAction<UserPreferenceRequest>('update user preferences success');
const updateUserPreferencesFailure = createPayloadAction('update user preferences failure');

export const userPreferencesActions = {
  getUserPreferences,
  getUserPreferencesSuccess,
  getUserPreferencesFailure,
  updateUserPreferences,
  updateUserPreferencesSuccess,
  updateUserPreferencesFailure,
};
