import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { AssertionsContainer } from '../../components/AssertionsContainer/AssertionsContainer';
import { useDispatch, useSelector } from 'react-redux';
import { articleErrorCodes, currentArticle } from '../../redux/selectors/article.selectors';
import { isGuestUser, isLoggedInUser } from '../../redux/selectors/activeUser.selectors';
import MainPage from '../../components/MainPage/MainPage';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import { FeaturesList } from '../../components/FeaturesList/FeaturesList';
import { UnlockFeaturesBanner } from '../../components/UnlockFeaturesBanner/UnlockFeaturesBanner';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { articleActions } from '../../redux/actions/article.actions';
import UserAssertionsList from '../../layouts/Sidenav/UserAssertionsList';
import { showUserArticles } from '../../redux/selectors/layout.selectors';
import { SideBarIcon } from '../../common/icons/Icons';
import { layoutActions } from '../../redux/actions/layout.actions';
import { RoutePath } from '../../common/constants/route-path';
import { setNotification } from '../../redux/actions/notification.actions';
import { ArticleResultView } from '../../components/ResultView/ResultView';
import { PromptProtectResultView } from '../../components/PromptProtectResultView/PromptProtectResultView';
import { mockPrompt } from '../../common/services/prompt/prompt.service';
import { currentDossier } from '../../redux/selectors/dossier.selectors';
import { currentPromptSelector } from '../../redux/selectors/prompt.selectors';
import { PromptActions } from '../../redux/actions/prompt.actions';

export const PromptProtectDetailedView = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInUser);
  const assertion = useSelector(currentArticle);
  const error = useSelector(articleErrorCodes);
  const toggleUserAticles = useSelector(showUserArticles);
  const isGuestAccount = useSelector(isGuestUser);
  const { id } = useParams<{ id: string }>();
  const data = useSelector(currentPromptSelector);
  useEffect(() => {
    if (error && error.length) {
      navigate(RoutePath.Landing);
    }
  }, [error]);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      dispatch(PromptActions.getPromptById(id));
    } else {
      dispatch(setNotification({ title: 'Prompt Id Missing ', type: 'error' }));
      navigate(RoutePath.Landing);
    }
  }, [id]);
  return data ? (
    <>
      <div>
        <div className="flex justify-center items-center animate__animated animate__fadeInUp">
          <div className="w-4/5 mt-2">
            <PromptProtectResultView data={data} />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="text-center w-full mt-2 text-sm">Fetching articles...</div>
  );
};
