import { useDispatch } from 'react-redux';
import { VALIDATE_EMAIL, DID_NOT_RECEIVE_EMAIL, RESEND, BACK_TO_EMAIL } from '../../../common/constants/app-text';
import { CenterLabel } from '../../CenterLabel/CenterLabel';
import OtpInput from '../../CodeInput/CodeInput';
import { Divider } from '../../Divider/Divider';
import { LogoDetailed } from '../../LogoDetailed/LogoDetailed';
import { MainButton } from '../../MainButton';
import { MessageWithAction } from '../../MessageWithAction/MessageWithAction';
import { profileActions } from '../../../redux/actions/profile.actions';
import { useState } from 'react';
import OTPStepContent from '../../OTPStepContent/OTPStepContent';

interface Props {
  onBackNavigation: any;
  handleChange?: any;
  email?: string;
}

export const SignUpOTPStep: React.FC<Props> = ({ email = '' }) => {
  var dispatch = useDispatch();
  const [animationClass, setAnimationClass] = useState('');
  const onBackNavigation = () => {
    setAnimationClass('animate__animated animate__flipOutX');
    setTimeout(() => {
      dispatch(profileActions.showOTPVerificationCode(false));
    }, 500);
  };
  return (
    <div className={`flex flex-col items-center justify-center mt-5 ${animationClass}`}>
      <div className="block group mx-5 my-5">
        <LogoDetailed />
      </div>
      <OTPStepContent></OTPStepContent>
      <Divider />
      <CenterLabel onClick={onBackNavigation} text={BACK_TO_EMAIL}></CenterLabel>
    </div>
  );
};
