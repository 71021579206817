import { createSlice } from '@reduxjs/toolkit';
import { AssertionResult, FavoriteArticles, PaginationConfig } from '../../types/models';
import { DefaultAssertionsData } from '../../common/constants/defaultArticle';
import { mapApiResponseToResult, mapArticlesToFavorite } from '../../common/helpers/Helper';
import { ARTICLE_STORE_NAME, articleActions } from '../actions/article.actions';
import { profileActions } from '../actions/profile.actions';

export interface ArticleState {
  currentArticle?: AssertionResult | any;
  defaultArticle: AssertionResult;
  userInput: string;
  isProcessingArticle: boolean;
  enableArticleResults: boolean;
  processArticleEnabled: boolean;
  errorCode: string;
  isFetchingArticles: boolean;
  isFavoriteEnabled: boolean;
  articlesList: AssertionResult[];
  favoriteArticlesCount: number;
  articlePagination: PaginationConfig;
  favoriteArticles: FavoriteArticles[];
  isDeleting: boolean;
}

const initialState: ArticleState = {
  userInput: '',
  currentArticle: null,
  isProcessingArticle: false,
  isFetchingArticles: false,
  enableArticleResults: false,
  processArticleEnabled: false,
  isFavoriteEnabled: false,
  errorCode: '',
  defaultArticle: mapApiResponseToResult(DefaultAssertionsData),
  articlesList: [],
  articlePagination: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  favoriteArticles: [],
  favoriteArticlesCount: 0,
  isDeleting: false,
};
export const articleSlice = createSlice({
  name: ARTICLE_STORE_NAME,
  initialState,
  reducers: {
    processArticle: (state, action) => {
      state.isProcessingArticle = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(articleActions.processArticle, (state, { payload }) => {
        state.isProcessingArticle = true;
        state.userInput = payload;
      })
      .addCase(articleActions.setPromptContent, (state, { payload }) => {
        state.isProcessingArticle = true;
        state.userInput = payload;
      })
      .addCase(articleActions.getDefaultArticleSuccess, (state) => {
        state.defaultArticle = mapApiResponseToResult(DefaultAssertionsData);
      })
      .addCase(articleActions.processArticleSuccess, (state, { payload }) => {
        state.currentArticle = mapApiResponseToResult(payload);
        state.isProcessingArticle = false;
        let index = state.articlesList.findIndex((e) => e.id === payload.id);
        if (index < 0) {
          state.articlesList.unshift(payload);
          var list = state.articlesList;
          state.favoriteArticles = mapArticlesToFavorite(list);
        }
        state.errorCode = '';
      })
      .addCase(articleActions.toggleFavoriteArticles, (state, { payload }) => {
        state.isFavoriteEnabled = payload;
        var list = state.articlesList;
        if (state.isFavoriteEnabled) {
          list = state.articlesList.filter((article) => article.isFavorite === true);
        }
        state.favoriteArticles = mapArticlesToFavorite(list);
        state.favoriteArticlesCount = state.articlesList.filter((article) => article.isFavorite).length;
      })
      .addCase(articleActions.getUserArticlesListSuccess, (state, { payload }) => {
        state.articlesList = state.articlesList.concat(payload.data ?? []);
        var list = state.articlesList;
        if (state.isFavoriteEnabled) {
          list = state.articlesList.filter((article) => article.isFavorite === true);
        }
        state.favoriteArticles = mapArticlesToFavorite(list);
        state.favoriteArticlesCount = state.isFavoriteEnabled
          ? state.favoriteArticles.length
          : state.articlesList.filter((article) => article.isFavorite === true).length;
        state.articlePagination = payload.meta;
        console.log(state.articlePagination);
        state.isFetchingArticles = false;
      })
      .addCase(articleActions.getUserArticlesList, (state, { payload }) => {
        state.isFetchingArticles = true;
      })
      .addCase(articleActions.setArticleFavoriteSuccess, (state, { payload }) => {
        state.currentArticle = payload;
        let index = state.articlesList.findIndex((e) => e.id === payload.id);
        state.articlesList[index].isFavorite = payload.isFavorite;
        var list = state.articlesList;
        if (state.isFavoriteEnabled) {
          list = state.articlesList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoriteArticles = mapArticlesToFavorite(list);
        state.favoriteArticlesCount = state.isFavoriteEnabled
          ? state.favoriteArticles.length
          : state.articlesList.filter((article, index, self) => article.isFavorite === true).length;
      })
      .addCase(articleActions.getUserArticlesListFailure, (state, { payload }) => {
        state.isFetchingArticles = false;
      })
      .addCase(articleActions.processArticleFailure, (state, { payload }) => {
        state.isProcessingArticle = false;
        state.errorCode = payload.message;
      })
      .addCase(articleActions.getArticleByIdSuccess, (state, { payload }) => {
        state.currentArticle = mapApiResponseToResult(payload);
        state.errorCode = '';
      })
      .addCase(articleActions.getArticleByIdFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(articleActions.processArticleEnabled, (state, { payload }) => {
        state.processArticleEnabled = payload;
      })
      .addCase(articleActions.deleteArticle, (state, { payload }) => {
        state.isDeleting = true;
        state.userInput = payload;
      })
      .addCase(articleActions.clearArticleHistorySuccess, (state) => {
        state.articlesList = [];
        state.favoriteArticles = [];
        state.favoriteArticlesCount = 0;
        state.articlePagination = {
          page: 1,
          take: 10,
          itemCount: 0,
          hasPreviousPage: false,
          hasNextPage: false,
        };
      })
      .addCase(articleActions.deleteArticleSuccess, (state, { payload }) => {
        state.isDeleting = false;
        if (state.currentArticle?.id == payload) {
          state.currentArticle = null;
        }
        state.articlesList = state.articlesList.filter((e) => e.id != payload);
        state.favoriteArticles = mapArticlesToFavorite(
          state.articlesList.filter((article, index, self) => article.isFavorite === state.isFavoriteEnabled),
        );
        state.favoriteArticlesCount = state.articlesList.filter(
          (article, index, self) => article.isFavorite === true,
        ).length;
      })
      .addCase(articleActions.deleteArticleFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(profileActions.logout, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { processArticle } = articleSlice.actions;
export const articleReducer = articleSlice.reducer;
