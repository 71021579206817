import PropTypes from 'prop-types';
import React from 'react';
import { useReducer } from 'react';
import './style.css';
import Typography from '../Typography/Ty[pgraphy';

interface Props {
  stateProp: 'disabled' | 'rest' | 'hover';
  className: any;
  text?: string;
  onClick?: any;
}

export const SecondaryButton = ({ stateProp, className, text, onClick }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || 'rest',
  });

  return (
    <button
      onClick={onClick}
      className={`secondary-button cursor-pointer state-${state.state} ${className}`}
      onMouseLeave={() => {
        dispatch('mouse_leave');
      }}
      onMouseEnter={() => {
        dispatch('mouse_enter');
      }}
    >
      <Typography className="text-primary">{text ?? 'Sign In'}</Typography>
    </button>
  );
};

function reducer(state: any, action: any) {
  switch (action) {
    case 'mouse_enter':
      return {
        ...state,
        state: 'hover',
      };

    case 'mouse_leave':
      return {
        ...state,
        state: 'rest',
      };
  }

  return state;
}

SecondaryButton.propTypes = {
  stateProp: PropTypes.oneOf(['disabled', 'rest', 'hover']),
};
