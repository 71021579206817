import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { mapApiResponseToResult, mapDossierOrganizationToDossierLite, wait } from '../../common/helpers/Helper';
import { setNotification } from '../actions/notification.actions';
import { RoutePath } from '../../common/constants/route-path';
import { useNavigate } from 'react-router-dom';
import { browserHistory } from '../helpers/history/history';
import {
  DossierLite,
  DossierOrganizaiton,
  DossierOrganizaitonLite,
  DossierUpdateRequest,
  ProcessDossierRequest,
  ResultListLite,
} from '../../types/models';
import { DossierOrgActions } from '../actions/dossier-org.actions';
import { DossierOrganizationService } from '../../common/services/dossier/organization/dossier-org.service';
import { profileActions } from '../actions/profile.actions';

export function* getDossierOrgs({ payload }: PayloadAction<number>) {
  try {
    var userDossierOrgs: ResultListLite<DossierOrganizaitonLite> = yield call(
      DossierOrganizationService.getDossierList,
      payload,
      10,
    );
    const mappedData: ResultListLite<DossierLite> = {
      ...userDossierOrgs,
      data: userDossierOrgs.data.map(mapDossierOrganizationToDossierLite),
    };
    yield put(DossierOrgActions.getUserDossierOrgsListSuccess(mappedData));
  } catch (err: any) {
    yield put(DossierOrgActions.getUserDossierOrgsListFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* getDefaultDossierOrg() {
  try {
    const defaultDossierOrg: DossierOrganizaiton = yield call(DossierOrganizationService.getDefaultDossier);
    yield put(DossierOrgActions.getDefaultDossierOrgSuccess(defaultDossierOrg));
  } catch (err: any) {
    yield put(DossierOrgActions.getDefaultDossierOrgFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* processDossierOrgs({ payload }: PayloadAction<ProcessDossierRequest>) {
  try {
    payload.live_data = payload.live_data || false;
    const processedDossierOrg: DossierOrganizaiton = yield call(DossierOrganizationService.processDossier, payload);
    yield put(DossierOrgActions.processDossierOrgSuccess(processedDossierOrg));
  } catch (err: any) {
    yield put(setNotification({ title: err.error, type: 'error' }));
    yield put(DossierOrgActions.processDossierOrgFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
    yield put(profileActions.getUserLimit());
    yield put(DossierOrgActions.processDossierOrgEnabled(false));
  }
}

export function* setDossierOrgFavorite({ payload }: PayloadAction<DossierUpdateRequest>) {
  try {
    const updateDossierOrgResult: DossierOrganizaiton = yield call(DossierOrganizationService.updateDossier, payload);
    yield put(DossierOrgActions.setDossierOrgFavoriteSuccess(updateDossierOrgResult));
    let message = payload.isFavorite ? 'Dossier marked as favorite' : 'Dossier removed from favorite';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(DossierOrgActions.setDossierOrgFavoriteFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* deleteDossierOrg({ payload }: PayloadAction<string>) {
  try {
    yield call(DossierOrganizationService.deleteDossier, payload);
    yield put(DossierOrgActions.deleteDossierOrgSuccess(payload));
    yield put(setNotification({ title: 'Dossier deleted successfully', type: 'success' }));
  } catch (err: any) {
    yield put(DossierOrgActions.deleteDossierOrgFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to delete Dossier', type: 'error' }));
  }
}

export function* clearDossierOrgHistory(){
  try {
    const result: boolean = yield call(DossierOrganizationService.clearHistory);
    if(result){
      yield put(DossierOrgActions.clearDossierOrgHistorySuccess());
      yield put(setNotification({ title: 'Code Analysis History Cleared', type: 'success' }));
    }else{
      yield put(DossierOrgActions.clearDossierOrgPersonHistoryFailure());
      yield put(setNotification({ title: 'Unable to clear code analysis history', type: 'error' }));
    }
  } catch (err: any) {
    yield put(DossierOrgActions.clearDossierOrgPersonHistoryFailure());
  }
}


export function* getDossierOrgsById({ payload }: PayloadAction<string>) {
  try {
    const DossierOrgDetails: DossierOrganizaiton = yield call(DossierOrganizationService.getDossierById, payload);
    if (DossierOrgDetails) {
      yield put(DossierOrgActions.getDossierOrgByIdSuccess(DossierOrgDetails));
    } else {
      yield put(DossierOrgActions.getDossierOrgByIdFailure({ message: 'DossierOrg Not Found', statusCode: 404 }));
    }
  } catch (err: any) {
    yield put(DossierOrgActions.getDossierOrgByIdFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to fetch DossierOrg details', type: 'error' }));
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export const PROCESS_DossierOrg_SAGA = 'Process DossierOrg Saga';

export const processDossierOrgSaga = createAction<string>(PROCESS_DossierOrg_SAGA);

export default function* watcher() {
  yield takeLatest(DossierOrgActions.processDossierOrg.type, processDossierOrgs);
  yield takeEvery(DossierOrgActions.getUserDossierOrgsList.type, getDossierOrgs);
  yield takeEvery(DossierOrgActions.getDefaultDossierOrg.type, getDefaultDossierOrg);
  yield takeEvery(DossierOrgActions.getDossierOrgById.type, getDossierOrgsById);
  yield takeLatest(DossierOrgActions.setDossierOrgFavorite.type, setDossierOrgFavorite);
  yield takeLatest(DossierOrgActions.clearDossierHistory.type, clearDossierOrgHistory);
  yield takeLatest(DossierOrgActions.deleteDossierOrg.type, deleteDossierOrg);
}
