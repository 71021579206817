import { AssessmentOptions } from '../common/constants/default-values';
import { ExpressionType, PatternTypes, SourceType } from '../common/constants/enums/engine-types.enum';
import { PersonalInformation } from '../common/services/dossier/person/dossierPerson';
import { LLMResponse } from '../common/services/llm/model/llm-response.model';

export interface AssertionResult {
  id: string;
  userId: string;
  article: string;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  percentage?: number;
  deletedAt: any;
  isFavorite?: boolean;
  time?: string;
  isCompleted?: boolean;
  tag?: string;
  progressPercentage: number;
  tags?: string[];
  assertions: Assertion[];
  extendProps?: any;
  sourceType?: SourceType;
}

export interface ContradictionResult {
  id: string;
  userId: string;
  article: string;
  updatedAt: string;
  isReqFromApi: boolean;
  createdBy: string;
  createdAt: string;
  percentage?: number;
  deletedAt: any;
  isFavorite?: boolean;
  time?: string;
  isCompleted?: boolean;
  tag?: string;
  progressPercentage: number;
  tags?: string[];
  assertions: string[];
  assertionsInfo: ContradictionInfoResult[];
  sourceType?: SourceType;
}

export interface ContradictionInfoResult {
  id: string;
  assertion: string;
  colorCode?: string;
  articleName: string;
  articleIndex?: number;
  riskAssessment?: string;
  assertPosition: number;
  negation: string;
  negation_type: string;
  topRanks: Result[];
}
export interface AssertionResultLite {
  data: AssertionResult[];
  meta: PaginationConfig;
}

export interface LLMResultLite {
  data: LLMResponse[];
  meta: PaginationConfig;
}

export interface ContradictionLite {
  data: ContradictionResult[];
  meta: PaginationConfig;
}

export interface ArticleUpdateRequest {
  isFavorite: boolean;
  progressPercentage: number;
  id: string;
}

export interface LLMFactCheckerRequest {
  prompt: string;
  sourceType: SourceType | undefined;
}

export interface PaginationConfig {
  page: number;
  take: number;
  itemCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface UpdateProfileForm {
  firstName: string;
  lastName: string;
}

export interface UpdateSubscriptionPlan {
  planName: string;
  planId: string;
}

export interface FavoriteArticles {
  date: string;
  collections: AssertionResult[];
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface GuestLoginForm {
  email: string;
  password?: string;
}

export interface Assertion {
  id: string;
  articleId: string;
  colorCode?: string;
  articleName: string;
  articleIndex?: number;
  riskAssessment?: string;
  assertPosition: number;
  topRanks: Result[];
}

export interface Result {
  id?: string;
  articleName: string;
  articleId?: string;
  source: string;
  excerpt: string;
  score: number;
}

export interface HttpErrorResponse {
  statusCode: number;
  message: string;
}
export interface AssertionResponse {
  id: string;
  userId: string;
  article: string;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  deletedAt: any;
  progressPercentage: number;
  tags?: string[];
  assertions: Result[];
}

export interface ProcessDossierRequest {
  subject_name: string;
  subject_class: string | 'organization' | 'person';
  live_data: boolean;
}

export interface ResultListLite<T> {
  data: T[];
  meta: PaginationConfig;
}

export interface ProcessPromoteRequest {
  prompt: string;
  check_for_profanity: boolean;
}

export interface DossierUpdateRequest {
  id: string;
  isFavorite: boolean;
  progressPercentage: number;
}

export enum DossierType {
  PERSON = 'person',
  ORGANIZATION = 'organization',
}
export interface DossierPersonLite {
  dossierId: string;
  personalInformationLightResponseDto: PersonalInformation;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  deletedAt: any;
  isFavorite: boolean;
  progressPercentage: number;
}

export interface DossierLite {
  id: string;
  name: string;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  tag: string;
  type: DossierType;
  deletedAt: any;
  isFavorite: boolean;
  progressPercentage: number;
}

export interface PaginationData {
  id: string;
  name: string;
  isFavorite: boolean;
  progressPercentage: number;
  time: string;
  percentage: string;
}

export interface ContactInformationResponseDto {
  Address: string;

  Phone: string;

  Email: string;
}

export interface OrganizationInformationResponseDto {
  LegalStatus: string;

  RegistrationDetails: string;

  Name: string;

  ContactInformation?: ContactInformationResponseDto;
}

export interface MissionAndVisionResponseDto {
  MissionStatement: string[];

  VisionStatement: string[];

  CoreValues: string[];
}

export interface TeamsAndRolesResponseDto {
  EditorialDepartment: string[];

  NewsDepartment: string[];

  OpinionDepartment: string[];

  BusinessDepartment: string[];

  TechnologyDepartment: string[];

  SportsDepartment: string[];
}

export interface OrganizationalStructureResponseDto {
  Departments: string[];
  TeamsAndRoles: any[];
  ReportingStructure: string[];
}

export interface KeyPersonnelResponseDto {
  Founders: string[];

  ExecutiveTeam: string[];

  BoardOfDirectors: string[];
}

export interface HistoryAndMilestonesResponseDto {
  FoundingDate: string;

  History: string;

  Achievements: string[];
}

export interface FinancialInformationResponseDto {
  RevenueSources: string[];

  FinancialStatements: string;

  FundingPartners: string[];
}

export interface ProgramsAndServicesResponseDto {
  Overview: string;

  Objectives: string;

  TargetAudience: string;

  Impact: string;
}

export interface PartnershipsAndCollaborationsResponseDto {
  Partnerships: string[];

  Collaborations: string[];
}

export interface LegalAndComplianceResponseDto {
  LegalStructure: string[];

  Compliance: string[];
}

export interface RecognitionAndAwardsResponseDto {
  AwardsAndHonors: string[];
}

export interface MediaCoverageResponseDto {
  MediaArticles: string[];

  PressReleases: string[];
}

export interface ClienteleAndBeneficiariesResponseDto {
  Clientele: string[];

  Beneficiaries: string[];
}

export interface PartnershipsAndStakeholdersResponseDto {
  GovernmentEntities: string[];

  CommunityGroups: string[];

  DonorsAndSupporters: string[];
}

export interface DossierOrganizaiton {
  dossierOrgId: string;

  organizationInformationDto: OrganizationInformationResponseDto;

  missionAndVisionDto: MissionAndVisionResponseDto;

  organizationalStructureDto: OrganizationalStructureResponseDto;

  keyPersonnelDto: KeyPersonnelResponseDto;

  historyAndMilestonesDto: HistoryAndMilestonesResponseDto;

  financialInformationDto: FinancialInformationResponseDto;

  programsAndServicesDto: ProgramsAndServicesResponseDto;

  partnershipsAndCollaborationsDto: PartnershipsAndCollaborationsResponseDto;

  legalAndComplianceDto: LegalAndComplianceResponseDto;

  mediaCoverageDto: MediaCoverageResponseDto;

  clienteleAndBeneficiariesDto: ClienteleAndBeneficiariesResponseDto;

  partnershipsAndStakeholdersDto: PartnershipsAndStakeholdersResponseDto;

  createdBy: string;

  createdAt: string;

  updatedAt: string;

  deletedAt: string;

  isFavorite: boolean;
  isReqFromApi: boolean;

  progressPercentage: number;
}

export interface DossierOrganizaitonLite {
  dossierOrgId: string;
  organizationInformationDto: OrganizationInformationResponseDto;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  deletedAt: any;
  isFavorite: boolean;
  progressPercentage: number;
}

export interface OrganizationHeaderModel {
  title: string;
  legalStatus: string;
  incorporationDate: string;
  history: string;
  id: string;
  isFavorite: boolean;
  CoreValues: string[];
}

export interface TitleWithCardsDescription {
  title: string;
  items: CardTitleDescription[];
}

export interface CardTitleDescription {
  title: string;
  description: string;
}

export interface TabWithList {
  name: string;
  key: string;
  items: string[];
}

export interface DossierFinancialInformation {
  title: string;
  description: string;
  revenueSources: string[];
  fundingPartners: string[];
}

export interface DossierOrganizationProgramAndServices {
  title: string;
  description: string;
  services: CardTitleDescription[];
}

export interface PromptProtect {
  id: string;
  prompt: string;
  is_profanity_para: boolean;
  isFavorite: boolean;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  deletedAt: any;
  progressPercentage: number;
  promptsInfoResponseDto: PromptsInfoResponseDto[];
}

export interface PromptsInfoResponseDto {
  profanityCheckResponseDto: ProfanityCheckResponseDto[];
  sentence: string;
}

export interface ProfanityCheckResponseDto {
  profanity: string;
  index: number;
  word: string;
}

export interface UserHistoryList {
  id: string;
  name: string;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  tag?: string;
  deletedAt: any;
  isFavorite: boolean;
  progressPercentage: number;
}

export interface PromptProtectLite {
  id: string;
  userId: string;
  prompt: string;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  deletedAt: any;
  isFavorite: boolean;
  progressPercentage: number;
}

export interface LLMRequestWithoutHistory {
  prompt: string;
  sourceType: SourceType;
}

export interface LLMRequest {
  prompt: string;
  sourceTypes: SourceType[];
}

export interface CodeRiskAssessmentResult extends Base {
  riskAssessmentOutputResponse: RiskAssessment[];
}

export interface RiskAssessment {
  check_type: AssessmentOptions;
  code_segment: string;
  result: IdentiedRisks | null;
}

export interface RiskAssessmentResultView {
  check_type: AssessmentOptions;
  risk_detected: boolean;
  risks: RiskAssesmentRecord[];
}

export interface RiskAssesmentRecord {
  code_segment: string;
  result: IdentiedRisks | null;
}

export interface IdentiedRisks {
  risk_detected: boolean;
  risk_location?: string;
  risk_reason?: string;
  risk_mitigation?: string;
  risk_start_character?: number | null;
}

export enum RiskAssessmentResultStatus {
  PASSED = 'PASSED',
  FAILED = 'FAILED',
}

export interface Location {
  line: string;
  code: string;
}

export interface BillOfLaddingResult extends Base {
  bill_of_ladding: BillOfLadding;
  version_command: {
    mac: string;
    win: string;
  };
}

export interface BillOfLadding {
  datatype: string;
  languages: string[];
  databases: any[];
  third_party_apis: any[];
  custom_apis: any[];
  libraries: string[];
}

export interface Base {
  id?: string;
  name?: string;
  isFavorite?: string;
  updatedAt?: string;
  createdBy?: string;
  createdAt?: string;
  deletedAt?: any;
  progressPercentage?: number;
}

export interface VersionCommand {
  windows: string;
  mac: string;
  linux: string;
}

export interface ReferenceFinderResult extends Base {
  result: ReferenceFinder;
}

export interface GithubResponse {
  code: string;
  repo: RepoResponseDto[];
  source: string;
  tier: number;
  url: string;
  user: UserResponseDto[];
}

export interface RepoResponseDto {
  about_description: string;
  about_url: string;
  contributors: string;
  forks: string;
  name: string;
  org: string;
  repo_url: string;
  source: string;
  stars: string;
  tier: number;
  url: string;
  used_by: string;
  watchers: string;
}

export interface UserResponseDto {
  activity: string[];
  bio: string;
  contributions: string;
  followers: string;
  following: string;
  loc: string;
  name: string;
  org: string;
  source: string;
  tier: number;
  url: string;
  user_url: string;
}

export interface ReferenceFinder {
  github: GithubResponse[];
  website: Website[];
  medium: Medium[];
  stackoverflow: StackOverFlow[];
}

export interface Medium {
  code: string;
  source: string;
  tier: number;
  followers: string;
  author_name: string;
  author_url: string;
  url: string;
}

export interface StackOverFlow {
  answers: string;
  badges: Badges;
  code: string;
  experience: string;
  name: string;
  questions: string;
  reached: string;
  replier_url: string;
  reputation: string;
  source: string;
  tier: number;
  url: string;
}

export interface Badges {
  bronze: number;
  gold: number;
  silver: number;
}

export interface Medium {}
export interface Website {
  code: string;
  source: string;
  tier: number;
  title: string;
  url: string;
}

export interface CodeAnalysisResult {
  code: string;
  id?: string;
  isFavorite?: boolean;
  riskAssessment?: RiskAssessment[];
  billOfLadding?: BillOfLaddingResult;
  codeReference?: ReferenceFinder;
}

export interface CodeAnalysisInput {
  code: string;
  riskAssessment: string[];
  checkForBillOfLadding: boolean;
  checkForReferenceFinder: boolean;
}
export interface CodeAnalysisEnteryRequest {
  code: string;
  codeReferenceId?: string;
  billOfLadding?: string;
  riskAssessmentId?: string;
}

export interface CodeAnalysisLightResponse {
  code: string;
  id: string;
  codeReferenceId: string;
  billOfLaddingId: string;
  riskAssessmentId: string;
  userId: string;
  createdBy: string;
  createdAt: Date;
  isFavorite: boolean;
  progressPercentage: number;
}

export interface SummarizationLite {
  id: string;
  userId: string;
  isFavorite: boolean;
  isReqFromApi: boolean;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  deletedAt: string | null;
  progressPercentage: number;
  summaries: SummariesDto;
  summaryRequest: {
    extractedText: string;
    referenceSummary: string;
    modeOfSummary: string[];
  };
}

export interface SummariesDto {
  [key: string]: SummaryDto;
}

export interface SummaryDto {
  summarized_text: string;
  evaluation_metrics: {
    readability_score: number;
    topic_coherence: number;
    compression_ratio: number;
    sentence_similarity: number;
  };
}

export interface SummaryComparisonResponse {
  evaluation_metrics: {
    rogue: {
      rouge1: number;
      rouge2: number;
      rougeL: number;
      rougeLsum: number;
    };
    meteor: number;
  };
}

export interface ProcessSummaryRequest {
  extracted_text: string;
  reference_summary: string;
  mode_of_summary: string[];
}

export interface SummaryComparisonSummaries {
  summary_1: string;
  summary_2: string;
}
export interface SummaryComparisonInput {
  summaries: SummaryComparisonSummaries;
}

export interface SummarizationUpdateRequest {
  id: string;
  isFavorite: boolean;
  progressPercentage: string;
}
export interface Library {
  id?: string;
  libraryComment: string;
  patterns: PatternDto[];
  libraryType?: string;
  libraryGroup?: string;
  isSelected?: boolean;
}

export interface LibraryDetailsUpdateRequest {
  id?: string;
  libraryComment: string;
  libraryGroup?: string;
}

export interface ActionResponseDto {
  action: string;
  RGB?: string;
}

export interface PatternDto {
  label: string;
  type: string;
  description: string;
  id?: string;
  pattern: string;
  expressionType: ExpressionType;
  action: ActionResponseDto;
}

export interface LibraryListPaginate {
  data: Library[];
  meta: PaginationConfig;
}

export interface LibraryListLitePaginate {
  data: LibraryMapLite[];
  meta: PaginationConfig;
}

export interface LibraryMap {
  libraryId: string;
  libraryName?: string;
  isEnabled: boolean;
  patternType: PatternTypes;
  id?: string;
}

export interface LibraryMapLite {
  label: string;
  isSelected?: boolean;
  libraryGroup: string;
  comment?: string;
  id?: string;
  count: number;
}

export interface LibraryCreateOrUpdateRequest {
  patternName: string;
  label: string;
  libraryId: string;
  id?: string;
  libraryType: PatternTypes;
  isActive: boolean;
  patternGroup: string;
  expressionType: ExpressionType;
  pattern: string | string[];
  libraryGroup: string;
}

export interface ProjectsLite {
  id: string;
  title: string;
  text: string;
  assertions: ProjectAssertionDto[] | any;
  pinToTop: boolean;
  userId: string;
  isReqFromApi: boolean;
  updatedAt: string;
  createdBy: string;
  createdAt: string;
  deletedAt: string | null;
}

export interface CreateProjectRequest {
  title: string;
}

export interface ProjectUpdateRequest {
  id: string;
  pinToTop?: boolean;
  assertions?: ProjectAssertionDto[];
}

export interface ProjectAssertionDto {
  articleId: string;
  assertionId: string;
  assertionText: string;
  model: string;
  createdAt: string;
}

export interface GetProjectsRequest {
  page: number;
  take?: number;
  search?: string;
  pinToTop?: boolean;
}

export interface PromptOptimzerRequest {
  session_id: string;
  user_initial_prompt: string;
  user_initial_prompt_response?: string;
  user_feedback?: string;
}

export interface PromptOptimizerLiteRequest {
  initial_prompt: string;
}

export interface PromptOptimizerLLMsRequest {
  initial_prompt: string;
  optimized_prompt: string;
}
