import { useEffect, useState } from 'react';
import { FacebookIcon, InstagramIcon, GoogleIcon, TwitterIcon, LinkedInIcon } from '../../common/icons/Icons';
import { SocialMediaData, SocialMediaProfiles } from '../../common/services/dossier/person/dossierPerson';
import { SocialMediaProfilesEnum } from '../../common/constants/enums/engine-types.enum';
interface SocialMediaProfilesProps {
  socialMediaProfile: SocialMediaData[];
}

export const SocialMediaLinksGroup: React.FC<SocialMediaProfilesProps> = ({ socialMediaProfile }) => {
  const [socialMediaProfileData, setSocialMediaProfileData] = useState<SocialMediaProfiles>();
  useEffect(() => {
if(socialMediaProfile){
  let socialMediaProfileData: SocialMediaProfiles = {};
  socialMediaProfile.forEach((profile) => {
    switch (profile.Platform) {
      case SocialMediaProfilesEnum.Facebook:
        socialMediaProfileData.Facebook = profile.ProfileUrl;
        break;
      case SocialMediaProfilesEnum.Instagram:
        socialMediaProfileData.Instagram = profile.ProfileUrl;
        break;
      case SocialMediaProfilesEnum.Twitter:
        socialMediaProfileData.Twitter = profile.ProfileUrl;
        break;
        case SocialMediaProfilesEnum.LinkedIn:
          socialMediaProfileData.LinkedIn = profile.ProfileUrl;
          break;
    }
  });
    setSocialMediaProfileData(socialMediaProfileData);
}

  }, [socialMediaProfile]);

  return (
    <>
    {socialMediaProfileData && <div className="flex gap-4 m-4 justify-end flex-grow">
      {socialMediaProfileData?.Facebook && (
        <a href={socialMediaProfileData.Facebook} target="_blank" role="button">
          <FacebookIcon />
        </a>
      )}
      {socialMediaProfileData?.Instagram && (
        <a href={socialMediaProfileData.Instagram} role="button" target="_blank">
          <InstagramIcon />
        </a>
      )}
      {/* <a href="#!" role="button"><GoogleIcon /></a> */}
      {socialMediaProfileData?.Twitter && (
        <a href={socialMediaProfileData.Twitter} target="_blank" role="button">
          <TwitterIcon />
        </a>
      )}
      {socialMediaProfileData?.LinkedIn && (
        <a href={socialMediaProfileData.LinkedIn} target="_blank" role="button">
          <LinkedInIcon />
        </a>
      )}
    </div>}
    </>
  );
};
