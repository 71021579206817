import { hide } from '@popperjs/core';

export const planDetails = [
  {
    id: 1,
    name: 'Free',
    paymentLink: 'https://www.google.com/',
    currentPlan: true,
    description: 'Best option for personal use & for your next project.',
    price: 0,
    features: ['Max 5 Searches per day', 'Fact Checker', 'Prompt Protector', 'Gateway (Legacy)'],
  },
  {
    id: 2,
    currentPlan: false,
    paymentLink: 'https://buy.stripe.com/test_14k7sV2vkc9r6tO8wA',
    name: 'Individual',
    description: 'Best option for personal use & for your next project.',
    price: 5,
    features: [
      'Unlimited Searches',
      'Fact Checker',
      'Prompt Protector',
      'AI Validation Tools',
      'Gateway (Legacy)',
      'Dossier Generator',
    ],
  },
  {
    id: 3,
    name: 'Pro',
    paymentLink: 'https://buy.stripe.com/test_dR6bJbd9Yehz5pKeUZ',

    currentPlan: false,
    description: 'Relevant for multiple users, extended & premium support.',
    price: 50,
    features: [
      'Unlimited Searches',
      'Fact Checker',
      'Prompt Protector',
      'AI Validation Tools',
      'Gateway (Legacy)',
      'Dossier Generator',
      'Counterpoint',
      'Team Administration',
      'Advanced Client instance Deployment',
      'API Access',
    ],
  },
  {
    id: 4,
    name: 'Enterprise',

    currentPlan: false,
    paymentLink: 'https://buy.stripe.com/test_6oE3cF2vk6P77xS7sy',
    description: 'Best for large scale uses and extended redistribution rights.',
    price: 250,
    features: [
      'Unlimited Searches',
      'Fact Checker',
      'Prompt Protector',
      'AI Validation Tools',
      'Gateway (Legacy)',
      'Dossier Generator',
      'Counterpoint',
      'Team Administration',
      'Advanced Client instance Deployment',
      'API Access',
      'Advanced Client instance Deployment',
    ],
  },
];
class PaymenterviceImpl {
  private static instance: PaymenterviceImpl;

  private constructor() {}

  static getInstance(): PaymenterviceImpl {
    if (!PaymenterviceImpl.instance) {
      PaymenterviceImpl.instance = new PaymenterviceImpl();
    }

    return PaymenterviceImpl.instance;
  }

  fetchPlanDetails = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(planDetails);
      }, 1000);
    });
  };
}

const paymentService = PaymenterviceImpl.getInstance();
export { paymentService };
