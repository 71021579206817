import { FC } from 'react';
import Modal from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MainButton } from '../MainButton';
import { SecondaryButton } from '../SecondaryButton';

interface PropTypes {
  isShown: boolean;
  onClose: () => void;
  onConfirm: (e: React.FormEvent) => void;
}

export const ConfirmModal: FC<PropTypes> = ({ isShown, onClose, onConfirm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="flex justify-center text-left items-center animate__animated animate__slideInUp  ">
      <Modal
        isShown={isShown}
        // animation='fade'
        centered
        onAfterClose={onClose}
        withBackdrop
        onBackdropClick={onClose}
      >
        <div
          className="flex flex-col items-center:true  border rounded-md border-gray-100 border-solid bg-white shadow-md "
          id="ConfirmModal"
        >
          <div className="bg-gradient-to-b from-purple-500 to-blue-500 text-gray-50 p-2 mb-5 rounded-md">Confirm</div>
          <div className="m-2">
            <h1 className="mb-10  text-gray-600 font-sansInte">Please click the Confirm button to delete</h1>
            <div className="flex gap-2 float-right">
              <MainButton
                isLoading={false}
                text={'Confirm'}
                onClick={onConfirm}
                className="px-2 py-2 w-24"
                stateProp="rest"
                disabled={false}
              />
              {<SecondaryButton className="px-3 py-2" stateProp="rest" onClick={onClose} text="Cancel" />}
              {/* <button
                className='bg-yellow rounded-md shadow-md m-2 p-1'
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className='bg-blue rounded-md shadow-md m-2 p-1'
                onClick={onConfirm}
              >
                Confirm
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
