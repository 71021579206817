import React, { useEffect, useState } from 'react';
import ImportPatterns from './ImportPatterns';
import CustomPatternLibraries from './PatternLibraries/CustomPatternLibraries';
import NewCustomPatternLibrary from './PatternLibraries/NewCustomPatternLibrary';
import PatternLibraryList from './PatternLibraries/PatternLibraryList';
import SystemPatternLibraries from './PatternLibraries/SystemPatternLibraries';
import { Divider } from '../../components/Divider/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { patternManagementActions } from '../../redux/actions/pattern-management.actions';
import { patternManagementSelectors } from '../../redux/selectors/pattern-management.selectors';
interface Pattern {
    id: number;
    title: string;
    description: string;
  }
  

  
const PatternLibrariesPage: React.FC = () => {
  const [newCustomLibraryMode, setNewCustomLibraryMode] = useState(false);
  const dispatch = useDispatch();
  const systemPatterns = useSelector(patternManagementSelectors.selectSystemPatterns);
  const userLibraries = useSelector(patternManagementSelectors.selectUserLibraries);
  const removePattern = (id: number) => {
    // Implement the logic to remove the pattern from the list
    console.log(`Removing pattern with id ${id}`);
  };


  useEffect(() => {
    dispatch(patternManagementActions.initilizeLibraries())
  }, []);
 

  return (
    <div className="flex">
      <div className="flex-1 p-4">
        <SystemPatternLibraries  systemPatterns={systemPatterns} />
        <div className="px-4">
        <Divider />
        </div>
        <CustomPatternLibraries
          customPatterns={userLibraries}
          onCreateNew={() => setNewCustomLibraryMode(true)}
        />
        
        {newCustomLibraryMode && (
          <NewCustomPatternLibrary
            onSave={(name) => {
              // Handle saving new custom pattern library
              setNewCustomLibraryMode(false);
            }}
            onCancel={() => setNewCustomLibraryMode(false)}
          />
        )}
        <div className="px-4">
        <Divider />
        </div>
      </div>
    </div>
  );
};

export default PatternLibrariesPage;