import React, { useState, useEffect, useCallback } from 'react';
import { AssertionsListIcon, DotsMenuIcon, PinToTopIcon, PlusIcon } from '../../common/icons/Icons';
import { PrimaryButton } from '../../components/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '../../components/Divider/Divider';
import { RoutePath } from '../../common/constants/route-path';
import { useNavigate } from 'react-router-dom';
import { ProjectsSelector, loadingProjectsSelector } from '../../redux/selectors/projects.selectors';
import Modal from '../../components/Modal/Modal';
import { ActionItem } from '../../components/ActionItem/ActionItem';
import { CrossIcon } from '../../common/icons/CrossIcon';
import { ProjectsActions } from '../../redux/actions/projects.actions';
import { iconComponents } from '../../common/helpers/Helper';
import { GuardRailEngineType, SourceType } from '../../common/constants/enums/engine-types.enum';
import { ProjectAssertionDto, ProjectsLite } from '../../types/models';
import AppLoader from '../../components/AppLoader/AppLoader';
import { DeleteModal } from '../../components/Modal/DeleteModal/DeleteModal';
import { setNotification } from '../../redux/actions/notification.actions';

interface ProjectListItemProps {
  Project: ProjectsLite;
}

const ProjectListItem: React.FC<ProjectListItemProps> = React.memo(({ Project }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    setShowDeleteModal(false);
    dispatch(ProjectsActions.deleteProject(Project.id));
  };

  const uniqueModels: string[] = [];
  Project?.assertions?.forEach((assertion: ProjectAssertionDto) => {
    if (!uniqueModels.includes(assertion?.model)) {
      uniqueModels.push(assertion?.model);
    }
  });

  return (
    <div className=" my-3">
      <div className="flex justify-between items-center mb-3">
        <p
          className="text-nowrap leading-5"
          onClick={() => {
            navigate(`${RoutePath.Projects}/${Project.id}`);
          }}
        >
          {Project?.title}
        </p>
        <div className="flex relative">
          {showDeleteButton && (
            <div className="absolute bottom-10 right-0 bg-white border rounded-md shadow-lg min-w-40">
              <div
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  setShowDeleteModal(true);
                  setShowDeleteButton(false);
                }}
                style={{ color: 'var(--purple-600)' }}
              >
                Delete Project
              </div>
            </div>
          )}
          {showDeleteModal && (
            <DeleteModal
              engineType={GuardRailEngineType.Projects}
              isShown={showDeleteModal}
              onDialogClose={() => {
                setShowDeleteModal(false);
              }}
              onDeleteClick={handleDelete}
            />
          )}

          <div
            className="input-action-item pt-4 pl-4 pr-4 pb-4"
            onClick={() => {
              if (!Project.pinToTop) {
                dispatch(
                  ProjectsActions.updateProject({
                    id: Project.id,
                    pinToTop: true,
                  }),
                );
              } else {
                dispatch(setNotification({ title: 'Project already pinned ', type: 'info' }));
              }
            }}
          >
            <div className="text-wrapper-27 flex items-center">
              <span className="mr-2">Pin to top</span> <PinToTopIcon />
            </div>
          </div>
          <div
            className="input-action-item pt-4 pl-4 pr-4 pb-4 flex-shrink-0"
            onClick={() => {
              setShowDeleteButton(!showDeleteButton);
            }}
          >
            <div className="text-wrapper-27 flex items-center">
              <DotsMenuIcon />
            </div>
          </div>
        </div>
      </div>
      {Project?.assertions?.length > 0 && (
        <p
          className="text-sm text-neutral-500 line-clamp-3 mb-4"
          onClick={() => {
            navigate(`${RoutePath.Projects}/${Project.id}`);
          }}
        >
          {Project?.assertions[0]?.assertionText}
        </p>
      )}
      <div className="flex items-center justify-between">
        <div className="flex justify-center items-center">
          <p className="text-sm text-neutral-400 flex justify-center mr-4">
            <span className="mr-2">
              <AssertionsListIcon />
            </span>
            {Project?.assertions?.length}
          </p>
          {uniqueModels.map((model: string) => {
            return <div className="mx-1">{iconComponents[model as SourceType]}</div>;
          })}
        </div>
        <p className="text-xs text-neutral-400 line-clamp-3">
          {'Last edited: ' +
            new Date(Project?.updatedAt).toLocaleDateString('en-US', {
              year: 'numeric',
              day: 'numeric',
              month: 'numeric',
            })}
        </p>
      </div>
    </div>
  );
});

export const ProjectsListView: React.FC = () => {
  const dispatch = useDispatch();
  const Projects = useSelector(ProjectsSelector);
  const loadingProjects = useSelector(loadingProjectsSelector);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [projectTitle, setProjectTitle] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const debounce = (func: Function, delay: number) => {
    let debounceTimer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  const handleSearch = useCallback(
    debounce((text: string) => {
      dispatch(ProjectsActions.getProjectsList({ page: 1, search: text }));
    }, 500),
    [],
  );

  useEffect(() => {
    if (searchText !== undefined) handleSearch(searchText);
  }, [searchText, handleSearch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex justify-center mb-10">
      <div className="w-full">
        <div className=" bg-gray-100 min-h-28 flex justify-center items-center">
          <div className=" w-3/4 flex justify-between">
            <input
              type="text"
              id="table-search-users"
              className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search project canvas"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <PrimaryButton
              onClick={() => {
                setModalOpen(true);
              }}
              className=""
              size="medium"
              stateProp="rest"
              style={`boxed`}
              icon={<PlusIcon />}
              iconPosition="left"
              text="Create new"
            />
          </div>
        </div>
        {loadingProjects ? (
          <AppLoader />
        ) : (
          <div className=" flex justify-center">
            {Projects.length > 0 ? (
              <div className="w-3/4 mt-12">
                {Projects?.map((project: ProjectsLite) => (
                  <>
                    <ProjectListItem Project={project} />
                    <Divider />
                  </>
                ))}
              </div>
            ) : searchText ? (
              <div className="flex flex-col justify-center items-center">
                <div className="mt-20 mb-10">No projects found. Try some other term.</div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <div className="mt-20 mb-10">Start by creating a new project.</div>
                <PrimaryButton
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  className=""
                  size="medium"
                  stateProp="rest"
                  style={`boxed`}
                  icon={<PlusIcon />}
                  iconPosition="left"
                  text="Create new"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        isShown={modalOpen}
        animation="fade"
        centered
        withBackdrop
        onBackdropClick={() => {
          setModalOpen(false);
        }}
      >
        <div className=" flex items-center justify-center p-4 w-[50vw]">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full max-w-4xl p-8">
            <div className="flex items-center justify-between">
              <span className=" text-lg">Create project canvas</span>
              <ActionItem
                icon={<CrossIcon />}
                text={'Close'}
                isVisible={true}
                isPrefix={true}
                textClass={`text-wrapper-27`}
                onClick={() => {
                  setModalOpen(false);
                }}
              />
            </div>
            <input
              type="text"
              id="table-search-users"
              className="mt-6 block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Provide project name"
              value={projectTitle}
              onChange={(e) => setProjectTitle(e.target.value)}
            />
            <div className="flex justify-end">
              <button
                disabled={projectTitle.length === 0}
                className="disabled:from-gray-300 disabled:to-gray-300 text-white rounded-md px-4 py-[6px] text-sm mt-2 bg-gradient-to-b from-purple-500 to-blue-500 "
                onClick={() => {
                  dispatch(
                    ProjectsActions.createProject({
                      title: projectTitle,
                    }),
                  );
                  setModalOpen(false);
                  setProjectTitle('');
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
