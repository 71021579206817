import Chart from 'react-apexcharts';
import { Card } from '../../components/Card/Card';
import Loading from '../../components/Loader/Loader';
import { BarChart } from '../../components/Charts/BarChart';
import { PieChart } from '../../components/Charts/PieChart';
import { BarChartDouble } from '../../components/Charts/BarChartDouble';
import { ProgressChart } from '../../components/Charts/ProgressChart';
import { AreaChart } from '../../components/Charts/AreaChart';
import { RecentActivity } from '../../components/RecentActivity/RecentActivity';
import { useDispatch, useSelector } from 'react-redux';
import {
  allRecords,
  loadingDashboard,
  metricValues,
  metricsCategories,
  metricsForArea,
  profileCompletion,
  seriesMetricsForCompleted,
} from '../../redux/selectors/dashboard.selectors';
import { useEffect, useState } from 'react';
import { dashboardActions } from '../../redux/actions/dashboard.actions';
import { DashboardMatericsRequestByDateRange } from '../../common/services/dashboard/models/dashboard.model';
import { LoadingIndicator } from '../../components/AppLoader/LoadingIndicator';
import { userPermissions } from '../../redux/selectors/profile.selectors';

export const Dashboard = () => {
  const dashboardCategories = useSelector(metricsCategories);
  const allActivity = useSelector(allRecords);
  const dashboardValues = useSelector(metricValues);
  const dispatch = useDispatch();
  const series = useSelector(seriesMetricsForCompleted);
  const areaMetrics = useSelector(metricsForArea);
  const profileCompletionStates = useSelector(profileCompletion);
  const fetchingDashboard = useSelector(loadingDashboard);
  const allowedPermissions = useSelector(userPermissions);
  useEffect(() => {
    let currentDate = new Date();
    let payload = {
      endDate: new Date(),
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      userPermissions: allowedPermissions
    } as DashboardMatericsRequestByDateRange;
    dispatch(dashboardActions.getDashboardMetrics(payload));
  }, [allowedPermissions]);

  return (
    <>
      {fetchingDashboard ? (
        <> <LoadingIndicator/> </>
      ) : (
        <div className="m-4">
          {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-10">
            {<BarChart categories={dashboardCategories} data={dashboardValues} title="No of completed request" />}
            <AreaChart matrics={areaMetrics} title="Favorite Records Trend" />
            <BarChartDouble categories={dashboardCategories} series={series} title="Completed vs Favorite Trend" />
          </div> */}
          {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-10"> */}
          {/* <div className="charts-container md:col-span-1">
              <PieChart categories={dashboardCategories} data={dashboardValues} title="Module wise completed request" />
              <ProgressChart progress={profileCompletionStates} />
            </div> */}
          <div className="recent-activity-container w-full md:col-span-2 animate__animated animate__fadeInUp">
            <RecentActivity />
          </div>
          {/* </div> */}
        </div>
      )}
    </>
  );
};
