import { createBrowserRouter } from 'react-router-dom';
import {
  PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER,
  PLACEHOLDER_FOR_USERINPUT_DOSSIER_GENERATOR,
  PLACEHOLDER_FOR_PROMPT_PROTECTOR,
  PLACEHOLDER_FOR_USERINPUT_FEDERATED,
} from '../../common/constants/app-text';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';
import { RoutePath } from '../../common/constants/route-path';
import { SignIn } from '../../views/Auth/SignIn';
import { SignUp } from '../../views/Auth/SignUp';
import OnboardClientApi from '../../views/ClientAppOnboarding/OnboardClientApi';
import { OnboardClientApp } from '../../views/ClientAppOnboarding/OnboardClientApp';
import { DossierGeneratorEngineView } from '../../views/Engines/DossierGeneratorEngineView';
import { DossierOrganizationLayout } from '../../views/Engines/DossierOrganization';
import { FactCheckerEngineView } from '../../views/Engines/FactCheckerEngineView';
import { PromptProtechEngineView } from '../../views/Engines/PromptProtechEngineView';
import { PromptProtectDetailedView } from '../../views/Engines/PromptProtectDetailedView';
import { ExternalSearchApp } from '../../views/ExternalSearchSource/ExternalSearchApp';
import ExternalSearchSourceApi from '../../views/ExternalSearchSource/ExternalSearchSourceApi';
import { Home } from '../../views/Home';
import { Landing } from '../../views/Landing';
import { ArticleResultViewContent } from '../../views/Landing/ArticleResultView';
import { HomeContent } from '../../views/Landing/HomeContent';
import { NewPassword } from '../../views/PasswordRecovery/components/Newpassword';
import { PasswordRecovery } from '../../views/PasswordRecovery/components/PasswordRecovery';
import { ArticleProcess } from '../../views/Processing/ArticleProcess/ArticleProcess';
import { ContentInput } from '../ContentInput';
import { DynamicLayoutContainer } from '../DynamicLayout/DynamicLayoutContainer';
import { DossierProcess } from '../DynamicLayout/configComponents/DossierProcess';
import { PromptProcess } from '../DynamicLayout/configComponents/PromptProcess';
import ErrorPage from '../ErrorPage/ErrorPage';
import { ViewProfile } from '../../views/UserPreference/ViewProfile';
import WithAuthorizationCheck from '../../config/authorization/WithAuthorizationCheck';
import {
  ACCESS_GUARDRAIL123,
  ACCESS_PERMISSIONS_MANAGE,
  CLIENT_API_CONFIGURE_EXTERNAL_SEARCH,
  CONFIGURE_EXTERNAL_SEARCH_SOURCE,
  CONTRADICTION_FINDER_CREATE,
  DOSSIER_GENERATOR_CREATE,
  FACT_CHECKER_CREATE,
  FACT_CHECKER_PROCESS,
  GATEWAY_ACCESS,
  PROMPT_PROTECT_CREATE,
} from '../../config/authorization/PermissionsConstants';
import { ProtectedRoute } from '../../config/authentication/ProtectedRoute';
import { AccessManagementMainView, AccessManagementView } from '../../views/AccessManagement/AccessManagement';
import PermissionsView from '../../views/AccessManagement/permissions/permissionsView';
import { RolesDetailedView } from '../../views/AccessManagement/roles/rolesDetailedView';
import { RolesView } from '../../views/AccessManagement/roles/rolesView';
import { UsersView } from '../../views/AccessManagement/users/usersView';
import ProfilePage from '../ViewProfile/ProfileOverriew';
import { FederatedView } from '../../views/Federated/Federated';
import { ContentInputFederated } from '../ContentInputFederated';
import { LLMSResultView } from '../../views/Federated/LLMSResultView';
import { CodeAnalysisView } from '../../views/CodeAnalysis/CodeAnalysis';
import { CodeAnalysisInput } from '../../views/CodeAnalysis/CodeAnalysisInput';
import { CodeAnalysisResultView } from '../../views/CodeAnalysis/CodeAlalysisResultView';
import { SummarizationView } from '../../views/Summarization/SummarizationView';
import { SummarizationInput } from '../../views/Summarization/SummarizationInput';
import { SummarizationResultView } from '../../views/Summarization/SummarizationResultView';
import { SummarizationMethods } from '../../views/Summarization/SummarizationMethods';
import { SummarizationProcessing } from '../../views/Summarization/SummarizationProcessingView';
import { SummaryDetailsView } from '../../views/Summarization/SummaryDetailsView';
import { SummaryComparisonView } from '../../views/Summarization/SummaryComparisonView';
import ProfileSettings from '../ViewProfile/ProfileSettings';
import Preferences from '../ViewProfile/Preferences';
import ConfigurationManagement from '../ViewProfile/ConfigurationManagement';
import { ProjectsView } from '../../views/AiProjects/ProjectsView';
import { ProjectDetailsView } from '../../views/AiProjects/ProjectDetailsView';
import PatternLibrariesPage from '../../views/PatternConfiguration/PatternManagementLanding';
import { PatternManagement } from '../../views/PatternConfiguration/PatternManagement';
import LibraryAddOrUpdate from '../../views/PatternConfiguration/PatternLibraries/LibraryAddOrUpdate';
import ProjectsTabsView from '../../views/AiProjects/ProjectsTabsView';
import { SubscriptionPlansView } from '../../views/SubscriptionPlans/SubscriptionPlansView';
import { SubscriptionConfirmation } from '../../views/SubscriptionPlans/pages/SubscriptionConfirmation';
import SubscriptionPlans from '../../views/SubscriptionPlans/components/SubscriptionPlans';
import { StripePricingTable } from '../../views/SubscriptionPlans/components/StripePricingTable';
import { Gateway123View } from '../../views/Gateway123/Gateway123View';
import Gateway123Input from '../../views/Gateway123/Gateway123Input';
import { Gateway123ResultView } from '../../views/Gateway123/Gateway123Result';

export const router = createBrowserRouter([
  {
    path: RoutePath.Home,
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePath.Landing,
    element: (
      <ProtectedRoute>
        <Landing />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.Landing,
        element: <HomeContent />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: `${RoutePath.Plans}`,
    element: (
      <ProtectedRoute>
        <SubscriptionPlansView />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: `${RoutePath.Plans}`,
        element: <SubscriptionPlans />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.PaymentSuccess}`,
        element: <SubscriptionConfirmation />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.BillingPlan}`,
        element: <SubscriptionPlans />,
        errorElement: <ErrorPage />,
      },
    ],
  },

  {
    path: RoutePath.FactChecker,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[FACT_CHECKER_PROCESS]}>
          <FactCheckerEngineView />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: `${RoutePath.FactChecker}/:id`,
        element: <ArticleResultViewContent />,
        errorElement: <ErrorPage />,
      },

      {
        path: RoutePath.FactCheckerProcess,
        element: (
          <ProtectedRoute>
            <WithAuthorizationCheck requiredPermissions={[FACT_CHECKER_PROCESS]}>
              <ArticleProcess />
            </WithAuthorizationCheck>
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.FactChecker,
        element: (
          <ProtectedRoute>
            <WithAuthorizationCheck requiredPermissions={[FACT_CHECKER_PROCESS]}>
              <ContentInput
                engineType={GuardRailEngineType.FactChecker}
                placeHolderText={PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER}
              />
            </WithAuthorizationCheck>
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.ContradictionFinder,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[CONTRADICTION_FINDER_CREATE]}>
          <FactCheckerEngineView engineType={GuardRailEngineType.ContractionFinder} />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: `${RoutePath.ContradictionFinder}/:id`,
        element: <ArticleResultViewContent engineType={GuardRailEngineType.ContractionFinder} />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.ContradictionFinderProcess,
        element: <ArticleProcess engineType={GuardRailEngineType.ContractionFinder} />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.ContradictionFinder,
        element: (
          <ContentInput
            engineType={GuardRailEngineType.ContractionFinder}
            placeHolderText={PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER}
          />
        ),
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.Dossier,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[DOSSIER_GENERATOR_CREATE]}>
          <DossierGeneratorEngineView />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: `${RoutePath.Dossier}/organization/:id`,
        element: <DossierOrganizationLayout />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.Dossier}/person/:id`,
        element: <DynamicLayoutContainer />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.DossierProcess,
        element: <DossierProcess />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.Dossier,
        element: (
          <ContentInput
            engineType={GuardRailEngineType.Dossier}
            placeHolderText={PLACEHOLDER_FOR_USERINPUT_DOSSIER_GENERATOR}
            showTextInput={true}
          />
        ),
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.PromptProtect,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[PROMPT_PROTECT_CREATE]}>
          <PromptProtechEngineView />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: `${RoutePath.PromptProtect}/:id`,
        element: <PromptProtectDetailedView />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.PromptProcess,
        element: <PromptProcess />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.PromptProtect,
        element: (
          <ContentInput
            engineType={GuardRailEngineType.PromptProtect}
            placeHolderText={PLACEHOLDER_FOR_PROMPT_PROTECTOR}
            showTextInput={true}
          />
        ),
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.PasswordRecovery,
    element: <PasswordRecovery />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.PasswordRecovery,
        element: <NewPassword />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.SignIn,
    element: <SignIn />,
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePath.SignUp,
    element: <SignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: RoutePath.ChangePassword,
    element: (
      <ProtectedRoute>
        <PasswordRecovery />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.ChangePassword,
        element: <NewPassword />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.clientApiOnboarding,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[CLIENT_API_CONFIGURE_EXTERNAL_SEARCH]}>
          <OnboardClientApp />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.clientApiOnboarding,
        element: <OnboardClientApi />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.clientApiOnboarding}/:id`,
        element: <OnboardClientApi />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.externalSearchSource,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[CONFIGURE_EXTERNAL_SEARCH_SOURCE]}>
          <ExternalSearchApp />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.externalSearchSource,
        element: <ExternalSearchSourceApi />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.externalSearchSource}/:id`,
        element: <ExternalSearchSourceApi />,
        errorElement: <ErrorPage />,
      },
    ],
  },

  {
    path: RoutePath.patternConfiguration,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[]}>
          <PatternManagement />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.patternConfiguration,
        element: <PatternLibrariesPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.patternConfigurationAdd,
        element: <LibraryAddOrUpdate isAdd={true} />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.patternConfigurationEdit}/:id`,
        element: <LibraryAddOrUpdate isAdd={false} />,
        errorElement: <ErrorPage />,
      },
    ],
  },

  {
    path: RoutePath.EditProfile,
    element: (
      <ProtectedRoute>
        <ViewProfile />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.EditProfile,
        element: <ProfilePage />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: RoutePath.ProfileSettings,
            element: <ProfileSettings />,
            errorElement: <ErrorPage />,
          },
          {
            path: RoutePath.Preferences,
            element: <Preferences />,
            errorElement: <ErrorPage />,
          },
          {
            path: RoutePath.ConfigurationManagement,
            element: <ConfigurationManagement />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePath.accessManagement,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[ACCESS_PERMISSIONS_MANAGE]}>
          <AccessManagementView />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.accessManagement,
        element: <AccessManagementMainView />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: RoutePath.accessManagementPermissions,
            element: <PermissionsView />,
            errorElement: <ErrorPage />,
          },
          {
            path: RoutePath.accessManagementRole,
            element: <RolesView />,
            errorElement: <ErrorPage />,
          },
          {
            path: `${RoutePath.accessManagementRole}/:role`,
            element: <RolesDetailedView />,
            errorElement: <ErrorPage />,
          },
          {
            path: RoutePath.accessManagementUsers,
            element: <UsersView />,
            errorElement: <ErrorPage />,
          },
          {
            path: RoutePath.accessManagementSupport,
            element: <PermissionsView />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePath.Federated,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[GATEWAY_ACCESS]}>
          <FederatedView />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.LLMResult,
        element: (
          <ContentInputFederated
            engineType={GuardRailEngineType.Federated}
            placeHolderText={PLACEHOLDER_FOR_USERINPUT_FEDERATED}
            showTextInput={true}
          />
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.LLMResult}/:id`,
        element: <LLMSResultView />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.LLMResult}/:id/fact-check`,
        element: (
          <ProtectedRoute>
            <WithAuthorizationCheck requiredPermissions={[GATEWAY_ACCESS]}>
              <ContentInput
                viewType="Processing"
                engineType={GuardRailEngineType.Federated}
                placeHolderText={PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER}
              />
            </WithAuthorizationCheck>
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.LLMResult}/:id/fact-check/:id`,
        element: (
          <ProtectedRoute>
            <WithAuthorizationCheck requiredPermissions={[GATEWAY_ACCESS]}>
              <ArticleResultViewContent showClipToProject={true} />
            </WithAuthorizationCheck>
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.CodeAnalyzer,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[]}>
          <CodeAnalysisView />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.CodeAnalyzer,
        element: (
          <>
            <CodeAnalysisInput />
          </>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.CodeAnalyzer}/:id`,
        element: <CodeAnalysisResultView />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.CodeAnalyzerProcess,
        element: <CodeAnalysisInput isProcessing={true} />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.Summarization,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[]}>
          <SummarizationView />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.Summarization,
        element: <SummarizationInput />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.Summarization}/:id`,
        element: <SummarizationResultView />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.SummarizationMethods,
        element: <SummarizationMethods />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePath.SummarizationProcess,
        element: <SummarizationProcessing />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.Summarization}/:id/details/:model`,
        element: <SummaryDetailsView />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.Summarization}/:id/compare/:comparisonId`,
        element: <SummaryComparisonView />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.Projects,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[]}>
          <ProjectsView />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.Projects,
        element: <ProjectsTabsView />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.Projects}/:id`,
        element: <ProjectDetailsView />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: RoutePath.Gateway123,
    element: (
      <ProtectedRoute>
        <WithAuthorizationCheck requiredPermissions={[ACCESS_GUARDRAIL123]}>
          <Gateway123View />
        </WithAuthorizationCheck>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePath.Gateway123,
        element: <Gateway123Input placeHolderText={PLACEHOLDER_FOR_USERINPUT_FEDERATED} />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.Gateway123}/:id`,
        element: <LLMSResultView engineType={GuardRailEngineType.Gateway123} />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePath.Gateway123}/:id/fact-check/:id`,
        element: (
          <ProtectedRoute>
            <WithAuthorizationCheck requiredPermissions={[ACCESS_GUARDRAIL123]}>
              <Gateway123ResultView engineType={GuardRailEngineType.Gateway123} />
            </WithAuthorizationCheck>
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
      },
    ],
  },
]);
