import React, { ReactNode } from 'react';
import { DynamicTitle } from './DynamicTitle';
import { TimeLineIcon } from '../../../common/icons/Icons';

interface TimelineItemProps {
  icon: ReactNode;
  title: string;
  description: string;
  hideSubTitle?: boolean;
}

interface TimelineProps {
  items: TimelineItemProps[];
  hideSubTitle?: boolean;
  title?: string;
}

const TimelineItem: React.FC<TimelineItemProps> = ({ title, description, hideSubTitle = false }) => {
  return (
    <li className="relative flex items-start overflow-visible my-5">
      <div
        className="
        -ml-0.5
        absolute
        mt-0.5
        top-14
        left-8
        w-px
        border-l-4 border-dotted border-gray-300
        h-full
      "
        aria-hidden="true"
      ></div>
      <div
        className="
        relative
        flex
        items-center
        justify-center
        flex-shrink-0
        w-16
        h-16
        bg-white
        rounded-full
        shadow
      "
      >
        <TimeLineIcon />
      </div>
      <div className="ml-6">
        <h3 className="text-lg font-semibold text-black">{title}</h3>
        {hideSubTitle ? null : <p className="mt-4 text-base text-gray-600">{description}</p>}
      </div>
    </li>
  );
};

export const Timeline: React.FC<TimelineProps> = ({ items, hideSubTitle = false, title }) => {
  return (
    <div
      className="
      divide-y divide-gray-200
      rounded-2xl
      border border-gray-200
      shadow-sm
      bg-white"
    >
      <div className="m-4 overflow-visible">
        <DynamicTitle title={title} />
        {items.length > 0 ? (
          <ul className="">
            {items.map((item, index) => (
              <TimelineItem hideSubTitle={hideSubTitle} key={index} {...item} />
            ))}
          </ul>
        ) : (
          <div className="text-center text-gray-400">No data to display</div>
        )}
      </div>
    </div>
  );
};
