// ... (imports and interfaces)

import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResponseItemFields from './ResponseItem';
import RequestItemFields from './RequestItemField';
import { MainButton } from '../MainButton';
import { isEmpty } from '../../common/helpers/objects/is-empty';
import { SecondaryButton } from '../SecondaryButton';
import { RoutePath } from '../../common/constants/route-path';
import Input from '../Input';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentExternalSearch,
  initialExternalSearch,
  isFetchingExternalSearch,
  externalSearchPagination,
  isValidated,
  isLoading,
  isCloningExternalSearch,
  isDeleteExternalSearch,
  isValidateExternalSearch,
} from '../../redux/selectors/external-search.selectors';
import { externalSearchActions } from '../../redux/actions/external-search.action';
import { ExternalSearchSource } from '../../common/services/external-search/models/external-search';
import useModal from '../Modal/useModal';
import { ConfirmModal } from '../Modal/ConfirmModal';
const isStringEmpty = (str: any) => !str;

interface PropTypes {
  onSubmit: (externalSearch: ExternalSearchSource) => void;
}

const ExternalSearchApiForm: React.FC<PropTypes> = ({ onSubmit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let modal = useModal();
  const [isShown, openModal, closeModal] = modal;

  // let initialData: ExternalSearchSource = {
  //   name: '',
  //   id: '',
  //   requestItemDto: {
  //     apiUrl: '',
  //     httpMethod: 'Get',
  //     params: [{ key: '', value: '', isEncrypted: false }],
  //     headers: [{ key: '', value: '', isEncrypted: false }],
  //     body: [{ key: '', value: '', isEncrypted: false }],
  //   },
  //   childRequestItemDto: {
  //     apiUrl: '',
  //     httpMethod: 'Post',
  //     params: [{ key: '', value: '', isEncrypted: false }],
  //     headers: [{ key: '', value: '', isEncrypted: false }],
  //     body: [{ key: '', value: '', isEncrypted: false }],
  //   },
  //   responseItemDto: {
  //     rootData: ['root', 'data'],
  //     url: '',
  //     title: '',
  //     snippet: '',
  //   },
  // };

  const externalSearchActualSource: ExternalSearchSource = useSelector(currentExternalSearch);

  const initialSearchActualSource: ExternalSearchSource = useSelector(initialExternalSearch);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isValidatedClicked, setIsValidatedClicked] = useState(false);
  const [isCloneClicked, setIsCloneClicked] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const isFetchingExternalSearchSource: boolean = useSelector(isFetchingExternalSearch);

  const isLoadingDone: boolean = useSelector(isLoading);
  const isValidateExternalSearchSource: boolean = useSelector(isValidateExternalSearch);
  const isCloningExternalSearchSource: boolean = useSelector(isCloningExternalSearch);

  const isValidatedExternalApi: boolean = useSelector(isValidated);
  const isDeleteExternalSearchSource: boolean = useSelector(isDeleteExternalSearch);

  const paginationConfig = useSelector(externalSearchPagination);

  const [externalSearchSource, SetExternalSearchSource] = useState<ExternalSearchSource>(initialSearchActualSource);
  const [errors, setErrors] = useState<{
    [key in keyof ExternalSearchSource]?: any;
  }>({});
  // const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    SetExternalSearchSource((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  const onChange = (field: string, value: string) => {
    SetExternalSearchSource((prevData) => ({
      ...prevData,
      name: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  // const handleExternalSourceChangeDataHandler = useCallback(
  //   (type: any) => (event: { target: { value: any } }) => {
  //     SetExternalSearchSource({
  //       ...externalSearchSource,
  //       [type]: event.target.value,
  //     });
  //   },
  //   [externalSearchSource]
  // );

  const handleRequestItemChange = (
    type: 'requestItemDto' | 'childRequestItemDto',
    field: string,
    value: string | { key: string; value: string; isEncrypted: boolean }[],
  ) => {
    SetExternalSearchSource((prevData) => ({
      ...prevData,
      [type]: {
        ...prevData[type],
        [field]: value,
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [type]: undefined,
    }));
  };

  const handleResponseItemChange = (
    field: string,
    value: string | string[] | { url: string; title: string; snippet: string | string[] },
  ) => {
    SetExternalSearchSource((prevData) => ({
      ...prevData,
      responseItemDto: { ...prevData.responseItemDto, [field]: value },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      responseItemDto: undefined,
    }));
  };

  useEffect(() => {
    if (externalSearchActualSource && isFetchingExternalSearchSource) {
      let response: ExternalSearchSource = {
        id: externalSearchActualSource.id,
        name: externalSearchActualSource.name,
        requestItemDto: externalSearchActualSource.requestItemDto,
        childRequestItemDto: externalSearchActualSource.childRequestItemDto,
        responseItemDto: externalSearchActualSource.responseItemDto,
      };
      SetExternalSearchSource(response);
      setIsValidatedClicked(false);
    }
  }, [externalSearchActualSource]);

  const validateForm = (): boolean => {
    const newErrors: { [key in keyof ExternalSearchSource]?: any } = {};

    // Validate individual fields here
    if (externalSearchSource.name && !externalSearchSource.name.trim()) {
      newErrors.name = 'Name is required';
    }

    // ... Add more validation rules for other fields

    // Validate requestItemDto
    if (externalSearchSource.requestItemDto.apiUrl && !externalSearchSource.requestItemDto.apiUrl.trim()) {
      newErrors.requestItemDto = {
        ...newErrors.requestItemDto,
        apiUrl: 'API URL is required',
      };
    }

    if (externalSearchSource.responseItemDto.url && !externalSearchSource.responseItemDto.url.trim()) {
      newErrors.responseItemDto = {
        ...newErrors.responseItemDto,
        url: 'URL is required',
      };
    }

    // ... Add more validation rules for responseItemDto

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitClicked(true);
    if (validateForm()) {
      try {
        onSubmit(externalSearchSource);
        SetExternalSearchSource(initialSearchActualSource);
      } catch (error) {
        console.error('Error updating data:', error);
      }
      console.log('Submitted data:', externalSearchSource);
    }
    setIsSubmitClicked(false);
  };

  const handleConfigurationValidation = (e: FormEvent) => {
    e.preventDefault();
    setIsValidatedClicked(true);
    if (externalSearchSource && externalSearchSource.id) {
      try {
        dispatch(externalSearchActions.postExternalSearchApiValidation(externalSearchSource.id));
      } catch (error) {
        console.error('Error updating data:', error);
      }
      console.log(
        'Submitted External Search Source for validation:',
        externalSearchSource.name,
        externalSearchSource.id,
      );
    }
    // setIsValidatedClicked(false);
  };

  const handleCloning = (e: FormEvent) => {
    e.preventDefault();
    setIsCloneClicked(true);
    if (externalSearchSource && externalSearchSource.id) {
      try {
        dispatch(externalSearchActions.postExternalSearchApiCloning(externalSearchSource.id));
        dispatch(externalSearchActions.getExternalSearchSourceLightList(paginationConfig.page + 1));
        // if (externalSearchActualSource && externalSearchActualSource.id) {
        //   navigate(
        //     `RoutePath.externalSearchSource/${externalSearchActualSource.id}`
        //   );
        // }
        setIsValidatedClicked(false);
      } catch (error) {
        console.error('Error updating data:', error);
      }
    }
  };

  const handleConfirm = (e: React.FormEvent) => {
    e.preventDefault();
    openModal();
  };

  const handleDelete = (e: FormEvent) => {
    e.preventDefault();
    setIsDeleteClicked(true);
    if (externalSearchSource && externalSearchSource.id) {
      try {
        dispatch(externalSearchActions.deleteExternalSearchApi(externalSearchSource.id));
        dispatch(externalSearchActions.getExternalSearchSourceLightList(paginationConfig.page + 1));
        navigate(RoutePath.externalSearchSource);
        SetExternalSearchSource(initialSearchActualSource);
      } catch (error) {
        console.error('Error deleting data:', error);
      }
      console.log('Submitted External Search Source for deleting:', externalSearchSource.name, externalSearchSource.id);
    }
    closeModal();
  };

  const handleReset = (e: FormEvent) => {
    e.preventDefault();
    SetExternalSearchSource(initialSearchActualSource);
    navigate(RoutePath.externalSearchSource);
    // setErrors((prevErrors) => ({ ...prevErrors }));
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Display fields for top-level data */}
      {/* ... (same as before) */}
      <div className="w-full">
        <div className="mt-3">
          <label className="font-bold text-2xl lg:ml-20 sm:ml-40">External Search Source Form</label>
        </div>
        <div className="flex flex-row justify-between w-full">
          <div className="p-3 my-1 ">
            <label htmlFor="Url" className="mb-1 mt-4 block text-xs text-gray-600 font-sansInte">
              name:
            </label>
            <div className="flex flex-row gap-x-2 ">
              <Input
                id="name"
                type="text"
                size="sm"
                placeholder="name"
                value={externalSearchSource.name}
                error={!!errors?.name}
                onChange={(e) => onChange('name', e.target.value)}
              />
              <input id="id" type="hidden" />
              {errors && errors.name && <div className="error">{errors.name}</div>}
              {isValidatedClicked && isValidatedExternalApi ? (
                <span className="my-0 text-4xl text-green-600"> &#x2611;</span>
              ) : (
                <span className="my-0 text-4xl text-red-600"> &#x237B;</span>
              )}
            </div>
          </div>
          <div className="my-10 mx-1 flex flex-between gap-6 w-min  float-right ">
            <MainButton
              isLoading={isCloneClicked && isCloningExternalSearchSource}
              text={'Clone'}
              onClick={handleCloning}
              className="px-2 py-2 w-24"
              stateProp="rest"
              disabled={isStringEmpty(externalSearchSource?.id)}
            />
            <MainButton
              isLoading={isValidateExternalSearchSource && isValidatedClicked}
              text={'Validate'}
              onClick={handleConfigurationValidation}
              className="px-2 py-2"
              stateProp="rest"
              disabled={isStringEmpty(externalSearchSource?.id)}
            />
            <MainButton
              isLoading={isDeleteClicked && isDeleteExternalSearchSource}
              text={'Delete'}
              onClick={handleConfirm}
              className="px-2 py-2 w-24"
              stateProp="rest"
              disabled={isStringEmpty(externalSearchSource?.id)}
            />
          </div>
        </div>
        <div className="flex justify-evenly sm:flex-col lg:flex-row  my-2  w-full">
          {/* Display fields for requestItemDto */}
          <RequestItemFields
            requestItem={externalSearchSource.requestItemDto}
            onChange={(field, value) => handleRequestItemChange('requestItemDto', field, value)}
            errors={errors.requestItemDto}
          />

          {externalSearchSource.childRequestItemDto && (
            //externalSearchSource.childRequestItemDto.apiUrl &&
            <RequestItemFields
              requestItem={externalSearchSource.childRequestItemDto}
              onChange={(field, value) => handleRequestItemChange('childRequestItemDto', field, value)}
              errors={errors.childRequestItemDto}
            />
          )}

          {/* Display fields for responseItemDto */}
          <ResponseItemFields
            responseItem={externalSearchSource.responseItemDto}
            onChange={(field, value) => handleResponseItemChange(field, value)}
            errors={errors.responseItemDto}
          />
        </div>
        <div className="my-8 flex flex-between gap-6 w-min  float-right ">
          <MainButton
            isLoading={isLoadingDone && isSubmitClicked}
            text={'Submit'}
            onClick={handleSubmit}
            className="px-5 py-2"
            stateProp="rest"
            disabled={!isStringEmpty(errors?.name) || !externalSearchSource.name}
          />
          <MainButton
            isLoading={false}
            text={'Reset'}
            onClick={handleReset}
            className="px-5 py-2"
            stateProp="rest"
            disabled={false}
          />
          {
            <SecondaryButton
              className="px-3 py-2"
              stateProp="rest"
              onClick={() => {
                navigate(RoutePath.Landing);
              }}
              text="Cancel"
            />
          }
        </div>
      </div>
      <div>
        <ConfirmModal isShown={isShown} onClose={closeModal} onConfirm={handleDelete} />
      </div>
    </form>
  );
};

export default ExternalSearchApiForm;
