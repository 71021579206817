import { EmailValidateRequest } from '../../common/password-recover/models/email-validate-request';
import { EmailValidateResponse } from '../../common/password-recover/models/email-validate-response';
import { PasswordResetRequest } from '../../common/password-recover/models/password-reset-request';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';
import { PasswordRecoveryStage } from '../slices/password-recovery.slice';
import { ChangePasswordRequest } from '../../common/password-recover/models/change-password-request';

export const PASSWORD_RECOVERY_STORE_NAME = 'password_recover';

const createPayloadAction = getCreatePayloadActionFromStore(PASSWORD_RECOVERY_STORE_NAME);
const createAction = getCreateActionFromStore(PASSWORD_RECOVERY_STORE_NAME);

const setPasswordRecoveryRequest = createPayloadAction<string>('set password recovery request');
const setPasswordRecoveryRequestSuccess = createAction('set password recovery request success');
const setPasswordRecoveryRequestFail = createPayloadAction<any>('set password recovery request fail');

const resendPasswordRecoveryRequest = createPayloadAction<string>('resend password recovery request');
const resendPasswordRecoveryRequestSuccess = createAction('resend password recovery request success');
const resendPasswordRecoveryRequestFail = createPayloadAction<any>('resend password recovery request fail');

const setEmailValidate = createPayloadAction<EmailValidateRequest>('set email validate');
const setEmailValidateSuccess = createPayloadAction<EmailValidateResponse>('set email validate success');
const setEmailValidateFail = createPayloadAction<any>('set email validate fail');

const resetPassword = createPayloadAction<PasswordResetRequest>('reset password');
const resetPasswordSuccess = createAction('reset password success');
const resetPasswordFail = createPayloadAction<any>('reset password fail');

const showStage = createPayloadAction<PasswordRecoveryStage>('show stage');
const resetValidationProcess = createAction('reset validation');

const showNextStage = createAction('show next stage');
const showErrorStage = createAction('show error stage');

const changePassword = createPayloadAction<ChangePasswordRequest>('change password');
const changePasswordSuccess = createAction('change password success');
const changePasswordFail = createPayloadAction<any>('change password fail');

const updateUserEmail = createPayloadAction<string>('update user email for password recovery');
const updateUserEmailSuccess = createAction('update user email success');
const updateUserEmailFail = createPayloadAction<any>('update user email fail');

export const passwordRecoveryActions = {
  setPasswordRecoveryRequest,
  setPasswordRecoveryRequestSuccess,
  setPasswordRecoveryRequestFail,
  setEmailValidate,
  setEmailValidateSuccess,
  setEmailValidateFail,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFail,
  showStage,
  resetValidationProcess,
  showNextStage,
  showErrorStage,
  resendPasswordRecoveryRequest,
  resendPasswordRecoveryRequestSuccess,
  resendPasswordRecoveryRequestFail,
  changePassword,
  changePasswordSuccess,
  changePasswordFail,
  updateUserEmail,
  updateUserEmailSuccess,
  updateUserEmailFail,
};
