interface ActionItemProps {
  icon: JSX.Element;
  text: string;
  onClick?: () => void;
  isVisible?: boolean;
  textClass?: string;
  isPrefix?: boolean;
}

export const ActionItem: React.FC<ActionItemProps> = ({
  icon,
  text,
  onClick,
  isVisible = true,
  isPrefix = false,
  textClass = '',
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="input-action-item" onClick={onClick}>
      {isPrefix && <div className={`text-wrapper-29 ${textClass}`}>{text}</div>}
      <div className="icon-wrapper">
        <div className="text-wrapper-30">{icon}</div>
      </div>
      {!isPrefix && <div className={`text-wrapper-29 ${textClass}`}>{text}</div>}
    </div>
  );
};
