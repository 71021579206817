import { useSelector } from 'react-redux';
import WaitingMessage from '../../components/Base/WaitingMessage';
import ProgressBar from '../../components/Progressbar/Progressbar';
import {
  processingSummerizationInputSelector,
  isProcessingSummarizationSelector,
  currentSummerizationIdSelector,
} from '../../redux/selectors/summarization.selectors';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { useDispatch } from 'react-redux';

export const SummarizationProcessing: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const summmarizationOptions = useSelector(processingSummerizationInputSelector);
  const showProgressBar = useSelector(isProcessingSummarizationSelector);
  const [processingInputs, setProcessingInputs] = useState<string[]>([]);
  const currentId = useSelector(currentSummerizationIdSelector);
  useEffect(() => {
    window.scrollTo(0, 0);
    //To show used models in the processing message
    if (summmarizationOptions) {
      let inputs = [...summmarizationOptions.mode_of_summary];
      setProcessingInputs(inputs);
    }
  }, [summmarizationOptions, dispatch]);

  const handleProgressNavigation = () => {
    navigate(`${RoutePath.Summarization}/${currentId}`);
  };
  return (
    <div className="p-10 w-full">
      <div className="flex text-center  w-full mt-10 mb-5 animate__animated animate__zoomIn">
        <div className="w-full">
          <ProgressBar
            stop={!showProgressBar}
            subText={`${processingInputs}`}
            message={`Processing outputs of selected models`}
            navigate={handleProgressNavigation}
          />
        </div>
      </div>
      <div>
        <WaitingMessage />
      </div>
    </div>
  );
};
