import { HttpClient } from '../http';
import {
  ArticleUpdateRequest,
  HttpErrorResponse,
  LLMRequest,
  LLMRequestWithoutHistory,
  LLMResultLite,
  PromptOptimizerLiteRequest,
  PromptOptimzerRequest,
} from '../../../types/models';
import {
  LLMResponse,
  LLMResponseWithoutHistory,
  PromptOptimizerLiteResponse,
  PromptOptimzerResponse,
} from './model/llm-response.model';

class LLMServiceImpl {
  constructor() {}
  processPromptWithoutHistory(request: LLMRequestWithoutHistory): Promise<LLMResponseWithoutHistory> {
    let baseURL = '/llm';
    return HttpClient.post<LLMResponseWithoutHistory>(baseURL, request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  processPrompt(request: LLMRequest): Promise<LLMResponse> {
    let baseURL = '/llms';
    return HttpClient.post<LLMResponse>(baseURL, request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  clearHistory(): Promise<boolean> {
    return HttpClient.post<boolean>('/llm/clear-history', {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  getPromptById(id: string): Promise<LLMResponse> {
    let baseURL = '/llm';
    return HttpClient.get<LLMResponse>(`/${baseURL}/${id}`, { portNumber: 5001 }).then((response) => response.data);
  }

  getPromptsList(page: number = 1, take: number = 10): Promise<LLMResultLite> {
    let baseURL = '/llm';
    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}`;
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<LLMResultLite>(paginationEndpoint, { portNumber: 5001 }).then((response) => response.data);
  }

  updatePrompt(changes: ArticleUpdateRequest): Promise<LLMResponse> {
    let baseURL = '/llm';

    let request = {
      isFavorite: changes.isFavorite.toString(),
      progressPercentage: '100',
    };

    return HttpClient.put<LLMResponse>(`${baseURL}/${changes.id}`, request, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  deletePrompt(id: string): Promise<void> {
    let baseURL = '/llm';

    return HttpClient.delete<void>(`${baseURL}/${id}`, { portNumber: 5001 })
      .then(() => {})
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  promptOptimizer(request: PromptOptimzerRequest): Promise<PromptOptimzerResponse> {
    let baseURL = '/llm/qualitative_optimization';
    return HttpClient.post<PromptOptimzerResponse>(baseURL, request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  promptOptimizerLite(request: PromptOptimizerLiteRequest): Promise<PromptOptimizerLiteResponse> {
    let baseURL = '/llm/prompt_optimization';
    return HttpClient.post<PromptOptimizerLiteResponse>(baseURL, request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
}

const LLMService = new LLMServiceImpl();

export { LLMService };
