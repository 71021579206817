import React, { useState } from 'react';
import { extractMainDomain, extractBaseUrl } from '../../common/helpers/Helper';
import { Result } from '../../types/models';
import { ASSERTION, SOURCES_FOUND } from '../../common/constants/app-text';
import { ClipboardIcon, InfoIcon, LinkIcon } from '../../common/icons/Icons';
import { SourceType } from '../../common/constants/enums/engine-types.enum';
import ClipToProjectModal from '../ClipToProjectModal/ClipToProjectModal';
import ExpandableParagraph from '../ExpandableParagraph/ExpandableParagraph';

interface Props {
  assertion?: any;
  assertionCount: number;
  showClipToProject?: boolean;
  createdAt?: any;
  sourceType?: SourceType;
}

interface AssertionResultItemProps {
  result: Result;
}

const AssertionResultItem: React.FC<AssertionResultItemProps> = React.memo(({ result }: AssertionResultItemProps) => {
  const mainDomain = extractMainDomain(result.source);
  return (
    <tr className="border-t border-gray-200 border-solid">
      {/* Source */}
      <td className="align-top sticky left-0 bg-white">
        <div className="p-2">
          <span className="flex">
            <InfoIcon />
            <div className="ml-1">
              <p className="text-lg -mt-[5px] pt-0">{mainDomain}</p>
              <p className="text-xs text-gray-400">{extractBaseUrl(result.source)}</p>
            </div>
          </span>
          <div className="flex items-center text-sm mt-1">
            <div className="min-w-4 mr-2">
              <LinkIcon />
            </div>
            <a className="text-primary-blue text-xs !line-clamp-1" target="blank" href={result.source}>
              {result.source}
            </a>
          </div>
        </div>
      </td>
      {/* Excerpt */}
      <td className="align-top">
        <ExpandableParagraph text={result.excerpt} className="text-gray-400 text-xs p-2 pb-0" />
      </td>
      {/* Score */}
      <td className="align-top">
        <div className="flex items-end flex-col pt-3">
          <div className="text-sm bg-gray-200 px-3 py-2 rounded-md text-gray-500">{result.score.toFixed(2)}</div>
        </div>
      </td>
    </tr>
  );
});

export const AssertionResultsList = ({
  assertion,
  assertionCount,
  showClipToProject,
  createdAt,
  sourceType,
}: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);

  const assertionsList = [assertion];

  return (
    <div className="assertion-container-main">
      <div className="assertion-container">
        <div className="flex items-center space-x-4 mt-3">
          <div className="text-black font-bold text-lg">
            {ASSERTION} {assertionCount}
          </div>
          <div className="text-purple-600 text-sm">
            {assertion?.topRanks.length} {SOURCES_FOUND}
          </div>
        </div>
      </div>

      {assertionsList?.map((assert: any, index: any) => (
        <React.Fragment key={index}>
          <div className="flex items-center justify-between space-x-4 mt-3">
            <div className="p-wrapper" style={{ backgroundColor: assert?.colorCode }}>
              <p className="p whitespace-pre-wrap break-words ">{assertion?.articleName}</p>
            </div>
            {showClipToProject && (
              <>
                <ClipToProjectModal
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  assertion={assertion}
                  createdAt={createdAt}
                  sourceType={sourceType}
                />
                <div
                  className="input-action-item pt-4 pl-4 pr-4 pb-4 flex-shrink-0"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  <div className="text-wrapper-27 flex items-center">
                    <span className="mr-2">Clip to Project</span> <ClipboardIcon />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="relative mb-10 mt-4 overflow-x-scroll">
            <table>
              <thead>
                <th className="assertion-warpper text-start p-2 pb-4 sticky left-0 bg-white">SOURCE</th>
                <th className="assertion-warpper text-start p-2">EXCERPT</th>
                <th className="assertion-warpper text-end pt-2 pb-2 flex items-center">
                  SCORE
                  <InfoIcon />
                </th>
              </thead>
              <tbody>
                {assert.topRanks?.map((result: Result, index: number) => (
                  <AssertionResultItem result={result} key={index} />
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
