import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';

export interface CodeMirrorInputProps {
  code?: string;
  hideTitle?: boolean;
  disabled?: boolean;
  maxHeight?: string;
  onChange?: (newCode: string) => void;
}
export const CodeMirrorInput: React.FC<CodeMirrorInputProps> = ({
  code = '',
  hideTitle = false,
  maxHeight = '500px',
  onChange,
  disabled = false,
}: CodeMirrorInputProps) => {
  return (
    <div className="border-b border-gray-200 mb-2 animate__animated animate__fadeInUp ">
      {!hideTitle && (
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">Code</h2>
          <i className="far fa-copy cursor-pointer"></i>
        </div>
      )}

      <div className="flex flex-col rounded mt-4 w-100 relative">
        <CodeMirror
          autoFocus={true}
          placeholder={disabled ? 'No Code Snippet Found' : 'Input your code here'}
          value={code}
          minHeight="100px"
          maxHeight={maxHeight}
          extensions={[javascript({ jsx: true })]}
          onChange={(value, viewUpdate) => {
            console.log('value:', value);
            if (onChange) {
              onChange(value);
            }
          }}
        />
        {disabled && (
          <div
            style={{
              // margin: '16px',
              position: 'absolute',
              top: 0,
              borderRadius: '10px',
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(86, 96, 124, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></div>
        )}
      </div>
    </div>
  );
};
