import { useSelector } from 'react-redux';
import { ContentInput } from '../../../components/ContentInput';
import { Header } from '../../../components/Header';
import { processingInput } from '../../../redux/selectors/article.selectors';
import { GuardRailEngineType } from '../../../common/constants/enums/engine-types.enum';
import { PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER } from '../../../common/constants/app-text';
import { ArticleResultViewContentProps } from '../../Landing/ArticleResultView';
import { processingContradictionInput } from '../../../redux/selectors/contradiction.selectors';

export const ArticleProcess: React.FC<ArticleResultViewContentProps> = ({
  engineType = GuardRailEngineType.FactChecker,
}) => {
  let userProcessingInput = useSelector(
    engineType === GuardRailEngineType.FactChecker ? processingInput : processingContradictionInput,
  );
  return (
    <div className="landing">
      <div className="div">
        <div>
          <div className="md:w-7/10 w-full relative">
            <ContentInput
              placeHolderText={PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER}
              showTextInput={true}
              engineType={engineType}
              viewType="Processing"
              userProcessingInput={userProcessingInput}
            ></ContentInput>
          </div>
        </div>
      </div>
    </div>
  );
};
