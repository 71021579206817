import { Divider } from '../../components/Divider/Divider';
import { useEffect, useState } from 'react';
import { Tabs } from 'flowbite-react';

import { MainButton } from '../../components/MainButton';
import InfoCheckbox from '../../components/InfoCheckbox';
import { TextArea } from '../../components/Input';
import {
  PLACEHOLDER_FOR_USERINPUT_SUMMARIZATION,
  PLACEHOLDER_FOR_USERINPUT_EXISTING_SUMMARY,
} from '../../common/constants/app-text';
import { FileDropZone } from '../../components/FileDropZone';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { useDispatch } from 'react-redux';

import { SummarizationActions } from '../../redux/actions/summarization.actions';
import HorizontalStepper from '../../components/Stepper/Stepper';
import { stepperSteps } from '../../common/constants/summarization';

export const SummarizationInput = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [summaryInput, setSummaryInput] = useState<string>('');
  const [existingSummaryText, setExistingSummaryText] = useState<string>('');
  const [existingSummary, setExistingSummary] = useState<boolean>(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleClick = () => {
    dispatch(
      SummarizationActions.saveSummarizationInput({
        extracted_text: summaryInput,
        reference_summary: existingSummaryText,
        mode_of_summary: [],
      }),
    );
    navigate(RoutePath.SummarizationMethods);
  };
  return (
    <div className="flex justify-center my-10">
      <div className="bg-white w-4/5">
        <HorizontalStepper steps={stepperSteps} completedStep={1} />
        <Tabs
          theme={{
            tablist: {
              tabitem: {
                base: 'flex justify-center items-center mr-1 border p-2.5 text-sm font-medium first:ml-0 focus:ring-0 focus:outline-none focus:border-b-0 rounded-t-lg border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 border-gray-200 ',
              },
            },
          }}
          color="white"
          className="border-b border-gray-300 "
        >
          <Tabs.Item title="Text Input" className="p-2 m-2">
            <TextArea
              content={summaryInput}
              setContent={setSummaryInput}
              placeHolderText={PLACEHOLDER_FOR_USERINPUT_SUMMARIZATION}
            />
          </Tabs.Item>
          {/* <Tabs.Item title="File Upload" className="p-2 m-2">
          <FileDropZone />
        </Tabs.Item> */}
        </Tabs>
        <Divider />
        <InfoCheckbox
          checkboxLabel="I do have an existing summary"
          description="  You can provide an existing summary to compare with the generated ones after running Guardrail’s Summarization Engine."
          title="Do you have an existing summary?"
          isChecked={existingSummary}
          onCheckboxChange={(event) => setExistingSummary(event.target.checked)}
        />
        <Divider />
        {existingSummary && (
          <TextArea
            content={existingSummaryText}
            setContent={setExistingSummaryText}
            placeHolderText={PLACEHOLDER_FOR_USERINPUT_EXISTING_SUMMARY}
            showTextInput
          />
        )}
        <div className="w-100 flex items-end justify-end">
          <MainButton onClick={handleClick} disabled={!summaryInput} className="" stateProp="rest" text="Continue" />
        </div>
      </div>
    </div>
  );
};
