import React from 'react';
import { InfoIcon } from '../../common/icons/Icons';
import Typography from '../Typography/Ty[pgraphy';

interface CheckboxWithLabelProps {
  label: string;
  showInfoIcon: boolean;
  tooltipContent?: string;
  isChecked?: boolean;
  onInfoClick?: () => void;
  description?: string;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  isChecked = false,
  label,
  showInfoIcon,
  onInfoClick,
  tooltipContent = '',
  onCheckboxChange,
  description = '',
}) => {
  return (
    <div className="flex gap cursor-pointe items-start py-3">
      <input
        id="vue-checkbox-list"
        type="checkbox"
        checked={isChecked}
        onChange={onCheckboxChange}
        className="w-5 h-5 mx-4 mt-0.5 bg-white text-blue-600 ring-mid-gray rounded focus:ring-blue-500" />
      <div className="flex flex-col mx-2">
        <label
          htmlFor="vue-checkbox-list"
          className="w-full cursor-pointe  text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {label}
        </label>
        <Typography variant='p' className={`text-xs text-gray-500 ${description ? 'mt-2' : ''}`}>{description}</Typography>
      </div>

      {showInfoIcon && (
        <div className="pr-4" onClick={onInfoClick} title={tooltipContent}>
          <InfoIcon />
        </div>
      )}
    </div>
  );
};

export default CheckboxWithLabel;
