import React, { ReactNode } from 'react';
import { DynamicTitle } from './DynamicTitle';
import { DynamicTitleDescriptionLayout } from './DynamicTitleDescriptionLayout';

interface ElementToRender {
  title: string;
  description: string;
}

interface DynamicTitleDescriptionLayoutProps {
  title: string;
  icon: ReactNode;
  items: ElementToRender[];
}
export const DynamicTitleDescriptionLayoutWithTitle: React.FC<DynamicTitleDescriptionLayoutProps> = ({
  title,
  items,
  icon,
}) => {
  return (
    <div className="max-w-screen-xl p-2 sm:px-2 sm:py-4 lg:px-2 lg:py-2 animate__animated animate__fadeInUp">
      <DynamicTitle title={title} />
      {items.length > 0 ? (
        <DynamicTitleDescriptionLayout icon={icon} items={items} />
      ) : (
        <div className="text-center">No data to display</div>
      )}
    </div>
  );
};
