import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { SummarizationState } from '../slices/summarization.slice';

const selectSummarizationState: (state: AppState) => SummarizationState = (state) => state.summarization;
export const currentSummerizationInputSelector = createSelector(
  selectSummarizationState,
  ({ inputDetails }) => inputDetails,
);
export const currentSummarizationSelector = createSelector(
  selectSummarizationState,
  ({ currentSummarization }) => currentSummarization,
);
export const currentSummerizationIdSelector = createSelector(
  selectSummarizationState,
  ({ currentSummarization }) => currentSummarization.id,
);
export const isProcessingSummarizationSelector = createSelector(
  selectSummarizationState,
  ({ isProcessingSummarization }) => isProcessingSummarization,
);
export const processingSummerizationInputSelector = createSelector(
  selectSummarizationState,
  ({ inputDetails }) => inputDetails,
);
export const favouriteSummarizationListSelector = createSelector(
  selectSummarizationState,
  ({ favouriteSummarizations }) => favouriteSummarizations,
);
export const favouriteSummarizationPaginationSelector = createSelector(
  selectSummarizationState,
  ({ historyPaginationConfig }) => historyPaginationConfig,
);
export const favoritesSummarizationCountSelector = createSelector(
  selectSummarizationState,
  ({ favoriteSummarizationCount }) => favoriteSummarizationCount,
);
export const binaryMetricsSelector = createSelector(selectSummarizationState, ({ binaryMetrics }) => binaryMetrics);

export const summarizationErrorCodesSelector = createSelector(selectSummarizationState, ({ errorCode }) => errorCode);
