import { GuardRailEngineType } from '../../../common/constants/enums/engine-types.enum';

export const getDeleteMessage = (engineType: any) => {
  switch (engineType) {
    case GuardRailEngineType.FactChecker:
    case GuardRailEngineType.Federated:
      return 'Are you sure you want to delete this Article?';
    case GuardRailEngineType.PromptProtect:
    case GuardRailEngineType.ContractionFinder:
      return 'Are you sure you want to delete this Prompt?';
    case GuardRailEngineType.Dossier:
      return 'Are you sure you want to delete this Dossier?';
    case GuardRailEngineType.CodeAnalyzer:
      return 'Are you sure you want to delete this Code Analysis?';
    case GuardRailEngineType.Summarization:
      return 'Are you sure you want to delete this Summarization?';
    case GuardRailEngineType.Projects:
      return 'Are you sure you want to delete this Project?';
    case GuardRailEngineType.ProjectAssertion:
      return 'Are you sure you want to remove this assertion from project?';
    default:
      return 'Are you sure you want to delete this Article?';
  }
};
