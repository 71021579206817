import { useSelector } from 'react-redux';
import { ContentInput } from '../../ContentInput';
import { Header } from '../../Header';
import { GuardRailEngineType } from '../../../common/constants/enums/engine-types.enum';
import { dossierProcessingInput } from '../../../redux/selectors/dossier.selectors';
import { dossierOrgProcessingInput } from '../../../redux/selectors/dossier-org.selectors';
import { PLACEHOLDER_FOR_USERINPUT_DOSSIER_GENERATOR } from '../../../common/constants/app-text';

export const DossierProcess = () => {
  let userProcessingInput = useSelector(dossierProcessingInput);
  return (
    <div className="landing">
      <div className="div">
        <div>
          <div className="md:w-7/10 w-full relative">
            <ContentInput
              showTextInput={true}
              placeHolderText={PLACEHOLDER_FOR_USERINPUT_DOSSIER_GENERATOR}
              engineType={GuardRailEngineType.Dossier}
              viewType="Processing"
              userProcessingInput={userProcessingInput}
            ></ContentInput>
          </div>
        </div>
      </div>
    </div>
  );
};
