import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { UserPreferencesState } from '../slices/user-preference.slice';

const selectUserPreferenceState: (state: AppState) => UserPreferencesState = (state) => state.userPreference;

export const preferenceDetails = createSelector(selectUserPreferenceState, ({ preferences }) => preferences);
export const timeZone = createSelector(selectUserPreferenceState, ({ preferences }) => preferences.timeZone);
export const preferenceFlags = createSelector(
  selectUserPreferenceState,
  ({ preferences }) => preferences.preferenceFlagsDto,
);
export const userPreferences = createSelector(
  selectUserPreferenceState,
  ({ preferenceFlagsDto }) => preferenceFlagsDto,
);
export const numberofRecords = createSelector(
  selectUserPreferenceState,
  ({ preferenceFlagsDto }) => preferenceFlagsDto.numberofRecords,
);

export const defaultLLM = createSelector(
  selectUserPreferenceState,
  ({ preferenceFlagsDto }) => preferenceFlagsDto.defaultLLM,
);

export const cacheResults = createSelector(
  selectUserPreferenceState,
  ({ preferenceFlagsDto }) => preferenceFlagsDto.cacheResults,
);
