import React from 'react';
import { getRandomColor } from '../../common/helpers/Helper';

interface AvatarProps {
  name: string;
}

export const AvatarWithName: React.FC<AvatarProps> = ({ name = '' }) => {
  const color = getRandomColor();

  return (
    <div className={`h-10 w-10 rounded-full bg-${color}-500 flex items-center justify-center text-white`}>
      {name.charAt(0).toUpperCase()}
    </div>
  );
};
