import React, { useState } from 'react';

interface NewCustomPatternLibraryProps {
  onSave: (name: string) => void;
  onCancel: () => void;
}

const NewCustomPatternLibrary: React.FC<NewCustomPatternLibraryProps> = ({ onSave, onCancel }) => {
  const [name, setName] = useState('');

  const handleSave = () => {
    onSave(name);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <h2 className="text-lg font-semibold mb-4">New Custom Pattern Library</h2>
      <div className="mb-4">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Pattern Library Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      <div className="flex justify-end space-x-4">
        <button
          className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default NewCustomPatternLibrary;