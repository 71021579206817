import { createSlice } from '@reduxjs/toolkit';
import { Summarization_STORE_NAME, SummarizationActions } from '../actions/summarization.actions';
import { mapPromptToFavorite } from '../../common/helpers/Helper';
import {
  FavoriteArticles,
  PaginationConfig,
  ProcessSummaryRequest,
  SummarizationLite,
  SummaryComparisonResponse,
  UserHistoryList,
} from '../../types/models';

export interface SummarizationState {
  favoriteSummarizationCount: number;
  isFavoriteEnabled: boolean;
  isProcessingSummarization: boolean;
  isProcessingSummary: boolean;
  favouriteSummarizations: FavoriteArticles[];
  history: UserHistoryList[];
  currentSummarization?: SummarizationLite | any;
  inputDetails: ProcessSummaryRequest;
  historyPaginationConfig: PaginationConfig;
  errorCode: string;
  isDeleting: boolean;
  isComparing: boolean;
  binaryMetrics: SummaryComparisonResponse | null;
}

const initialState: SummarizationState = {
  favoriteSummarizationCount: 0,
  isFavoriteEnabled: false,
  isProcessingSummarization: false,
  isProcessingSummary: false,
  favouriteSummarizations: [],
  history: [],
  //List of summaries
  currentSummarization: {},
  //Summary Details
  inputDetails: {
    extracted_text: '',
    reference_summary: '',
    mode_of_summary: [],
  },
  historyPaginationConfig: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  errorCode: '',
  isDeleting: false,
  isComparing: false,
  binaryMetrics: null,
};
export const summerizationSlice = createSlice({
  name: Summarization_STORE_NAME,
  initialState,
  reducers: {
    processSummerization: (state, action) => {
      state.isProcessingSummarization = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // Store user Input
      .addCase(SummarizationActions.saveSummarizationInput, (state, { payload }) => {
        state.inputDetails = payload;
      })
      // Get methods and call the API
      .addCase(SummarizationActions.processSummarization, (state, { payload }) => {
        state.isProcessingSummarization = true;
        state.inputDetails.mode_of_summary = payload.mode_of_summary;
      })
      // Processed Successfully
      .addCase(SummarizationActions.processSummarizationSuccess, (state, { payload }) => {
        state.isProcessingSummarization = false;
        state.currentSummarization = payload;
        state.errorCode = '';
        let index = state.history.findIndex((e) => e.id === payload.id);
        if (index < 0) {
          state.history.unshift(payload);
          var list = state.history;
          state.favouriteSummarizations = mapPromptToFavorite(list);
        }
      })
      // Processing Failed
      .addCase(SummarizationActions.processSummarizationFailure, (state, { payload }) => {
        state.isProcessingSummarization = false;
        state.errorCode = payload.message;
      })
      // Transform the favourites list for left side menu
      .addCase(SummarizationActions.toggleFavoriteSummarizations, (state, { payload }) => {
        state.isFavoriteEnabled = payload;
        var list = state.history;
        if (state.isFavoriteEnabled) {
          list = state.history.filter((summarization) => summarization.isFavorite === true);
        }
        state.favouriteSummarizations = mapPromptToFavorite(list);
        state.favoriteSummarizationCount = state.history.filter((summarization) => summarization.isFavorite).length;
      })
      // Add new favourite to the list
      .addCase(SummarizationActions.setSummarizationFavoriteSuccess, (state, { payload }) => {
        state.currentSummarization = payload;
        let index = state.history.findIndex((e) => e.id === payload.id);
        state.history[index].isFavorite = payload.isFavorite ?? false;
        var list = state.history;
        if (state.isFavoriteEnabled) {
          list = state.history.filter((summarization) => summarization.isFavorite === true);
        }
        state.favouriteSummarizations = mapPromptToFavorite(list);
        state.favoriteSummarizationCount = state.isFavoriteEnabled
          ? state.favouriteSummarizations.length
          : state.history.filter((summarization) => summarization.isFavorite === true).length;
      })
      // Called in next() of infinite scroll of UserAssertionList
      .addCase(SummarizationActions.getSummarizationsList, (state, { payload }) => {
        state.isProcessingSummarization = true;
      })
      // Transform the favourites list with new and more data
      .addCase(SummarizationActions.getSummarizationsListSuccess, (state, { payload }) => {
        state.history = state.history.concat(payload.data ?? []);
        var list = state.history;
        if (state.isFavoriteEnabled) {
          list = state.history.filter((summarization) => summarization.isFavorite === true);
        }
        state.favouriteSummarizations = mapPromptToFavorite(list);
        state.favoriteSummarizationCount = state.isFavoriteEnabled
          ? state.favouriteSummarizations.length
          : state.history.filter((summarization) => summarization.isFavorite === true).length;
        state.historyPaginationConfig = payload.meta;
        state.isProcessingSummarization = false;
      })
      // API call failed
      .addCase(SummarizationActions.getSummarizationsListFailure, (state, { payload }) => {
        state.isProcessingSummarization = false;
      })
      // Get the summarization by id for results page
      .addCase(SummarizationActions.getSummarizationById, (state, { payload }) => {
        state.currentSummarization = null;
        state.isProcessingSummarization = true;
      })
      // Got the summarization by id
      .addCase(SummarizationActions.getSummarizationByIdSuccess, (state, { payload }) => {
        state.isProcessingSummarization = false;
        state.currentSummarization = payload;
        state.errorCode = '';
      })
      // Failed to get the summarization by id
      .addCase(SummarizationActions.getSummarizationByIdFailure, (state, { payload }) => {
        state.isProcessingSummarization = false;
        state.errorCode = payload.message;
      })
      .addCase(SummarizationActions.deleteSummarization, (state, { payload }) => {
        state.isDeleting = true;
      })
      .addCase(SummarizationActions.deleteSummarizationSuccess, (state, { payload }) => {
        state.isDeleting = false;
        if (state.currentSummarization?.id === payload) {
          state.currentSummarization = null;
        }
        state.history = state.history.filter((e) => e.id !== payload);
        let list = state.history;
        if (state.isFavoriteEnabled) {
          list = state.history.filter((summarization) => summarization.isFavorite === true);
        }
        state.favouriteSummarizations = mapPromptToFavorite(list);
        state.favoriteSummarizationCount = state.history.filter(
          (summarization) => summarization.isFavorite === true,
        ).length;
      })
      .addCase(SummarizationActions.deleteSummarizationFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(SummarizationActions.compareSummaries, (state, { payload }) => {
        state.binaryMetrics = null;
        state.isComparing = true;
      })
      .addCase(SummarizationActions.compareSummariesSuccess, (state, { payload }) => {
        state.isComparing = false;
        state.binaryMetrics = payload;
      })

      .addCase(SummarizationActions.clearSummaryHistorySuccess, (state) => {
        state.history = [];
        state.favouriteSummarizations = [];
        state.favoriteSummarizationCount = 0;
        state.historyPaginationConfig = {
          page: 1,
          take: 10,
          itemCount: 0,
          hasPreviousPage: false,
          hasNextPage: false,
        };
      })
      .addCase(SummarizationActions.compareSummariesFailure, (state, { payload }) => {
        state.isProcessingSummarization = false;
        state.errorCode = payload.message;
      });
  },
});

export const { processSummerization } = summerizationSlice.actions;
export const summarizationReducer = summerizationSlice.reducer;
