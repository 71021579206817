import { PROCESSING_WAITING_MESSAGE_USER } from '../../common/constants/app-text';
const WaitingMessage = () => {
  return (
    <div className="flex justify-center items-center mt-2 mb-2  animate__animated animate__fadeInUp">
      <div className={`flex flex-col md:flex-row justify-between py-4'}`}>
        <div className="flex space-x-6 items-center  mb-4 md:mb-0 md:mr-4">
          <p className="don-t-want-to-wait">
            <span className=" text-blue-600 font-bold">Don’t want to wait? </span>
            <span className="text-gray-500 font-['Open_Sans']"> {PROCESSING_WAITING_MESSAGE_USER} </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WaitingMessage;
