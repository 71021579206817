import { createSlice } from '@reduxjs/toolkit';
import { FEDERATED_STORE_NAME, federatedActions } from '../actions/federated.actions';
import { SourceType } from '../../common/constants/enums/engine-types.enum';
import {
  AssertionResult,
  FavoriteArticles,
  PaginationConfig,
  PromptProtect,
  ResultListLite,
  UserHistoryList,
  PromptOptimzerRequest,
} from '../../types/models';
import {
  LLMRequestOutput,
  LLMResponse,
  PromptOptimzerResponse,
} from '../../common/services/llm/model/llm-response.model';
import { mapLLMtToFavorite } from '../../common/helpers/Helper';
import { profileActions } from '../actions/profile.actions';

export interface llmsResponse {
  llms: SourceType[];
  prompt: string;
  response: LLMRequestOutput;
  id: string;
}

export interface federatedState {
  currentPrompt: string;
  currentFactCheckPrompt: string;
  isPromptProtectEnabled: boolean;
  isSpellCheckEnabled: boolean;
  isGrammarCheckEnabled: boolean;
  isPromptOptimierRunning: boolean;
  isPromptProtectRunning: boolean;
  enabledLLMTypes: SourceType[];
  processedFactChecker: AssertionResult | null;
  profanityFound: boolean;
  promptProtectTime: string;
  llmResponse: llmsResponse[];
  userFavoriteResults?: AssertionResult[];
  runningFactChecker: boolean;
  federatedPagination: PaginationConfig;
  processedPromptProtect?: PromptProtect | null;
  histroyList: UserHistoryList[];
  showPromptProtectModal: boolean;
  promptCheckPrompt: string;
  checkingForProfanity: boolean;
  currentActivePrompt: LLMResponse | null;
  fetchingActivePrompt: boolean;
  isPromptProcessing: boolean;
  isProcessingFactChecker: boolean;
  isRequestSubmitted: boolean;
  isFavoriteEnabled: boolean;
  favoritePrompts: FavoriteArticles[];
  favoritePromptsCount: number;
  isDeleting: boolean;
  errorCode: string;
  currentFactCheckerOriginalModel: SourceType | undefined;
  currentPromptOptimizer: PromptOptimzerResponse | any;
  promptOptimizerRequest: PromptOptimzerRequest | any;
  showPromptOptimserModel: boolean;
  promptOptimiserLLMResponse: string;
  optimisedPromptLLMResponse: string;
  processingPromptOptimizerLLM: boolean;
  processingOptimisedPromptLLM: boolean;
}

export const initialfederatedState: federatedState = {
  currentPrompt: '',
  isPromptProtectEnabled: false,
  currentFactCheckPrompt: '',
  processedFactChecker: null,
  promptCheckPrompt: '',
  isRequestSubmitted: false,
  isSpellCheckEnabled: false,
  isGrammarCheckEnabled: false,
  isPromptOptimierRunning: false,
  favoritePromptsCount: 0,
  currentActivePrompt: null,
  isProcessingFactChecker: false,
  isPromptProtectRunning: false,
  enabledLLMTypes: [],
  profanityFound: false,
  promptProtectTime: '',
  isPromptProcessing: false,
  histroyList: [],
  fetchingActivePrompt: false,
  userFavoriteResults: [],
  llmResponse: [],
  runningFactChecker: false,
  federatedPagination: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  processedPromptProtect: null,
  showPromptProtectModal: false,
  checkingForProfanity: false,
  isFavoriteEnabled: false,
  favoritePrompts: [],
  isDeleting: false,
  errorCode: '',
  currentFactCheckerOriginalModel: undefined,
  currentPromptOptimizer: {},
  promptOptimizerRequest: {},
  showPromptOptimserModel: false,
  promptOptimiserLLMResponse: '',
  optimisedPromptLLMResponse: '',
  processingPromptOptimizerLLM: false,
  processingOptimisedPromptLLM: false,
};

export const federatedSlice = createSlice({
  name: FEDERATED_STORE_NAME,
  initialState: initialfederatedState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(federatedActions.togglePromptProtectModal, (state, { payload }) => {
        state.showPromptProtectModal = payload;
      })
      .addCase(federatedActions.togglePromptOptimiserModel, (state, { payload }) => {
        state.showPromptOptimserModel = payload;
      })
      .addCase(federatedActions.getPromptById, (state, { payload }) => {
        state.currentActivePrompt = null;
        state.fetchingActivePrompt = true;
      })
      .addCase(federatedActions.setFactCheckerText, (state, { payload }) => {
        state.currentFactCheckPrompt = payload.prompt;
        state.currentFactCheckerOriginalModel = payload.sourceType;
      })
      .addCase(federatedActions.getUserPromptsListSuccess, (state, { payload }) => {
        state.histroyList = state.histroyList.concat(payload.data ?? []);
        var list = state.histroyList;
        if (state.isFavoriteEnabled) {
          list = state.histroyList.filter((Prompt, index, self) => Prompt.isFavorite === true);
        }
        state.favoritePrompts = mapLLMtToFavorite(list);
        state.favoritePromptsCount = state.isFavoriteEnabled
          ? state.favoritePrompts.length
          : state.histroyList.filter((Prompt, index, self) => Prompt.isFavorite === true).length;
        state.federatedPagination = payload.meta;
      })
      .addCase(federatedActions.getUserPromptsList, (state, { payload }) => {
        state.fetchingActivePrompt = true;
      })
      .addCase(federatedActions.getPromptByIdSuccess, (state, { payload }) => {
        state.currentActivePrompt = payload;
        state.fetchingActivePrompt = false;
      })

      .addCase(federatedActions.getPromptByIdFailure, (state, { payload }) => {
        state.currentActivePrompt = null;
        state.fetchingActivePrompt = false;
      })
      .addCase(federatedActions.processPrompt, (state, { payload }) => {
        state.currentPrompt = payload.prompt;
        state.isPromptProcessing = true;
        state.isRequestSubmitted = true;
        state.promptCheckPrompt = payload.prompt;
      })
      .addCase(federatedActions.processPromptSuccess, (state, { payload }) => {
        state.currentPrompt = '';
        state.llmResponse = [];
        state.currentActivePrompt = payload;
        state.llmResponse.push({
          id: payload.id,
          llms: payload.sourceTypes,
          prompt: payload.prompt,
          response: payload.output,
        });
        let index = state.histroyList.findIndex((e) => e.id === payload.id);
        if (index < 0) {
          let newPrompt = {
            ...payload,
            name: payload.prompt,
          };
          state.histroyList.unshift(newPrompt);
          var list = state.histroyList;
          state.favoritePrompts = mapLLMtToFavorite(list);
        }
        state.isPromptProcessing = false;
      })
      .addCase(federatedActions.toggleRequestSubmitted, (state, { payload }) => {
        state.isRequestSubmitted = payload;
      })
      .addCase(federatedActions.processArticleSuccess, (state, { payload }) => {
        state.processedFactChecker = payload;
        state.isProcessingFactChecker = false;
      })
      .addCase(federatedActions.processArticleReset, (state) => {
        state.processedFactChecker = null;
      })
      .addCase(federatedActions.processPromptFailure, (state, { payload }) => {
        state.llmResponse = [];
        state.isPromptProcessing = false;
      })
      .addCase(federatedActions.processPromptProtect, (state, { payload }) => {
        state.promptCheckPrompt = payload;
        state.checkingForProfanity = true;
      })
      .addCase(federatedActions.setPromptText, (state, { payload }) => {
        state.currentPrompt = payload;
        state.promptCheckPrompt = payload;
      })
      .addCase(federatedActions.processPromptProtectSuccess, (state, { payload }) => {
        state.checkingForProfanity = false;
        state.processedPromptProtect = payload;
      })
      .addCase(federatedActions.processArticle, (state, { payload }) => {
        state.isProcessingFactChecker = true;
        state.processedFactChecker = null;
      })
      .addCase(federatedActions.processPromptProtectFailure, (state, { payload }) => {
        state.checkingForProfanity = false;
      })
      .addCase(federatedActions.setPromptContent, (state, { payload }) => {
        state.isPromptProcessing = true;
        state.promptCheckPrompt = payload;
      })
      .addCase(federatedActions.processArticleFailure, (state, { payload }) => {
        state.isProcessingFactChecker = false;
        state.checkingForProfanity = false;
      })
      .addCase(federatedActions.deletePrompt, (state, { payload }) => {
        state.isDeleting = true;
        state.promptCheckPrompt = payload;
      })
      .addCase(federatedActions.deletePromptSuccess, (state, { payload }) => {
        state.isDeleting = false;
        if (state.currentActivePrompt?.id == payload) {
          state.currentActivePrompt = null;
        }
        state.histroyList = state.histroyList.filter((e) => e.id != payload);
        let records = state.histroyList;
        if (state.isFavoriteEnabled) {
          records = state.histroyList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoritePrompts = mapLLMtToFavorite(records);
        state.favoritePromptsCount = state.histroyList.filter(
          (article, index, self) => article.isFavorite === true,
        ).length;
      })
      .addCase(federatedActions.deletePromptFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(federatedActions.clearFederatedHistorySuccess, (state) => {
        state.histroyList = [];
        state.favoritePrompts = [];
        state.favoritePromptsCount = 0;
        state.federatedPagination = {
          page: 1,
          take: 10,
          itemCount: 0,
          hasPreviousPage: false,
          hasNextPage: false,
        };
      })
      .addCase(federatedActions.setPromptFavorite, (state, { payload }) => {})
      .addCase(federatedActions.setPromptFavoriteSuccess, (state, { payload }) => {
        state.currentActivePrompt = payload;
        let index = state.histroyList.findIndex((e) => e.id === payload.id);
        state.histroyList[index].isFavorite = payload.isFavorite;
        var list = state.histroyList;
        if (state.isFavoriteEnabled) {
          list = state.histroyList.filter((prompt, index, self) => prompt.isFavorite === true);
        }
        state.favoritePrompts = mapLLMtToFavorite(list);
        state.favoritePromptsCount = state.isFavoriteEnabled
          ? state.favoritePrompts.length
          : state.histroyList.filter((prompt, index, self) => prompt.isFavorite === true).length;
      })
      .addCase(federatedActions.setPromptFavoriteFailure, (state, { payload }) => {})
      .addCase(profileActions.logout, (state, { payload }) => {
        Object.assign(state, initialfederatedState);
      })
      .addCase(federatedActions.processPromptOptimiser, (state, { payload }) => {
        state.promptOptimiserLLMResponse = '';
        state.currentPromptOptimizer = null;
        state.promptCheckPrompt = payload.user_initial_prompt;
        state.isPromptOptimierRunning = true;
        state.promptOptimizerRequest = payload;
      })
      .addCase(federatedActions.processPromptOptimiserSuccess, (state, { payload }) => {
        state.isPromptOptimierRunning = false;
        state.currentPromptOptimizer = payload;
      })
      .addCase(federatedActions.processPromptOptimiserFailure, (state, { payload }) => {
        state.isPromptOptimierRunning = false;
        state.currentPromptOptimizer = null;
      })
      .addCase(federatedActions.promptOptimizerLLMSuccess, (state, { payload }) => {
        state.promptOptimiserLLMResponse = payload.output;
      })
      .addCase(federatedActions.processPromptOptimizerLite, (state, { payload }) => {
        state.currentPromptOptimizer = null;
        state.promptCheckPrompt = payload.initial_prompt;
        state.isPromptOptimierRunning = true;
      })
      .addCase(federatedActions.processPromptOptimizerLiteSuccess, (state, { payload }) => {
        state.isPromptOptimierRunning = false;
        state.currentPromptOptimizer = payload;
      })
      .addCase(federatedActions.processPromptOptimizerLiteFailure, (state, { payload }) => {
        state.isPromptOptimierRunning = false;
        state.currentPromptOptimizer = null;
      })
      .addCase(federatedActions.processPromptOptimizerLLM, (state, { payload }) => {
        state.processingPromptOptimizerLLM = true;
        state.processingOptimisedPromptLLM = true;
      })
      .addCase(federatedActions.processPromptIptimizerLLMInitialPromptSuccess, (state, { payload }) => {
        state.promptOptimiserLLMResponse = payload.output;
        state.processingPromptOptimizerLLM = false;
      })
      .addCase(federatedActions.processPromptOptimizerLLMInitialPromptFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.processingPromptOptimizerLLM = false;
      })
      .addCase(federatedActions.processPromptIptimizerLLMOptimizedPromptSuccess, (state, { payload }) => {
        state.optimisedPromptLLMResponse = payload.output;
        state.processingOptimisedPromptLLM = false;
      })
      .addCase(federatedActions.processPromptOptimizerLLMOptimizedPromptFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.processingOptimisedPromptLLM = false;
      });
  },
});

export const federatedReducer = federatedSlice.reducer;
