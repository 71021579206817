import {
  ArticleUpdateRequest,
  AssertionResult,
  AssertionResultLite,
  ContradictionLite,
  ContradictionResult,
  HttpErrorResponse,
} from '../../../types/models';
import { DashboardMatericsRequestByDateRange, ModuleMetrics } from '../dashboard/models/dashboard.model';
import { HttpClient } from '../http';
import { ArticleProcessRequest } from './models/ArticleProcessRequest';

class ContradictionServiceImpl {
  processContradiction(request: ArticleProcessRequest): Promise<ContradictionResult> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/contradiction' : '/contradiction';
    request.assertions = [request.article];
    return HttpClient.post<ContradictionResult>(baseURL, request, {
      portNumber: 5001,
      showLoader:false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  private getBaseUrl = (): string => {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/contradiction' : '/contradiction';
    return baseURL;
  };
  getUserContradictionsById(id: string): Promise<ContradictionResult[]> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/contradiction' : '/contradiction';
    return HttpClient.get<ContradictionResult[]>(`/${baseURL}/${id}`, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  getContradictionsList(page: number = 1, take: number = 10): Promise<ContradictionLite> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/contradiction' : '/contradiction';

    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}`;
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<ContradictionLite>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  getForDashboard(request: DashboardMatericsRequestByDateRange): Promise<ModuleMetrics> {
    const baseURL = '/contradiction/getMetricsByDaterange';
    return HttpClient.post<ModuleMetrics>(baseURL, request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  getDefaulContradiction(): Promise<ContradictionResult> {
    return HttpClient.get<ContradictionResult>('/defaultarticle', { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  updateContradiction(changes: ArticleUpdateRequest): Promise<ContradictionResult> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/contradiction' : '/contradiction';
    let request = {
      isFavorite: changes.isFavorite.toString(),
      progressPercentage: '100',
    };

    return HttpClient.put<ContradictionResult>(`${baseURL}/${changes.id}`, request, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }
  deleteContradiction(id: string): Promise<void> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/contradiction' : '/contradiction';

    return HttpClient.delete<void>(`${baseURL}/${id}`, { portNumber: 5001 })
      .then(() => {})
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
  clearHistory(): Promise<boolean> {
    return HttpClient.post<boolean>('/contradiction/clear-history', {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
}
const ContradictionService = new ContradictionServiceImpl();

export { ContradictionService };
