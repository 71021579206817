import { RouterProvider } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import { router } from './components/Router/Router';
import { TokenService } from './common/services/token/token.service';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from './redux/actions/profile.actions';
import { selectProfileErrorMessage, userDetails } from './redux/selectors/profile.selectors';
import Notification from './components/Notification';
import './App.css';
import { AuthProvider } from './config/authorization/AuthContext';
import AppLoader from './components/AppLoader/AppLoader';
import { ProfileErrorCode } from './common/constants/profile/profile-error-code';

function App() {
  const dispatch = useDispatch();
  const user = useSelector(userDetails);
  const [loadApplication, setLoadApplication] = useState(false);
  const loginErrorSelector = useSelector(selectProfileErrorMessage);
  useEffect(() => {
    console.log('process', process.env);
    if (TokenService && TokenService.accessToken && TokenService.accessToken.length > 0 && !user.id) {
      dispatch(profileActions.initialize());
    } else {
      setLoadApplication(true);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (loginErrorSelector == ProfileErrorCode.UnAuthorized) {
      console.log('loginErrorSelector', loginErrorSelector);
      setLoadApplication(true);
    }
  }, [loginErrorSelector, dispatch]);
  if (!loadApplication) return <AppLoader />;

  return (
    <>
      <Suspense fallback={<AppLoader />}>
        <AppLoader />
        <AuthProvider>{<RouterProvider router={router} />}</AuthProvider>
      </Suspense>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-40 mt-10"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Notification />
        </div>
      </div>
    </>
  );
}

export default App;
