import React, { useContext, useEffect, useState } from 'react';
import SearchInput from '../../../components/Search/SearchInput';
import InfoIconWithDescription from '../../../components/InfoDescription/InfoDescription';
import { JsonInputIcon, PlusIcon } from '../../../common/icons/Icons';
import CheckboxWithLabel from '../../../components/Base/CheckBoxWithLabel';
import { TitleWithActionIcon } from '../../../layouts/Sidenav/UserAssertionsList';
import Typography from '../../../components/Typography/Ty[pgraphy';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { SecondaryButton } from '../../../components/SecondaryButton';
import { Library, LibraryMap, LibraryMapLite } from '../../../types/models';
import { useDispatch } from 'react-redux';
import { patternManagementActions } from '../../../redux/actions/pattern-management.actions';
import { PatternTypes } from '../../../common/constants/enums/engine-types.enum';
import { CommonLibraryService } from '../../../common/services/library/common-library.service';
import { hideLoader, showLoader, toggleLoader } from '../../../common/services/loader-provider/loaderObservable';
import { PatternsListModal } from '../../../components/Modal/PatternsListModal/PatternsListModal';
import ImportPatterns from '../ImportPatterns';
import { setNotification } from '../../../redux/actions/notification.actions';
import IconButton from '../../../components/IconButton/IconButton';
import { AuthContext } from '../../../config/authorization/AuthContext';
import { UPLOAD_SYSTEM_PATTERN } from '../../../config/authorization/PermissionsConstants';

interface SystemPatternLibrariesProps {
  systemPatterns: LibraryMapLite[];
}

interface ListItemProps {
  item: { id: string; selected: boolean; title: string; description: string };
  handleAdd: (id: string, label:string) => void;
  onClick: (libraryId: string) => void;
  handleRemove: (id: string, label:string) => void;
}

const ListItem: React.FC<ListItemProps> = ({ item, handleAdd, handleRemove , onClick }) => (
  <li key={item.id} className="border border-gray-200 border-solid  p-4 w-[400px]">
    <div className="flex justify-between items-center">
      <div className="flex w-[300px] justify-between" onClick={() =>  onClick(item.id)}  >
        <Typography  variant="p" className="text-black-900 font-openSans">
          {item.title}
        </Typography>
        <InfoIconWithDescription description="This is a description" />
      </div>
      {item.selected ? (
        <Typography className="text-purple-primary cursor-pointer" onClick={() => handleRemove(item.id,item.title)}>
          Remove
        </Typography>
      ) : (
        <Typography className="text-primary cursor-pointer" onClick={() => handleAdd(item.id, item.title)}>
          Add
        </Typography>
      )}
    </div>
    <Typography className="mb-2 text-gray-700 overflow-auto break-words" variant="subtext">
      {item.description}
    </Typography>
  </li>
);

const SystemPatternLibraries: React.FC<SystemPatternLibrariesProps> = ({ systemPatterns }) => {

  const [searchTerm, setSearchTerm] = useState('');
  const [viewSearch, setViewSearchTerm] = useState('');
  const [filter, setFilter] = useState('Show All');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showImportModel, setShowImportModeln] = useState(false);
  const [openPatternDetails, setShowPatternDetails] = useState(false);
  const [list, setList] = useState<string[]>([]);
  const [filteredItems, setFilteredItems] = useState(systemPatterns);
  const authContext = useContext(AuthContext);
  const [filteredItemsForView, setFilteredItemsForView] = useState(systemPatterns);
  const [selectedPattern, setSelectedPattern] = useState<Library | null>(null);
  const dispatch = useDispatch();
  const [canEnableJsonUpload, setCanEnableJsonUpload] = useState(false);
  const handleAdd = (id: string,label:string) => {
    let payload = {isEnabled:true , libraryId: id , patternType: PatternTypes.System, libraryName:label} as LibraryMap;
             dispatch(patternManagementActions.setPatternSelection(payload));
  };

   const showPatternDetails = async (libraryId: string) =>{
   try {
    showLoader('Loading Pattern Details');
    let libraryDetails = await CommonLibraryService.getSystemPatternById(libraryId);
    setSelectedPattern(libraryDetails);
    setShowPatternDetails(true);
   } catch (error) {
    console.log(error);
    setSelectedPattern(null);
    setShowPatternDetails(false);
   } finally {
    hideLoader();
   }
  };

  const handleRemove = (id: string) => {
    let payload = {isEnabled:false , libraryId: id , patternType: PatternTypes.System} as LibraryMap;
    dispatch(patternManagementActions.setPatternSelection(payload));
  };

  useEffect(() => {
    let canUpload = authContext?.checkPermission([UPLOAD_SYSTEM_PATTERN]) ?? false;
    setCanEnableJsonUpload(canUpload);
  }, [systemPatterns]);

  useEffect(() => {
    let newFilteredItems = systemPatterns;

    if (filter === 'Show Added Only') {
      newFilteredItems = newFilteredItems.filter(item =>item.isSelected);
    } 

    if (searchTerm) {
      newFilteredItems = newFilteredItems.filter(item =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredItems(newFilteredItems);
  }, [searchTerm, filter, systemPatterns, list]);

  useEffect(() => {
    let newFilteredItems = systemPatterns;
    if (viewSearch) {
      newFilteredItems = newFilteredItems.filter(item =>
        item.label.toLowerCase().includes(viewSearch.toLowerCase())
      );
    }
    setFilteredItemsForView(newFilteredItems);
  }, [viewSearch, systemPatterns]);

  return (
    <><div className="bg-white p-4 ">
      <h2 className="text-lg  mb-4 font-semibold">System Pattern Libraries</h2>
      <p className="text-sm text-gray-600 mb-4 font-openSans">
        Patterns are libraries of terms that allow prompts to accurately detect labels and similar expressions related
        to specific compliance or information disclosure matters.
        <a href="#" className="text-blue-500 hover:underline">
          Learn more
        </a>
      </p>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 justify-between my-5">
      <SearchInput
              className="my-2"
              placeholder="Search By Name"
              onChange={value => setViewSearchTerm(value)}
              value={viewSearch}
            />
        <div className='flex flex mx-2 items-center'>
          {canEnableJsonUpload && <div>
          <IconButton
                icon={<JsonInputIcon />}
                text="Import System Libraries With JSON"
                onClick={()=>{
                  setShowImportModeln(true);
                }}
              />
          </div>}
        <div onClick={() => setIsSidebarOpen(true)} className="flex  items-center font-openSans text-purple-primary">
          <span className="mx-2">
            <PlusIcon color="#C098CE" />
          </span>
          Get / Manage Libraries
        </div>
        </div>
      </div>
      {isSidebarOpen && (
        <div className="fixed top-0 right-0 h-full  w-[450px] bg-white p-4 shadow-lg  z-[999] animate__animated animate__slideInRight ">
          {/* menu header */}
          <div className="sticky top-0 bg-white z-10">
          <div className='flex justify-between' >
          <h2 className="text-lg font-semibold mb-4 sticky top-0 bg-white">Pattern Libraries</h2>
          <button
                  onClick={()=>{
                    setIsSidebarOpen(false);
                  }}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="crud-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
          </div>
            <select className="w-full mb-4 border rounded" value={filter} onChange={e => setFilter(e.target.value)}>
              <option><Typography className='text-black-900 font-openSans'>Show All</Typography></option>
              <option><Typography  className='text-black-900 font-openSans'>Show Added Only</Typography></option>
            </select>
            <SearchInput
              className="my-2 w-full"
              placeholder="Search By Name"
              onChange={value => setSearchTerm(value)}
              value={searchTerm}
            />
          </div>
          <ul className="overflow-scroll h-[78%] mt-2">
            { filteredItems.length > 0 ?  filteredItems.map(item => (
              <ListItem onClick={showPatternDetails} item={ {id:item.id ?? "", description:item.comment ?? ""  , selected :item.isSelected ?? false , title:item.libraryGroup} } handleAdd={handleAdd} handleRemove={handleRemove} />
            )) : <Typography className="w-full text-center text-gray-500">No items to show</Typography>}
          </ul>
          {/* menu actions */}
          <div className="bottom-0  flex gap-2 w-full p-4 bg-white  justify-end">
            {/* <SecondaryButton  stateProp={'rest'} className="" text='Close' onClick={() => setIsSidebarOpen(false)}         /> */}
            {/* <PrimaryButton
              className=""
              size="small"
              stateProp="rest"
              iconPosition="left"
              enableFullWidth={false}
              style="boxed"
              text="Close"
              onClick={() => {
                setIsSidebarOpen(false);
              }}
            /> */}
          
          </div>
        </div>
      )}
      <div className="flex flex-wrap ">
        { filteredItemsForView.length == 0 ?   <p className="w-full flex justify-center text-gray-500">No patterns to show</p>:  filteredItemsForView.slice(0,10).map(pattern => (
          <div className="w-[300px] flex flex-wrap font-openSans">
            <CheckboxWithLabel onInfoClick={()=>showPatternDetails(pattern.id!)} label={pattern.libraryGroup} isChecked={pattern.isSelected} onCheckboxChange={() => {
              let payload = {isEnabled:!(pattern.isSelected ?? false) , libraryId: pattern.id , patternType: PatternTypes.System, libraryName:pattern.libraryGroup} as LibraryMap;
             dispatch(patternManagementActions.setPatternSelection(payload));
             } } showInfoIcon={true} />
          </div>
        ))}
      </div>
    </div>
    <ImportPatterns
        isOpen={showImportModel}
        isBulkUpdate={true}
        patternType={PatternTypes.System}
        onCancel={() => {
          setShowImportModeln(false);
        }} 
        onImport={function (file: File): void {
          setShowImportModeln(false);
          dispatch(patternManagementActions.initilizeLibraries());
          dispatch(setNotification({ text: 'System Libraries Imported Successfully', type: 'success' }));
        } }      />
    {selectedPattern && <PatternsListModal
        isShown={openPatternDetails}

        patterns={selectedPattern.patterns} onClose={()=>{
          setShowPatternDetails(false);
        } } title={selectedPattern.libraryGroup ?? ""} description={selectedPattern.libraryComment}  />}
    </>
  );
};

export default SystemPatternLibraries;
