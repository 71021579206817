import { useSelector } from 'react-redux';
import { appUsersList } from '../../../redux/selectors/access-management.selectors';
import { UsersList } from './usersList';

export const UsersView = () => {
  const usersList = useSelector(appUsersList);
  return (
    <>
      <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">Users In Domain</h3>
      <UsersList users={usersList} />
    </>
  );
};
