import React from 'react';
import CopyToClipboard from '../Clipboard/CopyToClipboard';

const SampleCode = () => {
  const code = `
    accessGuardrailApi = async () => {
        let url = 'https://api.dev.guardrail.tech/v1/queueArticlesClient';
        const headers = {
            'Content-Type': 'application/json',
            'GRP-EXT-API-KEY': 'TkRNMlF6WTVOalUyUlRjME5ERTFNRFE1TkVJMk5UYzU=',
        };
        try {
            let response = await axios.post(
            url,
            {
                article: 'provide your article details for assertion',
                top_k: 10,
                top_n: 5,
                recursion_level: 1,
            },
            { headers: headers }
            );
            console.log(response);
        } catch (error) {
            console.log({ message: error });
        }
    };
    `;
  const readCode = `
    accessGuardrailApi = async () => {
        let url = 'https://api.dev.guardrail.tech/v1/articles/client/63479d5307ae95e793245f64';
        const headers = {
            'Content-Type': 'application/json',
            'GRP-EXT-API-KEY': 'TkRNMlF6WTVOalUyUlRjME5ERTFNRFE1TkVJMk5UYzU=',
        };
        try {
            let response = await axios.get({ headers: headers });
            console.log(response);
        } catch (error) {
            console.log({ message: error });
        }
    };
    `;
  return (
    <div>
      <div className="box-content ">
        <div className="mt-5">
          <p className="mt-1 text-base text-blueGray-500">Sample Code to consume Guardrail Api</p>
        </div>
        <pre className=" bg-slate-50 text-xs text-black rounded-lg border border-highlight-lightgreen">
          <div className="float-right  mr-1 mt-1">
            <CopyToClipboard text={code} />
          </div>
          <code className="leading-3">{code}</code>
        </pre>
        <div className="mt-5">
          <p className="mt-1 text-base text-blueGray-500">Sample Code to retrieve Asserts details from Guardrail Api</p>
        </div>
        <pre className=" bg-slate-50 text-xs text-black rounded-lg border border-highlight-lightgreen">
          <div className="float-right  mr-1 mt-1">
            <CopyToClipboard text={readCode} />
          </div>
          <code className="leading-3">{readCode}</code>
        </pre>
      </div>
    </div>
  );
};

export default SampleCode;
