import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { MainMenu } from '../../layouts/Sidenav/MainMenu';
import ContentLayout from '../../components/ContentLayout/ContentLayout';

export const Landing = (): JSX.Element => {
  return (
    <div className="landing">
      <div className="div">
        <Header />
        <ContentLayout>
          <MainMenu />
        </ContentLayout>
        <Footer
          className="footer-instance"
          group="https://generation-sessions.s3.amazonaws.com/992bca50d1a1be566686effdf99311ae/img/group-41-1@2x.png"
        />
      </div>
    </div>
  );
};
