import { useContext, useState } from 'react';
import { CurrentDateTime } from '../../common/helpers/Helper';
import { ArticleUpdateRequest, AssertionResult } from '../../types/models';
import HighlightedParagraph from '../HighlightedParagraph/HighlightedParagraph';
import './style.css';
import { PrimaryButton } from '../PrimaryButton';
import {
  ArrowDown,
  EditIcon,
  FavoriteIcon,
  FavoriteIconPink,
  ShareIcon,
  GeneratePDFIcon,
} from '../../common/icons/Icons';
import { useDispatch } from 'react-redux';
import { articleActions } from '../../redux/actions/article.actions';
import { PlugInResultActions } from '../PlugInResultActions/PlugInResultActions';
import { EDIT_TEXT, FAVORITE_TEXT, SHARE_TEXT, Generate_PDF } from '../../common/constants/app-text';
import { Tags } from '../Tags/Tags';
import { GuardRailEngineType, SourceType } from '../../common/constants/enums/engine-types.enum';
import { contradictionActions } from '../../redux/actions/contradiction.actions';
import { AuthContext } from '../../config/authorization/AuthContext';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { FACT_CHECKER_FAVORITES, FACT_CHECKER_SHARE } from '../../config/authorization/PermissionsConstants';
import LLMModels from '../../common/constants/llm-models';
interface ResultViewProps {
  text?: string;
  group?: string;
  assertion?: AssertionResult;
  showTags?: boolean;
  disableHideInput?: boolean;
  engineView?: GuardRailEngineType;
}

export const ArticleResultView = ({
  text,
  group,
  assertion,
  showTags,
  disableHideInput = false,
  engineView = GuardRailEngineType.FactChecker,
}: ResultViewProps): JSX.Element => {
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const sourceType = LLMModels.findIndex((item) => assertion?.sourceType === item.sourceType);
  const handleEditText = () => {
    if (engineView === GuardRailEngineType.ContractionFinder) {
      dispatch(contradictionActions.setPromptContent(assertion?.article ?? ''));
      navigate(RoutePath.ContradictionFinder);
    } else {
      dispatch(articleActions.setPromptContent(assertion?.article ?? ''));
      navigate(RoutePath.FactChecker);
    }
  };
  return (
    <div className="group-6">
      <div className="overlap-3">
        <div className="flex items-center justify-between">
          <div className="flex space-x-4 items-center">
            {assertion?.isFavorite ? <FavoriteIcon /> : null}
            {sourceType !== -1 ? (
              <div className="flex items-center">
                <div className="mr-2">{LLMModels[sourceType].icon}</div>
                <div className="text-wrapper-25">{LLMModels[sourceType].displayName}</div>
              </div>
            ) : (
              <div className="text-wrapper-25">Input</div>
            )}
          </div>

          <div className="flex space-x-4">
            <p className="support-text">{CurrentDateTime(assertion?.createdAt)}</p>
          </div>
        </div>
        <div
          className={` collapsible-section ${isOpen ? 'open' : ''} ${
            engineView === GuardRailEngineType.Gateway123 ? 'mb-8' : ''
          }`}
        >
          <HighlightedParagraph engineType={engineView} currentAssertion={assertion} />
          {/* {showTags && <Tags className="mt-10" tags={assertion?.tags} />} */}
        </div>
        {engineView !== GuardRailEngineType.Gateway123 && (
          <div className="flex items-center justify-between mt-10 ">
            <div className="flex space-x-4">
              <PlugInResultActions
                actionItems={[
                  {
                    icon: <EditIcon />,
                    text: EDIT_TEXT,
                    isVisible: true,
                    onClick: handleEditText,
                  },
                  // {
                  //   icon: <GeneratePDFIcon />,
                  //   isVisible: authContext?.checkPermission([FACT_CHECKER_SHARE]),
                  //   text: Generate_PDF,
                  //   onClick: () => {},
                  // },
                  {
                    icon: assertion?.isFavorite ? <FavoriteIcon /> : <FavoriteIconPink />,
                    text: FAVORITE_TEXT,
                    isVisible: authContext?.checkPermission([FACT_CHECKER_FAVORITES]),
                    textClass: assertion?.isFavorite ? `text-primary` : 'text-wrapper-27 ',
                    onClick: () => {
                      let updateArticle = {
                        isFavorite: !assertion?.isFavorite,
                        id: assertion?.id,
                        progressPercentage: assertion?.progressPercentage,
                      } as ArticleUpdateRequest;
                      if (engineView === GuardRailEngineType.ContractionFinder) {
                        dispatch(contradictionActions.setContradictionFavorite(updateArticle));
                      } else {
                        dispatch(articleActions.setArticleFavorite(updateArticle));
                      }
                    },
                  },
                ]}
              />
            </div>
            <div className="flex space-x-4">
              {isOpen ? (
                <div
                  className="input-action-item"
                  onClick={() => {
                    if (!disableHideInput) {
                      setIsOpen(false);
                    }
                  }}
                >
                  <div className="text-wrapper-27">Hide Input</div>
                  <div className="text-wrapper-26"></div>
                </div>
              ) : (
                <PrimaryButton
                  onClick={() => setIsOpen(true)}
                  className=""
                  size="small"
                  stateProp="rest"
                  style="boxed"
                  icon={<ArrowDown />}
                  iconPosition="right"
                  text="Show Input"
                />
              )}
            </div>
          </div>
        )}
        <div className="rectangle-16 mb-2" />
      </div>
    </div>
  );
};
