import { SourceType } from '../../../constants/enums/engine-types.enum';

export class ArticleProcessRequest {
  article: string;
  sourceType: SourceType | undefined;
  top_k: number;
  assertions?: Array<string>;
  top_n: number;
  recursion_level: number;
  checkForSimilarity?: boolean;
  constructor(article: string, top_n: number, checkForSimilarity: boolean, sourceType?: SourceType | undefined) {
    this.article = article ?? '';
    this.sourceType = sourceType;
    this.top_k = 10;
    this.top_n = top_n || 5;
    this.recursion_level = 3;
    this.checkForSimilarity = checkForSimilarity || true;
    this.assertions = [];
  }
  toPlainObject() {
    return {
      article: this.article,
      sourceType: this.sourceType,
      top_k: this.top_k,
      top_n: this.top_n,
      assertions: this.assertions ?? [],
      recursion_level: this.recursion_level,
      checkForSimilarity: this.checkForSimilarity,
    };
  }
}
