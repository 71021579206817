import React from 'react';
import Input from '../Input';

import { isBoolean } from '../../redux/helpers/primitive/isBoolean';

// const secureContentService = new SecureContentService();
interface Props {
  elements: { key: string; value: string; isEncrypted: boolean }[];
  elementName: string;
  onChange: (field: string, value: string | { key: string; value: string; isEncrypted: boolean }[]) => void;
  errors?: { [key: string]: string };
}

const DynamicRow: React.FC<Props> = ({ elements, errors, elementName, onChange }) => {
  const handleElementChange = (index: number, field: string, value: any) => {
    let updatedElements = [...elements];
    if (field == 'isEncrypted') {
      let isChecked = updatedElements[index].isEncrypted;
      updatedElements[index] = {
        ...updatedElements[index],
        [field]: !isChecked,
      };
    } else {
      updatedElements[index] = { ...updatedElements[index], [field]: value };
    }
    onChange(elementName, updatedElements);
  };

  const handleAddElement = () => {
    onChange(elementName, [...elements, { key: '', value: '', isEncrypted: false }]);
  };

  const handleRemoveElement = (index: number) => {
    const updatedParams = [...elements];
    updatedParams.splice(index, 1);
    onChange(elementName, updatedParams);
  };

  return (
    <div className="flex flex-col space-y-1 bg-slate-50 p-2 rounded-lg ">
      <label className="mb-1 block text-xs text-gray-600 font-sansInte">{elementName}:</label>
      {elements &&
        elements.length > 0 &&
        elements.map((param, index) => (
          <div key={index} className="flex flex-row justify-between">
            <Input
              type="text"
              size="sm"
              value={param.key}
              placeholder="Key"
              onChange={(e) => handleElementChange(index, 'key', e.target.value)}
            />
            <Input
              type={param.isEncrypted ? 'password' : 'text'}
              size="sm"
              value={param.value}
              // value={
              //   param &&
              //   param.isEncrypted &&
              //   (param.value !== undefined || param.value !== '')
              //     ? secureContentService.decryptData(param.value)
              //     : param.value
              // }
              placeholder="Value"
              onChange={(e) => handleElementChange(index, 'value', e.target.value)}
            />
            <div className="mt-3 mr-2 ml-1 ">
              <input
                value="isEncrypted"
                type="checkbox"
                checked={param.isEncrypted}
                onChange={(e) => handleElementChange(index, 'isEncrypted', e.target.value)}
                className="h-5 w-5 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
              />
            </div>
            <button type="button" onClick={() => handleRemoveElement(index)}>
              <span className="text-2xl">🗑</span>
            </button>
          </div>
        ))}
      <button type="button" onClick={handleAddElement} className="self-end mt-3">
        <span>➕</span>
      </button>
    </div>
  );
};

export default DynamicRow;
