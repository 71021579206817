import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import { PrimaryButton } from '../PrimaryButton';
import { userPreferencesActions } from '../../redux/actions/user-preference.actions';
import { preferenceDetails, userPreferences } from '../../redux/selectors/user-preference.selectors';
import { domainDetails } from '../../redux/selectors/profile.selectors';
import {
  USER_PREFERENCE_PUSH_NOTIFICATIONS,
  USER_PREFERENCE_EMAIL_NOTIFICATIONS,
  USER_PREFERENCE_IN_APP_NOTIFICATIONS,
  USER_PREFERENCE_NEWS_LETTER,
  USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION,
  USER_PREFERENCE_ACCESSIBILITY_SETTINGS,
  USER_PREFERENCE_FONTSIZE,
  USER_PREFERENCE_NO_OF_RECORDS,
  USER_PREFERENCE_CACHED_RESULTS,
  USER_PREFERENCE_ARCHIEVE_HISTORY,
  USER_PREFERENCE_DEFAULT_LLM,
} from '../../common/constants/app-text';

function Preferences() {
  const dispatch = useDispatch();
  const preference = useSelector(preferenceDetails);
  const domain = useSelector(domainDetails);
  const userpreference = useSelector(userPreferences);

  const [editedPreferences, setEditedPreferences] = useState({
    pushNotifications: userpreference.pushNotifications,
    emailNotifications: userpreference.emailNotifications,
    inAppNotifications: userpreference.inAppNotifications,
    newsletter: userpreference.newsletter,
  });

  useEffect(() => {
    setEditedPreferences({
      pushNotifications: userpreference.pushNotifications,
      emailNotifications: userpreference.emailNotifications,
      inAppNotifications: userpreference.inAppNotifications,
      newsletter: userpreference.newsletter,
    });
  }, [userpreference]);

  const onPreferenceSaveChange = () => {
    const updatedPreferences = {
      id: preference?.id ?? '',
      domainId: domain?.id || '',
      language: preference?.language ?? 'en',
      timeZone: preference?.timeZone ?? 'pst',
      preferenceFlagsDto: [
        { name: USER_PREFERENCE_PUSH_NOTIFICATIONS, value: editedPreferences.pushNotifications },
        { name: USER_PREFERENCE_EMAIL_NOTIFICATIONS, value: editedPreferences.emailNotifications },
        { name: USER_PREFERENCE_IN_APP_NOTIFICATIONS, value: editedPreferences.inAppNotifications },
        { name: USER_PREFERENCE_NEWS_LETTER, value: editedPreferences.newsletter },
        { name: USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION, value: userpreference.twoFactorAuthentication },
        { name: USER_PREFERENCE_FONTSIZE, value: userpreference.fontSize },
        { name: USER_PREFERENCE_ACCESSIBILITY_SETTINGS, value: userpreference.accessibilitySettings },
        { name: USER_PREFERENCE_NEWS_LETTER, value: userpreference.newsletter },
        { name: USER_PREFERENCE_NO_OF_RECORDS, value: userpreference.numberofRecords },
        { name: USER_PREFERENCE_CACHED_RESULTS, value: userpreference.cacheResults },
        { name: USER_PREFERENCE_ARCHIEVE_HISTORY, value: userpreference.archieveHistory },
        { name: USER_PREFERENCE_DEFAULT_LLM, value: userpreference.defaultLLM },
      ],
    };
    dispatch(userPreferencesActions.updateUserPreferences(updatedPreferences));
  };

  const handleDiscardChanges = () => {
    setEditedPreferences({
      pushNotifications: userpreference.pushNotifications,
      emailNotifications: userpreference.emailNotifications,
      inAppNotifications: userpreference.inAppNotifications,
      newsletter: userpreference.newsletter,
    });
  };

  return (
    <div className="flex-1 bg-white">
      <div className="flex items-center justify-center p-2 text-gray-900 rounded-lg dark:text-white">
        <h3 className="text-2xl leading-6 font-medium mb-6 mt-10">Preferences</h3>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className="flex flex-col gap-8">
          {/* <div className="flex items-center gap-4 mb-6">
            <label htmlFor="language" className="text-lg font-medium text-gray-700">
              Language:
            </label>
            <select
              id="language"
              className="py-2 px-3 border border-gray-700 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
            >
              <option value="en">English</option>
              <option value="spanish">Spanish</option>
            </select>

            <label htmlFor="time-zone" className="text-lg font-medium text-gray-700 ml-40">
              Time Zone:
            </label>
            <select
              id="time-zone"
              className="py-2 px-3 border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
            >
              <option value="pst">PST</option>
            </select>

            <label htmlFor="date-format" className="text-lg font-medium text-gray-700 ml-40">
              Date Format:
            </label>
            <select
              id="date-format"
              className="py-2 px-3 border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
            >
              <option value="mm-dd-yyyy">MM-DD-YYYY</option>
              <option value="dd-mm-yyyy">DD-MM-YYYY</option>
            </select>
          </div> */}

          <div className="grid grid-cols-2 gap-y-3 gap-x-4">
            <div className="flex items-center gap-4 mb-6">
              <label htmlFor="email-notifications" className="block text-lg font-medium text-gray-700 mr-4">
                Email Notifications
              </label>
              <div className="switch-container ml-3">
                <input
                  type="checkbox"
                  id="email-notifications"
                  className="switch-checkbox"
                  checked={editedPreferences.emailNotifications}
                  onChange={(e) => setEditedPreferences((prev) => ({ ...prev, emailNotifications: e.target.checked }))}
                />
                <label htmlFor="email-notifications" className="switch-label">
                  <div className="slider"></div>
                </label>
              </div>
              {/* <div className="ml-2">
                  <span className="text-blue-500 to-violet-500">Enabled</span> : <span className="text-gray-500">Disabled</span>
                  </div> */}
            </div>

            {/* <div className="flex items-center gap-4 mb-6">
              <label htmlFor="push-notifications" className="block text-lg font-medium text-gray-700 mr-4">
                Push Notifications
              </label>
              <div className="switch-container ml-1">
                <input
                  type="checkbox"
                  id="push-notifications"
                  className="switch-checkbox"
                  checked={editedPreferences.pushNotifications}
                  onChange={(e) => setEditedPreferences((prev) => ({ ...prev, pushNotifications: e.target.checked }))}
                />
                <label htmlFor="push-notifications" className="switch-label">
                  <div className="slider"></div>
                </label>
              </div>
              {/* <div className="ml-2">
                   <span className="text-blue-500 to-violet-500">Enabled</span> : <span className="text-gray-500">Disabled</span>
                    </div> 
            </div>
          </div> */}
            {/* 
          <div className="grid grid-cols-2 gap-y-3 gap-x-4">
            <div className="flex items-center gap-4 mb-6">
              <label htmlFor="in-app-notifications" className="block text-lg font-medium text-gray-700 mr-4">
                In-App Notifications
              </label>
              <div className="switch-container ml-1">
                <input
                  type="checkbox"
                  id="in-app-notifications"
                  className="switch-checkbox"
                  checked={editedPreferences.inAppNotifications}
                  onChange={(e) => setEditedPreferences((prev) => ({ ...prev, inAppNotifications: e.target.checked }))}
                />
                <label htmlFor="in-app-notifications" className="switch-label">
                  <div className="slider"></div>
                </label>
              </div>
              {/* <div className="ml-2">
                  <span className="text-blue-500 to-violet-500">Enabled</span> : <span className="text-gray-500">Disabled</span>
                  </div> 
            </div> */}

            {/* <div className="flex items-center gap-4 mb-6">
              <label htmlFor="newsletter" className="block text-lg font-medium text-gray-700 mr-4">
                Newsletter
              </label>
              <div className="switch-container ml-16">
                <input
                  type="checkbox"
                  id="newsletter"
                  className="switch-checkbox"
                  checked={editedPreferences.newsletter}
                  onChange={(e) => setEditedPreferences((prev) => ({ ...prev, newsletter: e.target.checked }))}
                />
                <label htmlFor="newsletter" className="switch-label">
                  <div className="slider"></div>
                </label>
              </div>
              {/* <div className="ml-2">
                  <span className="text-blue-500 to-violet-500">Enabled</span> : <span className="text-gray-500">Disabled</span>
                  </div>  */}
          </div>

          <div className="flex justify-end mt-6">
            <PrimaryButton
              className="mx-2 bg-gradient-to-tl from-blue-500 to-violet-500"
              size="small"
              stateProp="rest"
              style="boxed"
              text="Save Changes"
              onClick={onPreferenceSaveChange}
            />
            <PrimaryButton
              onClick={handleDiscardChanges}
              className=""
              size="small"
              stateProp="rest"
              style="label-bold"
              text="Discard"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preferences;
