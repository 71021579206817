import { createSlice } from '@reduxjs/toolkit';
import { DefaultAssertionsData } from '../../common/constants/defaultArticle';
import { mapApiResponseToResult, mapPromptToFavorite } from '../../common/helpers/Helper';
import { FavoriteArticles, PaginationConfig, PromptProtect, UserHistoryList } from '../../types/models';
import { PROMPT_STORE_NAME, PromptActions } from '../actions/prompt.actions';
import { mockPrompt } from '../../common/services/prompt/prompt.service';
import { profileActions } from '../actions/profile.actions';

export interface PromptState {
  currentPrompt?: PromptProtect | any;
  userInput: string;
  isProcessingPrompt: boolean;
  enablePromptResults: boolean;
  processPromptEnabled: boolean;
  errorCode: string;
  isFetchingPrompts: boolean;
  isFavoriteEnabled: boolean;
  PromptsList: UserHistoryList[];
  favoritePromptsCount: number;
  promptPagination: PaginationConfig;
  favoritePrompts: FavoriteArticles[];
  isDeleting: boolean;
}

const initialState: PromptState = {
  userInput: '',
  currentPrompt: null,
  isProcessingPrompt: false,
  isFetchingPrompts: false,
  enablePromptResults: false,
  processPromptEnabled: false,
  isFavoriteEnabled: false,
  errorCode: '',
  PromptsList: [],
  promptPagination: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  favoritePrompts: [],
  favoritePromptsCount: 0,
  isDeleting: false,
};
export const PromptSlice = createSlice({
  name: PROMPT_STORE_NAME,
  initialState,
  reducers: {
    processPrompt: (state, action) => {
      state.isProcessingPrompt = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PromptActions.processPrompt, (state, { payload }) => {
        state.isProcessingPrompt = true;
        state.userInput = payload;
      })
      .addCase(PromptActions.processPromptSuccess, (state, { payload }) => {
        state.currentPrompt = payload;
        let index = state.PromptsList.findIndex((e) => e.id === payload.id);
        if (index < 0) {
          let newPrompt = {
            ...payload,
            name: payload.prompt,
          };
          state.PromptsList.unshift(newPrompt);
          var list = state.PromptsList;
          state.favoritePrompts = mapPromptToFavorite(list);
        }
        state.isProcessingPrompt = false;
        state.errorCode = '';
      })
      .addCase(PromptActions.toggleFavoritePrompts, (state, { payload }) => {
        state.isFavoriteEnabled = payload;
        var list = state.PromptsList;
        if (state.isFavoriteEnabled) {
          list = state.PromptsList.filter((Prompt, index, self) => Prompt.isFavorite === true);
        }
        state.favoritePrompts = mapPromptToFavorite(list);
        state.favoritePromptsCount = state.PromptsList.filter((Prompt, index, self) => Prompt.isFavorite).length;
      })
      .addCase(PromptActions.getUserPromptsListSuccess, (state, { payload }) => {
        state.PromptsList = state.PromptsList.concat(payload.data ?? []);
        var list = state.PromptsList;
        if (state.isFavoriteEnabled) {
          list = state.PromptsList.filter((Prompt, index, self) => Prompt.isFavorite === true);
        }
        state.favoritePrompts = mapPromptToFavorite(list);
        state.favoritePromptsCount = state.isFavoriteEnabled
          ? state.favoritePrompts.length
          : state.PromptsList.filter((Prompt, index, self) => Prompt.isFavorite === true).length;
        state.promptPagination = payload.meta;
        console.log(state.promptPagination);
        state.isFetchingPrompts = false;
      })
      .addCase(PromptActions.getUserPromptsList, (state, { payload }) => {
        state.isFetchingPrompts = true;
      })
      .addCase(PromptActions.setPromptFavoriteSuccess, (state, { payload }) => {
        state.currentPrompt = payload;
        let index = state.PromptsList.findIndex((e) => e.id === payload.id);
        state.PromptsList[index].isFavorite = payload.isFavorite;
        var list = state.PromptsList;
        if (state.isFavoriteEnabled) {
          list = state.PromptsList.filter((Prompt, index, self) => Prompt.isFavorite === true);
        }
        state.favoritePrompts = mapPromptToFavorite(list);
        state.favoritePromptsCount = state.isFavoriteEnabled
          ? state.favoritePrompts.length
          : state.PromptsList.filter((Prompt, index, self) => Prompt.isFavorite === true).length;
      })
      .addCase(PromptActions.getUserPromptsListFailure, (state, { payload }) => {
        state.isFetchingPrompts = false;
      })
      .addCase(PromptActions.processPromptFailure, (state, { payload }) => {
        state.isProcessingPrompt = false;
        state.errorCode = payload.message;
      })
      .addCase(PromptActions.getPromptByIdSuccess, (state, { payload }) => {
        state.currentPrompt = payload;
        state.errorCode = '';
      })
      .addCase(PromptActions.getPromptById, (state, { payload }) => {
        state.currentPrompt = null;
        state.errorCode = '';
      })
      .addCase(PromptActions.getPromptByIdFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(PromptActions.setPromptContent, (state, { payload }) => {
        state.isProcessingPrompt = true;
        state.userInput = payload;
      })
      .addCase(PromptActions.deletePrompt, (state, { payload }) => {
        state.isDeleting = true;
        state.userInput = payload;
      })
      .addCase(PromptActions.deletePromptSuccess, (state, { payload }) => {
        state.isDeleting = false;
        if (state.currentPrompt?.id == payload) {
          state.currentPrompt = null;
        }
        state.PromptsList = state.PromptsList.filter((e) => e.id != payload);
        let records = state.PromptsList;
        if (state.isFavoriteEnabled) {
          records = state.PromptsList.filter((article, index, self) => article.isFavorite === true);
        }
        state.favoritePrompts = mapPromptToFavorite(records);
        state.favoritePromptsCount = state.PromptsList.filter(
          (article, index, self) => article.isFavorite === true,
        ).length;
      })
      .addCase(PromptActions.deletePromptFailure, (state, { payload }) => {
        state.errorCode = payload.message;
      })
      .addCase(PromptActions.clearPromptHistorySuccess, (state) => {
        state.PromptsList = [];
        state.favoritePrompts = [];
        state.favoritePromptsCount = 0;
        state.promptPagination = {
          page: 1,
          take: 10,
          itemCount: 0,
          hasPreviousPage: false,
          hasNextPage: false,
        };
      })
      .addCase(PromptActions.processPromptEnabled, (state, { payload }) => {
        state.processPromptEnabled = payload;
      })
      .addCase(profileActions.logout, (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { processPrompt } = PromptSlice.actions;
export const PromptReducer = PromptSlice.reducer;
