import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { isUserLoggedIn, userPermissions } from '../../redux/selectors/profile.selectors';
import { Router, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { UPLOAD_SYSTEM_PATTERN } from './PermissionsConstants';

export const ActionTypes = {
  ADD_REMOVE_FAVORITES: 'AddRemoveFavorites',
  VIEW_MANAGE_HISTORY: 'ViewManageHistory',
  SHARE_CONTENT: 'ShareContent',
  DELETE_HISTORY: 'DeleteHistory',
  ACCESS_FACT_CHECKER: 'AccessFactChecker',
  PROCESS_FACT_CHECKER: 'ProcessFactChecker',
  ACCESS_FACT_CHECK_SERVICE: 'AccessFactCheckService',
  ACCESS_GATEWAY_SERVICE: 'AccessGateWayService',
  ACCESS_GATEWAY: 'AccessGateWay',
  ACCESS_DOSSIER_GENERATOR: 'AccessDossierGenerator',
  ACCESS_PROMOT_PROTECT: 'AccessPromotProtect',
  ACCESS_PROMOT_PROTECT_SERVICE: 'AccessPromotProtectService',
  ACCESS_CODE_ANALYZER: 'AccessCodeAnalyzer',
  ACCESS_SUMMARIZATION: 'AccessSummarization',
  ACCESS_PROJECT: 'AccessProjects',
  ACCESS_PATTERN_MANAGEMENT: 'AccessPatternManagement',
  ACCESS_CONTRADICTION_FINDER: 'AccessContradictionFinder',
  GENERATE_PDF: 'GeneratePDF',
  CONFIGURE_USER_SETTINGS: 'ConfigureUserSettings',
  UPDATE_PERSONAL_INFO: 'UpdatePersonalInfo',
  SUBMIT_REQUESTS: 'SubmitRequests',
  ADD_USERS: 'AddUsers',
  CONFIGURE_SYSTEM_SETTINGS: 'ConfigureSystemSettings',
  WRITE_ACCESS_TO_BILLING_AND_SUBSCRIPTION: 'WriteAccessToBillingAndSubscription',
  ACCESS_DEVELOPMENT_ENVIRONMENTS: 'AccessDevelopmentEnvironments',
  GENERATE_API_KEYS: 'GenerateAPIKeys',
  CONFIGURE_DATA_SOURCES: 'ConfigureDataSources',
  CONFIGURE_EXTERNAL_SEARCH: 'ConfigureExternalSearch',
  MODIFY_USERS: 'ModityUsers',
  DELETE_USERS: 'DeleteUsers',
  ACCESS_DASHBOARD: 'AccessDashboard',
  MANAGE_ACCESS_PERMISSIONS: 'ManageAccessPermissions',
  UPLOAD_SYSTEM_PATTERN: 'canUploadSystemPatternLibrary',
  ACCESS_GUARDRAIL123: 'AccessGuardrail123'
} as const;

export const ModuleTypes = {
  DASHBOARD: 'DASHBOARD',
  FACT_CHECKER: 'FACT_CHECKER',
  DOSSIER_GENERATOR: 'DOSSIER_GENERATOR',
  API_GATEWAY: 'API_GATEWAY',
  CONTRADCTION_FINDER: 'CONTRADCTION_FINDER',
  PROMPT_PROTECT: 'PROMPT_PROTECT',
  SETTINGS: 'SETTINGS',
  CLIENT_API: 'CLIENT_API',
  CODE_ANALYZER: 'CODE_ANALYZER',
  EXTERNAL_SEARCH: 'EXTERNAL_SEARCH',
  PROJECTS: 'PROJECTS',
  SUMMARIZATION: 'SUMMARIZATION',
  ACCESS_PERMISSIONS: 'ACCESS_PERMISSIONS',
  PATTERN_MANAGEMENT: 'PATTERN_MANAGEMENT',
  GUARDRAIL123: 'GUARDRAIL123',
} as const;
export type Action = keyof typeof ActionTypes;
export type Module = keyof typeof ModuleTypes;

type Permissions = {
  [key in Module]: Partial<Record<Action, boolean>>;
};

type AuthContextType = {
  permissionList: Array<string>;
  checkPermission: (permissionPairs: { module: Module; action: Action }[]) => boolean;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [permissionList, setPermissions] = useState<string[]>([]);
  const userAPIPermissions = useSelector(userPermissions);
  const userLogedIn = useSelector(isUserLoggedIn);
  const permissionMapper: Record<number, Action> = Object.values(ActionTypes).reduce((acc, action, index) => {
    acc[index + 1] = action as Action;
    return acc;
  }, {} as Record<number, Action>);

  const decodePermissions = () => {
    const formattedPermissions: Permissions = {
      DASHBOARD: {},
      CLIENT_API: {},
      CONTRADCTION_FINDER: {},
      DOSSIER_GENERATOR: {},
      EXTERNAL_SEARCH: {},
      FACT_CHECKER: {},
      PROMPT_PROTECT: {},
      PROJECTS: {},
      CODE_ANALYZER: {},
      SUMMARIZATION: {},
      SETTINGS: {},
      API_GATEWAY:{},
      ACCESS_PERMISSIONS: {},
      PATTERN_MANAGEMENT: {},
      GUARDRAIL123: {}
    };

    userAPIPermissions.forEach((permission: any) => {
      const module = permission.componentName.toUpperCase();
      const actions: Partial<Record<Action, boolean>> = {};

      Object.values(permissionMapper).forEach((action) => {
        actions[action] = false;
      });

      permission.permissionCategories.forEach((category: any) => {
        const actionName = permissionMapper[category];
        if (actionName) {
          actions[actionName] = true;
        }
      });

      formattedPermissions[module as Module] = actions;
    });
    return formattedPermissions;
  };

  const onLoad = () => {
    // if (localStorage.getItem("token"))
    // {
    //   // fetchPermissions();
    // }
  };

  useEffect(() => {
    onLoad();
  }, []);

  // useEffect(() => {
  //   if (userAPIPermissions.length > 0) {
  //     const decodedPermissions = decodePermissions();
  //     setPermissions(decodedPermissions);
  //     console.log('decodedPermissions = ', decodedPermissions)
  //   }
  // }, [userAPIPermissions]);

   const checkPermission = (permissionPairs: { module: Module; action: Action }[]) => {
    if (permissionPairs.length === 0) return true;
    for (const { module, action } of permissionPairs) {
      if (userAPIPermissions.includes(action)) {
        return true;
      }
    }
    return false;
  };

  return <AuthContext.Provider value={{ permissionList, checkPermission }}>{children}</AuthContext.Provider>;
};
