import {
  PromptProtect,
  ProcessPromoteRequest,
  HttpErrorResponse,
  ResultListLite,
  PromptProtectLite,
  DossierUpdateRequest,
} from '../../../types/models';
import { DashboardMatericsRequestByDateRange, ModuleMetrics } from '../dashboard/models/dashboard.model';
import { HttpClient } from '../http';

export const mockPrompt: PromptProtect = {
  id: '658a8b98c4c071c32b8527f9',
  prompt: 'this is an absolute crap and shitty movie to watch',
  is_profanity_para: true,
  isFavorite: false,
  updatedAt: '2023-12-26T08:15:20.828Z',
  createdBy: 'Cesar Admin',
  createdAt: '2023-12-26T08:15:20.828Z',
  deletedAt: null,
  progressPercentage: 100,
  promptsInfoResponseDto: [
    {
      profanityCheckResponseDto: [
        {
          profanity: 'CRAP',
          index: 5,
          word: 'crap',
        },
        {
          profanity: 'shit',
          index: 7,
          word: 'shitty',
        },
      ],
      sentence: 'this is an absolute crap and shitty movie to watch',
    },
    {
      profanityCheckResponseDto: [
        {
          profanity: 'CRAP',
          index: 5,
          word: 'crap',
        },
        {
          profanity: 'shit',
          index: 7,
          word: 'shitty',
        },
      ],
      sentence: 'this is an absolute crap and shitty movie to watch',
    },
  ],
};

class PromptProtectServiceImpl {
  processPrompt(request: ProcessPromoteRequest): Promise<PromptProtect> {
    return HttpClient.post<PromptProtect>('/prompt', request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
  getPromptProtectById(id: string): Promise<PromptProtect> {
    return HttpClient.get<PromptProtect>(`/prompt/${id}`, { portNumber: 5001 }).then((response) => response.data);
  }

  getDefaultPrompt() {
    return mockPrompt;
  }

  getForDashboard(request: DashboardMatericsRequestByDateRange): Promise<ModuleMetrics> {
    const baseURL = '/prompt/getMetricsByDaterange';
    return HttpClient.post<ModuleMetrics>(baseURL, request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  getPromptList(page: number = 1, take: number = 10): Promise<ResultListLite<PromptProtectLite>> {
    const baseURL = '/prompt';
    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}`;
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<ResultListLite<PromptProtectLite>>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  updatePrompt(changes: DossierUpdateRequest): Promise<PromptProtect> {
    const baseURL = '/prompt';
    let request = {
      isFavorite: changes.isFavorite.toString(),
      progressPercentage: '100',
    };

    return HttpClient.put<PromptProtect>(`${baseURL}/${changes.id}`, request, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  deletePrompt(id: string): Promise<void> {
    return HttpClient.delete<void>(`/prompt/${id}`, { portNumber: 5001 })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
  clearHistory(): Promise<boolean> {
    return HttpClient.post<boolean>('/prompt/clear-history', {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
}

const PromptProtectService = new PromptProtectServiceImpl();

export { PromptProtectService };
