export enum RoutePath {
  Landing = '/home',
  Home = '/',
  Login = '/login',
  FactCheckerResults = '/engine/fact-checker',
  FactCheckerProcess = '/engine/fact-checker/process',
  DossierProcess = '/engine/dossier-generator/process',
  PromptProcess = '/engine/prompt-protect/process',
  FactChecker = '/engine/fact-checker',
  Plans = '/plans',
  PaymentSuccess = '/plans/subscribed',
  BillingPlan = '/plans/billing',
  Dossier = '/engine/dossier-generator',
  PromptProtect = '/engine/prompt-protect',
  PasswordRecovery = '/password-recovery',
  SignIn = '/SignIn',
  SignUp = '/SignUp',
  ChangePassword = '/change-password',
  clientApiOnboarding = '/client-api-onboarding',
  externalSearchSource = '/external-search-source',
  patternConfiguration = '/patterns-management',
  patternConfigurationAdd = '/patterns-management/add-pattern',
  patternConfigurationEdit = '/patterns-management/edit-pattern',
  ContradictionFinder = '/engine/counter-point',
  ContradictionFinderProcess = '/engine/counter-point/process',
  EditProfile = '/edit-profile',
  accessManagement = '/access-management',
  accessManagementPermissions = '/access-management/permissions',
  accessManagementRole = '/access-management/role',
  accessManagementUsers = '/access-management/users',
  accessManagementSupport = '/access-management/support',
  Federated = '/engine/guardrail-legacy/',
  LLMResult = '/engine/guardrail-legacy/llm-result',
  LLMResultProcess = '/engine/guardrail-legacy/llm-result/process',
  LLMFactCheckResult = '/engine/guardrail-legacy/',
  CodeAnalyzer = '/engine/code-analyzer',
  CodeAnalyzerProcess = '/engine/code-analyzer/processing',
  Summarization = '/engine/summarization',
  SummarizationMethods = '/engine/summarization/methods',
  SummarizationProcess = '/engine/summarization/process',
  SummaryDetails = '/engine/summarization/summary',
  ConfigurationManagement = '/edit-profile/configuration-management',
  ProfileSettings = '/edit-profile/profile-settings',
  Preferences = '/edit-profile/preferences',
  SecuritySettings = '/edit-profile/security-settings',
  Projects = '/engine/projects',
  Gateway123 = '/engine/guardrail-gateway',
}
