import {
  DossierUpdateRequest,
  HttpErrorResponse,
  ProcessSummaryRequest,
  ResultListLite,
  SummarizationLite,
  SummaryComparisonResponse,
  SummaryComparisonSummaries,
} from '../../../types/models';
import { HttpClient } from '../http';

class SummarizationServiceImpl {
  processSummarization(request: ProcessSummaryRequest): Promise<SummarizationLite> {
    return HttpClient.post<SummarizationLite>('/summary', request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
  getSummarizationById(id: string): Promise<SummarizationLite> {
    return HttpClient.get<SummarizationLite>(`/summary/${id}`, { portNumber: 5001 }).then((response) => response.data);
  }

  getSummarizationList(page: number = 1, take: number = 10): Promise<ResultListLite<SummarizationLite>> {
    const baseURL = '/summary';
    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}`;
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<ResultListLite<SummarizationLite>>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  updateSummarization(changes: DossierUpdateRequest): Promise<SummarizationLite> {
    const baseURL = '/summary';
    let request = {
      isFavorite: changes.isFavorite.toString(),
      progressPercentage: changes.progressPercentage,
    };

    return HttpClient.put<SummarizationLite>(`${baseURL}/${changes.id}`, request, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  deleteSummarization(id: string): Promise<void> {
    const baseURL = '/summary';
    return HttpClient.delete<void>(`${baseURL}/${id}`, { portNumber: 5001 })
      .then(() => {})
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  clearHistory(): Promise<boolean> {
    return HttpClient.post<boolean>('/summary/clear-history', {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  compareSummaries(request: SummaryComparisonSummaries): Promise<SummaryComparisonResponse> {
    const baseURL = '/summaryComparison';
    return HttpClient.post<SummaryComparisonResponse>(baseURL, request, {
      portNumber: 5001,
      showLoader: false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
}

const SummarizationService = new SummarizationServiceImpl();

export { SummarizationService };
