import { useEffect, useState } from 'react';
import { Icons } from '../../common/icons';
import { Popper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '../../redux/actions/profile.actions';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import maskGroup from '../../static/img/mask-group.png';
import { isLoggedInUser, isMockUser, loggedInUser } from '../../redux/selectors/activeUser.selectors';
import { layoutActions } from '../../redux/actions/layout.actions';
import { layoutMainMenu } from '../../redux/selectors/layout.selectors';
import { UserService } from '../../common/services/users/user.service';

interface Props {
  firstName: string;
  lastName: string;
}

function generateRandomColor(firstName: string) {
  const colors = ['#343A40', '#6C757D', '#343A40', '#6C757D', '#343A40'];
  const charCodeSum = firstName
    .split('')
    .map((char) => char.charCodeAt(0))
    .reduce((sum, code) => sum + code, 0);
  const index = charCodeSum % colors.length;
  return colors[index];
}

export const LoggedInProfileAvatar = ({ firstName = '', lastName = '' }: Props) => {
  const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  const randomBackgroundColor = generateRandomColor(initials);
  const enableMockUser = useSelector(isMockUser);
  const userDetails = useSelector(loggedInUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handlePasswordChange = () => {
    navigate(RoutePath.ChangePassword);
  };
  var showMenu = useSelector(layoutMainMenu);

  const handleProfileSettings = () => {
    navigate(RoutePath.EditProfile);
  };

const handleBillingAndSubscription = () => {
  UserService.getBillingSessionURL().then((response) => {
    if (response && response) {
     window.open(response, '_blank');
    }
  });
}

  return (
    <>
      <div
        className="relative inline-flex items-center cursor-pointer"
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        onMouseLeave={() => setIsPopoverOpen(false)}
      >
        <div
          className="relative inline-flex items-center justify-center w-9 h-9 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 cursor-pointer"
          style={{ backgroundColor: randomBackgroundColor }}
        >
          {userDetails.imageUrl && !enableMockUser ? (
            <img className="mask-group" alt="User image" src={userDetails.imageUrl} />
          ) : (
            <span className="font-small text-white ">{initials}</span>
          )}
        </div>
        <Icons className="icons-instance" />

        {isPopoverOpen && (
          <Popper placement="right">
            {({ ref, style, placement }) => (
              <div
                ref={ref}
                data-placement={placement}
                style={{ ...style, zIndex: 10, left: 'unset', right: '6px', top: '-4px' }}
                className="mt-10  text-sm relative bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul className="py-2 text-sm text-gray-600 dark:text-gray-200">
                  <li>
                    <span
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer dark:hover:text-white font-sans"
                      onClick={handleProfileSettings}
                    >
                      View Profile
                    </span>
                  </li>
                  { userDetails && userDetails.isSubscribed && <li>
                    <span
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer dark:hover:text-white font-sans"
                      onClick={handleBillingAndSubscription}
                    >
                       View Subscription Details
                    </span>
                  </li>}
                  <li>
                    <span
                      className="block px-4 py-2 hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-600 dark:hover:text-white font-sans"
                      onClick={handlePasswordChange}
                    >
                      Change Password
                    </span>
                  </li>
                </ul>
                <div className="py-1">
                  <p
                    onClick={() => {
                      navigate(RoutePath.Home);
                      if (showMenu) {
                        dispatch(layoutActions.toggleLayoutMenu());
                      }
                      dispatch(profileActions.logout());
                    }}
                    className="block px-4 py-2 text-sm text-gray-600 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Sign out
                  </p>
                </div>
              </div>
            )}
          </Popper>
        )}
      </div>
    </>
  );
};
