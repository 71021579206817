export class RoleDetails {
  id: string;
  name: string;
  permissions?: Array<string>;
  constructor() {
    this.id = '';
    this.name = '';
    this.permissions = [];
  }
}

export type PermissionMapping = {
  permissionName: string;
  allowedRoles: string[];
};

export interface UpdateUserPermissionsRequest {
  roleId: string;
  permissions: string[];
}
