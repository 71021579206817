import { FC } from 'react';
import Modal from '../Modal';
import PermissionsView from '../../../views/AccessManagement/permissions/permissionsView';
interface PropTypes {
  isShown: boolean;
  onClose: () => void;
  permissions: string[];
  roleId: string;
  onSaveClick: (permissions: string[]) => void;
}

export const PermissionsUpdateModal: FC<PropTypes> = ({ isShown, onClose, onSaveClick, permissions }) => {
  return (
    <>
      <Modal isShown={isShown} animation="fade" centered withBackdrop>
        <div id="permissions-modal " className=" w-full  h-full">
          <div className="relative p-4 w-full justify-center max-h-500">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Manage Permissions</h3>
                <button
                  onClick={onClose}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="crud-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5 w-full max-h-500 overflow-scroll">
                <PermissionsView
                  isUpdateMode={true}
                  initialSelectedPermissions={permissions}
                  onUpdatePermissions={(permissions) => {
                    console.log('permissions', permissions);
                    onSaveClick(permissions);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
