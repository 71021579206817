import { useEffect, useState } from 'react';
import { NavLink, Route, useNavigate, useParams } from 'react-router-dom';
import ImportPatterns from '../ImportPatterns';
import { DeleteIcon, EditIcon, JsonInputIcon, PlusIcon } from '../../../common/icons/Icons';
import Typography from '../../../components/Typography/Ty[pgraphy';
import IconButton from '../../../components/IconButton/IconButton';
import { PrimaryButton } from '../../../components/PrimaryButton';
import InfoIconWithDescription from '../../../components/InfoDescription/InfoDescription';
import { Select, Table, TextInput, Textarea } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { patternManagementActions } from '../../../redux/actions/pattern-management.actions';
import { libraryDetails, patternManagementSelectors } from '../../../redux/selectors/pattern-management.selectors';
import { Tags } from '../../../components/Tags/Tags';
import { ExpressionType, PatternTypes } from '../../../common/constants/enums/engine-types.enum';
import { getPatternsToArrary, patternsStringToArray } from '../../../common/helpers/Helper';
import { Library, LibraryCreateOrUpdateRequest, LibraryDetailsUpdateRequest, PatternDto } from '../../../types/models';
import { hideLoader, showLoader } from '../../../common/services/loader-provider/loaderObservable';
import { CommonLibraryService } from '../../../common/services/library/common-library.service';
import { RoutePath } from '../../../common/constants/route-path';
import { setNotification } from '../../../redux/actions/notification.actions';


const LibraryAddOrUpdate = ({isAdd = true}) => {
  const [list, setList] = useState<any>([]);
  const [libraryName, setLibraryName] = useState('');
  const [libraryDescription, setLibraryDescription] = useState('');
  const [patternLabel, setPatternLabel] = useState('');
  const [regex, setRegex] = useState('');
  const [action, setAction] = useState('');
  const [description, setDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [showImportModal, setShowImportModal] = useState(false);
  const [enableBulkUpdate, setBulkUpdate] = useState(false);
  const [showAddLibrary, setShowLibrary] = useState(false);
  const libraryDetails = useSelector(patternManagementSelectors.libraryDetails);
  const [expressionType, setExpressionOption] = useState(ExpressionType.fuzzyMatch);

  const [tempPatternDetails , setTempPatternDetails] = useState<PatternDto[]>([]);
  const [debounceTimeoutId, setDebounceTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const router = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const getPatternsForSave = (index:string) => {
    let patterns = {
      description: description,
      expressionType: expressionType,
      label: patternLabel,
      pattern: regex,
      id: index ? index : null,
    } as PatternDto;
    return patterns;
  };

  const handleSave = () => {
    let tempTerms = [...tempPatternDetails];
    if(editIndex === -1){
      tempTerms.push(getPatternsForSave(''));
    }else{
      tempTerms[editIndex] = getPatternsForSave(tempTerms[editIndex].id!);
    }

    let payload = {
      id: isAdd ? null :libraryDetails?.id,
      libraryType: PatternTypes.Custom,
      libraryComment: libraryDescription,
      patterns: tempTerms,
      libraryGroup: libraryName,
    } as Library;
    let promise = null;
    if(isAdd && !payload.id){
      promise = CommonLibraryService.createCustomPattern(payload);
    }else {
      promise = CommonLibraryService.updateCustomPattern(payload);
    }
    promise.then((res) => {
      setShowLibrary(false);
      if(isAdd){
        dispatch(setNotification({ text: 'Library Added Successfully', type: 'success' }));
        router(`${RoutePath.patternConfigurationEdit}/${res.id}`);
      }else{
        dispatch(setNotification({ text: 'Library Updated Successfully', type: 'success' }));
        dispatch(patternManagementActions.getCustomPatternById(libraryDetails?.id!));
      }
    }).catch((error) => {
      console.log('Failed to save library', error);
      dispatch(setNotification({ text: 'Failed to save library', type: 'error' }));
    });

  }

  const handleLibraryDetailsSave = () => {
    let payload = {
      id: libraryDetails?.id,
      libraryComment: libraryDescription,
      libraryGroup: libraryName,
      libraryType: PatternTypes.Custom,
    } as LibraryDetailsUpdateRequest;
    CommonLibraryService.updateLibraryMetadata(payload).then((res) => {
        dispatch(setNotification({ text: 'Library Updated Successfully', type: 'success' }));
        dispatch(patternManagementActions.getCustomPatternById(libraryDetails?.id!));
    }).catch((error) => {
      dispatch(setNotification({ text: 'Failed to save library', type: 'error' }));
    });

  }

  const resetForm = () => {
    setLibraryName('');
    setPatternLabel('');
    resetTermForm();
}

const resetTermForm = () => {
  setPatternLabel('');
  setRegex('');
  setAction('');
  setEditIndex(-1);
  setDescription('');
  setErrorMessage('');
  setShowLibrary(false);
}
  
  useEffect(() => {
    resetForm();
     if(libraryDetails && !isAdd){
      setLibraryName(libraryDetails.libraryGroup ?? '');
      setLibraryDescription(libraryDetails.libraryComment ?? '');
      setList(libraryDetails);
      setTempPatternDetails(libraryDetails.patterns ?? []);
    }
  }, [libraryDetails,isAdd]);


  useEffect(() => {
    resetForm();
    if (id) {
      dispatch(patternManagementActions.getCustomPatternById(id));
    }
  }, [id]);

  const handleDelete = () =>{
    showLoader();
    if(libraryDetails && libraryDetails.id){
      CommonLibraryService.removePattern(libraryDetails.id).then((res) => {
        router(RoutePath.patternConfiguration);
        dispatch(setNotification({ text: 'Library Terms Deleted Successfully', type: 'success' }));
      }).finally(() => {
        hideLoader();
      });
    }
    }

    useEffect(() => {
      const checkLibraryName = async () => {
        try {
          setErrorMessage('');
          // If it's the add page or the library name has been changed in the update page
          if (isAdd || (!isAdd && libraryDetails?.libraryGroup !== libraryName)) {
            const response = await CommonLibraryService.checkLibraryName(libraryName);
            if (!response) {
              setErrorMessage('Library name already exists');
            }
          }
        } catch (error) {
          console.error('Failed to check library name', error);
        }
      };

      // Clear the previous timeout if there is one
      if (debounceTimeoutId) {
        clearTimeout(debounceTimeoutId);
      }

      // Set a new timeout
      if (libraryName) {
        setDebounceTimeoutId(setTimeout(checkLibraryName, 300));
      }
    }, [libraryName, isAdd, libraryDetails]);

  const handleBulkUpdate = () => {
    let library = {} as Library;
    if(isAdd){
      library = {
        libraryType: PatternTypes.Custom,
        libraryComment: libraryDescription,
        libraryGroup: libraryName,
        patterns: tempPatternDetails,
      } as Library;
    }else {
      library = {
        id: libraryDetails?.id,
        libraryType: PatternTypes.Custom,
        libraryComment: libraryDescription,
        libraryGroup: libraryName,
        patterns: tempPatternDetails,
      } as Library;
    }
    let libraries = {library: [library]};
    let updatedInfoJson = JSON.stringify(libraries, null, 2);
    
    let updatedInfoFile = new Blob([updatedInfoJson], { type: 'application/json' });
let fileName = "updatedInfo.json"; 
let fileWithExtension = new File([updatedInfoFile], fileName, { type: 'application/json' });
    showLoader();
    CommonLibraryService.uploadCustomPattern(fileWithExtension as File).then((res) => {
    router(RoutePath.patternConfiguration);
    dispatch(setNotification({ text: 'Library Terms Imported Successfully', type: 'success' }));
   }).finally(() => {
     hideLoader();
   });
  }

  const handleEdit = (index: number) => {
    const item = tempPatternDetails[index];
    setPatternLabel(item.label ?? "");
    setEditIndex(index);
    setExpressionOption(item.expressionType!);
    setDescription(item.description!);
    setRegex((item?.pattern));
    setShowLibrary(true);
  };
  
  const handleTermDelete = async (index: number) => {
  if (isAdd || enableBulkUpdate) {
    const newTempLibraryDetails = [...tempPatternDetails];
    newTempLibraryDetails.splice(index, 1);
    setTempPatternDetails(newTempLibraryDetails);
  } else {
    showLoader();
    CommonLibraryService.removePatternTerm(id!,tempPatternDetails[index].id!).then((res) => {
      if (res) {
        const newTempLibraryDetails = [...tempPatternDetails];
        newTempLibraryDetails.splice(index, 1);
        setTempPatternDetails(newTempLibraryDetails);
      }
    }).finally(() => {
      hideLoader();
    });
  }
};



  return (
    <>
      <div className=" w-full">
        <div className="bg-gray-300  px-8 py-8">
          <div className="flex justify-start items-center space-x-2">
            <NavLink to="/patterns-management" className="text-blue-500 no-underline hover:underline">
              <Typography className="font-sm"> Existing patterns </Typography>
            </NavLink>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 30 30"
              stroke="currentColor"
              className="h-5 w-5 text-gray-500 mt-2"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
            <span>
              <Typography className="font-sm">{isAdd ? 'New' : 'Edit'} Custom Pattern Library</Typography>{' '}
            </span>
          </div>
          <div className="w-full">
            <div className="block my-5 text-sm  font-bold text-gray-700">
              <Typography variant="h2"> Pattern Library Name </Typography>{' '}
            </div>
            <div className="flex w-full justify-between">
              <div className='flex flex-row gap-2'>
                <div>
                <input
                  type="text"
                  placeholder="Enter library name"
                  id="pattern-library-name"
                  className="form-input mt-1 block w-[250px] font-sm  border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 font-openSans"
                  value={libraryName}
                  onChange={e => setLibraryName(e.target.value)}
                />{' '}
                <br />
                {errorMessage && (
                  <Typography variant="subtext" className="text-red-500">
                    {errorMessage}
                  </Typography>
                )}
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Enter library description"
                  id="pattern-library-name"
                  className="form-input mt-1 block w-[250px] font-sm  border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 font-openSans"
                  value={libraryDescription}
                  onChange={e => setLibraryDescription(e.target.value)}
                />{' '}
                <br />
              </div>
              <div className='mt-2'>
              { id &&  ((libraryName != libraryDetails?.libraryGroup) || (libraryDescription.length>0  && libraryDescription != libraryDetails?.libraryComment) ) && <PrimaryButton
                              className=""
                              size="small"
                              disabled={ !libraryName || !libraryDescription}
                              stateProp="rest"
                              iconPosition="left"
                              enableFullWidth={false}
                              style="boxed"
                              text="Update Library Details"
                              onClick={handleLibraryDetailsSave}
                            />}
              </div>
                </div>
              {!isAdd && <IconButton icon={<DeleteIcon />} onClick={handleDelete} text="Delete Pattern" />}
            </div>
            <div></div>
          </div>
        </div>
        <div className=" bg-white  px-8 py-8">
          <div className="flex justify-between items-center mb-3">
            <h2 className="text-lg my-5 text-sm font-bold text-gray-900">Patterns</h2>
            {!showAddLibrary && (
              <div className="flex">
                <IconButton
                  icon={<JsonInputIcon />}
                  text="Import Terms With JSON"
                  onClick={() => {
                    setShowImportModal(true);
                  }}
                />
                <div></div>
                {enableBulkUpdate && libraryName.length > 2 && (
                  <div className="mx-2">
                    <PrimaryButton
                      className=""
                      size="small"
                      stateProp="rest"
                      iconPosition="left"
                      enableFullWidth={false}
                      style="boxed"
                      text="Bulk Save"
                      onClick={() => handleBulkUpdate()}
                    />
                  </div>
                )}
                {!enableBulkUpdate && (
                  <PrimaryButton
                    className=""
                    size="small"
                    stateProp="rest"
                    iconPosition="left"
                    icon={<PlusIcon />}
                    enableFullWidth={false}
                    style="boxed"
                    text="Add Term"
                    onClick={() => {
                      setShowLibrary(true);
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="overflow-x-auto">
            <div className="overflow-x-auto">
              <Table>
                <Table.Head>
                  <Table.HeadCell className="">
                    <div className="flex gap-2 items-center">
                      Pattern Label <InfoIconWithDescription description="" />
                    </div>
                  </Table.HeadCell>
                  <Table.HeadCell>
                    <div className="flex gap-2 items-center">
                      Expression Type <InfoIconWithDescription description="" />
                    </div>
                  </Table.HeadCell>
                  <Table.HeadCell>
                    <div className="flex gap-2 items-center">
                      Expression Value <InfoIconWithDescription description="" />
                    </div>
                  </Table.HeadCell>
                  <Table.HeadCell>
                    <div className="flex gap-2 items-center">
                     Description <InfoIconWithDescription description="" />
                    </div>
                  </Table.HeadCell>
                  <Table.HeadCell className="w-[100px]">
                    <div className="flex gap-2 items-center">
                      Actions <InfoIconWithDescription description="" />
                    </div>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {showAddLibrary && (
                    <>
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          <TextInput
                            type="text"
                            className="font-openSans text-sm text-gray-900 border border-gray-300  w-80 bg-gray-50  "
                            id="pattern-label"
                            placeholder="Enter label"
                            value={patternLabel}
                            onChange={e => setPatternLabel(e.target.value)}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Select
                            value={expressionType}
                            onChange={e => setExpressionOption(e.target.value as ExpressionType)}
                          >
                            <option value={ExpressionType.fuzzyMatch}> Fuzzy Match </option>
                            <option value={ExpressionType.inlineRegex}> Regular Expression </option>
                            <option value={ExpressionType.stringArrary}> String List </option>
                          </Select>
                        </Table.Cell>
                        <Table.Cell>
                          <TextInput
                            type="text"
                            className="font-openSans text-sm text-gray-900 border border-gray-300  w-80 bg-gray-50  "
                            id="regular-expressions"
                            placeholder="Add regular expressions"
                            value={regex}
                            onChange={e => setRegex(e.target.value)}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Textarea
                            className="font-openSans text-sm text-gray-900 border border-gray-300  w-80 bg-gray-50  "
                            id="description"
                            placeholder="Add description"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                          />
                           </Table.Cell>
                      </Table.Row>
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell colSpan={4} className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          <div className="flex justify-end space-x-4">
                            <button
                              onClick={() => {
                                resetTermForm();
                              }}
                              className="btn btn-secondary bg-gray-300 text-gray-800 rounded-md px-4 py-2 hover:bg-gray-200"
                            >
                              Cancel
                            </button>
                            <PrimaryButton
                              className=""
                              size="small"
                              disabled={errorMessage.length > 0 || !patternLabel || !regex || !libraryName}
                              stateProp="rest"
                              iconPosition="left"
                              enableFullWidth={false}
                              style="boxed"
                              text="Save"
                              onClick={handleSave}
                            />
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )}
                  {tempPatternDetails &&
                    tempPatternDetails.map((item: PatternDto, index: number) => (
                      <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {item.label}
                        </Table.Cell>
                        <Table.Cell>{item.expressionType}</Table.Cell>
                        <Table.Cell>{<Tags tags={getPatternsToArrary(item)} />}</Table.Cell>
                        <Table.Cell style={{ maxWidth: '300px', overflow: 'hidden', maxHeight: '3em', lineHeight: '1.5em' }}>
  {item.description}
</Table.Cell>
                        <Table.Cell>
                          <div className="flex gap-5">
                            <div className='cursor-pointer' onClick={() => handleEdit(index)}>
                              <EditIcon />
                            </div>
                            <div className='cursor-pointer' onClick={() => handleTermDelete(index)}>
                              <DeleteIcon />
                            </div>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <ImportPatterns
        isOpen={showImportModal}
        isBulkUpdate={false}
        onPatternLoad={patterns => {
         let patters = tempPatternDetails.concat(patterns[0].patterns ?? []);
          setTempPatternDetails(patters);
          setShowImportModal(false);
          if (libraryName.length === 0) {
            setLibraryName(patterns[0].libraryGroup ?? '');
          }
          if (libraryDescription.length === 0) {
            setLibraryDescription(patterns[0].libraryComment ?? '');
          }
          setBulkUpdate(true);
        }}
        onImport={file => {}}
        onCancel={() => setShowImportModal(false)}
      />
    </>
  );
};
export default LibraryAddOrUpdate;
