import { FC } from 'react';
import Modal from '../Modal';
import { PromptOptimizerCheck } from './PromptOptimizerCheck';
import { GuardRailEngineType } from '../../../common/constants/enums/engine-types.enum';
interface PropTypes {
  isShown: boolean;
  onClose?: () => void;
  engineType?: GuardRailEngineType;
}

export const PromptOptimizerModal: FC<PropTypes> = ({ isShown, onClose, engineType }) => {
  return (
    <div>
      <Modal isShown={isShown} animation="fade" centered withBackdrop onBackdropClick={onClose}>
        <PromptOptimizerCheck engineType={engineType} />
      </Modal>
    </div>
  );
};
