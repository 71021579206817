import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { UserPreferencesState } from '../slices/user-preference.slice';
import { DashboardState } from '../slices/dashboard.slice';

const selectDashboardState: (state: AppState) => DashboardState = (state) => state.dashboard;

export const dashboardMertics = createSelector(selectDashboardState, ({ modulesMetrics }) => modulesMetrics);
export const recentActivity = createSelector(selectDashboardState, ({ recentActivity }) => recentActivity);
export const metricsCategories = createSelector(
  selectDashboardState,
  ({ modulesMetrics }) => modulesMetrics?.map((e) => e.moduleName) ?? [],
);
export const metricValues = createSelector(
  selectDashboardState,
  ({ modulesMetrics }) => modulesMetrics?.map((e) => e.count) ?? [],
);
export const allRecords = createSelector(selectDashboardState, ({ allRecentActivity }) => allRecentActivity ?? []);
export const seriesMetricsForCompleted = createSelector(
  selectDashboardState,
  ({ seriesMetricsOptions }) => seriesMetricsOptions ?? [],
);
export const metricsForArea = createSelector(
  selectDashboardState,
  ({ matricsForAreaChart }) => matricsForAreaChart ?? [],
);
export const loadingDashboard = createSelector(selectDashboardState, ({ fetching }) => fetching);
export const profileCompletion = createSelector(selectDashboardState, ({ profileCompletion }) => profileCompletion);
