import React from 'react';
import { TickMark } from '../../../common/icons/Icons';
interface DynamicListViewWithTitleProps {
  title: string;
  data: string[];
  layout?: 'row' | 'column';
}

export const DynamicListViewWithTitle: React.FC<DynamicListViewWithTitleProps> = ({
  title,
  data,
  layout = 'column',
}) => {
  return (
    <div className="divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm bg-white">
      <div className="p-6 sm:px-8">
        <p className="text-lg font-medium text-gray-900 sm:text-xl">{title}</p>
        <ul className={`mt-2 space-y-2 sm:mt-4 flex ${layout === 'row' ? 'flex-row space-x-2 space-y-0' : 'flex-col'}`}>
          {data.length > 0 ? (
            data.map((item, index) => (
              <li key={index} className="flex items-center gap-1">
                <TickMark />
                <span className="text-gray-700">{item}</span>
              </li>
            ))
          ) : (
            <div className="text-center text-gray-400">No data to display</div>
          )}
        </ul>
      </div>
    </div>
  );
};
