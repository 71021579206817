import { useDispatch, useSelector } from 'react-redux';
import ExternalSearchApiForm from '../../components/ExternalSearchSource/ExternalSearchSourceForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { externalSearchActions } from '../../redux/actions/external-search.action';
import { RoutePath } from '../../common/constants/route-path';
import { isFetchingExternalSearch } from '../../redux/selectors/external-search.selectors';
import { ExternalSearchSource } from '../../common/services/external-search/models/external-search';

const ExternalSearchSourceApi = () => {
  const dispatch = useDispatch();
  const isFetchingExternalSearchSource: boolean = useSelector(isFetchingExternalSearch);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      dispatch(externalSearchActions.getExternalSearchById(id));
    } else {
      //navigate(RoutePath.Dossier);
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(externalSearchActions.getExternalSearchSourceLightList(1));
  }, [dispatch]);

  const handleSubmit = (externalSearch: ExternalSearchSource) => {
    console.log(externalSearch);
    if (externalSearch && externalSearch.id && isFetchingExternalSearchSource) {
      let externalSearchUpdate = {
        id: externalSearch.id,
        name: externalSearch.name,
        requestItemDto: externalSearch.requestItemDto,
        childRequestItemDto: externalSearch.childRequestItemDto,
        responseItemDto: externalSearch.responseItemDto,
      } as ExternalSearchSource;
      dispatch(externalSearchActions.updateExternalSearch(externalSearchUpdate));
    } else if (externalSearch) {
      let externalSearchCreate = {
        name: externalSearch.name,
        requestItemDto: externalSearch.requestItemDto,
        childRequestItemDto: externalSearch.childRequestItemDto,
        responseItemDto: externalSearch.responseItemDto,
      } as ExternalSearchSource;
      dispatch(externalSearchActions.postExternalSearch(externalSearchCreate));
    }
    if (id) {
      dispatch(externalSearchActions.getExternalSearchById(id));
      navigate(`${RoutePath.externalSearchSource}/${id}`);
    }
  };
  return (
    <div className="flex flex-wrap justify-between mr-6 ml-7">
      <div className="w-full  px-4 border-r card">
        <ExternalSearchApiForm onSubmit={(externalSearch) => handleSubmit(externalSearch)} />
      </div>
      {/* <div className='w-full md:w-3/5 px-4'>
        <div className='hidden md:block'>
          <FeaturesList />
        </div>
      </div> */}
    </div>
  );
};

export default ExternalSearchSourceApi;
