import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentSummarizationSelector } from '../../redux/selectors/summarization.selectors';
import { Divider } from '../../components/Divider/Divider';
import { ArrowDown, ArrowUpIcon, InfoIcon } from '../../common/icons/Icons';
import { ActionItem } from '../../components/ActionItem/ActionItem';
import BackButton from '../../components/BackButton/BackButton';
import { languageModels } from '../../common/constants/summarization';
import { useNavigate, useParams } from 'react-router-dom';
import { SummarizationActions } from '../../redux/actions/summarization.actions';
import { setNotification } from '../../redux/actions/notification.actions';
import { RoutePath } from '../../common/constants/route-path';
import AppLoader from '../../components/AppLoader/AppLoader';

export const SummaryDetailsView: React.FC = () => {
  const { id, model } = useParams();
  const callApi = useRef(false);

  const currentSummarization = useSelector(currentSummarizationSelector);
  const summaryDetails =
    currentSummarization && Object.keys(currentSummarization).length > 0 && model
      ? currentSummarization?.summaries[model]
      : {};
  const [isOpen, setIsOpen] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (id) {
      if (!callApi.current) {
        callApi.current = true;
        dispatch(SummarizationActions.getSummarizationById(id));
      }
    } else {
      dispatch(setNotification({ title: 'Prompt Id Missing ', type: 'error' }));
      navigate(RoutePath.Landing);
    }
  }, [id, dispatch, navigate]);

  return (
    <>
      {currentSummarization && Object.keys(currentSummarization).length > 0 && model ? (
        <div className="relative">
          <div className=" bg-gray-50 p-10">
            <BackButton />
            <p className="text-xs pb-6 text-gray-500 pt-4">Summarization</p>
            <span>
              <span className=" font-bold">{languageModels[model]}</span>
              <span className="text-xs text-gray-400 ml-2">{summaryDetails?.summarized_text?.length} Words</span>
            </span>
            <p className={` my-5 text-sm text-gray-500 collapsible-section ${isOpen ? 'open' : ''}`}>
              {summaryDetails?.summarized_text}
            </p>
            <div className="flex justify-end">
              <ActionItem
                icon={isOpen ? <ArrowUpIcon /> : <ArrowDown color="#B987E1" />}
                text={isOpen ? 'Hide Summary' : 'Show Summary'}
                isVisible={true}
                textClass={`text-wrapper-27`}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            </div>
          </div>
          {/* Unitary Metrics */}
          <div className="p-10">
            <p className=" font-bold mb-12">Unitary Metrics</p>
            <div className="grid grid-cols-2 gap-x-10 ">
              <div className="">
                <div className="flex justify-between text-gray-500 text-sm">
                  <div className="flex">
                    <span className="pr-2">
                      <InfoIcon />
                    </span>
                    <p className="leading-5">Readability Score</p>
                  </div>
                  <p className="leading-5">{summaryDetails?.evaluation_metrics?.readability_score?.toFixed(5)}</p>
                </div>
                <Divider />
              </div>
              <div className="">
                <div className="flex justify-between text-gray-500 text-sm">
                  <div className="flex">
                    <span className="pr-2">
                      <InfoIcon />
                    </span>
                    <p className="leading-5">Topic Coherence</p>
                  </div>
                  <p className="leading-5">{summaryDetails?.evaluation_metrics?.topic_coherence?.toFixed(5)}</p>
                </div>
                <Divider />
              </div>
              <div className="">
                <div className="flex justify-between text-gray-500 text-sm">
                  <div className="flex">
                    <span className="pr-2">
                      <InfoIcon />
                    </span>
                    <p className="leading-5">Compression Ratio</p>
                  </div>
                  <p className="leading-5">{summaryDetails?.evaluation_metrics?.compression_ratio?.toFixed(5)}</p>
                </div>
                <Divider />
              </div>
              <div className="">
                <div className="flex justify-between text-gray-500 text-sm">
                  <div className="flex">
                    <span className="pr-2">
                      <InfoIcon />
                    </span>
                    <p className="leading-5">Sentence Similarity</p>
                  </div>
                  <p className="leading-5">{summaryDetails?.evaluation_metrics?.sentence_similarity?.toFixed(5)}</p>
                </div>
                <Divider />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AppLoader />
      )}
    </>
  );
};
