import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CommonLibraryService } from '../../common/services/library/common-library.service';
import { patternManagementActions } from '../actions/pattern-management.actions';
import { Library, LibraryCreateOrUpdateRequest,  LibraryMap, LibraryMapLite } from '../../types/models';
import { setNotification } from '../actions/notification.actions';
import { PatternLibraryService } from '../../common/services/library/pattern-library.service';
import { PayloadAction } from '@reduxjs/toolkit';
import { browserHistory } from '../helpers/history/history';
import { RoutePath } from '../../common/constants/route-path';

function* getSystemLibraries() {
  try {
      const libraries: LibraryMapLite[] = yield call(CommonLibraryService.getSystemLibrariesLite);
      yield put(patternManagementActions.setSystemPatternsSuccess(libraries));
  } catch (error) {
    yield put(setNotification({ title: 'Error while fetching system libraries', type: 'error' }));
  }
}
function* initilizeLibraries(): any{
  try {
    let [systemLibraries, customLibraries, userActiveLibraries] = yield all([
      call(CommonLibraryService.getSystemLibrariesLite),
      call(CommonLibraryService.getCustomLibraries),
      call(CommonLibraryService.getUserActivePatterns),
    ]);
    const updatePatternSelection = (pattern:LibraryMapLite) => {
      pattern.isSelected = userActiveLibraries.findIndex((activePattern:LibraryMap) => activePattern.libraryId === pattern.id) !== -1;
      return pattern;
    };

    systemLibraries = systemLibraries.map(updatePatternSelection);
    customLibraries = customLibraries.map(updatePatternSelection);

    systemLibraries = systemLibraries.sort((a:LibraryMapLite, b:LibraryMapLite) => {
      if (a.isSelected && !b.isSelected) {
        return -1;
      }
      if (!a.isSelected && b.isSelected) {
        return 1;
      }
      return 0;
    });
    yield put(patternManagementActions.setSystemPatternsSuccess(systemLibraries));
    yield put(patternManagementActions.setUserPatternsSuccess(customLibraries));
    yield put(patternManagementActions.setUserActivePatternsSuccess(userActiveLibraries));
  } catch (error) {
    yield put(patternManagementActions.fetchLibrariesFailure("Error while fetching libraries"));
  }
}


function* getLibraryDetailsById({ payload }: PayloadAction<string>) {
  try {
      const libraries: Library = yield call(CommonLibraryService.getCustomPatternById, payload);
      yield put(patternManagementActions.getCustomPatternByIdSuccess(libraries));
  } catch (error) {
    yield put(patternManagementActions.getCustomPatternByIdFail);
    yield put(setNotification({ title: 'Error while fetching library details', type: 'error' }));
  }
}




function* getCustomLibraries() {
  try {
      const libraries: LibraryMapLite[] = yield call(CommonLibraryService.getCustomLibraries);
      yield put(patternManagementActions.setUserPatternsSuccess(libraries));
  } catch (error) {
    yield put(patternManagementActions.setUserPatternsFail);
    yield put(setNotification({ title: 'Error while fetching system libraries', type: 'error' }));
  }
}


function* uploadCustomPattern({ payload }: PayloadAction<File>) {
  try {
      const libraries: LibraryMapLite[] = yield call(CommonLibraryService.uploadCustomPattern, payload);
      yield put(patternManagementActions.setUserPatternsSuccess(libraries));
      yield put(setNotification({ title: 'Pattern uploaded successfully', type: 'success' }));
  } catch (error) {
    yield put(patternManagementActions.setUserPatternsFail);
    yield put(setNotification({ title: 'Error while fetching system libraries', type: 'error' }));
  }
}


function* createCustomLibrary({ payload }: PayloadAction<Library>) {
  try {
      let response:Library = yield call(CommonLibraryService.createCustomPattern, payload);
      yield put(patternManagementActions.createLibrarySuccess(response));
      yield put(setNotification({ title: 'Custom Pattern Created', type: 'success' }));

  } catch (error) {
    yield put(setNotification({ title: 'Error while creating library', type: 'error' }));
  }
}




function* updateCustomLibrary({ payload }: PayloadAction<Library>) {
  try {
      let response:Library = yield call(CommonLibraryService.updateCustomPattern, payload);
      yield put(setNotification({ title: 'Custom Pattern Updated', type: 'success' }));
      browserHistory.replace(`${RoutePath.patternConfiguration}/${response.id}`);
  } catch (error) {
    yield put(setNotification({ title: 'Error while creating library', type: 'error' }));
  }
}


function* setSystemPattern({ payload }: PayloadAction<LibraryMap>) {
  try {
    debugger
    yield call(CommonLibraryService.toggleLibraryOption, payload);
    yield put(patternManagementActions.setPatternSelectionSuccess(payload));
    if (payload.isEnabled) {
      yield put(setNotification({ title: 'Pattern enabled successfully', type: 'success' }));
    } else {
      yield put(setNotification({ title: 'Pattern disabled successfully', type: 'success' }));
    }
  } catch (error) {
    yield put(setNotification({ title: 'Error while fetching system libraries', type: 'error' }));
  }
}
export default function* watcher() {
  yield takeLatest(patternManagementActions.updateLibrary.type, updateCustomLibrary);
  yield takeLatest(patternManagementActions.createLibrary.type, createCustomLibrary);
  yield takeLatest(patternManagementActions.setSystemPatterns.type, getSystemLibraries);
  yield takeLatest(patternManagementActions.setPatternSelection.type, setSystemPattern);
  yield takeLatest(patternManagementActions.setUserPatterns.type, getCustomLibraries);
  yield takeLatest(patternManagementActions.initilizeLibraries.type, initilizeLibraries);
  yield takeLatest(patternManagementActions.uploadCustomJson.type, uploadCustomPattern);
  yield takeLatest(patternManagementActions.getCustomPatternById.type, getLibraryDetailsById);
}
