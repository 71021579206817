import { Navigate, useNavigate, useNavigation } from 'react-router-dom';
import { LogoIconDetailed } from '../../common/icons/LogoIconDetailed';
import { RoutePath } from '../../common/constants/route-path';

export const LogoDetailed = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(RoutePath.Home);
  };

  return <div onClick={handleClick}>{<LogoIconDetailed />}</div>;
};
