import { HttpClient } from '../http';
import { UpdateProfileRequest } from './models/update-profile-request';
import { Profile } from '../../models/user/profile';

class ProfileServiceImpl {
  private servicePort = 3002;

  async getProfile() {
    return HttpClient.get<Profile>('/user/me', { portNumber: 3002 }).then((response) => response.data);
  }

  
  async getRemainingCount() {
    return HttpClient.get<number>('/request-tracker/remaining', { portNumber: 3002 }).then((response:any) => response.data.remainingRequests ?? 0);
  }

  async updateProfile(request: UpdateProfileRequest) {
    return HttpClient.put<Profile>('/user/me', request).then((response) => response.data);
  }

  async getIsPlatformAdmin(token: string) {
    const response = await HttpClient.post<{ user: { isPlatformAdmin: boolean } }>('/token/validate', { token });
    return response.data.user.isPlatformAdmin;
  }
}

const ProfileService = new ProfileServiceImpl();

export { ProfileService };
