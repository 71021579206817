import { createSlice } from '@reduxjs/toolkit';
import { USER_PREFERENCES_STORE_NAME, userPreferencesActions } from '../actions/user-preference.actions';
import {
  UserPreferenceRequest,
  PreferenceFlags,
} from '../../common/services/user-preferences/models/user-preference-request';
import {
  USER_PREFERENCE_ACCESSIBILITY_SETTINGS,
  USER_PREFERENCE_EMAIL_NOTIFICATIONS,
  USER_PREFERENCE_FONTSIZE,
  USER_PREFERENCE_IN_APP_NOTIFICATIONS,
  USER_PREFERENCE_NEWS_LETTER,
  USER_PREFERENCE_PUSH_NOTIFICATIONS,
  USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION,
  USER_PREFERENCE_NO_OF_RECORDS,
  USER_PREFERENCE_CACHED_RESULTS,
  USER_PREFERENCE_ARCHIEVE_HISTORY,
  USER_PREFERENCE_DEFAULT_LLM,
  DEFAULT_LLM_NAME,
  DEFAULT_FONT_SIZE,
} from '../../common/constants/app-text';
import { profileActions } from '../actions/profile.actions';

export interface UserPreferencesState {
  preferences: UserPreferenceRequest;
  errorCode: string;
  loading: boolean;
  preferenceFlagsDto: PreferenceFlags;
}

export const initialUserPreferencesState: UserPreferencesState = {
  preferences: {
    id: '',
    domainId: '',
    language: 'en',
    timeZone: 'PST',
    preferenceFlagsDto: [],
  },
  preferenceFlagsDto: {
    twoFactorAuthentication: false,
    fontSize: 'small',
    accessibilitySettings: false,
    twofactorAuthentication: false,
    emailNotifications: false,
    pushNotifications: false,
    inAppNotifications: false,
    newsletter: false,
    numberofRecords: 5,
    cacheResults: false,
    archieveHistory: false,
    defaultLLM: 'ChatGPT',
  },
  errorCode: '',
  loading: false,
};

export const userPreferencesSlice = createSlice({
  name: USER_PREFERENCES_STORE_NAME,
  initialState: initialUserPreferencesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userPreferencesActions.getUserPreferences, (state) => {
        state.loading = true;
        state.errorCode = '';
      })
      .addCase(userPreferencesActions.getUserPreferencesSuccess, (state, { payload }) => {
        state.preferences = payload;
        state.preferenceFlagsDto.twoFactorAuthentication =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION == e.name)
            ?.value ?? false;
        state.preferenceFlagsDto.accessibilitySettings =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_ACCESSIBILITY_SETTINGS == e.name)?.value ??
          false;
        state.preferenceFlagsDto.emailNotifications =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_EMAIL_NOTIFICATIONS == e.name)?.value ??
          false;
        state.preferenceFlagsDto.inAppNotifications =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_IN_APP_NOTIFICATIONS == e.name)?.value ??
          false;
        state.preferenceFlagsDto.pushNotifications =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_PUSH_NOTIFICATIONS == e.name)?.value ??
          false;
        state.preferenceFlagsDto.newsletter =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_NEWS_LETTER == e.name)?.value ?? false;
        state.preferenceFlagsDto.fontSize =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_FONTSIZE === e.name)?.value ??
          DEFAULT_FONT_SIZE;
        state.preferenceFlagsDto.cacheResults =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_CACHED_RESULTS == e.name)?.value ?? false;
        state.preferenceFlagsDto.archieveHistory =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_ARCHIEVE_HISTORY == e.name)?.value ?? false;
        state.preferenceFlagsDto.defaultLLM =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_DEFAULT_LLM === e.name)?.value ??
          DEFAULT_LLM_NAME;
        state.preferenceFlagsDto.numberofRecords =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_NO_OF_RECORDS === e.name)?.value ?? 5;
        state.loading = false;
      })
      .addCase(userPreferencesActions.getUserPreferencesFailure, (state, { payload }) => {
        state.errorCode = '';
        state.loading = false;
      })
      .addCase(userPreferencesActions.updateUserPreferences, (state) => {
        state.loading = true;
        state.errorCode = '';
      })
      .addCase(userPreferencesActions.updateUserPreferencesSuccess, (state, { payload }) => {
        state.preferences = payload;
        state.preferenceFlagsDto.twoFactorAuthentication =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_TWO_FACTOR_AUTHENTICATION == e.name)
            ?.value ?? false;
        state.preferenceFlagsDto.accessibilitySettings =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_ACCESSIBILITY_SETTINGS == e.name)?.value ??
          false;
        state.preferenceFlagsDto.emailNotifications =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_EMAIL_NOTIFICATIONS == e.name)?.value ??
          false;
        state.preferenceFlagsDto.inAppNotifications =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_IN_APP_NOTIFICATIONS == e.name)?.value ??
          false;
        state.preferenceFlagsDto.pushNotifications =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_PUSH_NOTIFICATIONS == e.name)?.value ??
          false;
        state.preferenceFlagsDto.newsletter =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_NEWS_LETTER == e.name)?.value ?? false;
        state.preferenceFlagsDto.fontSize =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_FONTSIZE === e.name)?.value ??
          DEFAULT_FONT_SIZE;
        state.preferenceFlagsDto.cacheResults =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_CACHED_RESULTS == e.name)?.value ?? false;
        state.preferenceFlagsDto.archieveHistory =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_ARCHIEVE_HISTORY == e.name)?.value ?? false;
        state.preferenceFlagsDto.defaultLLM =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_DEFAULT_LLM === e.name)?.value ??
          DEFAULT_LLM_NAME;
        state.preferenceFlagsDto.numberofRecords =
          state.preferences.preferenceFlagsDto.find((e) => USER_PREFERENCE_NO_OF_RECORDS === e.name)?.value ?? 5;
        state.loading = false;
      })
      .addCase(userPreferencesActions.updateUserPreferencesFailure, (state, { payload }) => {
        state.errorCode = '';
        state.loading = false;
      })
      .addCase(profileActions.logout, (state) => {
        Object.assign(state, initialUserPreferencesState);
      });
  },
});

export const userPreferencesReducer = userPreferencesSlice.reducer;
