import {
  Middleware,
  combineReducers,
  configureStore,
  createSerializableStateInvariantMiddleware,
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { articleReducer } from './slices/article.slice';
import { appRenderConfigReducer } from './slices/appSlice';
import rootSaga from './sagas';
import { isSerializable } from './helpers/is-serializable';
import { isIterable } from './helpers/arrays/is-iterable';
import { profileReducer } from './slices/profile.slice';
import logger from 'redux-logger';
import { layoutReducer } from './slices/layout.slice';
import { passwordRecoveryReducer } from './slices/password-recovery.slice';
import { notificationReducer } from './reducers/notification.reducer';
import { DossierReducer } from './slices/dossier.slice';
import { DossierOrgReducer } from './slices/dossier-org.slice';
import { PromptReducer } from './slices/prompt.slice';
import { clientAppReducer } from './slices/client-app.slice';
import { externalSearchReducer } from './slices/external-search.slice';
import { contradictionReducer } from './slices/contradiction.slice';
import { userPreferencesReducer } from './slices/user-preference.slice';
import { accessManagementReducer } from './slices/access-management.slice';
import { dashboardReducer } from './slices/dashboard.slice';
import { federatedReducer } from './slices/federated.slice';
import { CodeAnalysisReducer } from './slices/codeAnalysis.slice';
import { summarizationReducer } from './slices/summarization.slice';
import { patternManagementReducer } from './slices/pattern-management.slice';
import { projectsReducer } from './slices/projects.slice';
const configureSerializabilityMiddleware = () => {
  const getEntries = (value: any) => (isIterable(value) ? value.entries() : Object.entries(value));
  return createSerializableStateInvariantMiddleware({
    isSerializable,
    getEntries,
    ignoredActions: [],
  });
};

export function configureAppStore() {
  const sagaMiddleware = createSagaMiddleware();

  const middleware: Middleware[] = [sagaMiddleware];

  if (process.env.NODE_ENV === 'development') {
    const serializableMiddleware = configureSerializabilityMiddleware();
    middleware.push(serializableMiddleware);
    middleware.push(logger as Middleware);
  }

  const rootReducer = combineReducers({
    article: articleReducer,
    appRenderState: appRenderConfigReducer,
    profile: profileReducer,
    dossierOrg: DossierOrgReducer,
    prompt: PromptReducer,
    layout: layoutReducer,
    dossier: DossierReducer,
    passwordRecover: passwordRecoveryReducer,
    notification: notificationReducer,
    clientApp: clientAppReducer,
    externalSearch: externalSearchReducer,
    contradiction: contradictionReducer,
    userPreference: userPreferencesReducer,
    accessManagement: accessManagementReducer,
    dashboard: dashboardReducer,
    federated: federatedReducer,
    patternManagement: patternManagementReducer,
    codeAnalysis: CodeAnalysisReducer,
    summarization: summarizationReducer,
    projects: projectsReducer,
  });
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
  });

  sagaMiddleware.run(rootSaga);

  return { store };
}
