import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { applicationRoles } from '../../../redux/selectors/access-management.selectors';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CheckMarkIcon } from '../../../common/icons/Icons';
import { RoleDetails } from '../../../common/services/roles/models/roles';
import { UsersList } from '../users/usersList';
import { PermissionsUpdateModal } from '../../../components/Modal/PermissionsUpdateModel/PermissionsUpdateModel';
import { accessManagementActions } from '../../../redux/actions/access-management.actions';
import { User } from '../../../common/models/user/user';
import { UserService } from '../../../common/services/users/user.service';
import { UserQueryParams } from '../../../common/services/users/models/user-query-params';
import { activeOrganizationId, userDomainName } from '../../../redux/selectors/profile.selectors';

export const RolesDetailedView = (): JSX.Element => {
  const roleDetails = useSelector(applicationRoles);
  const domainName = useSelector(userDomainName);
  const activeOrgId: string = useSelector(activeOrganizationId);
  const [currentRoleDetails, setCurrentRoleDetails] = useState<RoleDetails>({} as RoleDetails);
  const { role } = useParams<{ role: string }>();
  const dispatch = useDispatch();
  const [updatePermissionsState, setUpdatePermissionsState] = useState({
    show: false,
    permissions: [] as string[],
    roleId: '',
  });
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (role) {
      getUsersForRole(role);
    }
  }, [role]);

  function getUsersForRole(roleName: string) {
    let query = { page: 1, pageSize: 25, domainName, role: roleName } as UserQueryParams;
    UserService.get(activeOrgId, query)
      .then((usersData) => {
        if (usersData) {
          setUsers(usersData.users);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch users', error);
      });
  }
  useEffect(() => {
    if (roleDetails) {
      const roleInfo = roleDetails.find((roleDetail) => roleDetail.name === role);
      if (roleInfo) {
        setCurrentRoleDetails(roleInfo);
        setUpdatePermissionsState({ roleId: roleInfo.id, permissions: roleInfo.permissions ?? [], show: false });
      }
    }
  }, [role]);

  return (
    <>
      <div className="flex flex-wrap justify-center  ">
        {currentRoleDetails ? (
          <>
            <div className=" w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-3/10">
              <div key={'userRoles'} className="p-2">
                <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                  <h5 className="mb-4 text-xl text-text-primary font-semibold ">{currentRoleDetails.name}</h5>
                  <div className="flex items-baseline text-gray-900 dark:text-white">
                    <span className="ms-1 text-sm text-gray-500 dark:text-gray-400">
                      {currentRoleDetails.permissions?.length} Permissions
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    {currentRoleDetails.permissions?.map((permission, indexKey) => (
                      <li key={indexKey} className="flex flex-wrap items-center">
                        <CheckMarkIcon />
                        <span className=" text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
                          {permission}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <div className="flex justify-end">
                    {/* <PrimaryButton
                    className="mx-2 bg-gradient-to-tl from-blue-500 to-violet-500"
                    size="small"
                    stateProp="rest"
                    style="boxed"
                    text="View Role"
                    onClick={() => {
                     }}
                  />  
                   */}
                    <PrimaryButton
                      className="mx-2 bg-gradient-to-tl from-blue-500 to-violet-500"
                      size="small"
                      stateProp="rest"
                      style="boxed"
                      text="Manage Permissions"
                      onClick={() => {
                        setUpdatePermissionsState((prevState) => ({ ...prevState, show: true }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-2/3 lg:w-3/4 xl:w-7/10   p-2  rounded-lg">
              <UsersList users={users} />
            </div>
          </>
        ) : (
          <div>Role Not Found</div>
        )}
      </div>
      {
        <PermissionsUpdateModal
          isShown={updatePermissionsState.show}
          permissions={updatePermissionsState.permissions}
          roleId={currentRoleDetails.id}
          onClose={() =>
            setUpdatePermissionsState({
              show: false,
              permissions: [],
              roleId: '',
            })
          }
          onSaveClick={(permissions) => {
            dispatch(
              accessManagementActions.updateRolePermissions({
                roleId: currentRoleDetails.id,
                permissions: permissions,
              }),
            );
            setUpdatePermissionsState({
              show: false,
              permissions: [],
              roleId: '',
            });
          }}
        />
      }
    </>
  );
};
