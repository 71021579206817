import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { RoutePath } from '../../common/constants/route-path';
import {
  mapDossierOrganizationToDossierLite,
  mapPromptProtectLiteToUserHistoryList,
} from '../../common/helpers/Helper';
import { DossierOrganizationService } from '../../common/services/dossier/organization/dossier-org.service';
import { PromptProtectService } from '../../common/services/prompt/prompt.service';
import {
  ResultListLite,
  DossierOrganizaitonLite,
  DossierLite,
  DossierOrganizaiton,
  ProcessDossierRequest,
  DossierUpdateRequest,
  PromptProtect,
  PromptProtectLite,
  UserHistoryList,
  ProcessPromoteRequest,
} from '../../types/models';
import { setNotification } from '../actions/notification.actions';
import { PromptActions } from '../actions/prompt.actions';
import { browserHistory } from '../helpers/history/history';
import { profileActions } from '../actions/profile.actions';

export function* getPrompts({ payload }: PayloadAction<number>) {
  try {
    var userDossierOrgs: ResultListLite<PromptProtectLite> = yield call(
      PromptProtectService.getPromptList,
      payload,
      10,
    );
    const mappedData: ResultListLite<UserHistoryList> = {
      ...userDossierOrgs,
      data: userDossierOrgs.data.map(mapPromptProtectLiteToUserHistoryList),
    };
    yield put(PromptActions.getUserPromptsListSuccess(mappedData));
  } catch (err: any) {
    yield put(PromptActions.getUserPromptsListFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* getDefaultPrompt() {
  try {
    const defaultDossierOrg: PromptProtect = yield call(PromptProtectService.getDefaultPrompt);
    yield put(PromptActions.getDefaultPromptSuccess(defaultDossierOrg));
  } catch (err: any) {
    yield put(PromptActions.getDefaultPromptFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* processPrompt({ payload }: PayloadAction<string>) {
  try {
    let data = {
      check_for_profanity: true,
      prompt: payload,
    } as ProcessPromoteRequest;
    const processPromptProtect: PromptProtect = yield call(PromptProtectService.processPrompt, data);
    yield put(PromptActions.processPromptSuccess(processPromptProtect));
  } catch (err: any) {
    yield put(PromptActions.processPromptFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
    yield put(PromptActions.processPromptEnabled(false));
    yield put(profileActions.getUserLimit());
  }
}

export function* setPromptProtectFavorite({ payload }: PayloadAction<DossierUpdateRequest>) {
  try {
    const updateDossierOrgResult: PromptProtect = yield call(PromptProtectService.updatePrompt, payload);
    yield put(PromptActions.setPromptFavoriteSuccess(updateDossierOrgResult));
    let message = payload.isFavorite ? 'Prompt  marked as favorite' : 'Prompt removed from favorite';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(PromptActions.setPromptFavoriteFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* getPromptById({ payload }: PayloadAction<string>) {
  try {
    const DossierOrgDetails: PromptProtect = yield call(PromptProtectService.getPromptProtectById, payload);
    if (DossierOrgDetails) {
      yield put(PromptActions.getPromptByIdSuccess(DossierOrgDetails));
    } else {
      yield put(PromptActions.getPromptByIdFailure({ message: 'Prompt Not Found', statusCode: 404 }));
    }
  } catch (err: any) {
    yield put(PromptActions.getPromptByIdFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to fetch prompt details', type: 'error' }));
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export function* deletePrompt({ payload }: PayloadAction<string>) {
  try {
    yield call(PromptProtectService.deletePrompt, payload);
    yield put(PromptActions.deletePromptSuccess(payload));
    yield put(setNotification({ title: 'prompt deleted successfully', type: 'success' }));
  } catch (err: any) {
    yield put(PromptActions.deletePromptFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to delete prompt', type: 'error' }));
  }
}

export function* clearPromptHistory(){
  try {
    const result: boolean = yield call(PromptProtectService.clearHistory);
    if(result){
      yield put(PromptActions.clearPromptHistorySuccess());
      yield put(setNotification({ title: 'Prompt  History Cleared', type: 'success' }));
    }else{
      yield put(PromptActions.clearPromptPersonHistoryFailure());
      yield put(setNotification({ title: 'Unable to clear Prompt  history', type: 'error' }));
    }
  } catch (err: any) {
    yield put(PromptActions.clearPromptPersonHistoryFailure());
  }
}



export const PROCESS_PROMPT_SAGA = 'Process Prompt Saga';

export const processDossierOrgSaga = createAction<string>(PROCESS_PROMPT_SAGA);

export default function* watcher() {
  yield takeLatest(PromptActions.processPrompt.type, processPrompt);
  yield takeEvery(PromptActions.getUserPromptsList.type, getPrompts);
  yield takeEvery(PromptActions.getDefaultPrompt.type, getDefaultPrompt);
  yield takeEvery(PromptActions.getPromptById.type, getPromptById);
  yield takeLatest(PromptActions.setPromptFavorite.type, setPromptProtectFavorite);
  yield takeLatest(PromptActions.clearPromptHistory.type, clearPromptHistory);
  yield takeLatest(PromptActions.deletePrompt.type, deletePrompt);
}
