import { FC, ReactNode } from 'react';

interface PropTypes {
  children: ReactNode;
  top?: boolean;
}

const ContentLayout: FC<PropTypes> = ({ children, top }: PropTypes) => (
  <main id="main-page" className={`main-content-margin flex-1 animte-size ${top && '!mt-0'}`}>
    {children}
  </main>
);

export default ContentLayout;
