import React from 'react';
import { useDispatch } from 'react-redux';
import { ArticleUpdateRequest } from '../../types/models';
import { FavoriteIcon, FavoriteIconPink } from '../../common/icons/Icons';
import { articleActions } from '../../redux/actions/article.actions';
import { ActionItem } from '../ActionItem/ActionItem';
interface ActionItemConfig {
  icon: JSX.Element;
  text: string;
  isVisible?: boolean;
  textClass?: string;
  onClick?: () => void;
}

interface PlugInResultActionsProps {
  actionItems: ActionItemConfig[];
  cssClass?: string;
}

export const PlugInResultActions: React.FC<PlugInResultActionsProps> = ({ actionItems, cssClass = '' }) => {
  return (
    <div className={`flex space-x-4  ${cssClass}`}>
      {actionItems.map((item, index) => (
        <ActionItem
          key={index}
          icon={item.icon}
          text={item.text}
          isVisible={item.isVisible}
          textClass={item.textClass}
          onClick={item.onClick}
        />
      ))}
    </div>
  );
};
