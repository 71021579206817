import SignUpPage from '../../components/Modal/SignUpModal/SignUpModal';
import { MainBackground } from '../Home/MainBackground';

export const SignUp = (): JSX.Element => {
  return (
    <div className="landing">
      <div className="div">
        <div className="main-content flex min-h-[90vh] overflow-auto ">
          <MainBackground>
            <SignUpPage />
          </MainBackground>
        </div>
        <></>
      </div>
    </div>
  );
};
