import { useDispatch } from 'react-redux';
import { profileActions } from '../../redux/actions/profile.actions';
import { LoginForm } from '../../types/models';
import { PrimaryButton } from '../PrimaryButton';
import { SecondaryButton } from '../SecondaryButton';
import { layoutActions } from '../../redux/actions/layout.actions';

export const SignInGroupPrimary = () => {
  const dispatch = useDispatch();
  const onSignInClick = () => {
    dispatch(layoutActions.setShowSignInModal(true));
  };
  const onSignUpClick = () => {
    dispatch(layoutActions.setShowSignUpModal(true));
  };
  return (
    <div className="flex space-x-4 items-center">
      <PrimaryButton
        onClick={onSignUpClick}
        className="mr-2 md:mr-4"
        size="medium"
        stateProp="rest"
        style="boxed"
        text="Create Account"
      />
      <SecondaryButton onClick={onSignInClick} className="" stateProp="rest" text="Sign In" />
    </div>
  );
};
