import { useDispatch, useSelector } from 'react-redux';
import { FeaturesList } from '../../../components/FeaturesList/FeaturesList';
import PasswordCreationForm from '../../../components/PasswordCreationForm';
import { passwordRecoverSelectors } from '../../../redux/selectors/password-recover.selectors';
import { passwordRecoveryActions } from '../../../redux/actions/password-recovery.actions';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../common/constants/route-path';
import { PasswordRecoveryStage } from '../../../redux/slices/password-recovery.slice';
import { loggedInUser } from '../../../redux/selectors/activeUser.selectors';
import Component from '../../../components/MessageBox/MessageBox';

export const NewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(passwordRecoverSelectors.selectToken);
  const userDetails = useSelector(loggedInUser);
  const passwordState = useSelector(passwordRecoverSelectors.selectStage);
  const isResetting = false;
  const isChangingPassword = window.location.pathname === RoutePath.ChangePassword;

  const PASSWORD_REQUIREMENTS = [
    'A minimum of 1 lower case letter (a-z)',
    'A minimum of 1 upper case letter (A-Z)',
    'A minimum of 1 numeric character (0-9)',
    'A minimum of 1 special character (~`!@#$%^&*()-_+={}[]|\\;:"<>,./?)',
  ];
  const handleSubmit = (password: string, currentpassword?: string) => {
    if (isChangingPassword) {
      if (currentpassword === undefined) {
        console.error('currentpassword is required for changing password');
        return;
      }
      const oldPassword = currentpassword;
      const newPassword = password;
      const email = userDetails?.email || '';
      dispatch(
        passwordRecoveryActions.changePassword({
          email,
          oldPassword,
          newPassword,
        }),
      );
      navigate(RoutePath.Landing);
    } else {
      dispatch(passwordRecoveryActions.resetPassword({ password, token }));
      navigate(RoutePath.SignIn);
    }
  };

  return (
    <div className="flex flex-wrap justify-center">
      <div className="w-full md:w-2/5 px-4 border-r card">
        <PasswordCreationForm
          onSubmit={(password, currentpassword) => handleSubmit(password, currentpassword)}
          isResetting={isResetting}
          isChangingPassword={isChangingPassword}
        />
      </div>
      <div className="w-full md:w-3/5 px-4">
        <div className="hidden md:block">
          {/* <FeaturesList /> */}
          <div className="flex flex-wrap justify-center mt-36">
        <Component title="Password must have:" type="information">
          <div className="leading-loose">
            <div className="leading-relaxed pt-1">
              {PASSWORD_REQUIREMENTS.map((requirement) => (
                <div className="marked-text marked-text-blue">{requirement}</div>
              ))}
            </div>
          </div>
        </Component>
      </div> 
        </div>
      </div>
    </div>
  );
};
