import { EDIT_TEXT, SHARE_TEXT, FAVORITE_TEXT } from '../../../common/constants/app-text';
import { EditIcon, FavoriteIcon, FavoriteIconPink, ShareIcon } from '../../../common/icons/Icons';
import { PersonalInformation } from '../../../common/services/dossier/person/dossierPerson';
import { articleActions } from '../../../redux/actions/article.actions';
import { ArticleUpdateRequest, DossierUpdateRequest } from '../../../types/models';
import { PlugInResultActions } from '../../PlugInResultActions/PlugInResultActions';
import { SocialMediaLinksGroup } from '../../SocialMedia/SocialMediaLinksGroup';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { dossierActions } from '../../../redux/actions/dossier.actions';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import { AuthContext } from '../../../config/authorization/AuthContext';
import { DOSSIER_GENERATOR_FAVORITES } from '../../../config/authorization/PermissionsConstants';

interface PersonalInformationContainerProps {
  personalInformation: PersonalInformation;
  isFavorite?: boolean;
  id: string;
}

export const PersonalInformationContainer: React.FC<PersonalInformationContainerProps> = ({
  personalInformation,
  id,
  isFavorite = false,
}) => {
  const dispatch = useDispatch();
  const profileImageClass = useRef('profile-image-man');
  const authContext = useContext(AuthContext);
  useEffect(() => {
    if (personalInformation?.Gender?.toLowerCase() === 'female') {
      profileImageClass.current = 'profile-image-woman';
    }
  }, [personalInformation.Gender, isFavorite]);

  return (
    <div className="flex items-center space-x-6 mb-4 bg-white p-2 animate__animated animate__fadeInUp">
      <div className="flex w-3/5 space-x-4">
        <div className={`h-24 w-24 rounded-full overflow-hidden ${profileImageClass.current} p-2`}></div>
        <div className="flex flex-col justify-center sm:text-left">
          <div className="flex text-xl w-full font-bold text-gray-900 sm:text-xl">
            <h1>{personalInformation?.FullName}</h1>
            <span className="m-1"> {isFavorite ? <FavoriteIcon /> : null}</span>
          </div>
          {/* <div className="grid grid-cols-3 gap-4 mt-2">
                                <p className="text-gray-500"><PhoneIcon className="h-6 w-6"/> Developer</p>
                                <p className="text-gray-500"><i className="fas fa-map-marker-alt"></i> SF, Bay Area</p>
                                <p className="text-gray-500"><MailIcon className="h-6 w-6"/> max@kt.com</p>
                            </div> */}
          <p className="mt-1.5 text-sm text-gray-500">
            Born on {personalInformation?.DateOfBirth} , {personalInformation.Gender} ,{personalInformation.Nationality}
          </p>
        </div>
      </div>
      <div className="w-2/5">
        <div className="flex flex-col justify-between">
          <SocialMediaLinksGroup socialMediaProfile={personalInformation?.SocialMediaProfiles} />
          <PlugInResultActions
            cssClass="justify-end"
            actionItems={[
              {
                icon: <EditIcon />,
                text: EDIT_TEXT,
                isVisible: false,
                onClick: () => {},
              },
              {
                icon: <ShareIcon />,
                text: SHARE_TEXT,
                onClick: () => {},
              },
              {
                icon: isFavorite ? <FavoriteIcon /> : <FavoriteIconPink />,
                text: FAVORITE_TEXT,
                isVisible: authContext?.checkPermission([DOSSIER_GENERATOR_FAVORITES]),
                textClass: isFavorite ? `text-primary` : 'text-wrapper-27 ',
                onClick: () => {
                  let updateArticle = {
                    isFavorite: !isFavorite,
                    id: id,
                    progressPercentage: 100,
                  } as DossierUpdateRequest;
                  dispatch(dossierActions.setDossierFavorite(updateArticle));
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
