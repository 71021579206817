import {
  favoriteArticlePagination,
  favoriteArticleSelector,
  favoritesCount,
  isProcessingArticle,
} from '../../redux/selectors/article.selectors';
import {
  favoriteDossierPagination,
  favoriteDossierSelector,
  favoritesDossierCount,
  isProcessingDossier,
} from '../../redux/selectors/dossier.selectors';
import {
  Assertion,
  AssertionResponse,
  AssertionResult,
  CodeAnalysisLightResponse,
  ContradictionResult,
  DossierLite,
  DossierOrganizaiton,
  DossierOrganizaitonLite,
  DossierPersonLite,
  DossierType,
  FavoriteArticles,
  Library,
  PatternDto,
  PromptProtectLite,
  RiskAssesmentRecord,
  RiskAssessment,
  RiskAssessmentResultView,
  SummarizationLite,
  UserHistoryList,
} from '../../types/models';
import { GuardRailEngineType, ExpressionType, SourceType } from '../constants/enums/engine-types.enum';
import { Dossier } from '../services/dossier/person/dossierPerson';
import {
  favoritePromptPaginationSelector,
  favoritesCountSelector,
  isProcessingPromptsSelector,
  userPromptsListSelector,
} from '../../redux/selectors/prompt.selectors';
import {
  favoriteContradictionPagination,
  favoriteContradictionSelector,
  favoritesContradictionCount,
  isProcessingContradiction,
} from '../../redux/selectors/contradiction.selectors';
import { AppState } from '../../redux/types/App.type';
import { PermissionMapping, RoleDetails } from '../services/roles/models/roles';
import { RecentActivity } from '../services/dashboard/models/dashboard.model';
import { AssessmentOptions, ModulesListEnum } from '../constants/default-values';
import { RoutePath } from '../constants/route-path';
import { LLMResponse } from '../services/llm/model/llm-response.model';
import {
  favoriteFederatedCount,
  favoriteFederatedPaginationSelector,
  isProcessingFactChecker,
  userFederatedList,
} from '../../redux/selectors/federated.selectors';
import {
  favoriteCodeRequestPaginationSelector,
  favoritesCodeRequestsCountSelector,
  isCodeAnalysisRunning,
  userCodeRequestListSelector,
} from '../../redux/selectors/codeAnalysis.selectors';
import {
  favoritesSummarizationCountSelector,
  favouriteSummarizationListSelector,
  favouriteSummarizationPaginationSelector,
} from '../../redux/selectors/summarization.selectors';
import { ImageBox } from '../../components/ImageBox/ImageBox';
import { BardIcon, GuardRailIcon } from '../icons/Icons';
import ChatGpt from '../../static/img/chat-gpt.png';
import ChatGptBlack from '../../static/img/chat-gpt-black.png';
import CoPilot from '../../static/img/copilot-new.png';

export function mapApiResponseToResult(apiResponse: AssertionResult): AssertionResult {
  const colors = ['#d6f5f8', '#ffe5e3', '#f6eaff', '#ecf0f7', '#f6f9ec', '#ecf1f8', '#f3f6e6', '#f3f6e6'];
  var result = { ...apiResponse };
  result.assertions = result.assertions.map((ele, index) => ({
    ...ele,
    colorCode: colors[index % colors.length],
    articleIndex: index + 1,
  }));
  const words = result.article.split(' ');
  let randomWords = [] as string[];
  const eligibleWords = words.filter((word) => word.length >= 5);

  if (eligibleWords.length < 5) {
    randomWords = eligibleWords;
  } else {
    while (randomWords.length < 4) {
      const randomIndex = Math.floor(Math.random() * words.length);
      const randomWord = words[randomIndex];
      if (!randomWords.includes(randomWord) && randomWord.length >= 5) {
        randomWords.push(randomWord);
      }
    }
  }

  result.tags = randomWords;

  return result;
}

export function mapContradictionApiResponseToResult(apiResponse: ContradictionResult): ContradictionResult {
  const colors = ['#d6f5f8', '#ffe5e3', '#f6eaff', '#ecf0f7', '#f6f9ec', '#ecf1f8', '#f3f6e6', '#f3f6e6'];
  var result = { ...apiResponse };
  result.article = result.assertions[0] ?? '';
  result.assertionsInfo = result.assertionsInfo.map((ele, index) => ({
    ...ele,
    colorCode: colors[index % colors.length],
    articleIndex: index + 1,
  }));
  const words = result.article.split(' ');
  let randomWords = [] as string[];
  const eligibleWords = words.filter((word) => word.length >= 5);
  if (eligibleWords.length < 5) {
    randomWords = eligibleWords;
  } else {
    while (randomWords.length < 4) {
      const randomIndex = Math.floor(Math.random() * words.length);
      const randomWord = words[randomIndex];
      if (!randomWords.includes(randomWord) && randomWord.length >= 5) {
        randomWords.push(randomWord);
      }
    }
  }

  result.tags = randomWords;

  return result;
}

export function mapDossierToPersonLite(dossier: Dossier): DossierLite {
  return {
    ...dossier,
    name: dossier.personalInformationDto?.FullName ?? '',
    id: dossier.dossierId ?? '',
    tag: DossierType.PERSON,
    type: DossierType.PERSON,
  };
}

const colors = ['red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink', 'gray', 'blueGray'];
export const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length - 1)];
};

export function mapDossierPersonLiteToDossierLite(dossierPersonLite: DossierPersonLite): DossierLite {
  return {
    id: dossierPersonLite.dossierId,
    name: dossierPersonLite?.personalInformationLightResponseDto.FullName ?? '', // assuming personalInformationDto has a name property
    updatedAt: dossierPersonLite.updatedAt,
    createdBy: dossierPersonLite.createdBy,
    createdAt: dossierPersonLite.createdAt,
    deletedAt: dossierPersonLite.deletedAt,
    isFavorite: dossierPersonLite.isFavorite,
    progressPercentage: dossierPersonLite.progressPercentage,
    type: DossierType.PERSON,
    tag: DossierType.PERSON,
  };
}

export function mapDossierOrganizationToDossierLite(dossierPersonLite: DossierOrganizaitonLite): DossierLite {
  return {
    id: dossierPersonLite.dossierOrgId,
    name: dossierPersonLite?.organizationInformationDto?.Name ?? '', // assuming personalInformationDto has a name property
    updatedAt: dossierPersonLite.updatedAt,
    createdBy: dossierPersonLite.createdBy,
    createdAt: dossierPersonLite.createdAt,
    deletedAt: dossierPersonLite.deletedAt,
    isFavorite: dossierPersonLite.isFavorite,
    progressPercentage: dossierPersonLite.progressPercentage,
    type: DossierType.ORGANIZATION,
    tag: 'Organization',
  };
}

export function mapPromptProtectLiteToUserHistoryList(promptProtectLite: PromptProtectLite): UserHistoryList {
  return {
    id: promptProtectLite.id,
    name: promptProtectLite.prompt, // Assuming 'prompt' maps to 'name'
    updatedAt: promptProtectLite.updatedAt,
    createdBy: promptProtectLite.createdBy,
    createdAt: promptProtectLite.createdAt,
    deletedAt: promptProtectLite.deletedAt,
    isFavorite: promptProtectLite.isFavorite,
    progressPercentage: promptProtectLite.progressPercentage,
    // 'tag' is optional in UserHistoryList and doesn't have a corresponding field in PromptProtectLite
  };
}

export function mapLLMPromptProtectLiteToUserHistoryList(promptProtectLite: LLMResponse): UserHistoryList {
  return {
    id: promptProtectLite.id,
    name: promptProtectLite.prompt, // Assuming 'prompt' maps to 'name'
    updatedAt: promptProtectLite.updatedAt,
    createdBy: promptProtectLite.createdBy,
    createdAt: promptProtectLite.createdAt,
    deletedAt: promptProtectLite.deletedAt,
    isFavorite: promptProtectLite.isFavorite,
    progressPercentage: promptProtectLite.progressPercentage,
    // 'tag' is optional in UserHistoryList and doesn't have a corresponding field in PromptProtectLite
  };
}
export function mapSummarizationToUserHistoryList(summary: SummarizationLite): UserHistoryList {
  return {
    id: summary.id,
    name: summary.summaryRequest.extractedText, // Showing the input text as the name in left menu
    updatedAt: summary.updatedAt,
    createdBy: summary.createdBy,
    createdAt: summary.createdAt,
    deletedAt: summary.deletedAt,
    isFavorite: summary.isFavorite,
    progressPercentage: summary.progressPercentage,
    // 'tag' is optional in UserHistoryList and doesn't have a corresponding field in PromptProtectLite
  };
}

export function mapDossierOrganizationToLite(dossier: DossierOrganizaiton): DossierLite {
  return {
    ...dossier,
    name: dossier?.organizationInformationDto?.Name ?? '',
    id: dossier?.dossierOrgId ?? '',
    tag: 'Organization',
    type: DossierType.ORGANIZATION,
  };
}

export function mapDossierToFavorite(articles: DossierLite[]): FavoriteArticles[] {
  articles.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  // Initialize an empty result object
  const result = {} as any;

  // Loop through the JSON data
  articles.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    const date = createdAt.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const time = createdAt.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Include AM/PM
    });

    // Determine the isCompleted value based on progressPercentage
    const isCompleted = item.progressPercentage === 100;

    // Create a new entry for the date if it doesn't exist
    if (!result[date]) {
      result[date] = {
        date: date,
        collections: [],
      };
    }

    // Push the item into the collections array for the corresponding date
    result[date].collections.push({
      id: item.id,
      time: time,
      tag: item.tag,
      percentage: item.progressPercentage,
      isCompleted: isCompleted,
      isFavorite: item.isFavorite,
      article: item?.name ?? '',
    });
  });

  // Convert the result object into an array of values
  const transformedData = Object.values(result) as FavoriteArticles[];
  return transformedData;
}
export function mapCodeAnalysisToFavorite(codeAnalysisHistory: CodeAnalysisLightResponse[]): FavoriteArticles[] {
  codeAnalysisHistory.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  // Initialize an empty result object
  const result = {} as any;

  // Loop through the JSON data
  codeAnalysisHistory.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    const date = createdAt.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const time = createdAt.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Include AM/PM
    });

    // Determine the isCompleted value based on progressPercentage
    const isCompleted = item.progressPercentage === 100;

    // Create a new entry for the date if it doesn't exist
    if (!result[date]) {
      result[date] = {
        date: date,
        collections: [],
      };
    }

    // Push the item into the collections array for the corresponding date
    result[date].collections.push({
      id: item.id,
      time: time,
      tag: '',
      extendProps: {
        showBillOfLadding: item.billOfLaddingId ?? false,
        showRiskAssessment: item.riskAssessmentId ?? false,
        showCodeReference: item.codeReferenceId ?? false,
      },
      percentage: item.progressPercentage,
      isCompleted: isCompleted,
      isFavorite: item.isFavorite,
      article: item?.code ?? '',
    });
  });

  // Convert the result object into an array of values
  const transformedData = Object.values(result) as FavoriteArticles[];
  return transformedData;
}

export function mapPromptToFavorite(articles: UserHistoryList[]): FavoriteArticles[] {
  articles.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  // Initialize an empty result object
  const result = {} as any;

  // Loop through the JSON data
  articles.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    const date = createdAt.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const time = createdAt.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Include AM/PM
    });

    // Determine the isCompleted value based on progressPercentage
    const isCompleted = item.progressPercentage === 100;

    // Create a new entry for the date if it doesn't exist
    if (!result[date]) {
      result[date] = {
        date: date,
        collections: [],
      };
    }

    // Push the item into the collections array for the corresponding date
    result[date].collections.push({
      id: item.id,
      time: time,
      tag: item?.tag ?? '',
      percentage: item.progressPercentage,
      isCompleted: isCompleted,
      isFavorite: item.isFavorite,
      article: item?.name ?? '',
    });
  });

  // Convert the result object into an array of values
  const transformedData = Object.values(result) as FavoriteArticles[];
  return transformedData;
}

export function mapLLMtToFavorite(articles: UserHistoryList[]): FavoriteArticles[] {
  articles.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  // Initialize an empty result object
  const result = {} as any;

  // Loop through the JSON data
  articles.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    const date = createdAt.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const time = createdAt.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Include AM/PM
    });

    // Determine the isCompleted value based on progressPercentage
    const isCompleted = item.progressPercentage === 100;

    // Create a new entry for the date if it doesn't exist
    if (!result[date]) {
      result[date] = {
        date: date,
        collections: [],
      };
    }

    // Push the item into the collections array for the corresponding date
    result[date].collections.push({
      id: item.id,
      time: time,
      tag: item?.tag ?? '',
      percentage: item.progressPercentage,
      isCompleted: isCompleted,
      isFavorite: item.isFavorite,
      article: item?.name ?? '',
    });
  });

  // Convert the result object into an array of values
  const transformedData = Object.values(result) as FavoriteArticles[];
  return transformedData;
}

export function mapDossierOrganzationToFavorite(articles: DossierLite[]): FavoriteArticles[] {
  // Initialize an empty result object
  const result = {} as any;

  // Loop through the JSON data
  articles.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    const date = createdAt.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const time = createdAt.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Include AM/PM
    });

    // Determine the isCompleted value based on progressPercentage
    const isCompleted = item.progressPercentage === 100;

    // Create a new entry for the date if it doesn't exist
    if (!result[date]) {
      result[date] = {
        date: date,
        collections: [],
      };
    }

    // Push the item into the collections array for the corresponding date
    result[date].collections.push({
      id: item.id,
      time: time,
      percentage: item.progressPercentage,
      isCompleted: isCompleted,
      isFavorite: item.isFavorite,
      article: item?.name ?? '',
    });
  });

  // Convert the result object into an array of values
  const transformedData = Object.values(result) as FavoriteArticles[];
  return transformedData;
}

export function mapArticlesToFavorite(articles: AssertionResult[]): FavoriteArticles[] {
  // Initialize an empty result object
  const result = {} as any;

  // Loop through the JSON data
  articles.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    const date = createdAt.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const time = createdAt.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Include AM/PM
    });

    // Determine the isCompleted value based on progressPercentage
    const isCompleted = item.progressPercentage === 100;

    // Create a new entry for the date if it doesn't exist
    if (!result[date]) {
      result[date] = {
        date: date,
        collections: [],
      };
    }

    // Push the item into the collections array for the corresponding date
    result[date].collections.push({
      id: item.id,
      time: time,
      percentage: item.progressPercentage,
      isCompleted: isCompleted,
      isFavorite: item.isFavorite,
      article: item.article,
    });
  });

  // Convert the result object into an array of values
  const transformedData = Object.values(result) as FavoriteArticles[];
  return transformedData;
}

export function mapContradictionToFavorite(articles: ContradictionResult[]): FavoriteArticles[] {
  // Initialize an empty result object
  const result = {} as any;

  // Loop through the JSON data
  articles.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    const date = createdAt.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const time = createdAt.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Include AM/PM
    });

    // Determine the isCompleted value based on progressPercentage
    const isCompleted = item.progressPercentage === 100;

    // Create a new entry for the date if it doesn't exist
    if (!result[date]) {
      result[date] = {
        date: date,
        collections: [],
      };
    }

    // Push the item into the collections array for the corresponding date
    result[date].collections.push({
      id: item.id,
      time: time,
      percentage: item.progressPercentage,
      isCompleted: isCompleted,
      isFavorite: item.isFavorite,
      article: item.assertions[0] ?? '',
    });
  });

  // Convert the result object into an array of values
  const transformedData = Object.values(result) as FavoriteArticles[];
  return transformedData;
}

export function mapApiDefaultToResponse(apiResponse: AssertionResponse): AssertionResult {
  return {
    id: apiResponse.id,
    userId: apiResponse.userId,
    article: apiResponse.article,
    updatedAt: apiResponse.updatedAt,
    createdBy: apiResponse.createdBy,
    createdAt: apiResponse.createdAt,
    deletedAt: apiResponse.deletedAt,
    progressPercentage: apiResponse.progressPercentage,
    assertions: apiResponse.assertions.map((result, index) => ({
      id: '',
      articleId: '', // You need to provide a way to generate or get this articleId
      colorCode: '', // You need to provide a way to generate or get this colorCode
      articleName: apiResponse.article,
      articleIndex: index + 1, // Assuming index starts from 1
      riskAssessment: '', // You need to provide a way to generate or get this riskAssessment
      assertPosition: 0, // You need to provide a way to generate or get this assertPosition
      topRanks: [result],
    })),
  };
}

export function getSortedAssertions(assertions: Assertion[], paragraph: string): Assertion[] {
  return assertions
    .map((sentence) => {
      const subWords = sentence.articleName.split(' ');
      let comparetxt = '';
      if (subWords.length > 5) {
        comparetxt = subWords.slice(0, 5).join(' ');
      } else {
        comparetxt = subWords.join(' ');
      }
      const index = paragraph.indexOf(comparetxt);
      return {
        id: sentence.id,
        articleId: sentence.articleId,
        articleName: sentence.articleName,
        assertPosition: sentence.assertPosition,
        topRanks: sentence.topRanks,
        index,
        sentence: comparetxt,
        colorCode: sentence.colorCode,
        articleIndex: index,
      };
    })
    .filter((item) => item.index !== -1)
    .sort((a, b) => a.articleIndex - b.articleIndex);
}

export function extractMainDomain(url: string): string {
  const urlObject = new URL(url);
  const parts = urlObject.hostname.split('.');
  const len = parts.length;
  if (len > 2) {
    return parts[len - 2];
  }
  return parts[0];
}

export function CurrentDateTime(createdDate?: string) {
  var date = new Date();
  if (createdDate) {
    date = new Date(createdDate);
  }
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  const formattedDate = date.toLocaleString('en-US', options);
  return <div>{formattedDate}</div>;
}

export function extractBaseUrl(url: string): string {
  const urlObject = new URL(url);
  return urlObject.host;
}

export function getFormatedDate(date: Date): string {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
}

export function wait(seconds: number) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export function convertObjectToArray(input: any) {
  const output = [];

  if (typeof input === 'object' && input !== null) {
    for (const key in input) {
      if (Object.hasOwnProperty.call(input, key)) {
        output.push({
          title: key,
          description: input[key],
        });
      }
    }
  } else if (typeof input === 'string') {
    const lowerCaseData = input.trim().toLowerCase();
    if (!(lowerCaseData === 'none publicly available' || lowerCaseData === 'n/a')) {
      output.push({
        title: '',
        description: input,
      });
    }
  }
  return output;
}

export function convertObjectToValueArray(input: any) {
  const output = [];

  if (typeof input === 'object' && input !== null) {
    for (const key in input) {
      if (Object.hasOwnProperty.call(input, key)) {
        output.push(input[key]);
      }
    }
  } else if (typeof input === 'string') {
    const lowerCaseData = input.trim().toLowerCase();
    if (!(lowerCaseData === 'none publicly available' || lowerCaseData === 'n/a')) {
      output.push(input);
    }
  }
  return output;
}
export const ensureArray = (data: any | string[]): any[] => {
  if (typeof data === 'string') {
    const lowerCaseData = data.trim().toLowerCase();
    if (lowerCaseData === 'none publicly available' || lowerCaseData === 'n/a' || lowerCaseData === 'Not available') {
      return [];
    }
  } else if (Array.isArray(data) && data.length && typeof data[0] === 'string') {
    let value = data[0].trim().toLowerCase();
    const lowerCaseData = value;
    if (lowerCaseData === 'none publicly available' || lowerCaseData === 'n/a' || lowerCaseData === 'not available') {
      return [];
    }
  }
  return Array.isArray(data) ? data : [data];
};

export function getUserListSelectorFromForEngine(engine = GuardRailEngineType.FactChecker) {
  let selectors = null;
  switch (engine) {
    case GuardRailEngineType.FactChecker:
      selectors = favoriteArticleSelector;
      break;
    case GuardRailEngineType.Dossier:
      selectors = favoriteDossierSelector;
      break;
    case GuardRailEngineType.ContractionFinder:
      selectors = favoriteContradictionSelector;
      break;
    case GuardRailEngineType.PromptProtect:
      selectors = userPromptsListSelector;
      break;

    case GuardRailEngineType.Federated:
      selectors = userFederatedList;
      break;
    case GuardRailEngineType.CodeAnalyzer:
      selectors = userCodeRequestListSelector;
      break;
    case GuardRailEngineType.Summarization:
      selectors = favouriteSummarizationListSelector;
      break;
    default:
      selectors = favoriteArticleSelector;
      break;
  }
  return selectors;
}

export function getPaginationConfigSelectorFromForEngine(engine = GuardRailEngineType.FactChecker) {
  let selectors = null;
  switch (engine) {
    case GuardRailEngineType.FactChecker:
      selectors = favoriteArticlePagination;
      break;
    case GuardRailEngineType.Dossier:
      selectors = favoriteDossierPagination;
      break;
    case GuardRailEngineType.ContractionFinder:
      selectors = favoriteContradictionPagination;
      break;
    case GuardRailEngineType.PromptProtect:
      selectors = favoritePromptPaginationSelector;
      break;
    case GuardRailEngineType.CodeAnalyzer:
      selectors = favoriteCodeRequestPaginationSelector;
      break;
    case GuardRailEngineType.Summarization:
      selectors = favouriteSummarizationPaginationSelector;
      break;
    case GuardRailEngineType.Federated:
      selectors = favoriteFederatedPaginationSelector;
      break;
    default:
      selectors = favoriteArticlePagination;
      break;
  }
  return selectors;
}

export function getCurrentActiveIdSelectorFromForEngine(engine = GuardRailEngineType.FactChecker, state: AppState) {
  let selectors = null;
  switch (engine) {
    case GuardRailEngineType.FactChecker:
      return state.article?.currentArticle?.id;
    case GuardRailEngineType.Dossier:
      return state.dossier.currentDossier?.dossierId;
    case GuardRailEngineType.ContractionFinder:
      return state.contradiction.currentContradiction?.id;
    case GuardRailEngineType.PromptProtect:
      return state.prompt.currentPrompt?.id;
    case GuardRailEngineType.CodeAnalyzer:
      return state.codeAnalysis.currentCodeAnalysis?.id;
    case GuardRailEngineType.Summarization:
      return state.summarization.currentSummarization?.id;
  }
}
export function getFavoriteCountSelectorFromForEngine(engine = GuardRailEngineType.FactChecker) {
  let selectors = null;
  switch (engine) {
    case GuardRailEngineType.FactChecker:
      selectors = favoritesCount;
      break;
    case GuardRailEngineType.Dossier:
      selectors = favoritesDossierCount;
      break;
    case GuardRailEngineType.ContractionFinder:
      selectors = favoritesContradictionCount;
      break;
    case GuardRailEngineType.PromptProtect:
      selectors = favoritesCountSelector;
      break;
    case GuardRailEngineType.CodeAnalyzer:
      selectors = favoritesCodeRequestsCountSelector;
      break;
    case GuardRailEngineType.Summarization:
      selectors = favoritesSummarizationCountSelector;
      break;
    case GuardRailEngineType.Federated:
      selectors = favoriteFederatedCount;
      break;
    default:
      selectors = favoritesCount;
      break;
  }
  return selectors;
}

export function getShowProgressBarSelectorFromForEngine(engine = GuardRailEngineType.FactChecker) {
  let selectors = null;
  switch (engine) {
    case GuardRailEngineType.FactChecker:
      selectors = isProcessingArticle;
      break;
    case GuardRailEngineType.Dossier:
      selectors = isProcessingDossier;
      break;
    case GuardRailEngineType.ContractionFinder:
      selectors = isProcessingContradiction;
      break;
    case GuardRailEngineType.PromptProtect:
      selectors = isProcessingPromptsSelector;
      break;
    case GuardRailEngineType.Federated:
      selectors = isProcessingFactChecker;
      break;
    case GuardRailEngineType.Gateway123:
      selectors = isProcessingFactChecker;
      break;
    case GuardRailEngineType.CodeAnalyzer:
      selectors = isCodeAnalysisRunning;
      break;
    default:
      selectors = isProcessingArticle;
      break;
  }
  return selectors;
}

export const createPermissionMapping = (roles: RoleDetails[], masterPermissions: string[]): PermissionMapping[] => {
  const permissionMap: { [key: string]: string[] } = {};

  roles.forEach((role) => {
    role.permissions?.forEach((permission) => {
      if (!permissionMap[permission]) {
        permissionMap[permission] = [];
      }
      if (!permissionMap[permission].includes(role.name)) {
        permissionMap[permission].push(role.name);
      }
    });
  });

  masterPermissions.forEach((permission) => {
    if (!permissionMap[permission]) {
      permissionMap[permission] = [];
    }
  });

  return Object.entries(permissionMap).map(([permissionName, allowedRoles]) => ({
    permissionName,
    allowedRoles,
  }));
};
export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: NodeJS.Timeout;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
export function generateReportForAreaChart(inputRecords: RecentActivity[]): any[] {
  // Convert datetime to date-only string and sort records by date
  let records =
    inputRecords.map((record) => {
      const date = new Date(record.date);
      record.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` as any;
      return record;
    }) ?? [];
  records.sort((a, b) => (a.date > b.date ? 1 : -1));

  // Group records by date
  const groupedRecords: { [key: string]: RecentActivity[] } = {};
  records.forEach((record) => {
    if (!groupedRecords[record.date]) {
      groupedRecords[record.date] = [];
    }
    groupedRecords[record.date].push(record);
  });

  // Distribute the 30 dates data into 10 days
  const dateKeys = Object.keys(groupedRecords);
  const dateChunks: string[][] = [];
  const chunkSize = Math.ceil(dateKeys.length / 10);
  for (let i = 0; i < dateKeys.length; i += chunkSize) {
    dateChunks.push(dateKeys.slice(i, i + chunkSize));
  }

  // Aggregate the data for each date chunk
  const report = dateChunks.map((dateChunk) => {
    const recordsInChunk = dateChunk.flatMap((date) => groupedRecords[date]);
    return {
      date: dateChunk.join(' to '),
      completed: recordsInChunk.filter((record) => record.progress === 100).length,
      favorite: recordsInChunk.filter((record) => record.isFavorite).length,
    };
  });

  return report;
}

export function getDetailsRouterFromModule(moduleName: ModulesListEnum) {
  let router = '';
  switch (moduleName) {
    case ModulesListEnum.FactChecker:
      router = RoutePath.FactCheckerResults;
      break;
    case ModulesListEnum.Dossier:
      router = `${RoutePath.Dossier}/person`;
      break;
    case ModulesListEnum.DossierOrg:
      router = `${RoutePath.Dossier}/organization`;
      break;
    case ModulesListEnum.PromptProtect:
      router = RoutePath.PromptProtect;
      break;
    case ModulesListEnum.ContradictionFinder:
      router = RoutePath.ContradictionFinder;
      break;
    default:
      router = RoutePath.FactCheckerResults;
      break;
  }
  return router;
}

type FieldMapper = {
  [fieldName: string]: (value: any) => any;
};

export function autoMapper<T>(obj: T, fieldMapper: FieldMapper): T {
  const result: any = { ...obj };
  for (const field in fieldMapper) {
    if (field in result) {
      result[field] = fieldMapper[field](result[field]);
    }
  }
  return result as T;
}

export function mapRiskAssessmentToResultView(riskAssessments: RiskAssessment[]): RiskAssessmentResultView[] {
  // Group RiskAssessments by check_type
  const grouped = riskAssessments.reduce((grouped: { [checkType: string]: RiskAssessment[] }, riskAssessment) => {
    if (Object.values(AssessmentOptions).includes(riskAssessment.check_type as AssessmentOptions)) {
      (grouped[riskAssessment.check_type] = grouped[riskAssessment.check_type] || []).push(riskAssessment);
    }
    return grouped;
  }, {});

  // Map each group to a RiskAssessmentResultView
  return Object.entries(grouped).map(([check_type, riskAssessments]) => {
    const risks: RiskAssesmentRecord[] = riskAssessments
      .filter((e) => e.result?.risk_detected)
      .map((riskAssessment) => ({
        code_segment: riskAssessment.code_segment,
        result: riskAssessment.result,
      }));

    const risk_detected = risks.some((risk) => risk.result && risk.result.risk_detected);

    return {
      check_type,
      risk_detected,
      risks,
    };
  }) as RiskAssessmentResultView[];
}

export const iconComponents: Record<SourceType, JSX.Element> = {
  [SourceType.GPT3]: <ImageBox key="gpt3" className="w-4 h-4" url={ChatGpt} />,
  [SourceType.GPT4]: <ImageBox key="gpt4" className="w-4 h-4" url={ChatGptBlack} />,
  [SourceType.Gemini]: (
    <div key="gemini" className="w-4 h-4">
      <BardIcon />
    </div>
  ),
  [SourceType.Guardrail]: (
    <div className="w-4 h-4">
      <GuardRailIcon />
    </div>
  ),
  [SourceType.Copilot]: <ImageBox className="h-4 w-4" url={CoPilot} />,
};

export const getPatternsToArrary = (patternLib: PatternDto) => {
  return patternLib.pattern?.split(',').map((pattern) => pattern.trim());
};

export const patternsStringToArray = (value: string, expressionType: ExpressionType) => {
  switch (expressionType) {
    case ExpressionType.fuzzyMatch || ExpressionType.stringArrary:
      return value.split(',');
    default:
      return [value];
  }
};
