import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import {
  EDIT_TEXT,
  FAVORITE_TEXT,
  FACT_CHECKER,
  PROCESSING_WAITING_MESSAGE_USER,
} from '../../common/constants/app-text';
import { ArrowDown, EditIcon, FactCheckerPinkIcon, FavoriteIconPink, FavoriteIcon } from '../../common/icons/Icons';
import { CurrentDateTime } from '../../common/helpers/Helper';
import { PlugInResultActions } from '../../components/PlugInResultActions/PlugInResultActions';
import { PrimaryButton } from '../../components/PrimaryButton';
import { FACT_CHECKER_FAVORITES } from '../../config/authorization/PermissionsConstants';
import { AuthContext } from '../../config/authorization/AuthContext';
import { ActionItem } from '../../components/ActionItem/ActionItem';
import { federatedActions } from '../../redux/actions/federated.actions';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import {
  currentActivePrompt,
  isProcessingFactChecker,
  processedFactChecker,
} from '../../redux/selectors/federated.selectors';
import LLMModels from '../../common/constants/llm-models';
import { GuardRailEngineType, SourceType } from '../../common/constants/enums/engine-types.enum';
import AppLoader from '../../components/AppLoader/AppLoader';
import HorizontalStepper from '../../components/Stepper/Stepper';
import { stepperSteps } from '../../common/constants/gateway123';
import Loading from '../../components/Loader/Loader';

interface LLMSResultViewProps {
  engineType?: GuardRailEngineType;
}

export const LLMSResultView: React.FC<LLMSResultViewProps> = ({ engineType }) => {
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const { id } = useParams<{ id: string }>();
  const activeLLMResponse = useSelector(currentActivePrompt);
  const navigate = useNavigate();
  const processedFactCheckerResult = useSelector(processedFactChecker);
  const isFactCheckerLoading = useSelector(isProcessingFactChecker);

  useEffect(() => {
    dispatch(federatedActions.toggleRequestSubmitted(false));
    if (id && activeLLMResponse?.id !== id) {
      dispatch(federatedActions.getPromptById(id));
    }
  }, [id]);

  useEffect(() => {
    if (engineType === GuardRailEngineType.Gateway123 && processedFactCheckerResult) {
      navigate('fact-check/' + processedFactCheckerResult?.id);
      dispatch(federatedActions.processArticleReset());
    }
  }, [processedFactCheckerResult, dispatch, engineType, navigate]);

  return activeLLMResponse ? (
    <div
      className={`flex justify-center px-5 mt-10 items-center animate__animated animate__fadeInUp mb-20 ${
        engineType === GuardRailEngineType.Gateway123 ? '' : 'ml-20'
      }`}
    >
      <div className="w-full px-10">
        {engineType === GuardRailEngineType.Gateway123 && <HorizontalStepper steps={stepperSteps} completedStep={2} />}
        <div className="group-6">
          <LLMResultHeader
            promptText={activeLLMResponse.prompt}
            authContext={authContext}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            engineType={engineType}
          />
          {engineType === GuardRailEngineType.Gateway123 && isFactCheckerLoading ? (
            <div className="mt-6 w-full">
              <div className="flex text-center items-center justify-center w-full mt-10 mb-5 animate__animated animate__zoomIn">
                <Loading className="h-8 w-8" gap="m-0 p-0" message="Getting Response" />
              </div>
              <div className="flex justify-center items-center mt-2 mb-2  animate__animated animate__fadeInUp">
                <div className="flex flex-col md:flex-row justify-between py-4">
                  <div className="flex space-x-6 items-center  mb-4 md:mb-0 md:mr-4">
                    <p className="don-t-want-to-wait">
                      <span className=" text-blue-600 font-bold">Don’t want to wait? </span>
                      <span className="text-gray-500  font-light"> {PROCESSING_WAITING_MESSAGE_USER} </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="font-bold text-left">All Responses</div>
              <div className="llm-result">
                <div className="flex flex-col">
                  {LLMModels.map((button, index) => (
                    <>
                      {activeLLMResponse?.output[button.sourceType] && (
                        <div>
                          <button
                            key={index}
                            className={`flex my-5 items-center cursor-pointer text-sm px-2 font-['Open_Sans'] text-black-gray  ${button.hoverColor} mb-2`}
                          >
                            <div className="">{button.icon}</div>
                            <span className="mx-4 font-semibold">{button.displayName}</span>
                          </button>
                          <div className="px-2 my-5 text-bg-light-dark font-['Open_Sans']">
                            <p>{activeLLMResponse?.output[button.sourceType]}</p>
                          </div>

                          <div>
                            <ActionItem
                              icon={<FactCheckerPinkIcon />}
                              text={FACT_CHECKER}
                              onClick={() => {
                                if (engineType === GuardRailEngineType.Gateway123) {
                                  dispatch(
                                    federatedActions.processArticle({
                                      prompt: activeLLMResponse?.output[button.sourceType] as string,
                                      sourceType: button.sourceType as SourceType,
                                    }),
                                  );
                                } else {
                                  dispatch(
                                    federatedActions.setFactCheckerText({
                                      prompt: activeLLMResponse?.output[button.sourceType] as string,
                                      sourceType: button.sourceType as SourceType,
                                    }),
                                  );
                                  navigate('fact-check');
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <AppLoader />
  );
};

interface LLMResultHeaderProps {
  promptText: string;
  isOpen: boolean;
  authContext: any;
  setIsOpen: any;
  disableHideInput?: boolean;
  engineType?: GuardRailEngineType;
}

export const LLMResultHeader: React.FC<LLMResultHeaderProps> = ({
  promptText,
  isOpen,
  authContext,
  setIsOpen,
  disableHideInput = false,
  engineType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEditText = () => {
    dispatch(federatedActions.setPromptContent(promptText));
    navigate(RoutePath.LLMResult);
  };
  const activeLLMResponse = useSelector(currentActivePrompt);
  return (
    <div className="overlap-3 mb-4">
      <div className="flex items-center justify-between">
        <div className="flex space-x-4 items-center">
          <div className="text-wrapper-25">Prompt</div>
        </div>
        <div className="flex space-x-4">
          <p className="support-text">{CurrentDateTime(new Date().toString())}</p>
        </div>
      </div>
      <div
        className={` collapsible-section mt-3 ${isOpen ? 'open' : ''} ${
          engineType === GuardRailEngineType.Gateway123 ? 'mb-8' : ''
        }`}
      >
        <p> {promptText} </p>
      </div>
      {engineType !== GuardRailEngineType.Gateway123 && (
        <div className="flex items-center justify-between mt-10 ">
          <div className="flex space-x-4">
            <PlugInResultActions
              actionItems={[
                {
                  icon: <EditIcon />,
                  text: EDIT_TEXT,
                  isVisible: true,
                  onClick: handleEditText,
                },
                // {
                //   icon: <GeneratePDFIcon />,
                //   isVisible: authContext?.checkPermission([FACT_CHECKER_SHARE]),
                //   text: Generate_PDF,
                //   onClick: () => {},
                // },
                {
                  icon: activeLLMResponse?.isFavorite ? <FavoriteIcon /> : <FavoriteIconPink />,
                  text: FAVORITE_TEXT,
                  isVisible: authContext?.checkPermission([FACT_CHECKER_FAVORITES]),
                  textClass: activeLLMResponse?.isFavorite ? `text-primary` : 'text-wrapper-27 ',
                  onClick: () => {
                    let updatePrompt = {
                      isFavorite: !activeLLMResponse?.isFavorite,
                      id: activeLLMResponse?.id ?? '',
                      progressPercentage: activeLLMResponse?.progressPercentage ?? 0,
                    };
                    dispatch(federatedActions.setPromptFavorite(updatePrompt));
                  },
                },
              ]}
            />
          </div>
          <div className="flex space-x-4">
            {isOpen ? (
              <div
                className="input-action-item"
                onClick={() => {
                  if (!disableHideInput) {
                    setIsOpen(false);
                  }
                }}
              >
                <div className="text-wrapper-27">Hide Input</div>
                <div className="text-wrapper-26"></div>
              </div>
            ) : (
              <PrimaryButton
                onClick={() => setIsOpen(true)}
                className=""
                size="small"
                stateProp="rest"
                style={`boxed`}
                icon={<ArrowDown />}
                iconPosition="right"
                text="Show Input"
              />
            )}
          </div>
        </div>
      )}
      <div className="rectangle-16 mb-2" />
    </div>
  );
};
