import { AccessManagementState } from '../slices/access-management.slice';
import { ClientAppState } from '../slices/client-app.slice';
import { AppState } from '../types/App.type';
import { createSelector } from '@reduxjs/toolkit';

const accessManagementAppState: (state: AppState) => AccessManagementState = (state) => state.accessManagement;
export const appPermissionMappings = createSelector(
  accessManagementAppState,
  ({ permissionMappings }) => permissionMappings,
);

export const appRolesWithPermissions = createSelector(
  accessManagementAppState,
  ({ applicationRoles }) => applicationRoles,
);

export const appUsersList = createSelector(accessManagementAppState, ({ usersResponse }) => usersResponse?.users);

export const appUsersInformation = createSelector(accessManagementAppState, ({ usersResponse }) => usersResponse);

export const applicationRoles = createSelector(accessManagementAppState, ({ applicationRoles }) => applicationRoles);

export const isUserUpdating = createSelector(accessManagementAppState, ({ isUpdatingUser }) => isUpdatingUser);
