import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { UserPreferencesState } from '../slices/user-preference.slice';
import { DashboardState } from '../slices/dashboard.slice';
import { federatedState } from '../slices/federated.slice';

const selectFederatedState: (state: AppState) => federatedState = (state) => state.federated;

export const showPromptProtectModel = createSelector(
  selectFederatedState,
  ({ showPromptProtectModal }) => showPromptProtectModal,
);

export const showPromptOptimserModel = createSelector(
  selectFederatedState,
  ({ showPromptOptimserModel }) => showPromptOptimserModel,
);
export const currentPrompt = createSelector(selectFederatedState, ({ currentPrompt }) => currentPrompt);
export const promptProtectCheckText = createSelector(
  selectFederatedState,
  ({ promptCheckPrompt }) => promptCheckPrompt,
);
export const isCheckingForProfanity = createSelector(
  selectFederatedState,
  ({ checkingForProfanity }) => checkingForProfanity,
);
export const promptProtectResult = createSelector(
  selectFederatedState,
  ({ processedPromptProtect }) => processedPromptProtect,
);
export const llmResponse = createSelector(selectFederatedState, ({ llmResponse }) => llmResponse);
export const currentActivePrompt = createSelector(
  selectFederatedState,
  ({ currentActivePrompt }) => currentActivePrompt,
);

export const isPromptProcessing = createSelector(
  selectFederatedState,
  ({ isPromptProcessing, isRequestSubmitted }) => (isPromptProcessing && isRequestSubmitted) ?? false,
);
export const isSubmittedRequest = createSelector(selectFederatedState, ({ isRequestSubmitted }) => isRequestSubmitted);

export const userFederatedList = createSelector(selectFederatedState, ({ favoritePrompts }) => favoritePrompts ?? []);

export const currentFactCheckerPrompt = createSelector(
  selectFederatedState,
  ({ currentFactCheckPrompt }) => currentFactCheckPrompt ?? [],
);

export const favoriteFederatedPaginationSelector = createSelector(
  selectFederatedState,
  ({ federatedPagination }) => federatedPagination,
);

export const favoriteFederatedCount = createSelector(
  selectFederatedState,
  ({ favoritePromptsCount }) => favoritePromptsCount,
);
export const processedFactChecker = createSelector(
  selectFederatedState,
  ({ processedFactChecker }) => processedFactChecker,
);
export const isProcessingFactChecker = createSelector(
  selectFederatedState,
  ({ isProcessingFactChecker }) => isProcessingFactChecker,
);
export const isPromptOptimierRunning = createSelector(
  selectFederatedState,
  ({ isPromptOptimierRunning }) => isPromptOptimierRunning,
);
export const promptOptimizerResult = createSelector(
  selectFederatedState,
  ({ currentPromptOptimizer }) => currentPromptOptimizer,
);
export const promptOptimiserLLMResponse = createSelector(
  selectFederatedState,
  ({ promptOptimiserLLMResponse }) => promptOptimiserLLMResponse,
);
export const optimisedPromptLLMResponse = createSelector(
  selectFederatedState,
  ({ optimisedPromptLLMResponse }) => optimisedPromptLLMResponse,
);
export const processingPromptOptimizerLLM = createSelector(
  selectFederatedState,
  ({ processingPromptOptimizerLLM }) => processingPromptOptimizerLLM,
);
export const processingOptimisedPromptLLM = createSelector(
  selectFederatedState,
  ({ processingOptimisedPromptLLM }) => processingOptimisedPromptLLM,
);
