import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { RoutePath } from '../../common/constants/route-path';
import { setNotification } from '../actions/notification.actions';
import { browserHistory } from '../helpers/history/history';
import {
  AssertionResult,
  CreateProjectRequest,
  GetProjectsRequest,
  ProjectUpdateRequest,
  ProjectsLite,
  ResultListLite,
} from '../../types/models';
import { ProjectsActions } from '../actions/projects.actions';
import { ProjectsService } from '../../common/services/AiProjects/projects.service';
import { ArticleService } from '../../common/services/article/article.service';

export function* getProjects({ payload }: PayloadAction<GetProjectsRequest>) {
  try {
    var userProjects: ResultListLite<ProjectsLite> = yield call(ProjectsService.getProjectsList, payload);
    yield put(ProjectsActions.getProjectsListSuccess(userProjects));
  } catch (err: any) {
    yield put(ProjectsActions.getProjectsListFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* getPinnedProjects({ payload }: PayloadAction<GetProjectsRequest>) {
  try {
    var pinnedProjects: ResultListLite<ProjectsLite> = yield call(ProjectsService.getProjectsList, payload);
    yield put(ProjectsActions.getPinnedProjectsSuccess(pinnedProjects));
  } catch (err: any) {
    yield put(ProjectsActions.getPinnedProjectsFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* createProject({ payload }: PayloadAction<CreateProjectRequest>) {
  try {
    const projectsResult: ProjectsLite = yield call(ProjectsService.createProject, payload);
    yield put(ProjectsActions.createProjectSuccess(projectsResult));
  } catch (err: any) {
    yield put(ProjectsActions.createProjectFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* getProjectById({ payload }: PayloadAction<string>) {
  try {
    const projectResult: ProjectsLite = yield call(ProjectsService.getProjectById, payload);
    if (projectResult) {
      yield put(ProjectsActions.getProjectByIdSuccess(projectResult));
    } else {
      yield put(ProjectsActions.getProjectByIdFailure({ message: 'Prompt Not Found', statusCode: 404 }));
    }
  } catch (err: any) {
    yield put(ProjectsActions.getProjectByIdFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to fetch project details', type: 'error' }));
    browserHistory.push(RoutePath.Landing);
  }
}

export function* updateProject({ payload }: PayloadAction<ProjectUpdateRequest>) {
  try {
    const projectResponse: ProjectsLite = yield call(ProjectsService.updateProject, payload);
    yield put(ProjectsActions.updateProjectSuccess(projectResponse));
    if (payload.pinToTop === undefined) {
      yield put(ProjectsActions.getProjectById(payload.id));
    } else {
      yield put(ProjectsActions.getPinnedProjects({ page: 1, pinToTop: true }));
    }
    let message = 'Project Updated';
    if (payload.pinToTop === true) {
      message = 'Project Pinned';
    } else if (payload.pinToTop === false) {
      message = 'Project Unpinned';
    }
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(ProjectsActions.updateProjectFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* getArticleById({ payload }: PayloadAction<string>) {
  try {
    const articleDetails: AssertionResult = yield call(ArticleService.getUserArticlesById, payload);
    if (articleDetails) {
      yield put(ProjectsActions.getArticleByIdSuccess(articleDetails));
    } else {
      yield put(ProjectsActions.getArticleByIdFailure({ message: 'Article Not Found', statusCode: 404 }));
    }
  } catch (err: any) {
    yield put(ProjectsActions.getArticleByIdFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to fetch article details', type: 'error' }));
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export function* deleteProject({ payload }: PayloadAction<string>) {
  try {
    yield call(ProjectsService.deleteProject, payload);
    yield put(ProjectsActions.deleteProjectSuccess(payload));
    yield put(setNotification({ title: 'Project deleted successfully', type: 'success' }));
  } catch (err: any) {
    yield put(ProjectsActions.deleteProjectFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to delete Project', type: 'error' }));
  }
}

export const PROJECTS_SAGA = 'Projects Saga';

export const processDossierOrgSaga = createAction<string>(PROJECTS_SAGA);

export default function* watcher() {
  yield takeLatest(ProjectsActions.createProject.type, createProject);
  yield takeEvery(ProjectsActions.getProjectsList.type, getProjects);
  yield takeEvery(ProjectsActions.getPinnedProjects.type, getPinnedProjects);
  yield takeEvery(ProjectsActions.getProjectById.type, getProjectById);
  yield takeLatest(ProjectsActions.updateProject.type, updateProject);
  yield takeLatest(ProjectsActions.deleteProject.type, deleteProject);
  yield takeEvery(ProjectsActions.getArticleById.type, getArticleById);
}
