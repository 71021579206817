export const CrossIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9336 13.3164C16.1445 13.5508 16.25 13.8203 16.25 14.125C16.25 14.4297 16.1445 14.6992 15.9336 14.9336C15.6992 15.1445 15.4297 15.25 15.125 15.25C14.8203 15.25 14.5508 15.1445 14.3164 14.9336L10.625 11.207L6.93359 14.9336C6.69922 15.1445 6.42969 15.25 6.125 15.25C5.82031 15.25 5.55078 15.1445 5.31641 14.9336C5.10547 14.6992 5 14.4297 5 14.125C5 13.8203 5.10547 13.5508 5.31641 13.3164L9.04297 9.625L5.31641 5.93359C5.10547 5.69922 5 5.42969 5 5.125C5 4.82031 5.10547 4.55078 5.31641 4.31641C5.55078 4.10547 5.82031 4 6.125 4C6.42969 4 6.69922 4.10547 6.93359 4.31641L10.625 8.04297L14.3164 4.31641C14.5508 4.10547 14.8203 4 15.125 4C15.4297 4 15.6992 4.10547 15.9336 4.31641C16.1445 4.55078 16.25 4.82031 16.25 5.125C16.25 5.42969 16.1445 5.69922 15.9336 5.93359L12.207 9.625L15.9336 13.3164Z"
        fill="#C098CE"
      />
    </svg>
  );
};
