import { FC } from 'react';
import CloseButton from '../../CloseButton/CloseButton';
import { MainButton } from '../../MainButton';
import Modal from '../Modal';
import { GuardRailEngineType } from '../../../common/constants/enums/engine-types.enum';
import { getDeleteMessage } from '../helpers/get-delete-message';

interface PropTypes {
  isShown: boolean;
  onDialogClose: () => void;
  onDeleteClick: () => void;
  engineType?: GuardRailEngineType;
}

export const DeleteModal: FC<PropTypes> = ({
  isShown,
  onDialogClose,
  onDeleteClick,
  engineType = GuardRailEngineType.FactChecker,
}) => {
  var message = getDeleteMessage(engineType);
  return (
    <div>
      <Modal isShown={isShown} animation="fade" centered withBackdrop>
        <div
          className="border rounded-md border-gray-100 border-solid bg-white shadow-md p-5 w-500"
          id="ArticleDeleteModal"
        >
          <div className="flex flex-col items-center justify-center">
            <div className="flex justify-between w-full items-center mb-4">
              <div className="font-semibold text-gray-700">Confirmation Message</div>
              <div className="flex items-center">
                <div className="border p-1 border-gray-300 rounded-md mr-2">
                  <CloseButton onClose={onDialogClose} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center my-5">
            <div className="text-gray-700">{message}</div>
          </div>
          <div className="flex justify-end items-center mt-2 mb-2 animate__animated animate__fadeInDown">
            <MainButton onClick={onDeleteClick} className="px-4 mt-2" stateProp="rest" text="Delete" />
          </div>
        </div>
      </Modal>
    </div>
  );
};
