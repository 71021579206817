import { Module, Action, ModuleTypes, ActionTypes } from './AuthContext';

export type PermissionsConstants = { module: Module; action: Action };

export const CLIENT_API_CONFIGURE_EXTERNAL_SEARCH: PermissionsConstants = {
  module: ModuleTypes.CLIENT_API,
  action: ActionTypes.CONFIGURE_EXTERNAL_SEARCH as Action,
};
export const CONFIGURE_EXTERNAL_SEARCH_SOURCE: PermissionsConstants = {
  module: ModuleTypes.EXTERNAL_SEARCH,
  action: ActionTypes.CONFIGURE_EXTERNAL_SEARCH as Action,
};

export const ACCESS_PERMISSIONS_MANAGE: PermissionsConstants = {
  module: ModuleTypes.ACCESS_PERMISSIONS,
  action: ActionTypes.MANAGE_ACCESS_PERMISSIONS as Action,
};

export const SETTINGS_ACCESS: PermissionsConstants = {
  module: ModuleTypes.SETTINGS,
  action: ActionTypes.CONFIGURE_SYSTEM_SETTINGS as Action,
};
export const SETTINGS_MODIFY_USERS: PermissionsConstants = {
  module: ModuleTypes.SETTINGS,
  action: ActionTypes.MODIFY_USERS as Action,
};
export const SETTINGS_DELETE_USERS: PermissionsConstants = {
  module: ModuleTypes.SETTINGS,
  action: ActionTypes.DELETE_USERS as Action,
};

export const FACT_CHECKER_ACCESS: PermissionsConstants = {
  module: ModuleTypes.FACT_CHECKER,
  action: ActionTypes.ACCESS_FACT_CHECKER as Action,
};

export const DOSSIER_GENERATOR_ACCESS: PermissionsConstants = {
  module: ModuleTypes.DOSSIER_GENERATOR,
  action: ActionTypes.ACCESS_DOSSIER_GENERATOR as Action,
};

export const CONTRADICTION_FINDER_ACCESS: PermissionsConstants = {
  module: ModuleTypes.CONTRADCTION_FINDER,
  action: ActionTypes.ACCESS_CONTRADICTION_FINDER as Action,
};

export const PROMPT_PROTECT_ACCESS: PermissionsConstants = {
  module: ModuleTypes.PROMPT_PROTECT,
  action: ActionTypes.ACCESS_PROMOT_PROTECT as Action,
};

export const API_GATEWAY_ACCESS: PermissionsConstants = {
  module: ModuleTypes.API_GATEWAY,
  action: ActionTypes.ACCESS_GATEWAY as Action,
};

export const CODE_ANALYZER_ACCESS: PermissionsConstants = {
  module: ModuleTypes.CODE_ANALYZER,
  action: ActionTypes.ACCESS_CODE_ANALYZER as Action,
};

export const SUMMARIZATION_ACCESS: PermissionsConstants = {
  module: ModuleTypes.SUMMARIZATION,
  action: ActionTypes.ACCESS_SUMMARIZATION as Action,
};


export const PROJECTS_ACCESS: PermissionsConstants = {
  module: ModuleTypes.PROJECTS,
  action: ActionTypes.ACCESS_PROJECT as Action,
};

export const GATEWAY_ACCESS: PermissionsConstants = {
  module: ModuleTypes.API_GATEWAY,
  action: ActionTypes.ACCESS_GATEWAY as Action,
};

export const FACT_CHECKER_CREATE: PermissionsConstants = {
  module: ModuleTypes.FACT_CHECKER,
  action: ActionTypes.ACCESS_FACT_CHECKER as Action,
};

export const FACT_CHECKER_PROCESS: PermissionsConstants = {
  module: ModuleTypes.FACT_CHECKER,
  action: ActionTypes.PROCESS_FACT_CHECKER as Action,
};
export const FACT_CHECKER_FAVORITES: PermissionsConstants = {
  module: ModuleTypes.FACT_CHECKER,
  action: ActionTypes.ADD_REMOVE_FAVORITES as Action,
};
export const FACT_CHECKER_SHARE: PermissionsConstants = {
  module: ModuleTypes.FACT_CHECKER,
  action: ActionTypes.SHARE_CONTENT as Action,
};
export const FACT_CHECKER_DELETE_HISTORY: PermissionsConstants = {
  module: ModuleTypes.FACT_CHECKER,
  action: ActionTypes.DELETE_HISTORY as Action,
};
export const FACT_CHECKER_GENERATE_PDF: PermissionsConstants = {
  module: ModuleTypes.FACT_CHECKER,
  action: ActionTypes.GENERATE_PDF as Action,
};

export const DOSSIER_GENERATOR_CREATE: PermissionsConstants = {
  module: ModuleTypes.DOSSIER_GENERATOR,
  action: ActionTypes.ACCESS_DOSSIER_GENERATOR as Action,
};
export const DOSSIER_GENERATOR_FAVORITES: PermissionsConstants = {
  module: ModuleTypes.DOSSIER_GENERATOR,
  action: ActionTypes.ADD_REMOVE_FAVORITES as Action,
};
export const DOSSIER_GENERATOR_SHARE: PermissionsConstants = {
  module: ModuleTypes.DOSSIER_GENERATOR,
  action: ActionTypes.SHARE_CONTENT as Action,
};
export const DOSSIER_GENERATOR_DELETE_HISTORY: PermissionsConstants = {
  module: ModuleTypes.DOSSIER_GENERATOR,
  action: ActionTypes.DELETE_HISTORY as Action,
};
export const DOSSIER_GENERATOR_GENERATE_PDF: PermissionsConstants = {
  module: ModuleTypes.DOSSIER_GENERATOR,
  action: ActionTypes.GENERATE_PDF as Action,
};

export const PROMPT_PROTECT_CREATE: PermissionsConstants = {
  module: ModuleTypes.PROMPT_PROTECT,
  action: ActionTypes.ACCESS_PROMOT_PROTECT as Action,
};
export const PROMPT_PROTECT_SERVICE: PermissionsConstants = {
  module: ModuleTypes.PROMPT_PROTECT,
  action: ActionTypes.ACCESS_PROMOT_PROTECT_SERVICE as Action,
};
export const PROMPT_PROTECT_SHARE: PermissionsConstants = {
  module: ModuleTypes.PROMPT_PROTECT,
  action: ActionTypes.SHARE_CONTENT as Action,
};
export const PROMPT_PROTECT_DELETE_HISTORY: PermissionsConstants = {
  module: ModuleTypes.PROMPT_PROTECT,
  action: ActionTypes.DELETE_HISTORY as Action,
};
export const PROMPT_PROTECT_GENERATE_PDF: PermissionsConstants = {
  module: ModuleTypes.PROMPT_PROTECT,
  action: ActionTypes.GENERATE_PDF as Action,
};
export const PROMPT_PROTECT_FAVORITES: PermissionsConstants = {
  module: ModuleTypes.PROMPT_PROTECT,
  action: ActionTypes.ADD_REMOVE_FAVORITES as Action,
};

export const CONTRADICTION_FINDER_CREATE: PermissionsConstants = {
  module: ModuleTypes.CONTRADCTION_FINDER,
  action: ActionTypes.ACCESS_CONTRADICTION_FINDER as Action,
};
export const CONTRADICTION_FINDER_SHARE: PermissionsConstants = {
  module: ModuleTypes.CONTRADCTION_FINDER,
  action: ActionTypes.SHARE_CONTENT as Action,
};
export const CONTRADICTION_FINDER_DELETE_HISTORY: PermissionsConstants = {
  module: ModuleTypes.CONTRADCTION_FINDER,
  action: ActionTypes.DELETE_HISTORY as Action,
};
export const CONTRADICTION_FINDER_GENERATE_PDF: PermissionsConstants = {
  module: ModuleTypes.CONTRADCTION_FINDER,
  action: ActionTypes.GENERATE_PDF as Action,
};
export const CONTRADICTION_FINDER_FAVORITES: PermissionsConstants = {
  module: ModuleTypes.CONTRADCTION_FINDER,
  action: ActionTypes.ADD_REMOVE_FAVORITES as Action,
};

export const ACCESS_DASHBOARD: PermissionsConstants = {
  module: ModuleTypes.DASHBOARD,
  action: ActionTypes.ACCESS_DASHBOARD as Action,
};


export const UPLOAD_SYSTEM_PATTERN: PermissionsConstants = {
  module: ModuleTypes.PATTERN_MANAGEMENT,
  action: ActionTypes.UPLOAD_SYSTEM_PATTERN as Action,
};


export const ACCESS_PATTERN_MANAGEMENT: PermissionsConstants = {
  module: ModuleTypes.PATTERN_MANAGEMENT,
  action: ActionTypes.ACCESS_PATTERN_MANAGEMENT as Action,
};


export const ACCESS_GUARDRAIL123: PermissionsConstants = {
  module: ModuleTypes.GUARDRAIL123,
  action: ActionTypes.ACCESS_GUARDRAIL123 as Action,
};