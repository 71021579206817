import React, { useEffect, useRef, useState } from 'react';
import { Divider } from '../../components/Divider/Divider';
import { AssertionsListIcon, SelectedAssertionIcon } from '../../common/icons/Icons';
import BackButton from '../../components/BackButton/BackButton';
import { useNavigate, useParams } from 'react-router-dom';
import AppLoader from '../../components/AppLoader/AppLoader';
import ProjectsRightMenu from '../../components/ProjectsRightMenu';
import { useDispatch, useSelector } from 'react-redux';
import { currentProjectSelector } from '../../redux/selectors/projects.selectors';
import { ProjectsActions } from '../../redux/actions/projects.actions';
import { setNotification } from '../../redux/actions/notification.actions';
import { RoutePath } from '../../common/constants/route-path';
import { PrimaryButton } from '../../components/PrimaryButton';
import { ProjectAssertionDto } from '../../types/models';
import { iconComponents } from '../../common/helpers/Helper';
import { SourceType } from '../../common/constants/enums/engine-types.enum';

interface ProjectListItemProps {
  Assertion: ProjectAssertionDto;
  selectedItem: string;
}

interface ProjectDetailsViewProps {
  projectId?: string;
}

const ProjectListItem: React.FC<ProjectListItemProps> = React.memo(
  ({ Assertion, selectedItem }: ProjectListItemProps) => {
    const assertionDate = Assertion.createdAt ? new Date(Assertion.createdAt) : new Date();
    return (
      <div className={`p-3 ${selectedItem === Assertion.assertionId ? ' bg-fuchsia-50' : ''}`}>
        <div className="flex">
          <div className=" h-4 w-4 flex-shrink-0 mt-2 mr-4">{iconComponents[Assertion?.model as SourceType]}</div>
          <div className="flex justify-between w-full">
            <div>
              <p className="text-sm text-neutral-500 mb-2 flex-shrink">{Assertion?.assertionText}</p>
              <p className="text-xs text-neutral-400 line-clamp-3">
                {assertionDate.toLocaleString('en-US', {
                  year: 'numeric',
                  day: 'numeric',
                  month: 'short',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </p>
            </div>
            <div className=" h-4 w-4 flex-shrink-0 mt-2 ml-4">
              {selectedItem === Assertion.assertionId && <SelectedAssertionIcon />}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export const ProjectDetailsView: React.FC<ProjectDetailsViewProps> = ({ projectId }: ProjectDetailsViewProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const callApi = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  const currentProject = useSelector(currentProjectSelector);

  const uniqueModels: string[] = [];
  currentProject?.assertions?.forEach((assertion: ProjectAssertionDto) => {
    if (!uniqueModels.includes(assertion?.model)) {
      uniqueModels.push(assertion?.model);
    }
  });

  useEffect(() => {
    // Close the menu when the project tab is changed
    setIsOpen(false);
    setSelectedItem('');
  }, [projectId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!callApi.current) {
      callApi.current = true;
      if (id) {
        dispatch(ProjectsActions.getProjectById(id));
      } else if (projectId) {
        // Do Nothing
      } else {
        dispatch(setNotification({ title: 'Project Id Missing ', type: 'error' }));
        navigate(RoutePath.Landing);
      }
    }
  }, [id, dispatch, navigate]);

  return (
    <>
      {currentProject ? (
        <div className="flex h-screen">
          <div className="relative w-full">
            <div className=" bg-gray-50 flex justify-center relative">
              {id && (
                <div className="absolute top-10 left-10 z-10">
                  <BackButton />
                </div>
              )}
              <div className=" w-3/4 p-4">
                <p className="text-xs pb-6 text-gray-500 pt-4">{'Projects > Project1'}</p>
                <span className=" font-bold text-xl">{currentProject.title}</span>
                <div className="flex items-center justify-between my-6">
                  <div className="flex justify-center items-center">
                    <p className="text-sm text-neutral-400 flex justify-center mr-4">
                      <span className="mr-2">
                        <AssertionsListIcon />
                      </span>
                      {currentProject?.assertions?.length}
                    </p>
                    {uniqueModels.map((model: string) => {
                      return <div className="mx-1">{iconComponents[model as SourceType]}</div>;
                    })}
                  </div>
                  <p className="text-xs text-neutral-400">
                    {'Last edited: ' +
                      new Date(currentProject?.updatedAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        day: 'numeric',
                        month: 'numeric',
                      })}
                  </p>
                </div>
              </div>
            </div>
            <div className={`flex ${isOpen ? 'justify-end' : 'justify-center'}`}>
              {currentProject?.assertions?.length > 0 ? (
                <div className={`mt-10 ${isOpen ? ' w-5/6' : 'w-3/4'}`}>
                  {currentProject?.assertions?.map((assertion: ProjectAssertionDto) => (
                    <div
                      onClick={() => {
                        setSelectedItem((prev) => (prev === assertion.assertionId ? '' : assertion.assertionId));
                        dispatch(ProjectsActions.getArticleById(assertion.articleId));
                        setIsOpen((prev) => (selectedItem === assertion.assertionId && prev ? false : true));
                      }}
                    >
                      <ProjectListItem Assertion={assertion} selectedItem={selectedItem} />
                      <Divider />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <div className="mt-20 mb-4">Let's add some assertions to this project</div>
                  <PrimaryButton
                    onClick={() => {
                      navigate(RoutePath.LLMResult);
                    }}
                    className=""
                    size="medium"
                    stateProp="rest"
                    style={`boxed`}
                    text="Go to Gateway (Legacy)"
                  />
                </div>
              )}
            </div>
          </div>
          {isOpen && (
            <ProjectsRightMenu
              project={currentProject}
              setIsOpen={setIsOpen}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          )}
        </div>
      ) : (
        <AppLoader />
      )}
    </>
  );
};
