import { ExternalSearchState } from '../slices/external-search.slice';
import { AppState } from '../types/App.type';
import { createSelector } from '@reduxjs/toolkit';

const selectExternalSearchState: (state: AppState) => ExternalSearchState = (state) => state.externalSearch;
export const currentExternalSearch = createSelector(
  selectExternalSearchState,
  ({ currentExternalSearch }) => currentExternalSearch,
);
export const initialExternalSearch = createSelector(
  selectExternalSearchState,
  ({ initialExternalSearch }) => initialExternalSearch,
);
export const ExternalSearchErrorCodes = createSelector(selectExternalSearchState, ({ errorCode }) => errorCode);
export const isFetchingExternalSearch = createSelector(
  selectExternalSearchState,
  ({ isFetchingExternalSearch }) => isFetchingExternalSearch,
);
export const isPostingExternalSearch = createSelector(
  selectExternalSearchState,
  ({ isPostingExternalSearch }) => isPostingExternalSearch,
);
export const isEditingExternalSearch = createSelector(
  selectExternalSearchState,
  ({ isEditingExternalSearch }) => isEditingExternalSearch,
);
export const isCloningExternalSearch = createSelector(
  selectExternalSearchState,
  ({ isCloningExternalSearch }) => isCloningExternalSearch,
);
export const isDeleteExternalSearch = createSelector(
  selectExternalSearchState,
  ({ isDeleteExternalSearch }) => isDeleteExternalSearch,
);
export const isLoading = createSelector(selectExternalSearchState, ({ isLoading }) => isLoading);
export const isValidated = createSelector(selectExternalSearchState, ({ isValidated }) => isValidated);
export const isValidateExternalSearch = createSelector(
  selectExternalSearchState,
  ({ isValidateExternalSearch }) => isValidateExternalSearch,
);
export const externalSearchLightList = createSelector(
  selectExternalSearchState,
  ({ externalSearchLightList }) => externalSearchLightList,
);
export const externalSearchPagination = createSelector(
  selectExternalSearchState,
  ({ externalSearchPagination }) => externalSearchPagination,
);
export const layoutExternalAppMainMenu = createSelector(
  selectExternalSearchState,
  ({ layoutExternalAppMainMenu }) => layoutExternalAppMainMenu,
);
export const showExternalSearchSourceList = createSelector(
  selectExternalSearchState,
  ({ showExternalSearchSourceList }) => showExternalSearchSourceList,
);
export const refreshExternalSearchSourceList = createSelector(
  selectExternalSearchState,
  ({ refreshExternalSearchSourceList }) => refreshExternalSearchSourceList,
);
