import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ArticleService } from '../../common/services/article/article.service';
import { ArticleUpdateRequest, AssertionResult, AssertionResultLite } from '../../types/models';
import { articleActions } from '../actions/article.actions';
import { mapApiResponseToResult, wait } from '../../common/helpers/Helper';
import { ArticleProcessRequest } from '../../common/services/article/models/ArticleProcessRequest';
import { setNotification } from '../actions/notification.actions';
import { RoutePath } from '../../common/constants/route-path';
import { useNavigate } from 'react-router-dom';
import { browserHistory } from '../helpers/history/history';
import { numberofRecords } from '../selectors/user-preference.selectors';
import { profileActions } from '../actions/profile.actions';
export function* getArticles({ payload }: PayloadAction<number>) {
  try {
    var userArticles: AssertionResultLite = yield call(ArticleService.getArticlesList, payload, 10);
    yield put(articleActions.getUserArticlesListSuccess(userArticles));
  } catch (err: any) {
    yield put(articleActions.getUserArticlesListFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* getDefaultArticle() {
  try {
    const defaultArticle: AssertionResult = yield call(ArticleService.getDefaulArticle);
    yield put(articleActions.getDefaultArticleSuccess(defaultArticle));
  } catch (err: any) {
    yield put(articleActions.getDefaultArticleFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* clearArticleHistory() {
  try {
    const result: boolean = yield call(ArticleService.clearHistory);
    if (result) {
      yield put(articleActions.clearArticleHistorySuccess());
      yield put(setNotification({ title: 'Article History Cleared', type: 'success' }));
    } else {
      yield put(articleActions.clearArticleHistoryFailure());
      yield put(setNotification({ title: 'Unable to clear Article history', type: 'error' }));
    }
  } catch (err: any) {
    yield put(articleActions.clearArticleHistoryFailure());
  }
}

export function* processArticles({ payload }: PayloadAction<string>) {
  try {
    const selectedNumberOfRecords: number = yield select(numberofRecords);
    const result: ArticleProcessRequest = new ArticleProcessRequest(payload, selectedNumberOfRecords, true);
    const processedArticle: AssertionResult = yield call(ArticleService.processArticle, result);
    yield put(articleActions.processArticleSuccess(mapApiResponseToResult(processedArticle)));
  } catch (err: any) {
    yield put(articleActions.processArticleFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
    yield put(profileActions.getUserLimit());
    yield put(articleActions.processArticleEnabled(false));
  }
}

export function* setArticleFavorite({ payload }: PayloadAction<ArticleUpdateRequest>) {
  try {
    const updateArticleResult: AssertionResult = yield call(ArticleService.updateArticle, payload);
    yield put(articleActions.setArticleFavoriteSuccess(mapApiResponseToResult(updateArticleResult)));
    let message = payload.isFavorite ? 'Article marked as favorite' : 'Article removed from favorite';

    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(articleActions.setArticleFavoriteFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* getArticlesById({ payload }: PayloadAction<string>) {
  try {
    const articleDetails: AssertionResult = yield call(ArticleService.getUserArticlesById, payload);
    if (articleDetails) {
      yield put(articleActions.getArticleByIdSuccess(articleDetails));
    } else {
      yield put(articleActions.getArticleByIdFailure({ message: 'Article Not Found', statusCode: 404 }));
    }
  } catch (err: any) {
    yield put(articleActions.getArticleByIdFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to fetch article details', type: 'error' }));
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export function* deleteArticle({ payload }: PayloadAction<string>) {
  try {
    yield call(ArticleService.deleteArticle, payload);
    yield put(articleActions.deleteArticleSuccess(payload));
    yield put(setNotification({ title: 'Article deleted successfully', type: 'success' }));
  } catch (err: any) {
    yield put(articleActions.deleteArticleFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to delete article', type: 'error' }));
  }
}

export const PROCESS_ARTICLE_SAGA = 'Process Article Saga';

export const processArticleSaga = createAction<string>(PROCESS_ARTICLE_SAGA);

export default function* watcher() {
  yield takeLatest(articleActions.processArticle.type, processArticles);
  yield takeEvery(articleActions.getUserArticlesList.type, getArticles);
  yield takeEvery(articleActions.getDefaultArticle.type, getDefaultArticle);
  yield takeEvery(articleActions.getArticleById.type, getArticlesById);
  yield takeLatest(articleActions.setArticleFavorite.type, setArticleFavorite);
  yield takeLatest(articleActions.deleteArticle.type, deleteArticle);
  yield takeLatest(articleActions.clearArticleHistory.type, clearArticleHistory);
}
