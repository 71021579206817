import SignInPage from '../../components/Modal/SignInModal/SignInModal';
import SignUpPage from '../../components/Modal/SignUpModal/SignUpModal';
import { MainBackground } from '../Home/MainBackground';

export const SignIn = (): JSX.Element => {
  return (
    <div className="landing">
      <div className="div">
        <div className="main-content flex min-h-[90vh]">
          <MainBackground>
            <SignInPage />
          </MainBackground>
        </div>
        <></>
      </div>
    </div>
  );
};
