import PropTypes from 'prop-types';
import React from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { TokenService } from '../../common/services/token/token.service';

interface Props {
  className: any;
  logo: string;
}

export const Logo = ({ className, logo = '../../static/img/logo-2.svg' }: Props): JSX.Element => {
  const navigate = useNavigate();
  const handleTryNowClick = () => {
    TokenService.accessToken?.length ? navigate(RoutePath.Landing) : navigate(RoutePath.Home);
  };

  return <img onClick={handleTryNowClick} className={`logo  cursor-pointer ${className}`} alt="Logo" src={logo} />;
};

Logo.propTypes = {
  logo: PropTypes.string,
};
