import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Preferences from './Preferences';
import SecuritySettings from './SecuritySettings';
import ProfileSettings from './ProfileSettings';
import { RoutePath } from '../../common/constants/route-path';

enum Tabs {
  Profile_SETTINGS = 'Profile Settings',
  PREFERENCES = 'Preferences',
  // SECURITY_SETTINGS = 'Security Settings',
  Configuration_Management = 'Configure Management',
}

function ProfilePage() {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Profile_SETTINGS);
  const navigate = useNavigate();
  useEffect(() => {
    handleTabClick(Tabs.Profile_SETTINGS);
  }, []);

  const handleTabClick = (tab: Tabs) => {
    setActiveTab(tab);
    let defaultLink = RoutePath.EditProfile;
    switch (tab) {
      case Tabs.Profile_SETTINGS:
        defaultLink = RoutePath.ProfileSettings;
        break;
      case Tabs.PREFERENCES:
        defaultLink = RoutePath.Preferences;
        break;
      case Tabs.Configuration_Management:
        defaultLink = RoutePath.ConfigurationManagement;
        break;
      default:
        defaultLink = RoutePath.EditProfile;
        break;
    }
    navigate(defaultLink);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case Tabs.Profile_SETTINGS:
        return <ProfileSettings />;
      case Tabs.PREFERENCES:
        return <Preferences />;
      // case Tabs.SECURITY_SETTINGS:
      //   return <SecuritySettings />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md mb-2 p-4">
      <div className="md:flex">
        <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
          {Object.values(Tabs).map((tab) => (
            <li key={tab}>
              <button
                onClick={() => handleTabClick(tab)}
                className={`inline-flex items-center px-4 py-3 rounded-lg ${
                  activeTab === tab
                    ? 'bg-blue-700 text-white dark:bg-blue-600'
                    : 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 hover:text-gray-900  dark:hover:bg-gray-700 dark:hover:text-white'
                } w-full`}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
        <div className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
