import {
  CardTitleDescription,
  DossierFinancialInformation,
  DossierOrganizaiton,
  DossierOrganizationProgramAndServices,
  OrganizationHeaderModel,
  TabWithList,
  TitleWithCardsDescription,
} from '../../../../types/models';
import { convertObjectToArray, convertObjectToValueArray, ensureArray } from '../../../helpers/Helper';
import {
  EducationAndQualification,
  ProfessionalAssociationsAndMembership,
  PublicationsAndContribution,
  SkillsAndExpertise,
} from './dossierPerson';

export const mapEducationAndQualificationDto = (educationAndQualificationDto: EducationAndQualification) => {
  let result = {
    title: 'Education and Qualifications',
    items: [] as any,
  };
  if (educationAndQualificationDto == null) {
    return result;
  }
  // Map educationalBackground
  if (educationAndQualificationDto.EducationalBackground) {
    result.items.push({
      title: 'Educational Background',
      description: educationAndQualificationDto.EducationalBackground[0],
    });
  }

  // Map professionalCertifications
  if (educationAndQualificationDto.ProfessionalCertifications) {
    result.items.push({
      title: 'Professional Certifications',
      description: educationAndQualificationDto.ProfessionalCertifications[0],
    });
  }

  return result;
};

export const mapDossierOrgHeader = (dossierOrg?: DossierOrganizaiton) => {
  return {
    CoreValues: dossierOrg?.missionAndVisionDto?.CoreValues ?? [],
    history: dossierOrg?.historyAndMilestonesDto?.History ?? '',
    incorporationDate: dossierOrg?.organizationInformationDto?.RegistrationDetails ?? '',
    legalStatus: dossierOrg?.organizationInformationDto?.LegalStatus ?? '',
    title: dossierOrg?.organizationInformationDto?.Name ?? '',
    id: dossierOrg?.dossierOrgId ?? '',
    isFavorite: dossierOrg?.isFavorite ?? false,
  } as OrganizationHeaderModel;
};

export const mapOrganizationMissionAndVision = (dossierOrg?: DossierOrganizaiton) => {
  return {
    title: 'Mission and Vision',
    items: [
      {
        title: 'Mission',
        description: dossierOrg?.missionAndVisionDto?.MissionStatement ?? '',
      },
      {
        title: 'Vision',
        description: dossierOrg?.missionAndVisionDto?.VisionStatement ?? '',
      },
    ],
  } as TitleWithCardsDescription;
};

export const mapKeyPersonsSelector = (dossierOrg?: DossierOrganizaiton) => {
  return {
    title: 'Key Persons',
    items: [
      {
        key: 'Founders',
        name: 'Mission',
        items: dossierOrg?.keyPersonnelDto?.Founders ?? [],
      },
      {
        key: 'ExecutiveTeam',
        name: 'Executive Team',
        items: dossierOrg?.keyPersonnelDto?.ExecutiveTeam ?? [],
      },
      {
        key: 'BoardOfDirectors',
        name: 'Board Of Directors',
        items: dossierOrg?.keyPersonnelDto?.BoardOfDirectors ?? [],
      },
    ],
  };
};

export const mapDossierFinancialInformation = (dossierOrg?: DossierOrganizaiton) => {
  return {
    title: 'Financial Information',
    description: dossierOrg?.financialInformationDto?.FinancialStatements ?? '',
    revenueSources: dossierOrg?.financialInformationDto?.RevenueSources ?? '',
    fundingPartners: dossierOrg?.financialInformationDto?.FundingPartners ?? '',
  } as DossierFinancialInformation;
};

export const mapDossierOrganizationProgramAndServices = (dossierOrg?: DossierOrganizaiton) => {
  return {
    title: 'Program &  Services',
    description: dossierOrg?.programsAndServicesDto?.Overview ?? '',
    services: [
      {
        title: 'Objectives',
        description: dossierOrg?.programsAndServicesDto?.Objectives ?? '',
      },
      {
        title: 'Target Audience',
        description: dossierOrg?.programsAndServicesDto?.TargetAudience ?? '',
      },
      {
        title: 'Impact',
        description: dossierOrg?.programsAndServicesDto?.Impact ?? '',
      },
    ],
  } as DossierOrganizationProgramAndServices;
};

export const mapDossierSkillsAndExpertise = (dossierOrg?: DossierOrganizaiton) => {
  return [
    {
      title: 'Partnerships',
      description: dossierOrg?.partnershipsAndCollaborationsDto?.Partnerships[0] ?? '',
    },
    {
      title: 'Collaborations',
      description: dossierOrg?.partnershipsAndCollaborationsDto?.Collaborations[0] ?? '',
    },
  ] as CardTitleDescription[];
};

export const mapDossierLegalAndCompliance = (dossierOrg?: DossierOrganizaiton) => {
  return [
    {
      title: 'Legal Structure',
      description: dossierOrg?.legalAndComplianceDto?.LegalStructure[0] ?? '',
    },
    {
      title: 'Compliance',
      description: dossierOrg?.legalAndComplianceDto?.Compliance[0] ?? '',
    },
  ] as CardTitleDescription[];
};
export const mapDossierMediaCoverage = (dossierOrg?: DossierOrganizaiton) => {
  return [
    {
      title: 'Media Articles',
      description: dossierOrg?.mediaCoverageDto?.MediaArticles[0] ?? '',
    },
    {
      title: 'Press Releases',
      description: dossierOrg?.mediaCoverageDto?.PressReleases[0] ?? '',
    },
  ] as CardTitleDescription[];
};

export const mapDossierclienteleAndBeneficiaries = (dossierOrg?: DossierOrganizaiton) => {
  return [
    {
      title: 'Clientele',
      description: dossierOrg?.clienteleAndBeneficiariesDto?.Clientele[0] ?? '',
    },
    {
      title: 'Beneficiaries',
      description: dossierOrg?.clienteleAndBeneficiariesDto?.Beneficiaries[0] ?? '',
    },
  ] as CardTitleDescription[];
};
export const mapDossierPartnershipsAndStakeholders = (dossierOrg?: DossierOrganizaiton) => {
  return [
    {
      title: 'Government Entities',
      description: dossierOrg?.partnershipsAndStakeholdersDto?.GovernmentEntities[0] ?? '',
    },
    {
      title: 'Community Groups',
      description: dossierOrg?.partnershipsAndStakeholdersDto?.CommunityGroups[0] ?? '',
    },
  ] as CardTitleDescription[];
};
export const mapSkillsAndExpertiseDto = (skillsAndExpertiseDto: SkillsAndExpertise) => {
  let result = {
    title: 'Skills and Expertise',
    items: [] as any,
  };

  if (skillsAndExpertiseDto == null) {
    return result;
  }

  if (skillsAndExpertiseDto.TechnicalSkills) {
    result.items.push({
      title: 'Technical Skills',
      data: ensureArray(skillsAndExpertiseDto.TechnicalSkills),
    });
  }
  if (skillsAndExpertiseDto.DomainSpecificSkills) {
    result.items.push({
      title: 'Domain-Specific Skills',
      data: ensureArray(skillsAndExpertiseDto.DomainSpecificSkills),
    });
  }
  if (skillsAndExpertiseDto.LeadershipAbilities) {
    result.items.push({
      title: 'Leadership Abilities',
      data: ensureArray(skillsAndExpertiseDto.LeadershipAbilities),
    });
  }
  return result;
};

export const mapProfessionalAssociationsandMembershipsDto = (
  professionalAssociationsAndMembership: ProfessionalAssociationsAndMembership,
) => {
  let result = {
    title: 'Professional Associations and Memberships',
    items: [] as any,
  };

  if (professionalAssociationsAndMembership == null) {
    return result;
  }
  if (professionalAssociationsAndMembership.ProfessionalOrganizations) {
    result.items.push({
      title: 'Professional Organizations',
      data: ensureArray(professionalAssociationsAndMembership.ProfessionalOrganizations),
    });
  }
  if (professionalAssociationsAndMembership.IndustryAssociations) {
    result.items.push({
      title: 'Industry Associations',
      data: ensureArray(professionalAssociationsAndMembership.IndustryAssociations),
    });
  }
  if (professionalAssociationsAndMembership.CommunityInvolvement) {
    result.items.push({
      title: 'Community Involvement',
      data: ensureArray(professionalAssociationsAndMembership.CommunityInvolvement),
    });
  }
  return result;
};

export const mapPublicationsandContributionsDto = (publicationsAndContribution: PublicationsAndContribution) => {
  let result = {
    title: 'Publications and Contributions',
    items: [] as any,
  };

  if (publicationsAndContribution == null) {
    return result;
  }
  if (publicationsAndContribution.ResearchPapers) {
    result.items.push({
      title: 'Research Papers',
      data: ensureArray(publicationsAndContribution.ResearchPapers),
    });
  }
  if (publicationsAndContribution.Articles) {
    result.items.push({
      title: 'Articles',
      data: ensureArray(publicationsAndContribution.Articles),
    });
  }
  if (publicationsAndContribution.Books) {
    result.items.push({
      title: 'Books',
      data: ensureArray(publicationsAndContribution.Books),
    });
  }
  if (publicationsAndContribution.PresentationsOrTalks) {
    result.items.push({
      title: 'Presentations or Talks',
      data: ensureArray(publicationsAndContribution.PresentationsOrTalks),
    });
  }
  return result;
};

export const mapWorkExperienceDto = (workExperince: any) => {
  let result = {
    title: 'Work Experience',
    items: [] as any,
  };

  if (workExperince == null) {
    return result;
  }

  var workExperienceList = ensureArray(workExperince);
  workExperienceList.forEach((experience: any) => {
    result.items.push(convertObjectToValueArray(experience));
  });
  console.log('workExperienceList', result);
  return result;
};
export const mapRolesAndResponsibilitiesDto = (rolesAndResponsibilities: any) => {
  let result = {
    title: 'Roles and Responsibilities',
    items: [] as any,
  };

  if (rolesAndResponsibilities == null) {
    return result;
  }
  result.items = convertObjectToArray(ensureArray(rolesAndResponsibilities)[0]);
  return result;
};

export const mapArhievementsOrProjectsDto = (achievementsOrProjectsUndertaken: any) => {
  let result = {
    title: 'Achievements or Projects Undertaken',
    items: [] as any,
  };

  if (achievementsOrProjectsUndertaken == null) {
    return result;
  }
  result.items = convertObjectToArray(ensureArray(achievementsOrProjectsUndertaken)[0]);
  return result;
};
