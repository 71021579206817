import { FC, useMemo } from 'react';
import Modal from '../Modal';
import { Library, PatternDto } from '../../../types/models';
import Typography from '../../Typography/Ty[pgraphy';
import PatternsListTable from '../../../views/PatternConfiguration/PatternLibraries/PatternsListTable';
import { ExpressionType } from '../../../common/constants/enums/engine-types.enum';
import { getPatternsToArrary } from '../../../common/helpers/Helper';
interface PropTypes {
  isShown: boolean;
  onClose: () => void;
  title: string;
  description: string;
  patterns: PatternDto[];
}

export const PatternsListModal: FC<PropTypes> = ({ isShown, title,description,  onClose, patterns }) => {
    return (
    <>
      <Modal isShown={isShown} animation="fade" centered withBackdrop>
        <div id="permissions-modal " className=" w-full  h-full">
          <div className="relative p-4 w-full justify-center max-h-500">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{title}</h3> <br/>
                <button
                  onClick={onClose}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="crud-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"  
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>

              </div>
              <div className='px-4'>
              <Typography variant="subtext" className="text-black-900 font-openSans"> {description} </Typography>
              </div>
             
              
              <div className="p-4 md:p-5 w-full max-h-500 overflow-scroll">
             {patterns && <PatternsListTable
                  data={patterns}
                />}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
