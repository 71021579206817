import { Table } from 'flowbite-react/lib/esm/components/Table';
import { ResultIndicatorColors } from '../../common/constants/default-values';
import { TickMark, InfoIcon, CrossMark } from '../../common/icons/Icons';
import {  RiskAssessmentResultView } from '../../types/models';
import Badge from '../Base/Badge';
import { CodeMirrorInput } from './CodeMirrorInput';

interface RiskAssessmentProps {
  riskAssessment: RiskAssessmentResultView;
}

export const RiskAssessmentResult = ({ riskAssessment }: RiskAssessmentProps) => {

  return (
    <>
      <div className="mt-4">
        {riskAssessment.risk_detected? (
          <div>
            <>
            {riskAssessment.risks.map((risk, index) => {
              return (<>
              { risk && risk.result?.risk_detected ? 
              <div className="overflow-x-auto animate__animated animate__fadeInUp ">
             { index === 0 && <div className="mt-4">
               <div className='flex justify-between'> 
                <div className="p-wrapper" style={{ backgroundColor: ResultIndicatorColors.RED }}>
                  <div className="flex justify-between gap-4 mx-2 align-middle">
                    <div className="flex col">
                      <span className="mt-1">
                        <CrossMark color="#7A8299" />
                      </span>
                      <span className="mx-2 font-bold"> Failed : </span>
                      <span className="font-sm font-['Open_Sans']">{riskAssessment.check_type}</span>
                    </div>
                    <span className="mt-1">
                      <InfoIcon />
                    </span>
                  </div>
                </div>
                <span className="text-gray-500 font-[Open-sans] mx-2">
                          <Badge backgroundColor="#FAFBFD" text={`${riskAssessment.risks.length} Failed code blocks found`} />
                        </span>
                
                </div>
              </div>}
             <div>
             <Table>
                <Table.Body className="divide-y">
                <Table.Row
                    className="border border-b-2"
                    style={{
                      borderBottom: '2px solid #E1E4EC',
                    }}
                  >
                    <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">
                      {'Risk No'}
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">
                      {index+1}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row
                    className="border border-b-2"
                    style={{
                      borderBottom: '2px solid #E1E4EC',
                    }}
                  >
                    
                    <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">
                      {'Location'}
                    </Table.Cell>
                    <Table.Cell>
                      <div className="flex items-center flex-row">
                        <Badge backgroundColor="#FAFBFD" text={risk.result?.risk_start_character?.toString() ?? ""} />
                        <span className="text-gray-500 font-[Open-sans] mx-2">
                          <Badge backgroundColor="#FAFBFD" text={risk.result?.risk_location ?? ""} />
                        </span>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row
                    className="border border-b-2"
                    style={{
                      borderBottom: '2px solid #E1E4EC',
                    }}
                  >
                    <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">
                      {'Reason'}
                    </Table.Cell>
                    <Table.Cell className="font-['Open_Sans'] text-gray-900">
                      {risk?.result?.risk_reason}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row
                    className="border border-b-2"
                    style={{
                      borderBottom: '2px solid #E1E4EC',
                    }}
                  >
                    <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">
                      {'Mitigation'}
                    </Table.Cell>
                    <Table.Cell className="font-['Open_Sans'] text-gray-900">
                    {risk?.result?.risk_mitigation}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
             </div>
              <div>
                {risk?.code_segment && <CodeMirrorInput
                  hideTitle={true}
                  code={risk?.code_segment}
                  disabled={true}/>}
              </div>
            </div> : <> </>}
              </>);
            })} 
            </>
        </div>
        ) : (
          <div className="p-wrapper animate__animated animate__fadeInUp " style={{ backgroundColor: ResultIndicatorColors.GREEN }}>
            <div className="flex justify-between gap-4 mx-2 align-middle">
              <div className="flex col  items-center">
                <div className="mt-1">
                  <TickMark color="#10828B" />
                </div>
                <span className="mx-2 font-bold"> Passed : </span>
                <span className="font-sm font-['Open_Sans']">{riskAssessment.check_type}</span>
              </div>
              <div>
                <InfoIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
