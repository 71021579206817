import { useEffect, useState } from 'react';
import { Tags } from '../../components/Tags/Tags';
import { EyeIcon, MailIcon, PhoneIcon } from '@heroicons/react/outline';
import { PlugInResultActions } from '../../components/PlugInResultActions/PlugInResultActions';
import { EDIT_TEXT, SHARE_TEXT, FAVORITE_TEXT } from '../../common/constants/app-text';
import { EditIcon, FavoriteIcon, FavoriteIconPink, ShareIcon } from '../../common/icons/Icons';
import { articleActions } from '../../redux/actions/article.actions';
import {
  ArticleUpdateRequest,
  CardTitleDescription,
  DossierOrganizationProgramAndServices,
  TabWithList,
} from '../../types/models';
import { AvatarWithName } from '../../components/CharactorAvatar/CharactorAvatar';
import { ensureArray, getRandomColor } from '../../common/helpers/Helper';
import { get } from 'http';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentDossierOrg,
  dossierClienteleAndBeneficiariesSelector,
  dossierLegalAndComplianceSelector,
  dossierOrgProcessingInput,
  dossierOrganizationFinancialInformationSelector,
  dossierOrganizatonHeaderSelector,
  dossierPartnershipsAndCollaborationsSelector,
  isFetchingDossierOrgs,
  keyPersonsSelector,
  missionAndVisionSelector,
  skillsAndExpertiseSelector,
} from '../../redux/selectors/dossier-org.selectors';
import { OrganizationHeader } from '../../components/Dossier-Organization/OrganizationHeader';
import { DossierOrganizationFinancialInformation } from '../../components/Dossier-Organization/FinancialInformation';
import { mapDossierPartnershipsAndStakeholders } from '../../common/services/dossier/person/dossier.helper';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { dossierActions } from '../../redux/actions/dossier.actions';
import { setNotification } from '../../redux/actions/notification.actions';
import { dossierProcessingInput } from '../../redux/selectors/dossier.selectors';
import { ContentInput } from '../../components/ContentInput';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';
import { DossierOrgActions } from '../../redux/actions/dossier-org.actions';
import ProgressBar from '../../components/Progressbar/Progressbar';

interface CardWithBackgroundProps {
  title: string;
  items: CardWithTitleState[];
}

export const CardWithBackground: React.FC<CardWithBackgroundProps> = ({ items, title }): JSX.Element => {
  return (
    <div className="">
      <h2 className="text-lg font-semibold text-white mb-4">{title}</h2>
      <div className="flex justify-center items-center mt-10 ">
        <TwoColumnCardsWithTitleDescription items={items} />
      </div>
      {/* <div className="border-b-2 border-blue-200 mb-4 text-center">
                <div className="mb-1 text-sm text-white">Founded On </div>
                <div className="text-3xl font-semibold text-white">September 4, 1998</div>
            </div> */}

      {/* <CardWithTitleAndList      /> */}
    </div>
  );
};

interface ListWithTabsProps {
  title?: string;
  tabList: TabWithList[];
}

export const ListWithTabs: React.FC<ListWithTabsProps> = ({ tabList, title }): JSX.Element => {
  const [activeTab, setActiveTab] = useState(tabList.length > 0 ? tabList[0].key : '');
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-purple-800 ">{title}</h2>
        <div className="text-gray-500 text-xs">
          <i className="fas fa-ellipsis-h"></i>
        </div>
      </div>
      <div className="flex gap-2">
        {tabList?.map((author: TabWithList) => (
          <div
            key={author.key}
            className={`p-2 cursor-pointer rounded-lg ${
              activeTab === author.key ? 'bg-blue-100 text-blue-500' : 'bg-gray-100 text-gray-500'
            }`}
            onClick={() => setActiveTab(author.key)}
          >
            <span className="ml-2 text-sm font-medium">{author.name}</span>
          </div>
        ))}
      </div>
      <div className="text-sm font-medium text-gray-900 my-2">Person Names </div>
      <div className="space-y-3 h-250 max-h-250 sm:max-h-500 overflow-auto">
        {tabList
          ?.find((author: any) => author.key === activeTab)
          ?.items?.map((authorName: string, index: number) => (
            <div key={index} className="flex items-center justify-between">
              <div className="flex items-center">
                <AvatarWithName name={authorName?.charAt(0)?.toUpperCase()} />
                <div className="ml-3">
                  <div className="text-sm font-medium text-gray-900">{authorName}</div>
                  {/* <div className="text-xs text-gray-500">{authorName}</div> */}
                </div>
              </div>
              <button className="text-blue-500 text-xs">
                <a href={`/engine/dossier-generator?SearchText=${authorName}&&Type=Person`} rel="noopener noreferrer">
                  <EyeIcon className="h-4 w-4" />
                </a>
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export const DossierOrganizationLayout = (): JSX.Element => {
  const headerInfo = useSelector(dossierOrganizatonHeaderSelector);
  const currentDossierOrganization = useSelector(currentDossierOrg);
  const missionVisionData = useSelector(missionAndVisionSelector);
  const keyPersons = useSelector(keyPersonsSelector);
  const dossierFinancialInformationSelector = useSelector(dossierOrganizationFinancialInformationSelector);
  const dossierPartnershipsAndCollaborations = useSelector(dossierPartnershipsAndCollaborationsSelector);
  const dossierLegalAndCompliance = useSelector(dossierLegalAndComplianceSelector);
  const dossierMediaCoverageDto = useSelector(dossierPartnershipsAndCollaborationsSelector);
  const dossierClienteleAndBeneficiaries = useSelector(dossierClienteleAndBeneficiariesSelector);
  const dossierProgramAndService = useSelector(skillsAndExpertiseSelector);
  const dossierPartnershipsAndStakeholders = useSelector(mapDossierPartnershipsAndStakeholders);
  const isFetchingDossier = useSelector(isFetchingDossierOrgs);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  let userProcessingInput = useSelector(dossierOrgProcessingInput);
  useEffect(() => {
    if (id) {
      dispatch(DossierOrgActions.getDossierOrgById(id));
    } else {
      dispatch(setNotification({ title: 'Dossier Id is missing ', type: 'error' }));
      navigate(RoutePath.Dossier);
    }
  }, [id]);
  return (
    <>
      {userProcessingInput ? (
        <div className="md:w-7/10 w-full relative">
          <ContentInput
            showTextInput={true}
            engineType={GuardRailEngineType.Dossier}
            userProcessingInput={userProcessingInput}
          ></ContentInput>
        </div>
      ) : null}
      {currentDossierOrganization ? (
        <div className="container mx-auto p-4 ">
          <div className="container bg-gray-100 mx-auto p-4">
            <OrganizationHeader data={headerInfo} />
            <div className="my-4 ">{/* <MissionAndVision/> */}</div>

            <div className="bg-gray-100">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 h-300 min-h-300 sm:max-h-500 overflow-auto ">
                <CardComponentWithListAndTitle
                  title="Achievements"
                  items={currentDossierOrganization?.historyAndMilestonesDto?.Achievements}
                />
                <div className="md:col-span-2  half-background p-6 rounded-lg shadow-md mt-4 md:mt-0 mb-2 animate__animated animate__fadeInRight">
                  <CardWithBackground title={missionVisionData.title} items={missionVisionData.items} />
                </div>
              </div>
            </div>
            <LayoutLeftCardAndRightDescription
              rightSideDescription={
                <DossierOrganizationFinancialInformation
                  fundingPartners={dossierFinancialInformationSelector.fundingPartners}
                  revenueSources={dossierFinancialInformationSelector.revenueSources}
                  title={dossierFinancialInformationSelector.title}
                  description={dossierFinancialInformationSelector.description}
                />
              }
              leftSideCard={<ListWithTabs title={keyPersons.title} tabList={keyPersons.items} />}
            />
            <div className="my-2">
              <KeyValueListComponent
                title="Reporting Structure"
                data={currentDossierOrganization?.organizationalStructureDto?.ReportingStructure ?? {}}
              />
              {/* <CardWithTitle key='Reporting_Structure' description={currentDossierOrganization?.organizationalStructureDto?.ReportingStructure[0] ?? ""} title="Reporting Structure" /> */}
            </div>
            {currentDossierOrganization?.organizationalStructureDto?.TeamsAndRoles[0] ? (
              <LayoutLeftCardAndRightDescriptionWithBoxes
                data={currentDossierOrganization.organizationalStructureDto.TeamsAndRoles[0]}
              />
            ) : null}
            <TwoColumnCardsWithTitleDescription items={dossierPartnershipsAndCollaborations} />
            <div>
              <TwoColumnCardsWithTitleDescription items={dossierLegalAndCompliance} />
            </div>
            <div className="my-2">
              <OverviewWithCardList
                description={dossierProgramAndService.description}
                services={dossierProgramAndService.services}
                title={dossierProgramAndService.title}
              />
            </div>
            <div>
              <TwoColumnCardsWithTitleDescription items={dossierClienteleAndBeneficiaries} />
            </div>
            <div>
              <TwoColumnCardsWithTitleDescription items={dossierMediaCoverageDto} />
            </div>
            <div>
              <TwoColumnCardsWithTitleDescription items={dossierPartnershipsAndStakeholders} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex  h-screen text-gray-400 mt-10">
          {isFetchingDossier ? (
            <div className="w-4/5 md:w-4/5 mx-auto">
              <ProgressBar stop={true} message="Fetching Dossier Information..." showTime={false} />
            </div>
          ) : (
            <>No data to display</>
          )}
        </div>
      )}
    </>
  );
};

interface RowColumnList {
  items?: string[];
  isRowLayout?: boolean;
}

export const RowColumnList: React.FC<RowColumnList> = ({ items = [], isRowLayout = true }) => {
  items = ensureArray(items);
  return items && items.length > 0 ? (
    <div className={`flex gap-2 ${isRowLayout ? 'flex-row' : 'flex-col'}`}>
      {items?.map((item, index) => (
        <div key={index} className="flex items-center mb-3 min-w-350">
          <div className={`p-2 mr-3 bg-${getRandomColor()}-300 rounded-full`}></div>
          <span className="text-sm  text-blue-800">{item}</span>
        </div>
      ))}
    </div>
  ) : null;
};

interface TwoColumnCardsWithTitleDescriptionProps {
  items: CardWithTitleState[];
}

export const TwoColumnCardsWithTitleDescription: React.FC<TwoColumnCardsWithTitleDescriptionProps> = ({
  items,
}): JSX.Element => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-2 gap-4 animate__animated animate__fadeInUp">
      {items.map((author: CardWithTitleState, index: number) => (
        <CardWithTitle key={index} description={author.description} title={author.title} />
      ))}
    </div>
  );
};

export const KeyValueListComponent = ({ title, data }: any) => {

  if(Array.isArray(data)){
    data = data[0];
    if(typeof data === 'string'){
      data = {"Structure": data}
    }
  }

  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-2 animate__animated animate__fadeInUp">
      {/* Title */}
      <h2 className="text-purple-800 font-semibold my-5">{title}</h2>

      {/* Key-Value Pairs List */}
      <ul>
        {/* Loop through the object keys and values */}
        {Object.keys(data).map((key) => (
          <li className="mb-2" key={key}>
            <span className="font-bold text-sm mr-4">{key}:</span>
            <span className="text-gray-900  text-sm  my-2">{data[key]}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const CardWithTitle: React.FC<CardWithTitleState> = ({ title, description }): JSX.Element => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-2 animate__animated animate__fadeInUp">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-purple-800 font-semibold">{title}</h2>
          <p className="text-gray-900  text-sm  my-2">{description}</p>
        </div>
      </div>
    </div>
  );
};
interface CardWithTitleState {
  title?: string;
  description?: string;
}
export const CardWithTitleBorder: React.FC<CardWithTitleState> = ({ title = '', description = '' }) => {
  return (
    <div className="border border-dashed border-gray-300 rounded-lg p-4 mb-2">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-purple-800 font-semibold">{title}</h2>
          <p className="text-gray-900  text-sm  my-2">{description}</p>
        </div>
      </div>
    </div>
  );
};

interface LayoutLeftCardAndRightDescriptionProps {
  leftSideCard?: JSX.Element;
  rightSideDescription?: JSX.Element;
}

export const LayoutLeftCardAndRightDescription: React.FC<LayoutLeftCardAndRightDescriptionProps> = ({
  leftSideCard,
  rightSideDescription,
}): JSX.Element => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 animate__animated animate__fadeInUp">
      <div className="md:col-span-2 bg-white p-6 rounded-lg shadow-md">{leftSideCard}</div>
      <div className="bg-white p-6 rounded-lg shadow-md mt-4 md:mt-0">{rightSideDescription}</div>
    </div>
  );
};

interface LayoutLeftCardAndRightDescriptionWithBoxesProps {
  data: any[];
}

export const LayoutLeftCardAndRightDescriptionWithBoxes: React.FC<LayoutLeftCardAndRightDescriptionWithBoxesProps> = ({
  data,
}): JSX.Element => {
  if (typeof data === 'string') {
    return <></>;
  }
  const colors = ['red', 'green', 'blue', 'indigo', 'purple', 'pink'];
  return (
    <div className="grid grid-flow-row-dense grid-cols-4 gap-4 my-2">
      {Object.entries(data).map(([department, roles], index) => (
        <div
          key={index}
          className="p-6 rounded-lg min-h-150 shadow-md my-2 animate__animated animate__fadeInUp"
          style={{
            background: `linear-gradient(to bottom, ${
              colors[Math.floor(Math.random() * colors.length)]
            } 50%, #ffffff 50%)`,
          }}
        >
          <div className="border-b-2 border-blue-200 mb-4 text-center">
            <div className="text font-semibold text-white">{department}</div>
          </div>
          <div className="bg-white shadow-md rounded-lg p-4">
            <RowColumnList items={roles} isRowLayout={false} />
          </div>
        </div>
      ))}
    </div>
  );
};

export const OverviewWithCardList: React.FC<DossierOrganizationProgramAndServices> = ({
  title,
  description,
  services,
}): JSX.Element => {
  return (
    <div className="bg-white rounded-lg shadow-md mb-2 p-4">
      <h2 className="text-purple-800 font-semibold my-2">{title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 h-300 min-h-300 sm:max-h-500 overflow-auto ">
        <div className="p-6 text-sm flex text-center justify-center items-center bg-gradient-to-b from-purple-500 via-violet-600 to-indigo-600">
          <p className="text-white">{description}</p>
        </div>
        <div className="md:col-span-2  md:mt-0 mb-2">
          {services.map((service: CardTitleDescription) => (
            <CardWithTitleBorder title={service.title} description={service.description} />
          ))}
        </div>
      </div>
    </div>
  );
};

interface CardComponentWithListAndTitleProps {
  title: string;
  items?: string[];
}

export const CardComponentWithListAndTitle: React.FC<CardComponentWithListAndTitleProps> = ({ title, items }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-2 animate__animated animate__fadeInLeft">
      <h2 className="text-purple-800 font-semibold my-2">{title}</h2>
      <RowColumnList items={items} isRowLayout={false} />
    </div>
  );
};
