import React, { useState } from 'react';

interface CopyToClipboardProps {
  text: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async <E extends Element = HTMLElement>(event?: React.MouseEvent<E, MouseEvent>) => {
    try {
      event?.preventDefault();
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000); // Reset the copied state after 2 seconds
    } catch (err) {
      console.error('Failed to copy text to clipboard:', err);
    }
  };

  return (
    <div>
      <input type="text" value={text} readOnly style={{ position: 'absolute', left: '-9999px' }} />
      <button onClick={handleCopyClick}>
        {isCopied ? (
          <>
            <span role="img" aria-label="Check Mark">
              ✅
            </span>
          </>
        ) : (
          <>
            <span role="img" aria-label="Clipboard">
              📋
            </span>
          </>
        )}
      </button>
    </div>
  );
};

export default CopyToClipboard;
