import { ArticleUpdateRequest, AssertionResult, AssertionResultLite, HttpErrorResponse } from '../../../types/models';
import { DashboardMatericsRequestByDateRange, ModuleMetrics } from '../dashboard/models/dashboard.model';
import { HttpClient } from '../http';
import { ArticleProcessRequest } from './models/ArticleProcessRequest';

class ArticleServiceImpl {
  processArticle(request: ArticleProcessRequest): Promise<AssertionResult> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/articles' : '/articles';
    return HttpClient.post<AssertionResult>(baseURL, request, {
      showLoader: false,
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  getForDashboard(request: DashboardMatericsRequestByDateRange): Promise<ModuleMetrics> {
    const baseURL = '/articles/getMetricsByDaterange';
    return HttpClient.post<ModuleMetrics>(baseURL, request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  private getBaseUrl = (): string => {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/articles' : '/articles';
    return baseURL;
  };
  getUserArticlesById(id: string): Promise<AssertionResult[]> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/articles' : '/articles';
    return HttpClient.get<AssertionResult[]>(`/${baseURL}/${id}`, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  getArticlesList(page: number = 1, take: number = 10): Promise<AssertionResultLite> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/articles' : '/articles';

    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}`;
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<AssertionResultLite>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  getDefaulArticle(): Promise<AssertionResult> {
    return HttpClient.get<AssertionResult>('/defaultarticle', { portNumber: 5001 }).then((response) => response.data);
  }

  updateArticle(changes: ArticleUpdateRequest): Promise<AssertionResult> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/demo/articles' : '/articles';
    let request = {
      isFavorite: changes.isFavorite.toString(),
      progressPercentage: '100',
    };

    return HttpClient.put<AssertionResult>(`${baseURL}/${changes.id}`, request, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  deleteArticle(id: string): Promise<void> {
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const baseURL = isDemoURL ? '/articles' : '/articles';

    return HttpClient.delete<void>(`${baseURL}/${id}`, { portNumber: 5001 })
      .then(() => {})
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  clearHistory(): Promise<boolean> {
    return HttpClient.post<boolean>('/articles/clear-history', {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
}

const ArticleService = new ArticleServiceImpl();

export { ArticleService };
