import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from '../../common/helpers/objects/is-empty';
import Input from '../Input';
import { MainButton } from '../MainButton';
import { useDispatch, useSelector } from 'react-redux';
import { RoutePath } from '../../common/constants/route-path';
import { SecondaryButton } from '../SecondaryButton';
import Dropdown from '../DropDown/DropDown';
import { ClientAppResponseDto, OnboardingFormClientData } from '../../common/services/client-app/models/client-app';
import { currentClientApp, isDeleteClientApp, isFetchingClientApp } from '../../redux/selectors/client-app.selectors';
import CopyToClipboard from '../Clipboard/CopyToClipboard';
import DropdownV2, { ExternalAppOption } from '../DropDown/DropDownV2';
import { CurrentDateTime, getFormatedDate } from '../../common/helpers/Helper';
import { ExternalSearchSource } from '../../common/services/external-search/models/external-search';
import { currentExternalSearch, externalSearchLightList } from '../../redux/selectors/external-search.selectors';
import { clientAppActions } from '../../redux/actions/client-app.actions';
import { ConfirmModal } from '../Modal/ConfirmModal';
import useModal from '../Modal/useModal';

const isStringEmpty = (str: any) => !str;

interface PropTypes {
  onSubmit: (clientAppResponseDto: ClientAppResponseDto) => void;
}

const ClientApiOnboardingForm: FC<PropTypes> = ({ onSubmit }) => {
  let modal = useModal();
  const [isShown, openModal, closeModal] = modal;
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let expireDefaultDate = new Date();
  expireDefaultDate.setMonth(expireDefaultDate.getMonth() + 6);
  const clientAppResponseDto: ClientAppResponseDto = useSelector(currentClientApp);
  const isFetchingClient: boolean = useSelector(isFetchingClientApp);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const externalSearchLightSources = useSelector(externalSearchLightList);
  const isDeleteClientAppStarted: boolean = useSelector(isDeleteClientApp);
  //this should be removed and should come from api
  const externalSearchOptions: ExternalAppOption[] = [
    { key: '65477df56fd074b6006258a7', value: 'GoogleApi' },
    { key: '65477c006fd074b6006258a2', value: 'BingApi' },
    { key: '654e2d2b087d9579d1753a29', value: 'WikiApi' },
  ];

  const externalSearchOptionV2 = externalSearchLightSources.map((e) => {
    return {
      key: e.id,
      value: e.name,
    };
  });
  const [externalSearchDefaultOption, setExternalSearchDefaultOption] = useState(
    externalSearchOptionV2.find((a) => a.value == 'GoogleApi'),
  );
  let initialExpireDate = new Date();
  let initialData: OnboardingFormClientData = {
    id: '',
    name: '',
    alias: '',
    clientDomain: '',
    requestPerDay: 300,
    expiresAt: initialExpireDate.setMonth(initialExpireDate.getMonth() + 6).toString(),
    callbackEndpoint: '',
    externalSourceApiId: '65477df56fd074b6006258a7',
    clientToHostHeaderKey: '',
    clientToHostApiKey: '',
    clientToHostUpdateApiKey: false,
  };

  const [clientApp, setClientApp] = useState(initialData);

  const [expireDate, setExpireDate] = useState(expireDefaultDate);

  const [isDeleteClicked, setIsDeleteClicked] = useState(false);

  const handleDropdownExpireAt = (selectedValue: any) => {
    let expireDate = new Date();
    if (selectedValue == '12 months') {
      expireDate.setMonth(expireDate.getMonth() + 12);
    } else if (selectedValue == '24 months') {
      expireDate.setMonth(expireDate.getMonth() + 24);
    } else {
      expireDate.setMonth(expireDate.getMonth() + 6);
    }
    setExpireDate(expireDate);
    setClientApp({ ...clientApp, expiresAt: expireDate.toString() });
  };

  const handleDropdownExternalSearches = (selectedValue?: ExternalAppOption) => {
    setClientApp({ ...clientApp, externalSourceApiId: selectedValue?.key });
    let finalDefault = selectedValue == undefined ? externalSearchOptions[0] : selectedValue;
    setExternalSearchDefaultOption(finalDefault);
  };

  const handleRequestPerDayChange = (type: any) => (event: ChangeEvent<HTMLInputElement>) => {
    const result = event.target.value.replace(/\D/g, '');
    setClientApp({ ...clientApp, [type]: Number(result) });
  };

  const updateClientAppDataHandler = useCallback(
    (type: any) => (event: { target: { value: any } }) => {
      setClientApp({ ...clientApp, [type]: event.target.value });
    },
    [clientApp],
  );
  const updateClientAppCheckboxDataHandler = useCallback(
    (type: any) => (event: { target: { value: any } }) => {
      setClientApp({
        ...clientApp,
        [type]: !clientApp.clientToHostUpdateApiKey,
      });
    },
    [clientApp],
  );

  useEffect(() => {
    if (clientAppResponseDto && isFetchingClient) {
      let response: OnboardingFormClientData = {
        name: clientAppResponseDto.name,
        alias: clientAppResponseDto.alias,
        clientDomain: clientAppResponseDto.clientDomain,
        requestPerDay: clientAppResponseDto.requestPerDay,
        expiresAt: clientAppResponseDto.expiresAt,
        callbackEndpoint: clientAppResponseDto.callbackEndpoint,
        externalSourceApiId: isStringEmpty(clientAppResponseDto.externalSourceApiId)
          ? externalSearchOptionV2[0].key
          : clientAppResponseDto.externalSourceApiId,
        id: clientAppResponseDto.id,
        clientToHostHeaderKey: clientAppResponseDto.clientToHostHeaderKey,
        clientToHostApiKey: clientAppResponseDto.clientToHostApiKey,
      };
      setClientApp(response);
      let defaultOption = externalSearchOptionV2.find((a) => a.key == response.externalSourceApiId);
      let finalDefault = defaultOption == undefined ? externalSearchOptionV2[0] : defaultOption;
      setExternalSearchDefaultOption(finalDefault);
      setExpireDate(
        clientAppResponseDto.expiresAt == undefined ? expireDate : new Date(clientAppResponseDto.expiresAt),
      );
    }
  }, [clientAppResponseDto]);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    // Add your validation logic here
    if (!clientApp?.name) {
      newErrors.name = 'Client application name required, please enter a valid application name';
    }
    if (!clientApp?.clientDomain) {
      newErrors.clientDomain = 'Client domain name required, please enter a valid domain name';
    }
    if (!clientApp?.requestPerDay) {
      newErrors.requestPerDay = 'please enter number of request per day';
    }
    if (!clientApp?.expiresAt) {
      newErrors.expiresAt = 'Expires at must be a valid date, please select the expire date';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        onSubmit(clientApp);
        setClientApp(initialData);
      } catch (error) {
        console.error('Error updating data:', error);
      }
    }
  };

  const handleConfirm = (e: React.FormEvent) => {
    e.preventDefault();
    openModal();
  };

  const handleDelete = (e: React.FormEvent) => {
    e.preventDefault();
    setIsDeleteClicked(true);
    if (clientApp && clientApp.id) {
      try {
        dispatch(clientAppActions.deleteClientApp(clientApp.id));
        setClientApp(initialData);
        // navigate(RoutePath.clientApiOnboarding);
      } catch (error) {
        console.error('Error deleting data:', error);
      }
    }
    closeModal();
  };

  if (!clientApp) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="mt-5">
        <p className="mt-1 text-base text-blueGray-500">Provide below details to on-board Guardrail </p>
      </div>
      <form onSubmit={handleSubmit} action="#" method="POST">
        {
          <div className="pt-4 my-1">
            <label htmlFor="name" className="mb-1 block text-xs text-gray-600 font-sansInte">
              Client application name:
            </label>
            <Input
              id="name"
              type="text"
              size="sm"
              placeholder="Client application name"
              value={clientApp.name}
              error={!!errors.name}
              onChange={updateClientAppDataHandler('name')}
            />
            <input id="id" type="hidden" />
            {errors.name && (
              <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-4 box-content">{errors.name}</div>
            )}
          </div>
        }
        <div className="pt-4 my-2">
          <label htmlFor="alias" className="mb-1 block text-xs text-gray-600  font-sansInte">
            Contact person name:
          </label>
          <Input
            id="alias"
            type="text"
            size="sm"
            placeholder="Contact person name"
            autoComplete="off"
            value={clientApp.alias}
            error={!!errors.alias}
            onChange={updateClientAppDataHandler('alias')}
          />
          {errors.alias && (
            <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-4 box-content">{errors.alias}</div>
          )}
        </div>
        <div className="my-4">
          <label htmlFor="client_domain" className="mb-1 block text-xs text-gray-600  font-sansInte">
            Client domain:
          </label>
          <Input
            id="client-domain"
            type="text"
            size="sm"
            placeholder="client Domain"
            autoComplete="off"
            value={clientApp.clientDomain}
            error={!!errors.clientDomain}
            onChange={updateClientAppDataHandler('clientDomain')}
          />
          {errors.clientDomain && (
            <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.clientDomain}</div>
          )}
        </div>
        <div className="my-4">
          <label htmlFor="request_per_day" className="mb-1 block text-xs text-gray-600  font-sansInte">
            No. of requests per day:
          </label>
          <Input
            id="request-per-day"
            type="text"
            size="sm"
            placeholder="No. of requests per day"
            autoComplete="off"
            value={clientApp.requestPerDay?.toString()}
            error={!!errors.requestPerDay}
            onChange={handleRequestPerDayChange('requestPerDay')}
          />
          {errors.requestPerDay && (
            <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.requestPerDay}</div>
          )}
        </div>
        <div className="my-4">
          <label htmlFor="expires_at" className="mb-1 block text-xs text-gray-600  font-sansInte">
            Expires after: {getFormatedDate(expireDate)}
          </label>
          <div className="flex row-auto gap-x-4">
            <Dropdown
              options={['6 months', '12 months', '24 months']}
              defaultOption="6 months"
              onSelect={handleDropdownExpireAt}
            />
          </div>
        </div>
        {/* <div className='my-4'>
          <label
            htmlFor='callback_endpoint'
            className='mb-1 block text-xs text-gray-600  font-sansInte'
          >
            Callback Endpoint
          </label>
          <Input
            id='callback-endpoint'
            type='text'
            size='sm'
            placeholder='Callback Endpoint'
            autoComplete='off'
            value={clientApp.callbackEndpoint}
            error={!!errors.callbackEndpoint}
            onChange={updateClientAppDataHandler('callbackEndpoint')}
          />
          {errors.callbackEndpoint && (
            <div className='text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content'>
              {errors.callbackEndpoint}
            </div>
          )}
        </div> */}
        {clientApp.clientToHostApiKey && (
          <div className="my-4">
            <label htmlFor="callback_endpoint" className="mb-1 block text-xs text-gray-600  font-sansInte">
              Client to host API key
            </label>

            <div className="flex row-auto gap-x-1">
              <label id="client-to-host-api-key" className="mt-1 block text-xs text-gray-600  font-sansInte">
                {clientApp.clientToHostApiKey}
              </label>
              <div className="h-3">
                <CopyToClipboard text={clientApp.clientToHostApiKey} />
              </div>
              <div className="flex mt-1 ml-16">
                <input
                  value="reset"
                  type="checkbox"
                  checked={clientApp.clientToHostUpdateApiKey}
                  onChange={updateClientAppCheckboxDataHandler('clientToHostUpdateApiKey')}
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                />
                <label htmlFor="reset_host_key" className="ml-1 block text-sm text-gray-600 font-sansInte">
                  Reset
                </label>
              </div>
            </div>

            {errors.callbackEndpoint && (
              <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">
                {errors.callbackEndpoint}
              </div>
            )}
          </div>
        )}
        <div className="my-4">
          <label htmlFor="search_source_option" className="mb-1 block text-xs text-gray-600  font-sansInte">
            Search Source Option
          </label>
          <DropdownV2
            options={
              externalSearchOptionV2 == undefined || externalSearchOptionV2.length == 0
                ? externalSearchOptions
                : externalSearchOptionV2
            }
            defaultOption={externalSearchDefaultOption}
            onSelect={handleDropdownExternalSearches}
          />
        </div>
        <div className="my-8 flex gap-6">
          <MainButton
            isLoading={false}
            text={'Submit'}
            onClick={handleSubmit}
            className="px-5 py-2"
            stateProp="rest"
            disabled={!isEmpty(errors) || !clientApp.clientDomain || !clientApp.clientDomain}
          />
          <MainButton
            isLoading={isDeleteClicked && isDeleteClientAppStarted}
            text={'Delete'}
            onClick={handleConfirm}
            className="px-2 py-2 w-24"
            stateProp="rest"
            disabled={isStringEmpty(clientApp?.id)}
          />
          {
            <SecondaryButton
              className="px-3 py-2"
              stateProp="rest"
              onClick={() => {
                navigate(RoutePath.Landing);
              }}
              text="Cancel"
            />
          }
        </div>
      </form>
      <div>
        <ConfirmModal isShown={isShown} onClose={closeModal} onConfirm={handleDelete} />
      </div>
    </div>
  );
};

export default ClientApiOnboardingForm;
