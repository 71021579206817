import { createSelector } from "@reduxjs/toolkit";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { userDetails } from "../../../redux/selectors/profile.selectors";

interface UserDetails {
  email: string;
}

interface StripePricingTableProps {
  pricingTableId: string;
  publishableKey: string;
}

interface PriceItemClickEvent extends Event {
  detail: {
    checkoutUrl: string;
  };
}

export const StripePricingTable: React.FC<StripePricingTableProps> = ({
  pricingTableId,
  publishableKey
}) => {
  const currentLoggedInUser = useSelector(userDetails) as UserDetails;
  const pricingTableRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (pricingTableRef.current) {
        pricingTableRef.current.addEventListener('priceItemClick', handlePriceItemClick as EventListener);
      }
    };

    return () => {
      document.body.removeChild(script);
      if (pricingTableRef.current) {
        pricingTableRef.current.removeEventListener('priceItemClick', handlePriceItemClick as EventListener);
      }
    };
  }, []);

  const handlePriceItemClick = (event: PriceItemClickEvent) => {
    event.preventDefault();
    const checkoutUrl = event.detail.checkoutUrl;
    window.open(checkoutUrl, '_blank');
  };

  return React.createElement("stripe-pricing-table", {
    ref: pricingTableRef,
    "customer-email": currentLoggedInUser.email,
    "pricing-table-id": pricingTableId,
    "publishable-key": publishableKey,
    "success-url": `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
  });
};