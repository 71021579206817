import { FC, useEffect, useRef, useState } from 'react';
import Modal from '../Modal';
import { Validators } from '../../../common/helpers/form-controls/validators';
import { useForm } from '../../../common/helpers/form-controls/use-form';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from '../../../redux/actions/layout.actions';
import { showGuestOTPScreen, showOTPScreen } from '../../../redux/selectors/profile.selectors';
import {
  CREATE_ACCOUNT,
  ALREADY_HAVE_ACCOUNT,
  SIGN_IN,
  GET_STARTED_NOW,
  DONT_HAVE_ACCOUNT,
  DONT_KNOW_EMAIL,
  REQUEST_SUPPORT,
  PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_MESSAGE,
  SUBMIT,
  BACK_TO_EMAIL,
  BACK_TO_SIGNIN,
} from '../../../common/constants/app-text';
import CloseButton from '../../CloseButton/CloseButton';
import { Divider } from '../../Divider/Divider';
import Input from '../../Input';
import { LogoDetailed } from '../../LogoDetailed/LogoDetailed';
import { MainButton } from '../../MainButton';
import { MessageWithAction } from '../../MessageWithAction/MessageWithAction';
import { SignInProvidersGroup } from '../../SignInProvidersGroup/SignInProvidersGroup';
import { TermsAndConditions } from '../../TermsAndConditions/TermsAndConditions';
import { CenterLabel } from '../../CenterLabel/CenterLabel';
import { profileActions } from '../../../redux/actions/profile.actions';
import { isEmpty } from '../../../common/helpers/objects/is-empty';
import { passwordRecoveryActions } from '../../../redux/actions/password-recovery.actions';
import PasswordRecoveryEmailStep from './PasswordRecoveryEmailStep';
import { OTPVerificationStep } from '../GuestSignInModal/OTPVerificationStep';
import { selectToken } from '../../../redux/selectors/password-recover.selectors';
import { RoutePath } from '../../../common/constants/route-path';
import { useNavigate } from 'react-router-dom';
interface PropTypes {
  orgId?: string;
  onClose?: () => void;
}

const PasswordRecoveryModal: FC<PropTypes> = ({ onClose }) => {
  const enableOTPScreen = useSelector(showOTPScreen);
  const recoveryToken = useSelector(selectToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (recoveryToken && recoveryToken.length) {
      dispatch(profileActions.showOTPVerificationCode(false));
      dispatch(layoutActions.setShowSignInModal(false));
      navigate(RoutePath.PasswordRecovery);
    }
  }, [recoveryToken]);
  return enableOTPScreen ? (
    <OTPVerificationStep
      otpAction="Verify OTP"
      onBackNavigation={() => {
        dispatch(profileActions.showOTPVerificationCode(false));
      }}
    />
  ) : (
    <PasswordRecoveryEmailStep />
  );
};

export default PasswordRecoveryModal;
