import { useRouteError } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Footer } from '../Footer';
import ContentLayout from '../ContentLayout/ContentLayout';
import { Header } from '../Header';
import MainPage from '../MainPage/MainPage';

export default function ErrorPage() {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <>
      <MainPage>
          <ErrorContent />
      </MainPage>
    </>
  );

  function ErrorContent() {
    return (
      <div className="grid h-full min-h-screen items-center justify-center place-content-center bg-white px-4">
        <div className="text-center">
          <h1 className="text-9xl font-black text-gray-200">404</h1>

          <p className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl">Uh-oh!</p>

          <p className="mt-4 text-gray-500">
            We can't find that page. <i>{error.statusText || error.message}</i>
          </p>

          <br />
          <br />
          <Link
            to="/home"
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Go back to home
          </Link>
        </div>
      </div>
    );
  }
}
