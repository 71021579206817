import ContentLayout from '../../../components/ContentLayout/ContentLayout';
import { Footer } from '../../../components/Footer';
import { Header } from '../../../components/Header';
import MainPage from '../../../components/MainPage/MainPage';
import { MainMenu } from '../../../layouts/Sidenav/MainMenu';
import { NewPassword } from './Newpassword';

export const PasswordRecovery = (): JSX.Element => {
  return (
    <MainPage>
      <Header />
      <ContentLayout>
        <MainMenu isPushMenu={true} />
      </ContentLayout>
      <Footer />
    </MainPage>
  );
};
