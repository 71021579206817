import { LinkIcon } from '@heroicons/react/outline';
import {  StatusIcon } from '../../../common/icons/Icons';
import { CodeMirrorInput } from '../CodeMirrorInput';
import Badge from '../../Base/Badge';
import { useEffect, useState } from 'react';
import {  Medium } from '../../../types/models';

interface MediumReferenceProps {
  index?: number;
  item: Medium;
  code?:string;
}

export const MediumReference = ({ index = 1 , item, code="" }:MediumReferenceProps) => {
  let [primaryTags , setPrimaryTags] = useState<string[]>([]);
  useEffect(() => {
    let primary = [] as string[];
    if(item){
        primary.push(`${item?.followers ?? 0} followers`)
        primary.push(`Tier ${item.tier}`)
    }
    setPrimaryTags(primary);
  },[])
  return (
    <>
      <div className="mt-5 animate__animated animate__fadeInUp ">
        <p className="font-bold font-black">Reference {index+1}</p>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col ">
            <div className="flex  flex-row gap-3 mt-4">
            <span className="text-gray-500 font-semibold font-[Open_sans]">Author Name</span>
              <span onClick={() => window.open(item.author_url, '_blank') }  className="text-primary-blue font-[Open_sans]">{item.author_name?? ""}</span>
            </div>
            <div>
              <div className="flex flex-row flex-wrap ">
                {primaryTags.map((e, index) => (
                  <Badge key={index} backgroundColor="#EBEEF6" text={e} />
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="flex  flex-row gap-3 mt-4">
              <div
              onClick={() => window.open(item.url, '_blank') }
                className="flex justify-between gap-4  border rounded  mx-2 p-3 align-middle"
                style={{
                  background: '#D6F6F9',
                }}
              >
                <div className="flex col"  >
                  <span className="mt-1">
                    <StatusIcon color="#10828B" />
                  </span>
                  <span className="font-sm font-['Open_Sans'] mx-2">Medium  URL</span>
                </div>
                <span className="mt-1">
                  <LinkIcon color="#56607C" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <>
        { code && code.length && <CodeMirrorInput
            hideTitle={true}
            disabled={true}
            code={code}
          />}
        </>
      </div>
    </>
  );
};
