import React from 'react';
import { RowColumnList } from '../../views/Engines/DossierOrganization';
import { DossierFinancialInformation } from '../../types/models';

export const DossierOrganizationFinancialInformation: React.FC<DossierFinancialInformation> = ({
  title,
  description,
  revenueSources,
  fundingPartners,
}) => {
  return (
    <>
      <h2 className="text-purple-800 font-semibold">{title}</h2>
      <p className="text-gray-900 text-sm my-2">{description}</p>
      <div className="flex flex-col h-250 min-h-250 sm:max-h-500 overflow-auto">
        <div>
          <div className="flex items-center my-2 font-semibold">Revenue Sources</div>
          <RowColumnList items={revenueSources} isRowLayout={false} />
        </div>
        <div>
          <div className="flex items-center my-2 font-semibold">Funding Partners</div>
          <RowColumnList items={fundingPartners} isRowLayout={false} />
        </div>
      </div>
    </>
  );
};
