import {
  ArticleUpdateRequest,
  AssertionResult,
  AssertionResultLite,
  CodeAnalysisEnteryRequest,
  CodeAnalysisInput,
  CodeAnalysisLightResponse,
  CodeAnalysisResult,
  HttpErrorResponse,
  ResultListLite,
  UserHistoryList,
} from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const CODE_ANALYSIS_STORE_NAME = 'Code Analysis Store';

const createPayloadAction = getCreatePayloadActionFromStore(CODE_ANALYSIS_STORE_NAME);
const createAction = getCreateActionFromStore(CODE_ANALYSIS_STORE_NAME);

const getDefaultCodeAnalysis = createAction('Get Default CodeAnalysis');
const getDefaultCodeAnalysisSuccess = createPayloadAction<CodeAnalysisResult>('Get Default Success');
const getDefaultCodeAnalysisFailure = createPayloadAction<HttpErrorResponse>('Get Default Failure');

const processCodeAnalysis = createPayloadAction<CodeAnalysisInput>('Process CodeAnalysis');
const processCodeAnalysisEnabled = createPayloadAction<boolean>('Process CodeAnalysis Enabled');
const processCodeAnalysisSuccess = createPayloadAction<CodeAnalysisResult>('Process Success');
const processCodeAnalysisFailure = createPayloadAction<HttpErrorResponse>('Process Failure');

const getCodeAnalysisById = createPayloadAction<string>('Get CodeAnalysis By Id');
const getCodeAnalysisByIdSuccess = createPayloadAction<CodeAnalysisResult>('get CodeAnalysis By Id Success');
const getCodeAnalysisByIdFailure = createPayloadAction<HttpErrorResponse>('get CodeAnalysis By Id Failure');

const getUserCodeAnalysisList = createPayloadAction<number>('Get User CodeAnalysis List');
const getUserCodeAnalysisListSuccess = createPayloadAction<ResultListLite<CodeAnalysisLightResponse>>(
  'Get User CodeAnalysis List Success',
);
const getUserCodeAnalysisListFailure = createPayloadAction<HttpErrorResponse>('Get User CodeAnalysis List Failure');

const setCodeAnalysisFavorite = createPayloadAction<ArticleUpdateRequest>('Set CodeAnalysis Favorite');
const setCodeAnalysisFavoriteSuccess = createPayloadAction<CodeAnalysisResult>('Set CodeAnalysis Favorite Success');
const setCodeAnalysisFavoriteFailure = createPayloadAction<HttpErrorResponse>('Set CodeAnalysis Favorite Failure');

const deleteCodeAnalysis = createPayloadAction<string>('Delete CodeAnalysis');
const deleteCodeAnalysisSuccess = createPayloadAction<string>('Delete CodeAnalysis Success');
const deleteCodeAnalysisFailure = createPayloadAction<HttpErrorResponse>('Delete CodeAnalysis Failure');

const toggleFavoriteCodeAnalysis = createPayloadAction<boolean>('Toggle Favorite CodeAnalysis');
const clearCodeAnalysisHistory = createAction('Clear code analysis history');
const clearCodeAnalysisHistorySuccess = createAction('Clear code analysis history success');

const clearCodeAnalysisHistoryFailure = createAction('Clear code analysis history failure');
const setCodeAnalysisHistoryEntry = createPayloadAction<CodeAnalysisEnteryRequest>('Toggle Favorite CodeAnalysis');

const setCodeAnalysisContent = createPayloadAction<string>('Set CodeAnalysis Content');

export const CodeAnalysisActions = {
  processCodeAnalysis,
  setCodeAnalysisHistoryEntry,
  processCodeAnalysisSuccess,
  processCodeAnalysisEnabled,
  processCodeAnalysisFailure,
  getUserCodeAnalysisList,
  getUserCodeAnalysisListSuccess,
  getUserCodeAnalysisListFailure,
  getDefaultCodeAnalysis,
  getDefaultCodeAnalysisSuccess,
  getDefaultCodeAnalysisFailure,
  getCodeAnalysisById,
  getCodeAnalysisByIdSuccess,
  getCodeAnalysisByIdFailure,
  setCodeAnalysisFavorite,
  setCodeAnalysisFavoriteSuccess,
  setCodeAnalysisFavoriteFailure,
  toggleFavoriteCodeAnalysis,
  clearCodeAnalysisHistory,
  clearCodeAnalysisHistorySuccess,
  clearCodeAnalysisHistoryFailure,
  setCodeAnalysisContent,
  deleteCodeAnalysis,
  deleteCodeAnalysisSuccess,
  deleteCodeAnalysisFailure,
};
