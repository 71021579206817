import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { ProjectsState } from '../slices/projects.slice';

const selectProjectsState: (state: AppState) => ProjectsState = (state) => state.projects;

export const ProjectsSelector = createSelector(selectProjectsState, ({ projects }) => projects);
export const currentProjectSelector = createSelector(selectProjectsState, ({ currentProject }) => currentProject);
export const projectsPaginationConfigSelector = createSelector(
  selectProjectsState,
  ({ projectsPaginationConfig }) => projectsPaginationConfig,
);
export const currentArticleSelector = createSelector(selectProjectsState, ({ currentArticle }) => currentArticle);
export const loadingProjectsSelector = createSelector(selectProjectsState, ({ loadingProjects }) => loadingProjects);
export const pinnedProjectsSelector = createSelector(selectProjectsState, ({ pinnedProjects }) => pinnedProjects);
