import './style.css';
import { AssertionsContainer } from '../../components/AssertionsContainer/AssertionsContainer';
import { useDispatch, useSelector } from 'react-redux';
import { articleErrorCodes, currentArticle } from '../../redux/selectors/article.selectors';
import { isGuestUser, isLoggedInUser } from '../../redux/selectors/activeUser.selectors';
import { FeaturesList } from '../../components/FeaturesList/FeaturesList';
import { UnlockFeaturesBanner } from '../../components/UnlockFeaturesBanner/UnlockFeaturesBanner';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { articleActions } from '../../redux/actions/article.actions';
import { RoutePath } from '../../common/constants/route-path';
import { setNotification } from '../../redux/actions/notification.actions';
import { ArticleResultView } from '../../components/ResultView/ResultView';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';
import { contradictionErrorCodes, currentContradiction } from '../../redux/selectors/contradiction.selectors';
import { contradictionActions } from '../../redux/actions/contradiction.actions';
import AppLoader from '../../components/AppLoader/AppLoader';

export interface ArticleResultViewContentProps {
  engineType?: GuardRailEngineType;
  showClipToProject?: boolean;
}

export const ArticleResultViewContent: React.FC<ArticleResultViewContentProps> = ({
  engineType = GuardRailEngineType.FactChecker,
  showClipToProject,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInUser);
  const assertion = useSelector(engineType === GuardRailEngineType.FactChecker ? currentArticle : currentContradiction);
  const error = useSelector(
    engineType === GuardRailEngineType.FactChecker ? articleErrorCodes : contradictionErrorCodes,
  );
  const isGuestAccount = useSelector(isGuestUser);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (error && error.length) {
      navigate(RoutePath.Landing);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (id) {
      dispatch(
        engineType === GuardRailEngineType.FactChecker
          ? articleActions.getArticleById(id)
          : contradictionActions.getContradictionById(id),
      );
    } else {
      let notification = 'Article';
      if (engineType === GuardRailEngineType.ContractionFinder) {
        notification = 'Contradiction';
      }
      dispatch(setNotification({ title: `${notification} Id Missing `, type: 'error' }));
      navigate(RoutePath.Landing);
    }
  }, [dispatch, engineType, id, navigate]);

  return assertion ? (
    <>
      <div>
        <div className="flex justify-center items-center animate__animated animate__fadeInUp">
          <div className="w-4/5">
            <ArticleResultView assertion={assertion} engineView={engineType} showTags={true} />
          </div>
        </div>
        <div
          className={`group-wrapper animate__animated animate__fadeInUp animate__delay-1s ${
            isLoggedIn && !isGuestAccount ? '' : 'hh-5'
          }`}
        >
          <AssertionsContainer
            engineType={engineType}
            assertion={assertion}
            enableOverlay={!isLoggedIn || isGuestAccount}
            showClipToProject={showClipToProject}
          ></AssertionsContainer>
        </div>
      </div>
      {(!isLoggedIn || isGuestAccount) && (
        <div className="flex justify-center items-center mt-2 mb-2">
          <div className="w-4/5">
            <UnlockFeaturesBanner />
            <FeaturesList isRowLayout={false} />
          </div>
        </div>
      )}
    </>
  ) : (
    <AppLoader />
  );
};
