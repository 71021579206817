import { useEffect, useState } from 'react';
import { AssertionResultsList } from '../AssertionResultsList/AssertionResultsList';
import { ContradictionResultsList } from '../ContradictionResultsList/ContradictionResultsList';
import { AssertionsContainerProps } from '../../common/models/Props/props';
import React from 'react';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';
import { ContradictionResult } from '../../types/models';
import CountIndicator from '../CountIndicator/CountIndicator';

export const AssertionsContainer: React.FC<AssertionsContainerProps> = ({
  assertion,
  enableOverlay,
  engineType = GuardRailEngineType.FactChecker,
  showClipToProject,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [renderedResults, setRenderedResults] = useState<any[]>([]);
  const [groupedResult, setGroupedResult] = useState<{ index: number; results: any[]; assertion: string }[]>([]);
  useEffect(() => {
    if (assertion) {
      if (engineType === GuardRailEngineType.FactChecker) {
        setRenderedResults(assertion.assertions);
      } else if (engineType === GuardRailEngineType.ContractionFinder) {
        console.log((assertion as ContradictionResult)?.assertionsInfo);
        let assertions = (assertion as ContradictionResult)?.assertionsInfo;
        if (assertions) {
          let uniqueAssertions = assertions.filter(
            (result, index: number, self) => index === self.findIndex((s) => s.assertion === result.assertion),
          );
          setRenderedResults(uniqueAssertions);
          let results = [] as any[];
          uniqueAssertions.forEach((result, index) => {
            results.push({
              index,
              results: assertions.filter((assertion) => assertion.assertion === result.assertion),
              assertion: result.assertion,
            });
          });
          setGroupedResult(results);
        }
      }
    }
  }, [assertion]);

  return (
    <>
      {renderedResults.length ? (
        <div className={`group-2 w-full`}>
          <div className="overlap-group-2">
            <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
              {engineType === GuardRailEngineType.ContractionFinder
                ? renderedResults.map((result, index) => (
                    <li key={index}>
                      <CountIndicator
                        setCurrentIndex={() => setCurrentIndex(index)}
                        colorCode={(result as any).colorCode}
                        currentIndex={currentIndex}
                        index={index}
                      />
                    </li>
                  ))
                : renderedResults.map((result, index) => (
                    <li key={index}>
                      <CountIndicator
                        setCurrentIndex={() => setCurrentIndex(index)}
                        colorCode={(result as any).colorCode}
                        currentIndex={currentIndex}
                        index={index}
                      />
                    </li>
                  ))}
            </ul>
            <div className="rectangle-6" />
          </div>
          <div className="navtab-result-container">
            {engineType === GuardRailEngineType.FactChecker ? (
              <AssertionResultsList
                assertion={assertion?.assertions[currentIndex]}
                assertionCount={currentIndex + 1}
                showClipToProject={showClipToProject}
                createdAt={assertion?.createdAt}
                sourceType={assertion?.sourceType}
              />
            ) : (
              <ContradictionResultsList assertion={groupedResult[currentIndex].results} />
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-96"> No sources found </div>
      )}
      {enableOverlay && <div className="rectangle-17" />}
    </>
  );
};
