import { createSlice } from '@reduxjs/toolkit';
import { EXTERNAL_SEARCH_STORE_NAME, externalSearchActions } from '../actions/external-search.action';
import { PaginationConfig } from '../../types/models';
import {
  ExternalSearchSource,
  ExternalSearchSourceLight,
} from '../../common/services/external-search/models/external-search';
import { profileActions } from '../actions/profile.actions';
import { initialProfileState } from './profile.slice';

export interface ExternalSearchState {
  currentExternalSearch?: ExternalSearchSource | any;
  initialExternalSearch?: ExternalSearchSource | any;
  errorCode: string;
  isFetchingExternalSearch: boolean;
  isPostingExternalSearch: boolean;
  isEditingExternalSearch: boolean;
  isLoading: boolean;
  isValidated: boolean;
  isDeleteExternalSearch: boolean;
  isExternalSearchValidation: boolean;
  isCloningExternalSearch: boolean;
  externalSearchLightList: ExternalSearchSourceLight[];
  externalSearchPagination: PaginationConfig;
  externalSearchCount: number;
  layoutExternalAppMainMenu: boolean;
  showExternalSearchSourceList: boolean;
  refreshExternalSearchSourceList: boolean;
  isValidateExternalSearch: boolean;
}

function initialData() {
  return {
    name: '',
    id: '',
    requestItemDto: {
      apiUrl: '',
      httpMethod: 'Get',
      params: [{ key: '', value: '', isEncrypted: true }],
      headers: [{ key: '', value: '', isEncrypted: true }],
      body: [{ key: '', value: '', isEncrypted: false }],
    },
    childRequestItemDto: {
      apiUrl: '',
      httpMethod: 'Post',
      params: [{ key: '', value: '', isEncrypted: true }],
      headers: [{ key: '', value: '', isEncrypted: true }],
      body: [{ key: '', value: '', isEncrypted: false }],
    },
    responseItemDto: {
      rootData: ['root', 'data'],
      url: '',
      title: '',
      snippet: '',
    },
  };
}

const initialExternalSearchState: ExternalSearchState = {
  currentExternalSearch: initialData(),
  initialExternalSearch: initialData(),
  externalSearchLightList: [],
  externalSearchPagination: {
    page: 1,
    take: 10,
    itemCount: 10,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  isFetchingExternalSearch: false,
  isPostingExternalSearch: false,
  isEditingExternalSearch: false,
  isExternalSearchValidation: false,
  isCloningExternalSearch: false,
  isDeleteExternalSearch: false,
  isLoading: false,
  isValidated: false,
  layoutExternalAppMainMenu: false,
  showExternalSearchSourceList: false,
  refreshExternalSearchSourceList: false,
  isValidateExternalSearch: false,
  errorCode: '',
  externalSearchCount: 0,
};
export const ExternalSearchSlice = createSlice({
  name: EXTERNAL_SEARCH_STORE_NAME,
  initialState: initialExternalSearchState,
  reducers: {
    postExternalSearch: (state, action) => {
      state.isPostingExternalSearch = true;
      state.currentExternalSearch = initialExternalSearchState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(externalSearchActions.initialExternalSearch, (state, { payload }) => {
        state.initialExternalSearch = initialData();
      })
      .addCase(externalSearchActions.postExternalSearch, (state, { payload }) => {
        state.isPostingExternalSearch = false;
        state.isLoading = true;
        state.errorCode = '';
      })
      .addCase(externalSearchActions.postExternalSearchSuccess, (state, { payload }) => {
        state.currentExternalSearch = payload;

        state.isPostingExternalSearch = true;
        state.isLoading = false;
        state.errorCode = '';
        state.refreshExternalSearchSourceList = true;
      })
      .addCase(externalSearchActions.postExternalSearchFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.isPostingExternalSearch = false;
        state.isLoading = false;
      })

      .addCase(externalSearchActions.getExternalSearchById, (state, { payload }) => {
        // state.currentExternalSearch = payload;
        state.isPostingExternalSearch = true;
        state.isLoading = true;
      })
      .addCase(externalSearchActions.getExternalSearchByIdFailure, (state, { payload }) => {
        state.isFetchingExternalSearch = false;
      })
      .addCase(externalSearchActions.getExternalSearchByIdSuccess, (state, { payload }) => {
        state.currentExternalSearch = payload;
        state.errorCode = '';
        state.isFetchingExternalSearch = true;
        state.isLoading = false;
      })

      .addCase(externalSearchActions.getExternalSearchSourceLightList, (state, { payload }) => {
        state.isExternalSearchValidation = false;
        state.isLoading = true;
      })
      .addCase(externalSearchActions.getExternalSearchSourceLightListFailure, (state, { payload }) => {
        state.isExternalSearchValidation = false;
        state.isLoading = false;
      })
      .addCase(externalSearchActions.getExternalSearchSourceLightListSuccess, (state, { payload }) => {
        if (payload) {
          state.externalSearchCount = payload.data.length;
          state.externalSearchPagination = payload.meta;
          state.externalSearchLightList = payload.data;
        }

        // state.externalSearchLightList = payload;
        state.errorCode = '';
        state.isExternalSearchValidation = true;
        state.isLoading = false;
      })

      .addCase(externalSearchActions.updateExternalSearch, (state, { payload }) => {
        state.isEditingExternalSearch = false;
        state.isLoading = true;
        state.errorCode = '';
      })
      .addCase(externalSearchActions.updateExternalSearchSuccess, (state, { payload }) => {
        state.currentExternalSearch = payload;
        state.isEditingExternalSearch = true;
        state.isLoading = false;
        state.errorCode = '';
      })
      .addCase(externalSearchActions.updateExternalSearchFailure, (state, { payload }) => {
        state.isEditingExternalSearch = false;
        state.isLoading = false;
        state.errorCode = payload.message;
      })

      .addCase(externalSearchActions.postExternalSearchApiValidation, (state, { payload }) => {
        state.isValidateExternalSearch = true;
        state.errorCode = '';
      })
      .addCase(externalSearchActions.postExternalSearchApiValidationSuccess, (state, { payload }) => {
        state.isValidated = payload;
        state.isValidateExternalSearch = false;
        state.errorCode = '';
      })
      .addCase(externalSearchActions.postExternalSearchApiValidationFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.isValidateExternalSearch = false;
        state.isValidated = false;
      })
      .addCase(externalSearchActions.postExternalSearchApiCloning, (state, { payload }) => {
        state.isCloningExternalSearch = true;
        state.errorCode = '';
      })
      .addCase(externalSearchActions.postExternalSearchApiCloningSuccess, (state, { payload }) => {
        state.isCloningExternalSearch = false;
        state.currentExternalSearch = payload;
        state.refreshExternalSearchSourceList = true;
        state.isLoading = false;
        state.errorCode = '';
      })
      .addCase(externalSearchActions.postExternalSearchApiCloningFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.isLoading = false;
        state.isCloningExternalSearch = false;
      })
      .addCase(externalSearchActions.toggleExternalAppMenu, (state) => {
        state.layoutExternalAppMainMenu = !state.layoutExternalAppMainMenu;
      })
      .addCase(externalSearchActions.toggleExternalSearchSourceList, (state, { payload }) => {
        state.showExternalSearchSourceList = payload;
        state.layoutExternalAppMainMenu = false;
        state.isValidated = false;
      })
      .addCase(externalSearchActions.deleteExternalSearchApi, (state, { payload }) => {
        state.isDeleteExternalSearch = true;
        state.errorCode = '';
      })
      .addCase(externalSearchActions.deleteExternalSearchApiSuccess, (state, { payload }) => {
        state.isDeleteExternalSearch = false;
        state.refreshExternalSearchSourceList = true;
        state.isLoading = false;
        state.errorCode = '';
      })
      .addCase(externalSearchActions.deleteExternalSearchApiFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.isLoading = false;
        state.isDeleteExternalSearch = false;
      })
      .addCase(profileActions.logout, (state) => {
        Object.assign(state, initialExternalSearchState);
      });
  },
});

export const { postExternalSearch } = ExternalSearchSlice.actions;
export const externalSearchReducer = ExternalSearchSlice.reducer;
