import { FormEvent, useEffect, useState } from 'react';
import {
  ArticleIcon,
  AssessmentIcon,
  BillOfLaddingIcon,
  FavoriteIconPink,
  HorizantalElipsis,
  IconsSideNavClose,
  PlusIcon,
  ReferenceLinksIcon,
} from '../../common/icons/Icons';
import { PrimaryButton } from '../../components/PrimaryButton';
import './style.css';
import { showUserArticles } from '../../redux/selectors/layout.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from '../../redux/actions/layout.actions';
import { articleActions } from '../../redux/actions/article.actions';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FACT_CHECKER } from '../../common/constants/app-text';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';
import { AppState } from '../../redux/types/App.type';
import { dossierActions } from '../../redux/actions/dossier.actions';
import { AssertionResult, FavoriteArticles, PaginationConfig } from '../../types/models';
import {
  getCurrentActiveIdSelectorFromForEngine,
  getFavoriteCountSelectorFromForEngine,
  getPaginationConfigSelectorFromForEngine,
  getUserListSelectorFromForEngine,
} from '../../common/helpers/Helper';
import { PromptActions } from '../../redux/actions/prompt.actions';
import { contradictionActions } from '../../redux/actions/contradiction.actions';
import { DeleteModal } from '../../components/Modal/DeleteModal/DeleteModal';
import { federatedActions } from '../../redux/actions/federated.actions';
import { CodeAnalysisActions } from '../../redux/actions/codeAnalysis.actions';
import { SummarizationActions } from '../../redux/actions/summarization.actions';
import ConfirmationPopup from '../../components/Modal/ConfirmationPopup';
import { DossierOrgActions } from '../../redux/actions/dossier-org.actions';
import { DossierTypes } from '../../common/constants/default-values';

interface UserListProps {
  label?: string;
  seachHistoryList?: boolean;
  routerLink?: string;
  userFavorites?: FavoriteArticles[];
  favoriteArticlesCount?: number;
  currentActiveAssetionId?: string | number;
  engineType?: GuardRailEngineType;
  headerIcon?: JSX.Element;
  topMargin?: boolean;
}

const UserAssertionsList: React.FC<UserListProps> = ({
  engineType = GuardRailEngineType.FactChecker,
  headerIcon = <ArticleIcon />,
  label = FACT_CHECKER,
  routerLink = RoutePath.FactChecker,
  topMargin = true,
}) => {
  const toggleUserAticles = useSelector(showUserArticles);
  const paginationConfig = useSelector(getPaginationConfigSelectorFromForEngine(engineType));
  const [activeTab, setActiveTab] = useState('history');
  const dispatch = useDispatch();
  const [hasMounted, setHasMounted] = useState(false);
  const [showList, setShowList] = useState(true);
  const currentActiveAssetionId = useSelector((state: AppState) =>
    getCurrentActiveIdSelectorFromForEngine(engineType, state),
  );
  const navigate = useNavigate();
  const userFavorites = useSelector(getUserListSelectorFromForEngine(engineType));
  const favoriteArticlesCount = useSelector(getFavoriteCountSelectorFromForEngine(engineType));
  useEffect(() => {
    const isFavorite = activeTab !== 'history';

    switch (engineType) {
      case GuardRailEngineType.FactChecker:
        dispatch(articleActions.toggleFavoriteArticles(isFavorite));
        break;
      case GuardRailEngineType.Dossier:
        dispatch(dossierActions.toggleFavoriteDossiers(isFavorite));
        break;
      case GuardRailEngineType.PromptProtect:
        dispatch(PromptActions.toggleFavoritePrompts(isFavorite));
        break;
      case GuardRailEngineType.ContractionFinder:
        dispatch(contradictionActions.toggleFavoriteContradictions(isFavorite));
        break;
      case GuardRailEngineType.Federated:
        dispatch(federatedActions.toggleFavoritePrompt(isFavorite));
        break;
      case GuardRailEngineType.CodeAnalyzer:
        dispatch(CodeAnalysisActions.toggleFavoriteCodeAnalysis(isFavorite));
        break;
      case GuardRailEngineType.Summarization:
        dispatch(SummarizationActions.toggleFavoriteSummarizations(isFavorite));
        break;
    }
  }, [activeTab]);

  function resetContentForEngine() {
    switch (engineType) {
      case GuardRailEngineType.FactChecker:
        dispatch(articleActions.setPromptContent(''));
        break;
      case GuardRailEngineType.Dossier:
        dispatch(dossierActions.setDossierContent(''));
        break;
      case GuardRailEngineType.PromptProtect:
        dispatch(PromptActions.setPromptContent(''));
        break;
      case GuardRailEngineType.ContractionFinder:
        dispatch(contradictionActions.setPromptContent(''));
        break;
      case GuardRailEngineType.Federated:
        dispatch(federatedActions.setPromptContent(''));
        break;
      case GuardRailEngineType.CodeAnalyzer:
        dispatch(CodeAnalysisActions.setCodeAnalysisContent(''));
        break;
    }
  }

  useEffect(() => {
    if (hasMounted && !toggleUserAticles) {
      const timeoutId = setTimeout(() => {
        setShowList(false);
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      setHasMounted(true);
    }
  }, [toggleUserAticles, hasMounted]);

  useEffect(() => {
    if (toggleUserAticles) {
      setShowList(true);
    }
  }, [toggleUserAticles]);
  const handleIconClick = () => {
    dispatch(layoutActions.toggleUsersArticles(false));
  };
  return (
    <>
      {showList && (
        <div
          className={`flex-1 max-w-350 p-5 pt-8 user-favoritie-bg relative duration-100 ${
            topMargin ? 'mt-16' : ''
          } border-r-2 rounded-r-lg border-dark-purple border-opacity-20  z-10 transform animate__animated  ${
            toggleUserAticles ? 'animate__slideInLeft' : 'animate__slideOutLeft'
          } `}
        >
          <TitleWithActionIcon icon={headerIcon} handleIconClick={handleIconClick} label={label} />
          <div className="flex justify-between items-center w-full mt-3">
            <PrimaryButton
              className="mx-2 flex w-full text-center"
              size="medium"
              stateProp="rest"
              iconPosition="left"
              icon=<PlusIcon />
              enableFullWidth={true}
              style="boxed"
              text="New"
              onClick={() => {
                resetContentForEngine();
                navigate(routerLink);
              }}
            />
          </div>
          <div>
            <div className="flex border-b border-gray-300 justify-between">
              <button
                className={`inline-block p-4 border-b-2 text-white border-transparent rounded-t-lg hover:text-white-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                  activeTab === 'history' ? 'active-tab-border' : ''
                }`}
                onClick={() => setActiveTab('history')}
              >
                History
              </button>
              <button
                className={` inline-block p-4 border-b-2 text-white border-transparent rounded-t-lg hover:text-white-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                  activeTab === 'favorites' ? 'active-tab-border' : ''
                }`}
                onClick={() => setActiveTab('favorites')}
              >
                Favorites ({favoriteArticlesCount})
              </button>
            </div>
            <div>
              {activeTab === 'history' && (
                <InfiniteScrollComponent
                  engineType={engineType}
                  activeTab={activeTab}
                  paginationConfig={paginationConfig}
                  userFavorites={userFavorites}
                  currentActiveAssetionId={currentActiveAssetionId}
                  navigate={navigate}
                  routePath={routerLink}
                />
              )}
              {activeTab === 'favorites' && (
                <InfiniteScrollComponent
                  engineType={engineType}
                  activeTab={activeTab}
                  paginationConfig={paginationConfig}
                  userFavorites={userFavorites}
                  currentActiveAssetionId={currentActiveAssetionId}
                  navigate={navigate}
                  routePath={routerLink}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default UserAssertionsList;

interface TitleWithActionIconProps {
  handleIconClick: () => void;
  label: string;
  icon?: JSX.Element;
}
export const TitleWithActionIcon: React.FC<TitleWithActionIconProps> = ({
  handleIconClick,
  label,
  icon = <ArticleIcon />,
}) => {
  return (
    <div className="flex justify-between ">
      <div className="flex  items-center">
        <span onClick={handleIconClick}> {icon}</span>
        <span className="mx-2 text-white font-sans"> {label}</span>
      </div>
      <span onClick={handleIconClick}>
        <IconsSideNavClose />
      </span>
    </div>
  );
};

interface HistoryCollectionCardProps {
  collection: AssertionResult;
  engineType?: GuardRailEngineType;
}

export const HistoryCollectionCard: React.FC<HistoryCollectionCardProps> = ({
  collection,
  engineType = GuardRailEngineType.FactChecker,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const handleDelete = () => {
    switch (engineType) {
      case GuardRailEngineType.FactChecker:
        dispatch(articleActions.deleteArticle(collection.id));
        setIsDeleted(true);
        break;
      case GuardRailEngineType.Dossier:
        if (collection.tag === DossierTypes.Person) {
          dispatch(dossierActions.deleteDossier(collection.id));
        } else {
          dispatch(DossierOrgActions.deleteDossierOrg(collection.id));
        }
        setIsDeleted(true);
        break;
      case GuardRailEngineType.PromptProtect:
        dispatch(PromptActions.deletePrompt(collection.id));
        setIsDeleted(true);
        break;
      case GuardRailEngineType.ContractionFinder:
        dispatch(contradictionActions.deleteContradiction(collection.id));
        setIsDeleted(true);
        break;
      case GuardRailEngineType.Federated:
        dispatch(federatedActions.deletePrompt(collection.id));
        setIsDeleted(true);
        break;
      case GuardRailEngineType.CodeAnalyzer:
        dispatch(CodeAnalysisActions.deleteCodeAnalysis(collection.id));
        setIsDeleted(true);
        break;
      case GuardRailEngineType.Summarization:
        dispatch(SummarizationActions.deleteSummarization(collection.id));
        setIsDeleted(true);
        break;
      default:
        console.error('Unknown engine type:', engineType);
        break;
    }
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (isDeleted) {
      switch (engineType) {
        case GuardRailEngineType.FactChecker:
          navigate(RoutePath.FactChecker);
          break;
        case GuardRailEngineType.Dossier:
          navigate(RoutePath.Dossier);
          break;
        case GuardRailEngineType.PromptProtect:
          navigate(RoutePath.PromptProtect);
          break;
        case GuardRailEngineType.ContractionFinder:
          navigate(RoutePath.ContradictionFinder);
          break;
        case GuardRailEngineType.Federated:
          navigate(RoutePath.LLMResult);
          break;
        case GuardRailEngineType.CodeAnalyzer:
          navigate(RoutePath.CodeAnalyzer);
          break;
        case GuardRailEngineType.Summarization:
          navigate(RoutePath.Summarization);
          break;
      }
    }
  }, [isDeleted, navigate]);
  return (
    <div className="history-container w-full">
      <div className="container-header">
        <div className="items-center flex justify-between">
          <div className="min-w-85 flex items-center">
            <span className="result-time">{collection.time}</span>
            <span className="result-time">
              {collection.tag ? (
                <div key={collection.tag} className="tags-wrapper mx-2">
                  <span className="tag capitalize">{collection.tag}</span>
                </div>
              ) : null}
            </span>
            {collection.isFavorite ? <FavoriteIconPink /> : null}
          </div>
          <div className={`flex items-center gap-2 ${collection.isCompleted ? '' : 'w-full'}`}>
            {
              <>
                {collection.isCompleted ? null : (
                  <>
                    <div className="w-full h-p-4 rounded-full bg-black mx-2">
                      <div
                        className="h-full text-center text-xs text-white bg-gradient-to-r from-purple-500 to-blue-500 transition-all duration-100 rounded-full"
                        style={{ width: `${collection.percentage}%` }}
                      ></div>
                    </div>
                    <span className="mx-2">{collection.percentage}%</span>
                  </>
                )}
                {engineType === GuardRailEngineType.CodeAnalyzer && collection.extendProps && (
                  <>
                    {collection.extendProps.showRiskAssessment && <AssessmentIcon />}
                    {collection.extendProps.showBillOfLadding && <BillOfLaddingIcon />}
                    {collection.extendProps.showCodeReference && <ReferenceLinksIcon />}
                  </>
                )}
              </>
            }
            <div className="min-w-25">
              <HorizantalElipsis
                onDeleteClick={() => {
                  setShowDeleteModal(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-body my-2">
        <p className="line-clamp-2 text-white result-text">{collection.article}</p>
      </div>
      {showDeleteModal && (
        <DeleteModal
          engineType={engineType}
          isShown={showDeleteModal}
          onDialogClose={() => {
            setShowDeleteModal(false);
          }}
          onDeleteClick={() => {
            handleDelete();
          }}
        />
      )}
    </div>
  );
};

interface InfiniteScrollComponentProps {
  activeTab: string;
  paginationConfig: PaginationConfig; // replace 'any' with the actual type
  userFavorites: FavoriteArticles[]; // replace 'any' with the actual type
  currentActiveAssetionId: string | number; // replace with the actual type
  navigate: (path: string) => void;
  routePath?: string;
  engineType?: GuardRailEngineType;
}

export const InfiniteScrollComponent: React.FC<InfiniteScrollComponentProps> = ({
  paginationConfig,
  userFavorites,
  currentActiveAssetionId,
  navigate,
  routePath = RoutePath.FactChecker,
  engineType = GuardRailEngineType.FactChecker,
}) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    console.log('paginationConfig', userFavorites);
  }, [userFavorites]);

  function handleDelete() {
    switch (engineType) {
      case GuardRailEngineType.FactChecker:
        dispatch(articleActions.clearArticleHistory());
        navigate(RoutePath.FactChecker);
        break;
      case GuardRailEngineType.Dossier:
        dispatch(dossierActions.clearDossierHistory());
        dispatch(DossierOrgActions.clearDossierHistory());
        navigate(RoutePath.Dossier);
        break;
      case GuardRailEngineType.PromptProtect:
        dispatch(PromptActions.clearPromptHistory());
        navigate(RoutePath.PromptProtect);
        break;
      case GuardRailEngineType.ContractionFinder:
        dispatch(contradictionActions.clearContradictionHistory());
        navigate(RoutePath.ContradictionFinder);
        break;
      case GuardRailEngineType.Federated:
        dispatch(federatedActions.clearFederatedHistory());
        navigate(RoutePath.LLMResult);
        break;
      case GuardRailEngineType.CodeAnalyzer:
        dispatch(CodeAnalysisActions.clearCodeAnalysisHistory());
        navigate(RoutePath.CodeAnalyzer);
        break;
    }
  }
  return (
    <>
      <div className="result-max-height overflow-scroll app-scroll-bar">
        <InfiniteScroll
          dataLength={paginationConfig.itemCount}
          next={() => {
            switch (engineType) {
              case GuardRailEngineType.FactChecker:
                dispatch(articleActions.getUserArticlesList(paginationConfig.page + 1));
                break;
              case GuardRailEngineType.Dossier:
                dispatch(dossierActions.getUserDossiersList(paginationConfig.page + 1));
                break;
              case GuardRailEngineType.ContractionFinder:
                dispatch(contradictionActions.getUserContradictionsList(paginationConfig.page + 1));
                break;
              case GuardRailEngineType.PromptProtect:
                dispatch(PromptActions.getUserPromptsList(paginationConfig.page + 1));
                break;
              case GuardRailEngineType.Federated:
                dispatch(federatedActions.getUserPromptsList(paginationConfig.page + 1));
                break;
              case GuardRailEngineType.CodeAnalyzer:
                dispatch(CodeAnalysisActions.getUserCodeAnalysisList(paginationConfig.page + 1));
                break;
              case GuardRailEngineType.Summarization:
                dispatch(SummarizationActions.getSummarizationsList(paginationConfig.page + 1));
                break;
              default:
                dispatch(articleActions.getUserArticlesList(paginationConfig.page + 1));
                break;
            }
          }}
          hasMore={paginationConfig.hasNextPage}
          loader={
            userFavorites.length < paginationConfig.itemCount ? (
              <div className="text-center text-white w-full mt-2 text-sm">Loading more...</div>
            ) : (
              <div className="text-center text-white w-full mt-2 text-sm">No more records to load.</div>
            )
          }
          endMessage={<div className="text-center text-white w-full mt-2 text-sm">No more records to load.</div>}
        >
          {userFavorites.map((favorite, index) => (
            <ul key={`collection-` + index}>
              <p className="text-upper text-purple-lite text-upper my-2">{favorite.date}</p>
              {favorite.collections.map((collection, favoriteIndex) => (
                <li
                  onClick={() => {
                    if (collection.tag) {
                      navigate(`${routePath}/` + collection.tag + `/` + collection.id);
                    } else {
                      navigate(`${routePath}/` + collection.id);
                    }
                  }}
                  key={`article-request` + favoriteIndex + index}
                  className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-dark text-gray-300 text-sm items-center gap-x-4 ${
                    currentActiveAssetionId === collection.id && 'bg-light-dark'
                  } `}
                >
                  <HistoryCollectionCard engineType={engineType} collection={collection} />
                </li>
              ))}
            </ul>
          ))}
        </InfiniteScroll>
      </div>
      <div className="flex   px-6 py-2 absolute bottom-2 left-2 w-full justify-center cursor-pointer">
        {userFavorites.length > 0 && (
          <div
            onClick={() => {
              setShowDeleteModal(true);
            }}
            className="flex bg-purple-lite items-center px-6 py-3 bg-base-100 shadow-md rounded-full border border-gray-200"
          >
            <span className="text-sm text-white font-normal">Clear History</span>
          </div>
        )}
      </div>
      <ConfirmationPopup
        isOpen={showDeleteModal}
        title="Confirmation"
        message="Are you sure you want to proceed?"
        confirmText="Yes"
        cancelText="No"
        onConfirm={() => {
          setShowDeleteModal(false);
          handleDelete();
        }}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};
