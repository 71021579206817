import { ChangeEvent, ClipboardEvent, FC, KeyboardEvent, useRef, useState } from 'react';
import cn from 'classnames';
import { KeyboardKey } from '../../common/constants/enums/keyboard-key.enum';

interface PropTypes {
  type?: 'text' | 'number';
  id?: string;
  length: number;
  className?: string;
  placeholder?: string | string[];
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onComplete?: (value: string) => void;
}

const defaultProps: Partial<PropTypes> = {
  type: 'number',
  length: 1,
  placeholder: '',
  required: true,
  disabled: false,
  onChange: () => {},
  onComplete: () => {},
};

const BASE_CODE_INPUT_CLASSNAME =
  'code-input appearance-none w-16 h-16 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:text-xl text-center focus:outline-none focus:ring-primary-500 focus:border-primary-500';

const CodeInput: FC<PropTypes> = ({
  type,
  id,
  length,
  className,
  placeholder,
  required,
  disabled,
  onChange,
  onComplete,
}) => {
  const [codes, setCodes] = useState([...Array(length)].map(() => ''));
  const fields = useRef<(HTMLInputElement | null)[]>([]);

  const triggerChange = (values = codes) => {
    const code = values.join('').trim();

    setCodes(values);

    onChange!(code);

    if (code.length === length) {
      onComplete!(code);
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const pastedString = event.clipboardData?.getData('text');

    if (!pastedString) return;

    const newCodes = [...codes];

    const splitedString = pastedString.split('').slice(0, length);

    splitedString.forEach((item, index) => {
      newCodes[index] = item;
    });

    triggerChange(newCodes);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const index = parseInt((event.target as any).dataset.id);
    const value = event.target.value;

    if (event.target.value === '' || (type === 'number' && !event.target.validity.valid)) {
      return;
    }

    const newCodes = [...codes];
    newCodes[index] = value;
    triggerChange(newCodes);

    const nextField = fields.current[index + 1];

    if (nextField) {
      nextField.focus();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const index = parseInt((event.target as any).dataset.id);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prevField = fields.current[prevIndex];
    const nextField = fields.current[nextIndex];

    if (event.key === KeyboardKey.Backspace || event.key === KeyboardKey.Delete) {
      const newCodes = [...codes];
      if (codes[index]) {
        newCodes[index] = '';
      } else if (prevField) {
        newCodes[prevIndex] = '';
      }

      triggerChange(newCodes);
      prevField?.focus();
    }

    if (event.key === KeyboardKey.ArrowLeft || event.key === KeyboardKey.ArrowUp) {
      prevField?.focus();
    }

    if (event.key === KeyboardKey.ArrowRight || event.key === KeyboardKey.ArrowDown) {
      nextField?.focus();
    }
  };

  return (
    <div className={cn(className, 'flex gap-2 justify-evenly ')}>
      {codes.map((code, index) => (
        <input
          key={index}
          id={id && `${id}-${index}`}
          type={type === 'number' ? 'tel' : type}
          pattern={type === 'number' ? '[0-9]*' : undefined}
          ref={(ref) => fields.current.push(ref)}
          className={BASE_CODE_INPUT_CLASSNAME}
          data-id={index}
          value={code}
          required={required}
          disabled={disabled}
          maxLength={1}
          autoComplete="off"
          placeholder={Array.isArray(placeholder) ? placeholder[index] : placeholder}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
        />
      ))}
    </div>
  );
};

CodeInput.defaultProps = defaultProps;

export default CodeInput;
