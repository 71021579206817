import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { RoutePath } from '../../common/constants/route-path';
import { SummarizationService } from '../../common/services/summarization/summarization.service';
import { setNotification } from '../actions/notification.actions';
import { SummarizationActions } from '../actions/summarization.actions';
import { browserHistory } from '../helpers/history/history';
import { mapSummarizationToUserHistoryList } from '../../common/helpers/Helper';
import {
  DossierUpdateRequest,
  ProcessSummaryRequest,
  ResultListLite,
  SummarizationLite,
  SummaryComparisonInput,
  SummaryComparisonResponse,
  UserHistoryList,
} from '../../types/models';

export function* getSummaries({ payload }: PayloadAction<number>) {
  try {
    var userSummaries: ResultListLite<SummarizationLite> = yield call(
      SummarizationService.getSummarizationList,
      payload,
      10,
    );
    const mappedData: ResultListLite<UserHistoryList> = {
      ...userSummaries,
      data: userSummaries.data.map(mapSummarizationToUserHistoryList),
    };
    yield put(SummarizationActions.getSummarizationsListSuccess(mappedData));
  } catch (err: any) {
    yield put(SummarizationActions.getSummarizationsListFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* processSummarization({ payload }: PayloadAction<ProcessSummaryRequest>) {
  try {
    let data = {
      extracted_text: payload.extracted_text,
      reference_summary: payload.reference_summary,
      mode_of_summary: payload.mode_of_summary,
    };
    const processSummarization: SummarizationLite = yield call(SummarizationService.processSummarization, data);
    yield put(
      SummarizationActions.processSummarizationSuccess(mapSummarizationToUserHistoryList(processSummarization)),
    );
  } catch (err: any) {
    yield put(SummarizationActions.processSummarizationFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* getSummarizationById({ payload }: PayloadAction<string>) {
  try {
    const summarizationResult: SummarizationLite = yield call(SummarizationService.getSummarizationById, payload);
    if (summarizationResult) {
      yield put(SummarizationActions.getSummarizationByIdSuccess(summarizationResult));
    } else {
      yield put(SummarizationActions.getSummarizationByIdFailure({ message: 'Prompt Not Found', statusCode: 404 }));
    }
  } catch (err: any) {
    yield put(SummarizationActions.getSummarizationByIdFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to fetch summary details', type: 'error' }));
    browserHistory.push(RoutePath.Landing);
  }
}

export function* setSummarizationFavorite({ payload }: PayloadAction<DossierUpdateRequest>) {
  try {
    const Summarization: SummarizationLite = yield call(SummarizationService.updateSummarization, payload);
    yield put(SummarizationActions.setSummarizationFavoriteSuccess(Summarization));
    let message = payload.isFavorite ? 'Summarization  marked as favorite' : 'Summarization removed from favorite';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(
      SummarizationActions.setSummarizationFavoriteFailure({ message: err.message, statusCode: err.statusCode }),
    );
  } finally {
  }
}

export function* deleteSummarization({ payload }: PayloadAction<string>) {
  try {
    yield call(SummarizationService.deleteSummarization, payload);
    yield put(SummarizationActions.deleteSummarizationSuccess(payload));
    yield put(setNotification({ title: 'Summarization deleted successfully', type: 'success' }));
  } catch (err: any) {
    yield put(SummarizationActions.deleteSummarizationFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to delete Summarization', type: 'error' }));
  }
}

export function* clearSummaryHistory() {
  try {
    const result: boolean = yield call(SummarizationService.clearHistory);
    if (result) {
      yield put(SummarizationActions.clearSummaryHistorySuccess());
      yield put(setNotification({ title: 'Summary History Cleared', type: 'success' }));
    } else {
      yield put(SummarizationActions.clearSummaryHistoryFailure());
      yield put(setNotification({ title: 'Unable to clear Summary history', type: 'error' }));
    }
  } catch (err: any) {
    yield put(SummarizationActions.clearSummaryHistoryFailure());
  }
}

export function* compareSummaries({ payload }: PayloadAction<SummaryComparisonInput>) {
  try {
    const ComparisonResponse: SummaryComparisonResponse = yield call(
      SummarizationService.compareSummaries,
      payload.summaries,
    );
    yield put(SummarizationActions.compareSummariesSuccess(ComparisonResponse));
  } catch (err: any) {
    yield put(SummarizationActions.compareSummariesFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export const PROCESS_Summarization_SAGA = 'Process Summarization Saga';

export const processDossierOrgSaga = createAction<string>(PROCESS_Summarization_SAGA);

export default function* watcher() {
  yield takeLatest(SummarizationActions.processSummarization.type, processSummarization);
  yield takeEvery(SummarizationActions.getSummarizationsList.type, getSummaries);
  yield takeEvery(SummarizationActions.getSummarizationById.type, getSummarizationById);
  yield takeLatest(SummarizationActions.setSummarizationFavorite.type, setSummarizationFavorite);
  yield takeLatest(SummarizationActions.deleteSummarization.type, deleteSummarization);
  yield takeLatest(SummarizationActions.compareSummaries.type, compareSummaries);
}
