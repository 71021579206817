import { Table } from 'flowbite-react';
import Badge from '../Base/Badge';
import { AppleIcon, InfoIcon, LinuxIcon, WindownsIcon } from '../../common/icons/Icons';
import { CodeMirrorInput } from './CodeMirrorInput';
import { IconTabSwitch } from '../IconTabSwitch/IconTabSwitch';
import { useEffect, useState } from 'react';
import { BillOfLaddingResult } from '../../types/models';
import { useSelector } from 'react-redux';
import { codeBillOfLaddingResult } from '../../redux/selectors/codeAnalysis.selectors';
export enum SystemOSTypes {
  Linux = 0,
  Mac = 1,
  Windows = 2,
}

interface BillOfLadingResultViewProps {
  code?: string;
  setCode?: (code: string) => void;
}
export const BillOfLadingResultView: React.FC<BillOfLadingResultViewProps> = ({ code, setCode}) => {
 
  let [activeVersionIndex, setActiveVersionIndex] = useState(0);
  let [currentCode, setCurrentCode] = useState('');
  let billOfLaddingResultView = useSelector(codeBillOfLaddingResult);
  useEffect(() => {
    if(!billOfLaddingResultView) return;
    let code = '';
    switch (activeVersionIndex) {
      case SystemOSTypes.Linux:
        code = billOfLaddingResultView.version_command.mac;
        break;
      case SystemOSTypes.Mac:
        code = billOfLaddingResultView.version_command.mac;
        break;
      case SystemOSTypes.Windows:
        code = billOfLaddingResultView.version_command.win;
        break;
    }
    setCurrentCode(code);
  }, [activeVersionIndex, billOfLaddingResultView]);

   
  return ( 
    billOfLaddingResultView ?
   <>
      <Table className='animate__animated animate__fadeInUp '>
        <Table.Body className="divide-y">
          <Table.Row
            className="border border-b-2"
            style={{
              borderBottom: '2px solid #E1E4EC',
            }}
          >
            <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">Data Type</Table.Cell>
            <Table.Cell>
              <div className="flex items-center flex-row">
                {billOfLaddingResultView?.bill_of_ladding?.datatype?.length ? (
                  <span> {billOfLaddingResultView.bill_of_ladding.datatype} </span>
                ) : (
                  <span>None</span>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row
            className="border border-b-2"
            style={{
              borderBottom: '2px solid #E1E4EC',
            }}
          >
            <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">Language</Table.Cell>
            <Table.Cell>
              <div className="flex items-center flex-row">
                {billOfLaddingResultView?.bill_of_ladding?.languages?.length ? (
                    billOfLaddingResultView?.bill_of_ladding?.languages?.map((api, index) => {
                                        return <Badge key={index} backgroundColor="#FAFBFD" text={api} />;
                      })
                ) : (
                  <span> None </span>
                )}
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row
            className="border border-b-2"
            style={{
              borderBottom: '2px solid #E1E4EC',
            }}
          >
            <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">Databases</Table.Cell>
            <Table.Cell>
              <div className="flex items-center flex-row">
              {billOfLaddingResultView?.bill_of_ladding?.databases?.length ? (
                    billOfLaddingResultView?.bill_of_ladding?.databases?.map((api, index) => {
                                        return <Badge key={index} backgroundColor="#FAFBFD" text={api} />;
                      })
                ) : (
                  <span> None </span>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row
            className="border border-b-2"
            style={{
              borderBottom: '2px solid #E1E4EC',
            }}
          >
            <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">Custom APIs</Table.Cell>
            <Table.Cell>
              <div className="flex items-center flex-row">
              {billOfLaddingResultView.bill_of_ladding?.custom_apis?.length ? (
                    billOfLaddingResultView.bill_of_ladding.custom_apis.map((api, index) => {
                                        return <Badge key={index} backgroundColor="#FAFBFD" text={api} />;
                      })
                ) : (
                  <span> None </span>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row
            className="border border-b-2"
            style={{
              borderBottom: '2px solid #E1E4EC',
            }}
          >
            <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">Libraries</Table.Cell>
            <Table.Cell>
              <div className="flex items-center flex-row">
              {billOfLaddingResultView.bill_of_ladding?.libraries?.length ? (
                    billOfLaddingResultView.bill_of_ladding.libraries.map((api, index) => {
                                        return <Badge key={index} backgroundColor="#FAFBFD" text={api} />;
                      })
                ) : (
                  <span> None </span>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row
            className="border border-b-2"
            style={{
              borderBottom: '2px solid #E1E4EC',
            }}
          >
            <Table.Cell className="whitespace-nowrap font-bold text-gray-500 dark:text-white">
              Third party APIs
            </Table.Cell>
            <Table.Cell>
              <div className="flex items-center flex-row">
                {billOfLaddingResultView.bill_of_ladding?.third_party_apis?.length ? (
                  billOfLaddingResultView.bill_of_ladding.third_party_apis.map((api, index) => {
                    return <Badge key={index} backgroundColor="#FAFBFD" text={api} />;
                  })
                ) : (
                  <span> None </span>
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <></>
      <div>
        <div className="flex justify-between flex-row my-3 font-[Open_sans]">
          <p className="text-gray-500 text-sm">Version Command</p>
          <div>
            <div>
              <IconTabSwitch
                onChange={(index) => setActiveVersionIndex(index)}
                activeIndex={activeVersionIndex}
                icons={[
                  <AppleIcon color={activeVersionIndex === 0 ? '#fff' : '#0065FF'} />,
                  <WindownsIcon color={activeVersionIndex === 1 ? '#fff' : '#0065FF'} />,
                  <LinuxIcon color={activeVersionIndex === 2 ? '#fff' : '#0065FF'} />,
                ]}
              />
            </div>
          </div>
        </div>
        <div>
          {currentCode && currentCode.length && <CodeMirrorInput hideTitle={true} maxHeight="100px" code={currentCode} />}
        </div>
      </div>
    </> :<></>
  );
};
