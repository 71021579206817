import React, { useEffect, useState } from 'react';
import { Tags } from '../../../components/Tags/Tags';
import { PatternDto } from '../../../types/models';

interface Props {
  data: PatternDto[];
}
const PatternsListTable: React.FC<Props> = ({
  data =[],
}) => {
  const [searchText, setSearchText] = useState('');
  const [filteredPatterns, setFilteredPatterns] = useState(data);
  useEffect(() => {
    setFilteredPatterns(data);
  }, [data]);
  const handleSearch = (text: string) => {
    if (text.length >= 3) {
      setFilteredPatterns(
        data.filter((permission) => (permission.label.toLowerCase().includes(text.toLowerCase()) || permission.pattern.toLowerCase().includes(text.toLowerCase()) || permission.description.toLowerCase().includes(text.toLowerCase()))),
      );
    } else {
      setFilteredPatterns(data);
    }
  };

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

//   const handleCheck = (permissionName: string) => {
//     if (selectedPermissions.includes(permissionName)) {
//       setSelectedPermissions(selectedPermissions.filter((permission) => permission !== permissionName));
//     } else {
//       setSelectedPermissions([...selectedPermissions, permissionName]);
//     }
//   };

  return (
    <>
      <div className="bg-white rounded-lg  mx-auto p-4 animate__animated animate__fadeInUp min-w-[700px]">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
            <div>
              <label htmlFor="table-search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for patterns"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>
            </div>
            <div>
              {/* {isUpdateMode && (
                <PrimaryButton
                  className="mx-2 bg-gradient-to-tl from-blue-500 to-violet-500"
                  size="small"
                  stateProp="rest"
                  style="boxed"
                  text="Update Permissions"
                  onClick={() => {
                    onUpdatePermissions?.(selectedPermissions);
                  }}
                />
              )} */}
            </div>
          </div>
          <table className="w-full text-sm w-[750px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {/* {isUpdateMode && (
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label htmlFor="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                )} */}
                <th scope="col" className="px-6 py-3 uppercase">
                  PATTERN LABEL
                </th>
                <th scope="col" className="px-6 py-3 uppercase">
                  Regular expressions
                </th>
                <th scope="col" className="px-6 py-3 uppercase">
                 Description
                </th>
                {/* <th scope="col" className="px-6 py-3">
                   Actions
                </th> */}
              </tr>
            </thead>
            <tbody>
              {filteredPatterns.map((pattern, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  {/* {isUpdateMode && (
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id={`checkbox-table-search-${index}`}
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          checked={selectedPermissions.includes(permission.permissionName)}
                          onChange={() => handleCheck(permission.permissionName)}
                        />
                        <label htmlFor={`checkbox-table-search-${index}`} className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                  )} */}
                  <th scope="row" className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" style={{ maxWidth: '300px', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {pattern.label}
                  </th>
                  <td className="px-6 py-4 ">
                    <Tags  className='max-w-[700px]' tags={pattern?.pattern?.split(",").map(tag => tag.trim().toLowerCase())
                      
                    } />
                  </td>

                 <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" style={{ maxWidth: '300px', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
  {pattern.description}
</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PatternsListTable;
