import { IS_RETRY_HEADER, RequestInterceptor, ResponseInterceptor } from './http-client';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { TokenService } from '../token/token.service';
import { ErrorResponse } from './models/error-response';
import { hideLoader, showLoader, toggleLoader } from '../loader-provider/loaderObservable';
import StoreProviderService from '../store-provider/store-provider.service';
import { profileActions } from '../../../redux/actions/profile.actions';
// function setRetryFlag(config: AxiosRequestConfig) {
//   config.headers[IS_RETRY_HEADER] = true;
//   return config;
// }
function hasRetryFlag(config?: AxiosRequestConfig) {
  return config?.headers?.[IS_RETRY_HEADER] || false;
}

const isRequestBypassedByRequestInterceptor = (config: AxiosRequestConfig) => {
  return !!(
    config.url?.startsWith('/login') ||
    (config.url?.startsWith('/account/password_reset') && config.url !== '/account/password_reset/change')
  );
};

export const requestInterceptor: RequestInterceptor = {

  onFulfilled: (config) => {
    
    config.url = config.url?.replace(
      /(https?:\/\/[^\/]+)\/+(.*)/g,
      (match, protocolAndDomain, path) => protocolAndDomain + '/' + path.replace(/\/+/g, '/'),
    );
    if (isRequestBypassedByRequestInterceptor(config)) return config;
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${TokenService.accessToken}`;
    } else {
      config.headers = { Authorization: `Bearer ${TokenService.accessToken}` };
    }
    const showLoader = (config as any).showLoader !== undefined ? (config as any).showLoader : true;
    // Toggle the loader based on the showLoader option
    toggleLoader(showLoader, '');
    return config;
  },

  onRejected: (error) => {
    hideLoader();
    return error
  },
};

export const responseInterceptor: ResponseInterceptor = {
  onFulfilled: (response) => {
    hideLoader();
    return response},
  onRejected: (error: AxiosError<ErrorResponse>) => {
    hideLoader();
    if (error.response?.status === 401) {
        StoreProviderService.dispatch(profileActions.sessionExpired());
      // TODO: implement logic once frontend wil receive refresh token
      // return HttpClient.fromConfig(setRetryFlag(error.config));
    }

    // if (error.response?.status === 403) {
    //   StoreProviderService.dispatch(profileActions.logout());
    // }

    const data: ErrorResponse | undefined = error.response?.data;

    return Promise.reject({ name: data?.error, message: data?.message, statusCode: data?.statusCode });
  },
};
