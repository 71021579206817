import React from 'react';

interface Props {
  text: string;
  onClick?: () => void;
}

export const CenterLabel: React.FC<Props> = ({ text, onClick }) => {
  return (
    <div className="mt-4 flex items-center justify-center text-gray-400 font-sansInter gap-10 text-lg">
      <button onClick={onClick} className="ml-1">
        {text}
      </button>
    </div>
  );
};
