import { all, call, delay, put, race, takeLatest } from 'redux-saga/effects';
import { passwordRecoveryActions } from '../actions/password-recovery.actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { PASSWORD_RECOVERY_CODE_VERIFICATION_FAIL_MESSAGE } from '../../common/constants/error-messages';
import { EmailValidateRequest } from '../../common/password-recover/models/email-validate-request';
import { PasswordResetRequest } from '../../common/password-recover/models/password-reset-request';
import { PasswordRecoverService } from '../../common/password-recover/password-recover.service';
import { profileActions } from '../actions/profile.actions';
import { layoutActions } from '../actions/layout.actions';
import { browserHistory } from '../helpers/history/history';
import { RoutePath } from '../../common/constants/route-path';
import { useNavigate, useNavigation } from 'react-router-dom';
import { setNotification } from '../actions/notification.actions';
import { ChangePasswordRequest } from '../../common/password-recover/models/change-password-request';

export function* setPasswordRecoveryRequest({ payload }: PayloadAction<string>) {
  try {
    yield call(PasswordRecoverService.sendRecoverRequest, payload);
    yield put(passwordRecoveryActions.setPasswordRecoveryRequestSuccess());
    yield put(profileActions.showOTPVerificationCode(true));
    yield put(setNotification({ title: 'OTP sent to ' + payload, type: 'success' }));
    yield put(passwordRecoveryActions.showNextStage());
  } catch (error) {
    yield put(passwordRecoveryActions.setPasswordRecoveryRequestFail(error));
    yield put(setNotification({ title: 'Unable to send OTP to ' + payload, type: 'error' }));
    yield put(passwordRecoveryActions.showErrorStage());
  }
}

export function* resendPasswordRecoveryRequest({ payload }: PayloadAction<string>) {
  try {
    yield call(PasswordRecoverService.sendRecoverRequest, payload);
    yield put(passwordRecoveryActions.resendPasswordRecoveryRequestSuccess());
    yield put(setNotification({ title: 'OTP sent to ' + payload, type: 'success' }));
  } catch (error) {
    yield put(passwordRecoveryActions.resendPasswordRecoveryRequestFail(error));
    yield put(setNotification({ title: 'Unable to send OTP to ' + payload, type: 'error' }));
    yield put(passwordRecoveryActions.showErrorStage());
  }
}

export function* setEmailValidate({ payload }: PayloadAction<EmailValidateRequest>) {
  try {
    const { response } = yield race({
      response: call(PasswordRecoverService.setEmailValidate, payload),
      timeout: delay(2000),
    });

    if (response) {
      yield put(passwordRecoveryActions.setEmailValidateSuccess(response));
      yield put(setNotification({ title: 'OTP validated', type: 'success' }));
      if (payload.isGuestAccount) {
        yield put(profileActions.guestLogin({ email: payload.email }));
      }
      yield put(layoutActions.setShowSignUpModal(false));
    } else {
      yield put(passwordRecoveryActions.setEmailValidateFail(PASSWORD_RECOVERY_CODE_VERIFICATION_FAIL_MESSAGE));
    }
  } catch (error: any) {
    yield put(passwordRecoveryActions.setEmailValidateFail(PASSWORD_RECOVERY_CODE_VERIFICATION_FAIL_MESSAGE));
    yield put(passwordRecoveryActions.showErrorStage());
  }
}

export function* resetPassword({ payload }: PayloadAction<PasswordResetRequest>) {
  try {
    yield call(PasswordRecoverService.resetPassword, payload);
    yield put(passwordRecoveryActions.resetPasswordSuccess());
    yield put(setNotification({ title: 'Password reset successfully', type: 'success' }));
    yield put(passwordRecoveryActions.showNextStage());
  } catch (error) {
    yield put(passwordRecoveryActions.resetPasswordFail(error));
    yield put(setNotification({ title: 'Unable to reset password', type: 'error' }));
    yield put(passwordRecoveryActions.showErrorStage());
  }
}

export function* changePassword({ payload }: PayloadAction<ChangePasswordRequest>) {
  try {
    yield call(PasswordRecoverService.changePassword, payload);
    yield put(setNotification({ title: 'Password changed successfully', type: 'success' }));
    yield put(passwordRecoveryActions.changePasswordSuccess());
  } catch (error) {
    yield put(passwordRecoveryActions.changePasswordFail(error));
    yield put(setNotification({ title: 'Unable to change password', type: 'error' }));
  }
}

export default function* watcher() {
  yield takeLatest(passwordRecoveryActions.setPasswordRecoveryRequest.type, setPasswordRecoveryRequest);
  yield takeLatest(passwordRecoveryActions.setEmailValidate.type, setEmailValidate);
  yield takeLatest(passwordRecoveryActions.resetPassword.type, resetPassword);
  yield takeLatest(passwordRecoveryActions.resendPasswordRecoveryRequest, resendPasswordRecoveryRequest);
  yield takeLatest(passwordRecoveryActions.changePassword.type, changePassword);
}
