import React from 'react';
import { DynamicListViewWithTitle } from './DynamicListViewWithTitle';

interface DynamicListLayoutProps {
  items: any[];
}

export const DynamicListLayout: React.FC<DynamicListLayoutProps> = ({ items }) => {
  return (
    <div className="max-w-screen-xl px-4 py-4 sm:px-6 sm:py-4 lg:px-4 lg:py-4">
      <div
        className="
         grid grid-cols-1
         gap-4
         sm:grid-cols-2 sm:items-stretch
         md:grid-cols-3 md:gap-8
         "
      >
        {items.length > 0 ? (
          items.map((item: any, index: number) => (
            <DynamicListViewWithTitle key={index} title={item.title} data={item.data.filter((e: any) => e?.length)} />
          ))
        ) : (
          <div className="text-center">No data to display</div>
        )}
      </div>
    </div>
  );
};
