import { useContext, useState } from 'react';
import { CurrentDateTime } from '../../common/helpers/Helper';
import { ArticleUpdateRequest, AssertionResult, PromptProtect, PromptsInfoResponseDto } from '../../types/models';
import HighlightedParagraph from '../HighlightedParagraph/HighlightedParagraph';
import './style.css';
import { PrimaryButton } from '../PrimaryButton';
import {
  ArrowDown,
  EditIcon,
  FavoriteIcon,
  FavoriteIconPink,
  ShareIcon,
  GeneratePDFIcon,
} from '../../common/icons/Icons';
import { useDispatch } from 'react-redux';
import { articleActions } from '../../redux/actions/article.actions';
import { PlugInResultActions } from '../PlugInResultActions/PlugInResultActions';
import { EDIT_TEXT, FAVORITE_TEXT, SHARE_TEXT, Generate_PDF } from '../../common/constants/app-text';
import { Tags } from '../Tags/Tags';
import HighlightSubstrings from '../HighlightedParagraph/HighlightedSubstring';
import { CardWithTitle } from '../../views/Engines/DossierOrganization';
import { PromptActions } from '../../redux/actions/prompt.actions';
import { AuthContext } from '../../config/authorization/AuthContext';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { DOSSIER_GENERATOR_FAVORITES, PROMPT_PROTECT_FAVORITES } from '../../config/authorization/PermissionsConstants';
interface PromptProtectResultViewProps {
  data: PromptProtect;
  disableHideInput?: boolean;
}

export const PromptProtectResultView = ({
  data,
  disableHideInput = false,
}: PromptProtectResultViewProps): JSX.Element => {
  let subWords = data.promptsInfoResponseDto?.flatMap((x) => x.profanityCheckResponseDto ?? []).map((e) => e.word);

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const handleEditText = () => {
    dispatch(PromptActions.setPromptContent(data.prompt));
    navigate(RoutePath.PromptProtect);
  };
  return (
    <div className="group-6 ">
      <div className="overlap-3">
        <div className="flex items-center justify-between">
          <div className="flex space-x-4 items-center">
            {data.isFavorite ? <FavoriteIcon /> : null}
            <div className="text-wrapper-25">Input</div>
          </div>

          <div className="flex space-x-4">
            <p className="support-text">{CurrentDateTime(data?.createdAt)}</p>
          </div>
        </div>
        <div className={` collapsible-section my-5 ${isOpen ? 'open' : ''}`}>
          <HighlightSubstrings paragraph={data.prompt} substrings={subWords} />
        </div>

        <div className="flex items-center justify-between mt-10 ">
          <div className="flex space-x-4">
            <PlugInResultActions
              actionItems={[
                {
                  icon: <EditIcon />,
                  text: EDIT_TEXT,
                  isVisible: true,
                  onClick: handleEditText,
                },
                // {
                //   icon: <GeneratePDFIcon />,
                //   text: Generate_PDF ,
                //   onClick: () => {},
                // },
                {
                  icon: data?.isFavorite ? <FavoriteIcon /> : <FavoriteIconPink />,
                  text: FAVORITE_TEXT,
                  isVisible: authContext?.checkPermission([PROMPT_PROTECT_FAVORITES]),
                  textClass: data?.isFavorite ? `text-primary` : 'text-wrapper-27 ',
                  onClick: () => {
                    let updateArticle = {
                      isFavorite: !data?.isFavorite,
                      id: data?.id,
                      progressPercentage: data?.progressPercentage,
                    } as ArticleUpdateRequest;
                    dispatch(PromptActions.setPromptFavorite(updateArticle));
                  },
                },
              ]}
            />
          </div>
          <div className="flex space-x-4">
            {isOpen ? (
              <div
                className="input-action-item"
                onClick={() => {
                  if (!disableHideInput) {
                    setIsOpen(false);
                  }
                }}
              >
                <div className="text-wrapper-27">Hide Input</div>
                <div className="text-wrapper-26"></div>
              </div>
            ) : (
              <PrimaryButton
                onClick={() => setIsOpen(true)}
                className=""
                size="small"
                stateProp="rest"
                style="boxed"
                icon={<ArrowDown />}
                iconPosition="right"
                text="Show Input"
              />
            )}
          </div>
        </div>
        <div className="rectangle-16 mb-2" />
        {true ? (
          <>
            <div>
              <p className="text-purple-800  font-semibold mx-2 mt-2">Profanity Words Identified</p>
              <Tags className="my-2" tags={subWords} />
            </div>

            <div className="container mx-auto  py-8">
              <h2 className="text-purple-800  font-semibold mx-2 mt-2">Details of Sentense and the Profanity Words</h2>
              {data.promptsInfoResponseDto?.map((x, index) => (
                <div className="my-2">
                  <div className="rectangle-16 mb-2" />
                  <CardWithTable title={`Sentence ${index + 1}`} data={x} />
                </div>
              ))}
            </div>
          </>
        ) : (
          <p className="text-purple-800  font-semibold mx-2 mt-2">No Profanity Words Identified</p>
        )}
      </div>
    </div>
  );
};

interface TwoColumnCardsWithTitleDescriptionProps {
  title: string;
  data: PromptsInfoResponseDto;
}

export const CardWithTable: React.FC<TwoColumnCardsWithTitleDescriptionProps> = ({ title, data }): JSX.Element => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-1 gap-4 animate__animated animate__fadeInUp">
      <CardWithTitleAndTable key={title} description={data.sentence} tableInfo={data} title={title} />
      {/* <div>
    <div className="flex flex-col shadow-md rounded-lg p-4 mb-2 animate__animated animate__fadeInUp">
  <div className="-m-1.5 overflow-x-auto">
    <div className=" min-w-full inline-block align-middle">
      <div className="overflow-hidden">
       
      </div>
    </div>
  </div>
</div>
    </div> */}
    </div>
  );
};

interface CardWithTitleAndTableState {
  title?: string;
  tableInfo: PromptsInfoResponseDto;
  description?: string;
}
export const CardWithTitleAndTable: React.FC<CardWithTitleAndTableState> = ({
  title,
  description,
  tableInfo,
}): JSX.Element => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-2 animate__animated animate__fadeInUp">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-purple-500 font-semibold">{title}</h2>
          <p className="text-gray-900  text-sm  my-2">{description}</p>
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead>
              <tr>
                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">
                  Word
                </th>
                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">
                  Index
                </th>
                <th scope="col" className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {tableInfo.profanityCheckResponseDto.map((row, index) => (
                <>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                      {row.word}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                      {row.index}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                      <button
                        type="button"
                        className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      >
                        Search
                      </button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
