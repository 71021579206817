import PropTypes from 'prop-types';
import { Icons, MainMenuIcon } from '../../common/icons/Icons';
import { Logo } from '../Logo';
import { PrimaryButton } from '../PrimaryButton';

import './style.css';
import { SingInGroup } from '../SignInGroup/SignInGroup';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInUser } from '../../redux/selectors/activeUser.selectors';
import logo4 from '../../static/img/logo-4.svg';
import {
  selectShowGuestModal,
  selectShowSignInModal,
  selectShowSignUpModal,
} from '../../redux/selectors/layout.selectors';
import SignUpPage from '../Modal/SignUpModal/SignUpModal';
import SignInPage from '../Modal/SignInModal/SignInModal';
import { GuestSignIn } from '../Modal/GuestSignInModal/GuestSignInModal';
import { userDetails } from '../../redux/selectors/profile.selectors';
import { LoggedInProfileAvatar } from '../Avatar/LoggedInProfileAvatar';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { layoutActions } from '../../redux/actions/layout.actions';
import { MainButton } from '../MainButton';
interface Props {
  state?: 'homescreen' | 'not-logged-in' | 'logged-in'; //maintain  based on user state
  className?: any;
}

export const Header = ({ state = 'not-logged-in', className = 'header-instance' }: Props): JSX.Element => {
  const isLoggedIn = useSelector(isLoggedInUser);
  const showLogin = useSelector(selectShowSignInModal);
  const showSignUp = useSelector(selectShowSignUpModal);
  const showGuestSignIn = useSelector(selectShowGuestModal);
  const currentLoggedInUser = useSelector(userDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className={`bg-white header flex items-center justify-between sticky top-0 p-4 ${state} ${className} `}>
        <div className="flex items-center justify-between">
          {isLoggedIn && (
            <span
              className="cursor-pointer"
              onClick={() => {
                dispatch(layoutActions.toggleLayoutMenu());
              }}
            >
              <MainMenuIcon />
            </span>
          )}
          <Logo className="logo-instance flex space-x-4" logo={logo4} />
        </div>
        <div className="flex space-x-4 flex-row-reverse">
          {/* {!isLoggedIn &&  <><SingInGroup/> 
         <PrimaryButton
            className=""
            size="small"
            stateProp="rest"
            style="label"
            text="Privacy Policy"
          />
          <PrimaryButton className="" size="small" stateProp="rest" style="label" text="Terms of Use" /></>}
          */}
        </div>
        {isLoggedIn && (
          <>
            <div className="flex space-x-4 gap-4 flex-row-reverse">
              <div>
                <div className="profile-thumbnail">
                  <div className="text-sm font-sans text-gray-600 mr-5 flex flex-col text-center">
                    <span> {`${currentLoggedInUser.firstName} ${currentLoggedInUser.lastName}`}</span>
                    <div>
                      <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                        {currentLoggedInUser.planName ?? 'Free'}
                      </span>
                    </div>
                  </div>
                  <LoggedInProfileAvatar
                    firstName={currentLoggedInUser.firstName}
                    lastName={currentLoggedInUser.lastName}
                  />
                </div>
              </div>
              {!currentLoggedInUser.isSubscribed && (
                <MainButton
                  className="w-100 px-8 rounded-none rounded-tl-3xl rounded-br-3xl"
                  stateProp="rest"
                  text="Upgrade Now"
                  onClick={() => {
                    navigate(RoutePath.Plans);
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
      {/* <SignInPage
        isShown={showLogin}
      />
     <SignUpPage
        isShown={showSignUp}
      />
      <GuestSignIn
        isShown={showGuestSignIn}/> */}
    </>
  );
};

Header.propTypes = {
  state: PropTypes.oneOf(['homescreen', 'not-logged-in', 'logged-in']),
  logoLogo: PropTypes.string,
};
