import React from 'react';
import Input from '../Input';
import DynamicRow from './DynamicRow';
import { RequestItem } from '../../common/services/external-search/models/external-search';

interface Props {
  requestItem: RequestItem;
  onChange: (field: string, value: string | { key: string; value: string; isEncrypted: boolean }[]) => void;
  errors?: { [key: string]: string };
}

const RequestItemFields: React.FC<Props> = ({ requestItem, onChange, errors }) => {
  return (
    <div className="bg-slate-50 p-2 mt-1 mr-4 rounded-lg border border-highlight-lightgreen w-full">
      <label>Request Item:</label>

      <div className="pt-4 my-1 ">
        <label htmlFor="Url" className="mb-1 block text-xs text-gray-600 font-sansInte">
          Api Url:
        </label>
        <Input
          id="apiUrl"
          type="text"
          size="sm"
          placeholder="apiUrl"
          value={requestItem.apiUrl == undefined ? '' : requestItem.apiUrl}
          error={!!errors?.apiUrl}
          onChange={(e) => onChange('apiUrl', e.target.value)}
        />
        <input id="id" type="hidden" />
        {errors && errors.apiUrl && <div className="error">{errors.apiUrl}</div>}
      </div>
      <DynamicRow elements={requestItem.params} elementName="params" onChange={onChange} errors={errors} />
      <DynamicRow
        elements={requestItem.headers == undefined ? [{ key: '', value: '', isEncrypted: false }] : requestItem.headers}
        elementName="headers"
        onChange={onChange}
        errors={errors}
      />
      <DynamicRow
        elements={requestItem.body == undefined ? [{ key: '', value: '', isEncrypted: false }] : requestItem.body}
        elementName="body"
        onChange={onChange}
        errors={errors}
      />
      {/* Display dynamic params for requestItemDto */}
      {/* <div className='flex flex-col space-y-1  py-4'>
        <label>Params:</label>
        {requestItem.params.map((param, index) => (
          <div key={index} className='flex flex-row justify-stretch'>
            <Input
              type='text'
              size='sm'
              value={param.key}
              placeholder='Key'
              onChange={(e) => handleParamChange(index, 'key', e.target.value)}
            />
            <Input
              type='text'
              size='sm'
              value={param.value}
              placeholder='Value'
              onChange={(e) =>
                handleParamChange(index, 'value', e.target.value)
              }
            />
            <button type='button' onClick={() => handleRemoveParam(index)}>
              Remove
            </button>
          </div>
        ))}
        <button
          type='button'
          onClick={handleAddParam}
          className='self-end mt-3 mr-100'
        >
          Add
        </button>
      </div> */}

      {/* ... (add more fields for headers, body) */}
    </div>
  );
};

export default RequestItemFields;
