import { ActionItem } from '../ActionItem/ActionItem';
import { CrossIcon } from '../../common/icons/CrossIcon';
import { Tabs } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { currentArticleSelector } from '../../redux/selectors/projects.selectors';
import AppLoader from '../AppLoader/AppLoader';
import { AssertionResultItemProps } from '../../common/models/Props/props';
import React, { useEffect, useState } from 'react';
import { extractBaseUrl, extractMainDomain, getFormatedDate, iconComponents } from '../../common/helpers/Helper';
import { DotsMenuIcon, FactCheckerIcon, InfoIcon } from '../../common/icons/Icons';
import { Divider } from '../Divider/Divider';
import { DeleteModal } from '../Modal/DeleteModal/DeleteModal';
import { GuardRailEngineType, SourceType } from '../../common/constants/enums/engine-types.enum';
import { ProjectsActions } from '../../redux/actions/projects.actions';
import { Assertion, ProjectAssertionDto, Result } from '../../types/models';
import LLMModels from '../../common/constants/llm-models';

const AssertionResultItem: React.FC<AssertionResultItemProps> = React.memo(({ result }: AssertionResultItemProps) => {
  const mainDomain = extractMainDomain(result.source);
  return (
    <>
      <div className="flex items-start justify-between">
        <div>
          <div className="flex items-center p-2">
            <div className="text-wrapper-12 mr-2">
              <a
                href={`/engine/dossier-generator?SearchText=${mainDomain}&&Type=Organization`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FactCheckerIcon />
              </a>
            </div>
            <div className="div-2">
              <span className="text-wrapper-13">{mainDomain}</span>
              <div className=" text-gray-400 text-xs">
                {extractBaseUrl(result.source)} {'- '}
                {getFormatedDate(new Date())}
              </div>
            </div>
          </div>

          <div className="frame-8 flex items-center">
            <div className="min-w-5 mr-1">
              <InfoIcon />
            </div>
            <p className="text-wrapper-16 !line-clamp-1">
              <a target="blank" href={result.source}>
                {result.excerpt}
              </a>
            </p>
          </div>
          <div className="align-top">
            <p className="text-wrapper-18 !line-clamp-1">“{result.excerpt}”</p>
          </div>
        </div>

        <div className="frame-10">
          <div className="text-wrapper-19">{result.score.toFixed(2)}</div>
        </div>
      </div>
      <Divider />
    </>
  );
});

const HighlightSentence = ({ paragraph, sentenceToHighlight }: any) => {
  // Split the paragraph into sentences using regex
  const sentences = paragraph.match(/[^.!?]+[.!?]+[\])'"`’”]*|.+/g);

  // Map through the sentences and wrap the target sentence in a <span>
  const highlightedParagraph = sentences.map((sentence: string, index: number) => {
    if (sentenceToHighlight.trim().includes(sentence.trim())) {
      return (
        <span key={index} className="bg-[#f6f9ec] text-black">
          {sentence}
        </span>
      );
    }
    return sentence;
  });

  return <p className="text-sm text-neutral-400 mb-2">{highlightedParagraph}</p>;
};

const ProjectsRightMenu = ({ setIsOpen, project, selectedItem, setSelectedItem }: any) => {
  const dispatch = useDispatch();
  const ArticleDetails = useSelector(currentArticleSelector);
  const AssertionDetails = ArticleDetails?.assertions?.find((e: Assertion) => e.id === selectedItem);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const currentAssertion = project.assertions.find((e: ProjectAssertionDto) => e.assertionId === selectedItem);
  const [source, setSource] = useState<any>({});

  useEffect(() => {
    setSource(LLMModels.find((item) => item.sourceType === currentAssertion?.model));
  }, [currentAssertion?.model]);

  const handleDelete = () => {
    setShowDeleteModal(false);
    setSelectedItem('');
    setIsOpen(false);
    dispatch(
      ProjectsActions.updateProject({
        id: String(project.id),
        pinToTop: false,
        assertions: project.assertions.filter((e: ProjectAssertionDto) => e.assertionId !== selectedItem),
      }),
    );
  };

  return (
    <>
      {ArticleDetails ? (
        <div className="ease-in min-w-[400px] w-400  mx-8">
          <div className="flex items-center justify-between mt-4">
            <span className=" text-lg">Assertion</span>
            <ActionItem
              icon={<CrossIcon />}
              text={'Close'}
              isVisible={true}
              isPrefix={true}
              textClass={`text-wrapper-27`}
              onClick={() => {
                setIsOpen(false);
                setSelectedItem('');
              }}
            />
          </div>
          <div className="flex justify-end">
            {showDeleteButton && (
              <div className=" bg-white border rounded-md shadow-lg">
                <div
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setShowDeleteButton(false);
                  }}
                  style={{ color: 'var(--purple-600)' }}
                >
                  Remove from project
                </div>
              </div>
            )}
          </div>
          <div className={`flex items-start justify-between mb-10 ${!showDeleteButton && 'mt-10'}`}>
            <p className="text-sm text-neutral-500">{AssertionDetails?.articleName}</p>
            <div
              className="input-action-item pt-4 pl-4 pr-4 pb-4 flex-shrink-0"
              onClick={() => {
                setShowDeleteButton(!showDeleteButton);
              }}
            >
              <div className="text-wrapper-27 flex items-center">
                <DotsMenuIcon />
              </div>
            </div>
          </div>
          <Tabs
            theme={{
              tablist: {
                tabitem: {
                  base: 'flex justify-center items-center border border-b-0 p-2.5 text-sm font-medium first:ml-0 focus:ring-0 focus:outline-none focus:border-b-0 rounded-t-lg border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 border-gray-200 ',
                },
              },
            }}
            color="white"
            className="border-b border-gray-300 "
          >
            <Tabs.Item title="Information">
              <div className="flex justify-between">
                <div>
                  <h2 className="mb-2">Original Prompt</h2>
                </div>
                <div>
                  <div className="flex items-center">
                    <div className="mr-2">{iconComponents[currentAssertion?.model as SourceType]}</div>
                    <p className="text-sm text-neutral-400">{source?.displayName}</p>
                  </div>
                  <p className="text-xs text-neutral-400 ml-6 mr-2">
                    {new Date(ArticleDetails?.updatedAt).toLocaleString('en-US', {
                      year: 'numeric',
                      day: 'numeric',
                      month: 'short',
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </p>
                </div>
              </div>
              <Divider />
              <h2 className="mb-2">LLM Output</h2>
              <HighlightSentence
                paragraph={ArticleDetails?.article}
                sentenceToHighlight={AssertionDetails?.articleName}
              />
            </Tabs.Item>
            <Tabs.Item title="Sources">
              {AssertionDetails?.topRanks?.map((result: Result) => (
                <AssertionResultItem key={result.id} result={result} />
              ))}
            </Tabs.Item>
          </Tabs>
          {showDeleteModal && (
            <DeleteModal
              engineType={GuardRailEngineType.ProjectAssertion}
              isShown={showDeleteModal}
              onDialogClose={() => {
                setShowDeleteModal(false);
              }}
              onDeleteClick={handleDelete}
            />
          )}
        </div>
      ) : (
        <AppLoader />
      )}
    </>
  );
};

export default ProjectsRightMenu;
