import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction, createAction } from '@reduxjs/toolkit';

import { setNotification } from '../actions/notification.actions';
import { browserHistory } from '../helpers/history/history';
import { RoutePath } from '../../common/constants/route-path';
import { ExternalSearchService } from '../../common/services/external-search/external-search.service';
import { externalSearchActions } from '../actions/external-search.action';
import {
  ExternalSearchSource,
  ExternalSearchSourceLightList,
} from '../../common/services/external-search/models/external-search';

// export function* initialSaga() {

//   return {
//     name: 'sampleSource',
//     id: '',
//     requestItemDto: {
//       apiUrl: 'http://sampleSource.io',
//       httpMethod: 'Get',
//       params: [{ key: '', value: '', isEncrypted: true }],
//       headers: [{ key: '', value: '', isEncrypted: true }],
//       body: [{ key: '', value: '', isEncrypted: false }],
//     },
//     childRequestItemDto: {
//       apiUrl: 'http://sampleSource.child.io',
//       httpMethod: 'Post',
//       params: [{ key: '', value: '', isEncrypted: true }],
//       headers: [{ key: '', value: '', isEncrypted: true }],
//       body: [{ key: '', value: '', isEncrypted: false }],
//     },
//     responseItemDto: {
//       rootData: ['root', 'data'],
//       url: 'http://url',
//       title: '',
//       snippet: '',
//     },
//   };
// }

export function* PostExternalSearch({ payload }: PayloadAction<ExternalSearchSource>) {
  try {
    const externalSearchResult: ExternalSearchSource = yield call(ExternalSearchService.postExternalSearch, payload);
    yield put(
      setNotification({
        title: 'External Source added successfully',
        type: 'success',
      }),
    );
    yield put(externalSearchActions.postExternalSearchSuccess(externalSearchResult));
    let message = externalSearchResult ? 'External Source created successfully' : 'unable to create External Source';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(
      externalSearchActions.postExternalSearchFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
    yield put(
      setNotification({
        title: 'Unable to create External Source secret key',
        type: 'error',
      }),
    );
  }
}

export function* updateExternalSearch({ payload }: PayloadAction<ExternalSearchSource>) {
  try {
    // yield call(ExternalSearchService.updateExternalSearch, payload);
    const updateExternalSearchResult: ExternalSearchSource = yield call(
      ExternalSearchService.updateExternalSearch,
      payload,
      payload.id,
    );
    // yield put(
    //   setNotification({
    //     title: 'External Source  updated successfully',
    //     type: 'success',
    //   })
    // );
    yield put(externalSearchActions.updateExternalSearchSuccess(updateExternalSearchResult));
    let message = updateExternalSearchResult.name
      ? 'External Source  updated successfully'
      : 'unable to update External Source  details';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(
      externalSearchActions.updateExternalSearchFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
    yield put(
      setNotification({
        title: 'unable to update External Source  details',
        type: 'error',
      }),
    );
  }
}

export function* getExternalSearchById({ payload }: PayloadAction<string>) {
  try {
    const externalSearchInfo: ExternalSearchSource = yield call(ExternalSearchService.getExternalSearchById, payload);
    if (externalSearchInfo) {
      yield put(externalSearchActions.getExternalSearchByIdSuccess(externalSearchInfo));
    } else {
      yield put(
        externalSearchActions.getExternalSearchByIdFailure({
          message: 'ExternalSearch Not Found',
          statusCode: 404,
        }),
      );
    }
  } catch (err: any) {
    yield put(
      externalSearchActions.getExternalSearchByIdFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
    yield put(
      setNotification({
        title: 'Unable to fetch ExternalSearch details',
        type: 'error',
      }),
    );
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export function* getExternalSearchSourceLightList({ payload }: PayloadAction<number>) {
  try {
    const externalSearchInfo: ExternalSearchSourceLightList = yield call(
      ExternalSearchService.getExternalSearchSourceLightList,
      payload,
      10,
    );
    if (externalSearchInfo) {
      yield put(externalSearchActions.getExternalSearchSourceLightListSuccess(externalSearchInfo));
    } else {
      yield put(
        externalSearchActions.getExternalSearchSourceLightListFailure({
          message: 'ExternalSearch Not Found',
          statusCode: 404,
        }),
      );
    }
  } catch (err: any) {
    yield put(
      externalSearchActions.getExternalSearchSourceLightListFailure({
        message: err.message,
        statusCode: err.statusCode,
      }),
    );
    yield put(
      setNotification({
        title: 'Unable to fetch ExternalSearch details',
        type: 'error',
      }),
    );
  } finally {
  }
}

export function* postExternalSearchApiValidation({ payload }: PayloadAction<string>) {
  try {
    const externalSearchInfo: boolean = yield call(ExternalSearchService.postExternalSearchApiValidation, payload);
    if (externalSearchInfo) {
      yield put(externalSearchActions.postExternalSearchApiValidationSuccess(externalSearchInfo));
      let message = payload
        ? `External Search Source validation successfully ${payload}`
        : 'External Search Source validation successfully';
      yield put(setNotification({ title: message, type: 'success' }));
    } else {
      yield put(
        externalSearchActions.postExternalSearchApiValidationFailure({
          message: 'Invalid configuration, validation failed',
          statusCode: 404,
        }),
      );
      let message = payload
        ? `External Search Source validation failed successfully ${payload}`
        : 'External Search Source validation failed successfully';
      yield put(setNotification({ title: message, type: 'success' }));
    }
  } catch (err: any) {
    // yield put(
    //   externalSearchActions.postExternalSearchApiValidationFailure({
    //     message: err.message,
    //     statusCode: err.statusCode,
    //   })
    // );
    yield put(externalSearchActions.postExternalSearchApiValidationSuccess(false));
    yield put(
      setNotification({
        title: 'Invalid configuration, validation failed',
        type: 'error',
      }),
    );
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export function* postExternalSearchApiCloning({ payload }: PayloadAction<string>) {
  try {
    const externalSearchInfo: ExternalSearchSource = yield call(
      ExternalSearchService.postExternalSearchApiCloning,
      payload,
    );
    if (externalSearchInfo) {
      yield put(externalSearchActions.postExternalSearchApiCloningSuccess(externalSearchInfo));
      let message = payload
        ? `External Search Source cloned successfully ${payload}`
        : 'External Search Source cloned successfully';
      yield put(setNotification({ title: message, type: 'success' }));
    } else {
      yield put(
        externalSearchActions.postExternalSearchApiCloningFailure({
          message: 'Cloning failed',
          statusCode: 404,
        }),
      );
    }
  } catch (err: any) {
    yield put(
      setNotification({
        title: 'Cloning failed',
        type: 'error',
      }),
    );
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export function* deleteExternalSearchApi({ payload }: PayloadAction<string>) {
  try {
    yield call(ExternalSearchService.deleteExternalSearchApi, payload);
    yield put(externalSearchActions.deleteExternalSearchApiSuccess());
    let message = payload
      ? `External Search Source deleted successfully ${payload}`
      : 'External Search Source deleted successfully';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(
      externalSearchActions.deleteExternalSearchApiFailure({
        message: 'delete failed',
        statusCode: 404,
      }),
    );
    yield put(
      setNotification({
        title: 'delete failed',
        type: 'error',
      }),
    );
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export const PROCESS_EXTERNAL_SEARCH_SAGA = 'Process External Search Saga';
export const processExternalSearchSaga = createAction<string>(PROCESS_EXTERNAL_SEARCH_SAGA);

export default function* watcher() {
  // yield takeLatest(externalSearchActions.initialize.type, initialSaga);
  yield takeLatest(externalSearchActions.postExternalSearch.type, PostExternalSearch);
  yield takeLatest(externalSearchActions.postExternalSearchApiValidation.type, postExternalSearchApiValidation);
  yield takeLatest(externalSearchActions.postExternalSearchApiCloning.type, postExternalSearchApiCloning);
  yield takeEvery(externalSearchActions.getExternalSearchById.type, getExternalSearchById);
  yield takeLatest(externalSearchActions.updateExternalSearch.type, updateExternalSearch);
  yield takeEvery(externalSearchActions.getExternalSearchSourceLightList.type, getExternalSearchSourceLightList);
  yield takeEvery(externalSearchActions.deleteExternalSearchApi.type, deleteExternalSearchApi);
}
