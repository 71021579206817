import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Userpreferencesservice } from '../../common/services/user-preferences/user-preference-service';
import { UserPreferenceRequest } from '../../common/services/user-preferences/models/user-preference-request';
import { userPreferencesActions } from '../actions/user-preference.actions';
import { setNotification } from '../actions/notification.actions';

function* getUserPreferences() {
  try {
    const preferences: UserPreferenceRequest = yield call(Userpreferencesservice.getUserPreferenceList);
    yield put(userPreferencesActions.getUserPreferencesSuccess(preferences));
  } catch (error) {
    console.error('Error getting user preferences:', error);
    yield put(userPreferencesActions.getUserPreferencesFailure(error));
  }
}
function* updateUserPreferences({ payload }: PayloadAction<UserPreferenceRequest>) {
  try {
    const updatedPreferences: UserPreferenceRequest = yield call(
      Userpreferencesservice.CreateorUpdateUserPreference,
      payload,
    );
    yield put(userPreferencesActions.updateUserPreferencesSuccess(updatedPreferences));
    yield put(setNotification({ title: 'Profile updated successfully', type: 'success' }));
  } catch (error) {
    console.error('Error updating user preferences:', error);
    yield put(userPreferencesActions.updateUserPreferencesFailure(error));
  }
}

function* userPreferencesWatcher() {
  yield takeLatest(userPreferencesActions.getUserPreferences.type, getUserPreferences);
  yield takeLatest(userPreferencesActions.updateUserPreferences.type, updateUserPreferences);
}

export default function* rootSaga() {
  yield all([userPreferencesWatcher()]);
}
