interface BadgeProps {
  backgroundColor: string;
  text: string;
}
const Badge: React.FC<BadgeProps> = ({ backgroundColor, text }) => {
  return (
    <div className="flex items-center space-x-4 mt-3 mx-2">
      <div className="p-wrapper" style={{ backgroundColor }}>
        <p className="p whitespace-pre-wrap break-words">{text}</p>
      </div>
    </div>
  );
};
export default Badge;
