import {
  AssertionResult,
  CreateProjectRequest,
  GetProjectsRequest,
  HttpErrorResponse,
  ProjectUpdateRequest,
  ProjectsLite,
  ResultListLite,
} from '../../types/models';
import { getCreatePayloadActionFromStore } from '../helpers/create-action';

export const PROJECTS_STORE_NAME = 'Projects Store';

const createPayloadAction = getCreatePayloadActionFromStore(PROJECTS_STORE_NAME);

// Create new project
const createProject = createPayloadAction<CreateProjectRequest>('Create Project');
const createProjectSuccess = createPayloadAction<ProjectsLite>('Create Project Success');
const createProjectFailure = createPayloadAction<HttpErrorResponse>('Create Project Failure');

// Update
const updateProject = createPayloadAction<ProjectUpdateRequest>('Update Project');
const updateProjectSuccess = createPayloadAction<ProjectsLite>('Update Project Success');
const updateProjectFailure = createPayloadAction<HttpErrorResponse>('Update Project Failure');

// List of Projects
const getProjectsList = createPayloadAction<GetProjectsRequest>('Get User Projects List');
const getProjectsListSuccess = createPayloadAction<ResultListLite<ProjectsLite>>('Get User Projects List Success');
const getProjectsListFailure = createPayloadAction<HttpErrorResponse>('Get User Projects List Failure');

// Pinned Projects
const getPinnedProjects = createPayloadAction<GetProjectsRequest>('Get Pinned Projects');
const getPinnedProjectsSuccess = createPayloadAction<ResultListLite<ProjectsLite>>('Get Pinned Projects Success');
const getPinnedProjectsFailure = createPayloadAction<HttpErrorResponse>('Get Pinned Projects Failure');

// Get Project by ID
const getProjectById = createPayloadAction<string>('Get Project By Id');
const getProjectByIdSuccess = createPayloadAction<ProjectsLite>('Get Project By Id Success');
const getProjectByIdFailure = createPayloadAction<HttpErrorResponse>('Get Project By Id Failure');

// Get Article for Project by ID
const getArticleById = createPayloadAction<string>('Get Article for Project By Id');
const getArticleByIdSuccess = createPayloadAction<AssertionResult>('Get Article for Project By Id Success');
const getArticleByIdFailure = createPayloadAction<HttpErrorResponse>('Get Article for Project By Id Failure');

// Delete Project
const deleteProject = createPayloadAction<string>('Delete Project');
const deleteProjectSuccess = createPayloadAction<string>('Delete Project Success');
const deleteProjectFailure = createPayloadAction<HttpErrorResponse>('Delete Project Failure');

export const ProjectsActions = {
  createProject,
  createProjectSuccess,
  createProjectFailure,
  updateProject,
  updateProjectSuccess,
  updateProjectFailure,
  getProjectsList,
  getProjectsListSuccess,
  getProjectsListFailure,
  getPinnedProjects,
  getPinnedProjectsSuccess,
  getPinnedProjectsFailure,
  getProjectById,
  getProjectByIdSuccess,
  getProjectByIdFailure,
  getArticleById,
  getArticleByIdSuccess,
  getArticleByIdFailure,
  deleteProject,
  deleteProjectSuccess,
  deleteProjectFailure,
};
