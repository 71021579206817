import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  DashboardMatericsRequestByDateRange,
  ModuleMetrics,
} from '../../common/services/dashboard/models/dashboard.model';
import { DashboardService } from '../../common/services/dashboard/dashboard.service';
import { dashboardActions } from '../actions/dashboard.actions';
import { ArticleService } from '../../common/services/article/article.service';
import { ContradictionService } from '../../common/services/article/contradiction.service';
import { DossierService } from '../../common/services/dossier/dossier.service';
import { DossierOrganizationService } from '../../common/services/dossier/organization/dossier-org.service';
import { PromptProtectService } from '../../common/services/prompt/prompt.service';
import { useContext } from 'react';
import { ActionTypes, AuthContext } from '../../config/authorization/AuthContext';
import { CONTRADICTION_FINDER_CREATE, DOSSIER_GENERATOR_CREATE, FACT_CHECKER_PROCESS, PROMPT_PROTECT_CREATE, PROMPT_PROTECT_SERVICE } from '../../config/authorization/PermissionsConstants';

function* getDashboardMetrics(request: PayloadAction<DashboardMatericsRequestByDateRange>) {
  const services:any[] = [];
  const serviceMapping:any = {
    [ActionTypes.ACCESS_FACT_CHECK_SERVICE]: [ArticleService],
    [ActionTypes.ACCESS_CONTRADICTION_FINDER]: [ContradictionService],
    [ActionTypes.ACCESS_DOSSIER_GENERATOR]: [DossierService, DossierOrganizationService],
    [ActionTypes.ACCESS_PROMOT_PROTECT_SERVICE]: [PromptProtectService],
  };
  
  request.payload.userPermissions.forEach(permission => {
    if (serviceMapping[permission]) {
      services.push(...serviceMapping[permission]);
    }
  });
  
  try {
    const metrics: ModuleMetrics[] = yield all(
      services.map((service) => call(service.getForDashboard, request.payload)),
    );

    yield put(dashboardActions.getDashboardMetricsSuccess(metrics));
  } catch (error) {
    console.error('Error getting dashboard metrics', error);
    yield put(dashboardActions.getDashboardMetricsSuccess([]));
    yield put(dashboardActions.getDashboardMetricsFailure(error));
  }
}

function* dashboardMetricsWatcher() {
  yield takeLatest(dashboardActions.getDashboardMetrics.type, getDashboardMetrics);
}

export default function* rootSaga() {
  yield all([dashboardMetricsWatcher()]);
}
