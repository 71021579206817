import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import MainPage from '../../components/MainPage/MainPage';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import { MainMenu } from '../../layouts/Sidenav/MainMenu';

export const Gateway123View = (): JSX.Element => {
  return (
    <MainPage>
      <Header />
      <div className="flex items-stretch h-[90vh] overflow-auto">
        <ContentLayout>
          <MainMenu isPushMenu={false} />
        </ContentLayout>
      </div>
      <Footer />
    </MainPage>
  );
};
