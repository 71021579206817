import { useEffect, useState } from 'react';
import { Divider } from '../../components/Divider/Divider';
import CheckboxWithLabel from '../../components/Base/CheckBoxWithLabel';
import { MainButton } from '../../components/MainButton';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { SummarizationActions } from '../../redux/actions/summarization.actions';
import { useDispatch, useSelector } from 'react-redux';
import { currentSummerizationInputSelector } from '../../redux/selectors/summarization.selectors';
import {
  languageModelsOptions,
  languageModels,
  ILanguageModelOption,
  stepperSteps,
} from '../../common/constants/summarization';
import { InfoIcon } from '../../common/icons/Icons';
import HorizontalStepper from '../../components/Stepper/Stepper';
import { DeSelectIcon, ArrowDown } from '../../common/icons/Icons';
import { PrimaryButton } from '../../components/PrimaryButton';

export const SummarizationMethods: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const currentSummerizationInput = useSelector(currentSummerizationInputSelector);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (model: string) => {
    const selectedIndex = selectedItems.indexOf(model);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, model]);
    } else {
      const updatedItems = [...selectedItems];
      updatedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedItems);
    }
  };

  const handleSelectAll = () => {
    const allModels = languageModelsOptions
      .filter((category: ILanguageModelOption) => category.items)
      .flatMap((category: ILanguageModelOption) => {
        if (category.items) {
          return category.items.map((item: any) => item.value);
        }
        return [];
      });
    setSelectedItems(allModels);
    setSelectAll(true);
  };
  const handleDeselectAll = () => {
    setSelectedItems([]);
    setSelectAll(false);
  };

  const handleClick = () => {
    dispatch(
      SummarizationActions.processSummarization({ ...currentSummerizationInput, mode_of_summary: selectedItems }),
    );
    navigate(RoutePath.SummarizationProcess);
  };

  return (
    <div className="flex justify-center my-10">
      <div className="bg-white w-4/5">
        <HorizontalStepper steps={stepperSteps} completedStep={2} />
        <div className="flex justify-start my-2">
          {selectAll ? (
            <div
              className="input-action-item"
              onClick={() => {
                handleDeselectAll();
              }}
            >
              <div className="text-sm text-secondary-pink">Deselect All</div>
              <div className="text-wrapper-26">
                <DeSelectIcon />
              </div>
            </div>
          ) : (
            <PrimaryButton
              onClick={() => handleSelectAll()}
              className=""
              size="small"
              stateProp="rest"
              style="boxed"
              icon={<ArrowDown />}
              iconPosition="right"
              text="Select All"
            />
          )}
        </div>
        {languageModelsOptions?.map((category: ILanguageModelOption) => (
          <div key={category.name}>
            <p className="text-sm text-gray-600 font-semibold my-2">{category.name}</p>
            {category?.items?.map((item) => (
              <div key={item.value}>
                <CheckboxWithLabel
                  isChecked={selectedItems.includes(item.value)}
                  label={languageModels[item.value]}
                  showInfoIcon={false}
                  onCheckboxChange={() => handleCheckboxChange(item.value)}
                  description={item.description}
                />
                <Divider />
              </div>
            ))}
          </div>
        ))}

        <div className={`w-100 flex ${selectedItems.length <= 0 ? 'justify-between' : 'justify-end'}`}>
          {selectedItems.length <= 0 && (
            <div className=" bg-sky-100 flex justify-center items-center px-4 py-2 rounded-lg text-gray-500 mr-8">
              <span className=" shrink-0">
                <InfoIcon color="#62C1E5" />
              </span>
              <p className="ml-2 text-sm">Select and configure at least one summarization model to continue</p>
            </div>
          )}
          <MainButton onClick={handleClick} className="" stateProp="rest" text="Process Outputs" />
        </div>
      </div>
    </div>
  );
};

export default SummarizationMethods;
