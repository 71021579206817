import {
  ExternalSearchSource,
  ExternalSearchSourceLightList,
} from '../../common/services/external-search/models/external-search';
import { HttpErrorResponse } from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const EXTERNAL_SEARCH_STORE_NAME = 'ExternalSearch Store';

const createPayloadAction = getCreatePayloadActionFromStore(EXTERNAL_SEARCH_STORE_NAME);
const createAction = getCreateActionFromStore(EXTERNAL_SEARCH_STORE_NAME);

const initialize = createAction('initialize');

const getExternalSearchById = createPayloadAction<string>('Get ExternalSearch  By Id');
const getExternalSearchByIdSuccess = createPayloadAction<ExternalSearchSource>('Get ExternalSearch By Id Success');
const getExternalSearchByIdFailure = createPayloadAction<HttpErrorResponse>('Get ExternalSearch By Id Failure');

const getExternalSearchSourceLightList = createPayloadAction<number>('Get Paginated External Search Sources');
const getExternalSearchSourceLightListSuccess = createPayloadAction<ExternalSearchSourceLightList>(
  'Get Paginated External Search Sources Success',
);
const getExternalSearchSourceLightListFailure = createPayloadAction<HttpErrorResponse>(
  'Get Paginated External Search Sources Failure',
);

const initialExternalSearch = createPayloadAction<ExternalSearchSource>('initial External Search Source');

const postExternalSearch = createPayloadAction<ExternalSearchSource>('External Search Source');

// const postExternalSearchEnabled = createPayloadAction<boolean>(
//   'On-boarded ExternalSearch'
// );
const postExternalSearchSuccess = createPayloadAction<ExternalSearchSource>('On-boarding Success');
const postExternalSearchFailure = createPayloadAction<HttpErrorResponse>('On-boarding Failure');

const postExternalSearchApiValidation = createPayloadAction<string>('External Api validation');

const postExternalSearchApiValidationSuccess = createPayloadAction<boolean>('External Api validation Success');
const postExternalSearchApiValidationFailure = createPayloadAction<HttpErrorResponse>(
  'External Api validation Failure',
);

const postExternalSearchApiCloning = createPayloadAction<string>('External Api Cloning');

const postExternalSearchApiCloningSuccess = createPayloadAction<ExternalSearchSource>('External Api Cloning Success');
const postExternalSearchApiCloningFailure = createPayloadAction<HttpErrorResponse>('External Api Cloning Failure');

const deleteExternalSearchApi = createPayloadAction<string>('External Api delete');

const deleteExternalSearchApiSuccess = createPayloadAction<void>('External Api delete Success');
const deleteExternalSearchApiFailure = createPayloadAction<HttpErrorResponse>('External Api delete Failure');

const updateExternalSearch = createPayloadAction<ExternalSearchSource>('update ExternalSearch');
const updateExternalSearchSuccess = createPayloadAction<ExternalSearchSource>('update ExternalSearch Success');
const updateExternalSearchFailure = createPayloadAction<HttpErrorResponse>('update ExternalSearch Failure');
const toggleExternalAppMenu = createPayloadAction<boolean>('toggle ExternalApp Menu');
const toggleExternalSearchSourceList = createPayloadAction<boolean>('toggle External source list');

export const externalSearchActions = {
  initialize,
  postExternalSearch,
  postExternalSearchSuccess,
  postExternalSearchFailure,
  postExternalSearchApiValidation,
  postExternalSearchApiValidationSuccess,
  postExternalSearchApiValidationFailure,
  getExternalSearchById,
  getExternalSearchByIdSuccess,
  getExternalSearchByIdFailure,
  updateExternalSearch,
  updateExternalSearchSuccess,
  updateExternalSearchFailure,
  getExternalSearchSourceLightList,
  getExternalSearchSourceLightListSuccess,
  getExternalSearchSourceLightListFailure,
  toggleExternalAppMenu,
  toggleExternalSearchSourceList,
  postExternalSearchApiCloning,
  postExternalSearchApiCloningSuccess,
  postExternalSearchApiCloningFailure,
  deleteExternalSearchApi,
  deleteExternalSearchApiSuccess,
  deleteExternalSearchApiFailure,
  initialExternalSearch,
};
