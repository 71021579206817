import React from 'react';
import CheckboxWithLabel from '../Base/CheckBoxWithLabel';

interface InformationCardForCheckboxProps {
  title: string;
  description: string;
  isChecked?: boolean;
  checkboxLabel: string;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InfoCheckbox: React.FC<InformationCardForCheckboxProps> = ({
  isChecked = false,
  title,
  description,
  checkboxLabel,
  onCheckboxChange,
}) => {
  return (
    <div className="border-b border-gray-200 mb-6 animate__animated animate__fadeInUp ">
      <h2 className="text-lg font-semibold">{title}</h2>
      <p className={`text-sm ${description ? 'mt-2 mb-4' : ''} text-gray-700`}>{description}</p>
      <button className="text-sm text-blue-600  cursor-pointer flex items-center">
        <CheckboxWithLabel
          isChecked={isChecked}
          label={checkboxLabel}
          showInfoIcon={false}
          onCheckboxChange={onCheckboxChange}
        />
      </button>
    </div>
  );
};

export default InfoCheckbox;
