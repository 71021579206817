import { HttpErrorResponse, Library, LibraryCreateOrUpdateRequest,  LibraryDetailsUpdateRequest,  LibraryListLitePaginate, LibraryListPaginate, LibraryMap, LibraryMapLite } from '../../../types/models';
import { HttpClient } from '../http';
  class CommonServiceLibraryServiceImpl {
  private static instance: CommonServiceLibraryServiceImpl;

  private constructor() {}

  static getInstance(): CommonServiceLibraryServiceImpl {
    if (!CommonServiceLibraryServiceImpl.instance) {
      CommonServiceLibraryServiceImpl.instance = new CommonServiceLibraryServiceImpl();
    }

    return CommonServiceLibraryServiceImpl.instance;
  }

  getCustomLibraries(): Promise<LibraryMapLite[]> {
    var endpoint = `patterns/custom-patterns`;
    return HttpClient.get<LibraryMapLite[]>(endpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }


  getUserActivePatterns(): Promise<LibraryMap[]> {
    var endpoint = `patterns/get-active-patterns`;
    return HttpClient.get<LibraryMap[]>(endpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  getSystemLibrariesLite(page: number = 1, take: number = 50): Promise<LibraryMapLite[]> {
    var paginationEndpoint = `library/system-library/paginate/lite?order=DESC&page=${page}&take=${take}&disablePagination=true`;
    return HttpClient.get<LibraryListLitePaginate>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data.data,
    );
  }
  getLibraries(page: number = 1, take: number = 50): Promise<Library[]> {
    var paginationEndpoint = `library/paginate/light?order=DESC&page=${page}&take=${take}`;
    return HttpClient.get<LibraryListPaginate>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data.data,
    );
  }

  toggleLibraryOption(payload:LibraryMap): Promise<LibraryMap> {
    var paginationEndpoint = `patterns/toggle-library-option`;
    return HttpClient.post<LibraryMap>(paginationEndpoint, payload, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  uploadCustomPattern(bulkUploadPattern: File) {
    const formData = new FormData();
    formData.append('file', bulkUploadPattern);
    return HttpClient.post<boolean>('/patterns/upload-custom-patterns', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((response) => response.data);
  } 

  uploadSystemPattern(bulkUploadPattern: File) {
    const formData = new FormData();
    formData.append('file', bulkUploadPattern);
    return HttpClient.post<boolean>('/library/upload-system-library', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((response) => response.data);
  }

  checkLibraryName(name: string) {
    return HttpClient.post<boolean>('/patterns/is-library-name-available', {name}).then((response) => response.data);
  }

  getCustomPatternById(libraryId:string): Promise<Library> {
    var paginationEndpoint = `patterns/get-library-by-id/`+libraryId;
    return HttpClient.get<Library>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }


  createCustomPattern(payload:Library): Promise<Library> {
    var paginationEndpoint = `patterns/create-custom-library`;
    return HttpClient.post<Library>(paginationEndpoint,payload, { portNumber: 5001,headers: { 'Content-Type': 'application/json' }, }).then(
      (response) => response.data,
    );
  }


  updateCustomPattern(payload:Library): Promise<Library> {
    var paginationEndpoint = `patterns/update-custom-library/`+payload.id;
    return HttpClient.patch<Library>(paginationEndpoint,payload, { portNumber: 5001,headers: { 'Content-Type': 'application/json' }, }).then(
      (response) => response.data,
    );
  }

  

  updateLibraryMetadata(payload:LibraryDetailsUpdateRequest): Promise<Library> {
    var paginationEndpoint = `patterns/update-library-meta/`+payload.id;
    return HttpClient.patch<Library>(paginationEndpoint,payload, { portNumber: 5001,headers: { 'Content-Type': 'application/json' }, }).then(
      (response) => response.data,
    );
  }

  getSystemPatternById(libraryId:string): Promise<Library> {
    var paginationEndpoint = `library/`+libraryId;
    return HttpClient.get<Library>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  removePatternTerm(libraryId:string,termId:string): Promise<boolean> {
    var paginationEndpoint = `patterns/${libraryId}/term/`+termId;
    return HttpClient.delete<boolean>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }


  removePattern(libraryId:string): Promise<boolean> {
    var paginationEndpoint = `patterns/`+libraryId;
    return HttpClient.delete<boolean>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }
}

const CommonLibraryService = CommonServiceLibraryServiceImpl.getInstance();

export { CommonLibraryService };