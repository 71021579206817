import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isUserLoggedIn, selectProfileErrorMessage } from '../../redux/selectors/profile.selectors';
import { RoutePath } from '../../common/constants/route-path';
import { Action, Module } from '../authorization/AuthContext';
import { useEffect, useState } from 'react';
import { ProfileErrorCode } from '../../common/constants/profile/profile-error-code';

interface WithAuthCheckProps {
  children?: React.ReactNode;
}

export const ProtectedRoute: React.FC<any> = ({ children }) => {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const location = useLocation();
  const loginErrorSelector = useSelector(selectProfileErrorMessage);
  const [sessionExpiredCheck, setSessionExpiredCheck] = useState(false);
  const router = useNavigate();
  useEffect(() => {
    if(loginErrorSelector === ProfileErrorCode.UnAuthorized) {
      router(RoutePath.Home);
    }
  }, [loginErrorSelector]);

  return isLoggedIn ? children : <Navigate to={location.state?.from || RoutePath.Home} />;
};
