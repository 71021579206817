import { useState } from 'react';
import { PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER } from '../../common/constants/app-text';

interface ContentInputProps {
  content?: string;
  setContent: (content: string) => void;
  placeHolderText?: string;
  showTextInput?: boolean;
}

export function TextArea({
  content,
  setContent,
  placeHolderText = PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER,
}: ContentInputProps) {
  const [charCount, setCharCount] = useState<number>(0);

  return (
    <div className="mt-5">
      <div className="flex justify-center items-center mt-2 mb-2 animate__animated animate__fadeInDown">
        <textarea
          style={{ backgroundColor: charCount > 0 ? 'white' : '#f0f1f9' }}
          className={
            ' w-full h-96 content-input relative p-4 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-lg text-base'
          }
          value={content}
          placeholder={placeHolderText}
          onChange={(e) => {
            setContent(e.target.value);
            setCharCount(e.target.value.length);
          }}
        />
      </div>
      <div className="text-wrapper-34">{charCount} characters</div>
    </div>
  );
}

export default TextArea;
