import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../types/App.type';
import { CodeAnalysisState } from '../slices/codeAnalysis.slice';

const selectCodeAnalysisState: (state: AppState) => CodeAnalysisState = (state) => state.codeAnalysis;
export const currentCodeAnalysisSelector = createSelector(
  selectCodeAnalysisState,
  ({ currentCodeAnalysis }) => currentCodeAnalysis,
);


export const codeBillOfLaddingResult = createSelector(
  selectCodeAnalysisState,
  ({ currentCodeAnalysis }) => currentCodeAnalysis?.billOfLadding ?? null,
);


export const referenceFinderResultSelector = createSelector(
  selectCodeAnalysisState,
  ({ currentCodeAnalysis }) => currentCodeAnalysis?.codeReference ?? null,
);
export const riskAssessmentOutputResponseSelector = createSelector(
  selectCodeAnalysisState,
  ({ currentCodeAnalysis }) => currentCodeAnalysis?.riskAssessment ?? [],
);
export const currentCodeAnalysisInputSelector = createSelector(
  selectCodeAnalysisState,
  ({ inputCodeRequest }) => inputCodeRequest,
);
export const currentCodeAnalysisId = createSelector(
  selectCodeAnalysisState,
  ({ currentCodeAnalysis }) => currentCodeAnalysis?.id,
);
export const isCodeAnalysisRunning = createSelector(
  selectCodeAnalysisState,
  ({ isProcessingCode }) => isProcessingCode,
);
export const promptErrorCodesSelector = createSelector(selectCodeAnalysisState, ({ errorCode }) => errorCode);
export const userCodeRequestListSelector = createSelector(
  selectCodeAnalysisState,
  ({ favoriteCodeRequests }) => favoriteCodeRequests,
);
export const processingCodeInputSelector = createSelector(
  selectCodeAnalysisState,
  ({ inputCodeRequest }) => inputCodeRequest,
);
export const favoriteCodeRequestSelector = createSelector(
  selectCodeAnalysisState,
  ({ favoriteCodeRequests }) => favoriteCodeRequests,
);
export const favoriteCodeRequestPaginationSelector = createSelector(
  selectCodeAnalysisState,
  ({ historyPaginationConfig }) => historyPaginationConfig,
);
export const favoritesCodeRequestsCountSelector = createSelector(
  selectCodeAnalysisState,
  ({ favoriteCodesCount }) => favoriteCodesCount,
);
