import { useDispatch, useSelector } from 'react-redux';
import { articleErrorCodes, currentArticle } from '../../redux/selectors/article.selectors';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { articleActions } from '../../redux/actions/article.actions';
import { RoutePath } from '../../common/constants/route-path';
import { ArticleResultView } from '../../components/ResultView/ResultView';
import { GuardRailEngineType } from '../../common/constants/enums/engine-types.enum';

import AppLoader from '../../components/AppLoader/AppLoader';
import { Gateway123ResultAssertions } from './GatewayResultAssertions';

import CKEditorComponent from '../../components/CKEditor/CKEditor';
import HorizontalStepper from '../../components/Stepper/Stepper';
import { stepperSteps } from '../../common/constants/gateway123';

export interface ArticleResultViewContentProps {
  engineType?: GuardRailEngineType;
}

export const Gateway123ResultView: React.FC<ArticleResultViewContentProps> = ({
  engineType = GuardRailEngineType.FactChecker,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const assertion = useSelector(currentArticle);
  const error = useSelector(articleErrorCodes);
  const { id } = useParams<{ id: string }>();
  const [footnoteCount, setFootnoteCount] = useState(1); // Track the current footnote number

  const [editorData, setEditorData] = useState('');

  useEffect(() => {
    if (error && error.length) {
      navigate(RoutePath.Landing);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (id) dispatch(articleActions.getArticleById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!editorData) setFootnoteCount(1);
  }, [editorData]);

  function addAssertion(text: string, links?: string[]) {
    // Get the current content from the editor and split it
    const [contentWithoutFootnotes, existingFootnotes] = editorData.split(
      '<hr><p style="font-size:14px;">References</p>',
    );
    // Add the new text to the content
    let updatedContent = contentWithoutFootnotes.trim() + text;
    let newFootnotes = existingFootnotes
      ? '<hr><p style="font-size:14px;">References</p>' + existingFootnotes
      : '<hr><p style="font-size:14px;">References</p>';
    // Append the footnotes, if any
    if (links && links?.length > 0) {
      links.forEach((link, index) => {
        const footnoteNumber = footnoteCount + index;
        updatedContent += ` <sup>[${footnoteNumber}]</sup>`;
        newFootnotes += `<p style="font-size:10px;" id="footnote-${footnoteNumber}">${footnoteNumber}. <a href="${link}" target="_blank" style="font-size:10px;">${link}</a></p>`;
      });
      // Update footnote count after adding new footnotes
      setFootnoteCount((prevCount) => prevCount + links.length);
    }
    // Combine the updated content with the footnotes
    const finalContent = updatedContent + newFootnotes;
    // Update the editor content state
    setEditorData(finalContent);
  }

  return assertion ? (
    <>
      <div className="flex justify-center items-center animate__animated animate__fadeInUp">
        <div className="w-4/5 mt-10">
          <HorizontalStepper steps={stepperSteps} completedStep={3} />
          <ArticleResultView assertion={assertion} engineView={engineType} showTags={true} />
        </div>
      </div>
      <div className={`group-wrapper animate__animated animate__fadeInUp animate__delay-1s flex`}>
        <Gateway123ResultAssertions assertion={assertion} addAssertion={addAssertion} />
        <div className="flex-1 mt-16 h-full ml-10">
          <CKEditorComponent editorData={editorData} setEditorData={setEditorData} />
        </div>
      </div>
    </>
  ) : (
    <AppLoader />
  );
};
