import { AppState } from '../types/App.type';
import { createSelector } from '@reduxjs/toolkit';
import { PasswordRecoveryState } from '../slices/password-recovery.slice';

const selectState: (state: AppState) => PasswordRecoveryState = (state) => state.passwordRecover;

export const selectStage = createSelector(selectState, (x) => x.stage);
export const selectEmail = createSelector(selectState, (x) => x.email);
export const selectToken = createSelector(selectState, (x) => x.token);
export const selectError = createSelector(selectState, (x) => x.error);
export const isLoading = createSelector(selectState, (x) => x.loading);

export const passwordRecoverSelectors = {
  selectStage,
  selectEmail,
  selectToken,
  selectError,
  isLoading,
};
