import {
  DossierPersonLite,
  DossierUpdateRequest,
  HttpErrorResponse,
  ProcessDossierRequest,
  ResultListLite,
} from '../../../types/models';
import { SocialMediaProfilesEnum } from '../../constants/enums/engine-types.enum';
import { DashboardMatericsRequestByDateRange, ModuleMetrics } from '../dashboard/models/dashboard.model';
import { HttpClient } from '../http';
import { Dossier } from './person/dossierPerson';

export const mockDossier: Dossier = {
  dossierId: '6570b18b5909e984d3f7700f',
  personalInformationDto: {
    FullName: 'Sundar Pichai',
    DateOfBirth: 'June 10, 1972',
    Nationality: ['Indian-born American'],
    Gender: 'Male',
    SocialMediaProfiles: [{
      ProfileUrl: 'https://twitter.com/sundarpichai',
      Platform: SocialMediaProfilesEnum.Twitter,
    }],
  },
  contactInformationDto: {
    phoneNumbers: ['+1 123 456 7890'],
    emailAddresses: ['sundar.pichai@example.com'],
    homeAddress: '1234 Alphabet Street, Mountain View, CA, USA',
    workAddress: '1600 Amphitheatre Parkway, Mountain View, CA, USA',
  },
  educationAndQualificationDto: {
    EducationalBackground: [
      'B.Tech from IIT Kharagpur, MS from Stanford University, MBA from Wharton School of the University of Pennsylvania',
    ],
    ProfessionalCertifications: ['N/A'],
  },
  employmentHistoryDto: {
    WorkExperience: ['CEO of Alphabet Inc. and Google'],
    RolesAndResponsibilities: ['Overseeing the operations and strategic direction of Alphabet Inc. and Google'],
    AchievementsOrProjectsUndertaken: ['Led the development of Google Chrome and Chrome OS'],
  },
  skillsAndExpertiseDto: {
    TechnicalSkills: ['Knowledge of computer architecture, machine learning'],
    DomainSpecificSkills: ['Product development, corporate strategy'],
    LeadershipAbilities: ['Strategic planning, team management'],
  },
  professionalAssociationsAndMembershipDto: {
    ProfessionalOrganizations: ['Member of the National Academy of Engineering'],
    IndustryAssociations: ['N/A'],
    CommunityInvolvement: ['Supports various charities'],
  },
  publicationsAndContributionDto: {
    ResearchPapers: ['N/A'],
    Articles: ['N/A'],
    Books: ['N/A'],
    PresentationsOrTalks: ['Various keynotes at Google I/O'],
  },
  updatedAt: '2023-12-06T17:38:20.002Z',
  createdBy: 'Cesar Admin',
  createdAt: '2023-12-06T17:38:20.002Z',
  deletedAt: '2023-12-06T17:38:20.002Z',
  progressPercentage: 100,
  isFavorite: false,
};

class DossierServiceImpl {
  processDossier(request: ProcessDossierRequest): Promise<Dossier> {
    return HttpClient.post<Dossier>('/dossier', request, {
      portNumber: 5001,
      showLoader:false,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
  getDossierById(id: string): Promise<Dossier> {
    return HttpClient.get<Dossier>(`/dossier/${id}`, { portNumber: 5001 }).then((response) => response.data);
  }

  getForDashboard(request: DashboardMatericsRequestByDateRange): Promise<ModuleMetrics> {
    const baseURL = '/dossier/getMetricsByDaterange';
    return HttpClient.post<ModuleMetrics>(baseURL, request, {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }

  getDefaultDossier() {
    return mockDossier;
  }

  getDossierList(page: number = 1, take: number = 10): Promise<ResultListLite<DossierPersonLite>> {
    const baseURL = '/dossier';
    var paginationEndpoint = `/paginate/light?order=DESC&page=${page}&take=${take}`;
    paginationEndpoint = baseURL + paginationEndpoint;
    return HttpClient.get<ResultListLite<DossierPersonLite>>(paginationEndpoint, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  updateDossier(changes: DossierUpdateRequest): Promise<Dossier> {
    const baseURL = '/dossier';
    let request = {
      isFavorite: changes.isFavorite.toString(),
      progressPercentage: '100',
    };

    return HttpClient.put<Dossier>(`${baseURL}/${changes.id}`, request, { portNumber: 5001 }).then(
      (response) => response.data,
    );
  }

  deleteDossier(id: string): Promise<void> {
    return HttpClient.delete<void>(`/dossier/${id}`, {
      portNumber: 5001,
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
  clearHistory(): Promise<boolean> {
    return HttpClient.post<boolean>('/dossier/clear-history', {
      portNumber: 5001,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.data)
      .catch((err: HttpErrorResponse) => {
        throw err;
      });
  }
}

const DossierService = new DossierServiceImpl();

export { DossierService };
