import { LinkIcon } from '@heroicons/react/outline';
import { GitHubIcon, StackOverFlowIcon } from '../../../common/icons/Icons';
import { CodeMirrorInput } from '../CodeMirrorInput';
import Badge from '../../Base/Badge';
import { useEffect, useState } from 'react';
import { StackOverFlow } from '../../../types/models';

interface StackOverflowReferenceProps {
  index?: number;
  item: StackOverFlow;
  code?:string;
}

export const StackOverFlowReference = ({ index = 1 , item, code="" }:StackOverflowReferenceProps) => {
  let [primaryTags , setPrimaryTags] = useState<string[]>([]);
  let [secondaryTags , setSecondaryTags] = useState<string[]>([]);
  let [replierName , setReplierName] = useState<string>();
  useEffect(() => {
    let primary = [] as string[];
      let secondary =  [] as string[];
    if(item){
        primary.push(`${item.questions ?? 0} questions`)
        primary.push(`${item.reached ?? 0} reached`)
        primary.push(` repuration : ${item.reputation ?? 0}`)
        primary.push(`Tier ${item.tier}`)
        secondary.push(`${item.answers ?? 0} answers`)
        secondary.push(`${item.badges.bronze ?? 0} bronze`)
        secondary.push(`${item.badges.silver ?? 0} silver`)
        secondary.push(`${item.badges.gold ?? 0} gold`)
        if(item.replier_url){
          let name = item.replier_url.split('/');
          if(name.length){
            setReplierName(name[name.length-1].replace(/-/g, ' '));
          }
           
        }else{
          setReplierName('NA');
        }
    }
    setPrimaryTags(primary);
    setSecondaryTags(secondary);
    console.log('github reference', item);
    
  },[item])
  return (
    <>
      <div className="mt-5 animate__animated animate__fadeInUp ">
        <p className="font-bold font-black">Reference {index+1}</p>
        <div className="flex flex-row gap-6">
          <div className="flex flex-col min-w-500 ">
            <div className="flex  flex-row gap-3 mt-6">
              <span className="text-gray-500 font-semibold font-[Open_sans]">Replier Name</span>
              <span  onClick={() => window.open(item.replier_url, '_blank') } className="text-primary-blue font-[Open_sans]">{replierName ?? 'NA'}</span>
            </div>
            <div>
              <div className="flex flex-row flex-wrap mt-4 ">
                {primaryTags.map((e, index) => (
                  <Badge key={index} backgroundColor="#EBEEF6" text={e} />
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="flex  flex-row gap-3 mt-4">
              <div
              onClick={() => window.open(item.url, '_blank') }
                className="flex justify-between gap-4  border rounded  mx-2 p-3 align-middle"
                style={{
                  background: '#D6F6F9',
                }}
              >
                <div className="flex col"  >
                  <span className="mt-1">
                    <StackOverFlowIcon color="#10828B" />
                  </span>
                  <span className="font-sm font-['Open_Sans'] mx-2">Repository URL</span>
                </div>
                <span className="mt-1">
                  <LinkIcon color="#56607C" />
                </span>
              </div>
            </div>
            <div>
              <div className="flex flex-row flex-wrap ">
                {secondaryTags.map((e, index) => (
                  <Badge key={index} backgroundColor="#EBEEF6" text={e} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <>
        { code && code.length && <CodeMirrorInput
            hideTitle={true}
            disabled={true}
            code={code}
          />}
        </>
      </div>
    </>
  );
};
