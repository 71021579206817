import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationIcon,
  ExclamationCircleIcon,
  TicketIcon,
  CheckIcon,
} from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { selectNotification } from '../../redux/selectors/notification.selectors';
import { clearNotification } from '../../redux/actions/notification.actions';

export default function Component() {
  const props = useSelector(selectNotification);
  const dispatch = useDispatch();

  const [show, setShow] = useState(true);

  const getIcon = () => {
    switch (props.type) {
      case 'success':
        return <CheckIcon className="h-6 w-6 text-white-500" aria-hidden="true" />;
      case 'info':
        return <InformationCircleIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />;
      case 'warning':
        return <ExclamationIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />;
      case 'error':
        return <ExclamationCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />;
      default:
        return <></>;
    }
  };

  const getBackground = () => {
    switch (props.type) {
      case 'success':
        return 'bg-primary-blue';
      case 'info':
        return 'bg-purple-600';
      case 'warning':
        return 'bg-orange';
      case 'error':
        return 'bg-red-500';
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (!props.title) {
      return;
    }

    const timeout = setTimeout(() => dispatch(clearNotification()), props.clearDelay || 5000);
    return () => clearTimeout(timeout);
  }, [props]);

  if (!props.title) {
    return null;
  }

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`max-w-sm w-full ${getBackground()} shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 text-white">{getIcon()}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-white">{props.title}</p>
              {props.text && <p className="mt-1 text-sm text-white">{props.text}</p>}
            </div>
            {props.dismissable !== false && (
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className="text-white inline-flex hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setShow(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
}
