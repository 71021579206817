import Typography from "../Typography/Ty[pgraphy";

interface IconButtonProps {
  icon: React.ReactNode;
  text: string;
  iconClassName?: string;
  textClassName?: string;
  onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  text,
  iconClassName = '',
  textClassName = 'text-purple-primary',
  onClick,
}) => {
  return (
    <div className="flex items-center cursor-pointer gap-2 justify-center mx-2" onClick={onClick}>
      <div className={iconClassName}>{icon}</div>
      <Typography className={`${textClassName} text-sm`}>{text}</Typography>
    </div>
  );
};

export default IconButton;