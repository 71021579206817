import { HttpClient } from '../http';
import { AuthResponse } from './models/auth-response';
import { SupportRequest } from './models/support-request';

class AuthServiceImpl {
  private static servicePort = 80;
  authorizeByCredentials(email: string, password: string) {
    return HttpClient.post<AuthResponse>('/login', { username: email, password }, { portNumber: 80 , showLoader:false, }).then(
      (response) => response.data,
    );
  }

  authorizeByEmail(email: string, password: string) {
    return HttpClient.post<AuthResponse>('/guestLogin', { username: email, password }, { portNumber: 80 }).then(
      (response) => response.data,
    );
  }

  refreshToken(token:string):Promise<AuthResponse> {
    return HttpClient.post<AuthResponse>('/token/refresh',{token}).then((response) => response.data);
  }
  sendSupportRequest(request: SupportRequest) {
    return HttpClient.post('/support/case', request).then((response) => response.data);
  }
}

const AuthService = new AuthServiceImpl();

export { AuthService };
