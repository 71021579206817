import {
  ArticleUpdateRequest,
  AssertionResult,
  AssertionResultLite,
  HttpErrorResponse,
  PromptProtect,
  ResultListLite,
  UserHistoryList,
} from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const PROMPT_STORE_NAME = 'Prompt Store';

const createPayloadAction = getCreatePayloadActionFromStore(PROMPT_STORE_NAME);
const createAction = getCreateActionFromStore(PROMPT_STORE_NAME);

const getDefaultPrompt = createAction('Get Default Prompt');
const getDefaultPromptSuccess = createPayloadAction<PromptProtect>('Get Default Success');
const getDefaultPromptFailure = createPayloadAction<HttpErrorResponse>('Get Default Failure');

const processPrompt = createPayloadAction<string>('Process Prompts');
const processPromptEnabled = createPayloadAction<boolean>('Process Prompts Enabled');
const processPromptSuccess = createPayloadAction<PromptProtect>('Process Success');
const processPromptFailure = createPayloadAction<HttpErrorResponse>('Process Failure');

const getPromptById = createPayloadAction<string>('Get Prompt By Id');
const getPromptByIdSuccess = createPayloadAction<PromptProtect>('get Prompt By Id Success');
const getPromptByIdFailure = createPayloadAction<HttpErrorResponse>('get Prompt By Id Failure');

const getUserPromptsList = createPayloadAction<number>('Get User Prompts List');
const getUserPromptsListSuccess = createPayloadAction<ResultListLite<UserHistoryList>>('Get User Prompts List Success');
const getUserPromptsListFailure = createPayloadAction<HttpErrorResponse>('Get User Prompts List Failure');

const setPromptFavorite = createPayloadAction<ArticleUpdateRequest>('Set Prompt Favorite');
const setPromptFavoriteSuccess = createPayloadAction<PromptProtect>('Set Prompt Favorite Success');
const setPromptFavoriteFailure = createPayloadAction<HttpErrorResponse>('Set Prompt Favorite Failure');

const deletePrompt = createPayloadAction<string>('Delete Prompt');
const deletePromptSuccess = createPayloadAction<string>('Delete Prompt Success');
const deletePromptFailure = createPayloadAction<HttpErrorResponse>('Delete Prompt Failure');

const toggleFavoritePrompts = createPayloadAction<boolean>('Toggle Favorite Prompts');
const setPromptContent=createPayloadAction<string>("Edit Prompts");
const clearPromptHistory = createAction('Clear prompt history');

const clearPromptHistorySuccess = createAction('Clear dossier Prompt history success');

const clearPromptPersonHistoryFailure = createAction('Clear dossier Prompt history failure');

export const PromptActions = {
  processPrompt,
  processPromptSuccess,
  processPromptEnabled,
  processPromptFailure,
  getUserPromptsList,
  getUserPromptsListSuccess,
  getUserPromptsListFailure,
  getDefaultPrompt,
  getDefaultPromptSuccess,
  getDefaultPromptFailure,
  getPromptById,
  getPromptByIdSuccess,
  getPromptByIdFailure,
  setPromptFavorite,
  setPromptFavoriteSuccess,
  setPromptFavoriteFailure,
  toggleFavoritePrompts,
  setPromptContent,
  clearPromptHistory,
  clearPromptHistorySuccess,
  clearPromptPersonHistoryFailure,
  deletePrompt,
  deletePromptSuccess,
  deletePromptFailure,
};
