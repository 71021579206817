import { Divider } from '../../components/Divider/Divider';
import { CodeMirrorInput } from '../../components/CodeAnalysis/CodeMirrorInput';
import { InputOptionsForCodeAnalysis } from '../../components/CodeAnalysis/InputOptions';
import { useEffect, useState } from 'react';
import { CodeProcessingIndicator } from '../../components/CodeAnalysis/CodeProcessingIndicator';
import { useSelector } from 'react-redux';
import { currentCodeAnalysisInputSelector } from '../../redux/selectors/codeAnalysis.selectors';
export const CodeAnalysisInput: React.FC<any> = ({ isProcessing }) => {
  const userInput = useSelector(currentCodeAnalysisInputSelector);
  const [codeInput, setCodeInput] = useState(userInput?.code || '');

  useEffect(() => {
    setCodeInput(userInput?.code || '');
  }, [userInput]);
  const handleCodeChange = (newCode: string) => {
    setCodeInput(newCode);
  };

  useEffect(() => {
    if (!userInput?.code) {
      setCodeInput('');
    }
  }, []);
  return (
    <div className=" p-4 ml-20">
      <div className="bg-white p-6">
        <CodeMirrorInput code={codeInput} disabled={isProcessing} onChange={handleCodeChange} />
        <Divider />
        {isProcessing ? (
          <CodeProcessingIndicator />
        ) : (
          <InputOptionsForCodeAnalysis
            onSubmi={() => {
              console.log('Submit clicked');
            }}
            code={codeInput}
          />
        )}
      </div>
    </div>
  );
};
