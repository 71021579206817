import { FC } from 'react';
import { isEmpty } from '../../common/helpers/objects/is-empty';
import { Validators } from '../../common/helpers/form-controls/validators';
import { useForm } from '../../common/helpers/form-controls/use-form';
import MessageBox from '../MessageBox';
import Input from '../Input';
import { PrimaryButton } from '../PrimaryButton';
import { MainButton } from '../MainButton';
import { passwordRecoverSelectors } from '../../redux/selectors/password-recover.selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { SecondaryButton } from '../SecondaryButton';

interface PasswordForm {
  oldPassword: string;
  password: string;
  repeatPassword: string;
}

interface PropTypes {
  isResetting?: boolean;
  isChangingPassword?: boolean;
  onSubmit: (password: string, oldPassword?: string) => void;
}

// const PASSWORD_REQUIREMENTS = [
//   'A minimum of 1 lower case letter (a-z)',
//   'A minimum of 1 upper case letter (A-Z)',
//   'A minimum of 1 numeric character (0-9)',
//   'A minimum of 1 special character (~`!@#$%^&*()-_+={}[]|\\;:"<>,./?)',
// ];

const Component: FC<PropTypes> = ({ isResetting, isChangingPassword, onSubmit }) => {
  const isProcessing = useSelector(passwordRecoverSelectors.isLoading);
  const heading = isResetting ? 'Reset Password' : isChangingPassword ? 'Change Password' : 'Create Password';

  const { handleSubmit, handleChange, data, errors } = useForm<PasswordForm>({
    initialValues: {
      oldPassword: '',
      password: '',
      repeatPassword: '',
    },
    validations: {
      oldPassword: isChangingPassword
        ? [
            {
              validator: Validators.required,
              message: 'Old password is required',
            },
          ]
        : [],
      password: [
        {
          validator: Validators.password,
          message: 'Invalid password',
        },
        {
          validator: Validators.notEqualToField('oldPassword'),
          message: 'New password cannot be the same as the old password',
        }
      ],
      repeatPassword: [
        {
          validator: Validators.equalToField('password'),
          message: "Password doesn't match",
        },
      ],
    },
    onSubmit: () => {
      const oldPassword = isChangingPassword ? data.oldPassword : undefined;
      onSubmit(data.password, oldPassword);
    },
  });
  var navigate = useNavigate();
  return (
    <div className="flex flex-col gap-1">
      <div className="mt-10">
        <h2 className="text-2xl font-bold text-blueGray-900">{heading}</h2>
        {isResetting && <p className="mt-4 text-base text-blueGray-500">Create a new password below</p>}
      </div>
      {/* <div className="mt-6">
        <MessageBox title="Password must have:" type="information">
          <div className="leading-loose">
            <div className="leading-relaxed pt-1">
              {PASSWORD_REQUIREMENTS.map((requirement) => (
                <div className="marked-text marked-text-blue">{requirement}</div>
              ))}
            </div>
          </div>
        </MessageBox>
      </div> */}
      <form onSubmit={handleSubmit} action="#" method="POST">
        {isChangingPassword && (
          <div className="pt-10 my-4">
            <Input
              id="old-password"
              type="password"
              size="sm"
              placeholder="Old Password"
              autoComplete="current-password"
              value={data.oldPassword}
              error={!!errors.oldPassword}
              onChange={handleChange('oldPassword')}
            />
            {errors.oldPassword && (
              <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-4 box-content">{errors.oldPassword}</div>
            )}
          </div>
        )}
        <div className="pt-4 my-2">
          <Input
            id="new-password"
            type="password"
            size="sm"
            placeholder="New Password"
            autoComplete="new-password"
            value={data.password}
            error={!!errors.password}
            onChange={handleChange('password')}
          />
          {errors.password && (
            <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-4 box-content">{errors.password}</div>
          )}
        </div>
        <div className="my-4">
          <Input
            id="confirm-password"
            type="password"
            size="sm"
            placeholder="Confirm Password"
            autoComplete="new-password"
            value={data.repeatPassword}
            error={!!errors.repeatPassword}
            onChange={handleChange('repeatPassword')}
          />
          {errors.repeatPassword && (
            <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.repeatPassword}</div>
          )}
        </div>
        <div className="my-8 flex gap-6">
          <MainButton
            isLoading={isProcessing}
            text={isChangingPassword ? 'Submit' : isResetting ? 'Reset Password ' : 'Activate Account'}
            onClick={handleSubmit}
            className="px-5 py-2"
            stateProp="rest"
            disabled={!isEmpty(errors) || !data.password || !data.repeatPassword}
          />
          {isChangingPassword && (
            <SecondaryButton
              className="px-3 py-2"
              stateProp="rest"
              onClick={() => {
                navigate(RoutePath.Landing);
              }}
              text="Cancel"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default Component;
