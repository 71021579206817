import { useEffect, useState } from 'react';
import React from 'react';
import CountIndicator from '../../components/CountIndicator/CountIndicator';
import { AssertionResult } from '../../types/models';
import { Gateway123AssertionResultsList } from './Gateway123AssertionResultsList';

export interface Gateway123ResultAssertionsProps {
  assertion: AssertionResult;
  addAssertion: any;
}

export const Gateway123ResultAssertions: React.FC<Gateway123ResultAssertionsProps> = ({ assertion, addAssertion }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAssertion, setCurrentAssertion] = useState(assertion.assertions[0]);
  const [selectedSourcesByTab, setSelectedSourcesByTab] = useState<Record<number, string[]>>({});

  useEffect(() => {
    setCurrentAssertion(assertion.assertions[currentIndex]);
  }, [currentIndex, assertion.assertions]);

  const handleSelectSources = (sources: string[]) => {
    setSelectedSourcesByTab((prev) => ({
      ...prev,
      [currentIndex]: sources,
    }));
  };

  const handleAddAssertion = () => {
    addAssertion(currentAssertion.articleName, selectedSourcesByTab[currentIndex] || []);
  };

  return (
    <>
      {assertion.assertions.length ? (
        <div className={`w-[30vw]`}>
          <div className="overlap-group-2">
            <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
              {assertion.assertions.map((result, index) => (
                <li key={index}>
                  <CountIndicator
                    setCurrentIndex={() => setCurrentIndex(index)}
                    colorCode={(result as any).colorCode}
                    currentIndex={currentIndex}
                    index={index}
                  />
                </li>
              ))}
            </ul>
            <div className="rectangle-6" />
          </div>
          <div className="navtab-result-container">
            <Gateway123AssertionResultsList
              assertion={currentAssertion}
              assertionCount={currentIndex + 1}
              selectedSources={selectedSourcesByTab[currentIndex] || []}
              onSelectSources={handleSelectSources}
              onAddAssertion={handleAddAssertion}
            />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-96"> No sources found </div>
      )}
    </>
  );
};
