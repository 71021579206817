import PropTypes from 'prop-types';
import React from 'react';
import './style.css';
import { FooterProps } from '../../common/models/Props/props';
import { Logo } from '../Logo';
import logo4 from '../../static/img/logo-4.svg';
export const Footer = ({ className = '', group = '../../static/img/group-41.png' }: FooterProps): JSX.Element => {
  return (
    <div className={`flex justify-between px-8 py-4 bg-gray-800 text-white footer footer-instance ${className}`}>
      <div className="flex space-x-6 items-center">
        <Logo className="logo-instance flex space-x-4" logo={logo4} />
        <div className="block  ml-100">
          <span className="text-black">Guardrail Technologies Inc., ©2024</span>
        </div>
      </div>
      <div className="flex space-x-4 items-center">
        <div className="block">Privacy Policy</div>
        <div className="block">Contact Us</div>
        <div className="block">Terms of Use</div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  group: PropTypes.string,
};
