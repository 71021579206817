import React, { useState, useRef, useEffect } from 'react';
import { InfoIcon } from '../../common/icons/Icons';
import './style.css';

interface InfoIconWithDescriptionProps {
  color?: string;
  description: string;
}

const InfoIconWithDescription: React.FC<InfoIconWithDescriptionProps> = ({ color = '#BEC5D7', description }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const iconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showDescription && iconRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      const tooltipWidth = 300; // Minimum width of the tooltip
      const tooltipHeight = 100; // Maximum height of the tooltip

      let top = iconRect.top - tooltipHeight - 10; // 10px gap above the icon
      let left = iconRect.left + iconRect.width / 2 - tooltipWidth / 2;

      // Ensure the tooltip does not go out of the viewport
      if (left < 0) {
        left = 10; // 10px from the left edge
      } else if (left + tooltipWidth > window.innerWidth) {
        left = window.innerWidth - tooltipWidth - 10; // 10px from the right edge
      }

      if (top < 0) {
        top = iconRect.bottom + 10; // Position below the icon if not enough space above
      }

      setTooltipPosition({ top, left });
    }
  }, [showDescription]);

  return (
    <div
      className="description-container"
      onMouseEnter={() => setShowDescription(true)}
      onMouseLeave={() => setShowDescription(false)}
      ref={iconRef}
    >
      {showDescription && (
        <div
          className="description-text"
          style={{ top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` }}
        >
          {description}
        </div>
      )}
      <InfoIcon color={color} />
    </div>
  );
};
export default InfoIconWithDescription;
