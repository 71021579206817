import { useEffect, useState } from 'react';
import { Divider } from '../Divider/Divider';
import { MainButton } from '../MainButton';
import InfoCheckbox from '../InfoCheckbox';
import { RiskAssessmentInput } from './RiskAssessmentInput';
import { CodeAnalysisInput } from '../../types/models';
import { useDispatch, useSelector } from 'react-redux';
import { CodeAnalysisActions } from '../../redux/actions/codeAnalysis.actions';
import { AssessmentOptions } from '../../common/constants/default-values';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../common/constants/route-path';
import { RequestTrackers } from '../RequestTracker/RequestTrackers';
import { limitedReached } from '../../redux/selectors/profile.selectors';

export const InputOptionsForCodeAnalysis = ({ code = '', onSumit }: any) => {
  const [content, setContent] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [billofLading, setBillofLading] = useState<boolean>(false);
  const [referenceFinder, setReferenceFinder] = useState<boolean>(false);
  const [selectedAssessments, setSelectedAssessments] = useState<string[]>([]);
  const limitReached = useSelector(limitedReached);
  const handleClick = () => {
    console.log('Code:', code);
    const codeAnalysisInput = {
      checkForBillOfLadding: billofLading,
      code: content,
      riskAssessment: selectedAssessments,
      checkForReferenceFinder: referenceFinder,
    } as CodeAnalysisInput;
    dispatch(CodeAnalysisActions.processCodeAnalysis(codeAnalysisInput));
    navigate(RoutePath.CodeAnalyzerProcess);
  };

  useEffect(() => {
    setContent(code);
  }, [code]);

  return (
    <>
      <RiskAssessmentInput
        onChange={(value) => {
          setSelectedAssessments(value);
        }}
      />
      <Divider />
      <InfoCheckbox
        checkboxLabel="Get Bill of Lading"
        description="  Assess your vulnerability to code supply chain attacks.  Start by creating an inventory the third party libraries and modules in use, then assess your execution environment for known issues."
        title="Bill of Lading"
        isChecked={billofLading}
        onCheckboxChange={(event) => setBillofLading(event.target.checked)}
      />
      <Divider />
      <InfoCheckbox
        isChecked={referenceFinder}
        checkboxLabel="Find code references"
        description="   When creating code using Generative AI, how do you know if you can trust the system?  Our reference finder locates potential source material that the AI may have been trained on and provides you with insights into the credibility of the original source."
        title="Reference Finder"
        onCheckboxChange={(event) => setReferenceFinder(event.target.checked)}
      />
      <Divider />
      <div className="w-100 flex items-end justify-end">
        {billofLading}

        <div className='flex justify-between w-full'>
              <div className='w-[80%]'>
                <RequestTrackers/>
              </div>
              <div className='flex justify-end items-center space-x-4'>
              <MainButton
          onClick={handleClick}
          className=""
          disabled={limitReached}
          stateProp="rest"
          text="Submit"
        />
              </div>
            </div>

       
      </div>
    </>
  );
};
