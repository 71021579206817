import { useDispatch, useSelector } from 'react-redux';
import { CheckMarkIcon } from '../../../common/icons/Icons';
import { appRolesWithPermissions } from '../../../redux/selectors/access-management.selectors';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { PermissionsUpdateModal } from '../../../components/Modal/PermissionsUpdateModel/PermissionsUpdateModel';
import { useState } from 'react';
import { accessManagementActions } from '../../../redux/actions/access-management.actions';

export const RolesView = (): JSX.Element => {
  const roleMappings = useSelector(appRolesWithPermissions);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updatePermissionsState, setUpdatePermissionsState] = useState({
    show: false,
    permissions: [] as string[],
    roleId: '',
  });

  return (
    <>
      <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">Roles Management</h3>
      <div className="flex flex-wrap justify-center">
        {roleMappings.map((roleDetails, index) => (
          <div key={index} className="m-5 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 flex-auto">
            <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <h5 className="mb-4 text-xl text-text-primary font-semibold ">{roleDetails.name}</h5>
              <div className="flex items-baseline text-gray-900 dark:text-white">
                <span className="ms-1 text-sm text-gray-500 dark:text-gray-400">
                  {roleDetails.permissions?.length} Permissions
                </span>
              </div>
              <ul role="list" className="space-y-5 my-7">
                {roleDetails.permissions?.slice(0, 8)?.map((permission, indexKey) => (
                  <li key={indexKey} className="flex items-center">
                    <CheckMarkIcon />
                    <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
                      {permission}
                    </span>
                  </li>
                ))}
                {roleDetails && roleDetails.permissions && roleDetails?.permissions?.length > 8 && (
                  <li className="flex items-center text-primary">
                    <p> {roleDetails?.permissions?.length - 8} more to go</p>
                  </li>
                )}
              </ul>
              <div className="flex justify-end">
                <PrimaryButton
                  className="mx-2 bg-gradient-to-tl from-blue-500 to-violet-500"
                  size="small"
                  stateProp="rest"
                  style="boxed"
                  text="View Role"
                  onClick={() => {
                    navigate('/access-management/role/' + roleDetails.name);
                  }}
                />
                <PrimaryButton
                  className="mx-2 bg-gradient-to-tl from-blue-500 to-violet-500"
                  size="small"
                  stateProp="rest"
                  style="boxed"
                  text="Manage Permissions"
                  onClick={() => {
                    setUpdatePermissionsState({
                      show: true,
                      permissions: roleDetails.permissions ?? [],
                      roleId: roleDetails.id,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {
        <PermissionsUpdateModal
          isShown={updatePermissionsState.show}
          permissions={updatePermissionsState.permissions}
          roleId={updatePermissionsState.roleId}
          onClose={() =>
            setUpdatePermissionsState({
              show: false,
              permissions: [],
              roleId: '',
            })
          }
          onSaveClick={(permissions) => {
            dispatch(
              accessManagementActions.updateRolePermissions({
                roleId: updatePermissionsState.roleId,
                permissions: permissions,
              }),
            );
            setUpdatePermissionsState({
              show: false,
              permissions: [],
              roleId: '',
            });
          }}
        />
      }
    </>
  );
};
