import { PayloadAction, createAction } from '@reduxjs/toolkit';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { mapApiResponseToResult, mapDossierPersonLiteToDossierLite, wait } from '../../common/helpers/Helper';
import { setNotification } from '../actions/notification.actions';
import { RoutePath } from '../../common/constants/route-path';
import { useNavigate } from 'react-router-dom';
import { browserHistory } from '../helpers/history/history';
import { DossierService } from '../../common/services/dossier/dossier.service';
import {
  DossierLite,
  DossierPersonLite,
  DossierUpdateRequest,
  ProcessDossierRequest,
  ResultListLite,
} from '../../types/models';
import { Dossier } from '../../common/services/dossier/person/dossierPerson';
import { dossierActions } from '../actions/dossier.actions';
import { profileActions } from '../actions/profile.actions';
export function* getDossiers({ payload }: PayloadAction<number>) {
  try {
    const userDossiers: ResultListLite<DossierPersonLite> = yield call(DossierService.getDossierList, payload, 10);

    if (Array.isArray(userDossiers.data)) {
      const mappedData: ResultListLite<DossierLite> = {
        ...userDossiers,
        data: userDossiers.data
          .filter((e) => e.personalInformationLightResponseDto)
          .map(mapDossierPersonLiteToDossierLite),
      };

      yield put(dossierActions.getUserDossiersListSuccess(mappedData));
    } else {
      throw new Error('Data is not an array');
    }
  } catch (err: any) {
    yield put(dossierActions.getUserDossiersListFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* getDefaultDossier() {
  try {
    const defaultDossier: Dossier = yield call(DossierService.getDefaultDossier);
    yield put(dossierActions.getDefaultDossierSuccess(defaultDossier));
  } catch (err: any) {
    yield put(dossierActions.getDefaultDossierFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* processDossiers({ payload }: PayloadAction<ProcessDossierRequest>) {
  try {
    payload.live_data = payload.live_data || false;
    const processedDossier: Dossier = yield call(DossierService.processDossier, payload);
    yield put(dossierActions.processDossierSuccess(processedDossier));
  } catch (err: any) {
    yield put(dossierActions.processDossierFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
    yield put(profileActions.getUserLimit());
    yield put(dossierActions.processDossierEnabled(false));
  }
}

export function* clearDossierPersonHistory(){
  try {
    const result: boolean = yield call(DossierService.clearHistory);
    if(result){
      yield put(dossierActions.clearDossierPersonHistorySuccess());
      yield put(setNotification({ title: 'Dossier History Cleared', type: 'success' }));
    }else{
      yield put(dossierActions.clearDossierPersonHistoryFailure());
      yield put(setNotification({ title: 'Unable to clear Dossier history', type: 'error' }));
    }
  } catch (err: any) {
    yield put(dossierActions.clearDossierPersonHistoryFailure());
  }
}

export function* setDossierFavorite({ payload }: PayloadAction<DossierUpdateRequest>) {
  try {
    const updateDossierResult: Dossier = yield call(DossierService.updateDossier, payload);
    yield put(dossierActions.setDossierFavoriteSuccess(updateDossierResult));
    let message = payload.isFavorite ? 'Dossier marked as favorite' : 'Dossier removed from favorite';
    yield put(setNotification({ title: message, type: 'success' }));
  } catch (err: any) {
    yield put(dossierActions.setDossierFavoriteFailure({ message: err.message, statusCode: err.statusCode }));
  } finally {
  }
}

export function* deleteDossier({ payload }: PayloadAction<string>) {
  try {
    yield call(DossierService.deleteDossier, payload);
    yield put(dossierActions.deleteDossierSuccess(payload));
    yield put(setNotification({ title: 'Dossier deleted successfully', type: 'success' }));
  } catch (err: any) {
    yield put(dossierActions.deleteDossierFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to delete Dossier', type: 'error' }));
  }
}

export function* getDossiersById({ payload }: PayloadAction<string>) {
  try {
    const DossierDetails: Dossier = yield call(DossierService.getDossierById, payload);
    if (DossierDetails) {
      yield put(dossierActions.getDossierByIdSuccess(DossierDetails));
    } else {
      yield put(dossierActions.getDossierByIdFailure({ message: 'Dossier Not Found', statusCode: 404 }));
    }
  } catch (err: any) {
    yield put(dossierActions.getDossierByIdFailure({ message: err.message, statusCode: err.statusCode }));
    yield put(setNotification({ title: 'Unable to fetch Dossier details', type: 'error' }));
    browserHistory.push(RoutePath.Landing);
  } finally {
  }
}

export const PROCESS_Dossier_SAGA = 'Process Dossier Saga';

export const processDossierSaga = createAction<string>(PROCESS_Dossier_SAGA);

export default function* watcher() {
  yield takeLatest(dossierActions.processDossier.type, processDossiers);
  yield takeEvery(dossierActions.getUserDossiersList.type, getDossiers);
  yield takeEvery(dossierActions.getDefaultDossier.type, getDefaultDossier);
  yield takeEvery(dossierActions.getDossierById.type, getDossiersById);
  yield takeLatest(dossierActions.setDossierFavorite.type, setDossierFavorite);
  yield takeLatest(dossierActions.clearDossierHistory.type, clearDossierPersonHistory);
  yield takeLatest(dossierActions.deleteDossier.type, deleteDossier);
}
