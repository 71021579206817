import { useSelector } from 'react-redux';
import { LoggedInProfileAvatar } from '../../../components/Avatar/LoggedInProfileAvatar';
import { appUsersList } from '../../../redux/selectors/access-management.selectors';
import { UserEditModal } from '../../../components/Modal/UserEditModal/UserEditModal';
import { useEffect, useState } from 'react';
import { User } from '../../../common/models/user/user';
import { ActionItem } from '../../../components/ActionItem/ActionItem';
import { EditIcon } from '../../../common/icons/Icons';

interface UsersViewProps {
  role?: string;
  users?: User[];
}
export const UsersList: React.FC<UsersViewProps> = ({ users = [] }) => {
  const [userEditState, setUserEditState] = useState({
    show: false,
    user: {} as User,
  });
  const [searchText, setSearchText] = useState('');
  const [usersList, setUsersList] = useState([] as User[]);
  const [filteredUsers, setFilteredUsers] = useState(usersList);
  useEffect(() => {
    if (users) {
      setUsersList(users);
    }
  }, [users]);

  useEffect(() => {
    setFilteredUsers(usersList);
  }, [usersList]);
  const handleSearch = (text: string) => {
    if (text.length >= 3) {
      setFilteredUsers(
        usersList.filter(
          (user) =>
            user.firstName.toLowerCase().includes(text.toLowerCase()) ||
            user.lastName.toLowerCase().includes(text.toLowerCase()),
        ),
      );
    } else {
      setFilteredUsers(usersList);
    }
  };

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 bg-white dark:bg-gray-900 pt-5">
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative m-5">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for users"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Last Login
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers?.length > 0 ? (
              filteredUsers?.map((user, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th
                    scope="row"
                    className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <LoggedInProfileAvatar firstName={user?.firstName} lastName={user?.lastName} />
                    <div className="ps-3">
                      <div className="text-base font-semibold">
                        {user?.firstName} {user?.lastName}
                      </div>
                      <div className="font-normal text-gray-500">{user.email}</div>
                    </div>
                  </th>
                  <td className="px-6 py-4">{user.role}</td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>
                      {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date())}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <ActionItem
                      key={index}
                      icon={<EditIcon />}
                      text={'Edit'}
                      onClick={() => {
                        setUserEditState({
                          show: true,
                          user: user,
                        });
                      }}
                    />

                    {/* <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit user</a> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="text-center py-4">
                  No users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {
        <UserEditModal
          isShown={userEditState.show}
          userInfo={userEditState.user}
          onClose={() =>
            setUserEditState({
              show: false,
              user: {} as User,
            })
          }
          onSaveClick={() => {}}
        />
      }
    </>
  );
};
