import React from 'react';

interface TagsProps {
  tags?: string[];
  className?: string;
}

export const Tags: React.FC<TagsProps> = ({ tags, className = 'mt-0' }) => {
  return (
    <div className={`flex items-center justify-start ${className} overflow-auto break-words `}>
      {tags?.map((tag) => (
        <div key={tag} className="tags-wrapper mx-2">
          <span className="tag capitalize">{tag}</span>
        </div>
      ))}
    </div>
  );
};
