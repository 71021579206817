import { all } from 'redux-saga/effects';
import articleWatcher from './article.saga';
import profileWatcher from './profile.saga';
import passwordRecoverWatcher from './password-recover.saga';
import clientAppWatcher from './client-app.saga';
import dossierWatcher from './dossier.saga';
import dossierOrgWatcher from './dossier-org.saga';
import externalSearchWatcher from './external-search.saga';
import promptProtechWatcher from './prompt.saga';
import userPreferencesWatcher from './user-preference.saga';
import contradictionWatcher from './contradiction.saga';
import accessManagementWatcher from './acccess-management.saga';
import dashboardMetricsWatcher from './dashboard.saga';
import federatedWatcher from './federated.saga';
import codeAnalysisWatcher from './code-analysis.saga';
import summarizationWatcher from './summarization.saga';
import patternManagementWatcher from './pattern-management.saga';

import projectsWatcher from './projects.saga';

export default function* rootSaga() {
  yield all([
    articleWatcher(),
    promptProtechWatcher(),
    profileWatcher(),
    passwordRecoverWatcher(),
    dossierWatcher(),
    dossierOrgWatcher(),
    clientAppWatcher(),
    externalSearchWatcher(),
    contradictionWatcher(),
    userPreferencesWatcher(),
    accessManagementWatcher(),
    dashboardMetricsWatcher(),
    federatedWatcher(),
    codeAnalysisWatcher(),
    patternManagementWatcher(),
    summarizationWatcher(),
    projectsWatcher(),
  ]);
}
