export type AnimationType = 'none' | 'fade';

export const getAnimationClassName = (type: AnimationType = 'none') => {
  return {
    fade: (isShown: boolean) => {
      return isShown ? 'animate__animated animate__zoomIn faster' : 'animate__animated animate__zoomOut faster';
    },
    none: () => {
      return '';
    },
  }[type];
};
