import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { articleErrorCodes } from '../../redux/selectors/article.selectors';
import {
  PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER,
  PROCESSING_WAITING_MESSAGE_USER,
  PROMPT_PROTECT,
  PROMPT_OPTIMIZER,
} from '../../common/constants/app-text';
import { MainButton } from '../MainButton';
import ProgressBar from '../Progressbar/Progressbar';
import { RoutePath } from '../../common/constants/route-path';
import { setNotification } from '../../redux/actions/notification.actions';
import { GuardRailEngineType, SourceType } from '../../common/constants/enums/engine-types.enum';
import { AppState } from '../../redux/types/App.type';

import { PromptProtectPrimaryIcon, PromptOptimizerPrimaryIcon } from '../../common/icons/Icons';
import { PlugInResultActions } from '../PlugInResultActions/PlugInResultActions';
import { Divider } from '../Divider/Divider';
import { PromptProtectModal } from '../Modal/PromptProtectCheck/PromptProtectModal';
import {
  isPromptProcessing,
  isSubmittedRequest,
  llmResponse,
  showPromptProtectModel,
  showPromptOptimserModel,
  promptProtectCheckText,
} from '../../redux/selectors/federated.selectors';
import { federatedActions } from '../../redux/actions/federated.actions';
import { defaultLLM } from '../../redux/selectors/user-preference.selectors';
import LLMModels from '../../common/constants/llm-models';
import { RequestTrackers } from '../RequestTracker/RequestTrackers';
import { limitedReached } from '../../redux/selectors/profile.selectors';
import { PromptOptimizerModal } from '../Modal/PromptOptimizerModal/PromptOptimizerModal';

interface ContentInputFederatedProps {
  userProcessingInput?: string;
  viewType?: 'Processing' | 'Results' | 'Input';
  placeHolderText?: string;
  showTextInput?: boolean;
  engineType: GuardRailEngineType;
}

export function ContentInputFederated({
  userProcessingInput = '',
  viewType,
  placeHolderText = PLACEHOLDER_FOR_USERINPUT_FACT_CHECKER,
}: ContentInputFederatedProps) {
  const [content, setContent] = useState<string>(userProcessingInput);
  const isFactGenProcessing = useSelector(isPromptProcessing);

  const llmResults = useSelector(llmResponse);
  const errorCode = useSelector(articleErrorCodes);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limitReached = useSelector(limitedReached);
  const showFederatedModel = useSelector(showPromptProtectModel);
  const currentPromptText = useSelector(promptProtectCheckText);
  const requestSubmitted = useSelector(isSubmittedRequest);
  const [selectedModels, setSelectedModels] = useState<number[]>([]);
  const showOptimserModel = useSelector(showPromptOptimserModel);

  const currentActiveAssetionId = useSelector((state: AppState) => state.article.currentArticle?.id);
  const defaultLLMEnabled = useSelector(defaultLLM);

  useEffect(() => {
    setContent(currentPromptText);
  }, [currentPromptText]);

  useEffect(() => {
    if (requestSubmitted && !isFactGenProcessing && llmResults && llmResults.length > 0) {
      navigate(`${RoutePath.LLMResult}/${llmResults[0]?.id}`);
    }
  }, [requestSubmitted, isFactGenProcessing, llmResults, navigate]);

  const handleClick = () => {
    const selectedSourceTypes = selectedModels.map((index) => LLMModels[index].sourceType);
    const request = {
      prompt: content,
      sourceTypes: selectedSourceTypes as SourceType[],
    };
    dispatch(federatedActions.processPrompt(request));
  };

  const handleProgressNavigation = () => {
    if (errorCode && errorCode.length) {
      dispatch(setNotification({ title: 'Unable to request.. ', type: 'error' }));
      navigate(RoutePath.Landing);
    } else {
      navigate(`${RoutePath.LLMResult}/${currentActiveAssetionId}`);
    }
  };

  useEffect(() => {
    const defaultLLMIndex = LLMModels.findIndex((item) => defaultLLMEnabled === item.type);
    if (defaultLLMIndex !== -1) {
      setSelectedModels([defaultLLMIndex]);
    }
  }, [LLMModels, defaultLLMEnabled]);

  const handleCheckboxChange = (index: number) => {
    setSelectedModels((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const handleSelectAll = () => {
    const enabledIndexes = LLMModels.reduce((acc, item, index) => {
      if (!item.disabled) {
        acc.push(index);
      }
      return acc;
    }, [] as number[]);

    if (selectedModels.length === enabledIndexes.length) {
      setSelectedModels([]);
    } else {
      setSelectedModels(enabledIndexes);
    }
  };

  return (
    <div className="mt-5 ml-20">
      <div>
        <div className="min-h-screen p-5 px-10">
          <div className="bg-white p-6 responsive-padding md:p-6">
            <div className="border-b border-gray-200">
              <div className="text-lg font-semibold text-gray-900 mb-2 responsive-text">Prompt</div>
              <div className="p-2 rounded-md border border-blue-200">
                <textarea
                  className={`w-full p-4 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-lg text-base ${
                    viewType === 'Processing' ? 'text-gray-500' : ''
                  }`}
                  value={content}
                  disabled={viewType === 'Processing'}
                  placeholder={placeHolderText}
                  onChange={(e) => setContent(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-between items-center flex-wrap">
              <div className="flex space-x-2 mb-4 md:mb-0">
                <div className="flex space-x-4">
                  <PlugInResultActions
                    actionItems={[
                      {
                        icon: <PromptProtectPrimaryIcon color="purple" />,
                        text: PROMPT_PROTECT,
                        textClass: '!text-purple !font-bold',
                        onClick: () => {
                          dispatch(federatedActions.togglePromptProtectModal(true));
                          dispatch(federatedActions.processPromptProtect(content));
                        },
                      },
                      {
                        icon: <PromptOptimizerPrimaryIcon color="purple" />,
                        text: PROMPT_OPTIMIZER,
                        textClass: '!text-purple !font-bold',
                        onClick: () => {
                          dispatch(federatedActions.togglePromptOptimiserModel(true));
                          dispatch(
                            federatedActions.processPromptOptimiser({
                              session_id: Math.random().toString(36).substring(2),
                              user_initial_prompt: content,
                            }),
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="text-sm text-gray-500 responsive-text">{content.length} characters</div>
            </div>
            <Divider />
            {content && isFactGenProcessing ? (
              <div className="mt-6 w-full">
                <div className="flex text-center  w-full mt-10 mb-5 animate__animated animate__zoomIn">
                  <div className="w-full">
                    <ProgressBar
                      subText="Guardrail LLM, Chat GPT, Microsoft Copilot, Gemini"
                      message="Submitting input to selected sources"
                      navigate={handleProgressNavigation}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-center items-center mt-2 mb-2  animate__animated animate__fadeInUp">
                    <div className="flex flex-col md:flex-row justify-between py-4">
                      <div className="flex space-x-6 items-center  mb-4 md:mb-0 md:mr-4">
                        <p className="don-t-want-to-wait">
                          <span className=" text-blue-600 font-bold">Don’t want to wait? </span>
                          <span className="text-gray-500  font-light"> {PROCESSING_WAITING_MESSAGE_USER} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="mt-3">
                  <div>
                    {/* LLMS Starts Here */}
                    <div className="flex justify-between items-center flex-wrap">
                      <div className="flex space-x-2 mb-4 md:mb-0">
                        <div className="flex space-x-4">
                          <div className="text-lg font-semibold text-gray-900 mb-2 responsive-text">Submit to:</div>
                        </div>
                      </div>
                      <div className="text-sm text-gray-500 responsive-text">
                        <div className="input-action-item">
                          <div className="text-wrapper-29">Select All</div>
                          <div className="icon-wrapper">
                            <div className="text-wrapper-30 p-2">
                              <input
                                type="checkbox"
                                className="mr-2 border cursor-pointer rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-lg text-base"
                                checked={selectedModels.length === LLMModels.filter((model) => !model.disabled).length}
                                onChange={handleSelectAll}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap-4 flex-wrap my-2">
                      {LLMModels.map((button, index) => (
                        <button
                          key={index}
                          className={`flex items-center text-sm px-2 font-['Open_Sans'] text-black-gray ${
                            button.disabled ? 'opacity-50 cursor-not-allowed' : `${button.hoverColor} cursor-pointer`
                          } mb-2`}
                          disabled={button.disabled}
                          onClick={() => !button.disabled && handleCheckboxChange(index)}
                        >
                          <input
                            type="checkbox"
                            disabled={button.disabled}
                            checked={selectedModels.includes(index)}
                            readOnly
                            className={`mr-2 border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-lg text-base ${
                              button.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                            }`}
                          />
                          <div className="mx-2">{button.icon}</div>
                          <span>{button.displayName}</span>
                        </button>
                      ))}
                    </div>
                    {/* LLMS Ends Here */}
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div className="w-[80%]">
                    <RequestTrackers />
                  </div>
                  <div className="flex justify-end items-center space-x-4">
                    <MainButton
                      disabled={content.length < 3 || selectedModels.length === 0 || limitReached}
                      onClick={handleClick}
                      className="px-5"
                      stateProp="rest"
                      text="Submit"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* ---------------------------------- */}
          <PromptProtectModal isShown={showFederatedModel} />
          <PromptOptimizerModal isShown={showOptimserModel} engineType={GuardRailEngineType.Federated} />
        </div>
      </div>
    </div>
  );
}

export default ContentInputFederated;
