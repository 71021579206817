import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AssertionResult } from '../../types/models';
import { DefaultAssertionsData } from '../../common/constants/defaultArticle';
import { MAX_RESULTS_TO_SHOW, MAX_SOURCES_TO_SHOW } from '../../common/constants/default-values';

export interface AppRenderState {
  isLoggedInUser: boolean;
  maxResultsToShow: number;
  maxSourceToShow: number;
}

const initialState: AppRenderState = {
  isLoggedInUser: false,
  maxResultsToShow: MAX_RESULTS_TO_SHOW,
  maxSourceToShow: MAX_SOURCES_TO_SHOW,
};
export const appRenderConfig = createSlice({
  name: 'Active User State',
  initialState,
  reducers: {
    setLoggedInUser: (state, action: PayloadAction<boolean>) => {
      state.isLoggedInUser = action.payload;
      state.maxResultsToShow = action.payload ? 100 : MAX_RESULTS_TO_SHOW;
      state.maxSourceToShow = action.payload ? 100 : MAX_SOURCES_TO_SHOW;
    },
  },
});

export const { setLoggedInUser } = appRenderConfig.actions;
export const appRenderConfigReducer = appRenderConfig.reducer;
