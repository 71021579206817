import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_ACCOUNT, ALREADY_HAVE_ACCOUNT, SIGN_IN, DEFAULT_DOMAIN_ID } from '../../../common/constants/app-text';
import CloseButton from '../../CloseButton/CloseButton';
import { Divider } from '../../Divider/Divider';
import Input from '../../Input';
import { LogoDetailed } from '../../LogoDetailed/LogoDetailed';
import { MainButton } from '../../MainButton';
import { MessageWithAction } from '../../MessageWithAction/MessageWithAction';
import { SignInProvidersGroup } from '../../SignInProvidersGroup/SignInProvidersGroup';
import { TermsAndConditions } from '../../TermsAndConditions/TermsAndConditions';
import { profileActions } from '../../../redux/actions/profile.actions';
import { layoutActions } from '../../../redux/actions/layout.actions';
import { useForm } from '../../../common/helpers/form-controls/use-form';
import { Validators } from '../../../common/helpers/form-controls/validators';
import { isEmpty } from '../../../common/helpers/objects/is-empty';
import { isLoading, selectProfileErrorMessage } from '../../../redux/selectors/profile.selectors';
import ErrorBox from '../../ErrorBox';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RoutePath } from '../../../common/constants/route-path';

export interface UserSignInForm {
  firstName: string;
  lastName: string;
  email: string;
  domainId: string;
}

interface Props {
  onSignInClose: any;
}

export const SignUpEmailStep = ({ onSignInClose }: Props) => {
  const errorMessageSelector = useSelector(selectProfileErrorMessage);
  const isProcessing = useSelector(isLoading);
  const [errorMessage, setError] = useState<string>('');


  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleSubmit, handleChange, data, errors } = useForm<UserSignInForm>({
    validations: {
      firstName: [
        {
          validator: Validators.required,
          message: 'First name is required',
        },
      ],
      lastName: [
        {
          validator: Validators.required,
          message: 'Last name is required',
        },
      ],
      email: [
        {
          validator: Validators.email,
          message: 'Invalid email format',
        },
      ],
    },
    onSubmit: () => validateEmailWithOTP(data),
  });

 useEffect(() => {
  setError(errorMessageSelector);
 }, [errorMessageSelector]);



  var dispatch = useDispatch();

  const validateEmailWithOTP = (data: UserSignInForm) => {
    dispatch(profileActions.resetError())
    data.domainId = searchParams.get('domainId') ?? DEFAULT_DOMAIN_ID;
    dispatch(profileActions.createAccount(data));
  };

  const showSignInModal = () => {
    dispatch(profileActions.resetError());
    navigate(RoutePath.SignIn);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {/* <div className="flex justify-end w-full items-center">
          <CloseButton onClose={onSignInClose} />
        </div> */}
      <div className="block group mx-5">
        <LogoDetailed />
      </div>
      <h2 className="mt-4  font-sans text-center text-midnight-blue font-bold">{CREATE_ACCOUNT}</h2>
      <div className="flex justify-between w-full">
        <div>
          <Input
            id="first-name"
            className="w-55  py-2 "
            type="text"
            size="sm"
            disabled={false}
            placeholder="First name"
            value={data.firstName}
            error={!!errors.firstName}
            onChange={handleChange('firstName')}
          />
          {errors.firstName && (
            <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.firstName}</div>
          )}
        </div>
        <div>
          <Input
            id="last-name"
            className="w-55  py-2 "
            type="text"
            size="sm"
            disabled={false}
            placeholder="Last name"
            value={data.lastName}
            error={!!errors.lastName}
            onChange={handleChange('lastName')}
          />
          {errors.lastName && (
            <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.lastName}</div>
          )}
        </div>
      </div>
      <div className="w-full">
        <Input
          id="email"
          className="w-full  py-2 "
          type="text"
          size="sm"
          disabled={false}
          placeholder="Email address"
          value={data.email}
          error={!!errors.email}
          onChange={handleChange('email')}
        />
        {errors.email && (
          <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.email}</div>
        )}
      </div>
      <div className="w-full my-2"> {errorMessage && <ErrorBox errorMessage={errorMessage} />}</div>
      <MainButton
        isLoading={isProcessing}
        disabled={!isEmpty(errors) || !data.firstName || !data.lastName || !data.email}
        onClick={() => handleSubmit()}
        className="w-full w-100 px-4  mt-2"
        stateProp="rest"
        text="Continue"
      />
      <MessageWithAction message={ALREADY_HAVE_ACCOUNT} onAction={showSignInModal} action={SIGN_IN} />
      <Divider />
      {/* <SignInProvidersGroup /> */}
      <TermsAndConditions />
    </div>
  );
};
