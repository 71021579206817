import { EDIT_TEXT, SHARE_TEXT, FAVORITE_TEXT } from '../../common/constants/app-text';
import { EditIcon, FavoriteIcon, FavoriteIconPink, ShareIcon } from '../../common/icons/Icons';
import { PlugInResultActions } from '../PlugInResultActions/PlugInResultActions';
import { Tags } from '../Tags/Tags';
import { ArticleUpdateRequest, DossierUpdateRequest, OrganizationHeaderModel } from '../../types/models';
import { useDispatch } from 'react-redux';
import { DossierOrgActions } from '../../redux/actions/dossier-org.actions';
import { AuthContext } from '../../config/authorization/AuthContext';
import { useContext } from 'react';
import { DOSSIER_GENERATOR_FAVORITES } from '../../config/authorization/PermissionsConstants';

interface OrganizationHeaderProps {
  data: OrganizationHeaderModel;
}

export const OrganizationHeader: React.FC<OrganizationHeaderProps> = ({ data }): JSX.Element => {
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  return (
    data && (
      <div className="container bg-white rounded-lg shadow-md   mx-auto p-4 animate__animated animate__fadeInUp">
        <div className="flex justify-between items-center w-100">
          <div className="w-100">
            <div>
              <span className="text-purple-800 text-3xl font-bold"> {data.title} </span>
              <span className="text-purple-800 text-sm font-semibold uppercase tracking-wide">{data.legalStatus}</span>
            </div>
            <span className="text-gray-500 text-sm ">{data.incorporationDate}</span>
          </div>
          <div className="">
            <div className="flex space-x-4">
              <PlugInResultActions
                actionItems={[
                  {
                    icon: <EditIcon />,
                    text: EDIT_TEXT,
                    isVisible: false,
                    onClick: () => {},
                  },
                  {
                    icon: <ShareIcon />,
                    text: SHARE_TEXT,
                    onClick: () => {},
                  },
                  {
                    icon: data?.isFavorite ? <FavoriteIcon /> : <FavoriteIconPink />,
                    text: FAVORITE_TEXT,
                    isVisible: authContext?.checkPermission([DOSSIER_GENERATOR_FAVORITES]),
                    textClass: data.isFavorite ? `text-primary` : 'text-wrapper-27 ',
                    onClick: () => {
                      let updateArticle = {
                        isFavorite: !data?.isFavorite,
                        id: data?.id,
                        progressPercentage: 100,
                      } as DossierUpdateRequest;
                      dispatch(DossierOrgActions.setDossierOrgFavorite(updateArticle));
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="mt-2 text-gray-600 text-lg">
          <p className="text-sm"> {data.history}</p>
        </div>
        <p className="text-purple-800  font-semibold mx-2 mt-4">Core Values</p>
        <Tags className="my-2" tags={data.CoreValues} />
      </div>
    )
  );
};
