import { createSlice } from '@reduxjs/toolkit';

import { ClientAppResponseDto } from '../../common/services/client-app/models/client-app';
import { CLIENT_APP_STORE_NAME, clientAppActions } from '../actions/client-app.actions';
import { profileActions } from '../actions/profile.actions';

export interface ClientAppState {
  currentClientApp?: ClientAppResponseDto | any;
  errorCode: string;
  isFetchingClientApp: boolean;
  isPostingClientApp: boolean;
  isEditingClientApp: boolean;
  isLoading: boolean;
  isDeleteClientApp: boolean;
}

const initialClientAppState: ClientAppState = {
  currentClientApp: {
    name: '',
    alias: '',
    clientDomain: '',
    requestPerDay: 100,
    expiresAt: '',
    callbackEndpoint: '',
  },
  isFetchingClientApp: false,
  isPostingClientApp: false,
  isEditingClientApp: false,
  isLoading: false,
  isDeleteClientApp: false,
  errorCode: '',
};
export const ClientAppSlice = createSlice({
  name: CLIENT_APP_STORE_NAME,
  initialState: initialClientAppState,
  reducers: {
    postClientApp: (state, action) => {
      state.isPostingClientApp = true;
      state.currentClientApp = initialClientAppState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientAppActions.postClientApp, (state, { payload }) => {
        state.isPostingClientApp = false;
        state.isLoading = true;
        state.errorCode = '';
      })
      .addCase(clientAppActions.postClientAppSuccess, (state, { payload }) => {
        state.currentClientApp = payload;

        state.isPostingClientApp = true;
        state.isLoading = false;
        state.errorCode = '';
      })
      .addCase(clientAppActions.postClientAppFailure, (state, { payload }) => {
        state.errorCode = payload.message;
        state.isPostingClientApp = false;
        state.isLoading = false;
      })

      .addCase(clientAppActions.getClientAppById, (state, { payload }) => {
        // state.currentClientApp = payload;
        state.isLoading = true;
      })
      .addCase(clientAppActions.getClientAppByIdFailure, (state, { payload }) => {
        state.isFetchingClientApp = false;
      })
      .addCase(clientAppActions.getClientAppByIdSuccess, (state, { payload }) => {
        state.currentClientApp = payload;
        state.errorCode = '';
        state.isFetchingClientApp = true;
        state.isLoading = false;
      })
      .addCase(clientAppActions.getClientAppByUserId, (state, {}) => {
        // state.currentClientApp = payload;
        state.isLoading = true;
      })
      .addCase(clientAppActions.getClientAppByUserIdFailure, (state, {}) => {
        state.isFetchingClientApp = false;
      })
      .addCase(clientAppActions.getClientAppByUserIdSuccess, (state, { payload }) => {
        state.currentClientApp = payload;
        state.errorCode = '';
        state.isFetchingClientApp = true;
        state.isLoading = false;
      })
      .addCase(clientAppActions.updateClientApp, (state, { payload }) => {
        state.isEditingClientApp = false;
        state.isLoading = true;
        state.errorCode = '';
      })
      .addCase(clientAppActions.updateClientAppSuccess, (state, { payload }) => {
        state.currentClientApp = payload;
        state.isEditingClientApp = true;
        state.isLoading = false;
        state.errorCode = '';
      })
      .addCase(clientAppActions.updateClientAppFailure, (state, { payload }) => {
        state.isEditingClientApp = false;
        state.isLoading = false;
        state.errorCode = payload.message;
      })
      .addCase(clientAppActions.deleteClientApp, (state, { payload }) => {
        state.isDeleteClientApp = true;
        state.errorCode = '';
      })
      .addCase(clientAppActions.deleteClientAppSuccess, (state, { payload }) => {
        state.isDeleteClientApp = false;
        state.errorCode = '';
      })
      .addCase(clientAppActions.deleteClientAppFailure, (state, { payload }) => {
        state.isDeleteClientApp = false;
        state.errorCode = payload.message;
      }).addCase(profileActions.logout, (state) => {
        Object.assign(state, initialClientAppState);
      });;
  },
});

export const { postClientApp } = ClientAppSlice.actions;
export const clientAppReducer = ClientAppSlice.reducer;
