import { useState } from "react";
import { useSelector } from "react-redux";
import { limitedReached, userDetails, userLimit } from "../../redux/selectors/profile.selectors";
import { RoutePath } from "../../common/constants/route-path";
import { useNavigate } from "react-router-dom";

export const RequestTrackers = (): JSX.Element => {
    const requestLimit = useSelector(userLimit);
    const navigate = useNavigate();
    const currentUser = useSelector(userDetails)
    return (
        currentUser.isSubscribed ? <></> :
        <div className="w-full rounded py-2 px-6 flex justify-between items-center bg-gradient-to-r bg-gradient-to-b from-purple-800 via-green-white to-blue-400">
            <div className="flex-grow w-3/5"> {/* Adjusted for 60% width */}
                <span className="text-lg font-normal text-white font-[Open_sans]">You've used {5-requestLimit} of your 5 daily request limit. </span>
            </div>
            <div className="w-2/5 flex justify-end"> {/* Adjusted for 40% width */}
                <button onClick={() => {
                      navigate(RoutePath.Plans);
                    }} className="bg-gradient-to-b from-yellow-600 to-yellow-300 text-white font-normal text-lg rounded py-2 px-6 hover:bg-green-600 font-[Open_sans]">Upgrade Now</button>
            </div>
        </div>
    );
}