export interface PostUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  claims?: Array<any>;
  organizationId?: string;
  role?: string;
}

export const DefaultUserRole = '64eb108ca7c91604f5bfebfb';
